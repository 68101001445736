import React from 'react'
import classes from '../../../../containers/Account/Account.module.css'
import Button from '../../../UI/Button/Button'
import Aux from '../../../../hoc/auxiliary/auxiliary'

const conditioningProtocol1 = (props) => {

    let conditioningProtocol1Form = (
        <Aux>
            <div className={classes.AccountContainer}>
                    <form className={classes.FormStyle}>
                        <h2>Obedience Stage 1 Run Details</h2>
                        <p className={classes.smallText}>The Hypno Virus Commands you to always obey, {props.name}</p>
                        <div>
                            <p><strong>Conditioning Protocol 1: {props.ConditioningProtocol1.conditioning_protocol1}</strong></p>
                        </div>
                        <div>
                            <p><strong>Comply Sequence: {props.ConditioningProtocol1.comply_sequence} </strong></p>
                        </div>
                        <div>
                            <p><strong>Resistance Sequence: {props.ConditioningProtocol1.resistance_sequence}</strong></p>
                        </div>
                    </form>
                    <div className={classes.AuthButtons}>
                        <Button btnType="Danger" clicked={props.ReturnBackHandler}>Back</Button>
                    </div>

                </div>
        </Aux>)


    return conditioningProtocol1Form
}

export default conditioningProtocol1