import React, { Component } from 'react';
import Aux from '../../../hoc/auxiliary/auxiliary';
import classes from './HypnosisShowMenu.module.scss'
// import classes2 from '../ExperienceMenu/ExperienceMenu.module.scss'
import HypnosisShow from '../../../assets/Images/Blank.png'
import PlayBallGame from './HypnosisShowPlayBall'
import ExploreFair from './HypnosisShowExplore'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'

class HypnosisShowStart extends Component {

    state = {
        startStory: true,
        playGame: false,
        explore: false
    }

    playGameHandler = () => {
        this.setState({ startStory: false, playGame: true })
    }

    ExploreHandler = () => {
        this.setState({ startStory: false, explore: true })
    }
    render() {
        let hit_miss = []
        let ball_name = ''
        for(let i = 0; i<3;i++){
            let ball_throwing = Math.floor(Math.random() * 2) +1
            if(ball_throwing === 1){
                ball_name = 'hits'
            }else{
                ball_name = 'misses'
            }
            hit_miss.push(ball_name)
        }
        let HypnosisShowForm = null
        if (this.state.startStory) {
            HypnosisShowForm = (
                <html style={{ backgroundImage: `url(${HypnosisShow})` }}>
                    <div className={classes.storyFrom}>
                    <p>
                        <strong>You did it!</strong> <br></br>
                        After weeks of hard work & late nights. You met the deadline & got the raise you wanted. <br></br>
                        This is why you are here at the fair, you are way overdue some much needed time off. <br></br> 
                        Here you are at the countryside, enjoying the fresh air away from all the noises and stress of the city. You decided to come to the fair cause it’s been ages since you’ve been to one of these. <br></br> 
                        In this lovely weather you were not going to miss the chance to feel the nice cool breeze on your skin while you wander around & check out the games<br></br>
                        Wandering around aimlessly checking the games & the entertainment in the fair. Finding all sorts of classic games and entertainment you’d find in a place like this from a small ferris wheel to prize winning games like the one you're standing in front of now. <br></br>
                        You stand & watch people as they attempt to hit the ball and drop the bowling pins in order to win a prize. You think to yourself <br></br>
                        <i>"Do I play the game or keep exploring?"</i><br></br>
                        You decide to...
                    </p>
                    <form className={classes.centered} onClick={this.playGameHandler}>
                        <h3 className={classes.storyButtonSize}>Play the Game</h3>
                    </form>
                    <form className={classes.centered} onClick={this.ExploreHandler}>
                        <h3 className={classes.storyButtonSize}>Explore</h3>
                    </form>
                    </div>
                </html>
                )
        }
        if(this.state.playGame){
            HypnosisShowForm = (
                <PlayBallGame hit_miss={hit_miss}/>
            )
        }
        if(this.state.explore){
            HypnosisShowForm = (
                <ExploreFair />
            )
        }

        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {HypnosisShowForm}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >




        )
    }
}


export default HypnosisShowStart