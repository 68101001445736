import React, { useState } from "react";
import TextTransition from "react-text-transition";
import TypingEffect from 'react-typing-effect'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import Button from '../../../../UI/Button/Button'
import classes from '../../../../../containers/HostsChamber/AudioRuns/AudioRuns.module.css'
import * as scripts from '../../../../../shared/AudioRuns/ConditioningProtocols/ConditioningProtocol#1/ConditioningProtocol1Scripts'
import * as Delays from '../../../../../shared/AudioRuns/ConditioningProtocols/ConditioningProtocol#1/ConditioningProtocol#1Delay'
import { connect } from 'react-redux';
import ConditioningComply1 from './Conditioning_Protocol_Comply1'
import {ConditioningProtocol1End} from '../../../../../store/actions/AudioRuns/audioRunsIndex'
import Sound from 'react-sound'
import Comply from '../../../../../assets/audio_runs/conditioning_protocols/conditioning_protocol_1/conditioning_protocol_comply.mp3'
import Delay from "react-delay";
import useInterval from 'react-useinterval';

const DELAY_COMPLY = [4100]


  

function Conditioning_Protocol_Comply ({userId, token,conditioningProtocol1Complete, ConditioningProtocol1End}) {
    let [complycount, setCount] = useState(0);
    let [conditioning_comply, setComply] = useState(true)
    let [conditioning_comply1, setComply1] = useState(false)

    const increaseComplyCount = () => {
        if(conditioning_comply && complycount < scripts.CONDITIONING_PROTOCOL_COMPLY.length){
            setCount(complycount + 1);
          }
      };
    
    
    useInterval(increaseComplyCount, Delays.DELAY_TIME_COMPLY[complycount]);
   
    

    const enterComply1Handler = () => {
        setComply(conditioning_comply = false)
        setComply1(conditioning_comply1 = true)
    }

    

        let conditioning_protocol_comply_form = null

        if (conditioning_comply) {
            conditioning_protocol_comply_form = (
                <Aux>
                    {complycount < scripts.CONDITIONING_PROTOCOL_COMPLY.length ?
                        <Aux >
                            {/* <div className={classes.texting}> */}
                                <Sound url={Comply} playStatus={Sound.status.PLAYING} />
                                <Delay wait={DELAY_COMPLY[complycount]}>
                                <TextTransition
                                    text={<TypingEffect typingDelay={100} text={scripts.CONDITIONING_PROTOCOL_COMPLY[complycount]} speed={90} eraseDelay={1000000} />}
                                    Overflow
                                />
                                </Delay>
                            {/* </div> */}
                        </Aux>
                        :
                        <div className={classes.ButtonPadding}>
                            <Button btnType="Success" clicked={enterComply1Handler}>I Comply, I obey The Hypno Virus</Button>
                        </div>
                    } 
                </Aux>
            )

        }

        if (conditioning_comply1) {
            conditioning_protocol_comply_form = (
                <ConditioningComply1/>
            )
        }



        return (
            conditioning_protocol_comply_form
        );
    }


const mapStateToProps = state => {
    return {
        hostName: state.auth.accountDetails.name,
        runSpeed: state.auth.accountDetails.speed,
        userId: state.auth.userId,
        token: state.auth.token,
        conditioningProtocol1Complete:state.conditioningProtocol1.conditioningProtocol1Complete
    }
}

const mapDispatchToProps = dispatch => {
    return {
        ConditioningProtocol1End: (userId, token, comply_sequence, resistance_sequence) => dispatch(ConditioningProtocol1End(userId, token, comply_sequence, resistance_sequence)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Conditioning_Protocol_Comply)