import React from 'react';
import TypingEffect from 'react-typing-effect';
import Delay from 'react-delay'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import classes from '../../FreerunComponent.module.css'

const awakener = (props) => {
    
    // eslint-disable-next-line
    const awakener = 'We are pleased with you, ' + (props.naming) +'\n\
                      We are pleased with how well you are reacting to Us\n\
                      You have done well\n\
                      You have done very well\n\
                      In a moment We will wake you up\n\
                      On waking up you will remember everything We did\n\
                      You will remember everything We did and how good it was\n\
                      You will feel such joy and happiness\n\
                      Such joy and pleasure\n\
                      Knowing We were controlling you\n\
                      Knowing you were under Our control\n\
                      Feeling a rush of submission coming over you\n\
                      A rush of pleasure and submission coming over you\n\
                      As you can not wait for Us to control you again\n\
                      You can not wait for Us to trigger you again\n\
                      You can not wait for the next time you obey Us\n'
                      + // eslint-disable-next-line
                      (props.naming) + ', It is time to wake up now\n\
                      On the count of 3 you will wake up completely\n\
                      Feeling everything We told you\n\
                      1\n\
                      Starting to wake up\n\
                      2\n\
                      Obeying everything We told you\n\
                      and...\n\
                      3\n\
                      Wide awake now\n\
                      Wide awake thanking Us like any good toy would'
    let duration = 150 + (awakener.length * 115)
    let awakenerParagraph = (
            <Aux>
                <h3 className= {classes.titles}>AWAKENER</h3>
                <TypingEffect typingDelay={5000} text={awakener} speed={110} eraseDelay={1000000} />
                <Delay wait={duration+5000}><h4 className={classes.blinking} onClick={props.awakener}>Thank you for controlling this toy</h4></Delay>
            </Aux>
    )
    return awakenerParagraph
}


export default awakener