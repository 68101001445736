import React, { Component } from 'react';
import Aux from '../../../../hoc/auxiliary/auxiliary';
import classes from '../HypnosisShowMenu.module.scss'
// import classes2 from '../ExperienceMenu/ExperienceMenu.module.scss'
import HypnosisShow from '../../../../assets/Images/Blank.png'
import Snap from './HypnosisShowNonVolSnap1'
import ForeheadTap from './HypnosisShowNonVolForeheadTap'
import SelfSnap from './HypnosisShowNonVolSelfSnap'
import WakeUp from './HypnosisShowNonVolWakeUp'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'

class HypnosisShowHypnoGun extends Component {

    state = {
        hypnoGun: true,
        fingerSnap: false,
        foreheadTap: false,
        countdownSnap: false,
        wakeUp: false
    }

    fingerSnapHandler = () => {
        this.setState({ hypnoGun: false, fingerSnap: true })
    }

    foreheadTapHandler = () => {
        this.setState({ hypnoGun: false, foreheadTap: true })
    }


    countdownSnapHandler = () => {
        this.setState({ hypnoGun: false, countdownSnap: true })
    }

    wakeUpHandler = () => {
        this.setState({ hypnoGun: false, wakeUp: true })
    }


    render() {
        let HypnosisShowForm = null
        if (this.state.hypnoGun) {
            HypnosisShowForm = (
                <html style={{ backgroundImage: `url(${HypnosisShow})` }}>
                    <div className={classes.storyFrom}>
                        <p>
                        <i>“Use my hypno gun. As soon as I point my fingers at you like a gun and shoot your body collapses as your mind falls deep. 
                        Sinking back under and feeling the joy that comes from going into trance again. Feeling so good & carefree just like you are now & enjoying every second of it, understood?” </i><br></br>
                        Imagining him pointing his fingers at you as you instinctively raise your hands up, he shoots you and you body collapses back deeper into trance. Smiling at this thought as you hear him waking you up <br></br>
                        <i>“Waking up on the count of five. <br></br>
                        One, starting to wake up. <br></br>
                        Two, feeling wonderful and refreshed. <br></br>
                        Three, Excited & happy. <br></br>
                        Four, Eager to participate and join in the experience<br></br>
                        Five, wide awake now!”</i><br></br>
                        <strong>*SNAP*</strong><br></br>
                        You open your eyes, stretch & blink a couple of times as you regain consciousness and remember what’s going on and where you are <br></br>
                        <i>“What an amazing job you guys are doing! A round of applause for these lovely volunteers”</i><br></br> 
                        You clap and cheer for them feeling really happy and excited for them because you yourself are experiencing what they’re feeling. <br></br> 
                        <strong>Suddenly</strong> He jumps around facing you making finger guns with his hand pointing them at the volunteers on each side. <br></br>
                        <i>“Stick’em up this is a robbery!”</i> He says seriously pausing for a few seconds as he bursts into laughter looking at you while you're not too sure what's going on. 
                        He starts to lower his hand only to raise them again and shoot both of them
                        <i>“Bam! Paw!” </i><br></br>
                        They both jump out of their place only to watch their bodies slump and fall forward almost falling to the floor, collapsing and they fall deep under. <br></br>
                        He turns to back to you while pointing his gun.
                        Frozen in place, uou look at him with his finger guns directed at the audience but deep down you know that they are directed at you. 
                        Your heart racing a little watching intently his movement feeling cornered, feeling  trapped in your seat unable to move but loving that, loving that feeling and anticipating. 
                        Anticipating him to shoot eagerly wanting to fall, knowing that this is the only choice you have, to fall... <br></br>
                        He looks at you as if reading your mind he asks in a nice, gentle tone <br></br> 
                        <i>“Are you ready?”</i><br></br> 
                        You nod enthusiastically without thinking as he says 
                        <i><strong>“Paw”</strong></i><br></br>
                        Dropping… dropping deep as your body falls a little bit forward, sinking in your seat more & your mind just goes completely empty. 
                        Your mind & body completely shut down now as you fall so deep, sink further than ever before & hear him say <br></br>
                        <i>"Dropping deeper & deeper, sinking more, drifting and floating further. Drifting & floating further into trance. All you want is have me…”</i>
                        </p>
                        <form className={classes.centered} onClick={this.fingerSnapHandler}>
                            <h4 className={classes.storyButtonSize}>Snap my Fingers</h4>
                        </form>
                        <form className={classes.centered} onClick={this.foreheadTapHandler}>
                            <h4 className={classes.storyButtonSize}>Tap your Forehead</h4>
                        </form>
                        <form className={classes.centered} onClick={this.countdownSnapHandler}>
                            <h4 className={classes.storyButtonSize}>Make you Countdown & Snap your Fingers</h4>
                        </form>
                        <form className={classes.centered} onClick={this.wakeUpHandler}>
                            <h4 className={classes.storyButtonSize}>Wake you Up</h4>
                        </form>
                    </div>
                </html>
            )
        }
        if (this.state.fingerSnap) {
            HypnosisShowForm = (
                <Snap />
            )
        }

        if (this.state.foreheadTap) {
            HypnosisShowForm = (
                <ForeheadTap />
            )
        }

        if (this.state.countdownSnap) {
            HypnosisShowForm = (
                <SelfSnap />
            )
        }

        if (this.state.wakeUp) {
            HypnosisShowForm = (
                <WakeUp />
            )
        }

        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {HypnosisShowForm}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >




        )
    }
}


export default HypnosisShowHypnoGun