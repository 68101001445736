import React, { useState } from "react";
import TextTransition from "react-text-transition";
import TypingEffect from 'react-typing-effect'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import Button from '../../../../UI/Button/Button'
import ConditioningProtocolComply from './Conditioning_Protocol_Comply'
import ConditioningProtocolResist from './Conditioning_Protocol_Resist'
import classes from '../../../../../containers/HostsChamber/AudioRuns/AudioRuns.module.css'
// import * as actions from '../../../../../store/actions/AudioRuns/audioRunsIndex'
import * as scripts from '../../../../../shared/AudioRuns/ConditioningProtocols/ConditioningProtocol#1/ConditioningProtocol1Scripts'
import * as Delays from '../../../../../shared/AudioRuns/ConditioningProtocols/ConditioningProtocol#1/ConditioningProtocol#1Delay'
import { ConditioningProtocol1 } from '../../../../../store/actions/AudioRuns/audioRunsIndex'
import Sound from 'react-sound'
import Opening from '../../../../../assets/audio_runs/conditioning_protocols/conditioning_protocol_1/conditioning_protocol_opening.mp3'
import Delay from "react-delay";
import useInterval from 'react-useinterval';
import { connect } from "react-redux";

const DELAY_TEXT = [4200]


function Conditioning_Protocol_Opening({ userId, token, ConditioningProtocol1 }) {

  let [count, setCount] = useState(0);
  let [conditioning_protocol, setProtocol] = useState(true)
  let [conditioning_comply, setComplySequence] = useState(false)
  let [conditioning_resist, setResistSequence] = useState(false)


  const increaseCount = () => {
    if (conditioning_protocol && count < scripts.CONDITIONING_PROTOCOL_OPENING.length) {
      setCount(count + 1);
    }
  };

  useInterval(increaseCount, Delays.DELAY_TIME[count]);


  ConditioningProtocol1(userId, token)



  const enterComplySequenceHandler = () => {
    setProtocol(conditioning_protocol = false)
    setComplySequence(conditioning_comply = true)

  }

  const enterResistSequencerHandler = () => {
    setProtocol(conditioning_protocol = false)
    setResistSequence(conditioning_resist = true)

  }


  let conditioning_protocol_form = null

  if (conditioning_protocol) {
    conditioning_protocol_form = (
      <Aux>
        {count < scripts.CONDITIONING_PROTOCOL_OPENING.length ?
          <Aux >
            <Sound url={Opening} playStatus={Sound.status.PLAYING} />
            <Delay wait={DELAY_TEXT[count]}>
              <TextTransition
                text={<TypingEffect typingDelay={100} text={scripts.CONDITIONING_PROTOCOL_OPENING[count]} speed={90} eraseDelay={1000000} />}
                Overflow
              />
            </Delay>
          </Aux>
          :
    <div className={classes.ButtonPadding}>
      <Button btnType="Success" clicked={enterComplySequenceHandler}>I want to comply</Button>
      <Button btnType="Success" clicked={enterResistSequencerHandler}>I choose to resist</Button>
    </div>
  } 
      </Aux >
    )
}

if (conditioning_comply) {
  conditioning_protocol_form = (
    <ConditioningProtocolComply />

  )
}

if (conditioning_resist) {
  conditioning_protocol_form = (
    <ConditioningProtocolResist />

  )
}


return (
  <Aux>
      {conditioning_protocol_form}
  </Aux>
)

}

const mapStateToProps = state => {
  return {
    hostName: state.auth.accountDetails.name,
    runSpeed: state.auth.accountDetails.speed,
    userId: state.auth.userId,
    token: state.auth.token,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ConditioningProtocol1: (userId, token) => dispatch(ConditioningProtocol1(userId, token)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Conditioning_Protocol_Opening)