export const PASSWROD_START = 'PASSWROD_START'
export const PASSWORD_SUCCESS = 'PASSWORD_SUCCESS'
export const PASSWORD_FAILED = 'PASSWORD_FAILED'


export const AUTH_START= 'AUTH_START'
export const AUTH_SUCCESS= 'AUTH_SUCCESS'
export const AUTH_FAIL= 'AUTH_FAIL'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const RESET_LOGOUT = 'RESET_LOGOUT'


export const VERIFY_EMAIL_STATUS = 'VERIFY_EMAIL_STATUS'
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS'
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS'
export const FETCH_ACCOUNT_DETAILS = 'FETCH_ACCOUNT_DETAILS'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHNAGE_EMAIL_SUCCESS = 'CHNAGE_EMAIL_SUCCESS'
export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS'
export const EDIT_PROFILE_VIEW = 'EDIT_PROFILE_VIEW'
export const CHANGE_EMAIL_PASSWORD_VIEW = 'CHANGE_EMAIL_PASSWORD_VIEW'
export const VIEW_RUN_DETAIL = 'VIEW_RUN_DETAIL'
export const VIEW_OPTIONS_PAGE = 'VIEW_OPTIONS_PAGE'
export const RETURN_BACK_SUCCESS = 'RETURN_BACK_SUCCESS'

export const VIEW_HOSTS_RUN_DETAIL = 'VIEW_HOSTS_RUN_DETAIL'

export const VIEW_CONDITIONING_STAGES_RUN_DETAIL = 'VIEW_CONDITIONING_STAGES_RUN_DETAIL'

export const VIEW_CONDITIONING_PROTOCOLS_RUN_DETAIL = "VIEW_CONDITIONING_PROTOCOLS_RUN_DETAIL"

export const VIEW_OBEDIENCE_STAGE_1_RUN_DETAIL = 'VIEW_OBEDIENCE_STAGE_1_RUN_DETAIL'
export const VIEW_OBEDIENCE_STAGE_2_RUN_DETAIL = 'VIEW_OBEDIENCE_STAGE_2_RUN_DETAIL'
export const VIEW_OBEDIENCE_STAGE_3_RUN_DETAIL = 'VIEW_OBEDIENCE_STAGE_3_RUN_DETAIL'

export const VIEW_CONDITIONING_PROTOCOL_1 = 'VIEW_CONDITIONING_PROTOCOL_1'

export const VIEW_TRIGGER_INTRODUCTION = "VIEW_TRIGGER_INTRODUCTION"
export const VIEW_TENTACLE_INTRODUCTION = "VIEW_TENTACLE_INTRODUCTION"


export const VIEW_TRIGGER_REINFORCEMENT = "VIEW_TRIGGER_REINFORCEMENT"
export const VIEW_TENTACLE_REINFORCEMENT = "VIEW_TENTACLE_REINFORCEMENT"

export const VIEW_CHLOROFORM_INTRODUCTION = 'VIEW_CHLOROFORM_INTRODUCTION'
export const VIEW_CHLOROFORM_REINFORCEMENT = "VIEW_CHLOROFORM_REINFORCEMENT"

export const VIEW_MOTOR_FUNCTION_CONTROL_INTRODUCTION = 'VIEW_MOTOR_FUNCTION_CONTROL_INTRODUCTION'
export const VIEW_MOTOR_FUNCTION_CONTROL_REINFORCEMENT = 'VIEW_MOTOR_FUNCTION_CONTROL_REINFORCEMENT'

export const VIEW_OPTIONS_SUCCESS = 'VIEW_OPTIONS_SUCCESS'
export const VIEW_OPTIONS_FAIL = 'VIEW_OPTIONS_FAIL'
export const VIEW_OPTIONS = 'VIEW_OPTIONS'
export const VIEW_PROFILE_OPTIONS = 'VIEW_PROFILE_OPTIONS'
export const EDIT_PROFILE_OPTIONS = 'EDIT_PROFILE_OPTIONS'

export const VIEW_TITLE_SUCCESS = 'VIEW_TITLE_SUCCESS'
export const VIEW_TITLE_FAIL = 'VIEW_TITLE_FAIL'
export const VIEW_TITLE = 'VIEW_TITLE'
export const GET_TITLE = 'GET_TITLE'
export const VIEW_PROFILE_TITLE = 'VIEW_PROFILE_TITLE'
export const CHANGE_PROFILE_TITLE = 'CHANGE_PROFILE_TITLE'