import React, { Component } from 'react'
// import Input from '../../../components/UI/Input/Input'
import Button from '../../../components/UI/Button/Button'
import classes from '../Account.module.css'
import Aux from '../../../hoc/auxiliary/auxiliary';
import * as actions from '../../../store/actions/index';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { connect } from 'react-redux'
import Input from '../../../components/UI/Input/Input'
import { updateObject, checkValidity } from '../../../shared/utility'

class Runs extends Component {
    state = {
        Profile_Options: {
            Sound: {
                label: 'Runs Audio',
                elementType: 'select',
                elementConfig: {
                    options: [
                        { value: 0, displayValue: 'None' },
                        { value: 1, displayValue: 'Bell' },
                        { value: 2, displayValue: 'Metronome' },
                        { value: 3, displayValue: 'Ticking Clock' },
                        { value: 4, displayValue: 'Mantra' },
                        { value: 5, displayValue: 'Beta Beat' },
                    ]
                },
                value: this.props.profileOptions.audio_option,
                validation: {
                    required: true
                },
                valid: true
            },
            WebsiteSound: {
                label: 'Website Audio',
                elementType: 'select',
                elementConfig: {
                    options: [
                        { value: 0, displayValue: 'None' },
                        { value: 1, displayValue: 'Bell' },
                    ]
                },
                value: this.props.profileOptions.website_audio,
                validation: {
                    required: true
                },
                valid: true
            },
            Audio_Run_Background: {
                label: 'Audio Run Background',
                elementType: 'select',
                elementConfig: {
                    options: [
                        { value: 0, displayValue: 'No Background' },
                        { value: 1, displayValue: 'Flashy Background' },
                        { value: 2, displayValue: 'Color Change Background' },
                    ]
                },
                value: this.props.profileOptions.audio_run_background,
                validation: {
                    required: true
                },
                valid: true
            },
            Text_To_Speech_Voice: {
                label: 'Text To Speech Voice',
                elementType: 'select',
                elementConfig: {
                    options: [
                        { value: 1, displayValue: 'Male Voice 1' },
                        { value: 2, displayValue: 'Male Voice 2' },
                        { value: 3, displayValue: 'Female Voice 1' },
                        { value: 4, displayValue: 'Female Voice 2' },
                    ]
                },
                value: this.props.profileOptions.text_to_speech_voice,
                validation: {
                    required: true
                },
                valid: true
            },
        }
    }

    componentDidMount() {
        this.props.onViewProfileOptions(this.props.token, this.props.userId)
    }

    inputProfileOptionChangeHandler = (event, profileOptionName) => {
        const updatedProfileOption = updateObject(this.state.Profile_Options, {
            [profileOptionName]: updateObject(this.state.Profile_Options[profileOptionName], {
                ...this.state.Profile_Options[profileOptionName],
                value: event.target.value,
                valid: checkValidity(event.target.value, this.state.Profile_Options[profileOptionName].validation),
                touched: true
            })
        })
        this.setState({ Profile_Options: updatedProfileOption })
    }

    ReturnBackHandler = (event) => {
        event.preventDefault();
        this.props.onReturnBack()
    }

    applyProfileOptionChangesHandler = (event) => {
        event.preventDefault()
        this.props.onEditProfileOptions(this.state.Profile_Options,this.props.token, this.props.userId)
        
    }

    render() {
        let form = null
        // console.log(this.props.profileOptions)
        const formProfileOptionsArray = [];
        for (let key in this.state.Profile_Options) {
            formProfileOptionsArray.push({
                id: key,
                config: this.state.Profile_Options[key]
            });
        }
        if (this.props.viewOptions) {
            form = (
            <div className={classes.AccountContainer}>
                <form className={classes.FormStyle}>
                    <h3>Profile Options</h3>
                        {formProfileOptionsArray.map(formElement => (
                            <Input
                                key={formElement.id}
                                elementType={formElement.config.elementType}
                                elementConfig={formElement.config.elementConfig}
                                value={formElement.config.value}
                                invalid={!formElement.config.valid}
                                shouldValidate={formElement.config.validation}
                                touched={formElement.config.touched}
                                label={formElement.config.label}
                                changed={(event) => this.inputProfileOptionChangeHandler(event, formElement.id)} />

                        ))}
                        <br></br><Button btnType="Success" clicked={this.applyProfileOptionChangesHandler}>Apply Changes</Button>
                        <Button btnType="Danger" clicked={this.props.returnToVisualHandler}>Back</Button>
                    </form>
            </div>
            )
        }

        


        if (this.props.loading) {
            form = <Spinner className={classes.Spinner} />
        }

        let errorMessage = null

        if (this.props.error) {
            errorMessage = (
                <p className={classes.error}>{this.props.error}</p>
            )
        }

        return (
            <Aux>
                <div className={classes.Account}>
                    {errorMessage}
                    {form}
                </div>
            </Aux >

        );
    }
}

const mapStatesToProps = state => {
    return {
        loading: state.options.loading,
        error: state.options.error,
        token: state.auth.token,
        userId: state.auth.userId,
        viewOptions: state.options.viewOptions,
        profileOptions: state.options.profileOptions,
        
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onViewProfileOptions: (token, userId) => dispatch(actions.viewProfileOptions(token, userId)),
        onEditProfileOptions: (updated_profile, token, userId) => dispatch(actions.editProfileOptions(updated_profile, token, userId)),
        onReturnBack: () => dispatch(actions.returnBackSuccess()),
    }
}

export default connect(mapStatesToProps, mapDispatchToProps)(Runs);