import React, { Component } from 'react';
import classes from './FreeRun.module.css'
import Aux from '../../hoc/auxiliary/auxiliary';
import Input from '../../components/UI/Input/Input'
import Button from '../../components/UI/Button/Button'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'
import { checkValidity, updateObject } from '../../shared/utility'
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import FreeRun from '../../components/FreeExperience/Freerun/FreerunComponent'
import { Redirect } from 'react-router-dom';
import Sound from 'react-sound'
import Bell from '../../assets/audio/bell.mp3'
import Metronome from '../../assets/audio/Metronome2.mp3'
import Mantra from '../../assets/audio/mantralong.mp3'
import Ticking from '../../assets/audio/Ticking3.mp3'
import Beta from '../../assets/audio/beta.mp3'
import Background from '../../components/Background/Background'
import MusicConditioning from './MusicConditioning/MusicConditioning';
import WebsiteAudio from '../../components/Background/WebsiteAudio';
import TrancingExercise from '../../components/FreeExperience/TrancingExercise/Trancing_Exercise'
// import Speech from 'react-speech';
import spoken from '../../../node_modules/spoken/build/spoken.js';

class Freerun extends Component {
    state = {
        InfoForm: {
            name: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Your Name'
                },
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                touched: false
            }
        },
        nameEntered: true,
        formIsValid: false,
        enterMusicHall: false,
        enterTrancingExercise: false,
        introduction: false,
        induction: false,
        induction2: false,
        induction3: false,
        mantra: false,
        deepener: false,
        trigger: false,
        awakener: false,
        ending: false,
        endRun: false
    }

    componentDidMount() {
        this.props.onViewProfileOptions(this.props.token, this.props.userId)
    }

    formHandler = (event) => {
        event.preventDefault();

        const formData = {};
        for (let formElementIdentifier in this.state.InfoForm) {
            formData[formElementIdentifier] = this.state.InfoForm[formElementIdentifier].value;
            this.props.onNameSave(formData[formElementIdentifier]);
        }

    }

    inputChangedHandler = (event, inputIdentifier) => {

        const updatedFormElement = updateObject(this.state.InfoForm[inputIdentifier], {
            value: event.target.value,
            valid: checkValidity(event.target.value, this.state.InfoForm[inputIdentifier].validation),
            touched: true
        });
        const updatedInfo = updateObject(this.state.InfoForm, {
            [inputIdentifier]: updatedFormElement
        });

        let formIsValid = true;
        for (let inputIdentifier in updatedInfo) {
            formIsValid = updatedInfo[inputIdentifier].valid && formIsValid;
        }
        this.setState({ InfoForm: updatedInfo, formIsValid: formIsValid });
    }

    startFreeRunHandler = () => {
        this.setState({ introduction: true })
    }

    enterMusicHallHandler = () => {
        this.setState({ enterMusicHall: true })
        this.props.history.replace('/FreeChamber/Music-Conditioning')
    }

    enterTrancingExercise = () => {
        this.setState({ enterTrancingExercise: true })
        this.props.history.replace('/FreeChamber/Trancing-Exercise')
    }

    introductionChangeHandler = () => {

        this.setState({ introduction: false, induction: true })
    }

    inductionChangeHandler = () => {
        this.setState({ induction: false, induction2: true })
    }

    induction2ChangeHandler = () => {
        this.setState({ induction2: false, induction3: true })
    }

    induction3ChangeHandler = () => {
        this.setState({ induction3: false, mantra: true })
    }

    mantraChangeHandler = () => {
        this.setState({ mantra: false, deepener: true })
    }

    deepenerChangeHandler = () => {
        this.setState({ deepener: false, trigger: true })
    }

    triggerChangeHandler = () => {
        this.setState({ trigger: false, awakener: true })
    }

    awakenerChangeHandler = () => {
        this.setState({ awakener: false, ending: true })
    }

    endingChangeHandler = () => {

        this.setState({ ending: false, endRun: true })
    }

    testSpoken = () => {
        spoken.say('Do you obey The Hypno Virus').then( speech => {
            spoken.listen().then( transcript =>
                spoken.say(transcript) )
        } )
    }

    render() {

        let audio_option = null
        if (this.props.profileOptions.audio_option === '1') {
            audio_option = <Sound url={Bell} playStatus={Sound.status.PLAYING} loop={true} />
        } else if (this.props.profileOptions.audio_option === '2') {
            audio_option = <Sound url={Metronome} playStatus={Sound.status.PLAYING} loop={true} />
        } else if (this.props.profileOptions.audio_option === '3') {
            audio_option = <Sound url={Ticking} playStatus={Sound.status.PLAYING} loop={true} />
        } else if (this.props.profileOptions.audio_option === '4') {
            audio_option = <Sound url={Mantra} playStatus={Sound.status.PLAYING} loop={true} />
        } else if (this.props.profileOptions.audio_option === '5') {
            audio_option = <Sound url={Beta} playStatus={Sound.status.PLAYING} loop={true} />
        }

        const formElementsArray = [];
        for (let key in this.state.InfoForm) {
            formElementsArray.push({
                id: key,
                config: this.state.InfoForm[key]
            });
        }
        let form = null
        if (this.state.nameEntered) {
            form = (
                <Aux>
                    <Background />
                    <form
                        onSubmit={this.formHandler}
                        className={classes.FormStyle}>
                        Enter your name, Human!
                    {formElementsArray.map(formElement => (
                            <Input
                                key={formElement.id}
                                elementType={formElement.config.elementType}
                                elementConfig={formElement.config.elementConfig}
                                value={formElement.config.value}
                                invalid={!formElement.config.valid}
                                shouldValidate={formElement.config.validation}
                                touched={formElement.config.touched}
                                changed={(event) => this.inputChangedHandler(event, formElement.id)} />
                        ))}
                        <Button btnType="Success" disabled={!this.state.formIsValid}>The Hypno Virus Welcomes You</Button>
                    </form>
                </Aux>
            );
        }
        if (this.props.saved) {
            form = (
                <Aux>
                    <WebsiteAudio />
                    <Background />
                    <form className={classes.FormStyle}>
                        <h2>Select A Chamber</h2>
                        <p className={classes.smallText}>We are here to control you, {this.props.names}</p>
                        <div>
                            <Button btnType="Success" clicked={this.startFreeRunHandler}>Free Run</Button>
                            <Button btnType="Success" clicked={this.enterMusicHallHandler}>Music Conditioning</Button>
                            <Button btnType="Success" clicked={this.enterTrancingExercise}>Trancing Exercise</Button>
                            {/* <Button btnType="Success" clicked={this.testSpoken}>Do you obey The Hypno Virus</Button>
                            <Speech text="Welcome to react speech"
                                    voice="Google UK English Female" /> */}
                                    
                        </div>
                    </form>
                </Aux>
            );
        }

        if (this.state.enterMusicHall) {
            form = (
                <MusicConditioning />
            );
        }

        if (this.state.enterTrancingExercise){
            form = <TrancingExercise />
        }

        if (this.state.introduction) {
            form = (
                <Aux>
                <Background/>
                    <Sound url={Bell} playStatus={Sound.status.PLAYING} />
                    <FreeRun naming={this.props.names}
                        audio_option={audio_option}
                        introduction={() => this.introductionChangeHandler()}
                    />
                    <p className={classes.Note}>Note: Do not switch tabs while running The Hypno Virus</p>
                </Aux>
            )
        } else if (this.state.induction) {
            form = (
                <Aux>  
                <Background/>
                    <Sound url={Bell} playStatus={Sound.status.PLAYING} />
                    <FreeRun naming={this.props.names}
                        audio_option={audio_option}
                        induction={() => this.inductionChangeHandler()}
                    />
                    <p className={classes.Note}>Note: Do not switch tabs while running The Hypno Virus</p>
                </Aux>
            )
        } else if (this.state.induction2) {
            form = (
                <Aux>
                <Background/>
                    <Sound url={Bell} playStatus={Sound.status.PLAYING} />
                    <FreeRun naming={this.props.names}
                        audio_option={audio_option}
                        induction2={() => this.induction2ChangeHandler()}
                    />
                    <p className={classes.Note}>Note: Do not switch tabs while running The Hypno Virus</p>
                </Aux>
            )
        } else if (this.state.induction3) {
            form = (
                <Aux>
                    <Background/>
                    <Sound url={Bell} playStatus={Sound.status.PLAYING} />
                    <FreeRun naming={this.props.names}
                        audio_option={audio_option}
                        induction3={() => this.induction3ChangeHandler()}
                    />
                    <p className={classes.Note}>Note: Do not switch tabs while running The Hypno Virus</p>
                </Aux>
            )
        } else if (this.state.mantra) {
            form = (
                <Aux>
                    <Background/>
                    <Sound url={Bell} playStatus={Sound.status.PLAYING} />
                    <FreeRun naming={this.props.names}
                        audio_option={audio_option}
                        mantra={() => this.mantraChangeHandler()}
                    />
                    <p className={classes.Note}>Note: Do not switch tabs while running The Hypno Virus</p>
                </Aux>
            )
        } else if (this.state.deepener) {
            form = (
                <Aux>
                    <Background/>
                    <Sound url={Bell} playStatus={Sound.status.PLAYING} />
                    <FreeRun naming={this.props.names}
                        audio_option={audio_option}
                        deepener={() => this.deepenerChangeHandler()}
                    />
                    <p className={classes.Note}>Note: Do not switch tabs while running The Hypno Virus</p>
                </Aux>
            )
        } else if (this.state.trigger) {
            form = (
                <Aux>
                    <Background/>
                    <Sound url={Bell} playStatus={Sound.status.PLAYING} />
                    <FreeRun naming={this.props.names}
                        audio_option={audio_option}
                        trigger={() => this.triggerChangeHandler()}
                    />
                    <p className={classes.Note}>Note: Do not switch tabs while running The Hypno Virus</p>
                </Aux>
            )
        } else if (this.state.awakener) {
            form = (
                <Aux>
                    <Background/>
                    <Sound url={Bell} playStatus={Sound.status.PLAYING} />
                    <FreeRun naming={this.props.names}
                        audio_option={audio_option}
                        awakener={() => this.awakenerChangeHandler()}
                    />
                    <p className={classes.Note}>Note: Do not switch tabs while running The Hypno Virus</p>
                </Aux>
            )
        } else if (this.state.ending) {
            form = (
                <Aux>
                    <Background/>
                    <Sound url={Bell} playStatus={Sound.status.PLAYING} />
                    <FreeRun naming={this.props.names}
                        audio_option={audio_option}
                        ending={() => this.endingChangeHandler()}
                    />
                    <p className={classes.Note}>Note: Do not switch tabs while running The Hypno Virus</p>
                </Aux>
            )
        } else if (this.state.endRun) {
            form = (<Redirect to='/' />)
        }
        
        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            <div className={classes.FreeRun}>
                                {form}

                            </div>
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >
            </Aux >
        )
    }
}

const mapStateToProps = state => {
    return {
        names: state.freeRun.name,
        saved: state.freeRun.saved,
        token: state.auth.token,
        userId: state.auth.userId,
        selectMethod: state.freeRun.selectMethod,
        profileOptions: state.options.profileOptions,
        mantra: state.freeRun.mantra
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onViewProfileOptions: (token, userId) => dispatch(actions.viewProfileOptions(token, userId)),
        onNameSave: (nameData) => dispatch(actions.savingName(nameData)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Freerun)