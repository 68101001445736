import React, { Component } from 'react'
// import Input from '../../../components/UI/Input/Input'
import Button from '../../../components/UI/Button/Button'
import classes from '../Account.module.css'
import Aux from '../../../hoc/auxiliary/auxiliary';
import * as actions from '../../../store/actions/index';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { connect } from 'react-redux'
import TentacleIntroduction from '../../../components/Account/Runs/TriggerIntroduction/Tentacle_Introduction'
import ChloroformIntroduction from '../../../components/Account/Runs/TriggerIntroduction/Chloroform_Introduction'
import MotorFunctionControlIntroduction from '../../../components/Account/Runs/TriggerIntroduction/Motor_Function_Control_Introduction'


class Runs extends Component {

    viewTentacleIntroductionHandler = (event) => {
        event.preventDefault();
        this.props.OnTentacleIntroduction(this.props.token, this.props.userId)
    }

    viewChloroformIntroductionHandler = (event) => {
        event.preventDefault();
        this.props.OnChloroformIntroduction(this.props.token, this.props.userId)
    }

    viewMotorFunctionControlIntroductionHandler = (event) => {
        event.preventDefault();
        this.props.OnMotorFunctionControlIntroduction(this.props.token, this.props.userId)
    }



    ReturnBackHandler = (event) => {
        event.preventDefault();
        this.props.onReturnBack()
    }

    render() {

        let form = null

        if (this.props.viewTriggerIntroduction) {
            form = (<div className={classes.AccountContainer}>
                <form className={classes.ViewProfile}>
                    <h2>Select Run</h2>
                    <div className={classes.AuthButtons}>
                        <Button btnType="Success" clicked={this.viewTentacleIntroductionHandler}>T.E.N.T.A.C.L.E. Introduction <br></br>Details</Button> <br></br>
                        <Button btnType="Success" clicked={this.viewChloroformIntroductionHandler}>Chloroform Introduction <br></br>Details</Button> <br></br>
                        <Button btnType="Success" clicked={this.viewMotorFunctionControlIntroductionHandler}>Motor Function Control Introduction <br></br>Details</Button> <br></br>
                        <Button btnType="Danger" clicked={this.ReturnBackHandler}>Back</Button>
                    </div>
                </form>
            </div>
            )
        }


        if (this.props.viewTentacleIntroduction){
            form = 
                <TentacleIntroduction
                    name = {this.props.accountDetails.name}
                    TentacleIntroduction = {this.props.viewTentacleIntroductionDetailsArray} 
                    ReturnBackHandler = {this.ReturnBackHandler}/>
        }

        if (this.props.viewChloroformIntroduction){
            form = 
                <ChloroformIntroduction
                    name = {this.props.accountDetails.name}
                    ChloroformIntroduction = {this.props.viewChloroformIntroductionDetailsArray} 
                    ReturnBackHandler = {this.ReturnBackHandler}/>
        }

        if (this.props.viewMotorFunctionControlIntroduction){
            form = 
                <MotorFunctionControlIntroduction
                    name = {this.props.accountDetails.name}
                    title = {this.props.title}
                    MotorFunctionControlIntroduction = {this.props.viewMotorFunctionControlIntroductionDetailsArray} 
                    ReturnBackHandler = {this.ReturnBackHandler}/>
        }


        if (this.props.loading) {
            form = <Spinner className={classes.Spinner} />
        }

        let errorMessage = null

        if (this.props.error) {
            errorMessage = (
                <p className={classes.error}>{this.props.error}</p>
            )
        }

        return (
            <Aux>
                <div className={classes.Account}>
                    {errorMessage}
                    {form}
                </div>
            </Aux >

        );
    }
}

const mapStatesToProps = state => {
    return {
        loading: state.runs.loading,
        error: state.auth.error,
        token: state.auth.token,
        userId: state.auth.userId,
        title: state.auth.title,
        accountDetails: state.auth.accountDetails,
        authRedirectValid: state.auth.authRedirectValid,
        viewTriggerIntroduction: state.runs.viewTriggerIntroduction,

        viewTentacleIntroduction: state.runs.viewTentacleIntroduction,
        viewTentacleIntroductionDetailsArray: state.runs.viewTentacleIntroductionDetailsArray,
        
        viewChloroformIntroduction: state.runs.viewChloroformIntroduction,
        viewChloroformIntroductionDetailsArray: state.runs.viewChloroformIntroductionDetailsArray,

        viewMotorFunctionControlIntroduction: state.runs.viewMotorFunctionControlIntroduction,
        viewMotorFunctionControlIntroductionDetailsArray: state.runs.viewMotorFunctionControlIntroductionDetailsArray,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        OnTentacleIntroduction: (token, userId) => dispatch(actions.TentacleIntroduction(token, userId)),
        OnChloroformIntroduction: (token, userId) => dispatch(actions.ChloroformIntroduction(token, userId)),
        OnMotorFunctionControlIntroduction: (token, userId) => dispatch(actions.MotorFunctionControlIntroduction(token, userId)),
        onReturnBack: () => dispatch(actions.returnBackSuccess()),
        onLogout: () => dispatch(actions.logout())
    }
}

export default connect(mapStatesToProps, mapDispatchToProps)(Runs);