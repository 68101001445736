import React, { Component } from 'react';
import Aux from '../../../../hoc/auxiliary/auxiliary';
import classes from '../HypnosisShowMenu.module.scss'
// import classes2 from '../ExperienceMenu/ExperienceMenu.module.scss'
import HypnosisShow from '../../../../assets/Images/Blank.png'
import SusceptibiltyTest from './HypnosisShowNonVolSusceptibiltyTest'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'

class HypnosisShowFront extends Component {

    state = {
        showBegins: true,
        susceptibiltyTest: false
    }

    susceptibiltyTestHandler = () => {
        this.setState({ showBegins: false, susceptibiltyTest: true })
    }


    render() {
        let HypnosisShowForm = null
        if (this.state.showBegins) {
            HypnosisShowForm = (
                <html style={{ backgroundImage: `url(${HypnosisShow})` }}>
                    <div className={classes.storyFrom}>
                        <p>
                            Thinking to yourself for a second <i>"Do I want to volunteer?"</i> <br></br>
                            Hesitant, you take a deep breath but decide against it. It just makes you nervous being up on stage <br></br>
                            The Great O gestures to the volunteers, watching them pass in front of you, going up on stage and taking their seats.<br></br>
                            You look at The Great O as he heads up on stage saying <br></br>
                            <i>“The Great O’s Hypnosis Show is about to begin”</i>
                        </p>
                        <form className={classes.centered} onClick={this.susceptibiltyTestHandler}>
                            <h4 className={classes.storyButtonSize}>The Show Starts</h4>
                        </form>
                    </div>
                </html>
            )
        }
        if (this.state.susceptibiltyTest) {
            HypnosisShowForm = (
                <SusceptibiltyTest />
            )
        }

        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {HypnosisShowForm}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >




        )
    }
}


export default HypnosisShowFront