import React, { Component }  from 'react'
import DefaultTextToSpeech from './DefaultTextToSpeech'
import InputTextToSpeech from './InputTextToSpeech'
import Conversation from './conversations/conversation'
import Button from '../../UI/Button/Button'
import Aux from '../../../hoc/auxiliary/auxiliary'
import classes from '../../../containers/HostsChamber/HostsChamber.module.css'
import { connect } from 'react-redux'
import * as actions from '../../../store/actions/index';

class TextToSpeech extends Component{
    state = {
        defaulttext2speech: false,
        inputtext2speech: false,
        conversation: false
    }

    UNSAFE_componentDidMount() {
        this.props.onViewProfileOptions(this.props.token, this.props.userId)
    }

    enterDefaultText2SpeechHandler = () => {
        this.setState({ defaulttext2speech: true })
    }

    enterInputText2SpeechHandler = () => {
        this.setState({ inputtext2speech: true })
    }

    enterQuestionnaireHandler = () => {
        this.setState({ conversation: true })
    }

    ReturnHandler = () => {
        this.props.onReturnHostsChamber()
    }

    render() {


        let form = null

        form = (
            <Aux>
                <div>
                    <h3>Choose Method, {this.props.title} {this.props.name}</h3>
                    <div >
                        <Button btnType="Success" className={classes.HostChamberButton} clicked={this.enterDefaultText2SpeechHandler}>Predefined Text To Speech</Button><br></br>
                        <Button btnType="Success" className={classes.HostChamberButton} clicked={this.enterInputText2SpeechHandler}>User Input Text To Speech</Button><br></br>
                        <Button btnType="Success" className={classes.HostChamberButton} clicked={this.enterQuestionnaireHandler}>Conversations</Button><br></br>
                        <Button btnType="Danger" clicked={this.ReturnHandler}>Return To Hosts Chamber</Button>
                    </div>
                </div>
            </Aux>
        )


        if (this.state.defaulttext2speech) {
            form = (
                <Aux>
                    <DefaultTextToSpeech />
                </Aux>
            )
        }

        if (this.state.inputtext2speech) {
            form = (
                <Aux>
                    <InputTextToSpeech />
                </Aux>
            )
        }

        if (this.state.conversation) {
            form = (
                <Aux>
                    <Conversation />
                </Aux>
            )
        }


        return form
    }
}

const mapStatesToProps = state => {
    return {
        token: state.auth.token,
        userId: state.auth.userId,
        name: state.auth.accountDetails.name,
        title: state.auth.title,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onViewProfileOptions: (token, userId) => dispatch(actions.viewProfileOptions(token, userId)),
        onReturnHostsChamber: () => dispatch(actions.onReturnHostsChamber()),
    }
}

export default connect(mapStatesToProps, mapDispatchToProps)(TextToSpeech)