import React, { useState } from "react";
import TextTransition from "react-text-transition";
import TypingEffect from 'react-typing-effect'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import Button from '../../../../UI/Button/Button'
import classes from '../../../../../containers/HostsChamber/AudioRuns/AudioRuns.module.css'
import * as scripts from '../../../../../shared/AudioRuns/TriggerIntroduction/TentaclesIntroduction/TentaclesIntroductionScripts'
import * as Delays from '../../../../../shared/AudioRuns/TriggerIntroduction/TentaclesIntroduction/TentaclesIntroductionDelay'
import Opening from '../../../../../assets/audio_runs/Trigger_Introduction/Tentacle_Introduction/Tentacle_Introduction_Opening.mp3'
import Sound from 'react-sound'
import Delay from "react-delay";
import useInterval from 'react-useinterval';
import { connect } from "react-redux";
import {TentacleIntroduction} from '../../../../../store/actions/AudioRuns/audioRunsIndex'
import TentacleObeyOpening from './Tentacle_Obey_Opening'
import TentacleResistOpening from './Tentacles_Resist_Opening'

const DELAY_TEXT = [4200]


function Tentacle_Introduction_Opening({ userId, token, TentacleIntroduction }) {

  let [count, setCount] = useState(0);
  let [tentacles_opening, setProtocol] = useState(true)
  let [tentacle_obey, setObeySequence] = useState(false)
  let [tentacle_resist, setResistSequence] = useState(false)


  const increaseCount = () => {
    if (tentacles_opening && count < scripts.TENTACLES_OPENING_SCRIPT.length) {
      setCount(count + 1);
    }
  };

  useInterval(increaseCount, Delays.TENTACLES_OPENING_DELAY_TIME[count]);

  TentacleIntroduction(userId, token)

  const enterObedienceSequenceHandler = () => {
    setProtocol(tentacles_opening = false)
    setObeySequence(tentacle_obey = true)

  }

  const enterResistanceSequencerHandler = () => {
    setProtocol(tentacles_opening = false)
    setResistSequence(tentacle_resist = true)

  }

  let tentacles_opening_form = null

  if (tentacles_opening) {
    tentacles_opening_form = (
      <Aux>
        {count < scripts.TENTACLES_OPENING_SCRIPT.length ?
          <Aux >
            <Sound url={Opening} playStatus={Sound.status.PLAYING} />
            <Delay wait={DELAY_TEXT[count]}>
              <TextTransition
                text={<TypingEffect typingDelay={100} text={scripts.TENTACLES_OPENING_SCRIPT[count]} speed={90} eraseDelay={1000000} />}
                Overflow
              />
            </Delay>
          </Aux>
          :
    <div className={classes.ButtonPadding}>
      <Button btnType="Success" clicked={enterResistanceSequencerHandler}>I Must Fight!</Button>
      <Button btnType="Success" clicked={enterObedienceSequenceHandler}>Yes, I will Obey...</Button>
    </div>
  } 
      </Aux >
    )
}

if (tentacle_obey){
  tentacles_opening_form = <TentacleObeyOpening />
}

if (tentacle_resist){
  tentacles_opening_form = <TentacleResistOpening />
}

return (
  <Aux>
      {tentacles_opening_form}
  </Aux>
)

}

const mapStateToProps = state => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    TentacleIntroduction: (userId, token) => dispatch(TentacleIntroduction(userId, token)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Tentacle_Introduction_Opening)