import React, { useState } from "react";
import TextTransition from "react-text-transition";
import TypingEffect from 'react-typing-effect'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import Button from '../../../../UI/Button/Button'
import classes from '../../../../../containers/HostsChamber/AudioRuns/AudioRuns.module.css'
import * as scripts from '../../../../../shared/AudioRuns/TriggerReinforcement/TentaclesReinforcement/TentaclesReinforcementScripts'
import * as Delays from '../../../../../shared/AudioRuns/TriggerReinforcement/TentaclesReinforcement/TentaclesReinforcementDelay'
import Edge from '../../../../../assets/audio_runs/Trigger_Reinforcement/Tentacle_Reinforcement/Covert_Commands_Edge.mp3'
import Sound from 'react-sound'
import Delay from "react-delay";
import useInterval from 'react-useinterval';
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom'
import { TentacleReinforcementEnd } from '../../../../../store/actions/AudioRuns/audioRunsIndex'

const DELAY_TEXT = [3500]


function Tentacle_Covert_Edge_Command({ userId, token, Tentacle_Reinforcement_Complete, TentacleReinforcementEnd, profileOptions }) {
  let [count, setCount] = useState(0);
  let [tentacle_covert_edge, setProtocol] = useState(true)


  const increaseCount = () => {
    if (tentacle_covert_edge && count < scripts.COVERT_TENTACLES_EDGE_COMMAND_SCRIPT.length) {
      setCount(count + 1);
    }
  };

  useInterval(increaseCount, Delays.COVERT_EDGE_COMMAND[count]);

  const enterCovertEdgeEndSequenceHandler = () => {
    setProtocol(tentacle_covert_edge = false)
    TentacleReinforcementEnd(userId, token, 0, 0, 0, 0, 1, 0)
  }

  let edgeCount = Math.floor(Math.random() * 5) + 1
  let edgeMantra = Math.floor(Math.random() * 3) + 1
  let edgeOutfit = Math.floor(Math.random() * 4) + 1
  let edgeDeadline = Math.floor(Math.random() * 10) + 3

  let mantra = ''
  if (edgeMantra === 1) {
    // eslint-disable-next-line
    mantra = "'No Thoughts'\n\
              'No Will'\n\
              'I Obey What The Hypno Virus Say'"
  } else if (edgeMantra === 2) {
    // eslint-disable-next-line
    mantra = "'Hosts serve'\n\
              'Hosts please'\n\
              'Hosts obey'\n\
              'I am a Host for The Hypno Virus'"
  } else if (edgeMantra === 3) {
    // eslint-disable-next-line
    mantra = "'I am brainless'\n\
              'I am mindlless'\n\
              'I must edge'\n\
              'I must obey what The Hypno Virus say'"
  }


  let centerText = window.innerHeight / 2 - 55
  let texting2 = null
  if (profileOptions.audio_run_background === '0'){
    texting2 = {

      // display: 'flex',
      // flexFlow: 'column',
      whiteSpace: 'pre-line',
      alignItems: 'center',
      fontWeight: 'bold',
      boxSizing: "border-box",
      margin: 'auto',
      marginBottom: '10px',
      padding: '30px 10px',
      width: '50%',
      paddingTop: centerText + 'px',
      color: 'white'
  
    }
  }

  let outfit = ''
  if (edgeOutfit === 1) {
    outfit = 'Fully Clothed'
  } else if (edgeOutfit === 2) {
    outfit = 'Topless'
  } else if (edgeOutfit === 3) {
    outfit = 'Bottomless'
  } else if (edgeOutfit === 4) {
    outfit = 'Naked'
  }
  let command = (
    <div style={texting2}>
      <h3>Covert Edging Command</h3>
      <p><TypingEffect typingDelay={100} text={'Count: ' + edgeCount} speed={90} eraseDelay={1000000} /></p>
      <p><TypingEffect typingDelay={100} text={'Mantra: ' + mantra} speed={90} eraseDelay={1000000} /></p>
      <p><TypingEffect typingDelay={100} text={'Outfit: ' + outfit} speed={90} eraseDelay={1000000} /></p>
      <p><TypingEffect typingDelay={100} text={'Deadlines: ' + edgeDeadline + " Hours"} speed={90} eraseDelay={1000000} /></p>
      <br></br>
      <div className={classes.ButtonPadding}>
        <Delay wait={9500}>
          {scripts.BellSound}
        </Delay>
        <Delay wait={10500}>
          <Button btnType="Success" clicked={enterCovertEdgeEndSequenceHandler}>This Host will edge its mind away</Button>
        </Delay>
      </div>
    </div>
  )

  let texting = null
  if (profileOptions.audio_run_background === '0'){
    texting = {

      // display: 'flex',
      // flexFlow: 'column',
      whiteSpace: 'pre-line',
      alignItems: 'center',
      fontWeight: 'bold',
      boxSizing: "border-box",
      margin: 'auto',
      marginBottom: '10px',
      padding: '30px 10px',
      width: '50%',
      paddingTop: centerText + 'px',
      color: 'white'
  
    }
  }

  let tentacles_covert_edge_command_form = null

  if (tentacle_covert_edge) {
    tentacles_covert_edge_command_form = (
      <Aux>
        {count < scripts.COVERT_TENTACLES_EDGE_COMMAND_SCRIPT.length ?
          <div style={texting}>
            <Sound url={Edge} playStatus={Sound.status.PLAYING} />
            <Delay wait={DELAY_TEXT[count]}>
              <TextTransition
                text={<TypingEffect typingDelay={100} text={scripts.COVERT_TENTACLES_EDGE_COMMAND_SCRIPT[count]} speed={90} eraseDelay={1000000} />}
                Overflow
              />
            </Delay>
          </div>
          :
          <div>
            {command}
          </div>
        }
      </Aux >
    )
  }

  if (Tentacle_Reinforcement_Complete) {
    tentacles_covert_edge_command_form = <Redirect to="/" />
  }

  return (
    <Aux>
      {tentacles_covert_edge_command_form}
    </Aux>
  )

}

const mapStateToProps = state => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    Tentacle_Reinforcement_Complete: state.tentacleReinforce.Tentacle_Reinforcement_Complete, 
    profileOptions: state.options.profileOptions,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    TentacleReinforcementEnd: (userId, token, daily_pleasure, daily_spank, daily_electric_spank, covert_fullday, covert_edging, covert_body_writing) => dispatch(TentacleReinforcementEnd(userId, token, daily_pleasure, daily_spank, daily_electric_spank, covert_fullday, covert_edging, covert_body_writing)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tentacle_Covert_Edge_Command)