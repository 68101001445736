import React, { useState } from "react";
import TextTransition from "react-text-transition";
import TypingEffect from 'react-typing-effect'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import Button from '../../../../UI/Button/Button'
import classes from '../../../../../containers/HostsChamber/AudioRuns/AudioRuns.module.css'
import * as scripts from '../../../../../shared/AudioRuns/TriggerIntroduction/TentaclesIntroduction/TentaclesIntroductionScripts'
import * as Delays from '../../../../../shared/AudioRuns/TriggerIntroduction/TentaclesIntroduction/TentaclesIntroductionDelay'
import Ending from '../../../../../assets/audio_runs/Trigger_Introduction/Tentacle_Introduction/Tentacle_Obey_Ending.mp3'
import Sound from 'react-sound'
import Delay from "react-delay";
import useInterval from 'react-useinterval';
import { connect } from "react-redux";
import {Redirect} from 'react-router-dom'
import {TentacleIntroductionEnd} from '../../../../../store/actions/AudioRuns/audioRunsIndex'

const DELAY_TEXT = [4000]


function Trigger_Obey_Ending({ userId, token, TentacleIntroductionEnd, Tentacle_introductionComplete }) {
  let [count, setCount] = useState(0);
  let [tentacle_ending, setProtocol] = useState(true)


  const increaseCount = () => {
    if (tentacle_ending && count < scripts.TENTALCE_OBEY_END_SCRIPT.length) {
      setCount(count + 1);
    }
  };

  useInterval(increaseCount, Delays.TENTACLES_OBEY_ENDING_DELAY_TIME[count]);

  const endSequenceHandler = () => {
    setProtocol(tentacle_ending = false)
    TentacleIntroductionEnd(userId, token, 1, 0)
  }

  

  let tentacles_obey_ending_form = null

  if (tentacle_ending) {
    tentacles_obey_ending_form = (
      <Aux>
        {count < scripts.TENTALCE_OBEY_END_SCRIPT.length ?
          <Aux >
            <Sound url={Ending} playStatus={Sound.status.PLAYING} />
            <Delay wait={DELAY_TEXT[count]}>
              <TextTransition
                text={<TypingEffect typingDelay={100} text={scripts.TENTALCE_OBEY_END_SCRIPT[count]} speed={90} eraseDelay={1000000} />}
                Overflow
              />
            </Delay>
          </Aux>
          :
    <div className={classes.ButtonPadding}>
      <Button btnType="Success" clicked={endSequenceHandler}>The T.E.N.T.A.C.L.E Unit has taken control</Button>
    </div>
  }
      </Aux >
    )
}

if(Tentacle_introductionComplete){
    tentacles_obey_ending_form = <Redirect to="/" />
}

return (
  <Aux>
      {tentacles_obey_ending_form}
  </Aux>
)

}

const mapStateToProps = state => {
    return {
      userId: state.auth.userId,
      token: state.auth.token,
      Tentacle_introductionComplete: state.tentacleIntro.Tentacle_introductionComplete
    }
}

const mapDispatchToProps = dispatch => {
    return {
        TentacleIntroductionEnd: (userId, token, obedience_sequence, resistance_sequence) => dispatch(TentacleIntroductionEnd(userId, token, obedience_sequence, resistance_sequence)),
    }
  }


export default connect(mapStateToProps, mapDispatchToProps)(Trigger_Obey_Ending)