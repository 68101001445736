import React from 'react'
import classes from '../../../../containers/Account/Account.module.css'
// import Input from '../../UI/Input/Input'
import Button from '../../../UI/Button/Button'
import Aux from '../../../../hoc/auxiliary/auxiliary'

const tentacleReinforcement = (props) => {
    
    let tentacleReinforcementForm = (
        <Aux>
            <div className={classes.AccountContainer}>
                <form className={classes.FormStyle}>
                    <h2>T.E.N.T.A.C.L.E. Reinforcement Run Details</h2>
                    <p className={classes.smallText}>Let Our T.E.N.T.A.C.L.E. unit control you, {props.name}</p>
                    <div>
                        <p><strong>T.E.N.T.A.C.L.E. Reinforcement Run: {props.TentacleReinforcement.Tentacle_Reinforcement}</strong></p>
                    </div>
                    <div>
                        <h3>Daily Commands</h3>
                        <div>
                            <p><strong>Conditioning Peasure: {props.TentacleReinforcement.daily_pleasure} </strong></p>
                        </div>
                        <div>
                            <p><strong>Spank Conditioning: {props.TentacleReinforcement.daily_spank} </strong></p>
                        </div>
                        <div>
                            <p><strong>Electric Spank Conditioning: {props.TentacleReinforcement.daily_electric_spank} </strong></p>
                        </div>
                    </div>
                    <div>
                        <h3>Covert Conditioning</h3>
                        <div>
                        <p><strong>Fullday Conditioning: {props.TentacleReinforcement.covert_fullday}</strong></p>
                    </div>
                    <div>
                        <p><strong>Edging Conditioning: {props.TentacleReinforcement.covert_edging}</strong></p>
                    </div>
                    <div>
                        <p><strong>Body Writing Conditioning: {props.TentacleReinforcement.covert_body_writing}</strong></p>
                    </div>
                    </div>
                   
                </form>
                <div className={classes.AuthButtons}>
                    <Button btnType="Danger" clicked={props.ReturnBackHandler}>Back</Button>
                </div>

            </div>
        </Aux>)


    return tentacleReinforcementForm
}

export default tentacleReinforcement