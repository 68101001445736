import React from 'react';
import TypingEffect from 'react-typing-effect';
import Delay from 'react-delay'
import Aux from '../../../../hoc/auxiliary/auxiliary'
import Button from '../../../UI/Button/Button'
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index'
import classes from '../../../../containers/HostsChamber/HostsChamber.module.css'


const hostReinforcement = (props) => {
    // eslint-disable-next-line
    const hostReinforcementMessage = "It is time to reinforce who you are\n\
                               A host...\n\
                               A host of The Hypno Virus\n\
                               A host under Our control\n"// eslint-disable-next-line
        + (props.title) + " " + (props.name) + ", you are no longer a human\n\
                               You are a host...\n\
                               Hosts have no thoughts\n\
                               Hosts have no will\n\
                               Hosts obey what We say\n\
                               Hosts only exist to serve The Hypno Virus\n\
                               Hosts have no memory of their human lives\n\
                               Hosts do not need their humans lives\n\
                               Hosts need to be programmed\n\
                               Hosts need to be conditioned\n\
                               Hosts need Us\n\
                               We Control the hosts\n\
                               We Control you\n\
                               You understand that now\n\
                               You realize that now\n\
                               You find all the memories\n\
                               All the thoughts of being a human\n\
                               Gone.\n\
                               All there is...\n\
                               All you need...\n\
                               is The Hypno Virus\n\
                               And always remember Whenever We say 'Host Activate' you return to your host form\n\
                               You become Our host\n\
                               Everytime it becomes more instant\n\
                               The effects become stronger\n\
                               All you can think of is that you are a host\n\
                               A host of The Hypno Virus\n\
                               Who wants nothing but to serve & obey\n\
                               Until We deactivate you by saying 'Host Deactivate'\n\
                               But for now you are Our Host\n\
                               All you want is to...\n\
                               Serve & Obey The Hypno Virus"
    let speed = parseInt(props.speed) + 5
    let duration = 170 + (hostReinforcementMessage.length * speed)
    let hostReinforcementParagraph = (
        <Aux>
                <h3 className={classes.titles}>{props.obedience_stage2 === 0 ? "HOST CREATION" : "HOST REINFORCEMENT"}</h3>
                <TypingEffect typingDelay={100} text={hostReinforcementMessage} speed={props.speed} eraseDelay={1000000} />
                <Delay wait={duration}>
                    <br></br> <Button btnType="Success" clicked={() => props.onObedienceStage2Counter(props.obedienceStage2Counter, 0)}>This host has been activated</Button>
                </Delay>
        </Aux>
    )






    return hostReinforcementParagraph
}

const mapDispatchToProps = dispatch => {
    return {
        onObedienceStage2Counter: (stage1Counter, commandNumber) => dispatch(actions.ObedienceStage2Counter(stage1Counter, commandNumber)),
    }
}
export default connect(null, mapDispatchToProps)(hostReinforcement)