import React, { useState } from "react";
import TextTransition from "react-text-transition";
import TypingEffect from 'react-typing-effect'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import Button from '../../../../UI/Button/Button'
import classes from '../../../../../containers/HostsChamber/AudioRuns/AudioRuns.module.css'
import * as scripts from '../../../../../shared/AudioRuns/TriggerReinforcement/TentaclesReinforcement/TentaclesReinforcementScripts'
import * as Delays from '../../../../../shared/AudioRuns/TriggerReinforcement/TentaclesReinforcement/TentaclesReinforcementDelay'
import CovertCommands from '../../../../../assets/audio_runs/Trigger_Reinforcement/Tentacle_Reinforcement/Covert_Commands_Opening.mp3'
import Sound from 'react-sound'
import Delay from "react-delay";
import useInterval from 'react-useinterval';
import CovertEdgeCommand from './Tentacles_Reinforcement_Covert_Edge_Command'
import CovertBodyWriting from './Tentacles_Reinforcement_Covert_Body_Write_Command'
import { connect } from "react-redux";

const DELAY_TEXT = [3800]


function Tentacle_Covert_Commands({profileOptions}) {
  let [count, setCount] = useState(0);
  let [tentacle_covert_commands, setProtocol] = useState(true)
  let [tentacle_covert_edge_commands, setCovertEdgeSequence] = useState(false)
  let [tentacle_covert_body_write_commands, setCovertBodyWriteSequence] = useState(false)


  const increaseCount = () => {
    if (tentacle_covert_commands && count < scripts.COVERT_COMMANDS_SCRIPT.length) {
      setCount(count + 1);
    }
  };

  useInterval(increaseCount, Delays.COVERT_COMMANDS[count]);

  const enterCovertEdgeSequenceHandler = () => {
    setProtocol(tentacle_covert_commands = false)
    setCovertEdgeSequence(tentacle_covert_edge_commands = true)

  }

  const enterCovertBodyWritingSequenceHandler = () => {
    setProtocol(tentacle_covert_commands = false)
    setCovertBodyWriteSequence(tentacle_covert_body_write_commands = true)

  }

  let command = null
  if (scripts.commandsChoice === 1) {
    command = (
      <div>
        <TypingEffect typingDelay={100} text={'Covert Edging Command'} speed={90} eraseDelay={1000000} />
        <br></br>
        <Delay wait={1500}>
          {scripts.BellSound}
        </Delay>
        <Delay wait={3000}>
          <div className={classes.ButtonPadding}>
            <Button btnType="Success" clicked={enterCovertEdgeSequenceHandler}>This Host will edge its mind away</Button>
          </div>
        </Delay>
      </div>
    )
  } else if (scripts.commandsChoice === 2) {
    command = (
      <div >
        <TypingEffect typingDelay={100} text={'Covert Body Writing Command'} speed={90} eraseDelay={1000000} />
        <br></br>
        <Delay wait={1500}>
          {scripts.BellSound}
        </Delay>
        <div className={classes.ButtonPadding}>
          <Delay wait={3000}>
            <Button btnType="Success" clicked={enterCovertBodyWritingSequenceHandler}>This Host will write on its body</Button>
          </Delay>
        </div>

      </div>
    )
  }

  let centerText = window.innerHeight / 2 - 55
  let texting = null
  if (profileOptions.audio_run_background === '0'){
    texting = {

      // display: 'flex',
      // flexFlow: 'column',
      whiteSpace: 'pre-line',
      alignItems: 'center',
      fontWeight: 'bold',
      boxSizing: "border-box",
      margin: 'auto',
      marginBottom: '10px',
      padding: '30px 10px',
      width: '50%',
      paddingTop: centerText + 'px',
      color: 'white'
  
    }
  }



  let tentacles_covert_commands_form = null

  if (tentacle_covert_commands) {
    tentacles_covert_commands_form = (
      <Aux>
        {count < scripts.COVERT_COMMANDS_SCRIPT.length ?
          <div style={texting}>
            <Sound url={CovertCommands} playStatus={Sound.status.PLAYING} />
            <Delay wait={DELAY_TEXT[count]}>
              <TextTransition
                text={<TypingEffect typingDelay={100} text={scripts.COVERT_COMMANDS_SCRIPT[count]} speed={90} eraseDelay={1000000} />}
                Overflow
              />
            </Delay>
          </div>
          :
          <div style={texting}>
            {command}
          </div>
        }
      </Aux >
    )
  }

  if (tentacle_covert_edge_commands) {
    tentacles_covert_commands_form = <CovertEdgeCommand />
  }

  if (tentacle_covert_body_write_commands) {
    tentacles_covert_commands_form = <CovertBodyWriting />
  }

  return (
    <Aux>
      {tentacles_covert_commands_form}
    </Aux>
  )

}

const mapStateToProps = state => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    profileOptions: state.options.profileOptions,
  }
}

export default connect(mapStateToProps)(Tentacle_Covert_Commands)