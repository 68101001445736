import React from 'react';
import TypingEffect from 'react-typing-effect';
import Delay from 'react-delay'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import classes from '../../HostsRunComponent.module.css'


const triggerCommand = (props) => {
    // let praiseChoice = 0
    // praiseChoice = Math.floor(Math.random() * props.praises.length);
    // let praise = props.praises[praiseChoice]
    
    // eslint-disable-next-line
    const triggerCommandMessage = "The Hypno Virus Took Over\n\
                                   The more you see it\n\
                                   The deeper you go\n\
                                   Now it is time for your to obey\n\
                                   It is time for you to obey Us\n\
                                   It is time for you to obey Us like a " + // eslint-disable-next-line
                                   (props.title) + "\n" + // eslint-disable-next-line
                                   (props.naming) + ', you will share your experience\n\
                                   The Hypno Virus Took Over\n\
                                   The Hypno Virus commands you to share your experience\n\
                                   The Hypno Virus commands you to tell everyone you trust and feel safe to tell about your experience\n\
                                   The Hypno Virus Took Over and now is the time to obey Us\n\
                                   We control your mind\n\
                                   We control your body\n\
                                   We control you' 
    let speed = parseInt(props.speed) +5
    let  duration = 150 + (triggerCommandMessage.length * speed)
    let triggerCommandParagraph = (
            <Aux>
                <h3 className= {classes.titles}>TRIGGER COMMAND</h3>
                <TypingEffect typingDelay={5000} text={triggerCommandMessage} speed={props.speed} eraseDelay={1000000} />
                <Delay wait={duration+5000}><h4 className={classes.blinking} onClick={props.triggerCommand}>I Obey What The Hypno Virus Say</h4></Delay>
            </Aux>
        )


    return triggerCommandParagraph
}


export default triggerCommand