import React from 'react';
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'
import Sound from 'react-sound'
import classes from '../Visual.module.css'
import Bell from '../../../assets/audio/bell.mp3'
import Mantra from '../../../assets/audio/mantralong.mp3'
import Metronome from '../../../assets/audio/Metronome2.mp3'
import Ticking from '../../../assets/audio/Ticking3.mp3'
import MantraVoice from '../../../assets/audio/MantraVoice.mp3'
import WordText from '../TextAddition'

function ColorVisual(props) {

  let innerWidth = window.innerWidth
  let innerHeight = window.innerHeight
  const canvasRef = React.useRef(null)

  React.useEffect(() => {
    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')
    let cw = window.innerWidth
    let ch = window.innerHeight
    let tick = 0
    let opt = {
      count: 5,
      range: {
        x: 20,
        y: 80
      },
      duration: {
        min: 20,
        max: 40
      },
      thickness: -1,
      strokeColor: '#444',
      level: .35,
      curved: true
    },
    rand = function(min, max){
      return Math.floor( (Math.random() * (max - min + 1) ) + min);
    },
    ease = function (t, b, c, d) {
      if ((t/=d/2) < 1) return c/2*t*t + b;
      return -c/2 * ((--t)*(t-2) - 1) + b;
    };

    ctx.lineJoin = 'round';
    ctx.strokeStyle = opt.strokeColor;

    var Point = function(config){
      this.anchorX = config.x;
      this.anchorY = config.y;
      this.x = config.x;
      this.y = config.y;
      this.setTarget();  
    };

    Point.prototype.setTarget = function(){
      this.initialX = this.x;
      this.initialY = this.y;
      this.targetX = this.anchorX + rand(0, opt.range.x * 2) - opt.range.x;
      this.targetY = this.anchorY + rand(0, opt.range.y * 2) - opt.range.y;
      this.tick = 0;
      this.duration = rand(opt.duration.min, opt.duration.max);
    }
      
    Point.prototype.update = function(){
      var dx = this.targetX - this.x;
      var dy = this.targetY - this.y;
      var dist = Math.sqrt(dx * dx + dy * dy);
      
      if(Math.abs(dist) <= 0){
        this.setTarget();
      } else {       
        var t = this.tick;
        var b = this.initialY;
        var c = this.targetY - this.initialY;
        var d = this.duration;
        this.y = ease(t, b, c, d);
        
        b = this.initialX;
        c = this.targetX - this.initialX;
        d = this.duration;
        this.x = ease(t, b, c, d);
      
        this.tick++;
      }
    };
      

    var renderShape = function(){
      ctx.beginPath();
      ctx.moveTo(0,0);	  
      ctx.closePath();   
      ctx.fillStyle = 'hsl('+(tick/2)+', 80%, 60%)';
      ctx.fillRect(0,0,innerWidth, innerHeight);  
      ctx.stroke();
    };

    var clear = function(){
      ctx.clearRect(0, 0, cw, ch);
    };

    var loop = function(){
      window.requestAnimFrame(loop, ctx);
      tick++;
      clear();
      renderShape();
    };

      window.requestAnimFrame=function(){return window.requestAnimationFrame||window.webkitRequestAnimationFrame||window.mozRequestAnimationFrame||window.oRequestAnimationFrame||window.msRequestAnimationFrame||function(a){window.setTimeout(a,1E3/60)}}();

      loop();

  })

  
  let soundComponent = null
  switch (props.sound) {
    case '1': soundComponent = <Sound url={Bell} playStatus={Sound.status.PLAYING} loop={true} />
      break;
    case '2': soundComponent = <Sound url={Metronome} playStatus={Sound.status.PLAYING} loop={true} />
      break;
    case '3': soundComponent = <Sound url={Ticking} playStatus={Sound.status.PLAYING} loop={true} />
      break;
    case '4': soundComponent = <Sound url={Mantra} playStatus={Sound.status.PLAYING} loop={true} />
      break;
    case '5': soundComponent = <Sound url={MantraVoice} playStatus={Sound.status.PLAYING} loop={true} />
      break;
    default: soundComponent = null
  }

  let divStyle
  divStyle = {
    fontSize: "48px",
    fontFamily: "Arial",
    background: 'transpartent',
    position: 'fixed',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    zIndex: '997',
    textAlign: 'center',
    // display: 'visible'


  }



  return (
    <Fullpage >
      <FullPageSections>
        < FullpageSection>
          <canvas
            ref={canvasRef}
            width={innerWidth}
            height={innerHeight}
          >
            {soundComponent}
          </canvas>
          <div style={divStyle}>
          <p className ={classes.returnText} onClick={props.returnHandler}>Return to Customization</p>
          <WordText text_color = {props.text_color}
                    text_speed ={props.text_speed} 
                    conditioning_text={props.conditioning_text}
                    arousal_check={props.arousal_check}
                    submissive_check = {props.submissive_check}/>
          </div>
        </FullpageSection>
      </FullPageSections>
    </Fullpage>
  );
}

export default ColorVisual;
