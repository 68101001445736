import * as audioRunTypes from '../../../actions/AudioRuns/audioRunTypes';
import { updateObject } from '../../../../shared/utility';

const initialState = {
    error: null,
    Chloroform_introductionComplete: false,
    Chloroform_Room : false,
    Forced_Chloroform : false,
    Chloroform_introductionCommands: [],
    loading: false,
}

const chloroformIntroductionStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true
    })
}

const chlroformIntroductionSuccess = (state, action) => {
        return updateObject(state, {
            Chloroform_introductionComplete: false,
            Chloroform_introductionCommands: action.Chloroform_introduction,
            loading: false,
        })
}

const chloroformIntroductionFailed = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    })
}

const chloroformRoomSuccess = (state, action) => {
    return updateObject(state, {
        chloroformRoom: true,
        loading: false
    })
}

const forcedChloroformSuccess = (state, action) => {
    return updateObject(state, {
        Forced_Chloroform: true,
        loading: false
    })
}

const chlroformIntroductionComplete = (state, action) => {
    return updateObject(state, {
        error: null,
        Chloroform_introductionComplete: true,
        chloroformRoom: false,
        Forced_Chloroform: false,
        Chloroform_introductionCommands: [],
        loading: false,
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case audioRunTypes.AUDIO_RUN_START: return chloroformIntroductionStart(state, action)
        case audioRunTypes.AUDIO_RUN_FAILED: return chloroformIntroductionFailed(state, action)
        case audioRunTypes.CHLOROFORM_INTRODUCTION_SUCCESS: return chlroformIntroductionSuccess(state,action)
        case audioRunTypes.CHLOROFORM_ROOM_INTRODUCTION: return chloroformRoomSuccess(state,action)
        case audioRunTypes.FORCED_CHLOROFORM_INTRODUCTION: return forcedChloroformSuccess(state,action)
        case audioRunTypes.CHLOROFORM_INTRODUCTION_COMPLETED: return chlroformIntroductionComplete(state, action)
        default:
            return state

    }
}


export default reducer