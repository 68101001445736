import React, { Component } from 'react';
import Aux from '../../../../hoc/auxiliary/auxiliary';
import classes from '../HypnosisShowMenu.module.scss'
// import classes2 from '../ExperienceMenu/ExperienceMenu.module.scss'
import HypnosisShow from '../../../../assets/Images/Blank.png'
import Snap from './HypnosisShowNonVolSnap1'
import ForeheadTap from './HypnosisShowNonVolForeheadTap'
import HypnoGun from './HypnosisShowNonVolHypnoGun'
import SelfSnap from './HypnosisShowNonVolSelfSnap'
import WakeUp from './HypnosisShowNonVolWakeUp'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'

class HypnosisShowSuggestionChoices extends Component {

    state = {
        suggestionsChoices: true,
        fingerSnap: false,
        foreheadTap: false,
        hypnoGun: false,
        countdownSnap: false,
        wakeUp: false
    }

    fingerSnapHandler = () => {
        this.setState({ suggestionsChoices: false, fingerSnap: true })
    }

    foreheadTapHandler = () => {
        this.setState({ suggestionsChoices: false, foreheadTap: true })
    }

    hypnoGunHandler = () => {
        this.setState({ suggestionsChoices: false, hypnoGun: true })
    }

    countdownSnapHandler = () => {
        this.setState({ suggestionsChoices: false, countdownSnap: true })
    }

    wakeUpHandler = () => {
        this.setState({ suggestionsChoices: false, wakeUp: true })
    }


    render() {
        let HypnosisShowForm = null
        if (this.state.suggestionsChoices) {
            HypnosisShowForm = (
                <html style={{ backgroundImage: `url(${HypnosisShow})` }}>
                    <div className={classes.storyFrom}>
                        <p>
                        <i>“Ready for some amazing experiences?”</i><br></br> 
                        He asks as you nod enthusiastically excited for what he has in store for you. <br></br> 
                        <i>“In a moment I will wake you up, we have a nice chat and talk. Everything is normal & ordinary, you feeling very good, happy, relaxed & comfortable. 
                        It's all pretty normal that is until I…” </i>
                        </p>
                        <form className={classes.centered} onClick={this.fingerSnapHandler}>
                            <h4 className={classes.storyButtonSize}>Snap my Fingers</h4>
                        </form>
                        <form className={classes.centered} onClick={this.foreheadTapHandler}>
                            <h4 className={classes.storyButtonSize}>Tap your Forehead</h4>
                        </form>
                        <form className={classes.centered} onClick={this.hypnoGunHandler}>
                            <h4 className={classes.storyButtonSize}>Use my Hypno Gun</h4>
                        </form>
                        <form className={classes.centered} onClick={this.countdownSnapHandler}>
                            <h4 className={classes.storyButtonSize}>Make you Countdown & Snap your Fingers</h4>
                        </form>
                        <form className={classes.centered} onClick={this.wakeUpHandler}>
                            <h4 className={classes.storyButtonSize}>Wake you Up</h4>
                        </form>
                    </div>
                </html>
            )
        }
        if (this.state.fingerSnap) {
            HypnosisShowForm = (
                <Snap />
            )
        }

        if (this.state.foreheadTap) {
            HypnosisShowForm = (
                <ForeheadTap />
            )
        }

        if (this.state.hypnoGun) {
            HypnosisShowForm = (
                <HypnoGun />
            )
        }

        if (this.state.countdownSnap) {
            HypnosisShowForm = (
                <SelfSnap />
            )
        }

        if (this.state.wakeUp) {
            HypnosisShowForm = (
                <WakeUp />
            )
        }

        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {HypnosisShowForm}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >




        )
    }
}


export default HypnosisShowSuggestionChoices