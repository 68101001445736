import * as actionTypes from '../../actions/Account/authTypes';
import { updateObject } from '../../../shared/utility';

const initialState = {
    profileOptions: [],
    viewOptions: false,
    loading: false,
    error: null
}

const Options_Success = (state, action) => {
    return updateObject(state, {
        loading: true,
        error: null
    })
}

const Options_Fail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error
    })
}


const viewOptions = (state, action) => {
    return updateObject(state, {
        viewOptions: true,
        loading: false,
        error: null,
    })
}

const viewProfileOptions = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: null,
        profileOptions: action.profile_options
    })
}

const editProfileOptions = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: null,
        profileOptions: action.profile_options,
        viewOptions: false
    })
}



const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.VIEW_OPTIONS: return viewOptions(state, action)
        case actionTypes.VIEW_OPTIONS_SUCCESS: return Options_Success(state, action)
        case actionTypes.VIEW_OPTIONS_FAIL: return Options_Fail(state, action)
        case actionTypes.VIEW_PROFILE_OPTIONS: return viewProfileOptions(state, action)
        case actionTypes.EDIT_PROFILE_OPTIONS: return editProfileOptions(state, action)
        default:
            return state

    }
}


export default reducer