import React from 'react'
import classes from '../../../../../containers/Account/Account.module.css'
// import Input from '../../UI/Input/Input'
import Button from '../../../../UI/Button/Button'
import Aux from '../../../../../hoc/auxiliary/auxiliary'

const obedienceStage2 = (props) => {
    let obedienceStage2Form = (
        <Aux>
            <div className={classes.AccountContainer}>
                    <form className={classes.FormStyle}>
                        <h2>Obedience Stage 2 Run Details</h2>
                        <p className={classes.smallText}>Becoming The Perfect Host, {props.name}</p>
                        <div>
                            <p><strong>Obedience Stage 2: {props.viewObedienceStage2.obedience_stage2}</strong></p>
                        </div>
                        <div>
                            <p><strong>Host Reinforcement: {props.viewObedienceStage2.host_reinforcement_command} </strong></p>
                        </div>
                        <div>
                            <p><strong>Host Speech: {props.viewObedienceStage2.host_speech_command}</strong></p>
                        </div>
                        <div>
                            <p><strong>Host Suit: {props.viewObedienceStage2.host_suit_command}</strong></p>
                        </div>
                        <div>
                            <p><strong>Host Pleasure: {props.viewObedienceStage2.host_pleasure_command}</strong></p>
                        </div>
                        <div>
                            <p><strong>Host Infection: {props.viewObedienceStage2.host_infection_command}</strong></p>
                        </div>

                        

                    </form>
                    <div className={classes.AuthButtons}>
                        <Button btnType="Danger" clicked={props.ReturnBackHandler}>Back</Button>
                    </div>

                </div>
        </Aux>)


    return obedienceStage2Form
}

export default obedienceStage2