import React from 'react'
import classes from '../../../../containers/Account/Account.module.css'
// import Input from '../../UI/Input/Input'
import Button from '../../../UI/Button/Button'
import Aux from '../../../../hoc/auxiliary/auxiliary'

const motorfunctionControlIntroduction = (props) => {
    let motorfunctionControlIntroductionForm = (
        <Aux>
            <div className={classes.AccountContainer}>
                    <form className={classes.FormStyle}>
                        <h2>Motor Function Control  Introduction Run Details</h2>
                        <p className={classes.smallText}>We control your mind & body, {props.title} {props.name}</p>
                        <div>
                            <p><strong>Motor Function Control Introduction Run: {props.MotorFunctionControlIntroduction.Motor_Function_Control_Introduction}</strong></p>
                        </div>
                        <div>
                            <p><strong>Instant Control: {props.MotorFunctionControlIntroduction.instant_control} </strong></p>
                        </div>
                        <div>
                            <p><strong>Slow Control: {props.MotorFunctionControlIntroduction.slow_control}</strong></p>
                        </div>
                        <div>
                            <p><strong>Mental Control: {props.MotorFunctionControlIntroduction.mental_control}</strong></p>
                        </div>
                    </form>
                    <div className={classes.AuthButtons}>
                        <Button btnType="Danger" clicked={props.ReturnBackHandler}>Back</Button>
                    </div>

                </div>
        </Aux>)


    return motorfunctionControlIntroductionForm
}

export default motorfunctionControlIntroduction