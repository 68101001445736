import React from 'react';
import Delay from 'react-delay'
import Aux from '../../../../hoc/auxiliary/auxiliary'
import classes from '../../../../containers/FreeRun/MusicConditioning/MusicConditioning.module.css'
import Sound from 'react-sound'
import Bell from '../../../../assets/audio/bell.mp3'

const bellChoice = (props) => {
    let hostName = props.name.toUpperCase()
    let bellChoice = null

    let mantras = ["WE CONTROL YOU, " + hostName
                    ,"YOU LOVE TO OBEY US, " + hostName
                    ,"YOU BELONG TO US, " + hostName
                    ,"WE COMMAND... YOU OBEY"
                    ,"YOU WANT TO SERVE THE HYPNO VIRUS"
                    ,"IT FEELS RIGHT TO OBEY US, "+ hostName
                    ,"WE ARE ALWAYS WATCHING YOU, "+hostName
                    ,"OBEYING US IS ALL YOU DESIRE, " + hostName
                    ,hostName + ', YOU LIVE TO PLEASE THE HYPNO VIRUS'
                    ,hostName + ', YOU OBEY WHAT WE SAY']
    let bellMantra = []
    for (let key=0; key < mantras.length; key++){
        bellMantra.push({
            id: key,
            config: mantras[key]})
    }
    // eslint-disable-next-line
    if (props.bellChoiceProp){
        bellChoice = (
            <Aux>
            <Delay wait={5000}>{bellMantra.map(bell => (
            <Delay key={bell.id} wait = {bell.id*5000}>
            <h3>{bellMantra[bell.id].config}</h3>
            </Delay>
            ))}</Delay>        
            </Aux>
        )
    } else{
        bellChoice = null
    }
    
    return (
        <div className={classes.BellChoice}> 
            <Sound url={Bell} playStatus={Sound.status.PLAYING} loop={true} />
            {bellChoice}
        </div>
        
    )
}


export default bellChoice
