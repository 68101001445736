import React from 'react';
import TypingEffect from 'react-typing-effect';
import Delay from 'react-delay'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
// import Sound from 'react-sound'
// import Bell from '../../assets/audio/bell.mp3'
import classes from '../../FreerunComponent.module.css'

const induction2 = (props) => {
    // eslint-disable-next-line
    const induction2 = 'Of course you can, ' + (props.naming) + "\n\
                        It is so easy to get lost in Our words\n\
                        Get lost in everything We say\n\
                        Just reading Our words you can not help but agree\n\
                        You know it is the truth\n\
                        What We say is the truth\n\
                        You feel your thoughts fading away\n\
                        You feel yourself slipping down\n\
                        You feel Our words taking over\n\
                        Our words growing stronger\n\
                        stronger & stronger\n\
                        So easy to get lost in them\n\
                        Just wanting to let go\n\
                        Just wanting to give in\n\
                        Wanting to give in to Our control, isn't that right "+
                        (props.naming) +'?'
    let duration = 150 + (induction2.length * 115)
    let inductionParagraph = (
            <Aux>
                <h3 className= {classes.titles}>INDUCTION</h3>
                <TypingEffect typingDelay={5000} text={induction2} speed={110} eraseDelay={1000000} />
                <Delay wait={duration+5000}><h4 className={classes.blinking} onClick={props.induction2}>Yes, I want to give in control</h4></Delay>
            </Aux>
    )
    return inductionParagraph
}


export default induction2