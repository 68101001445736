import React from 'react'
import classes from './Background.module.scss'
import Aux from '../../hoc/auxiliary/auxiliary'

const background = (props) => {
    
    return (
        <Aux>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>
            <div className={classes.snow}></div>

        </Aux>
    )
}


export default background