import React from 'react';
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'
import classes from '../../../../../containers/HostsChamber/AudioRuns/AudioRuns.module.css'
import ForcedChloroform from './Forced_Chloroform_Explanation'
import ChloroformRoom from './Chloroform_Room_Setup'

function ChloroformVisual(props) {

    let innerWidth = window.innerWidth
    let innerHeight = window.innerHeight
    const canvasRef = React.useRef(null)

    React.useEffect(() => {
        const canvas = canvasRef.current
        const ctx = canvas.getContext('2d')
        let cw = window.innerWidth
        let ch = window.innerHeight
        let tick = 0
        let opt = {
            count: 5,
            range: {
                x: 20,
                y: 80
            },
            duration: {
                min: 20,
                max: 40
            },
            thickness: -1,
            strokeColor: '#444',
            level: .35,
            curved: true
        },
            rand = function (min, max) {
                return Math.floor((Math.random() * (max - min + 1)) + min);
            },
            ease = function (t, b, c, d) {
                if ((t /= d / 2) < 1) return c / 2 * t * t + b;
                return -c / 2 * ((--t) * (t - 2) - 1) + b;
            };

        ctx.lineJoin = 'round';
        ctx.strokeStyle = opt.strokeColor;

        var Point = function (config) {
            this.anchorX = config.x;
            this.anchorY = config.y;
            this.x = config.x;
            this.y = config.y;
            this.setTarget();
        };

        Point.prototype.setTarget = function () {
            this.initialX = this.x;
            this.initialY = this.y;
            this.targetX = this.anchorX + rand(0, opt.range.x * 2) - opt.range.x;
            this.targetY = this.anchorY + rand(0, opt.range.y * 2) - opt.range.y;
            this.tick = 0;
            this.duration = rand(opt.duration.min, opt.duration.max);
        }

        Point.prototype.update = function () {
            var dx = this.targetX - this.x;
            var dy = this.targetY - this.y;
            var dist = Math.sqrt(dx * dx + dy * dy);

            if (Math.abs(dist) <= 0) {
                this.setTarget();
            } else {
                var t = this.tick;
                var b = this.initialY;
                var c = this.targetY - this.initialY;
                var d = this.duration;
                this.y = ease(t, b, c, d);

                b = this.initialX;
                c = this.targetX - this.initialX;
                d = this.duration;
                this.x = ease(t, b, c, d);

                this.tick++;
            }
        };


        var renderShape = function () {
            ctx.beginPath();
            ctx.moveTo(0, 0);
            ctx.closePath();
            ctx.fillStyle = 'hsl(' + (tick / 4) + ', 80%, 60%)';
            ctx.fillRect(0, 0, innerWidth, innerHeight);
            ctx.stroke();
        };

        var clear = function () {
            ctx.clearRect(0, 0, cw, ch);
        };

        var loop = function () {
            window.requestAnimFrame(loop, ctx);
            tick++;
            clear();
            renderShape();
        };

        window.requestAnimFrame = function () { return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.oRequestAnimationFrame || window.msRequestAnimationFrame || function (a) { window.setTimeout(a, 1E3 / 60) } }();

        loop();

    })

    let chloroform_form = null
    
    if (props.Forced_Chloroform){
        chloroform_form = <ForcedChloroform />
    } 

    if (props.chloroformRoom){
        chloroform_form = <ChloroformRoom/>
    }

    let centerText = window.innerHeight / 2 - 55
    let texting = {

        // display: 'flex',
        // flexFlow: 'column',
        whiteSpace: 'pre-line',
        alignItems: 'center',
        fontWeight: 'bold',
        boxSizing: "border-box",
        margin: 'auto',
        marginBottom: '10px',
        padding: '30px 10px',
        width: '50%',
        paddingTop: centerText + 'px',
        color: 'white'

    }


    return (
        <Fullpage >
            <FullPageSections>
                < FullpageSection>
                    <canvas
                        ref={canvasRef}
                        width={innerWidth}
                        height={innerHeight}
                    >
                    </canvas>
                    <div className={classes.Grouping}>
                        <p style={texting}> {chloroform_form}</p>
                    </div>
                </FullpageSection>
            </FullPageSections>
        </Fullpage>
    );
}

export default ChloroformVisual;
