import React, { useState } from "react";
import TextTransition from "react-text-transition";
import TypingEffect from 'react-typing-effect'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import Button from '../../../../UI/Button/Button'
import classes from '../../../../../containers/HostsChamber/AudioRuns/AudioRuns.module.css'
import * as scripts from '../../../../../shared/AudioRuns/TriggerIntroduction/TentaclesIntroduction/TentaclesIntroductionScripts'
import * as Delays from '../../../../../shared/AudioRuns/TriggerIntroduction/TentaclesIntroduction/TentaclesIntroductionDelay'
import Activate from '../../../../../assets/audio_runs/Trigger_Introduction/Tentacle_Introduction/Tentacle_Resist_Activate.mp3'
import Sound from 'react-sound'
import Delay from "react-delay";
import useInterval from 'react-useinterval';
import TentacleResistEnding from './Tentacles_Resist_Ending'

const DELAY_TEXT = [3600]


function Trigger_Resist_Activate() {

  let [count, setCount] = useState(0);
  let [tentacle_activate, setProtocol] = useState(true)
  let [tentacle_ending, setObeySequence] = useState(false)


  const increaseCount = () => {
    if (tentacle_activate && count < scripts.TENTACLES_RESIST_SCRIPT_ACTIVATE.length) {
      setCount(count + 1);
    }
  };

  useInterval(increaseCount, Delays.TENTACLES_RESIST_ACTIVATE_DELAY_TIME[count]);

  const enterActivateHandler = () => {
    setProtocol(tentacle_activate = false)
    setObeySequence(tentacle_ending = true)

  }


  let tentacles_resist_activate_form = null

  if (tentacle_activate) {
    tentacles_resist_activate_form = (
      <Aux>
        {count < scripts.TENTACLES_RESIST_SCRIPT_ACTIVATE.length ?
          <Aux >
            <Sound url={Activate} playStatus={Sound.status.PLAYING} />
            <Delay wait={DELAY_TEXT[count]}>
              <TextTransition
                text={<TypingEffect typingDelay={100} text={scripts.TENTACLES_RESIST_SCRIPT_ACTIVATE[count]} speed={90} eraseDelay={1000000} />}
                Overflow
              />
            </Delay>
          </Aux>
          :
          <div className={classes.ButtonPadding}>
            <Button btnType="Success" clicked={enterActivateHandler}>Yes... You control me</Button>
          </div>
        }
      </Aux >
    )
  }

  if (tentacle_ending){
    tentacles_resist_activate_form = <TentacleResistEnding />
  }

  return (
    <Aux>
      {tentacles_resist_activate_form}
    </Aux>
  )

}

export default Trigger_Resist_Activate