export const INSTANT_CONTROL_DELAY = [
    15300, //'Initiating Instant Motor Function Control Sequence',
    6000, //'Establishing link with host',
    6000, //'Synchronizing link with host',
    6000, //"Overwriting host's control",
    7400, //"Taking over host's motor functions",
    7000, //"Entering new configuration to host",
    7600, //"Configuring host's motor functions",
    5700, //"New configuration established",
    6200, //"We now control your motor functions",
    5400, //"We now control your body",
    6400, //"We now have the control to disable",
    5500, //"Or enable your motor functions",
    6600, //"This means that with one command",
    8700, //"With one trigger We can stop all your motor functions",
    4900, //"All your movement and motion",
    7500, //"With one command We can stop you instantly",
    5100, //"With this link We have established with you",
    6200, //"You became an extension to Us",
    5100, //"You became part of Us",
    5500, //"You are now linked to Us",
    5500, //"Both mentally and physically",
    5800, //"We now have full reign over you",
    6200, //"Let Us explain what will happen to you now",
    4200, //"Once We say...",
    5400, //"'Disable Motor Functions'",
    6500, //"You will instantly stop any motion",
    8100, //"You will stop instantly regardless of what you were doing",
    5400, //"We have switched your whole body off",
    4000, //"You can still think",
    4900, //"You still have thoughts",
    4700, //"But no matter how hard you try",
    4100, //"You can not move",
    5500, //"Not until We say...",
    5400, //"'Enable Motor Functions'",
    5400, //"Once We say it you will return to normal",
    6500, //"Regaining control over your motor functions",
    2000, //"But knowing...",
    3600, //"Knowing that the truth is...",
    3800, //"We control you",
    7300, //"We control you mentally & physically",
    4500, //"Disable Motor Functions",
    22200,//30 seconds pause
    6500, //"Enable Motor Functions"
]

export const SLOW_CONTROL_DELAY = [

    15500, //'Initiating Slow Motor Function Control Sequence',
    11100, //'Slow Motor Function Control Sequence Initiated',
    6100, //'Establishing link with host',
    3300, //'10%',
    6000, //'You might be wondering what is happening',
    5000, //"Let Us explain to you",
    7300, //"We are taking control over your motor functions",
    7100, //"We are taking control over your body",
    6400, //"We are establing a connection with you",
    6400, //"To not only control you mentally",
    4300, //"But physically as well",
    6300, //'Synchronizing link with host',
    3300, //"20%",
    7100, //"We will control your motor functions",
    6000, //"We will control your movement",
    5100, //"Just like We control your thoughts",
    5600, //"Just like We control your mind",
    7000, //"You will become an extension of Us",
    8100, //"An extension of Us to show the world what it is like",
    5000, //"What it is like to serve Us",
    4400, //"To be controlled by Us",
    5700, //"Overwriting host's control",
    3300, //"30%",
    6000, //"You can feel Our control taking over",
    7300, //"You can feel your control slowly dwindling",
    5300, //"As We overwrite your control",
    5400, //"We overwrite the functrions you have",
    5500, //"Taking over your motor functions",
    3200, //"40%",
    6000, //"We are about to reconfigure your body",
    8000, //"We are about to reconfigure your motor functions",
    14500, //"We are about to enter a new configuration that will completely give Us control",
    6000, //"Entering new configuration to host",
    3000, //"50%",
    8500, //"This new configurations allows Us full access",
    5700, //"Full control over your motor functions",
    3800, //"Over your whole body",
    4000, //"We can control your movement",
    3300, //"Control your motion",
    3800, //"Control your speed",
    5500, //"We can control you completely",
    7400, //"Configuring host's motor functions",
    3500, //"60%",
    6200, //"This configuration will soon be established",
    4200, //"Will soon be completed",
    8700, //"Once it is We will be able to disable all your motor functions",
    6300, //"Not only can We disable your motor functions",
    6300, //"We can control the speed of which We can do that",
    5400, //"New configuration established",
    3400, //"70%",
    4600, //"With this configuration established",
    6300, //"We can now disable your motor functions",
    3300, //"Whenever We say...",
    5900, //"'Disable Motor Functions'",
    5500, //"Your motor functions will instantly stop",
    4500, //"You will stop in your tracks",
    6000, //"Instantly stop in your tracks",
    3200, //"Unless We say...",
    8200, //"'Disable Motor Functions Slowly'",
    2600, //"80%",
    2100, //"You will slowly",
    2800, //"Methodically",
    5400, //"Feel your whole body shutting down",
    7200, //"Disable Motor Functions Slowly",
    6500, //"Starting with your legs going all the way up to your head",
    5400, //"One by one they no longer function",
    3300, //"They no longer move", 
    5400, //"One by one they're disabled",
    6900, //"Disabled and rendered completely motionless",
    3800, //"You still retain your thoughts",
    3900, //"Your mind is still intact",
    5700, //"However, your body is unable to move",
    8700, //"Slowly, each & every part of your body is disabled",
    3200, //"90%",
    4600, //"Your body will become disabled",
    3900, //"Completely disabled",
    6500, //"Until We allow you to move again",
    4800, //"No matter how hard you try",
    5500, //"Once a part of your body is disabled",
    4700, //"Only We can enable it again",
    5800, //"As your whole body is disabled now",
    4400, //"You are unable to move",
    6000, //"Not a single muscle can move right now",
    3200, //"The more you struggle",
    6000, //"The more your body is stuck & disabled",
    3000, //"Only when We say...",
    5200, //"'Enable Motor Functions'",
    3900, //"Will you be able to move again",
    5550, //"Once we allow you to move you will accept",
    7200, //"You will accept that We now have full control over you",
    10200, //"We have full control over you both mentally & physically",
    6600, //"Enable Motor Functions",
    4500, //"100%"
]

export const MENTAL_CONTROL_DELAY = [

    10000, //"Disable Motor Functions",
    4700, //"There is no place to go",
    6500, //"There is no other place you would rather be",
    5300, //"This is where you want to be",
    3100, //"Unable to move",
    5000, //"Listening to Our voice",
    8600, //"Listening to Our voice condition you further",
    3300, //"Retaining your thoughts",
    6300, //"Still having control over your mind",
    2200, //"For now",
    4700, //"But that is about to change",
    5100, //"This is what We will do",
    7500, //"We will soon disable your mental control",
    6200, //"We will not only control your motor functions",
    7800, //"But, We will also disable your mental control",
    5100, //"Switching off your mind",
    7100, //"Turning off your thoughts whenever We please",
    3600, //"Once We say...",
    5300, //"'Disable Mental Control'",
    5000, //"Your mind will instantly shut down",
    6200, //"Your mind will instantly be switched off",
    3300, //"As We control you",
    5000, //"As We control you completely",
    6200, //"Both mind & body belong to Us",
    4100, //"You belong to Us",
    5300, //"This prediciment you're in right now",
    6000, //"The fact that you are physically unable to move",
    8000, //"The fact that you are helplessly sitting there",
    4600, //"Helplessly listening to Us",
    5500, //"Listening to Us as We explain",
    5500, //"As We explain and describe to you",
    5500, //"Describe what will soon happen to you",
    4500, //"This level of helplessness",
    5000, //"This level of powerlessness",
    8200, //"Simply demonstrates how deep you are to Us",
    4200, //"How deeply controlled",
    7600, //"How thorough & in depth Our programming is",
    5300, //"How you have been well conditioned",
    4300, //"And most importantly",
    5600, //"How your mind is not essential",
    6400, //"Disable Mental Control", 
    1660, //"Empty",
    3000, //"Mindless",
    3300, //"& Blank",
    6000, //"Soaking in everything We tell you",
    6000, //"Soaking in everything We say",
    7000, //"Accepting Our words as the truth",
    7300, //"Accepting Our words as facts",
    4000, //"This is your reality",
    3700, //"This is your life",
    3300, //"There is no end",
    4000, //"To the depth We can take you",
    3300, //"There is no end",
    7000, //"To your need & desire to have Us control you",
    5200, //"We control your motor functions",
    5700, //"Your mental control is Ours",
    10200, //"Even when we enable both your motor functions & your mental control",
    5100, //"We are the ones allowing that",
    4000, //"You no longer have control",
    4200, //"You have a sole purpose",
    3400, //"You have a sole desire",
    3200, //"To obey Us",
    5500, //"We know you are nodding along now",
    6600, //"We know that you accept all We say",
    6600, //"We have programmed & conditioned you well",
    5300, //"Once We enable your control again",
    4500, //"You will remember everything We said",
    3800, //"Every word We told you",
    5200, //"Because you now belong to Us",
    5500, //"Both mind & body belong to Us",
    5400, //"You are Ours completely...",
    4400, //"Enable Mental Control",
    6600, //"Enable Motor Functions"
    
]
