import React, { useState } from "react";
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import Button from '../../../../UI/Button/Button'
import classes from '../../../../../containers/HostsChamber/HostsChamber.module.css'
import { MotorFunctionControlReinforcement } from '../../../../../store/actions/AudioRuns/audioRunsIndex'
import { connect } from 'react-redux'
import WebsiteAudio from '../../../../Background/WebsiteAudio';
import Background from '../../../../Background/Background'
import FlashyVisual from './FlashyVisual'
import ColorVisual from './ColorVisual'
import { Redirect } from "react-router-dom";
import NoBackground from './NoBackground'


function Motor_Function_Control_Reinforcement({ userId, token, Motor_Function_Reinforcement_Complete, MotorFunctionControlReinforcement, profileOptions }) {

    let [motor_function_control_opening, setProtocol] = useState(true)
    let [instant_control_reinforcement, setInstantControlProtocol] = useState(false)
    let [slow_control_reinforcement, setSlowControlProtocol] = useState(false)
    let [mental_control_reinforcement, setMentalControlProtocol] = useState(false)
    let [wired_reconfiguration, setWiredReconfigurationProtocol] = useState(false)

    MotorFunctionControlReinforcement(userId, token)

    const enterInstantControlSequenceHandler = () => {
        setProtocol(motor_function_control_opening = false)
        setInstantControlProtocol(instant_control_reinforcement = true)
    }

    const enterSlowControlSequencerHandler = () => {
        setProtocol(motor_function_control_opening = false)
        setSlowControlProtocol(slow_control_reinforcement = true)
    }

    const enterMentalControlSequencerHandler = () => {
        setProtocol(motor_function_control_opening = false)
        setMentalControlProtocol(mental_control_reinforcement = true)
    }

    const enterWiredReconfigurationSequencerHandler = () => {
        setProtocol(motor_function_control_opening = false)
        setWiredReconfigurationProtocol(wired_reconfiguration = true)
    }

    let motor_function_control_reinforcement_form = null

    if (motor_function_control_opening) {
        motor_function_control_reinforcement_form = (
            <Aux>
                <div className={classes.HostsChamber}>
                    <Background />
                    <WebsiteAudio />
                    <div className={classes.FormStyle}>
                        <div className={classes.HostButtons}>
                            <Button btnType="Success" clicked={enterInstantControlSequenceHandler}>Instant Motor Function Control Reinforcement</Button><br></br>
                            <Button btnType="Success" clicked={enterSlowControlSequencerHandler}>Slow Motor Function Control Reinforcement</Button><br></br>
                            <Button btnType="Success" clicked={enterMentalControlSequencerHandler}>Mental Control Reinforcement</Button><br></br>
                            <Button btnType="Success" clicked={enterWiredReconfigurationSequencerHandler}>Wired Reconfiguration</Button>
                        </div>
                    </div>
                </div>
            </Aux >
        )
    }
    if(profileOptions.audio_run_background === '0'){
        if (instant_control_reinforcement) {
            motor_function_control_reinforcement_form = <NoBackground instant_control_reinforcement = {instant_control_reinforcement} />
        }
        
        
        if (slow_control_reinforcement) {
            motor_function_control_reinforcement_form = <NoBackground slow_control_reinforcement = {slow_control_reinforcement}/>
        }
    
        if (mental_control_reinforcement) {
            motor_function_control_reinforcement_form = <NoBackground mental_control_reinforcement = {mental_control_reinforcement}/>
        }
    
        if (wired_reconfiguration) {
            motor_function_control_reinforcement_form = <NoBackground wired_reconfiguration = {wired_reconfiguration}/>
        }
    }else if(profileOptions.audio_run_background === '1'){
        if (instant_control_reinforcement) {
            motor_function_control_reinforcement_form = <FlashyVisual instant_control_reinforcement = {instant_control_reinforcement} />
        }
        
        
        if (slow_control_reinforcement) {
            motor_function_control_reinforcement_form = <FlashyVisual slow_control_reinforcement = {slow_control_reinforcement}/>
        }
    
        if (mental_control_reinforcement) {
            motor_function_control_reinforcement_form = <FlashyVisual mental_control_reinforcement = {mental_control_reinforcement}/>
        }
    
        if (wired_reconfiguration) {
            motor_function_control_reinforcement_form = <FlashyVisual wired_reconfiguration = {wired_reconfiguration}/>
        }
    }else if(profileOptions.audio_run_background === '2'){
        if (instant_control_reinforcement) {
            motor_function_control_reinforcement_form = <ColorVisual instant_control_reinforcement = {instant_control_reinforcement} />
        }
        
        
        if (slow_control_reinforcement) {
            motor_function_control_reinforcement_form = <ColorVisual slow_control_reinforcement = {slow_control_reinforcement}/>
        }
    
        if (mental_control_reinforcement) {
            motor_function_control_reinforcement_form = <ColorVisual mental_control_reinforcement = {mental_control_reinforcement}/>
        }
    
        if (wired_reconfiguration) {
            motor_function_control_reinforcement_form = <ColorVisual wired_reconfiguration = {wired_reconfiguration}/>
        }
    }
    


    if (Motor_Function_Reinforcement_Complete){
        motor_function_control_reinforcement_form = <Redirect to ="/"/>
    }


    return (
        <Aux>
            {motor_function_control_reinforcement_form}
        </Aux>
    )

}

const mapStateToProps = state => {
    return {
        userId: state.auth.userId,
        token: state.auth.token,
        Motor_Function_Reinforcement_Complete: state.motorFunctionReinforce.Motor_Function_Reinforcement_Complete,
        profileOptions: state.options.profileOptions,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        MotorFunctionControlReinforcement: (userId, token) => dispatch(MotorFunctionControlReinforcement(userId, token)),
        
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Motor_Function_Control_Reinforcement)