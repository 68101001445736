export const CHLOROFORM_ROOM_SETUP_DELAY = [
    10000, //'Initiating Chloroform Room Sequence',
    6000, //'Chloroform Room Sequence Initiated',
    5000, //'Setting up Chloroform Gas',
    4600, //'Chloroform Gas ready',
    3600, //'Welcome Host',
    4700, //'Welcome to The Chloroform Room',
    6000, //'This is a special room that We created',
    6400, //'A special room that We created in your mind',
    5700, //'Any room can become The Chloroform Room',
    3600, //'If We trigger it to be',
    4800, //'Any room can become The Chloroform Room',
    3800, //"If We desire it to be",
    6700, //"The Chloroform room is an empty room",
    4700, //"An empty black room",
    5100, //"With a single spotlight in the middle",
    6600, //"And a number of vents in the corners of the room",
    3800, //"This is what any room you are...",
    2000, //"In..."
    2000, //" becomes",
    5000, //"When We trigger you to enter The Chloroform Room",
    5000, //"There are no doors in The Chloroform Room",
    5500, //"There are no windows in The Chloroform Room",
    5500, //"There is no escape The Chloroform Room",
    5300, //"Once you enter The Chloroform Room...",
    5600, //"A green gas starts coming out of the vents",
    5500, //"Our special Chloroform Gas",
    6800, //"We have developed this gas specifically for this room",
    4400, //"This gas has a special ability",
    7900, //"This gas can work instantly or as slow as We desire",
    6800, //"During this file We will allow it to work slow",
    5000, //"We will allow it to take its time",
    5200, //"As you enter The Chloroform Room now",
    6000, //"You find everything around you fading away",
    4200, //"All the furniture disappears ",
    6500, //"Leaving you, Us & The Chloroform Room",
    4300, //"It is time to confirm, Host",
    7000, //"Confirm that you are ready to enter The Chloroform Room"
]

export const CHLOROFORM_ROOM_START_DELAY = [
    10200, //'Watch as the room around you vanishes',
    5500, //"As the room around you transforms",
    5700, //"Transforms into The Chloroform Room",
    4900, //"A dark empty room",
    7800, //"A dark empty room that starts filling with gas",
    4000, //"Our special gas",
    5100, //"Our special green gas",
    5500, //"The Hypno Virus's gas",
    4500, //"This gas has no smell",
    3500, //"Has no taste",
    3900, //"All you can do is see it",
    7800, //"See the green gas getting closer & closer to you",
    7600, //"Anticipating when you will inevitably breathe it in",
    4500, //"You try to hold your breath now",
    4200, //"You can see the green mist getting closer",
    3000, //"Filling the room",
    5600, //"Trying to hold your breath just a little longer",
    2500, //"Holding it",
    2500, //"Holding it",
    3000, //"Holding it...",
    2000, //"No more",
    10400, //"Taking a deep breath in as the green gas goes deep inside your lung",
    4900, //"Instantly you feel its effect",
    4800, //"You realize how potent it is",
    3700, //"You feel a thick cloud",
    5100, //"A thick green cloud in your mind now",
    5300, //"Making it harder and harder to think",
    4300, //"As you start breathing steadily",
    3600, //"Taking a deep breath in",
    3900, //"Letting it all out now",
    3800, //"Feeling that cloud getting thicker",
    3500, //"Thicker & Thicker",
    4500, //"Getting harder to keep your eyes open",
    4300, //'Getting harder to stay awake',
    4200, //"Here is where you get a chance",
    3800, //"Your one & only chance",
    9000, //"Do you want to stop or do you want to continue?"
  
]

export const CHLOROFORM_ROOM_DELAY = [
    8000, //"We knew you would continue",
    5000, //"Taking a deep breath in...",
    3600, //"Breathing in more",
    4500, //"Letting it all out now",
    4200, //"So hard to think straight",
    5000, //"You can not think straight",
    5200, //"Our gas has spread completely",
    5400, //"Our gas has filled your mind",
    5500, //"You are bound to pass out shortly",
    5500, //"You are to be rendered unconscious soon",
    9200, //"But We want you to breath as much... of Our gas in... as possible",
    8600, //"There is a part We intentionally did not reveal yet",
    8000, //"We did not reveal until We knew you were helpless to escape",
    4500, //"You were powerless to resist",
    7300, //"Our gas does not only render you unconscious",
    5900, //"It is also filled with nanobots",
    5900, //"Small microscopic nanobots",
    5800, //"That are created to infect your mind",
    5900, //"Infect your mind with whatever We tell it",
    6500, //"You do not go into a normal unconscious state",
    3900, //"When you breath in Our gas",
    5000, //"You go into a programmable state",
    5500, //"A state where Our word is law",
    5300, //"And We own your mind",
    7000, //"A state you are so close to reach right now",
    3300, //"As you breathe in...",
    3700, //"More of Our gas",
    3900, //"More of Our nanobots",
    4200, //"Breathing it all in now",
    4500, //"Getting closer & closer to fall",
    5500, //"Closer & closer to pass out",
    5700, //"Here is what will happen from now on",
    4200, //"Whenever We speak the words...",
    4500, //"'Enter The Chloroform Room'",
    3800, //"You will return to this place",
    4200, //"You will return to The Chloroform Room",
    5000, //"We will tell you how strong Our gas is",
    4300, //"We will knock you out",
    3500, //"Render you unconscious",
    2900, //"Or to be exact...",
    6100, //"We will put you into a programmable state",
    5500, //"That you will completely forget",
    5200, //"Believing you passed out the whole time",
    4100, //"While We program you further",
    3100, //"Condition you deeper",
    3100, //"Control you more",
    2500, //"Now Host...",
    3600, //"it is time",
    8100, //"It is time to forget all about what We just said",
    5600, //"About the reality of The Chloroform Room",
    7800, //"And continue to believe you just passed out",
    8000, //"As you enter this programmable state for a few minute",
    8200, //"Only to wake up with no recollection of what happened in...",
    2000, //"3",
    4000, //"Lungs filling with Our gas",
    4500, //"Mind clouded by Our gas",
    2000, //"2",
    3900, //"Helpless & Unconscious",
    4500, //"No memory of it all",
    2500, //"and...",
    2500, //"1",
    8000, //"Fully unconscious now"
    
]

export const FORCED_CHLOROFORM_EXPLANATION_DELAY = [
    12300, //'Initiating Forced Chloroform Sequence',
    6000, //"Forced Chloroform Sequence Initiated",
    6000, //"Facts Check In progress",
    6000, //"Facts Check Completed",
    5300, //"Here are the facts, Host",
    5500, //"We are going to Chloroform you",
    7400, //"We are going to knock you out",
    4200, //"By the end of this run",
    4600, //"You will be rendered unconscious",
    10000, //"These are the facts and We are going to explain how it will happen",
    3600, //"We are going to be use you",
    4500, //"Use your own body against you",
    5000, //"Use your own hands against you",
    3000, //"By forcing it",
    7800, //"By forcing your hand to cover your mouth and nose",
    5800, //"As We cover your hand in chloroform",
    4000, //"Not just any chloroform",
    6900, //"A special mixture We created Ourselves",
    9000, //"By using the most powerful and potent types of chloroform",
    5900, //"We have created Our own Chloroform",
    5200, //"The Hypno Virus's Chloroform",
    8200, //"We will be forcing your hand which is soaked in this chloroform",
    5600, //"To cover your mouth & nose ",
    7000, //"Becoming only a matter of time before you fall victim",
    5100, //"You fall victim to your own hand",
    5000, //"Fall victim to Our forced chloroform",
    4600, //"No matter how hard you fight Us",
    4700, //"How hard you struggle & resist",
    5300, //"The end result will always be the safe",
    2000, //"You...",
    2500, //"Uncoscious", 
    5000, //"And That is a fact"
  
]

export const FORCED_CHLOROFORM_DELAY = [
    8000, //"We control you",
    4000, //"We control your mind",
    4500, //"We control your body",
    4500, //"We control your hands",
    4200, //"We control all",
    7000, //"We have now taken control over your motor functions",
    6200, //"We have now taken control over your hands",
    7000, //"Your hands will cover your mouth and nose momentarily",
    3400, //"You will try your hardest",
    5400, //"You will try your best to fight it",
    3000, //"But as you know",
    6600, //"That once your hands cover your mouth & nose",
    3700, //"It will be too late",
    3300, //"You will be gone",
    4800, //"You will be knocked out instantly",
    5800, //"That is how powerful Our chloroform is",
    5200, //"That's how potent it can be",
    4600, //"Your hands is already moving closer",
    4300, //"Closer & closer to your mouth",
    4100, //"Closer & closer to your nose",
    3500, //"You try to fight it",
    3200, //"You try to resist it",
    2600, //"But you know...",
    6600, //"You know deep down that is futile",
    8400, //"You know very well how absolute Our control is",
    6800, //"And the screen in front of you is not helping you",
    3500, //"It is not helping you focus",
    3000, //"As it bombards you",
    6500, //"Bombards all your senses with the flashs",
    3700, //"The flashs of green",
    5800, //"Making it harder & harder for you to focus",
    5500, //"Harder & harder for you to resist",
    4700, //"As your hands slip closer",
    4600, //"Closer & closer to you",
    4800, //"It is only a matter of time",
    8000, //"It is only a matter of time before you get knocked out",
    4700, //"Before you are rendered unconscious",
    4800, //"It is so hard to fight Us, host",
    4500, //"It is so hard to resist",
    2500, //"We know...",
    4200, //"We know you do not want to",
    8500, //"We know you are only stopping yourself to drop deeper for Us",
    6800, //"We know you want Us to chloroform you",
    2400, //"And the fact...",
    6200, //"The fact that We are using your own body against you",
    4200, //"Makes it so much hotter",
    5600, //"So much erotic and pleasurable",
    3900, //"That is why you will return",
    4700, //"That is why you always return",
    4400, //"You love what We do to you",
    4900, //"You love everything We do to you",
    4400, //"As you hand gets so close",
    5200, //"So close to your mouth & nose",
    4900, //"You know your fate is sealed",
    5900, //"You know you will be unconscious very soon",
    7200, //"You know that you will blank out for a few minutes now",
    7100, //"Once you are conscious again you will end this run",
    7800, //"You will end this run and find yourself eagerly excited",
    5800, //"Eagerly excited for the next time We...",
    3900, //"Chloroform you",
    3400, //"So give in, Host",
    3000, //"Give in to Us.",
    2900, //"It is time...",
    3200, //"It is time to...",
    4500, //"Be rendered unconscious"
]