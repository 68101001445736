import React, { Component } from 'react';
import Aux from '../../../hoc/auxiliary/auxiliary';
import classes from './HypnosisShowMenu.module.scss'
// import classes2 from '../ExperienceMenu/ExperienceMenu.module.scss'
import HypnosisShow from '../../../assets/Images/Blank.png'
import HypnosisShowStart from '././HypnosisShowStarts'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'

class HypnosisShowFrontSeat extends Component {

    state = {
        frontSeat: true,
        showBegins: false,
    }

    showBeginsHandler = () => {
        this.setState({ frontSeat: false, showBegins: true })
    }

    render() {
        let HypnosisShowForm = null
        if (this.state.frontSeat) {
            HypnosisShowForm = (
                <html style={{ backgroundImage: `url(${HypnosisShow})` }}>
                <div className={classes.storyFrom}>
                    <p>
                    Sitting front & center you look up at the stage as the final preparations are on their way. <br></br>
                    Chairs being set, lights adjusted towards the stage while the candles in the audience section are being diminished. <br></br>
                    The preparation & anticipation getting you excited, feeling goosebumps and butterflies. Thinking to yourself <br></br>
                    <i>"What if I actually get hypnotized?"</i><br></br>
                    You don't have time to think any further about it as you hear someone grabbing the mic and saying <br></br> 
                    “Please be seated, the show is about to start” 
                    </p>
                    <form className={classes.centered} onClick={this.showBeginsHandler}>
                        <h3 className={classes.storyButtonSize}>The Show Begins</h3>
                    </form>
                    </div>
                </html>
                )
        }
        if(this.state.showBegins){
            HypnosisShowForm = (
                <HypnosisShowStart seating = {1}/>
            )
        }

        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {HypnosisShowForm}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >




        )
    }
}


export default HypnosisShowFrontSeat