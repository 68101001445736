import React from 'react';
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'
import classes from '../../../../../containers/HostsChamber/AudioRuns/AudioRuns.module.css'
import InstantControlReinforcement from './Instant_Control_Reinforcement'
import SlowControlReinforcement from './Slow_Control_Reinforcement'
import MentalControlReinforcement from './Mental_Control_Reinforcement'
import WiredReconfiguration from './Wired_Reconfiguration'

function MotorFunctionControlIntroductionVisual(props) {

    let motor_function_control_form = null
    if (props.instant_control_reinforcement){
        motor_function_control_form = <InstantControlReinforcement />
    } 

    if (props.slow_control_reinforcement){
        motor_function_control_form = <SlowControlReinforcement/>
    }

    if (props.mental_control_reinforcement){
        motor_function_control_form = <MentalControlReinforcement />
    } 

    if (props.wired_reconfiguration){
        motor_function_control_form = <WiredReconfiguration/>
    }

    let centerText = window.innerHeight / 2 - 55
    let texting = {

        // display: 'flex',
        // flexFlow: 'column',
        whiteSpace: 'pre-line',
        alignItems: 'center',
        fontWeight: 'bold',
        boxSizing: "border-box",
        margin: 'auto',
        marginBottom: '10px',
        padding: '30px 10px',
        width: '50%',
        paddingTop: centerText + 'px',
        color: 'white'

    }


    return (
        <Fullpage >
            <FullPageSections>
                < FullpageSection>
                    <div className={classes.Grouping}>
                        <p style={texting}> {motor_function_control_form}</p>
                    </div>
                </FullpageSection>
            </FullPageSections>
        </Fullpage>
    );
}

export default MotorFunctionControlIntroductionVisual;
