import React from '../../../../../node_modules/react';
import TypingEffect from '../../../../../node_modules/react-typing-effect'
import Delay from '../../../../../node_modules/react-delay'
import Aux from '../../../../hoc/auxiliary/auxiliary'
import classes from '../../HostsRun/HostsRunComponent.module.css'


const trigger = (props) => {
    // let praiseChoice = 0
    // praiseChoice = Math.floor(Math.random() * props.praises.length);
    // let praise = props.praises[praiseChoice]
     
    // eslint-disable-next-line
    const triggerMessage = "We are pleased with you, "+ // eslint-disable-next-line
                            (props.naming) + '\n\
                            You have done so well so far\n\
                            Now it is time for you to have a trigger\n\
                            A trigger for Us to use\n\
                            A trigger for Us to use how We please\n\
                            A trigger for Us to use to control you\n\
                            It is time to learn what your trigger is...\n\
                            "The Hypno Virus took over"\n\
                            Falling...\n\
                            Falling deep\n\
                            Deeper and Deeper everytime We say it\n\
                            The Hypno Virus took over\n\
                            and you know that is the truth\n\
                            You know and love that fact\n\
                            The Hypno Virus took over\n\
                            We are only getting started with you, ' + // eslint-disable-next-line
                            (props.naming) + "\n\
                            Soon you will be a " + // eslint-disable-next-line
                            (props.title) + "\n\
                            Soon you will experience " +(props.triggerMethodName)
    let speed = parseInt(props.speed) +5
    let  duration = 170 + (triggerMessage.length * speed)
    let triggerParagraph = (
            <Aux>
                <h3 className= {classes.titles}>TRIGGER</h3>
                <Delay wait={5000}><TypingEffect typingDelay={100} text={triggerMessage} speed={props.speed} eraseDelay={1000000} /></Delay>
                <Delay wait={duration+5000}><h4 className={classes.blinking} onClick={props.trigger}>The Hypno Virus Took Over</h4></Delay>
            </Aux>
        )
    





    return triggerParagraph
}


export default trigger