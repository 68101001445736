import React, { Component } from 'react';
import Aux from '../../../../hoc/auxiliary/auxiliary';
import classes from '../HypnosisShowMenu.module.scss'
// import classes2 from '../ExperienceMenu/ExperienceMenu.module.scss'
import HypnosisShow from '../../../../assets/Images/Blank.png'
import Snap2 from './HypnosisShowNonVolSnap2'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'

class HypnosisShowSnap extends Component {

    state = {
        snap1: true,
        snap2: false
    }

    snap2Handler = () => {
        this.setState({ snap1: false, snap2: true })
    }



    render() {
        let HypnosisShowForm = null
        if (this.state.snap1) {
            HypnosisShowForm = (
                <html style={{ backgroundImage: `url(${HypnosisShow})` }}>
                    <div className={classes.storyFrom}>
                        <p>
                        <i>“Snap my fingers. Immediately falling and dropping deep as soon as you hear the snap of my fingers. 
                            Dropping and falling into a nice deep trance, understood?”</i><br></br> 
                        You nod with a smile on your face excited to hear that snap. Starting to slowly sit up in your chair once you hear him say <br></br> 
                        <i>“Waking up on the count of five. <br></br>
                        One, starting to wake up. <br></br>
                        Two, feeling wonderful and refreshed. <br></br>
                        Three, Excited & happy. <br></br>
                        Four, Eager to participate and join in the experience<br></br>
                        Five, wide awake now!”</i><br></br>
                        <strong>*SNAP*</strong><br></br>
                        <i>"Happy, smiling wide like you're waking up from this lovely nap, sitting up & stretching. Grab a some water and drink. 
                            You've done a great job and you've all been impressivel responsive."</i><br></br>
                        Blinking for a few seconds as you start waking up, stretching and grabbing a bottle of water next to you taking a sip to get hydrated.
                        Feeling amazing while watching The Great O turning around to face you <br></br>
                        <i>“A big round of applause to all the folks up here giving you one heck of a show!" </i><br></br>
                        Without thinking you start cheering and clapping for the volunteers, showing them so much support & love <br></br>
                        <i>"I hope you're enjoying the show as well especially those of you who fell under. You're doing a great job!” </i><br></br> 
                        Blushing so hard. Knowing full well this was a personal attack but you loved it. 
                        Giggling to yourself while you watch him drinking water and looking at the volunteers <br></br>
                        <i>“Have you been enjoying yourselves?” </i><br></br>
                        Most of you nod happily as some seem to be still in a trance. 
                        You watch him put the water bottle down whispers in their ears something as he gently shakes them & they drop deep again. <br></br>
                        You watch intently with awe how he holds them and drops them feeling a bit envious and wanting to be in their place but you stop thinking once you hear him say <br></br>
                        <i>"Now where was I? Ah yes, I remember. I'm curious, what do you remember? Do you remember how you dropped? Do you remember forgetting numbers? Do you remember the cloud & resting on it?"</i> <br></br>
                        You think back on what transpired throughout the show but you are 
                        </p>
                        <form className={classes.centered} onClick={this.snap2Handler}>
                            <h4 className={classes.storyButtonSize}>Confused</h4>
                        </form>
                        <form className={classes.centered} onClick={this.snap2Handler}>
                            <h4 className={classes.storyButtonSize}>Not Confused</h4>
                        </form>
                    </div>
                </html>
            )
        }
        if (this.state.snap2) {
            HypnosisShowForm = (
                <Snap2 />
            )
        }


        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {HypnosisShowForm}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >




        )
    }
}


export default HypnosisShowSnap