import * as conditionTypes from '../../../actions/ConditioningStages/conditioningStagesTypes';
import { updateObject } from '../../../../shared/utility';

const initialState = {
    error: null,
    obedienceStage1Valid: false,
    obedienceStage2Valid: false,
    obedienceStage3Valid: false,
    obedienceTheme: false,
    selectModule: true,
    obedienceStage1Counter: 0,
    typing_command: 0,
    green_text_reinforcement_command: 0,
    we_are_watching_command: 0,
    resistance_is_futile_command: 0,
    instant_trigger_command:0,
    obedienceStage1Complete: false,
    obedienceStage1: [],
    obedienceStage1Commands: [],
    loading: false,
}

const ObedienceStageStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true
    })
}

const ObedienceStage1Start = (state, action) => {
    return updateObject(state, {
        obedienceStage1Valid: true,
        loading: false
    })
}

const ObedienceStage1Success = (state, action) => {
    if(action.obedience_stage1.obedience_stage1 > 4){
        return updateObject(state, {
            obedienceStage1: action.obedience_stage1,
            obedienceStage1Commands: action.obedienceStage1Commands, 
            obedienceStage1Complete: false,
            selectModule: false,
            obedienceTheme: true,
            loading: false,
            error: null
        })
    }
    else if (action.obedience_stage1.obedience_stage1 <= 4){
        return updateObject(state, {
            obedienceStage1: action.obedience_stage1,
            obedienceStage1Commands: action.obedienceStage1Commands,
            obedienceStage1Complete: false,
            selectModule: false,
            obedienceTheme: true,
            loading: false,
            error: null
        })
    }
}
    



const ObedienceStageFailed = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    })
}

const ObedienceStage1Counter = (state, action) => {
    let updatedCommandNumber = 1
    if (action.commandNumber === 0){
        state.typing_command = state.typing_command + updatedCommandNumber
    } else if (action.commandNumber === 1){
        state.green_text_reinforcement_command = state.green_text_reinforcement_command + updatedCommandNumber
    } else if (action.commandNumber === 2){
        state.we_are_watching_command = state.we_are_watching_command + updatedCommandNumber
    } else if (action.commandNumber === 3){
        state.resistance_is_futile_command = state.resistance_is_futile_command + updatedCommandNumber
    } else if (action.commandNumber === 4){
        state.instant_trigger_command = state.instant_trigger_command + updatedCommandNumber
    }
    
    return updateObject(state, {
        obedienceStage1Counter: action.obedienceStage1Counter
    })
}

const ObedienceStage1Complete = (state, action) => {
    return updateObject(state, {
        obedienceStage1Complete: true,
        obedienceStage1Valid: false,
        obedienceStage1: [],
        obedienceStage1Commands: [],
        loading: false,
        obedienceTheme: false,
        selectModule: true,
        typing_command: 0,
        green_text_reinforcement_command: 0,
        we_are_watching_command: 0,
        resistance_is_futile_command: 0,
        instant_trigger_command:0,
        obedienceStage1Counter: 0,
        error: null
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case conditionTypes.OBEDIENCE_STAGE_START: return ObedienceStageStart(state, action)
        case conditionTypes.OBEDIENCE_STAGE_1_START: return ObedienceStage1Start(state, action)
        case conditionTypes.OBEDIENCE_STAGE_1_SUCCESS: return ObedienceStage1Success(state, action)
        case conditionTypes.OBEDIENCE_STAGE_FAILED: return ObedienceStageFailed(state, action)
        case conditionTypes.OBEDIENCE_STAGE_1_COUNTER: return ObedienceStage1Counter(state, action)
        case conditionTypes.OBEDIENCE_STAGE_1_COMPLETED: return ObedienceStage1Complete(state, action)
        default:
            return state

    }
}


export default reducer