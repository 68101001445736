import React from 'react'
import classes from '../../../../containers/Account/Account.module.css'
// import Input from '../../UI/Input/Input'
import Button from '../../../UI/Button/Button'
import Aux from '../../../../hoc/auxiliary/auxiliary'

const ChloroformReinforcement = (props) => {
    console.log(props.ChloroformReinforcement)
    let chloroformReinforcementForm = (
        <Aux>
            <div className={classes.AccountContainer}>
                <form className={classes.FormStyle}>
                    <h2>Chloroform Reinforcement Run Details</h2>
                    <p className={classes.smallText}>Conscious or unconscious, {props.name}. We control your mind</p>
                    <div>
                        <p><strong>Chloroform Reinforcement Run: {props.ChloroformReinforcement.Chloroform_Reinforcement}</strong></p>
                    </div>
                    <div>
                        <p><strong>Quick Chloroform Effect: {props.ChloroformReinforcement.Quick_Effect_Chloroform} </strong></p>
                    </div>
                    <div>
                        <p><strong>Normal Chloroform Effect: {props.ChloroformReinforcement.Normal_Effect_Chloroform} </strong></p>
                    </div>
                    <div>
                        <p><strong>Chloroform Body Play: {props.ChloroformReinforcement.Body_Play} </strong></p>
                    </div>
                    <div>
                        <p><strong>Chloroform Arousal Effect: {props.ChloroformReinforcement.Arousal_Effect} </strong></p>
                    </div>
                    <div>
                        <p><strong>Chloroform Amnesia Effect: {props.ChloroformReinforcement.Amnesia_Effect} </strong></p>
                    </div>
                </form>
                <div className={classes.AuthButtons}>
                    <Button btnType="Danger" clicked={props.ReturnBackHandler}>Back</Button>
                </div>

            </div>
        </Aux>)

    return chloroformReinforcementForm
}

export default ChloroformReinforcement