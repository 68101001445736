import React from 'react';
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'
import classes from '../Visual.module.css'
import Sound from 'react-sound'
import Bell from '../../../assets/audio/bell.mp3'
import Mantra from '../../../assets/audio/mantralong.mp3'
import Metronome from '../../../assets/audio/Metronome2.mp3'
import Ticking from '../../../assets/audio/Ticking3.mp3'
import MantraVoice from '../../../assets/audio/MantraVoice.mp3'
import WordText from '../TextAddition'

function ParticlesVisual(props) {

  let innerWidth = window.innerWidth
  let innerHeight = window.innerHeight
  const canvasRef = React.useRef(null)

  React.useEffect(() => {
    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')

    let colorArray = [
      props.color
    ]

    window.addEventListener('resize', function () {
      canvas.width = innerWidth
      canvas.height = innerHeight

      init()
    }
    )

    function randomIntFromRange(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min)
    }

    function randomColor(colorArray) {
      return colorArray[Math.floor(Math.random() * colorArray.length)]
    }

    function Particle(x, y, radius, color) {
      this.x = x
      this.y = y
      this.radius = radius
      this.color = color
      this.radians = Math.random() * Math.PI * 2
      this.velocity = 0.02
      this.distanceFromCenter = randomIntFromRange(50, 120)

      this.update = () => {
        let lastPoint = { x: this.x, y: this.y }

        this.radians += this.velocity
        this.x = x + Math.cos(this.radians) * this.distanceFromCenter
        this.y = y + Math.sin(this.radians) * this.distanceFromCenter
        this.draw(lastPoint)
      }

      this.draw = (lastPoint) => {
        ctx.beginPath()
        ctx.strokeStyle = this.color
        ctx.lineWidth = this.radius
        ctx.moveTo(lastPoint.x, lastPoint.y)
        ctx.lineTo(this.x, this.y)
        ctx.stroke()
        ctx.closePath()


      }
    }
    

    let particles;
    function init() {

      particles = []
      for (let i = 0; i <= props.particles_number; i++) {
        const radius = Math.floor(Math.random() * 5) + 1
        let x = Math.random() * innerWidth
        let y = Math.random() * innerHeight
        particles.push(new Particle(x, y, radius, randomColor(colorArray)))
      }
    }
    function animate() {
      ctx.fillStyle = props.background_color
      ctx.fillRect(0, 0, innerWidth, innerHeight)
      requestAnimationFrame(animate)
      particles.forEach(particle => {
        particle.update()
      })
    }

    init()
    animate()



  })

  
  let soundComponent = null
  switch (props.sound) {
    case '1': soundComponent = <Sound url={Bell} playStatus={Sound.status.PLAYING} loop={true} />
      break;
    case '2': soundComponent = <Sound url={Metronome} playStatus={Sound.status.PLAYING} loop={true} />
      break;
    case '3': soundComponent = <Sound url={Ticking} playStatus={Sound.status.PLAYING} loop={true} />
      break;
    case '4': soundComponent = <Sound url={Mantra} playStatus={Sound.status.PLAYING} loop={true} />
      break;
    case '5': soundComponent = <Sound url={MantraVoice} playStatus={Sound.status.PLAYING} loop={true} />
      break;
    default: soundComponent = null
  }

  let divStyle
  divStyle = {
    fontSize: "48px",
    fontFamily: "Arial",
    background: 'transpartent',
    position: 'fixed',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    zIndex: '997',
    textAlign: 'center',
    // display: 'visible'


  }

  return (
    <Fullpage >
      <FullPageSections>
        < FullpageSection>
          <canvas
            ref={canvasRef}
            width={innerWidth}
            height={innerHeight}
          >
            {soundComponent}
          </canvas>
          <div style={divStyle}>
            <p className ={classes.returnText} onClick={props.returnHandler}>Return to Customization</p>
            <WordText text_color = {props.text_color}
                      text_speed ={props.text_speed} 
                      conditioning_text={props.conditioning_text}
                      arousal_check={props.arousal_check}
                      submissive_check = {props.submissive_check}/>
          </div>
        </FullpageSection>
      </FullPageSections>
    </Fullpage>
  );
}

export default ParticlesVisual;
