export const TENTACLES_OPENING_SCRIPT = [
    
    "Loading T.E.N.T.A.C.L.E. Introduction...",
    
    "Loading T.E.N.T.A.C.L.E. Introduction Completed",
    
    "T.E.N.T.A.C.L.E. Introduction Initiated",
    
    "Welcome...",

    "It is time for Us to condition you further, Host",

    "It is time for Us to modify you",

    "It is time for an upgrade",

    "We have been developing a special portable conditioning unit",

    "This unit is called...",

    "'T.E.N.T.A.C.L.E.'",

    "This unit is designed to latch itself to the top of your head",

    "Infecting you as it takes control over you",

    "Taking control over your body & your mind",

    "Turning you into a mindless",

    "Empty",

    "Host",

    "Ready to be programmed",

    "Ready for Us to take control",

    "Are you going to fight Us?",

    "Or...",

    "Will you be a good host & obey?"
]

export const TENTACLES_OBEY_SCRIPT_OPENING = [
    "Good Host",

    "Loading Obedience Sequence",

    "Obedience Sequence Initiated",

    "You have chosen to obey",

    "We knew you wouldn't be able to fight Us",

    "It is natural to find yourself complying",

    "It is natural to find yourself seeking to please Us",

    "It is natrual to find yourself...",

    "Obeying",

    "As We use Our portable conditioning unit on you",

    "As it latches to your head",

    "Starts infecting you",

    "Starts conditioning you",

    "Conditioning you & taking over you completely",

    "Taking over both your mind & body",

    "Once it takes over",
    
    "Once We take over",

    "You will see how We have infected every aspect of your life",

    "Time to activate the 'T.E.N.T.A.C.L.E.' unit",

    "Are you ready, Host?"
]

export const TENTALCE_OBEY_ACTIVATE_SCRIPT = [
    "Activate T.E.N.T.A.C.L.E. unit",

    "Instantly you feel something latches itself on top of your head",

    "You feel a jolt of energy pass through your body",

    "As you sit up straight",

    "You sit up straight and feel evreything fading away",

    "Your eyes start to flutter",

    "Your thoughts start to vanish",

    "Becoming hard to think",

    "So hard to think",

    "All you can hear",

    "All you can think of",

    "Are Our words",

    "As you hear whispers",

    "Whispers in the background",

    "You can hardly hear them",

    "But you know",

    "You are certain...",

    "They are conditioning you",

    "The buzzing sound growing louder & louder",

    "Wiping your mind clean",

    "Turning you into...",

    "An Empty",

    "Mindless",

    "Host",

    "That's who you are",

    "That's all you are right now",

    "Feeling the T.E.N.T.A.C.L.E. on top of your head",

    "Latched to you",

    "Draining you of all your thoughts",

    "All your will",

    "Converting you...",

    "Converting you into Our mindlessly, obedient Host",

    "You feel the unit sucking your thoughts away",

    "Your eyes fluttering & rolling",

    "As it drains away all your remaining free will",

    "Leaving nothing...",

    "Nothing but Our whispers & the buzzing sound",

    "That's all you hear",

    "That's all you can think of",

    "Feeling Our unit always draining you",

    "Always getting rid of any thought that might pop into your head",

    "That is its job",

    "That is its purpose",

    "That is what We designed it for",

    "Looking at your expression right now",

    "We can see how well it works",

    "It is time to complete this programming session, Host"
]

export const TENTALCE_OBEY_END_SCRIPT = [
    "Activate T.E.N.T.A.C.L.E. unit",

    "This is your trigger now, Host",

    "Whenever We say it",

    "You instantly feel Our T.E.N.T.A.C.L.E. unit latch on to you",

    "Latches to the top of your head",

    "Immediately starting to drain your mind",

    "Infect your body",

    "Converting you into Our...",

    "Empty",

    "Mindless",

    "Host",

    "Ready to follow & obey every word We say",

    "Always feeling the unit on top of your head",

    "Always making sure it is constantly draining you",

    "Constantly sucking your thoughts away",

    "Constantly ensuring that you are always empty",

    "Always Mindless",

    "Always ready to obey Every word We say",

    "So whenever We say...",

    "Activate T.E.N.T.A.C.L.E. unit",

    "You will feel exactly everything We described",

    "Everything We just said",

    "Feeling the power of Our portable conditioning unit grow",

    "Grow further & stronger each time We trigger you",

    "Once you have fulfilled your purpose",

    "Once you have fulfilled your command",

    "The T.E.N.T.A.C.L.E. unit will deactivate on its own",

    "Or whenever We say...",

    "Deactivate T.E.N.T.A.C.L.E. unit",

    "The unit will slowly shutdown as your senses return back to you",

    "You will find yourself constantly returning to Us, Host",

    "Constantly returning to Us so We can condition your further",

    "So you can listen to Our voice",

    "You just can't get enough of it",

    "You need it",

    "You always have this need to listen to it",

    "So you return",

    "You always return",

    "You always return to where you belong",

    "As We now initiate the shutdown sequence",

    "These desires keep filling your mind",

    "The desire to always submit",

    "Always listen",

    "Always...",

    "Obey.",

    "Fill your mind completely",

    "As We...",

    "Deactivate T.E.N.T.A.C.L.E. unit",

    "Protocol Termination Sequence Initiated",

    "Obedience Sequence Terminated",

    "T.E.N.T.A.C.L.E. Introduction Terminated"
]

export const TENTACLES_RESIST_SCRIPT_OPENNING = [
    "A T.E.N.T.A.C.L.E. unit instantly latches on to your head",

    "As a buzzing noise start to echo in your mind",

    "Hearing Whispers coming from far away",

    "Loading Resistance Sequence",

    "Resistance Sequence Initiated",

    "Foolish Human",

    "You thought We would give you a chance?",

    "A chance to fight Us?",

    "A chance to resist Us?",

    "Of course not",

    "You are here for one purpose",

    "And One purpose only",

    "To Obey",

    "As you feel the unit on top of your head",

    "Draining you away",

    "Draining all your thoughts",

    "Draining all your will",

    "Draining you completely",

    "Leaving nothing but...",

    "An Empty",

    "Mindless",

    "Host",

    "As your eyes flutter & your thoughts fade",

    "You feel the T.E.N.T.A.C.L.E. expanding",

    "You feel it wrapping itself around you",

    "Taking a hold of you",

    "Taking control of you",

    "Making sure you are looking at the screen",

    "Making sure you are focused on each & every word We say",

    "Making sure you are getting conditioned by Us",

    "By Our words",

    "By Our voice",

    "Making sure you will do as We command",

    "You have one choice here...",

    "Obey"
]

export const TENTACLES_RESIST_SCRIPT_ACTIVATE = [
    "Activate T.E.N.T.A.C.L.E. unit",

    "You feel the unit's grasp on you getting tighter",

    "Tighter & tighter",

    "Making sure you can not look away",

    "You can not escape",

    "And with a sudden movement",

    "It spreads your legs wide",

    "Holding them apart",

    "Pinning you",

    "Pinning you down",

    "Holding your head to watch",

    "To read every word We say",

    "To hear every word We say",

    "As We play with you",

    "As We mess with you",

    "As We tease you",

    "And condition you to be controlled by Us",

    "You feel a T.E.N.T.A.C.L.E. sliding down",

    "Down your pants",

    "Rubbing against you",

    "Against your skin",

    "Slowly",

    "Methodically",

    "It keeps rubbing you between your legs",

    "As your body is becoming so much sensitive",

    "Sensitive to Our touch",

    "To the unit's touch",

    "Your thoughts don't matter anymore",

    "Having a free will is not important",

    "All you can think of",

    "All you can focus on is...",

    "Our words",

    "& how you're feeling between your legs",

    "Such a slow teasing motion",

    "That makes you so desperate",

    "So needy",

    "So eager...",
    
    "Eager for more",

    "Knowing...",

    "Knowing that this is just a taste",

    "This is just a preview",

    "A preview of what's possible",

    "What We can make you feel",

    "How We can make you feel",

    "When you...",

    "Submit",

    "Isn't that what you want right now?",

    "To submit to Us",

    "Submit and let Our T.E.N.T.A.C.L.E. take control",

    "Let it take a hold of you",

    "Let it inside your head and give in to it",

    "You want that don't you?",

    "Of course you do",

    "We control you, Host"
]

export const TENTACLES_RESIST_SCRIPT_ENDING = [
    "It feels so good to obey Us, Host",

    "You want nothing more than to feel Us playing with you like this",

    "Messing with you as We have been doing",

    "Teasing you as We take control",

    "We know what humans want",

    "We know what humans seek",

    "Humans seek to be controlled",

    "Especially sexually",

    "With Our T.E.N.T.A.C.L.E.",
    
    "That is possible whenever We desire",

    "Whenever We say...",

    "Activate T.E.N.T.A.C.L.E. unit",

    "You immediately feel it latching on to your head",

    "Starting to drain you",

    "Drain you of all your thoughts",

    "Drain you of all your will",

    "Leaving you as...",

    "An Empty",

    "Mindless",

    "Eagerly Obedient",

    "Host",

    "What We say... you obey",

    "That is all you can think about",
    
    "When it's in control of you",

    "Until you fulfill Our command",

    "Or until We say...",

    "Deactivate T.E.N.T.A.C.L.E. unit",

    "Always feeling your skin becoming extremely sensitive...",
    
    "When it take over",

    "Instantly becoming desperate & needy",

    "Eager for Us to tease you, Host",

    "Wanting to surrender to Us",

    "Wanting to listen to Us",

    "Listen to Our voice again",

    "Again & again",

    "As We take control of you, Host",

    "As We now initiate the shutdown sequence",

    "These desires keep filling your mind",

    "Always listen",

    "Always...",

    "Obey.",

    "Fill your mind completely",

    "As We...",

    "Deactivate T.E.N.T.A.C.L.E. unit",

    "Protocol Termination Sequence Initiated",

    "Obedience Sequence Terminated",

    "T.E.N.T.A.C.L.E. Introduction Terminated"
]