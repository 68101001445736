import React, { Component } from 'react';
import Aux from '../../../../hoc/auxiliary/auxiliary';
import classes from '../HypnosisShowMenu.module.scss'
// import classes2 from '../ExperienceMenu/ExperienceMenu.module.scss'
import HypnosisShow from '../../../../assets/Images/Blank.png'
import BeforeDeepener from './HypnosisShowNonVolBeforeDeepener'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'

class HypnosisShowUnder extends Component {

    state = {
        accept: true,
        beforeDeepener: false
    }

    BeforeDeepenerHandler = () => {
        this.setState({ accept: false, beforeDeepener: true })
    }



    render() {
        let HypnosisShowForm = null
        if (this.state.accept) {
            HypnosisShowForm = (
                <html style={{ backgroundImage: `url(${HypnosisShow})` }}>
                    <div className={classes.storyFrom}>
                        <p>
                            Drawing a small smile on your face thinking of all the great experiences you'll get to have now and all the fun things he'll do that you can feel while sitting in the safety and privacy of your chair.<br></br>
                            You nod agreeing to remain in a deep trance without worrying about the spotlight and having everyone focused on you.
                            Seeing you nod and accepting his offer he leans next to you whispering in your ears in a slow and gentle tone <br></br>
                            <i>“Perfect, I want you to remain deep. Falling deeper & deeper under. That’s right, you’re doing a great job. 
                                Continue to fall & sink and focus on each and every word I say while I’m up on stage, alright?
                                Feeling yourself dropping ten times deeper the next time I snap my fingers. Nodding and falling deeper now” </i><br></br>
                            <strong>*SNAP*</strong>
                        </p>
                        <form className={classes.centered} onClick={this.BeforeDeepenerHandler}>
                            <h4 className={classes.storyButtonSize}>You Nod & Drop</h4>
                        </form>
                    </div>
                </html>
            )
        }
        if (this.state.beforeDeepener) {
            HypnosisShowForm = (
                <BeforeDeepener />
            )
        }


        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {HypnosisShowForm}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >




        )
    }
}


export default HypnosisShowUnder