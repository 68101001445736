import React from 'react';
import TypingEffect from 'react-typing-effect';
import Delay from 'react-delay'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import classes from '../../HostsRunComponent.module.css'
import Sound from 'react-sound'
import Metronome1 from '../../../../../assets/audio/Metronome1.mp3'
import Metronome2 from '../../../../../assets/audio/Metronome2.mp3'

const Metronome = (props) => {
    // let praiseChoice = 0
    // praiseChoice = Math.floor(Math.random() * props.praises.length);
    // let praise = props.praises[praiseChoice]
    let MetronomeDuration = Math.floor(Math.random() * 2) + 1
    let metronomeDelay = 0
    let metronomeAudio = null
    let metronomeMin = ''
    if (MetronomeDuration === 1){
        metronomeAudio = (<Sound  url={Metronome1}
        playStatus={Sound.status.PLAYING} />)
        metronomeMin = '1 minute'
        metronomeDelay = 60000
    } else {
        metronomeAudio = (<Sound  url={Metronome2}
        playStatus={Sound.status.PLAYING} />)
        metronomeMin = '2 minutes'
        metronomeDelay = 120000
    }
    // eslint-disable-next-line
    const MetronomeMessage = "Deeper and deeper you go like the " + // eslint-disable-next-line
                              (props.title) + ' you are\n\
                              Deeper and deeper you go for Us, ' + // eslint-disable-next-line
                              (props.title) +" "+(props.naming) + "\n\
                              So easy to fall for Us\n\
                              So natural to give in to Us\n\
                              There is nothing you want more than to do as We tell you\n\
                              There is no other place you'd rather be than being under Our control\n\
                              You are doing so well\n\
                              We are pleased with you\n\
                              This is why We will take you deeper\n\
                              This is why We will take you deeper down\n\
                              As you listen to Our Metronome for the next " + // eslint-disable-next-line
                              metronomeMin + ' \n\
                              You will give in even more\n\
                              You will let yourself go\n\
                              Let yourself go completely as We take over\n\
                              As We take over your mind, ' + // eslint-disable-next-line
                              (props.naming)
    let speed = parseInt(props.speed) +5
    let duration = 150 + (MetronomeMessage.length * speed)
    let metronomeTime = duration + metronomeDelay
    let metronomeDeepenerParagraph = (
            <Aux>
                <h3 className= {classes.titles}>METRONOME DEEPENER</h3>
                <TypingEffect typingDelay={100} text={MetronomeMessage} speed={props.speed} eraseDelay={1000000} />
                <Delay wait={duration}>{metronomeAudio}</Delay>
                <Delay wait={metronomeTime}><h4 className={classes.blinking} onClick={props.metronomeDeepener}>The Metronome took my thoughts away</h4></Delay>
            </Aux>
        )


    return metronomeDeepenerParagraph
}


export default Metronome