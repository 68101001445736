import React from 'react';
import TypingEffect from 'react-typing-effect';
import Delay from 'react-delay'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import classes from '../../FreerunComponent.module.css'

const ending = (props) => {
    
    // eslint-disable-next-line
    const ending = 'It is time for Us to log off, ' + (props.naming)+ '\n\
                    It is time for Us to leave\n\
                    We know you will return\n\
                    We made sure that you would\n\
                    We know you want more\n\
                    We know you want so much more\n\
                    And you know how you can get more\n\
                    When you join Us\n\
                    When you join Our "Hosts Chamber"\n\
                    You will experience so much more\n'
                    + // eslint-disable-next-line
                    (props.naming) + ', It is time for you to truly join Us\n\
                    It is time for you to experience The True Power of The Hypno Virus\n\
                    So as We are about to leave you now\n\
                    You know what you have to do...\n\n\
                    The Hypno Virus is logging off'
    let duration = 150 + (ending.length * 115)
    let endingaragraph = (
            <Aux>
                <h3 className= {classes.titles}>ENDING</h3>
                <TypingEffect typingDelay={5000} text={ending} speed={110} eraseDelay={1000000} />
                <Delay wait={duration+5000}><h4 className={classes.blinking} onClick={props.ending}>I must join The Hypno Virus!</h4></Delay>
            </Aux>
    )
    return endingaragraph
}


export default ending