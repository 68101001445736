import React, { Component } from 'react'
// import Input from '../../../components/UI/Input/Input'
import Button from '../../../components/UI/Button/Button'
import classes from '../Account.module.css'
import Aux from '../../../hoc/auxiliary/auxiliary';
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux'
import Title from './Title'
import ProfileOptions from './Profile_Options'

class Options extends Component {

    componentDidMount() {
        this.props.onViewProfileOptions(this.props.token, this.props.userId)
        this.props.onFetchView()
    }

    viewProfileOptionsHandler = (event) => {
        event.preventDefault();
        this.props.onOptionsView()
    }

    viewTitlesHandler = (event) => {
        event.preventDefault();
        this.props.onTitlesView()
    }




    ReturnBackHandler = (event) => {
        event.preventDefault();
        this.props.onReturnBack()
    }

    render() {

        let form = (<div className={classes.AccountContainer}>
            <form className={classes.ViewProfile}>
                <h2>Select Option</h2>
                <div className={classes.AuthButtons}>
                    <Button btnType="Success" clicked={this.viewProfileOptionsHandler}>Profile Options</Button> <br></br>
                    <Button btnType="Success" clicked={this.viewTitlesHandler}>Titles</Button> <br></br>
                    <Button btnType="Danger" clicked={this.ReturnBackHandler}>Back</Button>
                </div>
            </form>
        </div>
        )



        if (this.props.viewTitle) {
            form = <Title />
        }

        if (this.props.viewOptions) {
            form = <ProfileOptions profileOptions={this.props.profileOptions} />
        }

        return (
            <Aux>
                <div className={classes.Account}>
                    {form}
                </div>
            </Aux >

        );
    }
}

const mapStatesToProps = state => {
    return {
        loading: state.runs.loading,
        error: state.auth.error,
        token: state.auth.token,
        userId: state.auth.userId,
        title: state.auth.title,
        viewOptions: state.options.viewOptions,
        profileOptions: state.options.profileOptions,
        viewTitle: state.titles.viewTitle
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onViewProfileOptions: (token, userId) => dispatch(actions.viewProfileOptions(token, userId)),
        onFetchView: () => dispatch(actions.fetchTitles()),
        onTitlesView: () => dispatch(actions.viewTitle()),
        onOptionsView: () => dispatch(actions.viewOptions()),
        onReturnBack: () => dispatch(actions.returnBackSuccess()),
    }
}

export default connect(mapStatesToProps, mapDispatchToProps)(Options);