import React, { Component } from 'react'
import Button from '../../components/UI/Button/Button'
import classes from './HostsChamber.module.css'
import Aux from '../../hoc/auxiliary/auxiliary';
import * as actions from '../../store/actions/index';
import Spinner from '../../components/UI/Spinner/Spinner';
import { connect } from 'react-redux'
import HostsChamberComponent from '../../components/HostsChamber/HostsChamberComponent'
import axios from '../../axios';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'
import MantraChamberComponent from '../../components/HostsChamber/MantraChamber/MantraChamberComponent';
import ConditioningStages from './CondititoningStages/ConditioningStages'
import TextToSpeech from '../../components/HostsChamber/TextToSpeech/TextToSpeech'
import AudioRuns from './AudioRuns/AudioRuns'
import { Redirect } from 'react-router-dom'
import Sound from 'react-sound'
import Bell from '../../assets/audio/bell.mp3'
import Metronome from '../../assets/audio/Metronome2.mp3'
import Mantra from '../../assets/audio/mantralong.mp3'
import Ticking from '../../assets/audio/Ticking3.mp3'
import Beta from '../../assets/audio/beta.mp3'
import Background from '../../components/Background/Background'
import WebsiteAudio from '../../components/Background/WebsiteAudio';

class HostsChamber extends Component {
    state = {
        formIsValid: false,
        HostsChamberUnlocked: false,
        HostsChamberValid: false,
        MantraChamberValid: false,
        redirectPath: false,
        mantraVisible: true
    }

    componentWillMount = () => {
        if (this.props.history.location.pathname !== '/HostsChamber') {
            this.setState({ redirectPath: true })

        }
        this.props.onViewProfileOptions(this.props.token, this.props.userId)
        this.props.onfetchTitle(this.props.token, this.props.userId)
    }

    enterHostChamberHandler = () => {
        this.setState({ HostsChamberValid: true })
        this.props.history.replace('/HostsChamber/Hosts-Experience')
        this.props.onStartHostsChamber()
    }

    enterMantraChamberHandler = () => {
        this.setState({ MantraChamberValid: true })
        this.props.history.replace('/HostsChamber/Mantra-Chamber')

    }

    enterConditioningStagesHandler = () => {
        this.props.onViewConditioning_Stages()
        this.props.history.replace('/HostsChamber/Conditioning-Stages')
    }

    enterAudioRunsHandler = () => {
        this.props.onViewAudioRun()
        this.props.history.replace('/HostsChamber/Audio-Runs')
    }

    enterText2SpeechHandler = () => {
        this.props.onViewTextToSpeech()
        this.props.history.replace('/HostsChamber/Text-to-Speech')
    }

    mantraVisibleHandler = () => {
        this.setState(prevState => {
            return { mantraVisible: !prevState.mantraVisible }
        })
    }


    render() {

        let audio_option = null
        if (this.props.profileOptions.audio_option === '1') {
            audio_option = <Sound url={Bell} playStatus={Sound.status.PLAYING} loop={true} />
        } else if (this.props.profileOptions.audio_option === '2') {
            audio_option = <Sound url={Metronome} playStatus={Sound.status.PLAYING} loop={true} />
        } else if (this.props.profileOptions.audio_option === '3') {
            audio_option = <Sound url={Ticking} playStatus={Sound.status.PLAYING} loop={true} />
        } else if (this.props.profileOptions.audio_option === '4') {
            audio_option = <Sound url={Mantra} playStatus={Sound.status.PLAYING} loop={true} />
        } else if (this.props.profileOptions.audio_option === '5') {
            audio_option = <Sound url={Beta} playStatus={Sound.status.PLAYING} loop={true} />
        }

        let form = null
        let hostsChamber = null

        if (this.props.token !== null || !this.props.restartHostRun) {
            form = (
                <Aux>
                    <Background />
                    <WebsiteAudio />
                    <div>
                        <h3>Choose A Chamber to Enter, {this.props.hostName}</h3>
                        <div className={classes.HostButtons}>
                            <Button btnType="Success" className={classes.HostChamberButton} clicked={this.enterHostChamberHandler}>Hosts Experience</Button><br></br>
                            <Button btnType="Success" className={classes.HostChamberButton} clicked={this.enterMantraChamberHandler}>Mantra Chamber</Button><br></br>
                            <Button btnType="Success" className={classes.HostChamberButton} clicked={this.enterConditioningStagesHandler}>Conditioning Stages</Button><br></br>
                            <Button btnType="Success" className={classes.HostChamberButton} clicked={this.enterAudioRunsHandler}>Audio Runs</Button><br></br>
                            <Button btnType="Success" className={classes.HostChamberButton} clicked={this.enterText2SpeechHandler}>Text To Speech</Button><br></br>
                        </div>
                    </div>
                </Aux>
            )
        }
        if (this.state.HostsChamberValid) {
            form = (
                <div >
                    <div className={classes.FormStyle}>
                        <Background />
                        <HostsChamberComponent audio_option={audio_option} />
                    </div>
                </div>
            )
        }
        if (this.state.MantraChamberValid) {
            if (this.state.mantraVisible) {
                form = (
                    <Aux>
                        <Background />
                        <MantraChamberComponent mantraSwitch={setTimeout(this.mantraVisibleHandler, 20000)} mantraVisible={this.state.mantraVisible} />
                    </Aux>
                )
            }
            else if (!this.state.mantraVisible) {

                form = (
                    <Aux>
                        <Background />
                        <MantraChamberComponent mantraSwitch={setTimeout(this.mantraVisibleHandler, 0)} mantraVisible={this.state.mantraVisible} />
                    </Aux>

                )
            }
        }
        if (this.props.conditioningStages) {
            form = (
                <Aux>
                    <div className={classes.FormStyle}>
                        {/* <Background /> */}
                        <ConditioningStages/>
                    </div>
                </Aux>

            )
        }
        
        if (this.props.audioRuns) {
            form = (
                <Aux>
                    <AudioRuns />
                </Aux>
            )
        }

        if (this.props.text2speech) {
            form = (
                <Aux>
                    <TextToSpeech />
                </Aux>
            )
        }

        if (this.props.loading) {
            form = <Spinner className={classes.Spinner} />
        }

        let errorMessage = null

        if (this.props.error) {
            errorMessage = (

                <p className={classes.error}>{this.props.error}</p>
            )
        }

        if (this.state.redirectPath) {
            hostsChamber = <Redirect to='/' />
        }

        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            <div className={classes.HostsChamber}>
                                {errorMessage}
                                {hostsChamber}
                                {form}
                            </div>
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >
            </Aux >

        );
    }
}

const mapStatesToProps = state => {
    return {
        loading: state.hostsChamber.loading,
        token: state.auth.token,
        userId: state.auth.userId,
        error: state.hostsChamber.error,
        hostName: state.auth.accountDetails.name,
        restartHostRun: state.hostsChamber.restartHostRun,
        profileOptions: state.options.profileOptions,
        conditioningStages: state.hostsChamber.conditioningStages,
        audioRuns: state.hostsChamber.audioRuns,
        text2speech: state.hostsChamber.text2speech,
        Title:state.titles.title
        // hostGender: state.auth.accountDetails.gender,
        // runSpeed: state.auth.accountDetails.speed,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onViewProfileOptions: (token, userId) => dispatch(actions.viewProfileOptions(token, userId)),
        onStartHostsChamber: () => dispatch(actions.onStartHostsChamber()),
        onfetchTitle: (token, userId) => dispatch(actions.fetchTitle(token, userId)),
        onViewConditioning_Stages: () => dispatch(actions.onViewConditioning_Stages()),
        onViewAudioRun: () => dispatch(actions.onViewAudioRun()),
        onViewTextToSpeech: () => dispatch(actions.onViewTextToSpeech()),
    }
}

export default connect(mapStatesToProps, mapDispatchToProps)(withErrorHandler(HostsChamber, axios));