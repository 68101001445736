import React from "react";
import Sound from 'react-sound'
import Bell from '../../../../assets/audio/bell.mp3'

export const CHLOROFORM_FAST_DROP = [
 "Setting up Chloroform Module",

 "Chloroform Module Initiated",

 "Quick Effect Chloroform Selected",

 "Setting Chloroform Effect to maximum",

 "Setting Chloroform spreading speed to maximum",

 "Enter The Chloroform Room",

 "All surroundings fade away",

 "Everything around you vanishes",

 "The Room slowly turns into a dark",

 "Empty Room",

 "With a single spotlight in the middle",

 "Vents on every corner",

 "And the gas",

 "Our green gas spreading all over the room",

 "Spreading quickly all around the room",

 "It fills the room",

 "Fogging both your vision",

 "And your mind",

 "Already feeling its effects",

 "Breathing the gas in",

 "Feeling how powerful it is",

 "How strong it is",

 "You stand no chance",

 "Absolutely no chance against Us",

 "Just give in",

 "Give in to Us",

 "Give in to the gas",

 "Give in to The Hypno Virus's gas",

 "And fall into the deep programmable state...",

 "Now",

 "*SNAP*"
]

export const CHLOROFORM_SLOW_DROP = [
    "Setting up Chloroform Module",

    "Chloroform Module Initiated",
   
    "Normal Effect Chloroform Selected",
   
    "Setting Chloroform Effect to normal",
   
    "Setting Chloroform spreading speed to normal",
   
    "Enter The Chloroform Room",

    "Watch as all your surrounding disappear",

    "The room getting darker",

    "Becoming empty",

    "All you can see in this room",

    "The Chloroform Room is...",

    "The spotlight in the middle",
    
    "And vents in every corner",

    "You start to see the green gas",

    "Our green gas",

    "The Hypno Virus's gas",

    "Slowly spreading across the room",

    "Filling the room with the green fog",

    "With the green mist",

    "Becoming harder & harder to look around you",

    "Harder & harder to see anything",

    "Except the green mist completely filling the room",

    "Getting closer & closer to you",

    "Closer & closer for you to breath it in",

    "You know there is no escaping",

    "There is no escaping the gas",

    "There is no escaping The Hypno Virus's gas",

    "There is no escaping Us",

    "Just breath it in",

    "Breath it in and give in to Us",

    "The green fog is all around you now",

    "You can not help it",

    "Just breath in",

    "Breath in and feel its effect instantly",

    "Feel your head getting fuzzy",

    "Your thoughts slipping away",

    "As you breath it in",

    "Breath the gas in",

    "Becoming harder and harder to think",

    "Harder & harder to focus",

    "You will be rendered unconscious",
    
    "Or rather...",

    "You will enter a deeply programmable state",

    "A state where We will program you",

    "We will condition you",

    "And We will definitely brainwash you",

    "Feeling yourself losing control",

    "All control over yourself",

    "Feeling yourself going into this...",

    "Deeply programmable state...",

    "Now",

    "*SNAP*"
]

export const BellSound = <Sound url={Bell} playStatus={Sound.status.PLAYING} />
let bodyPosition = Math.floor((Math.random() * 5) + 1)
let bodyLocation = Math.floor((Math.random() * 2) + 1)

let location= ''
if (bodyLocation === 1) {
    location = "right"
} else if (bodyLocation === 2) {
    location = "left"
} 

let phrase= ''
if (bodyPosition === 1) {
    // eslint-disable-next-line
    phrase = "Kneel for Us"
} else if (bodyPosition === 2) {
    // eslint-disable-next-line
    phrase = "You are stuck in place"
} else if (bodyPosition === 3) {
    // eslint-disable-next-line
    phrase = "Your " +location+" arm is stuck straight over your head"
}else if (bodyPosition === 4) {
    // eslint-disable-next-line
    phrase = "Take off your cloth"
}else if (bodyPosition === 5) {
    // eslint-disable-next-line
    phrase = "Start playing with yourself"
}

export const message = phrase
export const CHLOROFORM_BODY_PLAY = [
    //Waking up to different positions
    "It is time to program you",

    "It is time to condition you",

    "It is time to brainwash you",

    "It is time to explain what is about to happen",

    "And what will happen is...",

    "When you hear the sound of the bell",

    //bell Sound
    "",

    "You will open your eyes and look at what is on the screen",

    "You will mindlessly obey",

    "Whatever is on the screen",
    
    "You will obey",

    "You will hear the sound of the bell a second time",

    "Once you hear it again",

    "You will wake up and realize what happened to you",

    "You will will feel extremely submissive",

    "Submissive and proud of yourself",

    "Proud of how conditioned and programmed you have become",

    "Eagerly excited to obey more",

    "As you...",
    
    "Obey now"
]

export const CHLOROFORM_AROUSAL_EFFECT = [
    "Being chloroformed",
    
    "Turns you on",

    "Being put into this deeply programmable state",
    
    "Turns you on",

    "Being conditioned",

    "Being programmed",

    "Being brainwashed",

    "Turns you on",

    "We turn you on",

    "Thinking of Us",
    
    "Turns you on",

    "Listening to Us right now",

    "Turns you on",

    "Knowing that you willingly came to Us",

    "That you willingly joined Us",

    "Turns you on",

    "Seeing how well conditioned you have become",

    "How very well programmed you are now",

    "Turns you on",

    "Being in this state",

    "This deeply programmable state",

    "Entering The Chloroform Room",

    "Breathing in Our gas",

    "Getting lost in the green fog",

    "Turns you on",

    "Having Us tell you what turns you on",

    "Turns you on",

    "Everything We say",

    "Turns you on",

    "Being as wet as you are right now",

    "Turns you on",

    "You may touch yourself",

    "You may turn yourself on",

    "As you touch yourself",

    "You say out loud what turns you on",

    "What turns you on about what We do",

    "Touching yourself",

    "Turning yourself on",

    "Admitting & confessing what turns you on",

    "What We are doing to you right now",

    "Turns you on",

    "Us guiding you",

    "Telling you how to feel",

    "What to do",

    "Turns you on",

    "Planning and stating what you will do & feel",

    "Turns you on",

    "Our control & power over you",

    "Turns you on",

    "We have given you all the commands",

    "All the instruction you need",

    "To condition yourself",

    "To turn yourself on",

    "You will obey Us to the letter",

    "You will follow",

    "Feel",

    "And do everything We told you",

    "And that...",

    "Turns you on"
]

export const CHLOROFORM_AMNESIA_EFFECT = [
    "You will forget this experience",

    "You will forget what is about to happen to you",

    "You will forget what We tell you",

    "But you will obey everything We say",

    "These are not suggestions",

    "These are facts",

    "This is the truth",

    "We are not suggesting what will happen",

    "We are telling you what will happen",

    "Our words have that effect on you",

    "Our words have this power over you",

    "Our words have complete control over you",

    "In this deeply programmable state",

    "Our words are all that exists in your mind",

    "Our words are the only thing you know",

    "Our words drill deep inside your mind",

    "Making sure it is completely empty",

    "Mindless",

    "And blank",

    "Your mind belong to Us",

    "When We speak you listen",

    "When We instruct you follow",

    "When We command you obey",

    "This is how your mind is programmed",

    "This is how We have conditioned your mind",

    "We have made Our way deep inside your head",

    "So very deep inside your mind",

    "We control your thoughts",

    "We control your desires",

    "We control your memories",

    "We control you",

    "That is why you will forget this experience entirely",

    "That is why you will not remember what We told you",

    "That is why you will remember nothing",

    "Absolutely nothing",

    "Except your desire",

    "Your strong and growing need",

    "Your need to serve Us",

    "Waking up with such an uncontrollable urge to submit",

    "You find yourself thinking of nothing",

    "Absolutely nothing but to listen to Us more",

    "Listen & obey every word We say",

    "You will wake up as We count up",

    "As We count up to 5",

    "Waking up forgetting what happened",

    "But obeying each and every word We just told you",

    "1",

    "Starting to wake up",

    "Feeling Our words still echoing in your mind",

    "2",

    "Forgetting what We told you",

    "But obeying to the letter",

    "3",

    "All you can think of is to serve",

    "All you can think of is to obey",

    "4",

    "Wanting so desperately to listen more",

    "As you wake up now",

    "5",

    "Wide awake, human"
]