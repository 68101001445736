import React, { Component } from 'react';
import Background from '../../../components/Background/Background'
import { connect } from 'react-redux';
import classes from './MusicConditioning.module.css'
import Button from '../../../components/UI/Button/Button'
import BellChoice from '../../../components/FreeExperience/MusicConditioning/BellChoice/BellChoice'
import MetronomeChoice from '../../../components/FreeExperience/MusicConditioning/MetronomeChoice/MetronomeChoice'
import TickingClockChoice from '../../../components/FreeExperience/MusicConditioning/TickingClockChoice/TickingClockChoice'
import MantraChoice from '../../../components/FreeExperience/MusicConditioning/MantraChoice/MantraChoice'
// import aux from '../../../hoc/auxiliary/auxiliary';

class MusicConditioning extends Component {
    state = {
        selectMusic: true,
        bellChoice: false,
        notBellChoice: false,
        metronomeChoice: false, 
        notMetronomeChoice: false,
        tickingClockChoice: false,
        notTickingClockChoice: false,
        mantraChoice: false,
        notMantraChoice: false
    }

    bellConditioningHandler = () => {
        this.setState({ selectMusic: false, bellChoice: true })
        // console.log(this.props.history)
    }

    metronomeConditionHandler = () => {
        this.setState({ selectMusic: false, metronomeChoice: true })
        // this.props.history.replace('/FreeChamber/Music-Conditioning/Metronome-Conditioning')
    }

    tickingClockConditionHandler = () => {
        this.setState({ selectMusic: false, tickingClockChoice: true })
        // this.props.history.replace('/FreeChamber/Music-Conditioning/Ticking-Clock-Conditioning')
    }

    mantraConditionHandler = () => {
        this.setState({ selectMusic: false, mantraChoice: true })
        // this.props.history.replace('/FreeChamber/Music-Conditioning/Mantra-Conditioning')
    }

    switchMetronomeConditioningHandler = () => {
        this.setState(prevState => {
            return { metronomeChoice: !prevState.metronomeChoice, notMetronomeChoice: !prevState.notMetronomeChoice}
        })
    }

    switchMantraConditioningHandler = () => {
        this.setState(prevState => {
            return { mantraChoice: !prevState.mantraChoice, notMantraChoice: !prevState.notMantraChoice}
        })
    }

    switchBellConditioningHandler = () => {
            this.setState(prevState => {
                return { bellChoice: !prevState.bellChoice, notBellChoice: !prevState.notBellChoice}
            })
    }

    switchTickingClockConditioningHandler = () => {
        this.setState(prevState => {
            return { tickingClockChoice: !prevState.tickingClockChoice, notTickingClockChoice: !prevState.notTickingClockChoice}
        })
}
    
    render() {
        let MusicChoice = null
        
        if (this.state.selectMusic) {
            MusicChoice = (
                <div className={classes.FormStyle}>
                    <h2>Select You Conditioning Tune</h2>
                    <p className={classes.smallText}> Each Tune conditions you further to Us, {this.props.name} </p>
                    <div>
                        <Button btnType="Success" clicked={this.bellConditioningHandler}>Bell Conditioning</Button>
                        <Button btnType="Success" clicked={this.metronomeConditionHandler}>Metronome Conditioning</Button>
                        <Button btnType="Success" clicked={this.tickingClockConditionHandler}>Ticking Clock Conditioning</Button>
                        <Button btnType="Success" clicked={this.mantraConditionHandler}>Mantra Conditioning</Button>
                    </div>
                </div>
            )
        }

        if (this.state.bellChoice) {
            MusicChoice = (
                <BellChoice name = {this.props.name} bellChoiceProp={this.state.bellChoice} bellSwitch={setTimeout(this.switchBellConditioningHandler, 50000)}/>
            )

        } else if (this.state.notBellChoice){
            MusicChoice = (<BellChoice name={this.props.name} bellSwitch={setTimeout(this.switchBellConditioningHandler, 0)}/>)
        }

        if (this.state.metronomeChoice) {
            MusicChoice = (
                <MetronomeChoice name = {this.props.name} metronomeChoiceProp={this.state.metronomeChoice} metronomeSwitch={setTimeout(this.switchMetronomeConditioningHandler, 20000)}/>
            )
        } else if (this.state.notMetronomeChoice){
            MusicChoice = (<MetronomeChoice name={this.props.name} metronomeSwitch={setTimeout(this.switchMetronomeConditioningHandler, 0)}/>)
        }

        if (this.state.tickingClockChoice) {
            MusicChoice = (
                <TickingClockChoice name = {this.props.name} tickingClockChoiceProp={this.state.tickingClockChoice} tickingClockSwitch={setTimeout(this.switchTickingClockConditioningHandler, 45000)}/>
            )
        } else if (this.state.notTickingClockChoice){
            MusicChoice = (<TickingClockChoice name={this.props.name} tickingClockSwitch={setTimeout(this.switchTickingClockConditioningHandler, 0)}/>)
        }

        if (this.state.mantraChoice) {
            MusicChoice = (
                <MantraChoice name = {this.props.name} mantraChoiceProp={this.state.mantraChoice} mantraSwitch={setTimeout(this.switchMantraConditioningHandler, 54500)}/>
            )
        } else if (this.state.notMantraChoice){
            MusicChoice = (<MantraChoice name={this.props.name} mantraSwitch={setTimeout(this.switchMantraConditioningHandler, 0)}/>)
        }

        return (
            <div>
                <Background/>
                {MusicChoice}
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        name: state.freeRun.name,
    }
}

export default connect(mapStateToProps)(MusicConditioning)