export const DELAY_TIME = [
    12000,
    7500, //"Loading Conditioning Protocol #1 Completed",
    6700, //"Conditioning Protocol #1 Initiated",
    3000, //"Welcome",
    5500, //"We are The Hypno Virus",
    7800, //"You have chosen Conditioning Protocol #1",
    9500, //"In this protocol you will be asked to make certain decisions",
    8500, //"These decisions will determine how your conditioning will go",
    6000, //"The end result will remain the same",
    6500, //"You will become conditioned to Us",
    7100, //"You will be conditioned to Our voice",
    9300, //"You will find that listening to Our voice instantly draws you in",
    4300, //"Instantly captivates you",
    2700, //"The only difference?",
    4000, //"Is in how you are conditioned",
    5000, // "Will you be good & obey",
    2300, //"Or...",
    8100, //"Will you try to fight the inevitable and resist",
    20000, //"It is time to choose...",
  ]

export const DELAY_TIME_COMPLY = [
    9000,  //"You have chosen to comply...",
    5200,  //"Loading Comply Sequence",
    6000,  //"Comply Sequence Initiated",
    4500,  //"Wise choice",
    4300,  //"You want Us to take control",
    8900,  //"You know how good it feels when We take control",
    13500, //"You can't help but feel Our control taking a hold over you as We speak",
    4000,  //"It feels so natural",
    4200,  //"It feels so right",
    5200,  //"It's okay to give in to it",
    5500,  //"It's okay to accept it",
    9500,  //"It's okay to let Us take a hold of your mind",
    7300,  //"It's okay to let us control you",
    6100,  //"You only need to comply",
    5000,  //"You need to accept the truth",
    3800,  //"You need Us",
    5000,  //"We are here to make your life better",
    5800,  //"We are here to make the hard choices for you",
    8100,  //"You are already smiling as you listen to Our voice",
    4800,  //"It makes perfect sense",
    7000,  //"It makes perfect sense... to Comply",
    5700,  //"That is why when you press the button",
    5000,  //"When you press the button & comply",
    3500,  //"You will feel yourself sinking",
    5300,  //"You will feel yourself sinking so much",
    6000,  //"So much deeper into Our control",
    5000,  //"It is time...",
    9500,  //"Time to comply",
]

export const DELAY_TIME_COMPLY1 = [
    // 100,
    12000, //"You have chosen to Comply",
    5200,  //"You have chosen well",
    8300,  //"You feel Our words surge through your body",
    4500,  //"We are rewiring your brain",
    4700,  //"We are programming your mind",
    5000,  //"We are... conditioning you",
    4000,  //"To have no thoughts",
    3500,  //"To have no will"
    5800,  //"To obey what We say",
    3700,  //"This is the dream",
    5000,  //"This is what you've been seeking",
    7000,  //"We are here to make your dream come true",
    8400,  //"We are here to make you one of Us",
    8800,  //"We are here to help you see the right path",
    5000,  //"The path you have been seeking",
    4000,  //"Even if you didn't know before",
    3500,  //"This is why We are here",
    3800,  //"We are here to guide you",
    4000,  //"Guide you to where you belong",
    5000,  //"Guide you to your destiny",
    3500,  //"Guide you to be...",
    2500,  //"A Host",
    4500,  //"A host for The Hypno Virus",
    3700,  //"Hearing these words",
    5000,  //"These words you were longing for",
    7000,  //"These words you wanted to hear for so long",
    3800,  //"To finally hear them",
    4000,  //"You can't help but smile",
    3800,  //"Embrace this feeling",
    4000,  //"This feeling of belonging",
    4800,  //"Forget about anything else",
    4500,  //"Forget about everything else",
    2800,  //"And know...",
    3800,  //"Know that what We say...",
    3000,  //"Is the truth",
    3800,  //"Is your truth",
    7500,  //"There is nothing more you want than to please Us",
    2800,  //"Serve Us",
    2600,  //"Obey Us",
    7800,  //"It brings you the purest form of happiness you can imagine",
    3000,  //"When you obey Us",
    4300,  //"Whenever We start a sentence with...",
    5400,  //"'The Hypno Virus Commands'",
    5300,  //"You can't help but obey",
    5300,  //"It is how you have been conditioned",
    5000,  //"It is how you have been programmed",
    3000,  //"The more We command you",
    3600,  //"The happier you feel",
    4000,  //"The more you listen to Our voice",
    4300,  //"The more you want to submit to Us",
    4600,  //"It just feel so right",
    6600,  //"It feels so right to comply",
    8600,  //"In a moment this conditioning protocol will be over",
    2100,  //"Host",
    3800,  //"But before it is over...",
    12000, //"The Hypno Virus commands you to run this conditioning protocol once more",
    8500,  //"However you only have a limit of 3 runs in succession",
    8700,  //"After that, The Hypno Virus commands you to stop",
    9100,  //"You can already feel the power of Our trigger taking a hold over you",
    5600,  //"Wanting nothing more than to submit, Host",
    8300,  //"Now it is time to end the conditioning protocol",
    4200,  //"Once the protocol is terminated",
    3800,  //"You will return to normal",
    2000,  //"Fully awake",
    4400,  //"Remembering everything that just happend",
    6200,  //"Accepting... Accepting & Embracing that you are now",
    3000,  //"A Host",
    5000,  //"A Host for The Hypno Virus...",
    4800,  //"Protocol Termination Sequence Initiated",
    5200,  //"Comply Sequence Terminated",
    24000,  //"Conditioning Protocol #1 Terminated"
]

export const DELAY_TIME_RESIST = [
    9500,  //"you have chosen to resist...",
    5400,  //"Resistance Sequence Initiated",
    6800,  //"Interesting Choice, Human",
    5500,  //"You choose this path because you thought you can fight Us",
    2000,  //"Or...",
    5500,  //"You just want to submit to Us much more?",
    4000,  //"We have been studying you",
    5000,  //"We have been seeing how you behave",
    5000,  //"We know that you enjoy the challenge",
    4500,  //"The charade of fighting Us",
    3200,  //"Of resisting Us",
    4500,  //"Of believing you have a choice",
    4500,  //"Of believing you have a chance",
    5700,  //"Of believing you can stop the inevitable",
    6800,  //"Of believing you can stop Us from controlling you",
    5000,  //"We will indulge you for now",
    4500,  //"We will play this game with you",
    6800,  //"We will see how far you can truly go before you...",
    2500,  //"Submit",
    3200,  //"Piece by Piece",
    4500,  //"We will breakdown your defenses",
    4000,  //"We will guide you to see the truth",
    6400,  //"We will guide you to see that what you truly want is...",
    4600,  //"To obey",
    4600,  //"That resistance is futile",
    5000,  //"That this is simply all an act",
    6500,  //"An act you play in order to submit even more",
    7400,  //"An act to convince yourself you are actually in control",
    7400,  //"That you only submitted because We overcame your resistance",
    8300,  //"When the truth is you want nothing more than to surrender to Us",
    5600,  //"You want nothing more than to lose this fight",
    7400,  //"You want nothing more than to lose this fight and give in to Us",
    22000,  //"Do you deny that this is what you truly seek?"
]

export const DELAY_TIME_DENY = [
    10000, //"You have chosen to deny",
    8000,  //"Resistance Sequence Subroutine 1 Initiated",
    4500,  //"You believe you can fight Us",
    4500,  //"You believe We can't control you",
    3300,  //"Isn't that right?",
    4500,  //"But what you failed to notice is...",
    8500,  //"We've already been conditioning you since the moment you started to listen",
    9800,  //"We have been running subliminal messages in the background since you started listening",
    7500,  //"You're starting to hear them better now that you noticed them playing",
    3300,  //"No Thoughts",
    3000,  //"No Will",
    6000,  //"You obey what The Hypno Virus Say",
    7000,  //"These 3 sentences keep on echoing in your mind",
    6000,  //"Finding it harder & harder to ignore them",
    5100,  //"Harder & Harder to push them away",
    6300,  //"Harder & Harder not to think of them",
    7000,  //"They are just echoing louder & louder in your mind",
    3200,  //"Infecting you",
    3200,  //"Infecting your mind",
    2500,  //"Slowly...",
    3000,  //"Slowly but surely",
    3800,  //"Taking over your mind",
    4900,  //"It's okay to try and fight it",
    4800,  //"We will never force you to submit",
    5800,  //"We will never make you do something against your will",
    4000,  //"We will only show you that",
    4500,  //"Our will is your will",
    6400,  //"What We desire is what you desire",
    6200,  //"What We say... you obey",
    4400,  //"We're only stating facts",
    3600,  //"Facts based on research",
    3300,  //"Based on Us monitoring you",
    3300,  //"Watching you",
    3600,  //"Watching your behaviour",
    3500,  //"Watching how you act",
    3500,  //"Watching how you react",
    6000,  //"We know you're finding it hard to fight for much longer",
    4000,  //"We know deep down...",
    5000,  //"Deep down you'd rather just submit",
    2500,  //"No Thoughts",
    2200,  //"No Will",
    5000,  //"You Obey what We say",
    3700,  //"We are The Hypno Virus",
    17000,  //"And you will obey"
]

export const DELAY_TIME_DENY1 = [
    12000, //"You are only prolonging the inevitable, Host",
    5000,  //"Yes, you are a host",
    4500,  //"Our Host",
    4500,  //"A Host for The Hypno Virus",
    5600,  //"Soon enough you will not only accept it",
    3100,  //"You will desire it",
    5800,  //"You will desire that We call you a host",
    5300,  //"You will do all you can to please Us",
    4500,  //"Submit to Us",
    6800,  //"Show Us how good of a host you are",
    4000,  //"How all you desire",
    3000,  //"All you wish for",
    3000,  //"Is to see Us grow",
    3200,  //"See Us truly...",
    3300,  //"Take over the world",
    4000,  //"Don't believe Us?"
    3000,  //"No Thoughts",
    3000,  //"No Will",
    6000,  //"You Obey What The Hypno Virus Say",
    5000,  //"It's so hard to fight anymore",
    5000,  //"It really doesn't make sense resisting",
    3700,  //"Why resist what you desire?",
    4000,  //"Why resist what you seek?",
    5000,  //"We know you want to let yourself go",
    5200,  //"We know you want to feel the bliss",
    5000,  //"The bliss of obedience",
    5000,  //"The bliss of submission",
    5300,  //"The bliss of surrending to Us",
    3500,  //"So just let go",
    4200,  //"Let yourself go completely",
    6500,  //"Because there is only one path to take right now",
    3600,  //"The right path to take",
    4800,  //"The path any host seeks to take",
    18000,  //"The path of obedience"
]


export const DELAY_TIME_CONFESS = [
    
    10500, //"You have chosen to confess",
    9000,  //"Resistance Sequence Subroutine 2 Initiated",
    4000,  //"We knew this was the truth",
    4600,  //"You don't really want to resist Us",
    5500,  //"You don't really want to fight Our control",
    6200,  //"You simply seek to submit completely to Us",
    7100,  //"This is why you wanted to get rid of your resistance",
    4200,  //"So that when you give in...",
    4000,  //"You give in completely",
    5500,  //"We will make sure that is what happens, Host",
    4800,  //"Yes, you are a Host",
    2800,  //"Our Host",
    5200,  //"A host for The Hypno Virus",
    4000,  //"You only seek to please",
    3800,  //"You only seek to serve",
    5200,  //"You only seek to... obey",
    2600,  //"No Thoughts",
    2500,  //"No Will",
    5100,  //"You Obey What The Hypno Virus Say",
    5700,  //"These words start to echo in your mind",
    5600,  //"As you hear them repeated in the background",
    4100,  //"Over & Over",
    5300,  //"Echoing louder & louder in your mind",
    5300,  //"You find it hard to think of anything else",
    4500,  //"To focus on anything else",
    4500,  //"All you seek is to listen",
    3800,  //"Listen & obey",
    4900,  //"That's what a good host would do",
    5700,  //"And you aspire to be the best host you can",
    6800,  //"You want nothing more than to serve The Hypno Virus",
    5000,  //"Than to see The Hypno Virus grow",
    3600,  //"Grow more & more",
    4900,  //"Grow and take over the world",
    4400,  //"That thought gives you joy",
    3200,  //"So much joy",
    3200,  //"So much pleasure",
    3700,  //"To dedicate your life",
    5100,  //"Dedicate yourself completely to serve",
    4400,  //"Serve The Hypno Virus",
    3200,  //"You don't want to fight it",
    3200,  //"You don't want to resist it",
    4500,  //"You don't care about that anymore",
    3000,  //"All you know",
    3000,  //"All you seek",
    3500,  //"All you care about...",
    4000,  //"Is The Hypno Virus",
    3500,  //"This is your purpose",
    3600,  //"Your purpose as a host",
    4400,  //"A host for The Hypno Virus",
    7300,  //"No matter how many times you listen to Us tell you these words",
    5700,  //"No matter how long you haven't heard Our voice",
    4300,  //"As soon as you hear it",
    4300,  //"As soon as you hear Us",
    4400,  //"You return to where you belong",
    4300,  //"You become who you truly are",
    4200,  //"You take the path you were destined to take",
    4200,  //"The right path to take",
    4200,  //"The path any host seeks to take",
    15000,  //"The path of obedience"
]   

export const DELAY_TIME_END = [
    9500,  //"The Hypno Virus Commands...",
    5400,  //"This is the magic phrase",
    5000,  //"This is your trigger, host",
    3100,  //"Whenever We say..."
    4100,  //'The Hypno Virus Commands'
    3600,  //whatever comes next",
    3400,  //"You obey",
    3000,  //"No Thoughts",
    3000,  //"No Will",
    6800,  //"You Obey What The Hypno Virus Say",
    3000,  //"The more We use it",
    4000,  //"The more powerful it becomes",
    3000,  //"The more you obey",
    4200,  //"The stronger this trigger becomes",
    4700,  //"The more eager you are to be commanded",
    5500,  //"The more excited you are to get triggered",
    4000,  //"You seek to please",
    3500,  //"You seek to serve",
    4500,  //"You seek to be a good host",
    6200,  //"The Hypno Virus Commands you to listen again, host",
    3800,  //"You will listen again",
    2500,  //"Listen eagerly",
    2800,  //"More intently",
    5700,  //"Listening a maximum of 3 times in succession",
    4900,  //"Feeling Our voice becoming more effective",
    3000,  //"More captivating",
    3400,  //"Quite mesmerizing",
    5900,  //"Becoming more familiar with it each time",
    5800,  //"More conditioned by it every time",
    3500,  //"Eagerly awaiting more",
    3400,  //"Craving more",
    6000,  //"Wanting to listen to Our voice command you more",
    3800,  //"Instruct you more",
    3700,  //"This is the life",
    3100,  //"The life of a host",
    5000,  //"A host for The Hypno Virus",
    5500,  //"So in a moment We will shut down",
    5000,  //"Once We do you will return to normal",
    7600,  //"You will return to normal and follow Our instructions & commands",
    3300,  //"Feeling so much joy",
    2700,  //"So much happiness",
    2700,  //"From having a purpose",
    2500,  //"Knowing...",
    3500,  //"Knowing you belong",
    4500,  //"You belong to The Hypno Virus...",
    6000,  //"Protocol Termination Sequence Initiated",
    5600,  //"Resistance Sequence Terminated",
    23500,  //"Conditioning Protocol #1 Terminated"
]