import React, { Component } from 'react';
import Aux from '../../../../hoc/auxiliary/auxiliary';
import classes from '../HypnosisShowMenu.module.scss'
// import classes2 from '../ExperienceMenu/ExperienceMenu.module.scss'
import HypnosisShow from '../../../../assets/Images/Blank.png'
import Accept from './HypnosisShowNonVolAccept'
import Decline from './HypnosisShowNonVolDecline'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'

class HypnosisFallsReaction extends Component {

    state = {
        fallReaction: true,
        accept: false,
        decline: false
    }

    AcceptHandler = () => {
        this.setState({ fallReaction: false, accept: true })
    }

    DeclineHandler = () => {
        this.setState({ fallReaction: false, decline: true })
    }


    render() {
        let HypnosisShowForm = null
        if (this.state.fallReaction) {
            HypnosisShowForm = (
                <html style={{ backgroundImage: `url(${HypnosisShow})` }}>
                    <div className={classes.storyFrom}>
                        <p>
                        Your body falls limp.<br></br>
                        Sliding down into your chair, feeling yourself engulfed by it as you sink deeper & deeper down and lets yourself go completely <br></br> 
                        No thoughts, no thoughts at all just a big wide smile and the feeling of pure joy and happiness spreading further and further accross your body.
                        Everything around you no longer matters, no longer exists. It's simply him, his words still echoing deep inside your head and that snap's effect lingering deep inside your mind.<br></br>
                        Indulging yourself and enjoying these effects and the feelings they're having over you without a care in the world.
                        Only for you to feel a hand holding your right shoulder, noticing someone's presence next to you as they whisper in your ears and you realize who this is. <br></br>
                        <i>“You’re doing so well. That’s right, Just keep sinking. Keep sinking & falling” </i><br></br>
                        Your body reacting on instinct, relaxing & sinking even deeper. Melting to his touch and getting more comfortable. <br></br>
                        However, your mind races for a second to process what’s happening but before you can do so you hear his voice again and that quiets your mind completely. 
                        Your mind goes silent as if his voice turned it off. Enticed & enthralled you listen to him and allow his voice to continue as he says <br></br>
                        <i>“Just let yourself relax more, enjoy yourself more & more” </i><br></br>
                        You feel his hand squeezing your shoulder in just the right spot, feeling yourself melting even further to him. 
                        Melting and falling deeper to his touch he continues <br></br>
                        <i>“Would you like to join me up on stage?”</i>
                        </p>
                        <form className={classes.centered} onClick={this.AcceptHandler}>
                            <h4 className={classes.storyButtonSize}>Accept</h4>
                        </form>
                        <form className={classes.centered} onClick={this.DeclineHandler}>
                            <h4 className={classes.storyButtonSize}>Decline</h4>
                        </form>
                    </div>
                </html>
            )
        }
        if (this.state.accept) {
            HypnosisShowForm = (
                <Accept />
            )
        }

        if (this.state.decline) {
            HypnosisShowForm = (
                <Decline />
            )
        }

        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {HypnosisShowForm}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >




        )
    }
}


export default HypnosisFallsReaction