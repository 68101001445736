import * as conditionTypes from '../../../actions/ConditioningStages/conditioningStagesTypes';
import { updateObject } from '../../../../shared/utility';

const initialState = {
    error: null,
    obedienceModule: false,
    loading: false
}

const ObedienceStageStart = (state, action) => {
    return updateObject(state, {
        error: null,
        obedienceModule: true,
    })
}



const ObedienceStageComplete = (state, action) => {
    return updateObject(state, {
        error: null,
        obedienceModule: false,
        loading: false
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case conditionTypes.OBEDIENCE_STAGE_START: return ObedienceStageStart(state, action)
        case conditionTypes.OBEDIENCE_STAGE_COMPLETED: return ObedienceStageComplete(state, action)
        default:
            return state

    }
}


export default reducer