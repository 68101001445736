import React from 'react';
import Delay from 'react-delay'
import Aux from '../../../../hoc/auxiliary/auxiliary'
import classes from '../../../../containers/FreeRun/MusicConditioning/MusicConditioning.module.css'
import Sound from 'react-sound'
import Mantra from '../../../../assets/audio/mantralong.mp3'

const mantraChoice = (props) => {
    let hostName = props.name.toUpperCase()
    let mantraChoice = null

    let mantras = ['NO THOUGHTS'
                  ,'NO WILL'
                  ,'YOU OBEY WHAT WE SAY'
                  ,hostName + ', THIS MANTRA...'
                  ,'WE KNOW WHAT IT DOES TO YOU'
                  ,'WE KNOW STRONG ITS EFFECTS ARE'
                  ,'AS SOON AS YOU SEE IT... EVERYTHING ELSE DISAPPEARS'
                  ,'NO THOUGHTS'
                  ,'NO WILL'
                  ,hostName +'... YOU OBEY WHAT WE SAY']

    let mantraChoiceMantra = []
    for (let key = 0; key < mantras.length; key++) {
        mantraChoiceMantra.push({
            id: key,
            config: mantras[key]
        })
    }
    // eslint-disable-next-line
    if (props.mantraChoiceProp) {
        mantraChoice = (
            <Aux>
                <Delay wait={5000}>{mantraChoiceMantra.map(mantra => (
                    <Delay key={mantra.id} wait={mantra.id * 5000}>
                        <h3>{mantraChoiceMantra[mantra.id].config}</h3>
                    </Delay>
                ))}</Delay>
            </Aux>
        )
    } else {
        mantraChoice = null
    }

    return (
        <div className={classes.MantraChoice}>
            <Sound url={Mantra} playStatus={Sound.status.PLAYING} loop={true} />
            {mantraChoice}
        </div>

    )
}


export default mantraChoice
