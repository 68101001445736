import React, { Component } from 'react';
import Aux from '../../../../hoc/auxiliary/auxiliary';
import classes from '../HypnosisShowMenu.module.scss'
// import classes2 from '../ExperienceMenu/ExperienceMenu.module.scss'
import HypnosisShow from '../../../../assets/Images/Blank.png'
import Suggestions from './HypnosisShowNonVolSuggestions'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'

class HypnosisShowForgetNumber extends Component {

    state = {
        forgetNumber: true,
        suggestions: false
    }

    suggestionsHandler = () => {
        this.setState({ forgetNumber: false, suggestions: true })
    }



    render() {
        let HypnosisShowForm = null
        if (this.state.forgetNumber) {
            HypnosisShowForm = (
                <html style={{ backgroundImage: `url(${HypnosisShow})` }}>
                    <div className={classes.storyFrom}>
                        <p>
                            <i>"Deeper & Deeper I go"</i> Repeating it over & over.<br></br>
                            Sinking deeper under with each repetition finding yourself drawn & pulled by his words, by his voice as he says <br></br>
                            <i>“Aren't you having fun? Enjoying yourself & how good you feel. Feeling so good listening to my voice and following it.
                                Deeper & deeper you go for it, Deeper & deeper you go for me. Don't you just love that? <br></br>
                                Of course you do! Wouldn't you love to listen some more and follow what I tell you? Doesn't that sound perfect? <br></br>
                                You know what the best part is? You don't need to do anything but forget one simple number. All you need to do is forget the number five. See, it's that simple!
                                All you need to do is forget the number five when I wake. You can do that can't you? I know you can!” </i> <br></br>
                            Watching intently as he taps one of the volunteer's forehead and says <br></br>
                            <i>“POP! The number five is now gone from your mind. It's locked in a safe place for now and you don't have to worry about it. <br></br>
                                What I want you to do for me right now though is start waking up <br></br>
                                One, Feeling warm & fuzzy. <br></br>
                                Two, Feeling good & happy.<br></br>
                                Three, excited to follow this simple task. <br></br>
                                Four, Forgetting a single number <br></br>
                                Five, wide awake"</i> <br></br>
                            <strong>*SNAP* </strong> <br></br>
                            You take a deep breath in while stretching and feeling your consciousness returning to you.
                            Like waking up from this beautiful nap, waking up from a nice, soothing nap.
                            Your senses returning to you as you remember where you are. What’s going on and what’s happening.
                            Instantly all your focus and attention turn towards the stage as you hear The Great O saying <br></br>
                            <i>“Raise your hands in front of you and count each finger” He points at each finger as he start counting.
                            One<br></br>
                            Two<br></br>
                            Three<br></br>
                            Four<br></br>
                            Six<br></br>
                            Seven<br></br>
                            Eight<br></br>
                            Nine<br></br>
                            Ten<br></br>
                            Eleven"</i><br></br>
                            <i>"Eleven?! <br></br> 
                            How does he have eleven fingers? What going on? Something must be wrong, did the guy count wrong? Did I count wrong?"</i><br></br>
                            You ask yourself all these questions while looking up at stage and seeing the guy looks as confused as you are. 
                            It seems like everyone who was under is confused. <br></br>
                            But before you can process what’s going on The Great O suddenly holds his head as he tells him 
                            <strong>“SLEEP NOW”</strong>
                            The guy just drops deep immediately before anyone realizes what’s going on.
                            You feel this strong wave of relaxation as soon as you realize what happened. It's like a jolt passing through your body relaxing it. <br></br>
                            He looks at you the crowd and you can see him looking directly at you which immediately makes you feel quite calm & relaxed in an exciting way.
                            <i>"Why don't you count in your head along with us next time?"</i><br></br>
                            immediately taking his words as an instruction that you feel compelled to follow rather than just a suggestion you find yourself thinking<br></br>
                            <i>"I should count along with his next volunteer"</i> <br></br>
                            Right on cue he turns around to face one of the volunteers his tone changes to a softer, more relaxing & soothing one telling them <br></br>
                            <i>“Would you kindly raise your hands in front of you?”</i><br></br>
                            Without thinking you find your hands rising up, rising right in front of you as he puts his finger on the volunteers fingers and says<br></br>
                            <i>“I’ll be pointing at each finger and I want you to count along”</i><br></br>
                            <strong>You Comply</strong>
                            <i>One</i><br></br>
                            <i>Two</i><br></br>
                            <i>Three</i><br></br>
                            <i>Four</i><br></br>
                            <i>Six</i><br></br>
                            <i>Seven</i><br></br>
                            <i>Eight</i><br></br>
                            <i>Nine</i><br></br>
                            <i>Ten</i><br></br>
                            <i>Eleven</i><br></br>
                            It takes you a second before you realize...<br></br>
                            <strong>Eleven?!</strong><br></br>
                            You look up at stage as he looks at that volunteer doing something that immediately eases them putting a smile on their face and in turn eases you mirroring their smile. 
                            Especially when he starts talking <br></br>
                            <i>“Let’s try this again, shall we?”</i> Before you register it you feel his hand tapping the side of your head as he says
                            <i>“Pop! Back to normal. Now would you raise your hand again”</i><br></br>
                            Again you comply with him as he starts pointing at the volunteer’s fingers, they start counting and you count along.<br></br>
                            <i>One</i><br></br>
                            <i>Two</i><br></br>
                            <i>Three</i><br></br>
                            <i>Four</i><br></br>
                            <i>Five</i><br></br>
                            <i>Six</i><br></br>
                            <i>Seven</i><br></br>
                            <i>Eight</i><br></br>
                            <i>Nine</i><br></br>
                            <i>Ten</i><br></br>
                            Smiling wide and letting out a sigh of relief knowing that you’re back to normal.
                            Looking at him on stage realizing what he did & how he made you forget the number five.
                            You see him smirking as if he knows what you’re thinking of. <br></br>
                            But, before you can react to anything you feel his hand holding the volunteer’s head as he says in a firm, commanding yet soothing tone<br></br>
                            <strong>“SLEEP NOW”</strong><br></br>
                            Falling deep again, deep in trance. Deep in to this wonderful, blissful state.
                            Loving how deep you are and ready for what he does next
                        </p>
                        <form className={classes.centered} onClick={this.suggestionsHandler}>
                            <h4 className={classes.storyButtonSize}>Ready for what he does next</h4>
                        </form>
                    </div>
                </html>
            )
        }
        if (this.state.suggestions) {
            HypnosisShowForm = (
                <Suggestions />
            )
        }


        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {HypnosisShowForm}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >




        )
    }
}


export default HypnosisShowForgetNumber