import * as audioRunTypes from '../../../actions/AudioRuns/audioRunTypes';
import { updateObject } from '../../../../shared/utility';

const initialState = {
    error: null,
    conditioningProtocol1Complete: false,
    conditioningProtocol1Commands: [],
    loading: false,
}

const conditioningProtocolStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true
    })
}

const conditioningProtocol1Success = (state, action) => {
        return updateObject(state, {
            conditioningProtocol1Commands: action.conditioning_protocol1,
            loading: false,
        })
    }

    



const conditioningProtocolFailed = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    })
}


const conditioningProtocol1Complete = (state, action) => {
    return updateObject(state, {
        error: null,
        conditioningProtocol1Complete: true,
        conditioningProtocol1Commands: [],
        loading: false,
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case audioRunTypes.AUDIO_RUN_START: return conditioningProtocolStart(state, action)
        case audioRunTypes.AUDIO_RUN_FAILED: return conditioningProtocolFailed(state, action)
        case audioRunTypes.CONDITIONING_PROTOCOL1_SUCCESS: return conditioningProtocol1Success(state,action)
        case audioRunTypes.CONDITIONING_PROTOCOL1_COMPLETED: return conditioningProtocol1Complete(state, action)
        default:
            return state

    }
}


export default reducer