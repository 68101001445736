import React from 'react';
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'
import classes from '../../../../../containers/HostsChamber/AudioRuns/AudioRuns.module.css'
import InstantControl from './Instant_Control'
import SlowControl from './Slow_Control'

function MotorFunctionControlIntroductionVisual(props) {

    let innerWidth = window.innerWidth
    let innerHeight = window.innerHeight
    const canvasRef = React.useRef(null)

    React.useEffect(() => {
        const canvas = canvasRef.current
        const ctx = canvas.getContext('2d')



        var centerX = canvas.width / 2;
        var centerY = canvas.height / 2;
        var radius = 0;
        setInterval(function () {
            let period = 5000; // [miliseconds]

            var linearMod = Date.now() % period / period; // this goes from 0 to 1
            var mod = Math.sin(linearMod * Math.PI); // and here with simple easing we create some bouncing
            var r = radius * mod; // voila
            canvas.width = innerWidth; // ugly dirty clean :)
            let randomColor = Math.floor(Math.random() * 2) + 1
            for (let i = 0; i < 150; i++) {
                ctx.beginPath();
                ctx.lineWidth = 100;
                ctx.arc(centerX, centerY, r + i * 10, 0, 2 * Math.PI, false);
                if (randomColor % 2 === 0) {
                        ctx.strokeStyle = 'green'
                } else {
                        ctx.strokeStyle = 'black';  
                }
                ctx.stroke();
            }
        }, 25);

    })

    let motor_function_control_form = null
    if (props.instant_control){
        motor_function_control_form = <InstantControl />
    } 

    if (props.slow_control){
        motor_function_control_form = <SlowControl/>
    }

    let centerText = window.innerHeight / 2 - 55
    let texting = {

        // display: 'flex',
        // flexFlow: 'column',
        whiteSpace: 'pre-line',
        alignItems: 'center',
        fontWeight: 'bold',
        boxSizing: "border-box",
        margin: 'auto',
        marginBottom: '10px',
        padding: '30px 10px',
        width: '50%',
        paddingTop: centerText + 'px',
        color: 'white'

    }


    return (
        <Fullpage >
            <FullPageSections>
                < FullpageSection>
                    <canvas
                        ref={canvasRef}
                        width={innerWidth}
                        height={innerHeight}
                    >
                    </canvas>
                    <div className={classes.Grouping}>
                        <p style={texting}> {motor_function_control_form}</p>
                    </div>
                </FullpageSection>
            </FullPageSections>
        </Fullpage>
    );
}

export default MotorFunctionControlIntroductionVisual;
