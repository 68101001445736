import React, { Component } from 'react';
import Aux from '../../../../hoc/auxiliary/auxiliary';
import classes from '../HypnosisShowMenu.module.scss'
// import classes2 from '../ExperienceMenu/ExperienceMenu.module.scss'
import HypnosisShow from '../../../../assets/Images/Blank.png'
import Snap2 from './HypnosisShowVolSnap2'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'

class HypnosisShowSnap extends Component {

    state = {
        fingerSnap: true,
        pointlessDecision: false,
    }

    pointlessDecisionHandler = () => {
        this.setState({ fingerSnap: false, pointlessDecision: true })
    }

    


    render() {
        let HypnosisShowForm = null
        if (this.state.fingerSnap) {
            HypnosisShowForm = (
                <html style={{ backgroundImage: `url(${HypnosisShow})` }}>
                    <div className={classes.storyFrom}>
                        <p>
                        <i>“Snap my fingers. Immediately falling and dropping deep as soon as you hear the snap of my fingers. Dropping and falling into a nice deep trance, understood?”</i><br></br> 
                        You nod, in a haze with a smile on your face blissfuly empty & excited to hear that snap. <br></br> 
                        <i>“Waking up on the count of five. <br></br>
                        One, starting to wake up. <br></br>
                        Two, feeling wonderful and refreshed. <br></br>
                        Three, Excited & happy. <br></br>
                        Four, Eager to participate and join in the experience<br></br>
                        Five, wide awake now!”</i><br></br>
                        <strong>*SNAP*</strong><br></br>
                        <i>"Happy, smiling wide like you're waking up from this lovely nap, sitting up & stretching. Grab a some water and drink. You've done a great job and you've all been impressivel responsive."</i><br></br>
                        Turning around to face the audience <br></br>
                        <i>“A big round of applause to all the folks up here giving you one heck of a show!" </i><br></br> 
                        People start to cheer and clap, watching and hearing the roar of applaud you're receiving making you smile and feel so good, proud of yourself. <br></br>
                        <i>"I hope you're enjoying the show as well especially those of you who fell under. You're doing a great job!” </i><br></br> 
                        Drinking water, he turns around to face you again. He speaks to you in a calm & comforting tone <br></br>
                        <i>"We've been having a lot of fun, haven't we? I hope you're enjoying yourselves too"</i> <br></br> 
                        Most of you nod happily as some seem to be still in a trance. 
                        You watch him put the water bottle down whispers in their ears something as he gently shakes them & they drop deep again. <br></br>
                        <i>"Now where was I? Ah yes, I remember. I'm curious, what do you remember? Do you remember how you dropped? Do you remember forgetting numbers? Do you remember the cloud & resting on it?"</i> <br></br>
                        You think back on what transpired throughout the show but you are 
                        </p>
                        <form className={classes.centered} onClick={this.pointlessDecisionHandler}>
                            <h3 className={classes.storyButtonSize}>Confused</h3>
                        </form>
                        <form className={classes.centered} onClick={this.pointlessDecisionHandler}>
                            <h3 className={classes.storyButtonSize}>Not Confused</h3>
                        </form>
                        
                    </div>
                </html>
            )
        }
        if (this.state.pointlessDecision) {
            HypnosisShowForm = (
                <Snap2 />
            )
        }

        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {HypnosisShowForm}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >




        )
    }
}


export default HypnosisShowSnap