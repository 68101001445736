import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    dailyCommand: null,
    error: null,
    hypnoVisual: false
}

const dailyCommandStart = (state, action) => {
    return updateObject(state, {error: null})
}

const dailyCommandSuccess = (state, action) => {
    return updateObject(state, {
        dailyCommand: action.dailyCommand,
    })
}

const dailyCommandFail = (state, action) => {
    return updateObject(state, {
        error: action.error
    })
}

const hypnoVisualsActivate = (state, action) => {
    return updateObject(state, {hypnoVisual: true})
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.DAILY_COMMAND_START: return dailyCommandStart(state, action)
        case actionTypes.DAILY_COMMAND_SUCCESS: return dailyCommandSuccess(state, action)
        case actionTypes.DAILY_COMMAND_FAILED: return dailyCommandFail(state, action)
        case actionTypes.HYPNO_VISUAL_ACTIVATE: return hypnoVisualsActivate(state, action)
        default: return state
    }
}

export default reducer