import React, { useState } from "react";
import TextTransition from "react-text-transition";
import TypingEffect from 'react-typing-effect'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import Button from '../../../../UI/Button/Button'
import classes from '../../../../../containers/HostsChamber/AudioRuns/AudioRuns.module.css'
import * as scripts from '../../../../../shared/AudioRuns/TriggerReinforcement/TentaclesReinforcement/TentaclesReinforcementScripts'
import * as Delays from '../../../../../shared/AudioRuns/TriggerReinforcement/TentaclesReinforcement/TentaclesReinforcementDelay'
import Opening from '../../../../../assets/audio_runs/Trigger_Reinforcement/Tentacle_Reinforcement/Tentacle_Reinforcement_Opening.mp3'
import Sound from 'react-sound'
import Delay from "react-delay";
import useInterval from 'react-useinterval';
import { connect } from "react-redux";
import { TentacleReinforcement } from '../../../../../store/actions/AudioRuns/audioRunsIndex'
import DailyPleasure from './Tentacles_Reinforcement_Daily_Pleasure'
import DailySpank from './Tentacles_Reinforcement_Daily_Spank'
import DailyElectricSpank from './Tentacles_Reinforcement_Daily_Electric_Spank'
import CovertFullday from './Tentacles_Reinforcement_Covert_Fullday'
import CovertCommands from './Tentacles_Reinforcement_Covert_Commands'

const DELAY_TEXT = [4800]


function Trigger_Introduction_Opening({ userId, token, TentacleReinforcement, profileOptions }) {

  let [count, setCount] = useState(0);
  let [tentacle_opening, setProtocol] = useState(true)
  let [tentacle_daily, setDailySequence] = useState(false)
  let [tentacle_daily_pleasure, setDailyPleasureSequence] = useState(false)
  let [tentacle_daily_spank, setDailySpankSequence] = useState(false)
  let [tentacle_daily_electric_spank, setDailyElectricSpankSequence] = useState(false)
  let [tentacle_covert, setCovertSequence] = useState(false)
  let [tentacle_covert_fullday, setCovertFulldaySequence] = useState(false)
  let [tentacle_covert_commands, setCovertCommandsSequence] = useState(false)


  const increaseCount = () => {
    if (tentacle_opening && count < scripts.TENTACLES_OPENING_SCRIPT.length) {
      setCount(count + 1);
    }
  };

  useInterval(increaseCount, Delays.TENTACLES_OPENING_DELAY_TIME[count]);

  TentacleReinforcement(userId, token)

  const enterDailySequenceHandler = () => {
    setProtocol(tentacle_opening = false)
    setDailySequence(tentacle_daily = true)

  }

  const enterCovertSequencerHandler = () => {
    setProtocol(tentacle_opening = false)
    setCovertSequence(tentacle_covert = true)

  }

  const enterCovertFulldaySequencerHandler = () => {
    setCovertSequence(tentacle_covert = false)
    setCovertFulldaySequence(tentacle_covert_fullday = true)

  }

  const enterCovertCommandsSequencerHandler = () => {
    setCovertSequence(tentacle_covert = false)
    setCovertCommandsSequence(tentacle_covert_commands = true)
  }

  const enterDailyPleasureSequencerHandler = () => {
    setDailySequence(tentacle_daily = false)
    setDailyPleasureSequence(tentacle_daily_pleasure = true)

  }

  const enterDailySpankSequencerHandler = () => {
    setDailySequence(tentacle_daily = false)
    setDailySpankSequence(tentacle_daily_spank = true)

  }

  const enterDailyElectricSpankSequencerHandler = () => {
    setDailySequence(tentacle_daily = false)
    setDailyElectricSpankSequence(tentacle_daily_electric_spank = true)

  }

  let centerText = window.innerHeight / 2 - 55
  let texting = null
  if (profileOptions.audio_run_background === '0'){
    texting = {

      // display: 'flex',
      // flexFlow: 'column',
      whiteSpace: 'pre-line',
      alignItems: 'center',
      fontWeight: 'bold',
      boxSizing: "border-box",
      margin: 'auto',
      marginBottom: '10px',
      padding: '30px 10px',
      width: '50%',
      paddingTop: centerText + 'px',
      color: 'white'
  
    }
  }

  let tentacles_opening_form = null

  if (tentacle_opening) {
    tentacles_opening_form = (
      <Aux >
        {count < scripts.TENTACLES_OPENING_SCRIPT.length ?
          <div style={texting}>
            <Sound url={Opening} playStatus={Sound.status.PLAYING} />
            <Delay wait={DELAY_TEXT[count]}>
              <TextTransition
                text={<TypingEffect typingDelay={100} text={scripts.TENTACLES_OPENING_SCRIPT[count]} speed={90} eraseDelay={1000000} />}
                Overflow
              />
            </Delay>
          </div>
          :
          <div className={classes.ButtonPadding} style={texting}>
            <Button btnType="Success" clicked={enterDailySequenceHandler}>Daily Conditioning</Button>
            <Button btnType="Success" clicked={enterCovertSequencerHandler}>Covert Conditioning</Button>
          </div>
        }
      </Aux >
    )
  }

  if (tentacle_daily) {
    tentacles_opening_form = (
      <div className={classes.ButtonPadding} style={texting}>
        <Button btnType="Success" clicked={enterDailyPleasureSequencerHandler}>Pleasure Conditioning</Button>
        <Button btnType="Success" clicked={enterDailySpankSequencerHandler}>Spank Conditioning</Button>
        <Button btnType="Success" clicked={enterDailyElectricSpankSequencerHandler}>Electric Spank Conditioning</Button>
      </div>
    )
  }

  if (tentacle_covert) {
    tentacles_opening_form = (
      <div className={classes.ButtonPadding} style={texting}>
        <Button btnType="Success" clicked={enterCovertFulldaySequencerHandler}>Full Day Conditioning</Button>
        <Button btnType="Success" clicked={enterCovertCommandsSequencerHandler}>Covert Commands</Button>
      </div>
    )
  }

  if (tentacle_daily_pleasure) {
    tentacles_opening_form = <DailyPleasure />
  }

  if (tentacle_daily_spank) {
    tentacles_opening_form = <DailySpank />
  }

  if (tentacle_daily_electric_spank) {
    tentacles_opening_form = <DailyElectricSpank />
  }

  if (tentacle_covert_fullday) {
    tentacles_opening_form = <CovertFullday />
  }

  if (tentacle_covert_commands) {
    tentacles_opening_form = <CovertCommands />
  }

  return (
    <Aux>
      {tentacles_opening_form}
    </Aux>
  )

}

const mapStateToProps = state => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    profileOptions: state.options.profileOptions,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    TentacleReinforcement: (userId, token) => dispatch(TentacleReinforcement(userId, token)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Trigger_Introduction_Opening)