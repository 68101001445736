import React, { Component } from 'react';
import Aux from '../../../hoc/auxiliary/auxiliary';
import classes from './HypnosisShowMenu.module.scss'
// import classes2 from '../ExperienceMenu/ExperienceMenu.module.scss'
import HypnosisShow from '../../../assets/Images/Blank.png'
import ExploreFair from './HypnosisShowExplore'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'

class HypnosisStoryPlayBall extends Component {

    state = {
        playball: true,
        explore: false,
    }

    ExploreHandler = () => {
        this.setState({ playball: false, explore: true })
    }
    render() {
        let hit_miss = []

        for (let i = 0; i < 3; i++) {
            let ball_throwing = Math.floor(Math.random() * 2) + 1
            hit_miss.push(ball_throwing)
        }
        let playGameForm = null
        let playGameForm1 = null
        let playGameForm2 = null
        let playGameForm3 = null

        playGameForm =
            <p>
                You go to the stand and buy 3 tickets.
                You throw the first ball and it <strong>{this.props.hit_miss[0]}</strong>

            </p>
        if (this.props.hit_miss[0] === 'hits') {
            playGameForm1 = (
                <Aux>
                    <p>
                        It hits the bowling pins causing them to fall. <br></br>
                        The game operator congratulates you, showing you the prizes you get to choose from.<br></br>
                        A big teddy bear, a small trophy with the words "Winner" written on it & finally a fluffy ball you can squeeze<br></br>
                        You choose the...
                    </p>
                    <form className={classes.centered} onClick={this.ExploreHandler}>
                        <h3 className={classes.storyButtonSize}>Teddy Bear</h3>
                    </form>
                    <form className={classes.centered} onClick={this.ExploreHandler}>
                        <h3 className={classes.storyButtonSize}>Trophy</h3>
                    </form>
                    <form className={classes.centered} onClick={this.ExploreHandler}>
                        <h3 className={classes.storyButtonSize}>Fluffy Ball</h3>
                    </form>
                </Aux>
            )
        } else {
            playGameForm1 = (
                <p>You miss and try again. This time you throw the ball and it <strong>{this.props.hit_miss[1]}</strong></p>
            )
            if (this.props.hit_miss[1] === 'hits') {
                playGameForm2 = (
                    <Aux>
                        <p>
                            It hits the bowling pins causing them to fall. <br></br>
                            The game operator congratulates you, showing you the prizes you get to choose from.<br></br>
                            A big teddy bear, a small trophy with the words "Winner" written on it & finally a fluffy ball you can squeeze<br></br>
                            You choose the...
                        </p>
                        <form className={classes.centered} onClick={this.ExploreHandler}>
                            <h3 className={classes.storyButtonSize}>Teddy Bear</h3>
                        </form>
                        <form className={classes.centered} onClick={this.ExploreHandler}>
                            <h3 className={classes.storyButtonSize}>Trophy</h3>
                        </form>
                        <form className={classes.centered} onClick={this.ExploreHandler}>
                            <h3 className={classes.storyButtonSize}>Fluffy Ball</h3>
                        </form>
                    </Aux>
                )
            } else {
                playGameForm2 = (
                    <p>You miss and try again. This time you throw the ball and it <strong>{this.props.hit_miss[2]}</strong></p>
                )
                if (this.props.hit_miss[2] === 'hits') {
                    playGameForm3 = (
                        <Aux>
                            <p>
                                It hits the bowling pins causing them to fall. <br></br>
                                The game operator congratulates you, showing you the prizes you get to choose from.<br></br>
                                A big teddy bear, a small trophy with the words "Winner" written on it & finally a fluffy ball you can squeeze<br></br>
                                You choose the...
                            </p>
                            <form className={classes.centered} onClick={this.ExploreHandler}>
                                <h3 className={classes.storyButtonSize}>Teddy Bear</h3>
                            </form>
                            <form className={classes.centered} onClick={this.ExploreHandler}>
                                <h3 className={classes.storyButtonSize}>Trophy</h3>
                            </form>
                            <form className={classes.centered} onClick={this.ExploreHandler}>
                                <h3 className={classes.storyButtonSize}>Fluffy Ball</h3>
                            </form>
                        </Aux>
                    )
                } else {
                    playGameForm3 = (
                        <Aux>
                            <p>You miss the third & final throw however the game's operator gives you a consolation prize. A keychain with a small giraffe hanging from it</p>
                            <form className={classes.centered} onClick={this.ExploreHandler}>
                                <h3 className={classes.storyButtonSize}>Explore More</h3>
                            </form>
                        </Aux>
                    )
                }
            }
        }





        let HypnosisShowForm = null
        if (this.state.playball) {
            HypnosisShowForm = (
                <html style={{ backgroundImage: `url(${HypnosisShow})` }}>
                    <div className={classes.storyFrom}>
                        {playGameForm}
                        {playGameForm1}
                        {playGameForm2}
                        {playGameForm3}
                    </div>
                </html>
            )
        }

        if (this.state.explore) {
            HypnosisShowForm = (
                <ExploreFair />
            )
        }

        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {HypnosisShowForm}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >




        )
    }
}


export default HypnosisStoryPlayBall