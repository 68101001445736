import React from 'react';
import TypingEffect from 'react-typing-effect';
import Delay from 'react-delay'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import classes from '../../HostsRunComponent.module.css'

import Sound from 'react-sound'
import Bell from '../../../../../assets/audio/bell.mp3'

const bellInduction = (props) => {
    // let praiseChoice = 0
    // praiseChoice = Math.floor(Math.random() * props.praises.length);
    // let praise = props.praises[praiseChoice]
    let bellCount = Math.floor(Math.random() * 15) +4
    let bellCounter = []
    let bellWait = 0
    
    for (let key=0; key < bellCount; key++){
        bellWait= bellCounter.length*5000
        let bellDiv = <Delay wait={bellWait}><Sound url={Bell} playStatus={Sound.status.PLAYING} /></Delay>
        bellCounter.push({
            id: key,
            config: bellDiv})
    }
    // eslint-disable-next-line
    const bellMessage = "That sound...\n\
                         That wonderful hypnotic sound\n\
                         Everytime you hear it\n\
                         Everytime you hear this bell\n\
                         It just makes you feel fuzzy\n\
                         Makes you feel floaty\n\
                         Harder & harder to think\n\
                         The more you hear it\n\
                         The more you sink\n\
                         The more you slip\n\
                         The more you just drop deep\n\
                         Deeper and deeper down\n\
                         In a moment We will ring the bell " + // eslint-disable-next-line
                         bellCount + " times\n\
                         With each bell you fall down\n\
                         Deep down \n\
                         Fall deep under Our control\n\
                         Deep under Our power\n\
                         Where you will learn to love\n\
                         Where you will soon learn\n\
                         This is where you belong " + // eslint-disable-next-line
                         (props.naming) + "\n\
                         You want to be a " + (props.title) + ", isn't that right?"
    let speed = parseInt(props.speed) +5
    let  duration = 170 + (bellMessage.length * speed)
    let bellDuration = duration + bellCounter.length*5000
    let bellInductionParagraph = (
            <Aux>
                <h3 className= {classes.titles}>BELL INDUCTION</h3>
                <Sound url={Bell} playStatus={Sound.status.PLAYING} />
                <Delay wait={5000}><TypingEffect typingDelay={100} text={bellMessage} speed={props.speed} eraseDelay={1000000} /></Delay>
                <Delay wait={duration}>{bellCounter.map(bell => (
                    <Delay key={bell.id} wait = {bell.config.props.wait}>
                    <Sound
                    key = {bell.id}
                    url = {bell.config.props.children.props.url}
                    playStatus = {bell.config.props.children.props.playStatus}
                    />
                    </Delay>
                    ))}</Delay>
                <Delay wait={bellDuration}><h4 className={classes.blinking} onClick={props.bellInduction}>I fell for the Bell</h4></Delay>
            </Aux>
        )
    





    return bellInductionParagraph
}


export default bellInduction