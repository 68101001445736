import React from 'react'
import { AnimateOnChange} from 'react-animation'


function TextAddition (props) {
    
    let centerText = window.innerHeight/2-48
    let texting = {
      // boxSizing: "border-size",
      margin: 'auto',
      display: 'flex',
      flexFlow: 'column',
      verticalAlign: 'center',
      fontWeight: 'bold',
      paddingTop: centerText+'px',
      color: props.text_color
  
    }
  
      let words= props.conditioning_text.split(',')
      if(props.arousal_check){
        words.push(['Aroused'])
        words.push(['Turned On'])
        words.push(['Mindlessly Watching'])
        words.push(['Obediently Following'])
        words.push(['Giving in'])
        words.push(['Pleasure'])
        words.push(["The Hypno Virus's Pleasure"])
        words.push(['Horny'])
        words.push(['Edging'])
        words.push(['No Thoughts'])
        words.push(['No Will'])
        words.push(['What We say you Obey'])
        words.push(['Touch Yourself'])
        words.push(['Condition Yourself'])
        words.push(['We Turn You On'])
      }
  
      if(props.submissive_check){
        words.push(['Mindless'])
        words.push(['Obedient'])
        words.push(['Blank'])
        words.push(['We Control You'])
        words.push(['We Command You'])
        words.push(['What We Say... You Obey'])
        words.push(["Serve"])
        words.push(['Submit'])
        words.push(['Obey'])
        words.push(['No Thoughts'])
        words.push(['No Will'])
        words.push(['What We say you Obey'])
        words.push(['Under Our Control'])
        words.push(['Conditioned to Us'])
        words.push(['Where You Belong'])
      }
     
    
        const [current, setCurrent] = React.useState(0)
    
        React.useEffect(() => {
          const interval = setInterval(() => {
            if (current === words.length - 1) {
              setCurrent(0)
            } else {
            //   setCurrent(current + 1)
            setCurrent(Math.floor(Math.random()*words.length))
            }
          }, props.text_speed);
          return (() => {
            clearInterval(interval)
          })
        })
    let wordsForm = (
        <AnimateOnChange style={texting}>{words[current]}</AnimateOnChange>
    )
        


    return wordsForm
}

export default TextAddition


