import * as audioRunTypes from '../../../actions/AudioRuns/audioRunTypes';
import { updateObject } from '../../../../shared/utility';

const initialState = {
    error: null,
    Chloroform_reinforcementComplete: false,
    fast_chloroform : false,
    normal_chloroform : false,
    Chloroform_ReinforcementCommands: [],
    loading: false,
}

const chloroformReinforcementStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true
    })
}

const chlroformReinforcementSuccess = (state, action) => {
        return updateObject(state, {
            Chloroform_reinforcementComplete: false,
            Chloroform_ReinforcementCommands: action.Chloroform_Reinforcement,
            loading: false,
        })
}

const chloroformReinforcementFailed = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    })
}

const fastChloroformSuccess = (state, action) => {
    return updateObject(state, {
        fast_chloroform: true,
        loading: false
    })
}

const normalChloroformSuccess = (state, action) => {
    return updateObject(state, {
        normal_chloroform: true,
        loading: false
    })
}

const chlroformReinforcementComplete = (state, action) => {
    return updateObject(state, {
        error: null,
        Chloroform_reinforcementComplete: true,
        fast_chloroform: false,
        normal_chloroform: false,
        Chloroform_ReinforcementCommands: [],
        loading: false,
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case audioRunTypes.AUDIO_RUN_START: return chloroformReinforcementStart(state, action)
        case audioRunTypes.AUDIO_RUN_FAILED: return chloroformReinforcementFailed(state, action)
        case audioRunTypes.CHLOROFORM_REINFORCEMENT_SUCCESS: return chlroformReinforcementSuccess(state,action)
        case audioRunTypes.FAST_EFFECT_CHLOROFORM: return fastChloroformSuccess(state,action)
        case audioRunTypes.NORMAL_EFFECT_CHLOROFORM: return normalChloroformSuccess(state,action)
        case audioRunTypes.CHLOROFORM_REINFORCEMENT_COMPLETED: return chlroformReinforcementComplete(state, action)
        default:
            return state

    }
}


export default reducer