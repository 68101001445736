import React from 'react';
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'
import Sound from 'react-sound'
import classes from '../Visual.module.css'
import Bell from '../../../assets/audio/bell.mp3'
import Mantra from '../../../assets/audio/mantralong.mp3'
import Metronome from '../../../assets/audio/Metronome2.mp3'
import Ticking from '../../../assets/audio/Ticking3.mp3'
import MantraVoice from '../../../assets/audio/MantraVoice.mp3'
import WordText from '../TextAddition'

function StarVisual(props) {

  let innerWidth = window.innerWidth
  let innerHeight = window.innerHeight
  const canvasRef = React.useRef(null)

  React.useEffect(() => {
    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')

    var mouse = {
      x: undefined,
      y: undefined
    }

    // let minRadius = 
    let maxRadius = 50

    let colorArray = []
    if(props.color_number === '1'){
      colorArray = [props.color1]
    }
    if(props.color_number === '2'){
      colorArray = [props.color1
      ,props.color2]
    }
    if(props.color_number === '3'){
      colorArray = [props.color1
      ,props.color2
      ,props.color3]
    }
    if(props.color_number === '4'){
      colorArray = [props.color1
        ,props.color2
        ,props.color3
        ,props.color4]
    }

    window.addEventListener('resize', function()
    {
      canvas.width = innerWidth
      canvas.height = innerHeight

       init()
    }
    )

    window.addEventListener('mousemove', 
        function(event){
          mouse.x = event.x
          mouse.y = event.y
        })

    function Circle(x, y, dx, dy, radius) {
      this.x = x 
      this.y = y
      this.dx = dx
      this.dy = dy
      this.radius = radius
      this.minRadius = radius
      this.color = colorArray[Math.floor(Math.random()*colorArray.length)]

      this.draw = function() {
        ctx.beginPath()
        ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2 , false)
        ctx.fillStyle = this.color
        ctx.fill()
        ctx.stroke()
      }

      this.update = function() {
        if (this.x + radius> innerWidth || this.x - radius < 0)
          this.dx = -this.dx
        if (this.y + radius> innerHeight || this.y - radius < 0)
          this.dy = -this.dy

        this.x += this.dx
        this.y += this.dy

        // interactivity

        if(mouse.x - this.x < 50 
            && mouse.x - this.x > -50
              && mouse.y - this.y < 50 
                && mouse.y - this.y > -50) {
                  if(this.radius < maxRadius){
                    this.radius += 1
                  }
        } else if(this.radius > this.minRadius){
          this.radius -= 1
        }

        this.draw()
      }
    }

    

    let circleArray = []
    // console.log(props.Bubbles_number)
    for( let i = 0; i < props.Bubbles_number; i++){
      let radius = (Math.random()*4)+1 
      let x = Math.random() * (innerWidth - radius * 2) + radius
      let y = Math.random() * (innerHeight - radius * 2) + radius
      let dx = (Math.random() - 0.5)*2
      let dy = (Math.random() - 0.5)*2    
      
      circleArray.push(new Circle(x, y, dx, dy, radius))
    }
    
    
    function init(){
      circleArray = []
      for( let i = 0; i < 1400; i++){
        let radius = (Math.random()*4)+1 
        let x = Math.random() * (innerWidth - radius * 2) + radius
        let y = Math.random() * (innerHeight - radius * 2) + radius
        let dx = (Math.random() - 0.5)*2
        let dy = (Math.random() - 0.5)*2    
        
        circleArray.push(new Circle(x, y, dx, dy, radius))
    }
    }
    
    function animate(){
      ctx.fillStyle = props.background_color
      ctx.fillRect(0, 0, innerWidth, innerHeight)
      requestAnimationFrame(animate)

      for(let i = 0; i < circleArray.length; i++){
        circleArray[i].update()
      }

    }
    init()
    animate()



  })

  
  let soundComponent = null
  switch (props.sound) {
    case '1': soundComponent = <Sound url={Bell} playStatus={Sound.status.PLAYING} loop={true} />
      break;
    case '2': soundComponent = <Sound url={Metronome} playStatus={Sound.status.PLAYING} loop={true} />
      break;
    case '3': soundComponent = <Sound url={Ticking} playStatus={Sound.status.PLAYING} loop={true} />
      break;
    case '4': soundComponent = <Sound url={Mantra} playStatus={Sound.status.PLAYING} loop={true} />
      break;
    case '5': soundComponent = <Sound url={MantraVoice} playStatus={Sound.status.PLAYING} loop={true} />
      break;
    default: soundComponent = null
  }

  let divStyle
  divStyle = {
    fontSize: "48px",
    fontFamily: "Arial",
    background: 'transpartent',
    position: 'fixed',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    zIndex: '997',
    textAlign: 'center',
    // display: 'visible'


  }

  return (
    <Fullpage >
      <FullPageSections>
        < FullpageSection>
          <canvas
            ref={canvasRef}
            width={innerWidth}
            height={innerHeight}
          >
            {soundComponent}
          </canvas>
          <div style={divStyle}>
          <p className ={classes.returnText} onClick={props.returnHandler}>Return to Customization</p>
          <WordText text_color = {props.text_color}
                    text_speed ={props.text_speed} 
                    conditioning_text={props.conditioning_text}
                    arousal_check={props.arousal_check}
                    submissive_check = {props.submissive_check}/>
          </div>
        </FullpageSection>
      </FullPageSections>
    </Fullpage>
  );
}

export default StarVisual;
