import React, { useState } from "react";
import TextTransition from "react-text-transition";
import TypingEffect from 'react-typing-effect'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import Button from '../../../../UI/Button/Button'
import classes from '../../../../../containers/HostsChamber/AudioRuns/AudioRuns.module.css'
import * as scripts from '../../../../../shared/AudioRuns/TriggerIntroduction/TentaclesIntroduction/TentaclesIntroductionScripts'
import * as Delays from '../../../../../shared/AudioRuns/TriggerIntroduction/TentaclesIntroduction/TentaclesIntroductionDelay'
import Activate from '../../../../../assets/audio_runs/Trigger_Introduction/Tentacle_Introduction/Tentacle_Obey_Activate.mp3'
import Sound from 'react-sound'
import Delay from "react-delay";
import useInterval from 'react-useinterval';
import TentacleObeyEnding from './Tentacle_Obey_Ending'

const DELAY_TEXT = [4000]


function Trigger_Obey_Activate() {
  let [count, setCount] = useState(0);
  let [tentacle_activate, setProtocol] = useState(true)
  let [tentacle_ending, setActivateSequence] = useState(false)


  const increaseCount = () => {
    if (tentacle_activate && count < scripts.TENTALCE_OBEY_ACTIVATE_SCRIPT.length) {
      setCount(count + 1);
    }
  };

  useInterval(increaseCount, Delays.TENTACLES_OBEY_ACTIVATE_DELAY_TIME[count]);

  const enterActivateSequenceHandler = () => {
    setProtocol(tentacle_activate = false)
    setActivateSequence(tentacle_ending = true)

  }

  

  let tentacles_obey_activate_form = null

  if (tentacle_activate) {
    tentacles_obey_activate_form = (
      <Aux>
        {count < scripts.TENTALCE_OBEY_ACTIVATE_SCRIPT.length ?
          <Aux >
            <Sound url={Activate} playStatus={Sound.status.PLAYING} />
            <Delay wait={DELAY_TEXT[count]}>
              <TextTransition
                text={<TypingEffect typingDelay={100} text={scripts.TENTALCE_OBEY_ACTIVATE_SCRIPT[count]} speed={90} eraseDelay={1000000} />}
                Overflow
              />
            </Delay>
          </Aux>
          :
    <div className={classes.ButtonPadding}>
      <Button btnType="Success" clicked={enterActivateSequenceHandler}>This Host is ready to complete<br></br> its programming session</Button>
    </div>
  }
      </Aux >
    )
}

if(tentacle_ending){
  tentacles_obey_activate_form = <TentacleObeyEnding />
}

return (
  <Aux>
      {tentacles_obey_activate_form}
  </Aux>
)

}

export default Trigger_Obey_Activate