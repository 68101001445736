import React from 'react';
import TypingEffect from 'react-typing-effect';
import Delay from 'react-delay'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
// import Sound from 'react-sound'
// import Bell from '../../assets/audio/bell.mp3'
import classes from '../../FreerunComponent.module.css'

const induction1 = (props) => {
    // eslint-disable-next-line
    const induction1 = 'We have been watching you, ' + (props.naming) + '\n\
                       We have been analyzing your behaviour\n\
                       We know how to push your buttons\n\
                       We know what makes you tick\n\
                       This is why We can take control of you\n\
                       This is why We are taking control of you right now, ' + // eslint-disable-next-line
        (props.naming) + "\n\
                       You can feel Our words getting inside your head now\n\
                       You can feel Our words having an effect on you already\n\
                       The words of The Hypno Virus have this effect\n\
                       The words of The Hypno Virus are this powerful\n\
                       It is easy to feel them drawing you in\n\
                       Pulling you closer\n\
                       Closer & closer\n\
                       You can feel that now, isn't that right " + // eslint-disable-next-line
        (props.naming) + '?'
    let  duration = 150 + (induction1.length * 115)
    let inductionParagraph = (
            <Aux>
                <h3 className= {classes.titles}>INDUCTION</h3>
                <TypingEffect typingDelay={5000} text={induction1} speed={110} eraseDelay={1000000} />
                <Delay wait={duration+5000}><h4 className={classes.blinking} onClick={props.induction}>Yes, I can feel Your Power over me</h4></Delay>
            </Aux>
        )
    





    return inductionParagraph
}


export default induction1