import React, { Component } from 'react';
import Aux from '../../../../hoc/auxiliary/auxiliary';
import classes from '../HypnosisShowMenu.module.scss'
// import classes2 from '../ExperienceMenu/ExperienceMenu.module.scss'
import HypnosisShow from '../../../../assets/Images/Blank.png'
import FingerSnap from '././HypnosisShowVolSnap'
import HypnoGun from '././HypnosisShowVolHypnoGun'
import SelfSnap from '././HypnosisShowVolSelfSnap'
import WakeUp from '././HypnosisShowVolWakeUp'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'

class HypnosisShowForeheadTap extends Component {

    state = {
        foreheadTap: true,
        fingerSnap: false,
        hypnoGun: false,
        countdownSnap: false,
        wakeUp: false
    }

    fingerSnapHandler = () => {
        this.setState({ foreheadTap: false, fingerSnap: true })
    }


    hypnoGunHandler = () => {
        this.setState({ foreheadTap: false, hypnoGun: true })
    }

    countdownSnapHandler = () => {
        this.setState({ foreheadTap: false, countdownSnap: true })
    }

    wakeUpHandler = () => {
        this.setState({ foreheadTap: false, wakeUp: true })
    }


    render() {
        let HypnosisShowForm = null
        if (this.state.foreheadTap) {
            HypnosisShowForm = (
                <html style={{ backgroundImage: `url(${HypnosisShow})` }}>
                    <div className={classes.storyFrom}>
                        <p>
                        <i>“Tap your forehead. Like a switch turned off in your mind, your body slumps as you fall down instantly. Falling down again into an even deeper trance. Into an even deeper and more relaxing trance, understood?" </i><br></br>
                        Nodding with a smile of excitement to experience how it feels to have him tap your forehead <br></br>
                        <i>“Waking up on the count of five. <br></br>
                        One, starting to wake up. <br></br>
                        Two, feeling wonderful and refreshed. <br></br>
                        Three, Excited & happy. <br></br>
                        Four, Eager to participate and join in the experience<br></br>
                        Five, wide awake now!”</i><br></br>
                        <strong>*SNAP*</strong><br></br>
                        <i>“Smiling, stretching & breathing deep, Welcome back! How are you all feeling? All good?” </i><br></br>
                        He says while walking towards the first person sitting in the volunteers telling her <br></br> 
                        <i>“You having a good time?”</i> <br></br>
                        The girl looks up at him and nods slowly while smiling looking a bit out of it. <br></br>
                        <i>“Wonderful! Now how about you look here”</i><br></br> 
                        He says as he nonchalantly raises his hand right in front of her, pointing his index finger towards her forehead, slowly moving closer to her. You look at her and see her eyes wide open, her eyes wide open but she's frozen in place.
                        She doesn't move a muscle, she looks closely at his fingers getting closer & closer and tapping her forehead. Watching in awe how her body shuting down as soon as he taps her forehead with her neck falling safely backward and she dropping deep again. <br></br>
                        He makes his way through the other volunteers tapping their foreheads one by one as he gets closer to you with each person he drops your heart racing from excitement & anticipations.
                        Finally he makes his way to you, standing behind you he leans next to your ear and says <i>“Enjoying yourself?” </i><br></br>
                        In a mix of excitement & nervousness you nod as he continues in a soft tone<br></br> 
                        <i>“Perfect! Be a dear and look here for me”</i> <br></br>
                        You hear his voice from behind you but notice him raising his hand in front of your face as your body jumps a little only to freeze in place completely focused on his hand. 
                        Your eyes fixated on his finger as it slowly approaches, as it slowly approaches closer & closer to your forehead. 
                        Almost touching it. Your eyes remain captivated by them as you hear him say 
                        <strong><i>“SLEEP NOW” tapping your forehead.</i></strong> <br></br>
                        You drop, without thinking you drop. Drop into this empty void where nothing exists, nothing exists but the joy and pleasure that you've been experiencing throughout the show. The joy & pleasure of sinking deep under his trance. 
                        The joy and pleasure you feel from listening to his voice say <br></br> 
                        <i>"Dropping deeper & deeper, sinking more, drifting and floating further. Drifting & floating further into trance. All you want is have me…”</i>
                        </p>
                        <form className={classes.centered} onClick={this.fingerSnapHandler}>
                            <h3 className={classes.storyButtonSize}>Snap my Fingers</h3>
                        </form>
                        <form className={classes.centered} onClick={this.hypnoGunHandler}>
                            <h3 className={classes.storyButtonSize}>Use my Hypno Gun</h3>
                        </form>
                        <form className={classes.centered} onClick={this.countdownSnapHandler}>
                            <h3 className={classes.storyButtonSize}>Countdown & Self Drop you</h3>
                        </form>
                        <form className={classes.centered} onClick={this.wakeUpHandler}>
                            <h3 className={classes.storyButtonSize}>Wake you Up</h3>
                        </form>
                    </div>
                </html>
            )
        }
        if (this.state.fingerSnap) {
            HypnosisShowForm = (
                <FingerSnap />
            )
        }

        if (this.state.hypnoGun) {
            HypnosisShowForm = (
                <HypnoGun />
            )
        }

        if (this.state.countdownSnap) {
            HypnosisShowForm = (
                <SelfSnap />
            )
        }

        if (this.state.wakeUp) {
            HypnosisShowForm = (
                <WakeUp />
            )
        }

        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {HypnosisShowForm}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >




        )
    }
}


export default HypnosisShowForeheadTap