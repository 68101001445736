import React from 'react'
import classes from '../../../../containers/Account/Account.module.css'
// import Input from '../../UI/Input/Input'
import Button from '../../../UI/Button/Button'
import Aux from '../../../../hoc/auxiliary/auxiliary'

const motorfunctionControlReinforcement = (props) => {
    let motorfunctionControlReinforcementForm = (
        <Aux>
            <div className={classes.AccountContainer}>
                    <form className={classes.FormStyle}>
                        <h2>Motor Function Control  Reinforcement Run Details</h2>
                        <p className={classes.smallText}>{props.title} {props.name}, your mind & body belongs to The Hypno Virus</p>
                        <div>
                            <p><strong>Motor Function Control Reinforcement Run: {props.MotorFunctionControlReinforcement.Motor_Function_Control_Reinforcement}</strong></p>
                        </div>
                        <div>
                            <p><strong>Instant Control Reinforcement: {props.MotorFunctionControlReinforcement.instant_control_reinforcement} </strong></p>
                        </div>
                        <div>
                            <p><strong>Slow Control Reinforcement: {props.MotorFunctionControlReinforcement.slow_control_reinforcement}</strong></p>
                        </div>
                        <div>
                            <p><strong>Mental Control Reinforcement: {props.MotorFunctionControlReinforcement.mental_control_reinforcement}</strong></p>
                        </div>
                        <div>
                            <p><strong>Wired Reconfiguration: {props.MotorFunctionControlReinforcement.wired_reconfiguration}</strong></p>
                        </div>
                    </form>
                    <div className={classes.AuthButtons}>
                        <Button btnType="Danger" clicked={props.ReturnBackHandler}>Back</Button>
                    </div>

                </div>
        </Aux>)


    return motorfunctionControlReinforcementForm
}

export default motorfunctionControlReinforcement