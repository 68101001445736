import React from 'react';
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'
import classes from '../../../../../containers/HostsChamber/AudioRuns/AudioRuns.module.css'
import ForcedChloroform from './Forced_Chloroform_Explanation'
import ChloroformRoom from './Chloroform_Room_Setup'

function ChloroformVisual(props) {

    
    let chloroform_form = null
    
    if (props.Forced_Chloroform){
        chloroform_form = <ForcedChloroform />
    } 

    if (props.chloroformRoom){
        chloroform_form = <ChloroformRoom/>
    }

    let centerText = window.innerHeight / 2 - 55
    let texting = {

        // display: 'flex',
        // flexFlow: 'column',
        whiteSpace: 'pre-line',
        alignItems: 'center',
        fontWeight: 'bold',
        boxSizing: "border-box",
        margin: 'auto',
        marginBottom: '10px',
        padding: '30px 10px',
        width: '50%',
        paddingTop: centerText + 'px',
        color: 'white'

    }


    return (
        <Fullpage >
            <FullPageSections>
                < FullpageSection>
                    <div className={classes.Grouping}>
                        <p style={texting}> {chloroform_form}</p>
                    </div>
                </FullpageSection>
            </FullPageSections>
        </Fullpage>
    );
}

export default ChloroformVisual;
