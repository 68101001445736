import * as conditioningStagesTypes from '../conditioningStagesTypes'
import axios from '../../../../axios'

export const ObedienceStageStart = () => {
    return {
        type: conditioningStagesTypes.OBEDIENCE_STAGE_START
    }
}

export const ObedienceStage2Success = (obedience_stage2) => {

    if(obedience_stage2.obedience_stage2 > 4){
        let obedienceStage2CommandsNum =Math.floor(Math.random() * 3) + 1
        let obedience2counter = 0
        let obedienceCommandsArray = []
        while (obedience2counter < obedienceStage2CommandsNum) {
            let commandNumber = Math.floor(Math.random() * 5)
            if (!obedienceCommandsArray.includes(commandNumber)) {
                obedienceCommandsArray.push(commandNumber)
                obedience2counter++
            }
        }
        return {
            type: conditioningStagesTypes.OBEDIENCE_STAGE_2_SUCCESS,
            obedience_stage2: obedience_stage2,
            obedienceStage2Commands: obedienceCommandsArray
        }
    }
    else if (obedience_stage2.obedience_stage2 <= 4){
        return {
            type: conditioningStagesTypes.OBEDIENCE_STAGE_2_SUCCESS,
            obedience_stage2: obedience_stage2,
            obedienceStage2Commands: [5]
        }
    }
}

export const ObedienceStageFailed = (error) => {
    return{
        type: conditioningStagesTypes.OBEDIENCE_STAGE_FAILED,
        error: error
    }
}

export const ObedienceStage2 = (userId, token) => {
    return dispatch => {
        // dispatch(ObedienceStageStart());
       
        axios.get('/Runs/'+userId+'/Conditioning_Stages/Obedience_Stage_2/.json?auth=' + token)
        
            .then( response => {
                // dispatch(ObedienceStageStart());
                if(response.data === null){
                    let obedience_stage2 = {
                        obedience_stage2: 0,
                        host_reinforcement_command: 0,
                        host_speech_command: 0,
                        host_suit_command: 0,
                        host_pleasure_command: 0,
                        host_infection_command:0
                    }
                    axios.put('/Runs/'+userId+'/Conditioning_Stages/Obedience_Stage_2/.json?auth=' + token, obedience_stage2)
                    .then(response => {
                        dispatch(ObedienceStage2Success(response.data))
                    })
                    .catch(error => {
                        dispatch(ObedienceStageFailed(error.message.data))
                    })
                    
                } else{
                    dispatch(ObedienceStage2Success(response.data))
                }
                       
            })
            .catch(error => {
                dispatch(ObedienceStageFailed(error.message))
            })
    }
}

export const ObedienceStage2Counter = (stage2Counter, commandNumber) => {
    return{
        type: conditioningStagesTypes.OBEDIENCE_STAGE_2_COUNTER,
        obedienceStage2Counter: stage2Counter + 1,
        commandNumber: commandNumber
    }
}

export const ObedienceStage2End = (userId, token, host_reinforcement_command, host_speech_command, host_suit_command, host_pleasure_command, host_infection_command) => {
    return dispatch => {
        dispatch(ObedienceStageStart());
       
        axios.get('/Runs/'+userId+'/Conditioning_Stages/Obedience_Stage_2/.json?auth=' + token)
        
            .then( response => {
                dispatch(ObedienceStageStart());
                    let updatedobedience_stage2 = {
                        obedience_stage2: response.data.obedience_stage2 + 1,
                        host_reinforcement_command: response.data.host_reinforcement_command + host_reinforcement_command,
                        host_speech_command: response.data.host_speech_command + host_speech_command,
                        host_suit_command: response.data.host_suit_command + host_suit_command,
                        host_pleasure_command: response.data.host_pleasure_command + host_pleasure_command,
                        host_infection_command: response.data.host_infection_command + host_infection_command
                    }
                    axios.put('/Runs/'+userId+'/Conditioning_Stages/Obedience_Stage_2/.json?auth=' + token, updatedobedience_stage2)
                    .then(response => {
                        dispatch(ObedienceStage2Completed())
                    })
                    .catch(error => {
                        dispatch(ObedienceStageFailed(error.message.data))
                    })    
                       
            })
            .catch(error => {
                dispatch(ObedienceStageFailed(error.message))
            })
    }
}

export const ObedienceStage2Completed = () => {
    return {
        type: conditioningStagesTypes.OBEDIENCE_STAGE_2_COMPLETED,
    }
}

export const ObedienceStage2Start = () => {
    return {
        type: conditioningStagesTypes.OBEDIENCE_STAGE_2_START,
    }
}