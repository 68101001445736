import React, { useState } from "react";
import TextTransition from "react-text-transition";
import TypingEffect from 'react-typing-effect'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import Button from '../../../../UI/Button/Button'
import classes from '../../../../../containers/HostsChamber/AudioRuns/AudioRuns.module.css'
import * as scripts from '../../../../../shared/AudioRuns/ConditioningProtocols/ConditioningProtocol#1/ConditioningProtocol1Scripts'
import * as Delays from '../../../../../shared/AudioRuns/ConditioningProtocols/ConditioningProtocol#1/ConditioningProtocol#1Delay'
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import {ConditioningProtocol1End} from '../../../../../store/actions/AudioRuns/audioRunsIndex'
import Sound from 'react-sound'
import Comply1 from '../../../../../assets/audio_runs/conditioning_protocols/conditioning_protocol_1/conditioning_protocol_comply1.mp3'
import Delay from "react-delay";
import useInterval from 'react-useinterval';

// const DELAY_COMPLY = [4100]
const DELAY_COMPLY1 = [4800]
        

function Conditioning_Protocol_Comply ({userId, token,conditioningProtocol1Complete, ConditioningProtocol1End}) {
    let [comply1count, setCount1] = useState(0);
    let [conditioning_comply1, setComply1] = useState(true)

   

    const increaseComply1Count = () => {
        if(conditioning_comply1 && comply1count < scripts.CONDITIONING_PROTOCOL_COMPLY1.length){
            setCount1(comply1count + 1); 
          }
      };
    
    
    useInterval(increaseComply1Count, Delays.DELAY_TIME_COMPLY1[comply1count]);
   
    

    

    const completeComplyHandler = () => {
        setComply1( conditioning_comply1 = false )
        ConditioningProtocol1End(userId, token, 1, 0)
    }


        let conditioning_protocol_comply_form = null

        if (conditioning_comply1) {
            conditioning_protocol_comply_form = (
                <Aux>
                    {comply1count< scripts.CONDITIONING_PROTOCOL_COMPLY1.length ?
                        <Aux >
                            {/* <div className={classes.texting}> */}
                                <Sound url={Comply1} playStatus={Sound.status.PLAYING} />
                                <Delay wait={DELAY_COMPLY1[comply1count]}>
                                <TextTransition
                                    text={<TypingEffect typingDelay={100} text={scripts.CONDITIONING_PROTOCOL_COMPLY1[comply1count]} speed={90} eraseDelay={1000000} />}
                                    Overflow
                                />
                                </Delay>
                            {/* </div> */}
                        </Aux>
                        :
                        <div className={classes.ButtonPadding}>
                            <Button btnType="Success" clicked={completeComplyHandler}>I am a Host for The Hypno Virus</Button>
                        </div>
                    }
                </Aux>
            )
        }

        if(conditioningProtocol1Complete){
            conditioning_protocol_comply_form = <Redirect to = "/"/>
        }




        return (
            conditioning_protocol_comply_form
        );
    }


const mapStateToProps = state => {
    return {
        hostName: state.auth.accountDetails.name,
        runSpeed: state.auth.accountDetails.speed,
        userId: state.auth.userId,
        token: state.auth.token,
        conditioningProtocol1Complete:state.conditioningProtocol1.conditioningProtocol1Complete
    }
}

const mapDispatchToProps = dispatch => {
    return {
        ConditioningProtocol1End: (userId, token, comply_sequence, resistance_sequence) => dispatch(ConditioningProtocol1End(userId, token, comply_sequence, resistance_sequence)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Conditioning_Protocol_Comply)