import React from '../../../../../node_modules/react';
import TypingEffect from '../../../../../node_modules/react-typing-effect'
import Delay from '../../../../../node_modules/react-delay'
import Aux from '../../../../hoc/auxiliary/auxiliary'
import classes from '../HostsRunComponent.module.css'

const induction = (props) => {
    
    // eslint-disable-next-line
    const inductionMessage = "We will guide you, " + (props.naming) + "\n\
                         We will guide you to see things Our way\n\
                         We will help you understand\n\
                         We will help you understand why you must obey\n\
                         Why you must obey Us\n\
                         We know what's best\n\
                         We always know what's best for you\n\
                         This is why We are here to choose for you\n\
                         We are here to choose for you how We will take over your mind\n\
                         We will choose how you experience Our control right now\n\
                         Starting with " + (props.inductionMethodName) 
    let speed = parseInt(props.speed) +5
    let duration = 170 + (inductionMessage.length * speed)+5000
    let inductionParagraph = (
            <Aux>
                <h3 className= {classes.titles}>INDUCTION</h3>
                <TypingEffect typingDelay={5000} text={inductionMessage} speed={props.speed} eraseDelay={1000000} />
                <Delay wait={duration}><h4 className={classes.blinking} onClick={props.induction}>Yes, I want to feel Your Power over me</h4></Delay>
            </Aux>
        )
    





    return inductionParagraph
}


export default induction