import React, { useState } from "react";
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import Button from '../../../../UI/Button/Button'
import classes from '../../../../../containers/HostsChamber/HostsChamber.module.css'
import { MotorFunctionControl, InstantMotorFunctionControl, SlowMotorFunctionControl } from '../../../../../store/actions/AudioRuns/audioRunsIndex'
import { connect } from 'react-redux'
import WebsiteAudio from '../../../../Background/WebsiteAudio';
import Background from '../../../../Background/Background'
import FlashyVisual from './FlashyVisual'
import ColorVisual from './ColorsVisual'
import NoBackground from './NoBackground'
import { Redirect } from "react-router-dom";


function Motor_Function_Control_Introduction({ userId, token, instant_control, slow_control, Motor_Function_Introduction_Complete, MotorFunctionControl, onInstantMotorFunctionControl, onSlowMotorFunctionControl, profileOptions }) {

    let [motor_function_control_opening, setProtocol] = useState(true)


    MotorFunctionControl(userId, token)

    const enterInstantControlSequenceHandler = () => {
        setProtocol(motor_function_control_opening = false)
        onInstantMotorFunctionControl()
    }

    const enterSlowControlSequencerHandler = () => {
        setProtocol(motor_function_control_opening = false)
        onSlowMotorFunctionControl()
    }

    let motor_function_control_introduction_form = null

    if (motor_function_control_opening) {
        motor_function_control_introduction_form = (
            <Aux>
                <div className={classes.HostsChamber}>
                    <Background />
                    <WebsiteAudio />
                    <div className={classes.FormStyle}>
                        <div className={classes.HostButtons}>
                            <Button btnType="Success" clicked={enterInstantControlSequenceHandler}>Instant Motor Function Control</Button>
                            <Button btnType="Success" clicked={enterSlowControlSequencerHandler}>Slow Motor Function Control</Button>
                        </div>
                    </div>
                </div>
            </Aux >
        )
    }

    if (profileOptions.audio_run_background === '0') {
        if (instant_control) {
            motor_function_control_introduction_form = <NoBackground instant_control={instant_control} />
        }


        if (slow_control) {
            motor_function_control_introduction_form = <NoBackground slow_control={slow_control} />
        }

    } else

    if (profileOptions.audio_run_background === '1') {
        if (instant_control) {
            motor_function_control_introduction_form = <FlashyVisual instant_control={instant_control} />
        }


        if (slow_control) {
            motor_function_control_introduction_form = <FlashyVisual slow_control={slow_control} />
        }

    } else if (profileOptions.audio_run_background === '2') {
        if (instant_control) {
            motor_function_control_introduction_form = <ColorVisual instant_control={instant_control} />
        }


        if (slow_control) {
            motor_function_control_introduction_form = <ColorVisual slow_control={slow_control} />
        }



    }
    if (Motor_Function_Introduction_Complete) {
        motor_function_control_introduction_form = <Redirect to="/" />
    }

    return (
        <Aux>
            {motor_function_control_introduction_form}
        </Aux>
    )

}

const mapStateToProps = state => {
    return {
        userId: state.auth.userId,
        token: state.auth.token,
        instant_control: state.motorFunctionIntro.instant_control,
        slow_control: state.motorFunctionIntro.slow_control,
        Motor_Function_Introduction_Complete: state.motorFunctionIntro.Motor_Function_Introduction_Complete,
        profileOptions: state.options.profileOptions,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        MotorFunctionControl: (userId, token) => dispatch(MotorFunctionControl(userId, token)),
        onInstantMotorFunctionControl: () => dispatch(InstantMotorFunctionControl()),
        onSlowMotorFunctionControl: () => dispatch(SlowMotorFunctionControl()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Motor_Function_Control_Introduction)