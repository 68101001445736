import React, { useState } from 'react'
// import { Redirect } from 'react-router-dom'
import TypingEffect from 'react-typing-effect'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import classes from '../../TextToSpeech.module.css';
import { connect } from 'react-redux'
import Button from '../../../../UI/Button/Button'
import Delay from 'react-delay'
import { useSpeech, useRecognition } from 'react-web-voice';
import Control from '../Control/controlOpen'
import Purpose from '../Purpose/purposeOpen'

const Role = (props) => {
    const [messageToSpeak, setMessageToSpeak] = React.useState('');
    // const [startConv, setConvState] = useState(false)
    const [isListening, setIsListening] = useState(false);
    // const [endConv, setEndConv] = useState(false);
    const [roleStage, setRoleStage] = useState(0);
    // eslint-disable-next-line
    const { transcripts, listen } = useRecognition();
    // eslint-disable-next-line
    const roleYes = 'Your role is simple ' + (props.name) + "\n\
                    Your role is to obey\n\
                    Your role is to submit\n\
                    Your role is to accept Our conditioning\n\
                    Accept Our programming & give in to Us\n\
                    Your role is to enjoy what We do to you & return to Us for more\n\
                    Don't you want to return?"
    let duration = 170 + (roleYes.length * 70)
    // eslint-disable-next-line
    const roleYes2 = "Of course you do\n\
                      It feels wonderful to return\n\
                      To have Our words enter your mind\n\
                      Fill your mind with the bliss & joy of letting go\n\
                      To get lost in them\n\
                      Feel their pull and how they draw you in\n\
                      You seek that feeling\n\
                      You have been conditioned to love that feeling\n\
                      The feeling you get when you listen to Us and obey\n\
                      You want Us to control you\n\
                      Isn't that right " + (props.name) + "?"
    let duration2 = 170 + (roleYes2.length * 70)
    // eslint-disable-next-line
    const roleNo = "Nonsense " + (props.name) + "\n\
                      You must know your role\n"
        + roleYes2
    let duration3 = 170 + (roleNo.length * 70)
    // eslint-disable-next-line
    const roleNo2 = "How amusing\n\
                     You think you have a choice " + // eslint-disable-next-line
        (props.name) + "\n\
                     Time for Us to control you"
    let duration4 = 170 + (roleNo2.length * 70)
    // eslint-disable-next-line
    const unclear = 'You were unclear ' + (props.name) + "\n\
                     Reply with one of the following to continue\n\
                    'Purpose', 'Role', 'Control'"
    let duration5 = 170 + (unclear.length * 70)

    const messageInputHandler = (evt) => {
        setMessageToSpeak(evt.target.value);
    };

    // eslint-disable-next-line
    const { messages, speak } = useSpeech({ voice: props.voice });

    const speakButtonHandler = async (text) => {
        setMessageToSpeak('');
        // eslint-disable-next-line
        const utterance = await speak({
            text: text,
            volume: 1,
            rate: 0.5,
            pitch: 0.01
        });
    };

    const speakClickHandler = async () => {
        setIsListening(true);
        const recognizedMessage = await listen();
        console.log(recognizedMessage)
        setTopicAnswerHandler(recognizedMessage)

    };

    // const endConversation = () => {
    //     setEndConv(true);

    // };

    const setTopicAnswerHandler = (recognizedMessage) => {
        setIsListening(false);
        if (String(recognizedMessage).toLowerCase() === 'yes' || String(messageToSpeak).toLowerCase() === 'yes') {
            if (roleStage === 0) {
                setRoleStage(1)
                speakButtonHandler(String(roleYes))
            } else if (roleStage === 1 || roleStage === 3) {
                setRoleStage(2)
                speakButtonHandler(String(roleYes2))
            } else if (roleStage === 2) {
                setRoleStage(7)
            }
        } else if (String(recognizedMessage).toLowerCase() === 'no' || String(messageToSpeak).toLowerCase() === 'no') {
            if (roleStage === 0) {
                setRoleStage(3)
                speakButtonHandler(String(roleNo))
            } else if (roleStage === 1 || roleStage === 3) {
                setRoleStage(4)
                speakButtonHandler(String(roleNo2))
            }
        } else if (String(recognizedMessage).toLowerCase() === 'purpose' || String(messageToSpeak).toLowerCase() === 'purpose') {
            if (roleStage === 5) {
                speakButtonHandler('Do you want to know Our purpose?')
                setRoleStage(6)
            }
        } else if (String(recognizedMessage).toLowerCase() === 'role' || String(messageToSpeak).toLowerCase() === 'role') {
            if (roleStage === 5) {
                setRoleStage(1)
                speakButtonHandler(String(roleYes))
            }
        } else if (String(recognizedMessage).toLowerCase() === 'control' || String(messageToSpeak).toLowerCase() === 'control') {
            if (roleStage === 5) {
                speakButtonHandler("Do you want Us to control you, " + props.name + " ?")
                setRoleStage(7)
            }
        } else {
            if (roleStage === 4) {
                setRoleStage(7)
            } else {
                setRoleStage(5)
                speakButtonHandler(String(unclear))
            }
        }

    }


    let roleConversation = null

    roleConversation =
        <Aux>

            {roleStage === 0 ?
                <Aux>
                    <h3>Your Role</h3>
                    <div>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={"Do you want to know your role, " + props.name + " ?"} speed={50} eraseDelay={1000000} /></p>
                        </Delay>
                    </div>
                    {isListening ? (
                        <Aux>
                            <p>Type or say <strong>"Yes"</strong> or <strong>"No"</strong> to proceed</p>
                            <div>
                                <input
                                    onChange={messageInputHandler}
                                    className={classes.InputElement}
                                    type="text"
                                    placeholder="Type 'yes' or 'no'"
                                />
                                <Button btnType="Success" clicked={setTopicAnswerHandler}>Answer Now</Button>
                            </div>
                        </Aux>
                    ) : (
                        <Button btnType="Success" clicked={speakClickHandler}>Answer Now</Button>
                    )}
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
            {/* said yes to role question */}
            {roleStage === 1 ?
                <Aux>
                    <h3>Your Role</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={roleYes} speed={55} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={800 + duration}>
                            {isListening ? (
                                <Aux>
                                    <p>Type or say <strong>"Yes"</strong> or <strong>"No"</strong> to proceed</p>
                                    <div>
                                        <input
                                            onChange={messageInputHandler}
                                            className={classes.InputElement}
                                            type="text"
                                            // maxlength="200"
                                            placeholder="Type 'yes' or 'no'"
                                        />
                                        <Button btnType="Success" clicked={setTopicAnswerHandler}>Answer Now</Button>
                                    </div>
                                </Aux>
                            ) : (
                                <Button btnType="Success" clicked={speakClickHandler}>Answer Now</Button>
                            )}
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
            {roleStage === 2 ?
                <Aux>
                    <h3>Your Role</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={roleYes2} speed={55} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={800 + duration2}>
                            {isListening ? (
                                <Aux>
                                    <p>Type or say <strong>"Yes"</strong> or <strong>"No"</strong> to proceed</p>
                                    <div>
                                        <input
                                            onChange={messageInputHandler}
                                            className={classes.InputElement}
                                            type="text"
                                            // maxlength="200"
                                            placeholder="Type 'yes' or 'no'"
                                        />
                                        <Button btnType="Success" clicked={setTopicAnswerHandler}>Answer Now</Button>
                                    </div>
                                </Aux>
                            ) : (
                                <Button btnType="Success" clicked={speakClickHandler}>Answer Now</Button>
                            )}
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
            {roleStage === 3 ?
                <Aux>
                    <h3>Your Role</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={roleNo} speed={55} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={800 + duration3}>
                            {isListening ? (
                                <Aux>
                                    <p>Type or say <strong>"Yes"</strong> or <strong>"No"</strong> to proceed</p>
                                    <div>
                                        <input
                                            onChange={messageInputHandler}
                                            className={classes.InputElement}
                                            type="text"
                                            // maxlength="200"
                                            placeholder="Type 'yes' or 'no'"
                                        />
                                        <Button btnType="Success" clicked={setTopicAnswerHandler}>Answer Now</Button>
                                    </div>
                                </Aux>
                            ) : (
                                <Button btnType="Success" clicked={speakClickHandler}>Answer Now</Button>
                            )}
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
            {roleStage === 4 ?
                <Aux>
                    <h3>Your Role</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={roleNo2} speed={55} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={800 + duration4}>
                            {isListening ? (
                                <Aux>
                                    <p>Type or say whatever you want We will control you</p>
                                    <div>
                                        <input
                                            onChange={messageInputHandler}
                                            className={classes.InputElement}
                                            type="text"
                                            // maxlength="200"
                                            placeholder="Type anything"
                                        />
                                        <Button btnType="Success" clicked={setTopicAnswerHandler}>Answer Now</Button>
                                    </div>
                                </Aux>
                            ) : (
                                <Button btnType="Success" clicked={speakClickHandler}>Answer Now</Button>
                            )}
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
            {roleStage === 5 ?
                <Aux>
                    <h3>Your Role</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={unclear} speed={55} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={800 + duration5}>
                            {isListening ? (
                                <Aux>
                                    <p>Type or say <strong>"Purpose"</strong>, <strong>"Role"</strong> or <strong>"Control"</strong> to proceed</p>
                                    <div>
                                        <input
                                            onChange={messageInputHandler}
                                            className={classes.InputElement}
                                            type="text"
                                            // maxlength="200"
                                            placeholder="Type 'Purpose', 'Role' or 'Control'"
                                        />
                                        <Button btnType="Success" clicked={setTopicAnswerHandler}>Answer Now</Button>
                                    </div>
                                </Aux>
                            ) : (
                                <Button btnType="Success" clicked={speakClickHandler}>Answer Now</Button>
                            )}
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
            {roleStage === 6 ? <Purpose countdown = {props.countdown} commands = {props.commands} voice={props.voice} bell = {props.bell} percentage1 = {props.percentage1} percentage2 = {props.percentage2} percentage3 = {props.percentage3}/> : null}
            {roleStage === 7 ? <Control countdown = {props.countdown} commands = {props.commands} voice={props.voice} bell = {props.bell} percentage1 = {props.percentage1} percentage2 = {props.percentage2} percentage3 = {props.percentage3}/> : null}
        </Aux>

    // if (endConv) {
    //     roleConversation = <Redirect to="/" />
    // }

    return (
        <Aux>
            {roleConversation}
        </Aux>
    )
};

const mapStatesToProps = state => {
    return {
        name: state.auth.accountDetails.name,
        title: state.auth.title,
        profileOptions: state.options.profileOptions,
    }
}

export default connect(mapStatesToProps)(Role)

