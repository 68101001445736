import * as audioRunTypes from '../audioRunTypes'
import axios from '../../../../axios'

export const ChloroformReinforcementStart = () => {
    return {
        type: audioRunTypes.AUDIO_RUN_START
    }
}

export const ChloroformReinforcementSuccess = (Chloroform_Reinforcement) => {
        return {
            type: audioRunTypes.CHLOROFORM_REINFORCEMENT_SUCCESS,
            Chloroform_Reinforcement: Chloroform_Reinforcement,
        }
   
}

export const FastEffectChloroform = () => {
    return {
        type: audioRunTypes.FAST_EFFECT_CHLOROFORM,
    } 
}


export const NormalEffectChloroform = () => {
    return {
        type: audioRunTypes.NORMAL_EFFECT_CHLOROFORM,
    } 
}

export const ChloroformReinforcement = (userId, token) => {
    return dispatch => {
        dispatch(ChloroformReinforcementStart());
        axios.get('/Runs/'+userId+'/AudioRuns/Trigger_Reinforcement/Chloroform_Reinforcement.json?auth=' + token)
            
            .then( response => {
                // dispatch(ObedienceStageStart());
                if(response.data === null){
                    let Chloroform_Reinforcement = {
                        Chloroform_Reinforcement: 0,
                        Quick_Effect_Chloroform: 0,
                        Normal_Effect_Chloroform: 0,
                        Body_Play: 0,
                        Arousal_Effect: 0,
                        Amnesia_Effect: 0
                    }
                    axios.put('/Runs/'+userId+'/AudioRuns/Trigger_Reinforcement/Chloroform_Reinforcement.json?auth=' + token, Chloroform_Reinforcement)
                    .then(response => {
                        dispatch(ChloroformReinforcementSuccess(response.data))
                    })
                    .catch(error => {
                        dispatch(ChloroformReinforcementFailed(error.message.data))
                    })
                    
                } else{
                    dispatch(ChloroformReinforcementSuccess(response.data))
                }
                       
            })
            .catch(error => {
                dispatch(ChloroformReinforcementFailed(error.message.data))
            })
    }
}

export const ChloroformReinforcementFailed = (error) => {
    return{
        type: audioRunTypes.AUDIO_RUN_FAILED,
        error: error
    }
}

export const ChloroformReinforcementEnd = (userId, token, Quick_Effect_Chloroform, Normal_Effect_Chloroform, Body_Play, Arousal_Effect, Amnesia_Effect) => {
    return dispatch => {
        axios.get('/Runs/'+userId+'/AudioRuns/Trigger_Reinforcement/Chloroform_Reinforcement.json?auth=' + token)
            .then( response => {
                    let updatedChloroform_Reinforcement = {
                        Chloroform_Reinforcement: response.data.Chloroform_Reinforcement + 1,
                        Quick_Effect_Chloroform: response.data.Quick_Effect_Chloroform + Quick_Effect_Chloroform,
                        Normal_Effect_Chloroform: response.data.Normal_Effect_Chloroform + Normal_Effect_Chloroform,
                        Body_Play: response.data.Body_Play + Body_Play,
                        Arousal_Effect: response.data.Arousal_Effect + Arousal_Effect,
                        Amnesia_Effect: response.data.Amnesia_Effect + Amnesia_Effect,
                    }
                    axios.put('/Runs/'+userId+'/AudioRuns/Trigger_Reinforcement/Chloroform_Reinforcement.json?auth=' + token, updatedChloroform_Reinforcement)
                    .then(response => {
                        dispatch(ChloroformReinforcementCompleted())
                    })
                    .catch(error => {
                        dispatch(ChloroformReinforcementFailed(error.message.data))
                    })    
                       
            })
            .catch(error => {
                dispatch(ChloroformReinforcementFailed(error.message))
            })
    }
}

export const ChloroformReinforcementCompleted = () => {
    return{
            type: audioRunTypes.CHLOROFORM_REINFORCEMENT_COMPLETED,
        }
        
    
}