import React, { useState } from "react";
import TextTransition from "react-text-transition";
import TypingEffect from 'react-typing-effect'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import Button from '../../../../UI/Button/Button'
import classes from '../../../../../containers/HostsChamber/AudioRuns/AudioRuns.module.css'
import { ChloroformintroductionEnd } from '../../../../../store/actions/AudioRuns/audioRunsIndex'
import * as scripts from '../../../../../shared/AudioRuns/TriggerIntroduction/ChloroformIntroduction/ChloroformIntroductionScripts'
import * as Delays from '../../../../../shared/AudioRuns/TriggerIntroduction/ChloroformIntroduction/ChloroformIntroductionDelay'
import ChloroformRoom from '../../../../../assets/audio_runs/Trigger_Introduction/Chloroform_Introduction/Forced_Chloroform.mp3'
import { connect } from 'react-redux'
import Sound from 'react-sound'
import Delay from "react-delay";
import useInterval from 'react-useinterval';

const DELAY_TEXT = [3800]

function Forced_Chloroform({ userId, token, onChloroformintroductionEnd }) {

    let [count, setCount] = useState(0);
    let [forced_chloroform, setProtocol] = useState(true)

    const increaseCount = () => {
        if (forced_chloroform && count < scripts.FORCED_CHLOROFORM.length) {
            setCount(count + 1);
        }
    };

    useInterval(increaseCount, Delays.FORCED_CHLOROFORM_DELAY[count]);


    const exitChloroformRoomSequenceHandler = () => {
        setProtocol(forced_chloroform = false)
        onChloroformintroductionEnd(userId, token, 0, 1)
    }


    let forced_chloroform_form = null

    if (forced_chloroform) {
        forced_chloroform_form = (
            <Aux>
                {count < scripts.FORCED_CHLOROFORM.length ?
                    <Aux >
                        <Sound url={ChloroformRoom} playStatus={Sound.status.PLAYING} />
                        <Delay wait={DELAY_TEXT[count]}>
                            <TextTransition
                                text={<TypingEffect typingDelay={100} text={scripts.FORCED_CHLOROFORM[count]} speed={50} eraseDelay={1000000} />}
                                Overflow
                            />
                        </Delay>
                    </Aux>
                    :
                    <div className={classes.ButtonPadding}>
                        <Button btnType="Success" clicked={exitChloroformRoomSequenceHandler}>Thank you for rendering me unconscious</Button>
                    </div>
                }
            </Aux >
        )
    }


    return (
        <Aux>
            {forced_chloroform_form}
        </Aux>
    )

}

const mapStateToProps = state => {
    return {
        userId: state.auth.userId,
        token: state.auth.token,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onChloroformintroductionEnd: (userId, token, chloroform_room, forced_chloroform) => dispatch(ChloroformintroductionEnd(userId, token, chloroform_room, forced_chloroform))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Forced_Chloroform)