import React, { Component } from 'react';
import Aux from '../../../hoc/auxiliary/auxiliary';
import classes from './HypnosisShowMenu.module.scss'
// import classes2 from '../ExperienceMenu/ExperienceMenu.module.scss'
import HypnosisShow from '../../../assets/Images/Blank.png'
import LeaveShow from './HypnosisShowLeaveEarly'
import EnterTent from './HypnosisShowEnterTent'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'

class HypnosisStoryExplore extends Component {

    state = {
        explore: true,
        enterTent: false,
        leaveShow: false
    }

    EnterTentHandler = () => {
        this.setState({ startStory: false, enterTent: true })
    }

    LeaveShowHandler = () => {
        this.setState({ startStory: false, leaveShow: true })
    }
    render() {
        let HypnosisShowForm = null
        if (this.state.explore) {
            HypnosisShowForm = (
                <html style={{ backgroundImage: `url(${HypnosisShow})` }}>
                <div className={classes.storyFrom}>
                    <p>
                        Exploring the fair & wandering around, you come across some kids playing to your left with farm animals, feeding them & petting them. <br></br>
                        You stand there for a moment smiling while watching how happy these kids are and how much they’re having fun <br></br> 
                        While standing there you hear a bunch of people behind you all excited as one of them says <br></br>
                        <i>“Have you heard? There is a hypnosis show starting in 10 minutes! We should definitely check it out” </i><br></br>
                        Another replies saying <i>“Yeah! I’m sure it’s going to be fun. Come on let’s go!” </i><br></br>
                        Turning around you see them running towards a tent. Your curiosity getting the better of you, so you follow them to the tent. <br></br>
                        Stopping at the entrance, you see a poster on it the following is written<br></br>
                        <strong><i>“Step Right In! The Great O’s Hypnosis Show. For One night only!”</i></strong><br></br>
                        You think to yourself <i>"I should"</i>...
                    </p>
                    <form className={classes.centered} onClick={this.EnterTentHandler}>
                        <h3 className={classes.storyButtonSize}>Check it out</h3>
                    </form>
                    <form className={classes.centered} onClick={this.LeaveShowHandler}>
                        <h3 className={classes.storyButtonSize}>Leave</h3>
                    </form>
                </div>
                </html>
                )
        }
        if(this.state.enterTent){
            HypnosisShowForm = (
                <EnterTent/>
            )
        }
        if(this.state.leaveShow){
            HypnosisShowForm = (
                <LeaveShow />
            )
        }

        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {HypnosisShowForm}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >




        )
    }
}


export default HypnosisStoryExplore