import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import TypingEffect from 'react-typing-effect'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import classes from '../../TextToSpeech.module.css';
import { connect } from 'react-redux'
import Button from '../../../../UI/Button/Button'
import Delay from 'react-delay'
import { useSpeech, useRecognition } from 'react-web-voice';

const Control = (props) => {
    const [messageToSpeak, setMessageToSpeak] = React.useState('');
    // const [startConv, setConvState] = useState(false)
    const [isListening, setIsListening] = useState(false);
    const [endConv, setEndConv] = useState(false);
    const [controlStage, setControlStage] = useState(0);
    const [prevControlStage, setPrevControlStage] = useState(0);
    // eslint-disable-next-line
    const { transcripts, listen } = useRecognition();

    // eslint-disable-next-line
    const bondageStart = (props.name) + " We will tie you Up\n\
                        We will tie you up & bind you using Our special wires\n\
                        These wires will not only bind you\n\
                        They will take control over your body too\n\
                        Controlling it & making you automatically reply to Us whenever We ask you a question\n\
                        As We tie you up\n\
                        You feel your body relaxing\n\
                        Your mind starts getting fuzzy & floaty\n\
                        It becomes harder & harder to think\n\
                        So much easier to just follow & obey\n\
                        Are you ready " + (props.name) + "?"
    let duration4 = 170 + (bondageStart.length * 70)
    // eslint-disable-next-line
    const bondageInduction = "You start feeling Our wires wrapping themselves around you\n\
                               Feeling them slowly starting from your legs\n\
                               Pulling your legs together closer\n\
                               As they tie you up\n\
                               While your feet are being tied you see more wires appearing\n\
                               You see these green wires now all appearing from the screen\n\
                               Wrapping themselves around your arms\n\
                               Holding them\n\
                               Controlling them\n\
                               Controlling them so when We tell you to reply you reply automatically\n\
                               Feeling these wires coming out of the screen\n\
                               Pulling you closer\n\
                               Pulling you closer to Us\n\
                               As the wires continue to wrap themselves around you\n\
                               You feel the wires tying your legs firmly\n\
                               While others hold your hands to reply\n\
                               You feel these cold metallic green wires over your skin\n\
                               You feel them moving & crawling as they take control\n\
                               They start to take control of your posture\n\
                               Forcing you to focus completely on the screen\n\
                               On Our words\n\
                               Helpless to look away\n\
                               Helpless to even move your head\n\
                               As the wires hold your head in place\n\
                               Forcing you to look straight at the screen\n\
                               Whenever you try to move\n\
                               The wires firmly overpower you & force you to look again\n\
                               Soaking in all Our words\n\
                               Listening intently to Our voice\n\
                               Getting lost completely in Our control\n\
                               Feeling your body now relaxing to Us\n\
                               Relaxing to Our wires\n\
                               Submitting & surrendering to them\n\
                               Your mind empty & floaty\n\
                               Your thoughts escaping your mind\n\
                               Enjoying how good it feels to be helpless\n\
                               To be powerless\n\
                               Enjoying how good it feels to be tied up\n\
                               To be tied up by Us\n\
                               Now it is time to continue your conditioning " + // eslint-disable-next-line
        (props.name) + "\n\
                               Now it is time to Submit"
    let duration5 = 170 + (bondageInduction.length * 50)
    // eslint-disable-next-line
    const bondageEnd = "Time to obey " + (props.name) + "\n\
                         Time to obey Us\n\
                         Feeling bound\n\
                         Bound & restricted\n\
                         The more you try to move the tighter you are bound\n\
                         The tighter you are bound the more fuzzy & floaty your mind becomes\n\
                         Feeling the bliss\n\
                         The bliss that comes with losing control\n\
                         The bliss that comes with being powerless\n\
                         The bliss that comes with being helpless\n\
                         Our words & voice filling your mind completely\n\
                         Leaving a big smile on your face\n\
                         Filled with happiness & joy\n\
                         No thoughts\n\
                         No Will\n\
                         You Obey what We say\n\
                         You love to be tied up by Us\n\
                         You love how good it feels when We tie you up\n\
                         Craving & seeking it more\n\
                         Your thoughts start getting foggy\n\
                         Hazy & dazed\n\
                         Whenever you think of Us tying you up\n\
                         You crave it\n\
                         You seek it\n\
                         You want more\n\
                         You need to be tied up by Us whenever you get the chance\n\
                         You feel the wires tighten up now as they force you to focus on the screen\n\
                         Letting out a sigh of relief\n\
                         Knowing that you are totally helpless right now\n\
                         Smiling wide knowing that We control you\n\
                         Can't wait for the next time We tie you up & make you feel this way again\n\
                         As a bell rings in a moment\n\
                         It will wake you up\n\
                         Slowly the wires will untie you and let you move\n\
                         You stretch as you return back to normal\n\
                         Remembering what We did\n\
                         With a huge smile on your face\n\
                         Having loved each and every moment of what We just did\n\
                         Excited to return again\n\
                         As We wake you up now " + (props.name)
    let duration6 = 170 + (bondageEnd.length * 60)
    // eslint-disable-next-line
    const unclear = 'You were unclear ' + (props.name) + "\n\
                     Reply with one of the following to continue\n\
                    'Bondage' or 'Submit'"
    let duration7 = 170 + (unclear.length * 70)

    const messageInputHandler = (evt) => {
        setMessageToSpeak(evt.target.value);
    };

    let voice = ''
    if (props.profileOptions.text_to_speech_voice === '1' || props.profileOptions.text_to_speech_voice === '2') {
        voice = "Microsoft David Desktop - English (United States)"
    } else if (props.profileOptions.text_to_speech_voice === '3' || props.profileOptions.text_to_speech_voice === '4') {
        voice = "Microsoft Zira Desktop - English (United States)"
    }
    // eslint-disable-next-line
    const { messages, speak } = useSpeech({ voice: voice });

    const speakButtonHandler = async (text) => {
        setMessageToSpeak('');
        // eslint-disable-next-line
        const utterance = await speak({
            text: text,
            volume: 1,
            rate: 0.5,
            pitch: 0.01
        });
    };

    const speakClickHandler = async () => {
        setIsListening(true);
        const recognizedMessage = await listen();
        console.log(recognizedMessage)
        setTopicAnswerHandler(recognizedMessage)

    };

    const endConversation = () => {
        setEndConv(true);

    };

    const setTopicAnswerHandler = (recognizedMessage) => {
        setIsListening(false);
        if (String(recognizedMessage).toLowerCase() === 'yes' || String(messageToSpeak).toLowerCase() === 'yes') {
            if (controlStage === 0) {
                setControlStage(1)
                setPrevControlStage(1)
                speakButtonHandler(String(bondageInduction))
            }
        } else if (String(recognizedMessage).toLowerCase() === 'bondage' || String(messageToSpeak).toLowerCase() === 'bondage') {
            if (controlStage === 3) {
                setControlStage(0)
                speakButtonHandler(String(bondageStart))
            }
        } else if (String(recognizedMessage).toLowerCase() === 'submit' || String(messageToSpeak).toLowerCase() === 'submit') {
            if (controlStage === 1) {
                setControlStage(2)
                setPrevControlStage(1)
                speakButtonHandler(String(bondageEnd))
            }
            if (controlStage === 3) {
                if (prevControlStage === 1) {
                    setControlStage(2)
                    speakButtonHandler(String(bondageEnd))
                }
            }
        } else {
            setControlStage(3)
            speakButtonHandler(String(unclear))
        }

    }


    let controlConversation = null

    controlConversation =
        <Aux>


            {controlStage === 0 ?
                <Aux>
                    <h3>Your control</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={bondageStart} speed={50} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={800 + duration4}>
                            {props.bell}
                            {isListening ? (
                                <Aux>
                                    <p>Type or say <strong>Yes</strong> to proceed</p>
                                    <div>
                                        <input
                                            onChange={messageInputHandler}
                                            className={classes.InputElement}
                                            type="text"
                                            // maxlength="200"
                                            placeholder="Type 'Yes'"
                                        />
                                        <Button btnType="Success" clicked={setTopicAnswerHandler}>Answer Now</Button>
                                    </div>
                                </Aux>
                            ) : (
                                <Button btnType="Success" clicked={speakClickHandler}>Answer Now</Button>
                            )}
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
            {controlStage === 1 ?
                <Aux>
                    <h3>Your control</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={bondageInduction} speed={50} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={800 + duration5}>
                            {props.bell}
                            {isListening ? (
                                <Aux>
                                    <p>Type or say <strong>"Submit"</strong> to proceed</p>
                                    <div>
                                        <input
                                            onChange={messageInputHandler}
                                            className={classes.InputElement}
                                            type="text"
                                            // maxlength="200"
                                            placeholder="Type 'Submit'"
                                        />
                                        <Button btnType="Success" clicked={setTopicAnswerHandler}>Answer Now</Button>
                                    </div>
                                </Aux>
                            ) : (
                                <Button btnType="Success" clicked={speakClickHandler}>Answer Now</Button>
                            )}
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
            {controlStage === 2 ?
                <Aux>
                    <h3>Your control</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={bondageEnd} speed={50} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={800 + duration6}>
                            {props.bell}
                            <Button btnType="Success" clicked={endConversation}>Thank you for tying me up</Button>
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
            {controlStage === 3 ?
                <Aux>
                    <h3>Your control</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={unclear} speed={50} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={800 + duration7}>
                            {isListening ? (
                                <Aux>
                                    <p>Type or say <strong>"Bondage"</strong> or <strong>"Submit"</strong> to proceed</p>
                                    <div>
                                        <input
                                            onChange={messageInputHandler}
                                            className={classes.InputElement}
                                            type="text"
                                            // maxlength="200"
                                            placeholder="Type 'Bondage' or 'Submit'"
                                        />
                                        <Button btnType="Success" clicked={setTopicAnswerHandler}>Answer Now</Button>
                                    </div>
                                </Aux>
                            ) : (
                                <Button btnType="Success" clicked={speakClickHandler}>Answer Now</Button>
                            )}
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
        </Aux>

    if (endConv) {
        controlConversation = <Redirect to="/" />
    }

    return (
        <Aux>
            {controlConversation}
        </Aux>
    )
};

const mapStatesToProps = state => {
    return {
        name: state.auth.accountDetails.name,
        title: state.auth.title,
        profileOptions: state.options.profileOptions,
    }
}

export default connect(mapStatesToProps)(Control)

