export const INSTANT_MOTOR_CONTROL_REINFORCEMENT_DELAY = [
    10000, //"Disable Motor Functions",
    4000, //"Your whole body stops",
    9200, //"As We seize control over your motor functions",
    3650, //"Over your body",
    4800, //"Allowing your eyes to move",
    5400, //"Move only to stare at the screen",
    5000, //"To follow each word We say",
    9700, //"To follow each word We say & be conditioned by them",
    4700, //"Be conditioned by Us",
    5000, //"To accept Our control",
    5000, //"Our power over you",
    8650, //"We have overridden your motor functions",
    5250, //"We have reconfigured your body",
    6550, //"We have reconfigured your body to disable",
    5200, //"Disable all motor functions",
    3800, //"When We desire",
    4800, //"Your body belongs to Us",
    5250, //"Your mind belongs to Us",
    4750, //"You belong to Us",
    4200, //"These are the facts",
    4400, //"Hear these words echoing",
    3700, //"Echoing in your mind",
    5450, //"'Host has no control'", 
    5650, //"'Host has no power'",
    6200, //"'Host is helpless to resist'",
    6700, //"'Host is powerless to fight'",
    8800, //"'Host's mind belongs to The Hypno Virus'",
    8000, //"'Host's body belongs to The Hypno Virus'",
    7800, //"'Host belongs to The Hypno Virus'",
    5900, //"These words continue to echo in your mind",
    5800, //"Even after We allow you to move again",
    9600, //"This words are so firmly ingrained into your mind",
    3500, //"They continue to echo",
    5100, //"They continue to echo as We...",
    17000, //"Enable Motor Functions"

]

export const SLOW_MOTOR_CONTROL_REINFORCEMENT_DELAY = [

    13100, //"Disable Motor Functions Slowly",
    4700, //"Slowly & Methodically",
    4550, //"Your body starts to stop",
    5900, //"As We seize control over it",
    8550, //"One part at a time stops to function",
    4600, //"Starting with your feet",
    6450, //"Going all the way up till it reaches your head",
    6400, //"Truly like a virus infecting your body",
    6800, //"Our trigger infects you",
    10200, //"As your feet are now completely bound in place",
    6200, //"There is no use trying to move them",
    3500, //"The more you try",
    3700, //"The more you struggle",
    6200, //"The more bound they become",
    5600, //"Your legs struggling now",
    3700, //"Struggling to move",
    8700, //"As Our trigger infects your whole body",
    5300, //"One part at a time",
    3300, //"It disables it",
    5700, //"One part at a time",
    3700, //"We seize control over",
    5900, //"One part at a time",
    4600, //"We override your control",
    4350, //"Unable to move your legs",
    6300, //"Your legs are completely bound in place",
    3600, //"Slowly moving up",
    4800, //"Moving up your body",
    3800, //"Infecting you further",
    3700, //"Infecting you more",
    5000, //"There is no escaping your fate",
    5700, //"There is no escaping the fact that...",
    7000, //"Your whole body will be disabled soon",
    8000, //"Your waist & midsection seizes to move right now",
    3700, //"Becoming infected",
    5500, //"Becoming bound in place",
    8800, //"Disabling your motor functions more & more",
    6500, //"Droning & drilling deep inside your mind",
    2700, //"Conditioning you",
    3000, //"Programming you",
    3500, //"Brainwashing you",
    2500, //"To accept.",
    4700, //"To accept Our control",
    5200, //"The power We have over your body",
    5050, //"The power We have over your mind",
    4800, //"The power We have over you",
    6300, //"There is no stopping this infection",
    8800, //"This is not only an infection to take control of your body",
    10500, //"This is an infection to cement & further establish Our hold",
    4500, //"Our hold over you",
    5500, //"We have a firm grip over you",
    7500, //"As your chest & arms seize to move",
    5400, //"You realize the gravity of your situation",
    7000, //"You realize the gravity of Our control",
    5000, //"What We say is the truth",
    4900, //"There is no second guessing",
    2900, //"No doubting",
    5800, //"No objections When it comes to Us",
    4900, //"When it comes to The Hypno Virus",
    8700, //"Feeling your shoulders & neck now bound in place",
    4500, //"Just look at the screen now",
    5000, //"Look at the screen and repeat what you see",
    9200, //"Repeat as you feel your face slowly becoming infected",
    6600, //"Slowly becoming bound in place",
    4000, //"That by the time you're done",
    7900, //"Your motor functions will be completely disabled",
    5400, //"'Host has no control'",
    5000, //"'Host has no power'",
    7600, //"'Host serves The Hypno Virus'",
    8600, //"'Host obeys The Hypno Virus'",
    10600, //"'Host's body belongs to The Hypno Virus'",
    9600, //"'Host's mind belongs to The Hypno Virus'",
    8600, //"'Host belongs to The Hypno Virus'",
    7100, //"With that your motor functions are disabled",
    4200, //"No longer able to move",
    5000, //"Staring at the screen infront of you",
    3000, //"Still aware",
    4400, //"Still awake and aware",
    3350, //"But unable to move",
    3000, //"Remaining like this",
    5800, //"With all your motor functions disabled",
    5500, //"Until We allow you to move again",
    38800, //
    12000, //"Enable Motor Functions"
    
]

export const MENTAL_CONTROL_REINFORCEMENT_DELAY = [

    9500, //"Disable Motor Functions",
    6800, //"Disable Mental Control",
    2300, //"Empty",
    2400, //"Mindless",
    2700, //"& Blank",
    3900, //"Stuck in place",
    4000, //"Bound where you are",
    8900, //"All your motor functions have been disabled", 
    5500, //"All you can do is listen",
    4350, //"Listen & accept",
    4100, //"Accept what We say",
    4600, //"Accept Our words",
    4800, //"Accept Our control",
    7600, //"Accept that We control your body",
    8000, //"Accept that We control your mind",
    7500, //"Accept that all it takes is a phrase",
    3800, //"A phrase to control you",
    4500, //"A phrase to trigger you",
    8000, //"To render you mindless & obedient to Us",
    5400, //"We have reconfigured your brain",
    5600, //"We have rewired your mind",
    10200, //"We have seized control over both your mind & body",
    3000, //"There is no doubt",
    4700, //"No doubt at all in your mind",
    4700, //"The moment you hear Our voice",
    5000, //"You are no longer in control",
    5000, //"The moment you hear Our voice",
    3900, //"All you can think about",
    3250, //"All you care for",
    3800, //"All you need is to...",
    7300, //"Obey what The Hypno Virus say",
    5300, //"Your mental control belongs to Us",
    6200, //"Your motor functions belong to Us",
    6700, //"You belong to Us",
    5200, //"Feeling shivers down your spine",
    5000, //"Goosebumps & tingles",
    3600, //"As you hear Our voice",
    4900, //"The Hypno Virus voice",
    4800, //"Rendering you this helpless",
    7300, //"Taking away your mental control",
    7100, //"Desabling all your motor functions",
    3400, //"You can not move",
    3600, //"You can not think",
    3650, //"You are an empty",
    2000, //"Mindless",
    4600, //"& Blank host",
    6300, //"Host serves The Hypno Virus",
    7100, //"Host obeys The Hypno Virus",
    8300, //"Host submits to The Hypno Virus",
    3800, //"You are a host",
    4300, //"You are Our host",
    10800, //"And you will always return to be conditioned by Us",
    6000, //"Enable Mental Control",
    11000, //"Enable Motor Functions"
    
]

export const WIRED_RECONFIGURATION_DELAY = [

    11100, //"Disable Motor Functions",
    7500, //"All your motor functions shut down",
    3900, //"Your body stops",
    9300, //"We have seized control over your motor functions",
    11800, //"We have created a special device such for this occasion",
    6100, //"You can hear these noises can't you?",
    8100, //"These are the noises of Our device",
    7200, //"This device will soon be connected to you",
    8100, //"A wire will latch to the back of your neck",
    9000, //"not only reinforcing Our control over your motor functions",
    9100, //"But will also reconfiguring your body on a neural level",
    8200, //"Reconfiguring your body to react to Our voice",
    8900, //"To have Us over ride your control over your own body",
    7300, //"You feel a gentle painless prick",
    6100, //"As the wire latches to the back of your neck",
    3900, //"Your eyes start to flutter",
    2300, //"Rolling back",
    5000, //"As the reconfiguration start",
    5100, //"You hear this buzzing sound",
    6400, //"This buzzing sound echoing in your mind",
    4400, //"We are draining your thoughts",
    7000, //"We are rendering you completely helpless to Us",
    7450, //"As the buzzing noise echoes louder & louder",
    8600, //"Your thoughts & free will continue to fade away",
    7200, //"We are seizing control over your nerves",
    4900, //"Over your whole nervous system",
    6800, //"Even with your motor functions disabled",
    8000, //"You can still feel this wire sending nanobots",
    5850, //"nanobots that are slowly spreading",
    7000, //"Slowly spreading all through your body",
    3900, //"Moving down your neck",
    3800, //"Down to your shoulders",
    3600, //"Down to your spine",
    5500, //"Sending shivers down your spine",
    13200, //"And by extension assisting the wires to send these nanobots faster",
    4000, //"For them to spread more",
    3300, //"Spread further",
    4500, //"Spread all over your body",
    9100, //"These nanobots are programmed to react to Our voice",
    4700, //"React to what We say",
    4500, //"Whenever We use the phrase",
    7450, //"'Seize control' followed by a body part",
    7600, //"This part of your body will become under Our control",
    6100, //"We will be able to move it freely",
    8300, //"We will be able to move it as We desire",
    8800, //"Feeling these nanobots spreading all over your body",
    5800, //"As the buzzing noise continues to echo",
    3700, //"Rendering you helpless",
    4600, //"Mindless & powerless to Us",
    4500, //"Reconfiguring your mind",
    4100, //"Reconfiguring your body",
    3700, //"Truly becoming a host",
    6450, //"A host infected by Our nanobots",
    7900, //"Nanobots that control both your mind & body",
    6500, //"Control each and every muscle in your body",
    3100, //"Only to return to you",
    4400, //"To return to you when We say",
    4300, //"'Release Control'",
    5000, //"Seize Control: Right hand",
    6000, //"We have taken control over your right hand",
    2700, //"Raise hand",
    2900, //"Lower hand",
    4200, //"Your hand obeys without thought",
    10200, //"We will use your own hand to conclude Our reconfiguration of your body",
    11600, //"As well as demonstrate that you no longer have control over your own body",
    7200, //"Once We command you to tap the back of your neck",
    7700, //"Your right hand will instant tap the back of your neck",
    5200, //"Releasing you from Our control",
    9200, //"Along with granting you control over your motor functions again",
    3800, //"Always remember",
    9900, //"Always remember that you belong to Us",
    9000, // "Tap the back of your neck"

]
