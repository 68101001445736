import React from 'react';
import VirusLogo from '../../assets/Images/Logo.png';
import classes from './Logo.module.css'

const logo = (props) => (
    <div className = {classes.Logo}>
        <img src={VirusLogo} alt="TheHypnoVirusOnline"/>
    </div>
);

export default logo;