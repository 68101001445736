import React, { Component } from 'react';
import Aux from '../auxiliary/auxiliary';

const withErrorHandler = (WrappedComponenet, axios) => {
    return class extends Component {
        state = {
            error: null
        }

        componentWillMount() {

            this.requestInterceptor = axios.interceptors.request.use(req => {
                this.setState({ error: null })
                return req;
            });

            this.responseInterceptor = axios.interceptors.response.use(res => res, error => {
                this.setState({ error: error })
                
            });
        }

        componentWillUnmount () {
            axios.interceptors.request.eject(this.requestInterceptor);
            axios.interceptors.response.eject(this.responseInterceptor);
        }

        errorConfirmedHandler = () => {
            this.setState({ error: null });
        }

        render() {
            return (
                <Aux>
                    {this.state.error? this.state.error.message: null}
                    <WrappedComponenet {...this.props} />
                </Aux>
            )
        }
    }

}


export default withErrorHandler