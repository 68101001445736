import * as actionTypes from './actionTypes'
import axios from '../../axios'

export const dailyCommandStart = () => {
    return {
        type: actionTypes.DAILY_COMMAND_START
    }
}

export const dailyCommandSuccess = (dailyCommand) => {
    return {
        type: actionTypes.DAILY_COMMAND_SUCCESS,
        dailyCommand: dailyCommand,
    }
}

export const hypnoVisualsActivate = () => {
    return {
        type: actionTypes.HYPNO_VISUAL_ACTIVATE,
        
    }
}

export const dailyCommandFail = (error) => {
    return{
        type: actionTypes.DAILY_COMMAND_FAILED,
        error: error
    }
}

export const dailyCommand = () => {
    return dispatch => {
        dispatch(dailyCommandStart());
        const today = new Date().getDate()
        axios.get('/DailyCommands/'+today+'.json')
        
            .then( response => {         
                dispatch(dailyCommandSuccess(response.data));
            })
            .catch(error => {
                dispatch(dailyCommandFail(error.message))
            })
    }
}