import * as conditioningStagesTypes from '../conditioningStagesTypes'
import axios from '../../../../axios'

export const ObedienceStage3Success = (obedience_stage3) => {

    if(obedience_stage3.obedience_stage3 > 4){
        let obedienceStage3CommandsNum =Math.floor(Math.random() * 3) + 1
        let obedience3counter = 0
        let obedienceCommandsArray = []
        while (obedience3counter < obedienceStage3CommandsNum) {
            let commandNumber = Math.floor(Math.random() * 5)
            if (!obedienceCommandsArray.includes(commandNumber)) {
                obedienceCommandsArray.push(commandNumber)
                obedience3counter++
            }
        }
        return {
            type: conditioningStagesTypes.OBEDIENCE_STAGE_3_SUCCESS,
            obedience_stage3: obedience_stage3,
            obedienceStage3Commands: obedienceCommandsArray
        }
    }
    else if (obedience_stage3.obedience_stage3 <= 4){
        return {
            type: conditioningStagesTypes.OBEDIENCE_STAGE_3_SUCCESS,
            obedience_stage3: obedience_stage3,
            obedienceStage3Commands: [5]
        }
    }
}

export const ObedienceStageFailed = (error) => {
    return{
        type: conditioningStagesTypes.OBEDIENCE_STAGE_FAILED,
        error: error
    }
}

export const ObedienceStage3 = (userId, token) => {
    return dispatch => {
        axios.get('/Runs/'+userId+'/Conditioning_Stages/Obedience_Stage_3/.json?auth=' + token)
            .then( response => {
                if(response.data === null){
                    let obedience_stage3 = {
                        obedience_stage3: 0,
                        Host_Chip_Command: 0, //Self trigger 3 taps back of neck
                        Mind_Format_Command: 0, //Remember/Forget
                        Mechanical_Tentacles_Command: 0, //Silver Mechanical Tentacles
                        Binural_Beat_Command: 0, //Beats to condition Hosts to go blank
                        Chloroformed_Conditioned_Command:0 //Chloroformed (remembering/forgetting) Our conditioning to (Obedience, infecting other, pleasure we bring, desire for more runs) 
                    }
                    axios.put('/Runs/'+userId+'/Conditioning_Stages/Obedience_Stage_3/.json?auth=' + token, obedience_stage3)
                    .then(response => {
                        dispatch(ObedienceStage3Success(response.data))
                    })
                    .catch(error => {
                        dispatch(ObedienceStageFailed(error.message.data))
                    })
                    
                } else{
                    dispatch(ObedienceStage3Success(response.data))
                }
                       
            })
            .catch(error => {
                dispatch(ObedienceStageFailed(error.message))
            })
    }
}

export const ObedienceStage3Counter = (stage3Counter, commandNumber) => {
    return{
        type: conditioningStagesTypes.OBEDIENCE_STAGE_3_COUNTER,
        obedienceStage3Counter: stage3Counter + 1,
        commandNumber: commandNumber
    }
}

export const ObedienceStage3End = (userId, token, Host_Chip_Command, Mind_Format_Command, Mechanical_Tentacles_Command, Binural_Beat_Command, Chloroformed_Conditioned_Command) => {
    return dispatch => {
       
        axios.get('/Runs/'+userId+'/Conditioning_Stages/Obedience_Stage_3/.json?auth=' + token)
        
            .then( response => {
                    let updatedobedience_stage3 = {
                        obedience_stage3: response.data.obedience_stage3 + 1,
                        Host_Chip_Command: response.data.Host_Chip_Command + Host_Chip_Command,
                        Mind_Format_Command: response.data.Mind_Format_Command + Mind_Format_Command,
                        Mechanical_Tentacles_Command: response.data.Mechanical_Tentacles_Command + Mechanical_Tentacles_Command,
                        Binural_Beat_Command: response.data.Binural_Beat_Command + Binural_Beat_Command,
                        Chloroformed_Conditioned_Command: response.data.Chloroformed_Conditioned_Command + Chloroformed_Conditioned_Command
                    }
                    axios.put('/Runs/'+userId+'/Conditioning_Stages/Obedience_Stage_3/.json?auth=' + token, updatedobedience_stage3)
                    .then(response => {
                        dispatch(ObedienceStage3Completed())
                    })
                    .catch(error => {
                        dispatch(ObedienceStageFailed(error.message.data))
                    })    
                       
            })
            .catch(error => {
                dispatch(ObedienceStageFailed(error.message))
            })
    }
}

export const ObedienceStage3Completed = () => {
    return{
            type: conditioningStagesTypes.OBEDIENCE_STAGE_3_COMPLETED,
        }
        
    
}


export const ObedienceStage3Start = () => {
    return {
        type: conditioningStagesTypes.OBEDIENCE_STAGE_3_START,
    }
}