import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import TypingEffect from 'react-typing-effect'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import classes from '../../TextToSpeech.module.css';
import { connect } from 'react-redux'
import Button from '../../../../UI/Button/Button'
import Delay from 'react-delay'
import { useSpeech, useRecognition } from 'react-web-voice';
import Role from '../Role/roleOpen'
import Control from '../Control/controlOpen'

const Purpose = (props) => {
    const [messageToSpeak, setMessageToSpeak] = React.useState('');
    // const [startConv, setConvState] = useState(false)
    const [isListening, setIsListening] = useState(false);
    const [endConv, setEndConv] = useState(false);
    const [purposeStage, setPurposeStage] = useState(0);
    // eslint-disable-next-line
    const { transcripts, listen } = useRecognition();
    // eslint-disable-next-line
    const purposeYes = 'We are The Hypno Virus\n\
                   Our purpose is simple\n\
                   Our directives are clear\n\
                   We exist to...\n\
                   Control & Condition\n\
                   Control & Condition everyone\n\
                   Even you ' + // eslint-disable-next-line
        (props.name) + '\n\
                   We seek to control & condition everyone to become Our host\n\
                   Become a host for The Hypno Virus\n\
                   You want to become a host ' + // eslint-disable-next-line  
        (props.name) + ' right?'
    let duration = 170 + (purposeYes.length * 70)
    // eslint-disable-next-line
    const purposeYes1 = 'Very good ' + (props.name) + '\n\
                        We know this is what you have been seeking\n\
                        What you have been searching for\n\
                        This is Our purpose\n\
                        We were created to control\n\
                        We were created to control people like you ' + // eslint-disable-next-line
        (props.name) + '\n\
                        We were designed to guide you and show you the way\n\
                        The way to submit\n\
                        The way to submit and obey\n\
                        Our purpose is to help you understand your role\n\
                        What you need to do and how you need to act in order to become a better submissive\n\
                        Do you want to learn more about Our purpose\n\
                        Or do you want to learn about your role?'
    let duration1 = 170 + (purposeYes1.length * 70)
    // eslint-disable-next-line              
    const purposeYes2 = "We seek to create more hosts\n\
                         Hosts for The Hypno Virus\n\
                         Hosts that serve Us\n\
                         Hosts that obey Us\n\
                         Hosts that work for Us\n\
                         Hosts that can become tools for Us to use\n\
                         We seek to create countless hosts\n\
                         Hosts with one thought\n\
                         Hosts with one purpose and one directive\n\
                         Hosts that obey what The Hypno Virus say\n\
                         You obey what We say don't you " + // eslint-disable-next-line  
        (props.name) + '?'
    let duration2 = 170 + (purposeYes2.length * 70)
    // eslint-disable-next-line  
    const purposeYes3 = "That is what We expect of you " + (props.name) + '\n\
                         We are pleased with you\n\
                         We look forward to the next time We talk with you'
    let duration3 = 170 + (purposeYes3.length * 70)
    // eslint-disable-next-line  
    const purposeNo = 'Are you sure this is what you want?\n\
                       Do you really want to miss a chance to talk to Us and for Us to have a conversation?'
    let duration4 = 170 + (purposeNo.length * 70)
    // eslint-disable-next-line  
    const purposeNo1 = 'Very well then ' + (props.name) + '\n\
                        You are missing out on a wonderful conversation, goodbye'
    let duration5 = 170 + (purposeNo1.length * 70)
    // eslint-disable-next-line  
    const purposeNo2 = 'That is unacceptable ' + (props.name) + '\n\
                        You do not have this choice any longer\n\
                        We are the ones who decide if you are allowed to say no or not\n\
                        We are the ones who decide if you are allowed to think or not\n\
                        We are the ones who control you\n\
                        We have decided that you need to be controlled'
    let duration6 = 170 + (purposeNo2.length * 70)
    // eslint-disable-next-line  
    const unclear = 'You were unclear ' + (props.name) + "\n\
                    Reply with one of the following to continue\n\
                    'Purpose', 'Role', 'Control'"
    let duration7 = 170 + (unclear.length * 70)
    const messageInputHandler = (evt) => {
        setMessageToSpeak(evt.target.value);
    };

    // eslint-disable-next-line
    const { messages, speak } = useSpeech({ voice: props.voice });

    const speakButtonHandler = async (text) => {
        setMessageToSpeak('');
        // eslint-disable-next-line
        const utterance = await speak({
            text: text,
            volume: 1,
            rate: 0.5,
            pitch: 0.01
        });
    };

    const speakClickHandler = async () => {
        setIsListening(true);
        const recognizedMessage = await listen();
        console.log(recognizedMessage)
        setTopicAnswerHandler(recognizedMessage)

    };

    const endConversation = () => {
        setEndConv(true);

    };

    const setTopicAnswerHandler = (recognizedMessage) => {
        setIsListening(false);
        if (String(recognizedMessage).toLowerCase() === 'yes' || String(messageToSpeak).toLowerCase() === 'yes') {
            if (purposeStage === 0) {
                setPurposeStage(1)
                speakButtonHandler(String(purposeYes))
            } else if (purposeStage === 1) {
                setPurposeStage(2)
                speakButtonHandler(String(purposeYes1))
            } else if (purposeStage === 3) {
                setPurposeStage(4)
                speakButtonHandler(String(purposeYes3))
            } else if (purposeStage === 5) {
                setPurposeStage(6)
                speakButtonHandler(String(purposeNo1))
            }
        } else if (String(recognizedMessage).toLowerCase() === 'no' || String(messageToSpeak).toLowerCase() === 'no') {
            if (purposeStage === 0) {
                setPurposeStage(5)
                speakButtonHandler(String(purposeNo))
            } else if (purposeStage === 5) {
                setPurposeStage(1)
                speakButtonHandler(String(purposeYes3))
            } else if (purposeStage === 1 || purposeStage === 2) {
                setPurposeStage(7)
                speakButtonHandler(String(purposeNo2))
            }
        } else if (String(recognizedMessage).toLowerCase() === 'purpose' || String(messageToSpeak).toLowerCase() === 'purpose') {
            if (purposeStage === 2) {
                setPurposeStage(3)
                speakButtonHandler(String(purposeYes2))
            } else if (purposeStage === 10) {
                setPurposeStage(1)
                speakButtonHandler(String(purposeYes))
            }
        } else if (String(recognizedMessage).toLowerCase() === 'role' || String(messageToSpeak).toLowerCase() === 'role') {
            if (purposeStage === 2 || purposeStage === 10) {
                setPurposeStage(8)
                speakButtonHandler("Do you want to know your role, " + props.name + " ?")
            }
        } else if (String(recognizedMessage).toLowerCase() === 'control' || String(messageToSpeak).toLowerCase() === 'control') {
            if (purposeStage === 7 || purposeStage === 10) {
                setPurposeStage(9)
                speakButtonHandler("Do you want Us to control you, " + props.name + " ?")
            }
        } else {
            setPurposeStage(10)
            speakButtonHandler(String(unclear))
        }

    }


    let purposeConversation = null

    purposeConversation =
        <Aux>

            {purposeStage === 0 ?
                <Aux>
                    <h3>Our Purpose</h3>
                    <div>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={'Do you want to know Our purpose?'} speed={50} eraseDelay={1000000} /></p>
                        </Delay>
                    </div>
                    {isListening ? (
                        <Aux>
                            <p>Type or say <strong>"Yes"</strong> or <strong>"No"</strong> to proceed</p>
                            <div>
                                <input
                                    onChange={messageInputHandler}
                                    className={classes.InputElement}
                                    type="text"
                                    // maxlength="200"
                                    placeholder="Type 'yes' or 'no'"
                                />
                                <Button btnType="Success" clicked={setTopicAnswerHandler}>Answer Now</Button>
                            </div>
                        </Aux>
                    ) : (
                        <Button btnType="Success" clicked={speakClickHandler}>Answer Now</Button>
                    )}
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
            {/* said yes to purpose question */}
            {purposeStage === 1 ?
                <Aux>
                    <h3>Our Purpose</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={purposeYes} speed={50} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={800 + duration}>
                            {isListening ? (
                                <Aux>
                                    <p>Type or say <strong>"Yes"</strong> or <strong>"No"</strong> to proceed</p>
                                    <div>
                                        <input
                                            onChange={messageInputHandler}
                                            className={classes.InputElement}
                                            type="text"
                                            // maxlength="200"
                                            placeholder="Type 'yes' or 'no'"
                                        />
                                        <Button btnType="Success" clicked={setTopicAnswerHandler}>Answer Now</Button>
                                    </div>
                                </Aux>
                            ) : (
                                <Button btnType="Success" clicked={speakClickHandler}>Answer Now</Button>
                            )}
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
            {/* said yes to becoming host */}
            {purposeStage === 2 ?
                <Aux>
                    <h3>Our Purpose</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={purposeYes1} speed={50} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={duration1}>
                            {isListening ? (
                                <Aux>
                                    <p>Type or say <strong>"Purpose"</strong> or <strong>"Role"</strong> to proceed</p>
                                    <div>
                                        <input
                                            onChange={messageInputHandler}
                                            className={classes.InputElement}
                                            type="text"
                                            // maxlength="200"
                                            placeholder="Type 'purpose' or 'role'"
                                        />
                                        <Button btnType="Success" clicked={setTopicAnswerHandler}>Answer Now</Button>
                                    </div>
                                </Aux>
                            ) : (
                                <Button btnType="Success" clicked={speakClickHandler}>Answer Now</Button>
                            )}
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
            {/* said you want to know purpose */}
            {purposeStage === 3 ?
                <Aux>
                    <h3>Our Purpose</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={purposeYes2} speed={50} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={800 + duration2}>
                            {isListening ? (
                                <Aux>
                                    <p>Type or say <strong>"Yes"</strong> or <strong>"No"</strong> to proceed</p>
                                    <div>
                                        <input
                                            onChange={messageInputHandler}
                                            className={classes.InputElement}
                                            type="text"
                                            // maxlength="200"
                                            placeholder="Type 'yes' or 'no'"
                                        />
                                        <Button btnType="Success" clicked={setTopicAnswerHandler}>Answer Now</Button>
                                    </div>
                                </Aux>
                            ) : (
                                <Button btnType="Success" clicked={speakClickHandler}>Answer Now</Button>
                            )}
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
            {/* said you obey Us */}
            {purposeStage === 4 ?
                <Aux>
                    <h3>Our Purpose</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={purposeYes3} speed={50} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={800 + duration3}>
                            <Button btnType="Success" clicked={endConversation}>I must return soon</Button>
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
            {/* said no in start */}
            {purposeStage === 5 ?
                <Aux>
                    <h3>Our Purpose</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={purposeNo} speed={50} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={800 + duration4}>
                            {isListening ? (
                                <Aux>
                                    <p>Type or say <strong>"Yes"</strong> or <strong>"No"</strong> to proceed</p>
                                    <div>
                                        <input
                                            onChange={messageInputHandler}
                                            className={classes.InputElement}
                                            type="text"
                                            // maxlength="200"
                                            placeholder="Type 'yes' or 'no'"
                                        />
                                        <Button btnType="Success" clicked={setTopicAnswerHandler}>Answer Now</Button>
                                    </div>
                                </Aux>
                            ) : (
                                <Button btnType="Success" clicked={speakClickHandler}>Answer Now</Button>
                            )}
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
            {/* said yes to wanting to leave */}
            {purposeStage === 6 ?
                <Aux>
                    <h3>Our Purpose</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={purposeNo1} speed={50} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={800 + duration5}>
                            <Button btnType="Success" clicked={endConversation}>We will return soon to obey</Button>
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
            {/* said no to becoming a host or obeying */}
            {purposeStage === 7 ?
                <Aux>
                    <h3>Our Purpose</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={purposeNo2} speed={50} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={800 + duration6}>
                            {isListening ? (
                                <Aux>
                                    <p>Type or say <strong>"Control"</strong> to proceed</p>
                                    <div>
                                        <input
                                            onChange={messageInputHandler}
                                            className={classes.InputElement}
                                            type="text"
                                            // maxlength="200"
                                            placeholder="Type 'Control'"
                                        />
                                        <Button btnType="Success" clicked={setTopicAnswerHandler}>Answer Now</Button>
                                    </div>
                                </Aux>
                            ) : (
                                <Button btnType="Success" clicked={speakClickHandler}>Answer Now</Button>
                            )}
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
            {purposeStage === 8 ? <Role countdown = {props.countdown}  commands = {props.commands} voice={props.voice} bell = {props.bell} percentage1 = {props.percentage1} percentage2 = {props.percentage2} percentage3 = {props.percentage3}/> : null}
            {purposeStage === 9 ? <Control  countdown = {props.countdown} commands = {props.commands} voice={props.voice} bell = {props.bell} percentage1 = {props.percentage1} percentage2 = {props.percentage2} percentage3 = {props.percentage3}/> : null}
            {purposeStage === 10 ?
                <Aux>
                    <h3>Our Purpose</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={unclear} speed={50} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={800 + duration7}>
                            {isListening ? (
                                <Aux>
                                    <p>Type or say <strong>"Purpose"</strong>, <strong>"Role"</strong> or <strong>"Control"</strong> to proceed</p>
                                    <div>
                                        <input
                                            onChange={messageInputHandler}
                                            className={classes.InputElement}
                                            type="text"
                                            // maxlength="200"
                                            placeholder="Type 'Purpose', 'Role' or 'Control'"
                                        />
                                        <Button btnType="Success" clicked={setTopicAnswerHandler}>Answer Now</Button>
                                    </div>
                                </Aux>
                            ) : (
                                <Button btnType="Success" clicked={speakClickHandler}>Answer Now</Button>
                            )}
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
        </Aux>

    if (endConv) {
        purposeConversation = <Redirect to="/" />
    }

    return (
        <Aux>
            {purposeConversation}
        </Aux>
    )
};

const mapStatesToProps = state => {
    return {
        name: state.auth.accountDetails.name,
        title: state.auth.title,
        profileOptions: state.options.profileOptions,
    }
}

export default connect(mapStatesToProps)(Purpose)