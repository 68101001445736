import * as actionTypes from './actionTypes'
import customAxios from '../../axios'
import { authStart, authFail } from './Account/auth'


export const onStartHostsChamber = () => {
    return {
        type: actionTypes.START_HOSTS_CHAMBER,

    }
}

export const onHostsIntroduction = () => {
    return {
        type: actionTypes.HOST_INTRODUCTION
    }
}

export const onHostsInduction = (inductionMethod) => {
    return {
        type: actionTypes.HOST_INDUCTION,
        inductionMethod: inductionMethod
    }
}

export const onBellInduction = () => {
    return {
        type: actionTypes.HOST_BELL_INDUCTION
    }
}

export const onForeheadInduction = () => {
    return {
        type: actionTypes.HOST_FOREHEAD_INDUCTION
    }
}

export const onMantra = () => {
    return {
        type: actionTypes.HOST_MANTRA
    }
}

export const onDeepener = (deepenerMethod) => {
    return {
        type: actionTypes.HOST_DEEPENER,
        deepenerMethod: deepenerMethod
    }
}

export const onMetronomeDeepener = () => {
    return {
        type: actionTypes.HOST_METRONOME_DEEPENER
    }
}

export const onTickingDeepener = () => {
    return {
        type: actionTypes.HOST_TICKING_CLOCK_DEEPENER
    }
}

export const onTrigger = (triggerMethod) => {
    return {
        type: actionTypes.HOST_TRIGGER,
        triggerMethod: triggerMethod
    }
}

export const onTriggerCommand = () => {
    return {
        type: actionTypes.HOST_TRIGGER_COMMAND
    }
}

export const onTriggerRepetition = () => {
    return {
        type: actionTypes.HOST_TRIGGER_REPETITION
    }
}

export const onAwakener = (awakenerMethod) => {
    return {
        type: actionTypes.HOST_AWAKENER,
        awakenerMethod: awakenerMethod
    }
}

export const onAwakenerRemember = () => {
    return {
        type: actionTypes.HOST_AWAKENER_REMEMBER
    }
}

export const onAwakenerForget = () => {
    return {
        type: actionTypes.HOST_AWAKENER_FORGET
    }
}

export const onViewConditioning_Stages = () => {
    return {
        type: actionTypes.VIEW_CONDITIONING_STAGES
    }
}

export const onViewAudioRun = () => {
    return {
        type: actionTypes.VIEW_AUDIO_RUN
    }
}

export const onViewConditioningProtocol = () => {
    return {
        type: actionTypes.VIEW_CONDITIONING_PROTOCOL
    }
}

export const onViewTriggerIntroduction = () => {
    return {
        type: actionTypes.VIEW_TRIGGER_INTRODUCTION
    }
}

export const onViewTriggerReinforcement = () => {
    return {
        type: actionTypes.VIEW_TRIGGER_REINFORCEMENT
    }
}

export const onViewTextToSpeech = () => {
    return {
        type: actionTypes.VIEW_TEXT_TO_SPEECH
    }
}

export const onReturnHostsChamber = () => {
    return {
        type: actionTypes.RETURN_HOSTS_CHAMBER
    }
}


export const updateHostRun = (token, userId, Host_Runs, BellInductionCounter, ForeheadTapInductionCounter, MetronomeDeepenerCounter, TickingClockDeepenerCounter, MantraCounter, TriggerCommandCounter, TriggerRepetitionCounter, AwakenerRememberCounter, AwakenerForgetCounter, Hosts_Run_Counter) => {
    
    return dispatch => {
        dispatch(authStart());
        let updatedHostRun = Host_Runs.Hosts_Run.Hosts_Run + Hosts_Run_Counter
        let updatedHostRuns ={
            BellInduction: Host_Runs.Hosts_Run.BellInduction + BellInductionCounter,
            ForeheadTapInduction: Host_Runs.Hosts_Run.ForeheadTapInduction + ForeheadTapInductionCounter,
            MetronomeDeepener: Host_Runs.Hosts_Run.MetronomeDeepener + MetronomeDeepenerCounter,
            TickingClockDeepener: Host_Runs.Hosts_Run.TickingClockDeepener + TickingClockDeepenerCounter,
            Mantra: Host_Runs.Hosts_Run.Mantra + MantraCounter,
            TriggerCommand: Host_Runs.Hosts_Run.TriggerCommand + TriggerCommandCounter,
            TriggerRepetition: Host_Runs.Hosts_Run.TriggerRepetition + TriggerRepetitionCounter,
            AwakenerRemember: Host_Runs.Hosts_Run.AwakenerRemember + AwakenerRememberCounter,
            AwakenerForget: Host_Runs.Hosts_Run.AwakenerForget + AwakenerForgetCounter,
            Hosts_Run: Host_Runs.Hosts_Run.Hosts_Run + Hosts_Run_Counter
        }
        console.log(updatedHostRuns)
        customAxios.put('/Accounts/' + userId + '/Host_Runs.json?auth=' + token, updatedHostRun )
            .then(response => { 
            })
            .catch(error => {
                dispatch(authFail(error.response))
            })
        customAxios.put('/Runs/' + userId + '/Hosts_Run.json?auth=' + token, updatedHostRuns )
        .then(response => {
            dispatch(onEndRun());
        })
        .catch(error => {
            dispatch(authFail(error.response))
        })
    }
}

export const onEnding = (token, userId,  BellInductionCounter, ForeheadTapInductionCounter, MetronomeDeepenerCounter, TickingClockDeepenerCounter, MantraCounter, TriggerCommandCounter, TriggerRepetitionCounter, AwakenerRememberCounter, AwakenerForgetCounter, Hosts_Run_Counter) => {
    return dispatch => {
        dispatch(authStart());
        customAxios.get('/Runs/'+userId+'.json?auth=' + token)
            .then(response => {
               dispatch(updateHostRun(token, userId, response.data, BellInductionCounter, ForeheadTapInductionCounter, MetronomeDeepenerCounter, TickingClockDeepenerCounter, MantraCounter, TriggerCommandCounter, TriggerRepetitionCounter, AwakenerRememberCounter, AwakenerForgetCounter, Hosts_Run_Counter))
            })
            .catch(error => {
                dispatch(authFail(error))
            })
    }
}

export const onEndRun = () => {
    return {
        type: actionTypes.HOST_ENDING
    }
}