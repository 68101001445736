import React from 'react';
import classes from './NavigationItems.module.css';
import NavigationItem from './NavigationItem/NavigationItem';



const navigationItems = (props) => (    
    <ul className = {classes.NavigationItems}>
        <NavigationItem link = '/'>The Hypno Virus</NavigationItem>
        {props.isAuthenticated ?  <NavigationItem link = '/TheMindMeltingExperience'>The Mind Melting Experience</NavigationItem>: null }
        <NavigationItem link = '/FreeChamber'>Free Chamber</NavigationItem>
        {props.isAuthenticated ? <NavigationItem link = '/HostsChamber'>Hosts Chamber</NavigationItem> : null }
        <NavigationItem link = '/Account'>{!props.isAuthenticated  ? 'Sign In' : 'Account'}</NavigationItem> 
             
    </ul>
);


export default navigationItems