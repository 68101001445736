import React, { Component } from 'react'
// import Input from '../../../components/UI/Input/Input'
import Button from '../../../components/UI/Button/Button'
import classes from '../Account.module.css'
import Aux from '../../../hoc/auxiliary/auxiliary';
import * as actions from '../../../store/actions/index';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { connect } from 'react-redux'
import ObedienceStage1 from '../../../components/Account/Runs/ConditioningStages/ObedienceModule/ObedienceStage1'
import ObedienceStage2 from '../../../components/Account/Runs/ConditioningStages/ObedienceModule/ObedienceStage2'
import ObedienceStage3 from '../../../components/Account/Runs/ConditioningStages/ObedienceModule/ObedienceStage3'

class Runs extends Component {

    viewDetailRunHandler = (event) => {
        event.preventDefault();
        this.props.onViewRunDetails(this.props.token, this.props.userId)
    }

    viewObedienceStage1RunDetailsHandler = (event) => {
        event.preventDefault();
        this.props.onViewObedienceStage1RunDetails(this.props.token, this.props.userId)
    }

    viewObedienceStage2RunDetailsHandler = (event) => {
        event.preventDefault();
        this.props.onViewObedienceStage2RunDetails(this.props.token, this.props.userId)
    }

    viewObedienceStage3RunDetailsHandler = (event) => {
        event.preventDefault();
        this.props.onViewObedienceStage3RunDetails(this.props.token, this.props.userId)
    }

    viewConditioningProtocol1DetailsHandler = (event) => {
        event.preventDefault();
        this.props.onViewConditioningProtocol1Details(this.props.token, this.props.userId)
    }


    ReturnBackHandler = (event) => {
        event.preventDefault();
        this.props.onReturnBack()
    }

    render() {

        let form = null

        if (this.props.viewRunDetails) {
            form = (<div className={classes.AccountContainer}>
                <form className={classes.ViewProfile}>
                    <h2>Select Run</h2>
                    <div className={classes.AuthButtons}>
                        <Button btnType="Success" clicked={this.viewObedienceStage1RunDetailsHandler}>Obedience Stage 1 <br></br>Details</Button><br></br> 
                        <Button btnType="Success" clicked={this.viewObedienceStage2RunDetailsHandler}>Obedience Stage 2 <br></br>Details</Button><br></br>
                        <Button btnType="Success" clicked={this.viewObedienceStage3RunDetailsHandler}>Obedience Stage 3 <br></br>Details</Button><br></br>
                        <Button btnType="Danger" clicked={this.ReturnBackHandler}>Back</Button>
                    </div>
                </form>
            </div>
            )
        }

        if (this.props.viewObedienceStage1){
            form = 
                <ObedienceStage1 
                    name = {this.props.accountDetails.name}
                    viewObedienceStage1 = {this.props.viewObedienceStage1Details} 
                    ReturnBackHandler = {this.ReturnBackHandler}/>
        }

        if (this.props.viewObedienceStage2){
            form = 
                <ObedienceStage2 
                    name = {this.props.accountDetails.name}
                    viewObedienceStage2 = {this.props.viewObedienceStage2Details} 
                    ReturnBackHandler = {this.ReturnBackHandler}/>
        }

        if (this.props.viewObedienceStage3){
            form = 
                <ObedienceStage3 
                    name = {this.props.accountDetails.name}
                    viewObedienceStage3 = {this.props.viewObedienceStage3Details} 
                    ReturnBackHandler = {this.ReturnBackHandler}/>
        }



        if (this.props.loading) {
            form = <Spinner className={classes.Spinner} />
        }

        let errorMessage = null

        if (this.props.error) {
            errorMessage = (
                <p className={classes.error}>{this.props.error}</p>
            )
        }

        return (
            <Aux>
                <div className={classes.Account}>
                    {errorMessage}
                    {form}
                </div>
            </Aux >

        );
    }
}

const mapStatesToProps = state => {
    return {
        loading: state.runs.loading,
        error: state.auth.error,
        token: state.auth.token,
        userId: state.auth.userId,
        accountDetails: state.auth.accountDetails,
        authRedirectValid: state.auth.authRedirectValid,
        viewDetails: state.runs.viewDetails,
        viewRunDetails: state.runs.viewRunDetails,
        viewObedienceStage1: state.runs.viewObedienceStage1RunDetails,
        viewObedienceStage1Details: state.runs.viewObedienceStage1RunDetailsArray,
        viewObedienceStage2: state.runs.viewObedienceStage2RunDetails,
        viewObedienceStage2Details: state.runs.viewObedienceStage2RunDetailsArray,
        viewObedienceStage3: state.runs.viewObedienceStage3RunDetails,
        viewObedienceStage3Details: state.runs.viewObedienceStage3RunDetailsArray,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onViewRunDetails: () => dispatch(actions.viewRunDetails()),
        onViewObedienceStage1RunDetails: (token, userId) => dispatch(actions.viewObedienceStage1RunDetails(token, userId)),
        onViewObedienceStage2RunDetails: (token, userId) => dispatch(actions.viewObedienceStage2RunDetails(token, userId)),
        onViewObedienceStage3RunDetails: (token, userId) => dispatch(actions.viewObedienceStage3RunDetails(token, userId)),

        onReturnBack: () => dispatch(actions.returnBackSuccess()),
        onLogout: () => dispatch(actions.logout())
    }
}

export default connect(mapStatesToProps, mapDispatchToProps)(Runs);