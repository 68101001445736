import * as actionTypes from './actionType'

export const hypnosisShowStart = () => {
    return {
        type: actionTypes.START_THE_SHOW
    }
}

export const hypnosisShowEnd = () => {
    return {
        type: actionTypes.END_THE_SHOW
    }
}