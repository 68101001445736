import * as actionTypes from '../../actions/Account/authTypes';
import { updateObject } from '../../../shared/utility';

const initialState = {
    accountDetails: [],
    title: null,
    token: null,
    userId: null,
    email: null,
    password: null,
    error: null,
    isVerified: false,
    PasswordSubmitted: false,
    loading: false,
    authRedirectValid: false,
    profileActive: false,
    authRedirectPath: '/'
}

const passwordStart = (state, action) => {
    return updateObject(state, { error: null, loading: true })

}

const passwordSuccess = (state, action) => {
    return updateObject(state, {
        password: action.password,
        error: null,
        PasswordSubmitted: true,
        loading: false
    })

}

const passwordFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
    })

}

const authStart = (state, action) => {
    return updateObject(state, { error: null, loading: true })

}

export const authSuccess = (state, action) => {
    return updateObject(state, {
        profileActive:true,
        token: action.token,
        userId: action.userId,
        email: action.email,
        password: action.password,
        isVerified: false,
        error: null,
        loading: false
    })

}

const verifyEmailMessage  = (state, action) => {
    return updateObject(state, {
        PasswordSubmitted: true,
        error: null,
        loading: false
    })

}

const authFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    })

} 

const authLogout = (state, action) => {
    return updateObject(state, { 
        token: null, 
        userId: null,
        error: null,
        PasswordSubmitted: false,
        loading: false,
        authRedirectValid: true 
    })
}

const resetLogout = (state, action) => {
    return updateObject(state, { 
        authRedirectValid: false 
    })
}

const createAccountSuccess = (state, action) => {
    return updateObject(state, {
        accountDetails: action.accountDetail,
        loading: false
    })
}

const deleteAccountSuccess = (state, action) => {
    return updateObject(state, {
        PasswordSubmitted: false,
        loading: false,
        authRedirectValid: true,
        error: null,
        token: null,
        userId: null
    })
}

const changePasswordSuccess = (state, action) => {
    return updateObject(state, {
        token: action.token,
        password: action.password,
        loading: false
    })
}

const changeEmailSuccess = (state, action) => {
    return updateObject(state, {
        token: action.token,
        email: action.email,
        loading: false
    })
}

const fetchProfile = (state, action) => {  
    
    let notdefined = void(0)
    let registerCheck = Object.entries(action.accountDetails)
    if(registerCheck.length === 0 || action.accountDetails === notdefined){  
        return updateObject(state, {
            profileActive: true,
            loading: false
        })
    } else{
        return updateObject(state, {
            title: action.title,
            accountDetails: action.accountDetails,
            profileActive: false,
            loading: false
        })
    }
    
}

const editProfile = (state, action) => {
    return updateObject(state, {
        accountDetails: action.accountDetails,
    })
}

const viewHostRunDetailSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
    })

}

const viewObedienceStage1RunDetails = (state, action) => {
    return updateObject(state, {
        loading: false,
    })

}

const viewObedienceStage2RunDetails = (state, action) => {
    return updateObject(state, {
        loading: false,
    })

}

const viewObedienceStage3RunDetails = (state, action) => {
    return updateObject(state, {
        loading: false,
    })

}






const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PASSWROD_START: return passwordStart(state, action)
        case actionTypes.PASSWORD_SUCCESS: return passwordSuccess(state, action)
        case actionTypes.PASSWORD_FAILED: return passwordFail(state, action)
        case actionTypes.AUTH_START: return authStart(state, action)
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action)
        case actionTypes.AUTH_FAIL: return authFail(state, action)
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action)
        case actionTypes.EDIT_PROFILE_SUCCESS: return editProfile(state, action)
        case actionTypes.VERIFY_EMAIL_STATUS: return verifyEmailMessage(state, action)
        case actionTypes.CREATE_ACCOUNT_SUCCESS: return createAccountSuccess(state, action)
        case actionTypes.DELETE_ACCOUNT_SUCCESS: return deleteAccountSuccess(state, action)
        case actionTypes.CHANGE_PASSWORD_SUCCESS: return changePasswordSuccess(state, action)
        case actionTypes.CHNAGE_EMAIL_SUCCESS: return changeEmailSuccess(state, action)
        case actionTypes.FETCH_ACCOUNT_DETAILS: return fetchProfile(state, action)
        case actionTypes.RESET_LOGOUT: return resetLogout(state, action)
        case actionTypes.VIEW_HOSTS_RUN_DETAIL: return viewHostRunDetailSuccess(state, action)
        case actionTypes.VIEW_OBEDIENCE_STAGE_1_RUN_DETAIL: return viewObedienceStage1RunDetails(state, action)
        case actionTypes.VIEW_OBEDIENCE_STAGE_2_RUN_DETAIL: return viewObedienceStage2RunDetails(state, action)
        case actionTypes.VIEW_OBEDIENCE_STAGE_3_RUN_DETAIL: return viewObedienceStage3RunDetails(state, action)
        default:
            return state

    }
}


export default reducer