import React, { useState } from "react";
import TextTransition from "react-text-transition";
import TypingEffect from 'react-typing-effect'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import Button from '../../../../UI/Button/Button'
import classes from '../../../../../containers/HostsChamber/AudioRuns/AudioRuns.module.css'
import * as scripts from '../../../../../shared/AudioRuns/TriggerIntroduction/TentaclesIntroduction/TentaclesIntroductionScripts'
import * as Delays from '../../../../../shared/AudioRuns/TriggerIntroduction/TentaclesIntroduction/TentaclesIntroductionDelay'
import Resist from '../../../../../assets/audio_runs/Trigger_Introduction/Tentacle_Introduction/Tentacle_Resist_Opening.mp3'
import Sound from 'react-sound'
import Delay from "react-delay";
import useInterval from 'react-useinterval';
import TentacleResistActivate from './Tentacles_Resist_Activate'

const DELAY_TEXT = [4000]


function Trigger_Resist_Opening() {

  let [count, setCount] = useState(0);
  let [tentacle_resist, setProtocol] = useState(true)
  let [tentacle_activate, setObeySequence] = useState(false)


  const increaseCount = () => {
    if (tentacle_resist && count < scripts.TENTACLES_RESIST_SCRIPT_OPENNING.length) {
      setCount(count + 1);
    }
  };

  useInterval(increaseCount, Delays.TENTACLES_RESIST_OPENING_DELAY_TIME[count]);

  const enterActivateHandler = () => {
    setProtocol(tentacle_resist = false)
    setObeySequence(tentacle_activate = true)

  }


  let tentacles_resist_opening_form = null

  if (tentacle_resist) {
    tentacles_resist_opening_form = (
      <Aux>
        {count < scripts.TENTACLES_RESIST_SCRIPT_OPENNING.length ?
          <Aux >
            <Sound url={Resist} playStatus={Sound.status.PLAYING} />
            <Delay wait={DELAY_TEXT[count]}>
              <TextTransition
                text={<TypingEffect typingDelay={100} text={scripts.TENTACLES_RESIST_SCRIPT_OPENNING[count]} speed={90} eraseDelay={1000000} />}
                Overflow
              />
            </Delay>
          </Aux>
          :
          <div className={classes.ButtonPadding}>
            <Button btnType="Success" clicked={enterActivateHandler}>I must... Obey</Button>
          </div>
        } 
      </Aux >
    )
  }

  if (tentacle_activate){
    tentacles_resist_opening_form = <TentacleResistActivate />
  }

  return (
    <Aux>
      {tentacles_resist_opening_form}
    </Aux>
  )

}

export default Trigger_Resist_Opening