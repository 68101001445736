import React, { useState } from "react";
import TextTransition from "react-text-transition";
import TypingEffect from 'react-typing-effect'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import Button from '../../../../UI/Button/Button'
import classes from '../../../../../containers/HostsChamber/AudioRuns/AudioRuns.module.css'
import * as scripts from '../../../../../shared/AudioRuns/ConditioningProtocols/ConditioningProtocol#1/ConditioningProtocol1Scripts'
import * as Delays from '../../../../../shared/AudioRuns/ConditioningProtocols/ConditioningProtocol#1/ConditioningProtocol#1Delay'
import { connect } from 'react-redux';
import ConditioningEnd from './Conditioning_Protocol_Resist_End'
import { ConditioningProtocol1End } from '../../../../../store/actions/AudioRuns/audioRunsIndex'
import Sound from 'react-sound'
import ResistDeny1 from '../../../../../assets/audio_runs/conditioning_protocols/conditioning_protocol_1/conditioning_protocol_resist_deny1.mp3'
import Delay from "react-delay";
import useInterval from 'react-useinterval';

const DELAY_DENY1 = [4500]

function Conditioning_Protocol_Resist({ userId, token, conditioningProtocol1Complete, ConditioningProtocol1End }) {

    
    let [conditioning_resistDeny1TextIndex, countConditioning_resistDeny1TextIndex] = useState(0)
    let [conditioning_deny1, setConditioning_deny1] = useState(true)
    let [conditioning_end, setConditioning_end] = useState(false)

    const increaseResistDeny1Count = () => {
        if (conditioning_deny1 && conditioning_resistDeny1TextIndex < scripts.CONDITIONING_PROTOCOL_RESIST_DENY1.length) {
            countConditioning_resistDeny1TextIndex(conditioning_resistDeny1TextIndex + 1)

        }
    }
    
    
    useInterval(increaseResistDeny1Count, Delays.DELAY_TIME_DENY1[conditioning_resistDeny1TextIndex]);
    
    const enterEndHandler = () => {
        setConditioning_deny1(conditioning_deny1 = false)
        setConditioning_end(conditioning_end = true)
    }


    let conditioning_protocol_resist_form = null

    if (conditioning_deny1) {
        conditioning_protocol_resist_form = (
            <Aux>
                {conditioning_resistDeny1TextIndex < scripts.CONDITIONING_PROTOCOL_RESIST_DENY1.length ?
                    <Aux >
                        {/* <div className={classes.texting}> */}
                            <Sound url={ResistDeny1} playStatus={Sound.status.PLAYING} />
                            <Delay wait={DELAY_DENY1[conditioning_resistDeny1TextIndex]}>
                                <TextTransition
                                    text={<TypingEffect typingDelay={100} text={scripts.CONDITIONING_PROTOCOL_RESIST_DENY1[conditioning_resistDeny1TextIndex]} speed={90} eraseDelay={1000000} />}
                                    Overflow
                                />
                            </Delay>
                        {/* </div> */}
                    </Aux>
                    :
                    <div className={classes.ButtonPadding}>
                        <Button btnType="Success" clicked={enterEndHandler}>Yes, I am a host for The Hypno Virus</Button>
                    </div>
                } 
            </Aux>
        )
    }

    if (conditioning_end) {
        conditioning_protocol_resist_form = (
            <ConditioningEnd />
        )
    }

    return (
        conditioning_protocol_resist_form
    );
}


const mapStateToProps = state => {
    return {
        hostName: state.auth.accountDetails.name,
        runSpeed: state.auth.accountDetails.speed,
        userId: state.auth.userId,
        token: state.auth.token,
        conditioningProtocol1Complete: state.conditioningProtocol1.conditioningProtocol1Complete
    }
}

const mapDispatchToProps = dispatch => {
    return {
        ConditioningProtocol1End: (userId, token, comply_sequence, resistance_sequence) => dispatch(ConditioningProtocol1End(userId, token, comply_sequence, resistance_sequence)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Conditioning_Protocol_Resist)