import * as conditionTypes from '../../../actions/ConditioningStages/conditioningStagesTypes';
import { updateObject } from '../../../../shared/utility';

const initialState = {
    error: null,
    obedienceStage2Valid: false,
    obedienceTheme: false,
    obedienceStage2Counter: 0,
    host_reinforcement_command: 0,
    host_speech_command: 0,
    host_suit_command: 0,
    host_pleasure_command: 0,
    host_infection_command:0,
    obedienceStage2Complete: false,
    obedienceStage2: [],
    obedienceStage2Commands: [],
    loading: false
}

const ObedienceStageStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true
    })
}

const ObedienceStage2Start = (state, action) => {
    return updateObject(state, {
        obedienceStage2Valid: true,
        loading: false,
    })
}

const ObedienceStage2Success = (state, action) => {
    if(action.obedience_stage2.obedience_stage2 > 4){
        return updateObject(state, {
            obedienceStage2: action.obedience_stage2,
            obedienceStage2Commands: action.obedienceStage2Commands, 
            obedienceStage2Complete: false,
            obedienceTheme: true,
            loading: false,
            error: null
        })
    }
    else if (action.obedience_stage2.obedience_stage2 <= 4){
        return updateObject(state, {
            obedienceStage2: action.obedience_stage2,
            obedienceStage2Commands: action.obedienceStage2Commands,
            obedienceStage2Complete: false,
            obedienceTheme: true,
            loading: false,
            error: null
        })
    }
}
    



const ObedienceStageFailed = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    })
}

const obedienceStage2Counter = (state, action) => {
    let updatedCommandNumber = 1
    if (action.commandNumber === 0){
        state.host_reinforcement_command = state.host_reinforcement_command + updatedCommandNumber
    } else if (action.commandNumber === 1){
        state.host_speech_command = state.host_speech_command + updatedCommandNumber
    } else if (action.commandNumber === 2){
        state.host_suit_command = state.host_suit_command + updatedCommandNumber
    } else if (action.commandNumber === 3){
        state.host_pleasure_command = state.host_pleasure_command + updatedCommandNumber
    } else if (action.commandNumber === 4){
        state.host_infection_command = state.host_infection_command + updatedCommandNumber
    }
    
    return updateObject(state, {
        obedienceStage2Counter: action.obedienceStage2Counter
    })
}

const ObedienceStage2Complete = (state, action) => {
    return updateObject(state, {
        error: null,
        obedienceStage2Valid: false,
        obedienceStage2Counter: 0,
        host_reinforcement_command: 0,
        host_speech_command: 0,
        host_suit_command: 0,
        host_pleasure_command: 0,
        host_infection_command:0,
        obedienceTheme: false,
        obedienceStage2Complete: true,
        obedienceStage2: [],
        obedienceStage2Commands: [],
        loading: false
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case conditionTypes.OBEDIENCE_STAGE_START: return ObedienceStageStart(state, action)
        case conditionTypes.OBEDIENCE_STAGE_2_START: return ObedienceStage2Start(state, action)
        case conditionTypes.OBEDIENCE_STAGE_2_SUCCESS: return ObedienceStage2Success(state, action)
        case conditionTypes.OBEDIENCE_STAGE_FAILED: return ObedienceStageFailed(state, action)
        case conditionTypes.OBEDIENCE_STAGE_2_COUNTER: return obedienceStage2Counter(state, action)
        case conditionTypes.OBEDIENCE_STAGE_2_COMPLETED: return ObedienceStage2Complete(state, action)
        default:
            return state

    }
}


export default reducer