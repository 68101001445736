import React, { useState } from "react";
import TextTransition from "react-text-transition";
import TypingEffect from 'react-typing-effect'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import Button from '../../../../UI/Button/Button'
import classes from '../../../../../containers/HostsChamber/AudioRuns/AudioRuns.module.css'
import { MotorFunctionControlEnd } from '../../../../../store/actions/AudioRuns/audioRunsIndex'
import * as scripts from '../../../../../shared/AudioRuns/TriggerIntroduction/MotorFunctionControlIntroduction/MotorFunctionControlIntroductionScripts'
import * as Delays from '../../../../../shared/AudioRuns/TriggerIntroduction/MotorFunctionControlIntroduction/MotorFunctionControlIntroductionDelay'
import MentalControl from '../../../../../assets/audio_runs/Trigger_Introduction/Motor_Function_Control_Introduction/Mental_Control.mp3'
import { connect } from 'react-redux'
import Sound from 'react-sound'
import Delay from "react-delay";
import useInterval from 'react-useinterval';

const DELAY_TEXT = [3800]

function Mental_Control({ userId, token, instant_control, onMotorFunctionControlEnd }) {

    let [count, setCount] = useState(0);
    let [mental_control, setProtocol] = useState(true)

    const increaseCount = () => {
        if (mental_control && count < scripts.MENTAL_CONTROL_SCRIPT.length) {
            setCount(count + 1);
        }
    };

    useInterval(increaseCount, Delays.MENTAL_CONTROL_DELAY[count]);


    const exitMentalControlSequenceHandler = () => {
        setProtocol(mental_control = false)
        if(instant_control){
            onMotorFunctionControlEnd(userId, token, 1, 0, 1)
        }else{

        }onMotorFunctionControlEnd(userId, token, 0, 1, 1)
        
    }


    let mental_control_form = null

    if (mental_control) {
        mental_control_form = (
            <Aux>
                {count < scripts.MENTAL_CONTROL_SCRIPT.length ?
                    <Aux >
                        <Sound url={MentalControl} playStatus={Sound.status.PLAYING} />
                        <Delay wait={DELAY_TEXT[count]}>
                            <TextTransition
                                text={<TypingEffect typingDelay={100} text={scripts.MENTAL_CONTROL_SCRIPT[count]} speed={50} eraseDelay={1000000} />}
                                Overflow
                            />
                        </Delay>
                    </Aux>
                    :
                    <div className={classes.ButtonPadding}>
                        <Button btnType="Success" clicked={exitMentalControlSequenceHandler}>Thank you for controlling <br></br>my mind & body</Button>
                    </div>
                }
            </Aux >
        )
    }



    return (
        <Aux>
            {mental_control_form}
        </Aux>
    )

}

const mapStateToProps = state => {
    return {
        userId: state.auth.userId,
        token: state.auth.token,
        instant_control: state.motorFunctionIntro.instant_control,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onMotorFunctionControlEnd: (userId, token, instant_control, slow_control, mental_control) => dispatch(MotorFunctionControlEnd(userId, token, instant_control, slow_control, mental_control))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Mental_Control)