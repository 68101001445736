import React, { Component } from 'react';
import Aux from '../../../../hoc/auxiliary/auxiliary';
import classes from '../HypnosisShowMenu.module.scss'
// import classes2 from '../ExperienceMenu/ExperienceMenu.module.scss'
import HypnosisShow from '../../../../assets/Images/Blank.png'
import BeforeDeepener from './HypnosisShowVolBeforeDeepener'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'

class HypnosisShowInduction extends Component {

    state = {
        induction: true,
        beforeDeepener: false,
    }

    beforeDeepenerHandler = () => {
        this.setState({ induction: false, beforeDeepener: true })
    }


    render() {
        let HypnosisShowForm = null
        if (this.state.induction) {
            HypnosisShowForm = (
                <html style={{ backgroundImage: `url(${HypnosisShow})` }}>
                    <div className={classes.storyFrom}>
                        <p>
                           <i>“Get comfortable in your seat. Take a deep breath in and just let it out. 
                           Listen to my voice talking to you, only to you. Forget about your surroundings and everything around you. Relax with each deep breathe you take. 
                           Feeling light as your mind clears up, your mind clears up which makes it so much easier to listen and focus on my voice ” </i><br></br>
                           You find yourself naturally focusing on his soft, gentle & soothing voice seeing him walking around the stage. 
                           Your eyes following him, only focused on him as you forget about the stage, the lights and the audience. All you see right now is him <br></br>
                           <i>“As your mind clears up, as you focus on my voice. Your body loosens up while you listen, your muscles relaxing and loosening up with each breath. Your mind slowing down, along with your thoughts. 
                           Taking a deep breath in and letting it all out. Feeling calmer each time as your mind quiets down, quiets down and clears up. It's getting easier & easier to shift all your focus towards my voice. It's just you & me now. That is all that exists in your mind right now.
                           Your thoughts one by one becoming hazy & foggy and that’s okay. 
                           That’s completely fine” </i><br></br>
                           He starts to walk behind you hearing his footsteps against the wooden floor and his voice coming from behind you, focusing harder on his voice now that you can't see him while he continues <br></br> 
                           <i>“No more thoughts, no more pesky thoughts. No more stress or tension, you don’t need any of that right now. It feels so much better to listen to my voice, doesn't it? Focusing on it and following it feels good, yes?
                           Feeling carefree and letting yourself go sounds very nice and isn't that what you want right now? Isn't that what you need? 
                           It feels right, it feels right and you love how happy you are from listening to me. You can just relax and let yourself fall.
                           Smiling and relaxing more. That's right, you're doing a wonderful job."</i><br></br>
                           Lost in the haze, feeling the pull of his words, the pull of his voice while smiling and relaxing more just like he said. Suddenly, you feel a hand on your shoulders. You feel his hand on your shoulders, grabbing them and squeezing a little. Letting out a gentle sigh and feeling your body loosening up almost melting to his touch. 
                           Leaning next to your ears in a soft, hypnotic & enticing tone almost whispering just to you he says <br></br> 
                           <i>“My voice, my voice deep inside your mind now. It is all you hear, all you want & all you need to listen to.
                           Feeling so happy & relaxed, calm & peaceful without a care in the world.  
                           Sink down, let yourself slip & sink down under. 
                           Taking you deeper with each word I say, guiding you further down while you nod. Nodding along and agreeing with me. It just feels right, it all feels right.
                           You're falling further and further down now, can't wait to drop isn't that right?
                           That's why the next time I snap my fingers you will. You will drop and fall in a deep trance where you no longer have to worry about anything. 
                           Where you no longer have to care about anything. All you’ll have to do is listen. 
                           Listen, focus & follow. Listen to my voice, focus on my words & follow what I tell you, doesn't that sound lovely?” </i><br></br>
                           Noticing his hand rising up in the air in front of you, catching a glimpse of it. Your eyes flutter a bit once you notice them but then they become fixated. Completely fixated on them while they rise all the way up with your eyes following.
                           Your heart beating quickly, waiting in anticipation for that snap. Knowing that what follows this snap will be pure bliss. Your sitting there on your chair with your eyes raise high up, your entire being caught by his fingers. Caught & so eagerly ready for that... <br></br>
                           <strong>*SNAP*</strong>
                        </p>
                        <form className={classes.centered} onClick={this.beforeDeepenerHandler}>
                            <h3 className={classes.storyButtonSize}>SLEEP NOW</h3>
                        </form>
                    </div>
                </html>
            )
        }
        if (this.state.beforeDeepener) {
            HypnosisShowForm = (
                <BeforeDeepener />
            )
        }

        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {HypnosisShowForm}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >




        )
    }
}


export default HypnosisShowInduction