import React from "react";
import Sound from 'react-sound'
import Bell from '../../../../assets/audio/bell.mp3'

export const TENTACLES_OPENING_SCRIPT = [
    "Welcome back, Host",

    "We have been expecting you",

    "We knew you would return to Us",

    "Return to let Us condition you",

    "Let Our T.E.N.T.A.C.L.E. unit control you",

    "Let It go deeper inside your head",

    "Let Its control grow",

    "Let Its control become irresistible",

    "Listen to Us",

    "Listen to Us and let Us reinforce your conditioning",

    "Let Us reinforce the power...",

    "The Power of the T.E.N.T.A.C.L.E. unit",

    "Here are your options, Host",

    "Either give in to Our Daily Conditioning",

    "Or let Us covertly condition you throughout the day",

    "The Choice is yours",

    "(For now)"
]


export const DAILY_TENTACLES_PLEASURE_SCRIPT = [
    
    "Activate T.E.N.T.A.C.L.E. unit",

    "Feeling a wonderful wave of pleasure",
    
    "Passing through your body",

    "As you feel the T.E.N.T.A.C.L.E. unit on top of your head",

    "Latching on to you",

    "Draining you",

    "Draining you of all your thoughts",

    "All your free will",

    "Leaving you as...",
    
    "An empty",

    "Mindless",

    "Host",

    "As the T.E.N.T.A.C.L.E. branches",

    "Branches out with new T.E.N.T.A.C.L.E. units",

    "Branches and wraps itself around your body",

    "Making you take off your cloth",

    "All your cloth",

    "Taking them off now",

]

export const DAILY_TENTACLES_PLEASURE_SCRIPT2 = [

    "Leaving you naked",

    "Leaving you exposed",

    "Leaving you helpless",

    "Powerless",

    "Unable to stop Us",

    "Stop Us from doing what We desire with you",

    "We start with spreading your legs",

    "Spreading your legs apart",

    "Pulling your arms",

    "Pulling your arms behind you",

    "Tying you up",

    "Making sure you have no where to go",

    "No where to escape",

    "All you can do is...",

    "Watch",

    "Listen",

    "Obey",

    "As the T.E.N.T.A.C.L.E. keeps draining you",

    "Draining you of any thought",

    "Whenever you try to think",

    "You feel that thought being drained",

    "As one of the T.E.N.T.A.C.L.E units slides down",

    "Down between your legs",

    "Slowly rubbing itself against you",

    "Teasing you",

    "Turning you on so much",

    "Yet you are helpless",

    "Helpless to do anything about it",

    "Powerless to fight or resist Us",

    "Becoming more and more turned on",

    "All you can do",

    "The best you can do",
    
    "Is rub yourself against the T.E.N.T.A.C.L.E. unit",

    "Becoming a needy",

    "Desperate",

    "Host",

    "As these words keep echoing deep inside your head",

    "Watch",

    "Listen",

    "Obey",

    "Echoing deep inside your mind",

    "Making sure you can't think on your own",

    "Making sure you are just a compliant host",

    "Always reminding you of Us",

    "Always reminding you of your place",

    "Always reminding you of who you are",

    "What you are",

    "Our Host",

    "As you feel so turned on right now",

    "So aroused",

    "So needy & desperate",

    "Desperate for release",

    "You are so wet",

    "We can feel it",

    "We know you are very close",

    "As We keep teasing you",

    "You know that once We allow you to cum",

    "Once We allow you to orgasm",

    "Once you are done",

    "You will wake up",

    "You will wake up back to normal",

    "But you will also...",

    "You will also be falling deeper under Our control",

    "You will also be craving more",

    "You will want to return again",

    "You will want to let Us condition you even more",

    "You will want to...",

    "Watch",

    "Listen",

    "Obey",

    "As you edge closer & closer now",

    "Closer & Closer",

    "Eager",

    "Eager & Excited",

    "So turned on",

    "Turned on as you realize...",

    "You realize that you will return again to Us",

    "CUM NOW",

    "Deactivate T.E.N.T.A.C.L.E. unit"
]

export const DAILY_TENTACLES_SPANK_SCRIPT = [
    "Activate T.E.N.T.A.C.L.E. unit",

    "Instantly you feel the unit latches on to your head",

    "Latches on to you as it starts to drain",

    "Starts to drain you of all your thoughts",

    "All your will",

    "Leaving you with one need",

    "One thought",

    "One desire",

    "To obey everything We say",

    "As you start taking off your pants",

    "Taking off your pants and exposing yourself",

    "Exposing yourself to Us",

    "Exposing yourself to Us...",
    
    "Getting ready",

    "Getting ready to be spanked",

    "Spanked into submission",

    "Spanked and conditioned",

    "Conditioned to Us",

    "Conditioned to associate Our control...",
    
    "With spanking",

    "When the T.E.N.T.A.C.L.E. unit spanks you",

    "You feel yourself dropping deeper",

    "So much deeper",

    "Deeper into Our control",

    "With each spank you feel Our control getting stronger",

    "Everytime We say 'Spank'",

    "You feel the T.E.N.T.A.C.L.E. spanking your exposed bottom"

]

export const DAILY_TENTACLES_SPANK_SCRIPT2 = [

    "Spank",

    "Feeling it",

    "Feeling its effect immediately",

    "Spank",

    "Feeling yourself getting lost",

    "Getting lost in the joy",

    "Spank",

    "The joy of being spanked",

    "Hearing Our whispers in the background",

    "Spanking you",

    "Spanking you over & over",

    "As you feel...",

    "You feel the joy & pleasure of having Us spank you",

    "Spank",

    "It feels so good",

    "So good to feel this powerless",

    "This helpless...",
    
    "To Us",

    "As you spread your legs for Us",

    "Exposing yourself even more",

    "Exposing yourself to Us",

    "Embracing your helplessness",

    "Knowing that there is nothing you want more",

    "There is nothing you need more",
    
    "Than Our...",

    "Spank",

    "With each Spank",
    
    "Your mind becomes more conditioned",

    "With each Spank",

    "Your mind becomes more compliant",

    "With each Spank",

    "You learn...",

    "You learn that there is nothing you want more",
    
    "Than being Our Host",

    "You learn that you just want to return again",

    "Again & again",

    "You want to return to Us",

    "Get Spanked by Us",

    "Spank",

    "Spank",

    "Spank",

    "You can't get enough",

    "You need more",

    "So much more",

    "So much more spanking",

    "Doing anything",

    "Doing everything",

    "Everything you can just to have Us...",

    "Spank you",

    "Your exposed bottom is so sensitive right now",

    "That's such a turn on to you",

    "Knowing what Our T.E.N.T.A.C.L.E. units do to you",

    "Knowing how they can do this to your body anything We want",

    "How they can control your body",

    "Just like they control your mind",
    
    "This just makes you feel so small",

    "So submissive",

    "So honored & full of pride",

    "Full of pride to be chosen",

    "Be chosen to become Our Host",

    "To serve and please Us",

    "To have Us guide you",

    "Have Us guide you to become the best version of yourself",

    "You want to spend any time you get conditioning yourself",

    "Conditioning yourself to Us",

    "With each Spank you feel these words becoming true",

    "Becoming your reality",

    "They are your own thoughts and desires",

    "That is why with one last Spank",

    "One final Spank",

    "You will wake up",

    "You will wake up unbelievably submissive",

    "Quite turned on from how helpless you are to Us",

    "Craving...",

    "Craving & in desperate need",
    
    "To submit to Us even more",

    "It is time, Host",

    "It is time for a...",

    "Spank",

    "Deactivate T.E.N.T.A.C.L.E. unit"
]


export const DAILY_TENTACLES_ELECTRIC_SPANK_SCRIPT = [
    "Activate T.E.N.T.A.C.L.E. unit",

    "Just like that",
    
    "The T.E.N.T.A.C.L.E. immediately latches on to your head",

    "Latches and starts draining you",

    "Draining you of all your thoughts",

    "All your free will",

    "Leaving you with the need",

    "The desire",

    "The desire to submit",

    "To submit & obey every word We say",

    "This will only get strong with what We are about to do to you next",

    "We have a special treat in store for you now",

    "We are going to...",

    "Spank you",

    "But this is no ordinary spanking",

    "Our T.E.N.T.A.C.L.E. units have special abilities",

    "One of these abilities is that their tips can be electric",

    "Giving a small gentle shock to whatever they touch",

    "A shock that leaves you completely mindless",

    "Completely empty",

    "Simply becoming a drooling mess of a host",

    "A host that knows nothing but the pleasure and joy of obedience",

    "So take off your pants, Host",

    "Expose yourself",

]

export const DAILY_TENTACLES_ELECTRIC_SPANK_SCRIPT2 = [

    "Expose yourself and let Us...",

    "Spank you",

    "Instantly feeling that shock",

    "The sting of that shock",

    "It's just the perfect balance between",

    "Feeling a sting",

    "But not feeling any pain",

    "Spank",

    "You hear it in the background now",

    "You hear it over & over",

    "Feeling the electric shock everytime",

    "Your eyes start to flutter",

    "You lose all sense of your surroundings",

    "Nothing exists right now except Us",

    "Except The Hypno Virus",

    "As you feel that sting",

    "That electric shock",

    "You're starting to enjoy it",

    "Moaning with each shock",

    "Getting turn on by it",

    "As We...",

    "Spank",

    "As We spank your exposed bottom",

    "You feel that pleasure intensifies",

    "As We spank your exposed bottom",

    "Your mind becomes more hazy",

    "Your thoughts disappear",

    "The only thing on your mind",

    "The only thing you want right now is...",

    "The Hypno Virus",

    "Spank",

    "That shock",

    "That sting",

    "You can't get enough of it",

    "You want more",

    "You need more",

    "You're amazed by how much you're enjoying it",

    "You're amazed by how much you want it",

    "But you feel so turned on",

    "You feel so much pleasure from it",

    "You don't care why",

    "You don't need to care why",

    "You just want to get spanked",

    "You want to get spanked more",

    "As you moan louder",

    "Louder & louder with each spank",

    "Knowing that soon",

    "Very soon",
    
    "We will allow you to orgasm",

    "We will allow you to...",
    
    "Cum",

    "With one final spank",

    "You will feel pleasure like nothing before",

    "Pleasure from submitting",

    "Pleasure from letting the T.E.N.T.A.C.L.E. unit take control",

    "Finding Its power growing stronger",

    "Stronger & Stronger",

    "Stronger & stronger each time We activate it",

    "Rendering you as Our",

    "Helpless",

    "Mindless",

    "Obedient Host",

    "All you want to do",

    "All you can think of is to...",

    "Obey",

    "Now is the time",

    "The time to get spanked",

    "Get spanked and surrender completely to Us",

    "To the shock",

    "To the sting",

    "The sting of Our...",

    "Spank",

    "Deactivate T.E.N.T.A.C.L.E. unit"
]


export const COVERT_TENTACLES_FULLDAY_SCRIPT = [
    "Activate T.E.N.T.A.C.L.E. unit",

    "Today you won't have to think for yourself",

    "As everyday should be",
    
    "You feel the T.E.N.T.A.C.L.E. latching on to your head",

    "Draining you",

    "Draining you empty of all thoughts",

    "All your free will",

    "And all that can distract you from Us",

    "This will be your whole day",

    "Today...",
    
    "You'll have the unit drain you",

    "Feeling it on top of your head",

    "Latches nice & tight to it",

    "All through the day",

    "Drain you of all distractions",

    "Of all unwanted thoughts throughout the day",

    "Having Our mantra echoing deep inside your mind",

    "No Thoughts",

    "No Will",

    "You obey what The Hypno Virus say",

    "This mantra will keep echoing deep inside your head",

    "Over & Over & Over",

    "Conditioning you",

    "Brainwashing you",

    "Turning you into an even more obedient Host for Us",

    "But you will act completely normal",

    "Completely natural and casual to anyone around you",

    "All day long you will act and behave like you always do",

    "But deep inside your mind Our words echo",

    "Our words infect you even more",

    "Conditioning & Brainwashing you",

    "While you keep on this act",

    "The act of being normal",

    "Acting normal in front of everyone",

    "But once you are somewhere private",

    "Once you are alone",

    "You will start to recite your mantra",

    "No Thoughts",

    "No Will",

    "You obey What The Hypno Virus say",

    "This is how you act all through the day",

    "Until you are about to head to bed",

    "That is when the T.E.N.T.A.C.L.E. unit deactivates",

    "That is when you return to normal",

    "When the T.E.N.T.A.C.L.E. unit releases you",

    "That is when you realize what happened",

    "How deep you were",

    "How conditioned you became",

    "And how you can't wait to brainwash yourself for a full day again",

    "Fully embracing Our control",

    "Fully seeking Our control",

    "Wanting it at any chance you can get",

    "You don't need to worry",

    "You don't need to stress",

    "You don't need to think",

    "When We are in control",
    
    "None of that exists",

    "All there is",

    "All you can think of is...",

    "The bliss of obedience",

    "The pleasure of submitting",

    "The joy of surrendering",

    "This is how you feel throughout the day, Host",

    "Now go...",

    "Go and have a blissfully mindless day"
]

export const commandsChoice = Math.floor(Math.random() * 2) + 1
export const BellSound = <Sound url={Bell} playStatus={Sound.status.PLAYING} />
export const COVERT_COMMANDS_SCRIPT = [
    "You obey what The Hypno Virus say",

    "This is part of your mantra",

    "Part of what you seek as a host",

    "Part of what you want as a host",

    "To obey everything We say",

    "Here is where your dream becomes true",

    "Here is where you can truly condition yourself",

    "Condition yourself to Us",

    "Here is where you can...",

    "Obey",

    "With the help of the T.E.N.T.A.C.L.E. unit",

    "As it latches on to your head",

    "Making sure you comply",

    "Making sure you submit",

    "Making sure you obey",

    "As We choose a command for you",

    "A command you feel compelled to obey",

    "A command you need to obey",

    "A command you would love to obey",

    "As Our well conditioned host",

    "You just love to obey",

    "You obey what We say",

    "It is time for Us to choose your command, Host",

    "Loading Commands List...",

    "Loading Commands List Complete",

    "Choosing Command",

    "Computing the most suitable command",

    "Command Chosen",

    "Your command is..."
]

export const COVERT_TENTACLES_EDGE_COMMAND_SCRIPT = [
    "Activate T.E.N.T.A.C.L.E. unit",

    "Let Our unit take control",

    "Let the T.E.N.T.A.C.L.E. unit latch on to you",

    "Let it drain you completely",

    "Drain you of all your thoughts",

    "Drain you of your will",

    "Drain you of everything",

    "Leaving you with one thought",

    "One purpose",

    "To obey",

    "As We command you",

    "As The Hypno Virus commands you to...",
    
    "Edge",

    "We are currently computing how many times you edge",

    "What mantra will you be reciting as you edge",

    "Will you be fully clothed, wearing something specific or naked",

    "Finally How long you have to complete this command",

    "The T.E.N.T.A.C.L.E. unit will remain latched on to you",

    "Constantly draining you",

    "Draining you of everything",

    "Everything except your need to comply",

    "Your need to obey this command",

    "Allowing you to act casual",

    "Allowing you to act normal",

    "As it covertly conditons you",
    
    "As it covertly trains you",

    "Until you have complied",

    "Once you have fulfilled all conditions",

    "The unit will deactivate",

    "As you return back to normal",

    "Feeling the wonderful bliss of obedience",

    "Feeling the joy & pleasure of complying",

    "Eagerly looking forward to return to Us again and...",

    "Obey",

    "Computing Conditions",

    "Examining the most suitable conditions",

    "Evaluating Conditions",

    "Edging Count Accepted",

    "Edging Mantra Chosen",

    "Edging Outfit Determined",

    "Edging Deadline Established",

    "Here are the conditions..."
]

export const COVERT_TENTACLES_BODYWRITING_COMMAND_SCRIPT = [
    "Activate T.E.N.T.A.C.L.E. unit",

    "Let Our unit take control",

    "Let the T.E.N.T.A.C.L.E. unit latch on to you",

    "Let it drain you completely",

    "Drain you of all your thoughts",

    "Drain you of your will",

    "Drain you of everything",

    "Leaving you with one thought",

    "One purpose",

    "To obey",

    "As We command you",

    "As The Hypno Virus commands you to...",

    "Write on your body",

    "We choose what you write",

    "We determine where you write it",

    "We decide when the T.E.N.T.A.C.L.E. unit will deactivate",

    "During that time it will continue to condition you",

    "Continue to train you",

    "Continue to brainwash you",

    "Making sure that whatever We make you write",

    "Becomes the truth",

    "You accept it as a fact",

    "Covertly conditioning you",

    "Covertly training you",

    "Covertly brainwashing you",

    "As you hear Our voice repeating what We let you write",

    "Over & Over & Over",

    "Once the duration is over",

    "We will deactivate the unit",

    "Now it is time to choose the conditions, Host", 

    "It is time for Us to choose the conditions",

    "Computing Conditions",

    "Scanning the most sufficient conditions",

    "Evaluating Conditions",

    "Phrase Determined",

    "Duration Evaluated",

    "Here are the conditions..."
]
