import React, { useState } from "react";
import TextTransition from "react-text-transition";
import TypingEffect from 'react-typing-effect'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import Button from '../../../../UI/Button/Button'
import classes from '../../../../../containers/HostsChamber/AudioRuns/AudioRuns.module.css'
import { MotorFunctionControlReinforcementEnd } from '../../../../../store/actions/AudioRuns/audioRunsIndex'
import * as scripts from '../../../../../shared/AudioRuns/TriggerReinforcement/MotorFunctionControlReinforcement/MotorFunctionControlReinforcementScripts'
import * as Delays from '../../../../../shared/AudioRuns/TriggerReinforcement/MotorFunctionControlReinforcement/MotorFunctionControlReinforcementDelay'
import InstantControl from '../../../../../assets/audio_runs/Trigger_Reinforcement/Motor_Function_Control_Reinforcement/Wired_Reconfiguration.mp3'
import { connect } from 'react-redux'
import Sound from 'react-sound'
import Delay from "react-delay";
import useInterval from 'react-useinterval';

const DELAY_TEXT = [3800]

function Instant_Control({ userId, token, onMotorFunctionControlReinforcementEnd }) {

    let [count, setCount] = useState(0);
    let [instant_control, setProtocol] = useState(true)

    const increaseCount = () => {
        if (instant_control && count < scripts.WIRED_RECONFIGURATION_SCRIPT.length) {
            setCount(count + 1);
        }
    };

    useInterval(increaseCount, Delays.WIRED_RECONFIGURATION_DELAY[count]);


    const exitWiredReconfigruationSequenceHandler = () => {
        setProtocol(instant_control = false)
        onMotorFunctionControlReinforcementEnd(userId, token, 0, 0, 0,1)
    }

    let instant_control_form = null

    if (instant_control) {
        instant_control_form = (
            <Aux>
                {count < scripts.WIRED_RECONFIGURATION_SCRIPT.length ?
                    <Aux >
                        <Sound url={InstantControl} playStatus={Sound.status.PLAYING} />
                        <Delay wait={DELAY_TEXT[count]}>
                            <TextTransition
                                text={<TypingEffect typingDelay={100} text={scripts.WIRED_RECONFIGURATION_SCRIPT[count]} speed={50} eraseDelay={1000000} />}
                                Overflow
                            />
                        </Delay>
                    </Aux>
                    :
                    <div className={classes.ButtonPadding}>
                        <Button btnType="Success" clicked={exitWiredReconfigruationSequenceHandler}>The Hypno Virus has seized control</Button>
                    </div>
                }
            </Aux >
        )
    }


    return (
        <Aux>
            {instant_control_form}
        </Aux>
    )

}

const mapStateToProps = state => {
    return {
        userId: state.auth.userId,
        token: state.auth.token,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onMotorFunctionControlReinforcementEnd: (userId, token, 
            instant_control_reinforcement, 
            slow_control_reinforcement, 
            mental_control_reinforcement, 
            wired_reconfiguration) => dispatch(MotorFunctionControlReinforcementEnd(userId, token, 
                                                                                                    instant_control_reinforcement, 
                                                                                                    slow_control_reinforcement, 
                                                                                                    mental_control_reinforcement, 
                                                                                                    wired_reconfiguration))
}
}
export default connect(mapStateToProps, mapDispatchToProps)(Instant_Control)