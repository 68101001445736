import React, { Component } from 'react'
// import Input from '../../../components/UI/Input/Input'
import Button from '../../../components/UI/Button/Button'
import classes from '../Account.module.css'
import Aux from '../../../hoc/auxiliary/auxiliary';
import * as actions from '../../../store/actions/index';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { connect } from 'react-redux'
import TentacleReinforcement from '../../../components/Account/Runs/TriggerReinforcement/Tentacle_Reinforcement'
import ChloroformReinforcement from '../../../components/Account/Runs/TriggerReinforcement/Chloroform_Reinforcement'
import MotorFunctionControlReinforcement from '../../../components/Account/Runs/TriggerReinforcement/Motor_Function_Control_Reinforcement'

class Runs extends Component {

    viewTentacleReinforcementHandler = (event) => {
        event.preventDefault();
        this.props.OnTentacleReinforcement(this.props.token, this.props.userId)
    }

    viewChloroformReinforcementHandler = (event) => {
        event.preventDefault();
        this.props.OnChloroformReinforcement(this.props.token, this.props.userId)
    }

    viewMotorFunctionControlReinforcementHandler = (event) => {
        event.preventDefault();
        this.props.OnMotorFunctionControlReinforcement(this.props.token, this.props.userId)
    }


    ReturnBackHandler = (event) => {
        event.preventDefault();
        this.props.onReturnBack()
    }

    render() {

        let form = null

        if (this.props.viewTriggerReinforcement) {
            form = (<div className={classes.AccountContainer}>
                <form className={classes.ViewProfile}>
                    <h2>Select Run</h2>
                    <div className={classes.AuthButtons}>
                        <Button btnType="Success" clicked={this.viewTentacleReinforcementHandler}>T.E.N.T.A.C.L.E. Reinforcement <br></br>Details</Button> <br></br>
                        <Button btnType="Success" clicked={this.viewChloroformReinforcementHandler}>Chloroform Reinforcement <br></br>Details</Button> <br></br>
                        <Button btnType="Success" clicked={this.viewMotorFunctionControlReinforcementHandler}>Motor Function Control Reinforcement <br></br>Details</Button> <br></br>
                        <Button btnType="Danger" clicked={this.ReturnBackHandler}>Back</Button>
                    </div>
                </form>
            </div>
            )
        }


        if (this.props.viewTentacleReinforcement){
            form = 
                <TentacleReinforcement
                    name = {this.props.accountDetails.name}
                    TentacleReinforcement = {this.props.viewTentacleReinforcementDetailsArray} 
                    ReturnBackHandler = {this.ReturnBackHandler}/>
        }

        if (this.props.viewChloroformReinforcement){
            form = 
                <ChloroformReinforcement
                    name = {this.props.accountDetails.name}
                    ChloroformReinforcement = {this.props.viewChloroformReinforcementDetailsArray} 
                    ReturnBackHandler = {this.ReturnBackHandler}/>
        }


        if (this.props.viewMotorFunctionControlReinforcement){
            form = 
                <MotorFunctionControlReinforcement
                    name = {this.props.accountDetails.name}
                    title = {this.props.title}
                    MotorFunctionControlReinforcement = {this.props.viewMotorFunctionControlReinforcementDetailsArray} 
                    ReturnBackHandler = {this.ReturnBackHandler}/>
        }

        if (this.props.loading) {
            form = <Spinner className={classes.Spinner} />
        }

        let errorMessage = null

        if (this.props.error) {
            errorMessage = (
                <p className={classes.error}>{this.props.error}</p>
            )
        }

        return (
            <Aux>
                <div className={classes.Account}>
                    {errorMessage}
                    {form}
                </div>
            </Aux >

        );
    }
}

const mapStatesToProps = state => {
    return {
        loading: state.runs.loading,
        error: state.auth.error,
        token: state.auth.token,
        userId: state.auth.userId,
        accountDetails: state.auth.accountDetails,
        authRedirectValid: state.auth.authRedirectValid,
        viewTriggerReinforcement: state.runs.viewTriggerReinforcement,

        viewTentacleReinforcement: state.runs.viewTentacleReinforcement,
        viewTentacleReinforcementDetailsArray: state.runs.viewTentacleReinforcementDetailsArray,

        viewChloroformReinforcement: state.runs.viewChloroformReinforcement,
        viewChloroformReinforcementDetailsArray: state.runs.viewChloroformReinforcementDetailsArray,

        viewMotorFunctionControlReinforcement: state.runs.viewMotorFunctionControlReinforcement,
        viewMotorFunctionControlReinforcementDetailsArray: state.runs.viewMotorFunctionControlReinforcementDetailsArray,

    }
}
const mapDispatchToProps = dispatch => {
    return {
        OnTentacleReinforcement: (token, userId) => dispatch(actions.TentacleReinforcement(token, userId)),
        OnChloroformReinforcement: (token, userId) => dispatch(actions.ChloroformReinforcement(token, userId)),
        OnMotorFunctionControlReinforcement: (token, userId) => dispatch(actions.MotorFunctionControlReinforcement(token, userId)),
        onReturnBack: () => dispatch(actions.returnBackSuccess()),
        onLogout: () => dispatch(actions.logout())
    }
}

export default connect(mapStatesToProps, mapDispatchToProps)(Runs);