import React from 'react'
import Aux from '../../hoc/auxiliary/auxiliary'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import Sound from 'react-sound'
import Bell from '../../assets/audio/bell.mp3'
import Delay from 'react-delay';

const background = (props) => {
    let websiteAudio = null
    let audioDelay = Math.floor(Math.random() * 120000) + 60000
    if (props.profileOptions.website_audio === '1') {
        websiteAudio = (
            <Aux>
                <Delay wait={audioDelay}>
                    <Sound url={Bell} playStatus={Sound.status.PLAYING} />
                </Delay>
            </Aux>
        )
    }
    return websiteAudio
}

const mapStatesToProps = state => {
    return {
        profileOptions: state.options.profileOptions,

    }
}
const mapDispatchToProps = dispatch => {
    return {
        onViewProfileOptions: (token, userId) => dispatch(actions.viewProfileOptions(token, userId)),
    }
}

export default connect(mapStatesToProps, mapDispatchToProps)(background)