import React, { Component } from 'react';
import Aux from '../../../../hoc/auxiliary/auxiliary';
import classes from '../HypnosisShowMenu.module.scss'
// import classes2 from '../ExperienceMenu/ExperienceMenu.module.scss'
import HypnosisShow from '../../../../assets/Images/Blank.png'
import SuggestionChoices from './HypnosisShowNonVolSuggestionChoices'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'

class HypnosisShowSuggestions extends Component {

    state = {
        suggestions: true,
        suggestionChoices: false
    }

    suggestionChoiceHandler = () => {
        this.setState({ suggestions: false, suggestionChoices: true })
    }



    render() {
        let HypnosisShowForm = null
        if (this.state.suggestions) {
            HypnosisShowForm = (
                <html style={{ backgroundImage: `url(${HypnosisShow})` }}>
                    <div className={classes.storyFrom}>
                        <p>
                        <strong>Relaxed… completely relaxed.</strong><br></br>
                        No thoughts, no stress & no care. Just blissfully empty, mindlessly relaxed & totally open. Open to his voice, to his words & to everything he says. 
                        You hear his voice talking in a low, soft almost whispering & soothingly saying <br></br>
                        <i>“You've done such a wonderful job. That’s right, you have been an excellent subject and wouldn't you want to be even better? Wouldn't you want to even drop deeper and become a better subject?
                            You know how you can do this? Don't worry, I'll tell you. All you have to do is imagine this cloud, this soft, fluffy cloud you are in & feel yourself sinking in it.
                            Letting it wrap you completely, feeling yourself getting surrounded by it and the fluffiness of it making you feel comfortable and relaxed.
                            Allow yourself, allow yourself to safely & peacefully fall deeper. Fall deeper & let whatever stress you might have, whatever thoughts you might have disappear.<br></br>
                            Doesn't this feel amazing? Wouldn't you love to listen more and let me guide you? Don't you want me to steer your mind to the most amazing places deep into your subconscious and allow my words to become part of your mind to become part of you?<br></br>
                            Think about all the new experiences & new adventures that await you. Think of all the amazing things you can experience with the help of my voice, are you ready for these adventures?
                            Are you ready to experience it all?"</i>.
                        </p>
                        <form className={classes.centered} onClick={this.suggestionChoiceHandler}>
                            <h4 className={classes.storyButtonSize}>You smile & nod</h4>
                        </form>
                    </div>
                </html>
            )
        }
        if (this.state.suggestionChoices) {
            HypnosisShowForm = (
                <SuggestionChoices />
            )
        }


        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {HypnosisShowForm}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >




        )
    }
}


export default HypnosisShowSuggestions