import React from 'react'
import Aux from '../../../hoc/auxiliary/auxiliary'
import Delay from 'react-delay'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'
import classes from './MantraChamberComponent.module.css'
import Sound from 'react-sound'
import Mantra from '../../../assets/audio/mantralong.mp3'
import Bell from '../../../assets/audio/bell.mp3'

const MantraChamberComponent = (props) => {
    let mantraDiv = null
    let mantraWait = 5000
    if (props.mantraVisible) {
        mantraDiv = (
            <div className={classes.MantraChamber}>
                <Delay wait={mantraWait}>
                    <Sound url={Bell} playStatus={Sound.status.PLAYING}/>
                    <h3 >NO THOUGHTS</h3>
                    </Delay>
                <Delay wait={mantraWait * 2}>
                    <Sound url={Bell} playStatus={Sound.status.PLAYING}/>
                    <h3>NO WILL</h3>
                    </Delay>
                <Delay wait={mantraWait * 3}>
                    <Sound url={Bell} playStatus={Sound.status.PLAYING}/>
                    <h3>I OBEY WHAT THE HYPNO VIRUS SAY</h3>
                    </Delay>
            </div>
        )
    } else if (!props.mantraVisible) {
        mantraDiv = null
    }

    return (
        <Aux>
            <Fullpage >
                <FullPageSections>
                    <FullpageSection>
                        <div>
                            <Sound url={Mantra} playStatus={Sound.status.PLAYING} loop={true} />
                            {mantraDiv}
                        </div>
                    </FullpageSection>
                </FullPageSections>
            </Fullpage >
        </Aux >

    )

}

export default MantraChamberComponent