import React from 'react';
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'
import classes from '../../../../../containers/HostsChamber/AudioRuns/AudioRuns.module.css'
import FastChloroform from './Fast_Chloroform_Effect'
import NormalChloroform from './Normal_Chloroform_Effect'

function ChloroformVisual(props) {

       let chloroform_form = null
    
    if (props.fast_chloroform){
        chloroform_form = <FastChloroform />
    } 

    if (props.normal_chloroform){
        chloroform_form = <NormalChloroform/>
    }

    // let centerText = window.innerHeight / 2 - 55
    // let texting = {

    //     // display: 'flex',
    //     // flexFlow: 'column',
    //     whiteSpace: 'pre-line',
    //     alignItems: 'center',
    //     fontWeight: 'bold',
    //     boxSizing: "border-box",
    //     margin: 'auto',
    //     marginBottom: '10px',
    //     padding: '30px 10px',
    //     width: '50%',
    //     paddingTop: centerText + 'px',
    //     color: 'white'

    // }


    return (
        <Fullpage >
            <FullPageSections>
                < FullpageSection>
                    <div className={classes.Grouping}>{chloroform_form}
                    </div>
                </FullpageSection>
            </FullPageSections>
        </Fullpage>
    );
}

export default ChloroformVisual;
