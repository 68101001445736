import React, { Component } from 'react'
// import Input from '../../../components/UI/Input/Input'
// import Button from '../../../components/UI/Button/Button'
import classes from '../Account.module.css'
import Aux from '../../../hoc/auxiliary/auxiliary';
import * as actions from '../../../store/actions/index';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { connect } from 'react-redux'
import { updateObject, checkValidity } from '../../../shared/utility'
import CreateProfile from '../../../components/Account/Profile/CreateProfile'
import ViewProfile from '../../../components/Account/Profile/ViewProfile'
import EditProfile from '../../../components/Account/Profile/EditProfile'
import ChangeData from '../../../components/Account/Profile/ChangeData'
import Options from '../Options/Options'

class Profile extends Component {
    state = {
        RegisteringForm: {
            name: {
                label: 'Name',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Your Name'
                },
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                touched: false
            },
            gender: {
                label: 'Gender',
                elementType: 'select',
                elementConfig: {
                    options: [
                        { value: 'Male', displayValue: 'Male' },
                        { value: 'Female', displayValue: 'Female' },
                        { value: 'Neutral', displayValue: 'Neutral' }
                    ]
                },
                value: 'Male',
                validation: {
                    required: true
                },
                valid: true
            },
            speed: {
                label: 'Text Speed',
                elementType: 'select',
                elementConfig: {
                    options: [
                        { value: 90, displayValue: 'Fast' },
                        { value: 110, displayValue: 'Normal' },
                        { value: 130, displayValue: 'Slow' }
                    ]
                },
                value: 90,
                validation: {
                    required: true
                },
                valid: true
            }
        },
        ChangeInfoForm: {
            newEmail: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'New Email'
                },
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                touched: false
            },
            newPassword: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'New Password'
                },
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                touched: false
            },
            confirmPassword: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Confirm Password'
                },
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                touched: false
            },
        },
        formIsValid: false,
        togglePassword: false,
        message: null
    }

    inputRegisterHandler = (event, inputIdentifier) => {

        const updatedFormElement = updateObject(this.state.RegisteringForm[inputIdentifier], {
            ...this.state.RegisteringForm[inputIdentifier],
            value: event.target.value,
            valid: checkValidity(event.target.value, this.state.RegisteringForm[inputIdentifier].validation),
            touched: true
        });
        const updatedInfo = updateObject(this.state.RegisteringForm, {
            [inputIdentifier]: updatedFormElement
        });

        let formIsValid = true;
        for (let inputIdentifier in updatedInfo) {
            formIsValid = updatedInfo[inputIdentifier].valid && formIsValid;
        }
        this.setState({ RegisteringForm: updatedInfo, formIsValid: formIsValid });

    }

    inputChangeInfoHandler = (event, inputIdentifier) => {
        const updatedFormElement = updateObject(this.state.ChangeInfoForm[inputIdentifier], {
            ...this.state.ChangeInfoForm[inputIdentifier],
            value: event.target.value,
            valid: checkValidity(event.target.value, this.state.ChangeInfoForm[inputIdentifier].validation),
            touched: true
        });
        const updatedInfo = updateObject(this.state.ChangeInfoForm, {
            [inputIdentifier]: updatedFormElement
        });

        let formIsValid = true;
        for (let inputIdentifier in updatedInfo) {
            formIsValid = updatedInfo[inputIdentifier].valid && formIsValid;
        }

        if (this.state.ChangeInfoForm.newPassword.value !== this.state.ChangeInfoForm.confirmPassword.value) {
            this.setState({ message: 'Password does not match', ChangeInfoForm: updatedInfo, formIsValid: formIsValid })
        } else {
            this.setState({ message: null, ChangeInfoForm: updatedInfo, formIsValid: formIsValid })
        }


    }

    togglePasswordHandler = () => {
        
        alert('Your Password is: ' + this.props.accountDetails.password)
    }

    createAccountHandler = (event) => {
        event.preventDefault();

        const formData = {};
        for (let formElementIdentifier in this.state.RegisteringForm) {
            formData[formElementIdentifier] = this.state.RegisteringForm[formElementIdentifier].value;
        }
        let speed = null
        if (formData.speed === '110') {
            speed = "Normal"
        } else if (formData.speed === 90) {
            speed = "Fast"
        } else if (formData.speed === '130') {
            speed = "Slow"
        }
        const registerAccount = {
            email: this.props.email,
            name: formData.name,
            gender: formData.gender,
            speed: formData.speed,
            speedName: speed,
            password: this.props.password,
            Host_Runs: 0,
            userId: this.props.userId
        }
        this.props.onCreateAccount(registerAccount, this.props.token, this.props.userId)
    }

    saveChangesHandler = (token, Host_Runs) => {
        // event.preventDefault();

        const formData = {};
        for (let formElementIdentifier in this.state.RegisteringForm) {
            formData[formElementIdentifier] = this.state.RegisteringForm[formElementIdentifier].value;
        }
        let speed = null
        if (formData.speed === '110') {
            speed = "Normal"
        } else if (formData.speed === 90) {
            speed = "Fast"
        } else if (formData.speed === '130') {
            speed = "Slow"
        }

        const editAccount = {
            email: this.props.accountDetails.email,
            name: formData.name,
            gender: formData.gender,
            speed: formData.speed,
            speedName: speed,
            Host_Runs: Host_Runs,
            password: this.props.accountDetails.password,
            userId: this.props.userId
        }

        if (this.props.error === null) {
            this.props.onEditProfile(editAccount, token, this.props.userId)
        }
    }



    deleteAccountHandler = (token, userId) => {
        this.props.onDeleteAccount(token, userId)
    }

    ReturnBackHandler = (event) => {
        event.preventDefault();
        this.props.onReturnBack()
    }

    logoutHandler = (event) => {
        event.preventDefault();
        this.props.onLogout()

    }


    render() {

        const registerFormElementsArray = [];
        for (let key in this.state.RegisteringForm) {
            registerFormElementsArray.push({
                id: key,
                config: this.state.RegisteringForm[key]
            });
        }

        const changeInfoFormElementsArray = [];
        for (let key in this.state.ChangeInfoForm) {
            changeInfoFormElementsArray.push({
                id: key,
                config: this.state.ChangeInfoForm[key]
            });
        }

        let form = null
        
        if (this.props.viewProfile) {
            form =
                <ViewProfile 
                    accountDetails = {this.props.accountDetails} 
                    onEditProfileView = {this.props.onEditProfileView} 
                    onViewRunDetails = {this.props.onViewRunDetails} 
                    changeDataHandler = {this.props.changeDataHandler}
                    onDeleteAccount = {this.props.onDeleteAccount}
                    token = {this.props.token}
                    userId = {this.props.userId}
                    togglePassword = {this.state.togglePassword}
                    togglePasswordHandler = {this.togglePasswordHandler}
                    logoutHandler = {this.logoutHandler}
                    onOptionsView = {this.props.onviewOptionPage} />

        } 
        
        if (this.props.profileActive) {
            form = (
                <CreateProfile
                    registerFormHandler = {this.registerFormHandler}
                    registerFormElementsArray = {registerFormElementsArray}
                    inputRegisterHandler = {this.inputRegisterHandler}
                    createAccountHandler = {this.createAccountHandler}/>
            );
        }

        if (this.props.editProfile) {
            form =
                <EditProfile 
                    registerFormElementsArray = {registerFormElementsArray} 
                    inputRegisterHandler = {this.inputRegisterHandler} 
                    saveChangesHandler = {this.saveChangesHandler} 
                    ReturnBackHandler = {this.ReturnBackHandler}
                    token = {this.props.token}
                    Host_Runs = {this.props.accountDetails.Host_Runs}
                    userId = {this.props.userId}/>
        }

        if (this.props.changeData) {
            form = (
                <ChangeData 
                    registerFormHandler = {this.registerFormHandler} 
                    message = {this.state.message} 
                    saveChangesHandler = {this.saveChangesHandler} 
                    changeInfoFormElementsArray = {changeInfoFormElementsArray}
                    inputChangeInfoHandler = {this.inputChangeInfoHandler}
                    onChangeEmail = {this.props.onChangeEmail}
                    onChangePassword = {this.props.onChangePassword}
                    ReturnBackHandler = {this.ReturnBackHandler}
                    token = {this.props.token}
                    userId = {this.props.userId}
                    newEmail ={this.state.ChangeInfoForm.newEmail.value}
                    newPassword ={this.state.ChangeInfoForm.newPassword.value}/>
            );
        }

        if (this.props.optionsView){
            form = <Options />
        }


        if (this.props.loading) {
            form = <Spinner className={classes.Spinner} />
        }

        let errorMessage = null

        if (this.props.error) {
            errorMessage = (
                <p className={classes.error}>{this.props.error}</p>
            )
        }

        return (
            <Aux>
                <div className={classes.Account}>
                    {errorMessage}
                    {form}
                </div>
            </Aux >

        );
    }
}

const mapStatesToProps = state => {
    return {
        loading: state.profile.loading,
        error: state.profile.error,
        token: state.auth.token,
        userId: state.auth.userId,
        email: state.auth.email,
        password: state.auth.password,
        changeData: state.profile.changeData,
        profileActive: state.auth.profileActive,
        accountDetails: state.auth.accountDetails,
        viewProfile: state.profile.viewProfile,
        editProfile: state.profile.editProfile,
        viewRunDetails: state.runs.viewRunDetails,
        viewOptions: state.options.viewOptions,
        viewTitle: state.titles.viewTitle,
        optionsView: state.profile.optionsView,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onViewRunDetails: () => dispatch(actions.viewRunDetails()),
        onCreateAccount: (accountDetail, token, userId) => dispatch(actions.Account(accountDetail, token, userId)),
        onDeleteAccount: (token, userId) => dispatch(actions.removeAccount(token, userId)),
        onChangePassword: (token, password, userId) => dispatch(actions.changePassword(token, password, userId)),
        onChangeEmail: (token, email, userId) => dispatch(actions.changeEmail(token, email, userId)),
        onFetchProfileDetails: (token, userId) => dispatch(actions.fetchProfile(token, userId)),
        onEditProfileView: () => dispatch(actions.editProfileView()),
        onEditProfile: (accountDetail, token, userId) => dispatch(actions.editProfile(accountDetail, token, userId)),
        changeDataHandler: () => dispatch(actions.changeEmailPasswordView()),
        onOptionsView: () => dispatch(actions.viewOptions()),
        onTitlesView: () => dispatch(actions.viewTitle()),
        onviewOptionPage: () => dispatch(actions.viewOptionPage()),
        onReturnBack: () => dispatch(actions.returnBackSuccess()),
        onLogout: () => dispatch(actions.logout())
    }
}

export default connect(mapStatesToProps, mapDispatchToProps)(Profile);