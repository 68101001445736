import React, { Component } from 'react';
import Aux from '../../../../hoc/auxiliary/auxiliary';
import classes from '../HypnosisShowMenu.module.scss'
// import classes2 from '../ExperienceMenu/ExperienceMenu.module.scss'
import HypnosisShow from '../../../../assets/Images/Blank.png'
import ForeHeadTap from '././HypnosisShowVolForeheadTap'
import HypnoGun from '././HypnosisShowVolHypnoGun'
import SelfSnap from '././HypnosisShowVolSelfSnap'
import WakeUp from '././HypnosisShowVolWakeUp'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'

class HypnosisShowSnap2 extends Component {

    state = {
        fingerSnap: true,
        foreheadTap: false,
        hypnoGun: false,
        countdownSnap: false,
        wakeUp: false
    }

    foreheadTapHandler = () => {
        this.setState({ fingerSnap: false, foreheadTap: true })
    }

    hypnoGunHandler = () => {
        this.setState({ fingerSnap: false, hypnoGun: true })
    }

    countdownSnapHandler = () => {
        this.setState({ fingerSnap: false, countdownSnap: true })
    }

    wakeUpHandler = () => {
        this.setState({ fingerSnap: false, wakeUp: true })
    }


    render() {
        let HypnosisShowForm = null
        if (this.state.fingerSnap) {
            HypnosisShowForm = (
                <html style={{ backgroundImage: `url(${HypnosisShow})` }}>
                    <div className={classes.storyFrom}>
                        <p>
                        <i>"Confused with no recollection of what I'm saying or remembering every detail of it. That does not matter right now. You are feeling wonderful, you are feeling great, you are feeling relaxed & you are feeling happy. That is all that matters right now, that and...</i> <br></br>
                        <strong>*SNAP*</strong><br></br>
                        You drop, without thinking you drop. Drop into this empty void where nothing exists, nothing exists but the joy and pleasure that you've been experiencing throughout the show. The joy & pleasure of sinking deep under his trance. 
                        The joy and pleasure you feel from listening to his voice say <br></br> 
                        <i>"Dropping deeper & deeper, sinking more, drifting and floating further. Drifting & floating further into trance. All you want is have me…”</i>
                        </p>
                        <form className={classes.centered} onClick={this.foreheadTapHandler}>
                            <h3 className={classes.storyButtonSize}>Tap your Forehead</h3>
                        </form>
                        <form className={classes.centered} onClick={this.hypnoGunHandler}>
                            <h3 className={classes.storyButtonSize}>Use my Hypno Gun</h3>
                        </form>
                        <form className={classes.centered} onClick={this.countdownSnapHandler}>
                            <h3 className={classes.storyButtonSize}>Countdown & Self Drop you</h3>
                        </form>
                        <form className={classes.centered} onClick={this.wakeUpHandler}>
                            <h3 className={classes.storyButtonSize}>Wake you Up</h3>
                        </form>
                    </div>
                </html>
            )
        }

        if (this.state.foreheadTap) {
            HypnosisShowForm = (
                <ForeHeadTap />
            )
        }

        if (this.state.hypnoGun) {
            HypnosisShowForm = (
                <HypnoGun />
            )
        }

        if (this.state.countdownSnap) {
            HypnosisShowForm = (
                <SelfSnap />
            )
        }

        if (this.state.wakeUp) {
            HypnosisShowForm = (
                <WakeUp />
            )
        }

        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {HypnosisShowForm}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >




        )
    }
}


export default HypnosisShowSnap2