import React, { Component } from 'react'
// import Input from '../../../components/UI/Input/Input'
import Button from '../../../components/UI/Button/Button'
import classes from '../Account.module.css'
import Aux from '../../../hoc/auxiliary/auxiliary';
import * as actions from '../../../store/actions/index';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { connect } from 'react-redux'
import Input from '../../../components/UI/Input/Input'
import { updateObject, checkValidity } from '../../../shared/utility'
class Titles extends Component {
    state = {
        Title_Options: {
            Title: {
                label: 'Titles',
                elementType: 'select',
                elementConfig: {
                    options: [
                        { value: this.props.Titles[0], displayValue: this.props.Titles[0] },
                        { value: this.props.Titles[1], displayValue: this.props.Titles[1] },
                        { value: this.props.Titles[2], displayValue: this.props.Titles[2] },
                        { value: this.props.Titles[3], displayValue: this.props.Titles[3] },
                        { value: this.props.Titles[4], displayValue: this.props.Titles[4] },
                        { value: this.props.Titles[5], displayValue: this.props.Titles[5] },
                        { value: this.props.Titles[6], displayValue: this.props.Titles[6] },
                        { value: this.props.Titles[7], displayValue: this.props.Titles[7] },
                        { value: this.props.Titles[8], displayValue: this.props.Titles[8] },
                        { value: this.props.Titles[9], displayValue: this.props.Titles[9] },
                        { value: this.props.Titles[10], displayValue: this.props.Titles[10] },
                        { value: this.props.Titles[11], displayValue: this.props.Titles[11] },
                    ]
                },
                value: this.props.currentTitle,
                validation: {
                    required: true
                },
                valid: true
            },
        }
    }
    
    


    inputTitleChangeHandler = (event, TitleOptionName) => {
        const updatedProfileOption = updateObject(this.state.Title_Options, {
            [TitleOptionName]: updateObject(this.state.Title_Options[TitleOptionName], {
                ...this.state.Title_Options[TitleOptionName],
                value: event.target.value,
                valid: checkValidity(event.target.value, this.state.Title_Options[TitleOptionName].validation),
                touched: true
            })
        })
        this.setState({ Title_Options: updatedProfileOption })
    }

    ReturnBackHandler = (event) => {
        event.preventDefault();
        this.props.onReturnBack()
    }

    applyTitleChangesHandler = (event) => {
        event.preventDefault()
        this.props.onChangeTitle(this.props.token, this.props.userId, this.state.Title_Options.Title.value)
        alert('Your new Title is: ' + this.state.Title_Options.Title.value)  
        
    }

    render() {
        let form = null
        const formTitleArray = [];
        for (let key in this.state.Title_Options) {
            formTitleArray.push({
                id: key,
                config: this.state.Title_Options[key]
            });
        }
        
        if (this.props.viewTitle) {
            form = (
            <div className={classes.AccountContainer}>
                <form className={classes.FormStyle}>
                    <h3>Title Selection</h3>
                    <p>Current Title is: {this.props.currentTitle}</p>
                        {formTitleArray.map(formElement => (
                            <Input
                                key={formElement.id}
                                elementType={formElement.config.elementType}
                                elementConfig={formElement.config.elementConfig}
                                value={formElement.config.value}
                                invalid={!formElement.config.valid}
                                shouldValidate={formElement.config.validation}
                                touched={formElement.config.touched}
                                label={formElement.config.label}
                                changed={(event) => this.inputTitleChangeHandler(event, formElement.id)} />

                        ))}
                        <br></br><Button btnType="Success" clicked={this.applyTitleChangesHandler}>Apply Changes</Button>
                        <Button btnType="Danger" clicked={this.props.returnToVisualHandler}>Back</Button>
                    </form>
            </div>
            )
        }

        


        if (this.props.loading) {
            form = <Spinner className={classes.Spinner} />
        }

        let errorMessage = null

        if (this.props.error) {
            errorMessage = (
                <p className={classes.error}>{this.props.error}</p>
            )
        }

        return (
            <Aux>
                <div className={classes.Account}>
                    {errorMessage}
                    {form}
                </div>
            </Aux >

        );
    }
}

const mapStatesToProps = state => {
    return {
        loading: state.options.loading,
        error: state.options.error,
        token: state.auth.token,
        userId: state.auth.userId,
        currentTitle: state.auth.title,
        viewTitle: state.titles.viewTitle,
        Titles:state.titles.titles,
        Title:state.titles.title

        
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onchangeTitleSuccess: () => dispatch(actions.changeTitleSuccess()),
        onChangeTitle: (token, userId, newTitle) => dispatch(actions.changeTitle(token, userId,newTitle)),
        onReturnBack: () => dispatch(actions.returnBackSuccess()),
    }
}

export default connect(mapStatesToProps, mapDispatchToProps)(Titles);