import * as audioRunTypes from '../../../actions/AudioRuns/audioRunTypes';
import { updateObject } from '../../../../shared/utility';

const initialState = {
    error: null,
    Tentacle_Reinforcement_Complete: false,
    Tentacle_Reinforcement_Commands: [],
    loading: false,
}

const tentacleReinforcementStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true
    })
}

const tentacleReinforcementSuccess = (state, action) => {
        return updateObject(state, {
            Tentacle_Reinforcement_Commands: action.Tentacle_Reinforcement,
            loading: false,
        })
    }

    



const tentacleReinforcementFailed = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    })
}


const tentacleReinforcementComplete = (state, action) => {
    return updateObject(state, {
        error: null,
        Tentacle_Reinforcement_Complete: true,
        Tentacle_Reinforcement_Commands: [],
        loading: false,
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case audioRunTypes.AUDIO_RUN_START: return tentacleReinforcementStart(state, action)
        case audioRunTypes.AUDIO_RUN_FAILED: return tentacleReinforcementFailed(state, action)
        case audioRunTypes.TENTACLE_REINFORCEMENT_SUCCESS: return tentacleReinforcementSuccess(state,action)
        case audioRunTypes.TENTACLE_REINFORCEMENT_COMPLETED: return tentacleReinforcementComplete(state, action)
        default:
            return state

    }
}


export default reducer