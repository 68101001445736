import React, { Component } from 'react';
import Aux from '../../hoc/auxiliary/auxiliary'
import classes from './Visuals.module.css'
// import Particles from '../../assets/Visuals/Stars.PNG'
// import Bubbles from '../../assets/Visuals/Bubbles.PNG'
import ParticleVisual from '../../components/Visuals/Particles_Visual/Particle_Visual'
import CirclesVisual from '../../components/Visuals/Bubbles_Visual/Bubbles_Visual'
import ColorsVisual from '../../components/Visuals/Color_Visual/Color_Visual'
import EchoVisual from '../../components/Visuals/Echo_Visual/Echo_Visual'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'
import { connect } from 'react-redux'

class Visuals extends Component {
  state = {
    visuals: true,
    particleView: false,
    circlesView: false,
    colorsView: false,
    echoVisual: false
  }

  particlesViewHandler = () => {
    this.setState({ visuals: false, particleView: true })
  }

  circlesViewHandler = () => {
    this.setState({ visuals: false, circlesView: true })
  }

  colorViewHandler = () => {
    this.setState({ visuals: false, colorsView: true })
  }

  echoViewHandler = () => {
    this.setState({ visuals: false, echoVisual: true })
  }

  returnToVisualsHander () {
    this.setState({ visuals: true, 
      particleView: false,
      circlesView: false,
      colorsView: false,
      echoVisual: false })
  }


  render() {

    let visualView = null
    if (this.state.visuals) {
      visualView = (
        <Aux>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <div className="snow"></div>
          <h3 className={classes.headline}>THE HYPNO VIRUS'S VISUALS</h3>
          <div  className={classes.visuals}>
            <p onClick={this.particlesViewHandler} className={classes.centered}>PARTICLES</p>
            <p onClick={this.circlesViewHandler} className={classes.centered}>BUBBLES</p>
            <p onClick={this.colorViewHandler} className={classes.centered}>COLORS</p>
            <p onClick={this.echoViewHandler} className={classes.centered}>ECHO</p>
          </div>
         
        </Aux>
      )
    }

    if (this.state.particleView) {
      visualView = (
        <ParticleVisual returnToVisualHandler = {this.returnToVisualsHander}/>
      )
    }

    if (this.state.circlesView) {
      visualView = (
        <CirclesVisual returnToVisualHandler = {this.returnToVisualsHander}/>
      )
    }

    if (this.state.colorsView) {
      visualView = (
        <ColorsVisual returnToVisualHandler = {this.returnToVisualsHander}/>
      )
    }

    if (this.state.echoVisual) {
      visualView = (
        <EchoVisual returnToVisualHandler = {this.returnToVisualsHander}/>
      )
    }



    return (
      <Aux>
        <Fullpage >
          <FullPageSections>
            <FullpageSection>
              {visualView}
            </FullpageSection>
          </FullPageSections>
        </Fullpage >

      </Aux >
    )
  }

}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null
  }
}

export default connect(mapStateToProps)(Visuals)