import React, { Component } from 'react';
import Aux from '../../../../hoc/auxiliary/auxiliary';
import classes from '../HypnosisShowMenu.module.scss'
// import classes2 from '../ExperienceMenu/ExperienceMenu.module.scss'
import HypnosisShow from '../../../../assets/Images/Blank.png'
import ForgetNumber from './HypnosisShowNonVolForgetNumber'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'

class HypnosisShowBeforeDeepener extends Component {

    state = {
        beforeDeepener: true,
        forgetNumber: false
    }

    forgetNumberHandler = () => {
        this.setState({ beforeDeepener: false, forgetNumber: true })
    }



    render() {
        let HypnosisShowForm = null
        if (this.state.beforeDeepener) {
            HypnosisShowForm = (
                <html style={{ backgroundImage: `url(${HypnosisShow})` }}>
                    <div className={classes.storyFrom}>
                        <p>
                            <i>“yes”</i> you nod to his words. Dropping deeper with that snap.
                            He is all you see now, all you focus on.<br></br>
                            Your surroundings & everything around you does not exist right now feeling yourself falling deep.<br></br>
                            Falling so deep under. <br></br>
                            Sinking into your chair, you feel your whole body light, light as a feather as you keep sinking deep down.
                            Sinking & falling deep into your chair. Not caring about anything happening around you.
                            The only thing you’re focusing on, the only thing you’re waiting for it The Great O. The Great O’s voice.<br></br>
                            That’s all you can think about right now, all you want & all you need.
                            As if he was reading your mind you hear his voice now saying <br></br>
                            <i>“You’re doing wonderful, just relax more. Relax & let my words help you sink deeper, guide you even further as they relax you & drop you deeper. <br></br>
                            Deeper & deeper you fall. <br></br>
                            Deeper & deeper you go. <br></br>
                            Deeper & deeper ready to do as I say”</i>
                        </p>
                        <form className={classes.centered} onClick={this.forgetNumberHandler}>
                            <h4 className={classes.storyButtonSize}> Deeper & Deeper I Go</h4>
                        </form>
                    </div>
                </html>
            )
        }
        if (this.state.forgetNumber) {
            HypnosisShowForm = (
                <ForgetNumber />
            )
        }


        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {HypnosisShowForm}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >




        )
    }
}


export default HypnosisShowBeforeDeepener