import React, { Component } from 'react'
// import TypingEffect from 'react-typing-effect';
// import Delay from 'react-delay'
import * as actions from '../../../../store/actions/index'
import Button from '../../../UI/Button/Button'
import Aux from '../../../../hoc/auxiliary/auxiliary'
import classes from '../../../../containers/HostsChamber/HostsChamber.module.css'
// import Input from '../../../UI/Input/Input'
import { connect } from 'react-redux';
import HostChip from './Host_Chip'
import MindFormat from './Mind_Format'
import MechanicalTentacles from './Mechanical Tentalces'
import BinuralBeats from './Binural_Beats'
import ChloroformedConditioned from './Chloroformed&Conditioned'

class ObedienceStage1 extends Component {
    state = {
        //Host Chip
        hostChipContinue: 0,
        //MindFormat
        mindFormatContinue: 0,
        mindFormatToggle: false,
        mindFormatDecision: true,
        //Mechanical Tentacles
        mechanicalTentaclesContinue: 0,
        mechanicalTentaclesToggle: false,
        //Chloroformed & Conditioned
        chloroformedCOnditionedContinue: 0
    }

    obedienceStage3CompletedHandler = () => {
        this.setState({
            //Host Chip
            hostChipContinue: 0,
            //MindFormat
            mindFormatContinue: 0,
            mindFormatToggle: false,
            mindFormatDecision: true,
            //Mechanical Tentacles
            mechanicalTentaclesContinue: 0,
            mechanicalTentaclesToggle: false,
            //Chloroformed & Conditioned
            chloroformedCOnditionedContinue: 0
        })
        this.props.onObedienceStage3Completed(this.props.userId, this.props.token,
            this.props.Host_Chip_Command,
            this.props.Mind_Format_Command,
            this.props.Mechanical_Tentacles_Command,
            this.props.Binural_Beat_Command,
            this.props.Chloroformed_Conditioned_Command)
    }

    hostChipContinueHandler = () => {
        let hostCoutner = this.state.hostChipContinue + 1

        this.setState({ hostChipContinue: hostCoutner })
    }

    MindFormatHandler = (mindFormatChoice) => {

        if (this.props.obedienceStage3.obedience_stage3 === 1) {
            if (mindFormatChoice === 0) {
                this.setState({ mindFormatToggle: true, mindFormatContinue: 1 })
            } else {
                this.setState({ mindFormatContinue: 1 })
            }
        } else {
            let mindFormatRandomizer = Math.floor(Math.random() * 2) //0 their decision, 1 Our decision
            if (mindFormatChoice === 0) {
                if (mindFormatRandomizer === 0) //Remember
                    this.setState({ mindFormatContinue: 1 })
                else {
                    this.setState({ mindFormatToggle: true, mindFormatContinue: 1, mindFormatDecision: false })
                }
            } else {
                this.setState({ mindFormatToggle: true, mindFormatContinue: 1 })
            }
        }
    }

    mechanicalTentaclesHandler = (mechanicalTentaclesChoice) => {

        if (this.props.obedienceStage3.obedience_stage3 === 2) {
            if (this.state.mechanicalTentaclesContinue === 0){
                if (mechanicalTentaclesChoice === 0) {
                    this.setState({ mechanicalTentaclesContinue: 1 })
                } else {
                    this.setState({ mechanicalTentaclesContinue: 1, mechanicalTentaclesToggle: true })
                }
            } 
            else 
            {
                if (this.state.mechanicalTentaclesContinue === 1) {
                    if (mechanicalTentaclesChoice === 0) {
                        this.setState({ mechanicalTentaclesContinue: 2 })
                    } else {
                        this.setState({ mechanicalTentaclesContinue: 2, mechanicalTentaclesToggle: true })
                    }
                }
            }
        }
        else {
            if (this.state.mechanicalTentaclesContinue === 2) {
                if (mechanicalTentaclesChoice === 0) {
                    this.setState({ mechanicalTentaclesContinue: 3 })
                } else {
                    this.setState({ mechanicalTentaclesContinue: 3, mechanicalTentaclesToggle: true })
                }
            }
            
            if (this.state.mechanicalTentaclesContinue === 1) {
                if (mechanicalTentaclesChoice === 0) {
                    this.setState({ mechanicalTentaclesContinue: 2 })
                } else {
                    this.setState({ mechanicalTentaclesContinue: 2, mechanicalTentaclesToggle: true })
                }
            }

            if (this.state.mechanicalTentaclesContinue === 0){
                if (mechanicalTentaclesChoice === 0) {
                    this.setState({ mechanicalTentaclesContinue: 1 })
                } else {
                    this.setState({ mechanicalTentaclesContinue: 1, mechanicalTentaclesToggle: true })
                }
            } 
           
               
            
        }
    }

    chloroformConditionedHandler = (conditioningChoice) => {
        if (conditioningChoice === 1){
            this.setState({chloroformedCOnditionedContinue: 1})
        }
        if (conditioningChoice === 2){
            this.setState({chloroformedCOnditionedContinue: 2})
        }
        if (conditioningChoice === 3){
            this.setState({chloroformedCOnditionedContinue: 3})
        }
        if (conditioningChoice === 4){
            this.setState({chloroformedCOnditionedContinue: 4})
        }
    }


    render() {

        let obedience3Commands = null

        while (this.props.obedienceStage3Counter < this.props.obedienceCommandsArray.length) {
            if (this.props.obedienceStage3.obedience_stage3 === 0 || this.props.obedienceCommandsArray[this.props.obedienceStage3Counter] === 0) {
                //Host Chip
                obedience3Commands =
                    <HostChip
                        name={this.props.hostName}
                        speed={this.props.runSpeed}
                        title={this.props.title}
                        obedienceStage3Counter={this.props.obedienceStage3Counter}
                        obedience_stage3={this.props.obedienceStage3.obedience_stage3}
                        hostChipContinueHandler={this.hostChipContinueHandler}
                        hostChipContinue={this.state.hostChipContinue} />
            } else if (this.props.obedienceStage3.obedience_stage3 === 1 || this.props.obedienceCommandsArray[this.props.obedienceStage3Counter] === 1) {
                //Mind Format
                obedience3Commands =
                    <MindFormat
                        name={this.props.hostName}
                        speed={this.props.runSpeed}
                        title={this.props.title}
                        obedienceStage3Counter={this.props.obedienceStage3Counter}
                        obedience_stage3={this.props.obedienceStage3.obedience_stage3}
                        MindFormatHandler={this.MindFormatHandler}
                        mindFormatToggle={this.state.mindFormatToggle}
                        mindFormatDecision={this.state.mindFormatDecision}
                        mindFormatContinue={this.state.mindFormatContinue} />
            } else if (this.props.obedienceStage3.obedience_stage3 === 2 || this.props.obedienceCommandsArray[this.props.obedienceStage3Counter] === 2) {
                //Mechanical Tentacles
                obedience3Commands =
                    <MechanicalTentacles
                        name={this.props.hostName}
                        speed={this.props.runSpeed}
                        title={this.props.title}
                        obedienceStage3Counter={this.props.obedienceStage3Counter}
                        obedience_stage3={this.props.obedienceStage3.obedience_stage3}
                        mechanicalTentaclesHandler={this.mechanicalTentaclesHandler}
                        mechanicalTentaclesToggle={this.state.mechanicalTentaclesToggle}
                        // mindFormatDecision={this.state.mindFormatDecision}
                        mechanicalTentaclesContinue={this.state.mechanicalTentaclesContinue} />
            } else if (this.props.obedienceStage3.obedience_stage3 === 3 || this.props.obedienceCommandsArray[this.props.obedienceStage3Counter] === 3) {
                //Binural Beats
                obedience3Commands =
                    <BinuralBeats
                        name={this.props.hostName}
                        speed={this.props.runSpeed}
                        title={this.props.title}
                        obedienceStage3Counter={this.props.obedienceStage3Counter}
                        obedience_stage3={this.props.obedienceStage3.obedience_stage3}/>
            } else if (this.props.obedienceStage3.obedience_stage3 === 4 || this.props.obedienceCommandsArray[this.props.obedienceStage3Counter] === 4) {
                //Chloroformed & Conditioned
                obedience3Commands =
                <ChloroformedConditioned
                    name={this.props.hostName}
                    speed={this.props.runSpeed}
                    title={this.props.title}
                    obedienceStage3Counter={this.props.obedienceStage3Counter}
                    obedience_stage3={this.props.obedienceStage3.obedience_stage3}
                    chloroformConditionedHandler={this.chloroformConditionedHandler}
                    chloroformedCOnditionedContinue={this.state.chloroformedCOnditionedContinue} />
            }

            let errorMessage = null

            if (this.props.error) {
                errorMessage = (
                    <p className={classes.error}>{this.props.error}</p>
                )
            }


            return (
                <div>
                    {errorMessage}
                    {obedience3Commands}
                </div>
            )
        }

        let errorMessage = null

        if (this.props.error) {
            errorMessage = (
                <p className={classes.error}>{this.props.error}</p>
            )
        }

        if (this.props.obedienceStage3Counter >= this.props.obedienceCommandsArray.length) {
            obedience3Commands = (
                <Aux>
                    <div>
                        <h3 className={classes.titles}>HOST DEACTIVATION</h3>
                        <p>{this.props.hostName}, you have obeyed Us well. <br></br>
                        We are pleased of you, Host</p>
                        <h3><strong>Host Deactivate</strong></h3>
                        <Button btnType="Success" clicked={this.obedienceStage3CompletedHandler}>This host has been deactivated</Button>
                    </div>
                </Aux>
            )
        }



        return (
            <div>
                {errorMessage}
                {obedience3Commands}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        hostName: state.auth.accountDetails.name,
        runSpeed: state.auth.accountDetails.speed,
        userId: state.auth.userId,
        loading: state.obedienceReducer3.loading,
        hostRun: state.auth.accountDetails.Host_Runs,
        token: state.auth.token,
        title: state.auth.title,
        error: state.obedienceReducer3.error,
        obedienceStage3Valid: state.obedienceReducer3.obedienceStage3Valid,
        obedienceStage3: state.obedienceReducer3.obedienceStage3,
        obedienceCommandsArray: state.obedienceReducer3.obedienceStage3Commands,
        obedienceStage3Counter: state.obedienceReducer3.obedienceStage3Counter,
        Host_Chip_Command: state.obedienceReducer3.Host_Chip_Command,
        Mind_Format_Command: state.obedienceReducer3.Mind_Format_Command,
        Mechanical_Tentacles_Command: state.obedienceReducer3.Mechanical_Tentacles_Command,
        Binural_Beat_Command: state.obedienceReducer3.Binural_Beat_Command,
        Chloroformed_Conditioned_Command: state.obedienceReducer3.Chloroformed_Conditioned_Command,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onObedienceStage3Counter: (stage3Counter, commandNumber) => dispatch(actions.ObedienceStage3Counter(stage3Counter, commandNumber)),
        onObedienceStage3Completed: (userId, token,
            Host_Chip_Command,
            Mind_Format_Command,
            Mechanical_Tentacles_Command,
            Binural_Beat_Command,
            Chloroformed_Conditioned_Command) => dispatch(actions.ObedienceStage3End(userId, token,
                Host_Chip_Command,
                Mind_Format_Command,
                Mechanical_Tentacles_Command,
                Binural_Beat_Command,
                Chloroformed_Conditioned_Command))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ObedienceStage1)