import React, { Component } from 'react'
// import Input from '../../../components/UI/Input/Input'
import Button from '../../../components/UI/Button/Button'
import classes from '../Account.module.css'
import Aux from '../../../hoc/auxiliary/auxiliary';
import * as actions from '../../../store/actions/index';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { connect } from 'react-redux'
import HostRun from '../../../components/Account/Runs/HostRuns'
import ConditioningStages from './Conditioning_Stages';
import ConditioningProtocol from './Conditioning_Protocol';
import TriggerIntroduction from './Trigger_Introduction';
import TriggerReinforcement from './Trigger_Reinforcement'

class Runs extends Component {

    viewDetailRunHandler = (event) => {
        event.preventDefault();
        this.props.onViewRunDetails(this.props.token, this.props.userId)
    }

    viewHostRunDetailHandler = (event) => {
        event.preventDefault();
        this.props.onViewHostRunDetails(this.props.token, this.props.userId)
    }

    viewConditioningStagesHandler = (event) => {
        event.preventDefault();
        this.props.onViewConditioningStages()
    }

    viewConditioningProtocolsHandler = (event) => {
        event.preventDefault();
        this.props.onViewConditioningProtocols()
    }

    viewTriggerIntroduction = (event) => {
        event.preventDefault()
        this.props.onViewTriggerIntroduction()
    }

    viewTriggerReinforcement = (event) => {
        event.preventDefault()
        this.props.onViewTriggerReinforcement()
    }

    ReturnBackHandler = (event) => {
        event.preventDefault();
        this.props.onReturnBack()
    }

    render() {

        let form = null

        

        if (this.props.viewRunDetails) {
            form = (<div className={classes.AccountContainer}>
                <form className={classes.ViewProfile}>
                    <h2>Select Run</h2>
                    <div className={classes.AuthButtons}>
                        <Button btnType="Success" clicked={this.viewHostRunDetailHandler}>Host Run <br></br>Details</Button><br></br>
                        <Button btnType="Success" clicked={this.viewConditioningStagesHandler}>Condtioning Stages<br></br>Details</Button> <br></br>
                        <Button btnType="Success" clicked={this.viewConditioningProtocolsHandler}>Condtioning Protocol<br></br>Details</Button> <br></br>
                        <Button btnType="Success" clicked={this.viewTriggerIntroduction}>Trigger Introduction<br></br>Details</Button> <br></br>
                        <Button btnType="Success" clicked={this.viewTriggerReinforcement}>Trigger Reinforcement<br></br>Details</Button> <br></br>
                        <Button btnType="Danger" clicked={this.ReturnBackHandler}>Back</Button>
                    </div>
                </form>
            </div>
            )
        }

        if (this.props.viewHostRun) {
            form =
                <HostRun 
                    viewHostRunDetails = {this.props.viewHostRunDetails} 
                    ReturnBackHandler = {this.ReturnBackHandler}/>
        }

        if(this.props.viewConditioningStages){
            form = <ConditioningStages />
        }

        if(this.props.viewConditioningProtocols){
            form = <ConditioningProtocol />
        }
        
        if(this.props.viewTriggerIntroduction){
            form = <TriggerIntroduction />
        }

        if(this.props.viewTriggerReinforcement){
            form = <TriggerReinforcement />
        }


        if (this.props.loading) {
            form = <Spinner className={classes.Spinner} />
        }

        let errorMessage = null

        if (this.props.error) {
            errorMessage = (
                <p className={classes.error}>{this.props.error}</p>
            )
        }

        return (
            <Aux>
                <div className={classes.Account}>
                    {errorMessage}
                    {form}
                </div>
            </Aux >

        );
    }
}

const mapStatesToProps = state => {
    return {
        loading: state.runs.loading,
        error: state.auth.error,
        token: state.auth.token,
        userId: state.auth.userId,
        accountDetails: state.auth.accountDetails,
        authRedirectValid: state.auth.authRedirectValid,
        viewDetails: state.runs.viewDetails,
        viewRunDetails: state.runs.viewRunDetails,
        viewConditioningStages: state.runs.viewConditioningStages,
        viewConditioningProtocols: state.runs.viewConditioningProtocols,
        viewTriggerIntroduction: state.runs.viewTriggerIntroduction,
        viewTriggerReinforcement: state.runs.viewTriggerReinforcement,
        viewHostRun: state.runs.viewHostRunDetails,
        viewHostRunDetails: state.runs.viewHostRunDetailsArray,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onViewRunDetails: () => dispatch(actions.viewRunDetails()),
        onViewHostRunDetails: (token, userId) => dispatch(actions.viewHostRunDetail(token, userId)),
        onViewConditioningStages: () => dispatch(actions.ViewConditioningStages()),
        onViewConditioningProtocols: () => dispatch(actions.ViewConditioningProtocols()),
        onViewTriggerIntroduction: () => dispatch(actions.viewTriggerIntroduction()),
        onViewTriggerReinforcement: () => dispatch(actions.viewTriggerReinforcement()),
        onReturnBack: () => dispatch(actions.returnBackSuccess()),
        onLogout: () => dispatch(actions.logout())
    }
}

export default connect(mapStatesToProps, mapDispatchToProps)(Runs);