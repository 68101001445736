import React, { Component } from 'react';
import Aux from '../../../../hoc/auxiliary/auxiliary';
import classes from '../HypnosisShowMenu.module.scss'
// import classes2 from '../ExperienceMenu/ExperienceMenu.module.scss'
import HypnosisShow from '../../../../assets/Images/Blank.png'
import Induction from './HypnosisShowNonVolInduction'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'

class HypnosisShowSusceptibiltyTest extends Component {

    state = {
        susceptibiltyTest: true,
        induction: false
    }

    InductionHandler = () => {
        this.setState({ susceptibiltyTest: false, induction: true })
    }


    render() {
        let HypnosisShowForm = null
        if (this.state.susceptibiltyTest) {
            HypnosisShowForm = (
                <html style={{ backgroundImage: `url(${HypnosisShow})` }}>
                    <div className={classes.storyFrom}>
                        <p>
                            That sentence gets you quite tingly, feeling butterflies while you look up stage focusing completely on The Great O. Following his every move as he starts to say <br></br>
                            <i>"We start with a test. This test aims to measure your susceptibility & how you respond to hypnosis. How you react to it is not an indication of whether you're a good subject or not<br></br>
                            Let's start, I’d like you to raise your hands straight in front of you, focus on the palm of your hands”</i><br></br>
                            He turns around to face you which makes you feel goosebumps as you believe he's looking directly at you & only you. 
                            His gaze captures you, you know deep down he's not looking at you but you can't help but feel caught in his eyes and follow him. <br></br> 
                            <i>"You're all welcome to try this out as well. Put your hands straight in front of you & focus on the palm of your hands"</i><br></br>
                            <i>"Now..." </i>He says while turning to face the volunteers. <i>"Focus on your right hand, feeling yourself holding a bucket of water. 
                                This bucket keeps getting heavier and heavier and heavier with each passing second. <br></br>
                            In the other hand you feel 2 balloons tied to your wrist. Slowly raising your hand higher as a third one is tied up, then a fourth & a fifth. 
                            Each balloon raising your hand higher & higher. Ten balloons now tied to your wrist raising it up high."</i> <br></br>
                            Caught in his words & voice. You only notice now that your right hand is falling more and more while your right hand is rising & floating straight up in the air. 
                            Your eyes wide open in shock of how effective his words are, that it takes you a second to notice he started talking again <br></br>
                            <i>“Once I snap my fingers you'll find the water bucket disappearing along with all Ten balloons. 
                                Your right hand free of that weight pulling it down while your left hand drops instantly tired from staying up in the air for that long. <br></br>
                                They disappear in 3, 2, &… 1”</i><br></br>
                            <strong>*SNAP*</strong><br></br>
                            You shake your hands as both the bucket and balloons instantly disappear. 
                            Taking a second to process what just happened and how effective his words were. 
                            Sitting up in your chair and watching him moving on stage, leaning next to each volunteer and saying something that is inaudible to the audience. <br></br> 
                            You're so curious trying to figure out what he might be telling them, your mind wondering off to how it would feel to be in their place feeling him this close and hearing his voice right whispering in your ears.<br></br>
                            Once he's done going to every volunteer you notice that most of them have quite the relaxed & calm expression on their faces which in turn makes you feel quite relaxed & calm too. Especially when he starts talking and say <br></br>
                            <i>"I can see a few of you out there enjoyed this test as well, perfect! Now is the time for what you've all came here to see, now is the time for hypnosis"</i><br></br>
                            He looks at the volunteers continuing <br></br>
                            <i>"Are you ready?"</i><br></br>
                            Capturing yourself nodding, excited for what comes next. Excited for the...
                        </p>
                        <form className={classes.centered} onClick={this.InductionHandler}>
                            <h4 className={classes.storyButtonSize}>Induction</h4>
                        </form>
                    </div>
                </html>
            )
        }
        if (this.state.induction) {
            HypnosisShowForm = (
                <Induction />
            )
        }

        // if (this.state.non_volunter) {
        //     HypnosisShowForm = (
        //         <DontVolunteer />
        //     )
        // }

        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {HypnosisShowForm}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >




        )
    }
}


export default HypnosisShowSusceptibiltyTest