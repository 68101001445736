import * as actionTypes from '../../actions/Account/authTypes';
import { updateObject } from '../../../shared/utility';

const initialState = {
    titles: [],
    title: null,
    viewTitle: false,
    loading: false,
    error: null
}

const Title_Success = (state, action) => {
    return updateObject(state, {
        titles: action.Titles,
        error: null
    })
}

const Title_Fail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error
    })
}


const viewTitle = (state, action) => {
    return updateObject(state, {
        viewTitle: true,
        loading: false,
        error: null,
    })
}

const fetchTitle = (state, action) => {
    return updateObject(state, {
        title: action.Title
    })
}

const changeTitle = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: null,
        viewTitle: false
    })
}



const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.VIEW_TITLE: return viewTitle(state, action)
        case actionTypes.VIEW_TITLE_SUCCESS: return Title_Success(state, action)
        case actionTypes.VIEW_TITLE_FAIL: return Title_Fail(state, action)
        case actionTypes.GET_TITLE: return fetchTitle(state, action)
        case actionTypes.CHANGE_PROFILE_TITLE: return changeTitle(state, action)
        default:
            return state

    }
}


export default reducer