import React, { Component } from 'react';
import Aux from '../../hoc/auxiliary/auxiliary'
// import LoadingScreen from '../../components/UI/LoadingScreens/LoadingScreens'
import Toolbar from '../../components/Navigation/Toolbar/Toolbar';
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer'
import Button from '../../components/UI/Button/Button'
import classes from './Layout.module.css'
import Spinner from '../../components/UI/Spinner/Spinner'
import { updateObject, checkValidity } from '../../shared/utility'
import { connect } from 'react-redux'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'
import Visuals from '../Visuals/Visuals';
import ExperienceMenu from '../../MindMeltingExperience/components/ExperienceMenu/ExperienceMenu';
import PasswordDatabase from '../../components/Account/Password_Database';
import * as actions from '../../store/actions/index';


class Layout extends Component {
    state = {
        controls: {
            password: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Password'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 6
                },
                valid: false,
                touched: false
            },
        },
        loading: true,
        disclaimer: false,
        mindMeltingExperience: false,
        PasswordEntered: false,
        hypnoVirusView: false,
        showSideDrawer: false,
        enterMindMeltingExperience: false,
        // loadingText: 'Establishing Connection...\nSetting Up Environment...\nActivating The Hypno Virus...\nConnection Established...'
    };

    submitHandler = (event) => {
        event.preventDefault();
        this.props.onPasswordAuth(this.state.controls.password.value)
        if (this.props.PasswordSubmitted) {
            this.setState({ enterMindMeltingExperience: true })
        }

    }

    // loadingFinish = () => {
    //     this.setState({ loading: false })
    // }

    disclaimerHandler = () => {
        this.setState({ loading: false, disclaimer: true })
    }

    entryDenyHandler = () => {
        window.location.href = "https://www.google.com"
    }

    sideDrawerClosedHandler = () => {
        this.setState({ showSideDrawer: false });
    }

    MindMeltingViewHandler = () => {
        this.setState({ mindMeltingExperience: true });
        if (this.props.isAuthenticated) {
            this.setState({ enterMindMeltingExperience: true })
        }
        
    }

    HypnoVirusViewHandler = () => {
        this.setState({ disclaimer: false, hypnoVirusView: true });
    }

    returnMainPageHandler = () => {
        this.setState({ disclaimer: true, mindMeltingExperience: false, hypnoVirusView: false });
    }

    sideDrawerToggleHandler = () => {
        this.setState((prevState) => {
            return { showSideDrawer: !prevState.showSideDrawer };
        });
    }

    inputChangedHandler = (event, controlName) => {


        const updatedControls = updateObject(this.state.controls, {
            [controlName]: updateObject(this.state.controls[controlName], {
                ...this.state.controls[controlName],
                value: event.target.value,
                valid: checkValidity(event.target.value, this.state.controls[controlName].validation),
                touched: true
            })
        })
        let PasswordEntered = true
        for (let controlName in updatedControls) {
            PasswordEntered = updatedControls[controlName].valid && PasswordEntered;
        }
        this.setState({ controls: updatedControls, PasswordEntered: PasswordEntered })
    }

    render() {

        const formElementsArray = [];
        for (let key in this.state.controls) {
            formElementsArray.push({
                id: key,
                config: this.state.controls[key]
            });
        }

        let screenView;
        // if (this.state.loading || this.props.isAuthenticated) {
        //     screenView = (
        //         <Aux>
        //             <LoadingScreen loadingVirusClicked={() => this.loadingFinish()} textLoad={this.state.loadingText} count={this.state.loadingText.length} />
        //         </Aux>
        //     )
        // }
        if (this.state.loading ) {
            screenView = (
                <Aux>
                    <div className={classes.Disclaimer}>
                        <Spinner />
                        <h3 className={classes.headline}>THE HYPNO VIRUS ONLINE</h3>
                        <p>Please read and comply with the following conditions before you continue:  This website contains information and links of sexually explicit material<br></br>
                            Do NOT continue if:<br></br>
                            1) you are not at least 18 years of age or the age of majority in each and every jurisdiction in which you will or may view the Sexually Explicit Material, whichever is higher (the "Age of Majority)<br></br>
                            2) such material offends you <br></br>
                            3) viewing the Sexually Explicit Material is not legal in each and every community where you may view it.</p>
                        <Button btnType="Success" clicked={this.disclaimerHandler}> I have read and agree to these terms</Button>
                        <Button btnType="Danger" clicked={this.entryDenyHandler}> I disagree and want to leave</Button>
                    </div>
                </Aux>

            )

        }

        

        if (this.state.disclaimer || this.props.isAuthenticated) {
            screenView = (
                <Aux>
                    <div className={classes.Disclaimer}>
                        <Button btnType="Success" clicked={this.MindMeltingViewHandler}>The Mind Melting Experience</Button>
                        <Button btnType="Success" clicked={this.HypnoVirusViewHandler}>The Hypno Virus</Button>
                    </div>
                </Aux>
            )
        } 

        if (this.state.mindMeltingExperience ) {
            screenView = (
                <Aux>
                    <div className={classes.Disclaimer}>
                        <PasswordDatabase
                            formElementsArray={formElementsArray}
                            inputChangedHandler={this.inputChangedHandler}
                            PasswordEntered={this.state.PasswordEntered}
                            PasswordSubmitted={this.props.PasswordSubmitted}
                            submitHandler={this.submitHandler} />
                        <Button btnType="Danger" clicked={this.returnMainPageHandler}>Return to Main Page</Button>
                    </div>
                </Aux >
            )
        }

        

        if (this.state.enterMindMeltingExperience) {
            screenView = (
                <Aux>
                    <ExperienceMenu/>
                </Aux >
            )
        }

        if (this.state.hypnoVirusView) {
            screenView = (
                <Aux>
                    <Toolbar
                        isAuth={this.props.isAuthenticated}
                        drawerToggleClicked={this.sideDrawerToggleHandler} />
                    <SideDrawer
                        isAuth={this.props.isAuthenticated}
                        open={this.state.showSideDrawer}
                        closed={this.sideDrawerClosedHandler} />
                    <main className={classes.Content}>
                        {this.props.children}
                    </main>

                </Aux>
            )
        }
        if (this.props.visuals) {
            screenView = (
            <div className={classes.visuals}>
                <Visuals />
            </div>
            )
        }
        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>

                            {screenView}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >
            </Aux>
        )
    }
}

const mapStateToProps = state => {
    return {
        password: state.auth.password,
        PasswordSubmitted: state.auth.PasswordSubmitted,
        visuals: state.homePage.hypnoVisual,
        isAuthenticated: state.auth.token !== null
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onPasswordAuth: (password) => dispatch(actions.passwordAuth(password)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Layout);