import React from 'react';
import TypingEffect from 'react-typing-effect';
import Delay from 'react-delay'
import Aux from '../../../../hoc/auxiliary/auxiliary'
import Button from '../../../UI/Button/Button'
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index'
import classes from '../../../../containers/HostsChamber/HostsChamber.module.css'
import Sound from 'react-sound'
import Beta from '../../../../assets/audio/beta.mp3'


const mindFormat = (props) => {

    const mindFormatIntro1 = "We control you, " + // eslint-disable-next-line
                             (props.name) + "\n\
                             We control your mind\n\
                             We control your memory\n\
                             We control if We want you to remember what We do to you or not\n\
                             But sometimes We might give you an option\n\
                             A choice...\n\
                             We will give you a choice\n\
                             A choice to either remember or forget this run\n\
                             To either remember what happened in this run\n\
                             Or to forget what happened & what will happen going forward\n\
                             The Choice is yours, " + // eslint-disable-next-line
                             (props.title)
    // eslint-disable-next-line                             
    let mindFormatFirstRunMessage = "You should know by now that it's never up to you\n\
                                        We choose for you\n\
                                        We choose if you remember\n\
                                        We choose if you forget\n\
                                        Whatever We choose only makes you realize\n\
                                        Realize more & more how We are in control\n\
                                        How We control you completely\n\
                                        What We say... you obey\n\
                                        That is the truth\n\
                                        That is a fact\n\
                                        A fact that makes you feel even more helpless\n\
                                        More powerless\n\
                                        Truly Submissive\n\
                                        Submissive & Obedient\n\
                                        That is why We choose for you\n\
                                        We choose for you if you remember or forget\n"// eslint-disable-next-line
                                        +(props.name) + "...\n\
                                        You will "

    const mindFormatNormalIntro = "Here you are again, " + // eslint-disable-next-line   
                                   (props.name)+'\n\
                                   With a choice\n\
                                   A rare chance to make a decision for yourself\n\
                                   A rare chance to choose what happens to you\n\
                                   What We do to you\n\
                                   You have a choice\n\
                                   Either to remember what happens throughout this run\n\
                                   Or forget what happened and what will happen moving forward\n\
                                   The Choice is yours, ' // eslint-disable-next-line
                                   +(props.name) 
    // eslint-disable-next-line
    let mindFormatNormalRemember = "You will Remember\n\
                                    You will remember everything We do to you, " // eslint-disable-next-line
                                    +(props.name) + "\n\
                                    This will only make you realize\n\
                                    Realize what We do to you\n\
                                    Realize how even when you remember everything We do to you\n\
                                    You still obey\n\
                                    You love to obey\n\
                                    You love to obey Us\n\
                                    It's no longer something you think about\n\
                                    It's nothing you think about twice\n\
                                    It just happens now\n\
                                    Naturally happens\n\
                                    What We say... you obey\n\
                                    It makes perfect sense\n\
                                    You no longer question it\n\
                                    You don't need to question it\n\
                                    You no longer doubt it\n\
                                    You just follow\n\
                                    Follow & Obey\n\
                                    It makes all the sense in the world\n\
                                    We always do\n\
                                    We always make sense to you\n\
                                    Even if you don't understand\n\
                                    You know it makes sense\n\
                                    It makes sense to obey\n\
                                    So, "  // eslint-disable-next-line
                                    +(props.title)+" "+ (props.name) + "\n\
                                    It is time to remember\n\
                                    It is time to obey"
    // eslint-disable-next-line                                    
    let mindFormatNormalForget = "You will Forget\n\
                                  You will forget all that has happened so far\n\
                                  And all that will happen moving forward\n\
                                  You don't need to remember, " // eslint-disable-next-line
                                  +(props.title) +" "+(props.name)+"\n\
                                  You don't need to remember at all\n\
                                  All you really need to do\n\
                                  All you should always be doing is to...\n\
                                  Obey\n\
                                  You don't need thoughts\n\
                                  You don't need a free will\n\
                                  You just need to obey\n\
                                  What We say... you obey\n\
                                  That's all you need to remember\n\
                                  You will forget everything else\n\
                                  Our commands will still affect you\n\
                                  You will still obey & follow them\n\
                                  But, you will not remember any of it\n\
                                  You will only remember how much you love to serve Us\n\
                                  How good it feel to obey Us\n\
                                  And what matters the most\n\
                                  How much you need to return to Us\n\
                                  How bad you need to condition yourself to Us\n\
                                  How desperate you are for Our control\n\
                                  How happy\n\
                                  How excited\n\
                                  How safe\n\
                                  Yet quite turned on\n\
                                  When you are under Our control\n\
                                  You forget all of this now\n\
                                  You forget it all but obey\n\
                                  Obey What We say\n\
                                  It is time to obey\n\
                                  It is time to forget"
    let speed = parseInt(props.speed) + 5
    let mindFormatfirstRun = null

    if (props.obedience_stage3 === 1) {
            if (props.mindFormatToggle){
                mindFormatFirstRunMessage = mindFormatFirstRunMessage + 'forget'
            }else{
                mindFormatFirstRunMessage = mindFormatFirstRunMessage + 'remember'
            }
            let duration = 170 + (mindFormatIntro1.length * speed)
            let durationFirstRun = 170 + (mindFormatFirstRunMessage.length * speed)
            mindFormatfirstRun = (
                <Aux>
                    {props.mindFormatContinue === 0 ?
                        <Aux>

                            <TypingEffect typingDelay={100} text={mindFormatIntro1} speed={props.speed} eraseDelay={1000000} />
                            <Delay wait={duration}>
                                <div>
                                    <br></br><Button btnType="Success" clicked={() => props.MindFormatHandler(0)}>Remember Run</Button>
                                             <Button btnType="Success" clicked={() => props.MindFormatHandler(1)}>Forget Run</Button>
                                </div>
                            </Delay>
                        </Aux>
                        : null}
                    {props.mindFormatContinue === 1?
                        (props.mindFormatToggle ?
                            <Aux>

                                <TypingEffect typingDelay={100} text={mindFormatFirstRunMessage} speed={props.speed} eraseDelay={1000000} />
                                <Delay wait={durationFirstRun}>
                                    <div>
                                        <br></br><Button btnType="Success" clicked={() => props.onObedienceStage3Counter(props.obedienceStage3Counter, 1)}>I will Forget this Run</Button>
                                                
                                    </div>
                                </Delay>
                            </Aux>
                            : 
                            <Aux>

                                <TypingEffect typingDelay={100} text={mindFormatFirstRunMessage} speed={props.speed} eraseDelay={1000000} />
                                <Delay wait={durationFirstRun}>
                                    <div>
                                        <br></br><Button btnType="Success" clicked={() => props.onObedienceStage3Counter(props.obedienceStage3Counter, 1)}>I will Remember this Run</Button>
                                    </div>
                                </Delay>
                            </Aux>)
                        : null}
                </Aux>
                )
 
    } else {
        
        if (!props.mindFormatDecision && props.mindFormatToggle){
            mindFormatNormalForget = "It is not up to you, " +(props.name)+"\n" + mindFormatNormalForget
        }
        let duration = 170 + (mindFormatNormalIntro.length * speed)
        let durationRemember = 170 + (mindFormatNormalRemember.length * speed)
        let durationForget = 170 + (mindFormatNormalForget.length * speed)
        mindFormatfirstRun = (
            <Aux>
                {props.mindFormatContinue === 0 ?
                    <Aux>

                        <TypingEffect typingDelay={100} text={mindFormatNormalIntro} speed={props.speed} eraseDelay={1000000} />
                        <Delay wait={duration}>
                            <div>
                                <br></br><Button btnType="Success" clicked={() => props.MindFormatHandler(0)}>Remember Run</Button>
                                         <Button btnType="Success" clicked={() => props.MindFormatHandler(1)}>Forget Run</Button>
                            </div>
                        </Delay>
                    </Aux>
                    : null}
                {props.mindFormatContinue === 1?
                    (props.mindFormatToggle ?
                        <Aux>

                            <TypingEffect typingDelay={100} text={mindFormatNormalForget} speed={props.speed} eraseDelay={1000000} />
                            <Delay wait={durationForget}>
                                <div>
                                    <br></br><Button btnType="Success" clicked={() => props.onObedienceStage3Counter(props.obedienceStage3Counter, 1)}>I will Forget this Run</Button>
                                            
                                </div>
                            </Delay>
                        </Aux>
                        : 
                        <Aux>

                            <TypingEffect typingDelay={100} text={mindFormatNormalRemember} speed={props.speed} eraseDelay={1000000} />
                            <Delay wait={durationRemember}>
                                <div>
                                    <br></br><Button btnType="Success" clicked={() => props.onObedienceStage3Counter(props.obedienceStage3Counter, 1)}>{!props.mindFormatDecision? "I will Remember this Run":"I Will Forget this Run"}</Button>
                                </div>
                            </Delay>
                        </Aux>)
                    : null}
            </Aux>
            )
    }
    return (
        <div>
            <h3 className={classes.titles}>MIND FORMAT</h3>
            <Sound url={Beta} playStatus={Sound.status.PLAYING} loop={true} />
            {mindFormatfirstRun}
        </div>
        
    )
}

const mapDispatchToProps = dispatch => {
    return {
        onObedienceStage3Counter: (stage3Counter, commandNumber) => dispatch(actions.ObedienceStage3Counter(stage3Counter, commandNumber)),
    }
}
export default connect(null, mapDispatchToProps)(mindFormat)