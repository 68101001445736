import React, { useState } from 'react'
import TypingEffect from 'react-typing-effect'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import classes from '../../TextToSpeech.module.css';
import { connect } from 'react-redux'
import Button from '../../../../UI/Button/Button'
import Delay from 'react-delay'
import { useSpeech, useRecognition } from 'react-web-voice';
import ControlCountdown from './controlCountdown';
import ControlBondage from './controlBondage'
import ControlAffirmation from './controlAffirmation'
import ControlBrainHack from './controlBrainHack'

const Control = (props) => {
    const [messageToSpeak, setMessageToSpeak] = React.useState('');
    // const [startConv, setConvState] = useState(false)
    const [isListening, setIsListening] = useState(false);
    // const [endConv, setEndConv] = useState(false);
    const [controlStage, setControlStage] = useState(0);
    // const [prevControlStage, setPrevControlStage] = useState(0);
    // eslint-disable-next-line
    const { transcripts, listen } = useRecognition();
    // eslint-disable-next-line
    const controlYes = 'You get to choose between several different methods of control ' + (props.name) + "\n\
                        You can choose the countdown method, the bondage method or more\n\
                        With the countdown method We start counting down until We reach the number 1\n\
                        Once We reach the number 1 you will drop\n\
                        As We reinforce Our control & conditioning\n\
                        While with the bondage method We tie you up with Our wires\n\
                        Conditioning you to feel submissive & docile when you are bound\n\
                        Taking you into a deep trance where We show you how wonderful it is to be tied up by Us\n\
                        You get to choose between these and many more methods\n\
                        All you have to do is say the name of the method you want to experience\n\
                        So which will you choose " + (props.name) + "?"
    let duration = 200 + (controlYes.length * 63)
    // eslint-disable-next-line
    const controlNo = (props.name) + " you don't have a control in when We control you\n\
                      You only have a choice on how We control you\n\
                      Time to choose " + (props.name) + "\n" + controlYes
    let duration1 = 170 + (controlNo.length * 70)

    let countdownList = []
    for (let i = props.countdown; i >= 1; i--) {
        countdownList.push(i)
    }
    countdownList = countdownList.join("\n")
    //eslint-disable-next-line
    const countdownStart = "In this run We will countdown from " + String(props.countdown) + "\n\
                            With each number you feel Our words taking you under\n\
                            You feel Our words taking you deeper\n\
                            With each number you feel the bliss of falling under Our control\n\
                            Each number empties your mind\n\
                            Empties your mind from all worries\n\
                            Empties your mind from all care\n\
                            Focusing only on what We say\n\
                            Falling deeper & further with each number as We reach the number 1\n\
                            Once We do a bell will ring\n\
                            Dropping you\n\
                            Dropping you deep for Us\n\
                            After the bell rings you will click on the button and say\n\
                            'Submit'\n\
                            Now " + // eslint-disable-next-line
        (props.name) + " it is time for you to drop deep\n" + countdownList + "\n\
                            Submit"
    // eslint-disable-next-line
    const bondageStart = (props.name) + " We will tie you Up\n\
                        We will tie you up & bind you using Our special wires\n\
                        These wires will not only bind you\n\
                        They will take control over your body too\n\
                        Controlling it & making you automatically reply to Us whenever We ask you a question\n\
                        As We tie you up\n\
                        You feel your body relaxing\n\
                        Your mind starts getting fuzzy & floaty\n\
                        It becomes harder & harder to think\n\
                        So much easier to just follow & obey\n\
                        Are you ready " + (props.name) + "?"
    // eslint-disable-next-line
    const AffirmationStart = (props.name) + " You will only reply with 'Yes'\n\
                              As We ask you questions\n\
                              Talk to you\n\
                              State many facts & tell you the truth\n\
                              All you will do is confirm & agree with what We say\n\
                              You will find yourself nodding along with Our words\n\
                              Fully accepting & agreeing with what We say\n\
                              Are you ready " + (props.name) + "?"
    // eslint-disable-next-line
    const BrainHackStart = (props.name) + " We are going to hack into your mind\n\
                            We are going to hack into your mind and make sure you have the required modes to serve Us\n\
                            We are going to install a number of modes to your brain via a chip\n\
                            A chip you might have had installed previously\n\
                            But in any case We will make sure it is installed & up to date with all the required modes\n\
                            It is time for Us to hack your brain " + (props.name)
    // eslint-disable-next-line
    const unclear = 'You were unclear ' + (props.name) + "\n\
                     Reply with one of the following to continue\n\
                    'Countdown' 'Affirmative' 'Hack' or 'Bondage'"
    let duration7 = 170 + (unclear.length * 70)

    // eslint-disable-next-line
    const { messages, speak } = useSpeech({ voice: props.voice });

    const speakButtonHandler = async (text) => {
        setMessageToSpeak('');
        // eslint-disable-next-line
        const utterance = await speak({
            text: text,
            volume: 1,
            rate: 0.5,
            pitch: 0.01
        });
    };

    const speakClickHandler = async () => {
        setIsListening(true);
        const recognizedMessage = await listen();
        console.log(recognizedMessage)
        setTopicAnswerHandler(recognizedMessage)

    };


    const setTopicAnswerHandler = (recognizedMessage) => {
        setIsListening(false);
        if (String(recognizedMessage).toLowerCase() === 'yes' || String(messageToSpeak).toLowerCase() === 'yes') {
            if (controlStage === 0) {
                setControlStage(1)
                speakButtonHandler(String(controlYes))
            }
        } else if (String(recognizedMessage).toLowerCase() === 'no' || String(messageToSpeak).toLowerCase() === 'no') {
            if (controlStage === 0) {
                setControlStage(2)
                speakButtonHandler(String(controlNo))
            }
        } else if (String(recognizedMessage).toLowerCase() === 'countdown' || String(messageToSpeak).toLowerCase() === 'countdown') {
            if (controlStage === 1 || controlStage === 2 || controlStage === 5) {
                setControlStage(3)
                speakButtonHandler(String(countdownStart))
            }
        } else if (String(recognizedMessage).toLowerCase() === 'bondage' || String(messageToSpeak).toLowerCase() === 'bondage') {
            if (controlStage === 1 || controlStage === 2 || controlStage === 5) {
                setControlStage(4)
                speakButtonHandler(String(bondageStart))
            }
        } else if (String(recognizedMessage).toLowerCase() === 'affirmative' || String(messageToSpeak).toLowerCase() === 'affirmative') {
            if (controlStage === 1 || controlStage === 2 || controlStage === 5) {
                setControlStage(6)
                speakButtonHandler(String(AffirmationStart))
            }
        } else if (String(recognizedMessage).toLowerCase() === 'hack' || String(messageToSpeak).toLowerCase() === 'hack') {
            if (controlStage === 1 || controlStage === 2 || controlStage === 5) {
                setControlStage(7)
                speakButtonHandler(String(BrainHackStart))
            }
        } else {
            setControlStage(5)
            speakButtonHandler(String(unclear))
        }

    }


    let controlConversation = null

    controlConversation =
        <Aux>

            {controlStage === 0 ?
                <Aux>
                    <h3>Your control</h3>
                    <div>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={"Do you want Us to control you, " + props.name + " ?"} speed={50} eraseDelay={1000000} /></p>
                        </Delay>
                    </div>
                    <Delay wait={2000}>
                        {isListening ? (
                            <Aux>
                                <p>Say <strong>"Yes"</strong> or <strong>"No"</strong> to proceed</p>
                                <div>
                                    {/* <input
                                        onChange={messageInputHandler}
                                        className={classes.InputElement}
                                        type="text"
                                        // maxlength="200"
                                        placeholder="Type 'yes' or 'no'"
                                    />
                                 <Button btnType="Success" clicked={speakClickHandler}>Answer Now</Button> */}
                                </div>
                            </Aux>
                        ) : (
                            <Aux>
                                <div>
                                    <Button btnType="Success" clicked={() => setTopicAnswerHandler('yes')}>Yes, I want to be controlled</Button><br></br>
                                    <Button btnType="Danger" clicked={() => setTopicAnswerHandler('no')}>No, I don't want to be controlled</Button><br></br>
                                    <Button btnType="Success" clicked={speakClickHandler}>Speak</Button>
                                </div>
                            </Aux>
                        )}
                    </Delay>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
            {/* said yes to control question */}
            {controlStage === 1 ?
                <Aux>
                    <h3>Your control</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={controlYes} speed={55} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={800 + duration}>
                            {isListening ? (
                                <Aux>
                                    <p>Say <strong>"Countdown"</strong>, <strong>"Affirmative"</strong>, <strong>"Hack"</strong> or <strong>"Bondage"</strong> to proceed</p>
                                    <div>
                                        {/* <input
                                        onChange={messageInputHandler}
                                        className={classes.InputElement}
                                        type="text"
                                        // maxlength="200"
                                        placeholder="Type 'yes' or 'no'"
                                    />
                                 <Button btnType="Success" clicked={speakClickHandler}>Answer Now</Button> */}
                                    </div>
                                </Aux>
                            ) : (
                                <Aux>
                                    <div>
                                        <p>Choose or say <strong>"Countdown"</strong>, <strong>"Affirmative"</strong>, <strong>"Hack"</strong> or <strong>"Bondage"</strong> to proceed</p>
                                        <Button btnType="Success" clicked={() => setTopicAnswerHandler('countdown')}>Countdown</Button><br></br>
                                        <Button btnType="Success" clicked={() => setTopicAnswerHandler('Affirmative')}>Affirmative</Button><br></br>
                                        <Button btnType="Success" clicked={() => setTopicAnswerHandler('hack')}>Hack</Button><br></br>
                                        <Button btnType="Success" clicked={() => setTopicAnswerHandler('bondage')}>Bondage</Button><br></br>
                                        <Button btnType="Success" clicked={speakClickHandler}>Speak</Button>
                                    </div>
                                </Aux>
                            )}
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
            {controlStage === 2 ?
                <Aux>
                    <h3>Your control</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={controlNo} speed={50} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={800 + duration1}>
                            {isListening ? (
                                <Aux>
                                    <p>Say <strong>"Countdown"</strong>, <strong>"Affirmative"</strong>, <strong>"Hack"</strong> or <strong>"Bondage"</strong> to proceed</p>
                                    <div>
                                        {/* <input
                                        onChange={messageInputHandler}
                                        className={classes.InputElement}
                                        type="text"
                                        // maxlength="200"
                                        placeholder="Type 'yes' or 'no'"
                                    />
                                 <Button btnType="Success" clicked={speakClickHandler}>Answer Now</Button> */}
                                    </div>
                                </Aux>
                            ) : (
                                <Aux>
                                    <div>
                                        <p>Choose or say <strong>"Countdown"</strong>, <strong>"Affirmative"</strong>, <strong>"Hack"</strong> or <strong>"Bondage"</strong> to proceed</p>
                                        <Button btnType="Success" clicked={() => setTopicAnswerHandler('countdown')}>Countdown</Button><br></br>
                                        <Button btnType="Success" clicked={() => setTopicAnswerHandler('Affirmative')}>Affirmative</Button><br></br>
                                        <Button btnType="Success" clicked={() => setTopicAnswerHandler('hack')}>Hack</Button><br></br>
                                        <Button btnType="Success" clicked={() => setTopicAnswerHandler('bondage')}>Bondage</Button><br></br>
                                        <Button btnType="Success" clicked={speakClickHandler}>Speak</Button>
                                    </div>
                                </Aux>
                            )}
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
            {controlStage === 3 ?
                <ControlCountdown countdown={props.countdown} bell={props.bell} /> : null}
            {controlStage === 4 ?
                <ControlBondage bell={props.bell} /> : null}
            {controlStage === 5 ?
                <Aux>
                    <h3>Your control</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={unclear} speed={50} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={800 + duration7}>
                            {isListening ? (
                                <Aux>
                                    <p>Say <strong>"Countdown"</strong>, <strong>"Affirmative"</strong>, <strong>"Hack"</strong> or <strong>"Bondage"</strong> to proceed</p>
                                    <div>
                                        {/* <input
                                        onChange={messageInputHandler}
                                        className={classes.InputElement}
                                        type="text"
                                        // maxlength="200"
                                        placeholder="Type 'yes' or 'no'"
                                    />
                                 <Button btnType="Success" clicked={speakClickHandler}>Answer Now</Button> */}
                                    </div>
                                </Aux>
                            ) : (
                                <Aux>
                                    <div>
                                        <p>Choose or say <strong>"Countdown"</strong>, <strong>"Affirmative"</strong>, <strong>"Hack"</strong> or <strong>"Bondage"</strong> to proceed</p>
                                        <Button btnType="Success" clicked={() => setTopicAnswerHandler('countdown')}>Countdown</Button><br></br>
                                        <Button btnType="Success" clicked={() => setTopicAnswerHandler('Affirmative')}>Affirmative</Button><br></br>
                                        <Button btnType="Success" clicked={() => setTopicAnswerHandler('hack')}>Hack</Button><br></br>
                                        <Button btnType="Success" clicked={() => setTopicAnswerHandler('bondage')}>Bondage</Button><br></br>
                                        <Button btnType="Success" clicked={speakClickHandler}>Speak</Button>
                                    </div>
                                </Aux>
                            )}
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
            {controlStage === 6 ?
                <ControlAffirmation bell={props.bell}
                    commands={props.commands} /> : null}
            {controlStage === 7 ?
                <ControlBrainHack bell={props.bell} percentage1={props.percentage1} percentage2={props.percentage2} percentage3={props.percentage3} /> : null}
        </Aux>


    return (
        <Aux>
            {controlConversation}
        </Aux>
    )
};

const mapStatesToProps = state => {
    return {
        name: state.auth.accountDetails.name,
        title: state.auth.title,
        profileOptions: state.options.profileOptions,
    }
}

export default connect(mapStatesToProps)(Control)

