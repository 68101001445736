import React, { Component } from 'react'
import Button from '../../../UI/Button/Button'
import classes from '../../../../containers/FreeRun/FreeRun.module.css'
import Aux from '../../../../hoc/auxiliary/auxiliary';
import * as actions from '../../../../store/actions/index';
import { connect } from 'react-redux'
import Input from '../../../UI/Input/Input'
import Background from '../../../Background/Background'
import { updateObject, checkMantra } from '../../../../shared/utility'
import Sound from 'react-sound';
import Delay from 'react-delay';

import EndingMessage from '../../../../assets/Listen_Type_Obey/Ending.mp3'


class listenTypeObey extends Component {

    state = {
        mantraRecite: true,
        mantraDisplay: this.props.mantraDisplay,
        mantraSound: this.props.mantraSound,
        mantraDelay: this.props.mantraDelay,
        counter: 0
    }

    inputChangedHandler = (event, mantra) => {
        let mantraEntered = true
        const updatedMantra = updateObject(this.state.mantraDisplay, {
            [mantra]: updateObject(this.state.mantraDisplay[mantra], {
                ...this.state.mantraDisplay[mantra],
                value: event.target.value,
                valid: checkMantra(this.state.mantraDisplay[mantra].id, event.target.value, this.state.mantraDisplay[mantra].validation),
                touched: true,
                mantraSubmitted: this.state.mantraDisplay[mantra].valid && mantraEntered
            })
        })
        
        this.setState({ mantraDisplay: updatedMantra, })
    }

    submitCommand = () => {
        if (this.state.counter < this.props.mantraNum.length) {
            let newCounter = this.state.counter + 1
            this.setState({ counter: newCounter })
        } else {
            this.setState({ mantraRecite: false })
        }

    }

    endListenFocusObey = () => {
        this.setState({mantraDisplay: [],
            mantraSound: [],
            mantraDelay: [],
            counter: 0})
    }

    render() {
        let form = null

        const formElementsArray = [];
        for (let key in this.state.mantraDisplay) {
            formElementsArray.push({
                id: key,
                config: this.state.mantraDisplay[key]
            });
        }


        if (this.state.mantraRecite) {
            form = (
                <div className={classes.AccountContainer}>
                    <Background />
                    <form className={classes.FormStyle}>
                        {this.state.counter < (formElementsArray.length) ?
                            <Delay wait = {41000}>
                            <Aux key={formElementsArray[this.state.counter].id}>
                                
                                
                                <h3 className={classes.titles}>INPUT TEXT FROM HEADPHONES</h3>
                                <p>Input <strong>{this.props.mantra[this.state.counter]}</strong></p>
                                <Input
                                    elementType={formElementsArray[this.state.counter].config.elementType}
                                    elementConfig={formElementsArray[this.state.counter].config.elementConfig}
                                    value={formElementsArray[this.state.counter].config.value}
                                    invalid={!formElementsArray[this.state.counter].config.valid}
                                    shouldValidate={formElementsArray[this.state.counter].config.validation}
                                    touched={formElementsArray[this.state.counter].config.touched}
                                    changed={(event) => this.inputChangedHandler(event, formElementsArray[this.state.counter].id)} />
                                {this.state.mantraSound[this.state.counter]}
                                <Delay wait={this.state.mantraDelay[this.state.counter]}>
                                <Button btnType="Success" disabled={!formElementsArray[this.state.counter].config.mantraSubmitted} clicked={this.submitCommand}>SUBMIT</Button>
                                </Delay>
                            </Aux>
                            </Delay>
                            : <Aux>
                                <h3 className={classes.titles}>YOU HAVE COMPLETED YOUR TRAINING</h3>
                                <p>We expect your return soon, {this.props.names}</p>
                                <Sound url = {EndingMessage} playStatus={Sound.status.PLAYING} />
                                <Delay wait ={28000}>     
                                    <Button btnType="Success" clicked={this.endListenFocusObey}>I must return</Button>
                                </Delay>
                            </Aux>}


                    </form>
                </div>
            )
        }



        let errorMessage = null

        if (this.props.error) {
            errorMessage = (
                <p className={classes.error}>{this.props.error}</p>
            )
        }
        return (
            <Aux>
                <div className={classes.Account}>
                    {errorMessage}
                    {form}
                </div>

            </Aux >
        )
    }

}

const mapStatesToProps = state => {
    return {
        names: state.freeRun.name,
        error: state.freeRun.error,
        mantra: state.freeRun.mantra,
        mantraNum: state.freeRun.mantraNum

    }
}
const mapDispatchToProps = dispatch => {
    return {
        onFetchMantra: () => dispatch(actions.fetchMantra())
    }
}

export default connect(mapStatesToProps, mapDispatchToProps)(listenTypeObey);