import React from 'react';
import Delay from 'react-delay'
import Aux from '../../../../hoc/auxiliary/auxiliary'
import classes from '../../../../containers/FreeRun/MusicConditioning/MusicConditioning.module.css'
import Sound from 'react-sound'
import Ticking from '../../../../assets/audio/Ticking3.mp3'

const tickingClockChoice = (props) => {
    let hostName = props.name.toUpperCase()
    let tickingClockChoice = null

    let mantras = ['TICK TOCK'
                ,'TICK TOCK'
                ,'YOUR MIND JUST POPS'
                ,'FALLING DEEPER AND DEEPER'
                ,'DEEPER AND DEEPER FOR US, '+ hostName
                ,'SO EASY TO FALL FOR US, ' + hostName
                ,'WITH EACH TICK... WITH EACH TICK YOU FEEL MORE AND MORE ENTRANCED'
                ,'WITH EACH TICK YOU GO DEEPER'
                ,'WE ARE ALWAYS IN CONTROL, ' + hostName
                ,'TICK TOCK'
                ,'TICK TOCK']

    let tickingClockMantra = []
    for (let key = 0; key < mantras.length; key++) {
        tickingClockMantra.push({
            id: key,
            config: mantras[key]
        })
    }
    // eslint-disable-next-line
    if (props.tickingClockChoiceProp) {
        tickingClockChoice = (
            <Aux>
                <Delay wait={2000}>{tickingClockMantra.map(tickingClock => (
                    <Delay key={tickingClock.id} wait={tickingClock.id * 4000}>
                        <h3>{tickingClockMantra[tickingClock.id].config}</h3>
                    </Delay>
                ))}</Delay>
            </Aux>
        )
    } else {
        tickingClockChoice = null
    }

    return (
        <div className={classes.TickingClockChoice}>
            <Sound url={Ticking} playStatus={Sound.status.PLAYING} loop={true} />
            {tickingClockChoice}
        </div>

    )
}


export default tickingClockChoice
