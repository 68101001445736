import * as actionTypes from './authTypes'
// import axios from 'axios'
import customAxios from '../../../axios';


export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
}

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    }
}

//---------------------------------------------Run_Details--------------------------------

export const viewRunDetails = () => {
    return {
        type: actionTypes.VIEW_RUN_DETAIL
    }
}

export const viewHostRunDetail = (token, userId) => {
    return dispatch => {
        dispatch(authStart());
        customAxios.get('/Runs/' + userId + '.json?auth=' + token)
            .then(response => {
                const viewRunDetails = [];

                viewRunDetails.push({
                    ...response.data.Hosts_Run,
                });
                dispatch(viewHostRunDetailSuccess(viewRunDetails[0]))
            })
            .catch(error => {
                dispatch(authFail(error.response))
            })
    }
}

export const viewHostRunDetailSuccess = (viewRunDetails) => {
    return {
        type: actionTypes.VIEW_HOSTS_RUN_DETAIL,
        viewDetails: viewRunDetails
    }
}

export const viewObedienceStage1RunDetails = (token, userId) => {
    return dispatch => {
        dispatch(authStart());
        customAxios.get('/Runs/'+userId+'/Conditioning_Stages/Obedience_Stage_1/.json?auth=' + token)
            .then( response => {
                if(response.data === null){
                    dispatch(authStart());
                    let obedience_stage1 = {
                        obedience_stage1: 0,
                        typing_command: 0,
                        green_text_reinforcement_command: 0,
                        we_are_watching_command: 0,
                        resistance_is_futile_command: 0,
                        instant_trigger_command:0
                    }
                    customAxios.put('/Runs/'+userId+'/Conditioning_Stages/Obedience_Stage_1/.json?auth=' + token, obedience_stage1)
                    .then(response => {
                        dispatch(ViewObedienceStage1RunDetailSuccess(response.data))
                    })
                    .catch(error => {
                        dispatch(authFail(error.message.data))
                    })
                    
                } else{
                    dispatch(ViewObedienceStage1RunDetailSuccess(response.data))
                }
                       
            })
            .catch(error => {
                dispatch(authFail(error.message))
            })
    }
}

export const viewObedienceStage2RunDetails = (token, userId) => {
    return dispatch => {
        // dispatch(ObedienceStageStart());
       
        customAxios.get('/Runs/'+userId+'/Conditioning_Stages/Obedience_Stage_2/.json?auth=' + token)
        
            .then( response => {
                // dispatch(ObedienceStageStart());
                if(response.data === null){
                    let obedience_stage2 = {
                        obedience_stage2: 0,
                        host_reinforcement_command: 0,
                        host_speech_command: 0,
                        host_suit_command: 0,
                        host_pleasure_command: 0,
                        host_infection_command:0
                    }
                    customAxios.put('/Runs/'+userId+'/Conditioning_Stages/Obedience_Stage_2/.json?auth=' + token, obedience_stage2)
                    .then(response => {
                        dispatch(ViewObedienceStage2RunDetailSuccess(response.data))
                    })
                    .catch(error => {
                        dispatch(authFail(error.message.data))
                    })
                    
                } else{
                    dispatch(ViewObedienceStage2RunDetailSuccess(response.data))
                }
                       
            })
            .catch(error => {
                dispatch(authFail(error.message))
            })
    }
}

export const viewObedienceStage3RunDetails = (token, userId) => {
    return dispatch => {
        customAxios.get('/Runs/'+userId+'/Conditioning_Stages/Obedience_Stage_3/.json?auth=' + token)
            .then( response => {
                if(response.data === null){
                    let obedience_stage3 = {
                        obedience_stage3: 0,
                        Host_Chip_Command: 0, //Self trigger 3 taps back of neck
                        Mind_Format_Command: 0, //Remember/Forget
                        Mechanical_Tentacles_Command: 0, //Silver Mechanical Tentacles
                        Binural_Beat_Command: 0, //Beats to condition Hosts to go blank
                        Chloroformed_Conditioned_Command:0 //Chloroformed (remembering/forgetting) Our conditioning to (Obedience, infecting other, pleasure we bring, desire for more runs) 
                    }
                    customAxios.put('/Runs/'+userId+'/Conditioning_Stages/Obedience_Stage_3/.json?auth=' + token, obedience_stage3)
                    .then(response => {
                        dispatch(ViewObedienceStage3RunDetailSuccess(response.data))
                    })
                    .catch(error => {
                        dispatch(authFail(error.message.data))
                    })
                    
                } else{
                    dispatch(ViewObedienceStage3RunDetailSuccess(response.data))
                }
                       
            })
            .catch(error => {
                dispatch(authFail(error.message))
            })
    }
}

export const ViewConditioningProtocol1Details = (token, userId) => {
    return dispatch => {
        customAxios.get('/Runs/'+userId+'/AudioRuns/Conditioning_Protocols/Conditioning_Protocols1.json?auth=' + token)
            
            .then( response => {
                // dispatch(ObedienceStageStart());
                if(response.data === null){
                    let conditioning_protocol1 = {
                        conditioning_protocol1: 0,
                        comply_sequence:0,
                        resistance_sequence:0,
                    }
                    customAxios.put('/Runs/'+userId+'/AudioRuns/Conditioning_Protocols/Conditioning_Protocols1.json?auth=' + token, conditioning_protocol1)
                    .then(response => {
                        dispatch(ViewconditioningProtocol1RunDetailSuccess(response.data))
                    })
                    .catch(error => {
                        dispatch(authFail(error.message.data))
                    })
                    
                } else{
                    dispatch(ViewconditioningProtocol1RunDetailSuccess(response.data))
                }
                       
            })
            .catch(error => {
                dispatch(authFail(error.message.data))
            })
    }
}

export const TentacleIntroduction = (token, userId) => {
    return dispatch => {
        customAxios.get('/Runs/'+userId+'/AudioRuns/Trigger_Introduction/Tentacle_Introduction.json?auth=' + token)
            
            .then( response => {
                // dispatch(ObedienceStageStart());
                if(response.data === null){
                    let Tentacle_introduction = {
                        Tentacle_introduction: 0,
                        obedience_sequence:0,
                        resistance_sequence:0,
                    }
                    customAxios.put('/Runs/'+userId+'/AudioRuns/Trigger_Introduction/Tentacle_Introduction.json?auth=' + token, Tentacle_introduction)
                    .then(response => {
                        dispatch(ViewTentacleIntroductionRunDetailSuccess(response.data))
                    })
                    .catch(error => {
                        dispatch(authFail(error.message.data))
                    })
                    
                } else{
                    dispatch(ViewTentacleIntroductionRunDetailSuccess(response.data))
                }
                       
            })
            .catch(error => {
                dispatch(authFail(error.message.data))
            })
    }
}

export const TentacleReinforcement = (token, userId) => {
    return dispatch => {
        customAxios.get('/Runs/'+userId+'/AudioRuns/Trigger_Reinforcement/Tentacle_Reinforcement.json?auth=' + token)
            
            .then( response => {
                // dispatch(ObedienceStageStart());
                if(response.data === null){
                    let Tentacle_Reinforcement = {
                        Tentacle_Reinforcement: 0,
                        daily_pleasure:0,
                        daily_spank:0,
                        daily_electric_spank:0,
                        covert_fullday:0,
                        covert_edging:0,
                        covert_body_writing:0
                    }
                    customAxios.put('/Runs/'+userId+'/AudioRuns/Trigger_Reinforcement/Tentacle_Reinforcement.json?auth=' + token, Tentacle_Reinforcement)
                    .then(response => {
                        dispatch(ViewTentacleReinforcementRunDetailSuccess(response.data))
                    })
                    .catch(error => {
                        dispatch(authFail(error.message.data))
                    })
                    
                } else{
                    dispatch(ViewTentacleReinforcementRunDetailSuccess(response.data))
                }
                       
            })
            .catch(error => {
                dispatch(authFail(error.message.data))
            })
    }
}

export const ChloroformIntroduction = (token, userId) => {
    return dispatch => {
        customAxios.get('/Runs/'+userId+'/AudioRuns/Trigger_Introduction/Chloroform_Introduction.json?auth=' + token)
            
            .then( response => {
                if(response.data === null){
                    let Chloroform_introduction = {
                        Chloroform_introduction: 0,
                        chloroform_room:0,
                        forced_chloroform:0,
                    }
                    customAxios.put('/Runs/'+userId+'/AudioRuns/Trigger_Introduction/Chloroform_Introduction.json?auth=' + token, Chloroform_introduction)
                    .then(response => {
                        dispatch(ViewChloroformIntroductionRunDetailSuccess(response.data))
                    })
                    .catch(error => {
                        dispatch(authFail(error.message.data))
                    })
                    
                } else{
                    dispatch(ViewChloroformIntroductionRunDetailSuccess(response.data))
                }
                       
            })
            .catch(error => {
                dispatch(authFail(error.message.data))
            })
    }
}

export const ChloroformReinforcement = (token, userId) => {
    return dispatch => {
        customAxios.get('/Runs/'+userId+'/AudioRuns/Trigger_Reinforcement/Chloroform_Reinforcement.json?auth=' + token)
            
            .then( response => {
                if(response.data === null){
                    let Chloroform_Reinforcement = {
                        Chloroform_Reinforcement: 0,
                        Quick_Effect_Chloroform: 0,
                        Normal_Effect_Chloroform: 0,
                        Body_Play: 0,
                        Arousal_Effect: 0,
                        Amnesia_Effect: 0
                    }
                    customAxios.put('/Runs/'+userId+'/AudioRuns/Trigger_Reinforcement/Chloroform_Reinforcement.json?auth=' + token, Chloroform_Reinforcement)
                    .then(response => {
                        dispatch(ViewChloroformReinforcementRunDetailSuccess(response.data))
                    })
                    .catch(error => {
                        dispatch(authFail(error.message.data))
                    })
                    
                } else{
                    dispatch(ViewChloroformReinforcementRunDetailSuccess(response.data))
                }
                       
            })
            .catch(error => {
                dispatch(authFail(error.message.data))
            })
    }
}

export const MotorFunctionControlIntroduction = (token, userId) => {
    return dispatch => {
        customAxios.get('/Runs/'+userId+'/AudioRuns/Trigger_Introduction/Motor_Function_Control_Introduction.json?auth=' + token)            
            .then( response => {
                
                if(response.data === null){
                    let Motor_Function_Control_Introduction = {
                        Motor_Function_Control_Introduction: 0,
                        instant_control:0,
                        slow_control:0,
                        mental_control: 0
                    }
                    customAxios.put('/Runs/'+userId+'/AudioRuns/Trigger_Introduction/Motor_Function_Control_Introduction.json?auth=' + token, Motor_Function_Control_Introduction)
                    .then(response => {
                        dispatch(ViewMotorFunctionControlIntroductionRunDetailSuccess(response.data))
                    })
                    .catch(error => {
                        dispatch(authFail(error.message.data))
                    })
                    
                } else{
                    dispatch(ViewMotorFunctionControlIntroductionRunDetailSuccess(response.data))
                }
                       
            })
            .catch(error => {
                dispatch(authFail(error.message.data))
            })
    }
}

export const MotorFunctionControlReinforcement = (token, userId) => {
    return dispatch => {
        customAxios.get('/Runs/'+userId+'/AudioRuns/Trigger_Reinforcement/Motor_Function_Control_Reinforcement.json?auth=' + token)
            
            .then( response => {
                if(response.data === null){
                    let Motor_Function_Control_Reinforcement = {
                        Motor_Function_Control_Reinforcement: 0,
                        instant_control_reinforcement:0,
                        slow_control_reinforcement:0,
                        mental_control_reinforcement: 0,
                        wired_reconfiguration: 0
                    }
                    customAxios.put('/Runs/'+userId+'/AudioRuns/Trigger_Reinforcement/Motor_Function_Control_Reinforcement.json?auth=' + token, Motor_Function_Control_Reinforcement)
                    .then(response => {
                        dispatch(ViewMotorFunctionControlReinforcementRunDetailSuccess(response.data))
                    })
                    .catch(error => {
                        dispatch(authFail(error.message.data))
                    })
                    
                } else{
                    dispatch(ViewMotorFunctionControlReinforcementRunDetailSuccess(response.data))
                }
                       
            })
            .catch(error => {
                dispatch(authFail(error.message.data))
            })
    }
}

export const ViewTentacleIntroductionRunDetailSuccess = (tentacle_introduction) => {
    return {
        type: actionTypes.VIEW_TENTACLE_INTRODUCTION,
        tentacle_introduction: tentacle_introduction
    }
}

export const ViewChloroformIntroductionRunDetailSuccess = (chloroform_introduction) => {
    return {
        type: actionTypes.VIEW_CHLOROFORM_INTRODUCTION,
        chloroform_introduction: chloroform_introduction
    }
}

export const ViewMotorFunctionControlIntroductionRunDetailSuccess = (motor_function_control_introduction) => {
    return {
        type: actionTypes.VIEW_MOTOR_FUNCTION_CONTROL_INTRODUCTION,
        motor_function_control_introduction: motor_function_control_introduction
    }
}

export const viewTriggerIntroduction = () => {
    return {
        type: actionTypes.VIEW_TRIGGER_INTRODUCTION
    }
}

export const ViewTentacleReinforcementRunDetailSuccess = (tentacle_reinforcement) => {
    return {
        type: actionTypes.VIEW_TENTACLE_REINFORCEMENT,
        tentacle_reinforcement: tentacle_reinforcement
    }
}

export const ViewChloroformReinforcementRunDetailSuccess = (chloroform_reinforcement) => {
    return {
        type: actionTypes.VIEW_CHLOROFORM_REINFORCEMENT,
        chloroform_reinforcement: chloroform_reinforcement
    }
}

export const ViewMotorFunctionControlReinforcementRunDetailSuccess = (motor_function_control_reinforcement) => {
    return {
        type: actionTypes.VIEW_MOTOR_FUNCTION_CONTROL_REINFORCEMENT,
        motor_function_control_reinforcement: motor_function_control_reinforcement
    }
}

export const viewTriggerReinforcement = () => {
    return {
        type: actionTypes.VIEW_TRIGGER_REINFORCEMENT
    }
}

export const ViewconditioningProtocol1RunDetailSuccess = (conditioning_protocol1) => {
    return {
        type: actionTypes.VIEW_CONDITIONING_PROTOCOL_1,
        conditioning_protocol1: conditioning_protocol1
    }
}

export const ViewConditioningStages = () => {
    return {
        type: actionTypes.VIEW_CONDITIONING_STAGES_RUN_DETAIL
    }
}

export const ViewConditioningProtocols = () => {
    return {
        type: actionTypes.VIEW_CONDITIONING_PROTOCOLS_RUN_DETAIL
    }
}

export const ViewObedienceStage3RunDetailSuccess = (obedienceRunDetails) => {
    return {
        type: actionTypes.VIEW_OBEDIENCE_STAGE_3_RUN_DETAIL,
        obedienceRun3Details: obedienceRunDetails
    }
}


export const ViewObedienceStage2RunDetailSuccess = (obedienceRunDetails) => {
    return {
        type: actionTypes.VIEW_OBEDIENCE_STAGE_2_RUN_DETAIL,
        obedienceRun2Details: obedienceRunDetails
    }
}

export const ViewObedienceStage1RunDetailSuccess = (obedienceRunDetails) => {
    return {
        type: actionTypes.VIEW_OBEDIENCE_STAGE_1_RUN_DETAIL,
        obedienceRun1Details: obedienceRunDetails
    }
}
