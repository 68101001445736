import React from '../../../../../node_modules/react';
import TypingEffect from '../../../../../node_modules/react-typing-effect';
import Delay from '../../../../../node_modules/react-delay'
import Aux from '../../../../hoc/auxiliary/auxiliary'
import classes from '../../HostsRun/HostsRunComponent.module.css'

 
const awakener = (props) => {
    // let praiseChoice = 0
    // praiseChoice = Math.floor(Math.random() * props.praises.length)-1;
    // let praise = props.praises[praiseChoice]
    
    
    const awakenerMessage = "It is time for Us to wake you up, " + // eslint-disable-next-line
                             (props.naming) + "\n\
                             It is time for you to wake up as Our host\n\
                             To wake up and realize the control\n\
                             To realize the power We have over you\n\
                             However We decide whether you remember all We did to you or not\n\
                             We have decided you will " +     // eslint-disable-next-line
                             (props.awakenerMethodName) + (props.naming)
    let speed = parseInt(props.speed) +5                         
    let  duration = 170 + (awakenerMessage.length * speed)
    let awakenerParagraph = (
            <Aux>
                <h3 className= {classes.titles}>AWAKENER</h3>
                <Delay wait={5000}><TypingEffect typingDelay={100} text={awakenerMessage} speed={props.speed} eraseDelay={1000000} /></Delay>
                <Delay wait={duration+5000}><h4 className={classes.blinking} onClick={props.awakener}>I am ready to serve The Hypno Virus</h4></Delay>
            </Aux>
        )
    





    return awakenerParagraph
}


export default awakener