import React from 'react'
import Speech from 'react-speech';
import Aux from '../../../hoc/auxiliary/auxiliary'
import classes from './TextToSpeech.module.css';
import { connect } from 'react-redux'
// import {speechUtteranceChunker} from './LongText'


const SpeechComponent = (props) => {
  const [messageToSpeak, setMessageToSpeak] = React.useState('');
  const messageInputHandler = (evt) => {
        setMessageToSpeak(evt.target.value);
      };

  let voice = ''
  if (props.profileOptions.text_to_speech_voice === '1'){
          voice="Google UK English Male"
  } else if (props.profileOptions.text_to_speech_voice === '2'){
          voice="Microsoft David Desktop - English (United States)"
  } else if (props.profileOptions.text_to_speech_voice === '3'){
          voice="Google UK English Female"
  } else if (props.profileOptions.text_to_speech_voice === '4'){
          voice="Microsoft Zira Desktop - English (United States)"
  }

  let speech =
    <Aux>
        <h3>Enter Your Mantra, {props.title} {props.name}</h3>
        <p><small>Text Limit 200 Characters</small></p>
      <input
        onChange={messageInputHandler}
        className = {classes.InputElement}
        type="text"
        maxlength="200"
        placeholder="Enter your mantra here"
      />
      <Speech text= {messageToSpeak}
              className = {classes.Success}
              // styles = {style}
              pitch="0.01"
              rate="0.5"
              volume="1"
              voice = {voice}
              textAsButton= {true}
              displayText = "Speak"
              />                
    </Aux>
  return speech
};

const mapStatesToProps = state => {
    return {
        name: state.auth.accountDetails.name,
        title: state.auth.title,
        profileOptions: state.options.profileOptions,
    }
}

export default connect(mapStatesToProps)(SpeechComponent)
