import React from 'react'
import HostsRunComponent from './HostsRun/HostsRunComponent'
import Aux from '../../hoc/auxiliary/auxiliary'
import Sound from 'react-sound'
import Bell from '../../assets/audio/bell.mp3'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import classes from '../../containers/HostsChamber/HostsChamber.module.css'
import { Redirect } from 'react-router-dom'

const HostsChamberComponent = (props) => {

    let hostRunform = null
    if (props.introduction) {
        hostRunform = (
            <Aux>
                <HostsRunComponent
                    naming={props.hostName}
                    gender={props.hostGender}
                    speed={props.runSpeed}
                    title={props.title}
                    introduction={() => props.onIntroduction()}
                />
            </Aux>
        )
    } else if (props.induction) {
        let inductionChoice = Math.floor(Math.random() * 2) + 1;
        let inductionMethod = 0
        let inductionMethodName = ''
        if (inductionChoice === 1) {
            inductionMethod = 1
            inductionMethodName = 'The Forehead Tap Induction'
        } else {
            inductionMethod = 2
            inductionMethodName = 'The Bell Induction'
        }
        hostRunform = (
            <Aux>
                <Sound url={Bell} playStatus={Sound.status.PLAYING} />
                <HostsRunComponent
                    naming={props.hostName}
                    gender={props.hostGender}
                    speed={props.runSpeed}
                    title={props.title}
                    inductionMethodName={inductionMethodName}
                    induction={() => props.onInduction(inductionMethod)}
                />
            </Aux>
        )
    } else if (props.foreheadInduction) {
        hostRunform = (
            <Aux>
                <Sound url={Bell} playStatus={Sound.status.PLAYING} />
                <HostsRunComponent
                    naming={props.hostName}
                    gender={props.hostGender}
                    speed={props.runSpeed}
                    title={props.title}
                    foreheadInduction={() => props.onForeheadInduction()}
                />
            </Aux>
        )
    } else if (props.bellInduction) {
        hostRunform = (
            <Aux>
                <Sound url={Bell} playStatus={Sound.status.PLAYING} />
                <HostsRunComponent
                    naming={props.hostName}
                    gender={props.hostGender}
                    speed={props.runSpeed}
                    title={props.title}
                    bellInduction={() => props.onBellInduction()}
                />
            </Aux>
        )
    } else if (props.mantra) {
        // console.log(props.BellInductionCounter)
        // console.log(props.ForeheadTapInductionCounter)
        hostRunform = (
            <Aux>
                <Sound url={Bell} playStatus={Sound.status.PLAYING} />
                <HostsRunComponent
                    naming={props.hostName}
                    gender={props.hostGender}
                    speed={props.runSpeed}
                    title={props.title}
                    mantra={() => props.onMantra()}
                />
            </Aux>
        )
    } else if (props.deepener) {
        let deepenerChoice = Math.floor(Math.random() * 2) + 1;
        let deepenerMethod = 0
        let deepenerMethodName = ''
        if (deepenerChoice === 1) {
            deepenerMethod = 1;
            deepenerMethodName = 'The Ticking Clock Deepener'
        } else {
            deepenerMethod = 2
            deepenerMethodName = 'The Metronome Deepener'
        }
        hostRunform = (
            <Aux>
                <Sound url={Bell} playStatus={Sound.status.PLAYING} />
                <HostsRunComponent
                    naming={props.hostName}
                    gender={props.hostGender}
                    speed={props.runSpeed}
                    title={props.title}
                    deepenerMethodName={deepenerMethodName}
                    deepener={() => props.onDeepener(deepenerMethod)}
                />

            </Aux>
        )
    } else if (props.tickingClockDeepener) {
        hostRunform = (
            <Aux>
                <Sound url={Bell} playStatus={Sound.status.PLAYING} />
                <HostsRunComponent
                    naming={props.hostName}
                    gender={props.hostGender}
                    speed={props.runSpeed}
                    title={props.title}
                    tickingDeepener={() => props.onTickingDeepener()}
                />
            </Aux>
        )
    } else if (props.metronomeDeepener) {
        hostRunform = (
            <Aux>
                <Sound url={Bell} playStatus={Sound.status.PLAYING} />
                <HostsRunComponent
                    naming={props.hostName}
                    gender={props.hostGender}
                    speed={props.runSpeed}
                    title={props.title}
                    metronomeDeepener={() => props.onMetronomeDeepener()}
                />
            </Aux>
        )
    } else if (props.trigger) {
        let triggerChoice = Math.floor(Math.random() * 2) + 1;
        let triggerMethod = 0
        let triggerMethodName = ''
        if (triggerChoice === 1) {
            triggerMethod = 1
            triggerMethodName = 'Trigger Repetition'
        } else {
            triggerMethod = 2
            triggerMethodName = 'Trigger Command'
        }
        hostRunform = (
            <Aux>
                <Sound url={Bell} playStatus={Sound.status.PLAYING} />
                <HostsRunComponent
                    naming={props.hostName}
                    gender={props.hostGender}
                    speed={props.runSpeed}
                    title={props.title}
                    triggerMethodName={triggerMethodName}
                    trigger={() => props.onTrigger(triggerMethod)}
                />
            </Aux>
        )
    } else if (props.triggerCommand) {
        hostRunform = (
            <Aux>
                <Sound url={Bell} playStatus={Sound.status.PLAYING} />
                <HostsRunComponent
                    naming={props.hostName}
                    gender={props.hostGender}
                    speed={props.runSpeed}
                    title={props.title}
                    triggerCommand={() => props.onTriggerCommand()}
                />
            </Aux>
        )
    } else if (props.triggerRepetition) {
        hostRunform = (
            <Aux>
                <Sound url={Bell} playStatus={Sound.status.PLAYING} />
                <HostsRunComponent
                    naming={props.hostName}
                    gender={props.hostGender}
                    speed={props.runSpeed}
                    title={props.title}
                    triggerRepetition={() => props.onTriggerRepetition()}
                />
            </Aux>
        )
    } else if (props.awakener) {
        let awakenerChoice = Math.floor(Math.random() * 2) + 1;
        let awakenerMethod = 0
        let awakenerMethodName = ''
        if (awakenerChoice === 1) {
            awakenerMethod = 1
            awakenerMethodName = ' forget your experience, '
        } else {
            awakenerMethod = 2
            awakenerMethodName = ' remember your experience, '
        }
        hostRunform = (
            <Aux>
                <Sound url={Bell} playStatus={Sound.status.PLAYING} />
                <HostsRunComponent
                    naming={props.hostName}
                    gender={props.hostGender}
                    speed={props.runSpeed}
                    title={props.title}
                    awakenerMethodName={awakenerMethodName}
                    awakener={() => props.onAwakener(awakenerMethod)}
                />
            </Aux>
        )
    } else if (props.awakenerRemember) {
        hostRunform = (
            <Aux>
                <Sound url={Bell} playStatus={Sound.status.PLAYING} />
                <HostsRunComponent
                    naming={props.hostName}
                    gender={props.hostGender}
                    speed={props.runSpeed}
                    title={props.title}
                    awakenerRemember={() => props.onAwakenerRemember()}
                />
            </Aux>
        )
    } else if (props.awakenerForget) {
        hostRunform = (
            <Aux>
                <Sound url={Bell} playStatus={Sound.status.PLAYING} />
                <HostsRunComponent
                    naming={props.hostName}
                    gender={props.hostGender}
                    speed={props.runSpeed}
                    title={props.title}
                    awakenerForget={() => props.onAwakenerForget()}
                />
            </Aux>
        )
        
    } else if (props.ending) {
        
        hostRunform = (
            <Aux>
                <Sound url={Bell} playStatus={Sound.status.PLAYING} />
                <HostsRunComponent
                    naming={props.hostName}
                    gender={props.hostGender}
                    speed={props.runSpeed}
                    title={props.title}
                    ending={() => props.onEnding(props.token, 
                                                props.userId, 
                                                props.BellInductionCounter,
                                                props.ForeheadTapInductionCounter,  
                                                props.MetronomeDeepenerCounter,
                                                props.TickingClockDeepenerCounter,
                                                props.MantraCounter,
                                                props.TriggerCommandCounter,
                                                props.TriggerRepetitionCounter,
                                                props.AwakenerRememberCounter,
                                                props.AwakenerForgetCounter,
                                                props.Hosts_Run_Counter )}
                />
            </Aux>
        )
    } else if (props.restartHostRun){
       hostRunform =  <Redirect to="/"/>
    }
    return (
        <Aux>
            <Fullpage >
                <FullPageSections>
                    <FullpageSection>
                        <div className={classes.HostsChamber}>
                            {props.audio_option}
                            {hostRunform}
                            <p className={classes.Note}>Note: Do not switch tabs while running The Hypno Virus</p>
                        </div>
                    </FullpageSection>
                </FullPageSections>
            </Fullpage >
        </Aux >

    );
    
}

const mapStatesToProps = state => {
    return {
        hostName: state.auth.accountDetails.name,
        hostGender: state.auth.accountDetails.gender,
        runSpeed: state.auth.accountDetails.speed,
        token: state.auth.token,
        userId: state.auth.userId,
        title: state.auth.title,
        introduction: state.hostsChamber.introduction,
        induction: state.hostsChamber.induction,
        foreheadInduction: state.hostsChamber.foreheadInduction,
        bellInduction: state.hostsChamber.bellInduction,
        mantra: state.hostsChamber.mantra,
        deepener: state.hostsChamber.deepener,
        tickingClockDeepener: state.hostsChamber.tickingClockDeepener,
        metronomeDeepener: state.hostsChamber.metronomeDeepener,
        trigger: state.hostsChamber.trigger,
        triggerCommand: state.hostsChamber.triggerCommand,
        triggerRepetition: state.hostsChamber.triggerRepetition,
        awakener: state.hostsChamber.awakener,
        awakenerRemember: state.hostsChamber.awakenerRemember,
        awakenerForget: state.hostsChamber.awakenerForget,
        ending: state.hostsChamber.ending,
        restartHostRun: state.hostsChamber.restartHostRun,
        BellInductionCounter: state.hostsChamber.BellInductionCounter,
        ForeheadTapInductionCounter: state.hostsChamber.ForeheadTapInductionCounter,
        MetronomeDeepenerCounter: state.hostsChamber.MetronomeDeepenerCounter,
        TickingClockDeepenerCounter: state.hostsChamber.TickingClockDeepenerCounter,
        MantraCounter: state.hostsChamber.MantraCounter,
        TriggerCommandCounter: state.hostsChamber.TriggerCommandCounter,
        TriggerRepetitionCounter: state.hostsChamber.TriggerRepetitionCounter,
        AwakenerRememberCounter: state.hostsChamber.AwakenerRememberCounter,
        AwakenerForgetCounter:state.hostsChamber.AwakenerForgetCounter,
        Hosts_Run_Counter: state.hostsChamber.Hosts_Run_Counter
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onIntroduction: () => dispatch(actions.onHostsIntroduction()),
        onInduction: (inductionMethod) => dispatch(actions.onHostsInduction(inductionMethod)),
        onForeheadInduction: () => dispatch(actions.onForeheadInduction()),
        onBellInduction: () => dispatch(actions.onBellInduction()),
        onMantra: () => dispatch(actions.onMantra()),
        onDeepener: (deepenerMethod) => dispatch(actions.onDeepener(deepenerMethod)),
        onTickingDeepener: () => dispatch(actions.onTickingDeepener()),
        onMetronomeDeepener: () => dispatch(actions.onMetronomeDeepener()),
        onTrigger: (triggerMethod) => dispatch(actions.onTrigger(triggerMethod)),
        onTriggerCommand: () => dispatch(actions.onTriggerCommand()),
        onTriggerRepetition: () => dispatch(actions.onTriggerRepetition()),
        onAwakener: (awakenerMethod) => dispatch(actions.onAwakener(awakenerMethod)),
        onAwakenerRemember: () => dispatch(actions.onAwakenerRemember()),
        onAwakenerForget: () => dispatch(actions.onAwakenerForget()),
        onEnding: (token, userId, ForeheadTapInductionCounter, BellInductionCounter, MetronomeDeepenerCounter, TickingClockDeepenerCounter, MantraCounter, TriggerCommandCounter,
            TriggerRepetitionCounter,
            AwakenerRememberCounter,
            AwakenerForgetCounter,
            Hosts_Run_Counter) => dispatch(actions.onEnding(token, userId, ForeheadTapInductionCounter, 
            BellInductionCounter, 
            MetronomeDeepenerCounter,
            TickingClockDeepenerCounter,
            MantraCounter,
            TriggerCommandCounter,
            TriggerRepetitionCounter,
            AwakenerRememberCounter,
            AwakenerForgetCounter,
            Hosts_Run_Counter))

        
    }
}
export default connect(mapStatesToProps, mapDispatchToProps)(HostsChamberComponent)