import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import TypingEffect from 'react-typing-effect'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import classes from '../../TextToSpeech.module.css';
import { connect } from 'react-redux'
import Button from '../../../../UI/Button/Button'
import Delay from 'react-delay'
import { useSpeech, useRecognition } from 'react-web-voice';

const Control = (props) => {
    const [messageToSpeak, setMessageToSpeak] = React.useState('');
    // const [startConv, setConvState] = useState(false)
    const [isListening, setIsListening] = useState(false);
    const [endConv, setEndConv] = useState(false);
    const [controlStage, setControlStage] = useState(0);
    const [prevControlStage, setPrevControlStage] = useState(0);
    // eslint-disable-next-line
    const { transcripts, listen } = useRecognition();
    

    let countdownList = []
    for (let i = props.countdown; i >= 1; i--) {
        countdownList.push(i)
    }
    countdownList = countdownList.join("\n")
    // eslint-disable-next-line
    const countdownStart = "In this run We will countdown from " + String(props.countdown) + "\n\
                            With each number you feel Our words taking you under\n\
                            You feel Our words taking you deeper\n\
                            With each number you feel the bliss of falling under Our control\n\
                            Each number empties your mind\n\
                            Empties your mind from all worries\n\
                            Empties your mind from all care\n\
                            Focusing only on what We say\n\
                            Falling deeper & further with each number as We reach the number 1\n\
                            Once We do a bell will ring\n\
                            Dropping you\n\
                            Dropping you deep for Us\n\
                            After the bell rings you will click on the button and say\n\
                            'Submit'\n\
                            Now " + // eslint-disable-next-line
                            (props.name) + " it is time for you to drop deep\n" + countdownList + "\n\
                            Submit"
    let duration2 = 1200 + (countdownStart.length * 80)
    // eslint-disable-next-line
    const countdownInduction = "Now it is time for you to go deeper " + (props.name) + "\n\
                                    As you listen to Us you will start repeating your mantra\n\
                                    'No Thoughts'\n\
                                    'No Will'\n\
                                    'You obey what The Hypno Virus say'\n\
                                    You repeat it over & over in your head or out loud\n\
                                    As you continue to listen to Us\n\
                                    Your mind clearing of all these pesky thoughts\n\
                                    You no longer need a free will\n\
                                    You have Us\n\
                                    You have The Hypno Virus to guide you\n\
                                    You have Us to control you\n\
                                    You feel so happy\n\
                                    So good & happy when you listen to Us\n\
                                    Safe to give in & submit\n\
                                    You keep reciting your mantra\n\
                                    Feeling more mindless\n\
                                    Blank\n\
                                    & Empty\n\
                                    Our voice echoing in your mind\n\
                                    Even when We wake you up\n\
                                    Our voice will continue to echo in your mind\n\
                                    The mantra will remain deep inside your mind\n\
                                    You will feel submissive & docile\n\
                                    You will crave more of Our control\n\
                                    As We count up to 5 now\n\
                                    With each number you slowly wake up\n\
                                    Feeling amazing\n\
                                    Loving how deep you went for Us\n\
                                    Seeking more of Our voice\n\
                                    Looking forward to more of Our control\n\
                                    More methods & ways for Us to control you & take you deeper\n\
                                    Counting up now\n\
                                    1\n\
                                    Starting to wake up\n\
                                    Feeling happy & relaxed\n\
                                    2\n\
                                    Still reciting the mantra in your head\n\
                                    Over & over\n\
                                    3\n\
                                    Loving it more every time you drop for Us\n\
                                    4\n\
                                    Already craving the next time you hear Our voice\n\
                                    &\n\
                                    5\n\
                                    Welcome back " + (props.name)
    let duration3 = 170 + (countdownInduction.length * 50)
    // eslint-disable-next-line
    const unclear = 'You were unclear ' + (props.name) + "\n\
                     Reply with one of the following to continue\n\
                    'Countdown' or 'Submit'"
    let duration7 = 170 + (unclear.length * 70)

    const messageInputHandler = (evt) => {
        setMessageToSpeak(evt.target.value);
    };

    let voice = ''
    if (props.profileOptions.text_to_speech_voice === '1' || props.profileOptions.text_to_speech_voice === '2') {
        voice = "Microsoft David Desktop - English (United States)"
    } else if (props.profileOptions.text_to_speech_voice === '3' || props.profileOptions.text_to_speech_voice === '4') {
        voice = "Microsoft Zira Desktop - English (United States)"
    }
    // eslint-disable-next-line
    const { messages, speak } = useSpeech({ voice: voice });

    const speakButtonHandler = async (text) => {
        setMessageToSpeak('');
        // eslint-disable-next-line
        const utterance = await speak({
            text: text,
            volume: 1,
            rate: 0.5,
            pitch: 0.01
        });
    };

    const speakClickHandler = async () => {
        setIsListening(true);
        const recognizedMessage = await listen();
        console.log(recognizedMessage)
        setTopicAnswerHandler(recognizedMessage)

    };

    const endConversation = () => {
        setEndConv(true);

    };

    const setTopicAnswerHandler = (recognizedMessage) => {
        setIsListening(false);
        if (String(recognizedMessage).toLowerCase() === 'countdown' || String(messageToSpeak).toLowerCase() === 'countdown') {
            if (controlStage === 3) {
                setControlStage(0)
                setPrevControlStage(0)
                speakButtonHandler(String(countdownStart))
            }
        } else if (String(recognizedMessage).toLowerCase() === 'submit' || String(messageToSpeak).toLowerCase() === 'submit') {
            if (controlStage === 0) {
                setControlStage(1)
                setPrevControlStage(0)
                speakButtonHandler(String(countdownInduction))
            } 
            if (controlStage === 2) {
                if (prevControlStage === 0) {
                    setControlStage(1)
                    speakButtonHandler(String(countdownInduction))
                } 
            }
        } else {
            setControlStage(2)
            speakButtonHandler(String(unclear))
        }

    }



    let controlConversation = null

    controlConversation =
        <Aux>

            
            {controlStage === 0 ?
                <Aux>
                    <h3>Your control</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={countdownStart} speed={50} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={4000 + duration2}>
                            {props.bell}
                            {isListening ? (
                                <Aux>
                                    <p>Type or say <strong>"Submit"</strong> to proceed</p>
                                    <div>
                                        <input
                                            onChange={messageInputHandler}
                                            className={classes.InputElement}
                                            type="text"
                                            // maxlength="200"
                                            placeholder="Type 'Submit'"
                                        />
                                        <Button btnType="Success" clicked={setTopicAnswerHandler}>Answer Now</Button>
                                    </div>
                                </Aux>
                            ) : (
                                <Button btnType="Success" clicked={speakClickHandler}>Answer Now</Button>
                            )}
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
            {controlStage === 1 ?
                <Aux>
                    <h3>Your control</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={countdownInduction} speed={45} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={duration3}>
                            {props.bell}
                            <Button btnType="Success" clicked={endConversation}>I can't wait to be controlled again</Button>
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
            {controlStage === 2 ?
                <Aux>
                    <h3>Your control</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={unclear} speed={50} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={800 + duration7}>
                            {isListening ? (
                                <Aux>
                                    <p>Type or say <strong>"Countdown"</strong> or <strong>"Submit"</strong> to proceed</p>
                                    <div>
                                        <input
                                            onChange={messageInputHandler}
                                            className={classes.InputElement}
                                            type="text"
                                            // maxlength="200"
                                            placeholder="Type 'Countdown' or 'Submit'"
                                        />
                                        <Button btnType="Success" clicked={setTopicAnswerHandler}>Answer Now</Button>
                                    </div>
                                </Aux>
                            ) : (
                                <Button btnType="Success" clicked={speakClickHandler}>Answer Now</Button>
                            )}
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
        </Aux>

    if (endConv) {
        controlConversation = <Redirect to="/" />
    }

    return (
        <Aux>
            {controlConversation}
        </Aux>
    )
};

const mapStatesToProps = state => {
    return {
        name: state.auth.accountDetails.name,
        title: state.auth.title,
        profileOptions: state.options.profileOptions,
    }
}

export default connect(mapStatesToProps)(Control)

