import * as actionTypes from '../../../actions/MindMeltingExperience/TheGreatOHypnosisShow/actionType';
import { updateObject } from '../../../../shared/utility';

const initialState = {
    showStart: true,
    startStory: false,
    chapterSelect: false,
}

const hypnosisShowStart = (state, action) => {
    return updateObject(state, {
        showStart: false,
        startStory: true
    })
}

const hypnosisShowEnd = (state, action) => {
    return updateObject(state, {
        showStart: true,
        startStory: false,
        chapterSelect: false,
    })
}



const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.START_THE_SHOW: return hypnosisShowStart(state, action)
        case actionTypes.END_THE_SHOW: return hypnosisShowEnd(state, action)
        default: return state
    }
}

export default reducer