import React, { Component } from 'react';
import Aux from '../../../../hoc/auxiliary/auxiliary';
import classes from '../HypnosisShowMenu.module.scss'
// import classes2 from '../ExperienceMenu/ExperienceMenu.module.scss'
import HypnosisShow from '../../../../assets/Images/Blank.png'
import Snap from './HypnosisShowNonVolSnap1'
import ForeheadTap from './HypnosisShowNonVolForeheadTap'
import HypnoGun from './HypnosisShowNonVolHypnoGun'
import WakeUp from './HypnosisShowNonVolWakeUp'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'

class HypnosisShowSelfSnap extends Component {

    state = {
        countdownSnap: true,
        fingerSnap: false,
        foreheadTap: false,
        hypnoGun: false,
        wakeUp: false
    }

    fingerSnapHandler = () => {
        this.setState({ countdownSnap: false, fingerSnap: true })
    }

    foreheadTapHandler = () => {
        this.setState({ countdownSnap: false, foreheadTap: true })
    }

    hypnoGunHandler = () => {
        this.setState({ countdownSnap: false, hypnoGun: true })
    }

    wakeUpHandler = () => {
        this.setState({ countdownSnap: false, wakeUp: true })
    }


    render() {
        let HypnosisShowForm = null
        if (this.state.countdownSnap) {
            HypnosisShowForm = (
                <html style={{ backgroundImage: `url(${HypnosisShow})` }}>
                    <div className={classes.storyFrom}>
                        <p>
                        <i>“Trigger you to countdown from five to one and snap your fingers. As soon as you do that you will instantly drop. 
                        You will instantly drop back again, drop deep into trance & return to this wonderful state, understood?” </i><br></br>
                        Nodding but thinking to yourself <i>"How will this work? Will I really just mindlessly start counting down and snapping my fingers with no control over myself?"</i> 
                        Hesitant about it but you're willing to see how it will transpire 
                        Continuing <i>“As soon as I say ‘Countdown begins’ you will all start counting down from five to one then snap your fingers & drop yourselves. 
                        Waking up on the count of five. <br></br>
                        One, starting to wake up. <br></br>
                        Two, feeling wonderful and refreshed. <br></br>
                        Three, Excited & happy. <br></br>
                        Four, Eager to participate and join in the experience<br></br>
                        Five, wide awake now!”</i><br></br>
                        <strong>*SNAP*</strong><br></br>
                        Stretching as you open your eyes, your thoughts slowly return to you one by one as you remember what is happening & where you are. <br></br>
                        <i>“Such amazing subjects & an amazing audience you all deserve a round of applause!” </i><br></br>
                        You clap instinctively simply because he said so and you see a couple of volunteers clapping feeling proud of themselves. <br></br>
                        <i>“I hope you’re having a wonderful time. I see a number of you are feeling quite proud of themselves & happy with how responsive they are. You should be! 
                        You’re doing an amazing job and you’re all great subjects in fact here try this. <strong>Countdown begins”. </strong></i>
                        Before you can register anything you hear your own voice. <br></br>
                        You hear you own voice along with the volunteers on stage and others in the audience all in unison starting to countdown. 
                        You just manage to whisper it instead of saying it out loud to avoid detection but along with the others you start
                        <i>Five<br></br>
                        Looking around as the others look back at you <br></br>
                        Four <br></br>
                        Realizing what’s happening but unable to stop it <br></br>
                        Three <br></br>
                        Already feeling yourself slipping deep down <br></br>
                        Two <br></br>
                        Quite fuzzy & floaty <br></br>
                        One <br></br>
                        You’re gone <br></br>
                        You raise your hand, watching it but having absolutely no control over it as you…<br></br>
                        <strong>*SNAP*</strong></i><br></br>
                        You fall. <br></br> 
                        You sink & fall as your body collapses and your mind goes blank. 
                        Feeling carefree & empty with a huge smile on your face as you hear his voice saying <br></br>
                        <i>“Great job everyone, you’re doing a fabulous job. 
                        That’s right you don’t need to worry about a thing. 
                        Nothing to think about or care for except my voice. My voice helping you, guiding you to drift deeper, drop further & sink more, 
                        and all you want is have me……” </i>
                        </p>
                        <form className={classes.centered} onClick={this.fingerSnapHandler}>
                            <h4 className={classes.storyButtonSize}>Snap my Fingers</h4>
                        </form>
                        <form className={classes.centered} onClick={this.foreheadTapHandler}>
                            <h4 className={classes.storyButtonSize}>Tap your Forehead</h4>
                        </form>
                        <form className={classes.centered} onClick={this.hypnoGunHandler}>
                            <h4 className={classes.storyButtonSize}>Use my Hypno Gun</h4>
                        </form>
                        <form className={classes.centered} onClick={this.wakeUpHandler}>
                            <h4 className={classes.storyButtonSize}>Wake you Up</h4>
                        </form>
                    </div>
                </html>
            )
        }
        if (this.state.fingerSnap) {
            HypnosisShowForm = (
                <Snap />
            )
        }

        if (this.state.foreheadTap) {
            HypnosisShowForm = (
                <ForeheadTap />
            )
        }

        if (this.state.hypnoGun) {
            HypnosisShowForm = (
                <HypnoGun />
            )
        }

        if (this.state.wakeUp) {
            HypnosisShowForm = (
                <WakeUp />
            )
        }

        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {HypnosisShowForm}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >




        )
    }
}


export default HypnosisShowSelfSnap