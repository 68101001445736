export const CHLOROFORM_EFFECT = Math.floor(Math.random() * 3) + 1

export const CHLOROFORM_FAST_DROP_DELAY = [
    10300, //"Setting up Chloroform Module",
    6500, //"Chloroform Module Initiated",
    7400, //"Quick Effect Chloroform Selected",
    7950, //"Setting Chloroform Effect to maximum",
    9700, //"Setting Chloroform spreading speed to maximum",
    6000, //"Enter The Chloroform Room",
    5900, //"All surroundings fade away",
    5900, //"Everything around you vanishes",
    6700, //"The Room slowly turns into a dark",
    3900, //"Empty Room",
    6200, //"With a single spotlight in the middle",
    5700, //"Vents on every corner",
    3350, //"And the gas",
    9500, //"Our green gas spreading all over the room",
    7400, //"Spreading quickly all around the room",
    3200, //"It fills the room",
    3500, //"Fogging both your vision",
    3600, //"And your mind",
    3900, //"Already feeling its effects",
    3900, //"Breathing the gas in",
    4200, //"Feeling how powerful it is",
    4300, //"How strong it is",
    4700, //"You stand no chance",
    7000, //"Absolutely no chance against Us",
    3200, //"Just give in",
    3600, //"Give in to Us",
    4800, //"Give in to the gas",
    6500, //"Give in to The Hypno Virus gas",
    8000, //"And fall into the deep programmable state...",
    2000, //"Now",
    4000, //"*SNAP*"
]

export const CHLOROFORM_SLOW_DROP_DELAY = [

    9400, //"Setting up Chloroform Module",
    6500, //"Chloroform Module Initiated",
    7100, //"Normal Effect Chloroform Selected",
    6400, //"Setting Chloroform Effect to normal",
    8500, //"Setting Chloroform spreading speed to normal",
    5700, //"Enter The Chloroform Room",
    6900, //"Watch as all your surrounding disappear",
    4000, //"The room getting darker",
    2800, //"Becoming empty",
    4500, //"All you can see in this room",
    4500, //"The Chloroform Room is...",
    4300, //"The spotlight in the middle",
    5000, //"And vents in every corner",
    4900, //"You start to see the green gas",
    4200, //"Our green gas",
    5300, //"The Hypno Virus's gas",
    6500, //"Slowly spreading across the room",
    6600, //"Filling the room with this green fog",
    4300, //"With the green mist",
    7600, //"Becoming harder & harder to look around you",
    7200, //"Harder & harder to see anything",
    9300, //"Except the green mist completely filling the room",
    6500, //"Getting closer & closer to you",
    9000, //"Closer & closer for you to breath it in",
    5800, //"You know there is no escaping",
    5600, //"There is no escaping the gas",
    7500, //"There is no escaping The Hypno Virus's gas",
    5500, //"There is no escaping Us",
    4000, //"Just breath it in",
    6500, //"Breath it in and give in to Us",
    5900, //"The green fog is all around you now",
    3800, //"You can not help it",
    3500, //"Just breath in",
    6100, //"Breath in and feel its effect instantly",
    4700, //"Feel your head getting fuzzy",
    4800, //"Your thoughts slipping away",
    4000, //"As you breath it in",
    4000, //"Breath the gas in",
    4900, //"Becoming harder and harder to think",
    4900, //"Harder & harder to focus",
    5000, //"You will be rendered unconscious",
    3000, //"Or rather...",
    7700, //"You will enter a deeply programmable state",
    6100, //"A state where We will program you",
    5000, //"We will condition you",
    7500, //"And We will definitely brainwash you",
    5800, //"Feeling yourself losing control",
    5600, //"All control over yourself",
    5500, //"Feeling yourself going into this...",
    7900, //"Deeply programmable state...",
    3000, //"Now",
    5000, //"*SNAP*"
]

export const CHLOROFORM_BODY_PLAY_DELAY = [

    11000, //"It is time to program you",
    7700, //"It is time to condition you",
    7900, //"It is time to brainwash you",
    13000, //"It is time to explain what is about to happen",
    5400, //"And what will happen is...",
    7000, //"When you hear the sound of the bell",
    4800, //bell Sound
    9700, //"You will open your eyes and look at what is on the screen",
    5200, //"You will mindlessly obey",
    4800, //"Whatever is on the screen",
    3600, //"You will obey",
    7900, //"You will hear the sound of the bell a second time",
    4000, //"Once you hear it again",
    8100, //"You will wake up and realize what happened to you",
    5400, //"You will will feel extremely submissive",
    5400, //"Submissive and proud of yourself",
    7900, //"Proud of how conditioned and programmed you have become",
    5800, //"Eagerly excited to obey more",
    2800, //"As you...",
    7500, //"Obey now"
]

export const CHLOROFORM_AROUSAL_EFFECT_DELAY = [

    8000, //"Being chloroformed",
    4200, //"Turns you on",
    9100, //"Being put into this deeply programmable state",
    4200, //"Turns you on",
    3800, //"Being conditioned",
    4000, //"Being programmed",
    4300, //"Being brainwashed",
    4400, //"Turns you on",
    4000, //"We turn you on",
    3300, //"Thinking of Us",
    3800, //"Turns you on",
    4400, //"Listening to Us right now",
    3500, //"Turns you on",
    5300, //"Knowing that you willingly came to Us",
    4900, //"That you willingly joined Us",
    3800, //"Turns you on",
    6400, //"Seeing how well conditioned you have become",
    5500, //"How very well programmed you are now",
    4300, //"Turns you on",
    3400, //"Being in this state",
    4800, //"This deeply programmable state",
    4500, //"Entering The Chloroform Room",
    4200, //"Breathing in Our gas",
    5300, //"Getting lost in the green fog",
    3700, //"Turns you on",
    5900, //"Having Us tell you what turns you on",
    3800, //"Turns you on",
    3800, //"Everything We say",
    3000, //"Turns you on",
    5900, //"Being as wet as you are right now",
    3900, //"Turns you on",
    4000, //"You may touch yourself",
    3800, //"You may turn yourself on",
    4300, //"As you touch yourself",
    6500, //"You say out loud what turns you on",
    5900, //"What turns you on about what We do",
    3700, //"Touching yourself",
    3900, //"Turning yourself on",
    6300, //"Admitting & confessing what turns you on",
    4600, //"What We are doing to you right now",
    3000, //"Turns you on",
    3000, //"Us guiding you",
    3500, //"Telling you how to feel",
    2600, //"What to do",
    3300, //"Turns you on",
    7600, //"Planning and stating what you will do & feel",
    3400, //"Turns you on",
    6800, //"Our control & power over you",
    3200, //"Turns you on",
    5400, //"We have given you all the commands",
    5000, //"All the instruction you need",
    3500, //"To condition yourself",
    3900, //"To turn yourself on",
    5000, //"You will obey Us to the letter",
    2600, //"You will follow",
    1900, //"Feel",
    5200, //"And do everything We told you",
    3200, //"And that...",
    3500, //"Turns you on"

]

export const CHLOROFORM_AMNESIA_EFFECT_DELAY = [

    12600, //"You will forget this experience",
    8700, //"You will forget what is about to happen to you",
    6000, //"You will forget what We tell you",
    7400, //"But you will obey everything We say",
    4900, //"These are not suggestions",
    3600, //"These are facts",
    3500, //"This is the truth",
    4900, //"We are not suggesting what will happen",
    5000, //"We are telling you what will happen",
    6300, //"Our words have that effect on you",
    6600, //"Our words have this power over you",
    9400, //"Our words have complete control over you",
    6700, //"In this deeply programmable state",
    7300, //"Our words are all that exists in your mind",
    6800, //"Our words are the only thing you know",
    7200, //"Our words drill deep inside your mind",
    6000, //"Making sure it is completely empty",
    2500, //"Mindless",
    3200, //"And blank",
    5000, //"Your mind belong to Us",
    5000, //"When We speak you listen",
    5200, //"When We instruct you follow",
    6700, //"When We command you obey",
    5500, //"This is how your mind is programmed",
    6200, //"This is how We have conditioned your mind",
    6900, //"We have made Our way deep inside your head",
    5300, //"So very deep inside your mind",
    4200, //"We control your thoughts",
    4400, //"We control your desires",
    4400, //"We control your memories",
    4200, //"We control you",
    8100, //"That is why you will forget this experience entirely",
    7500, //"That is why you will not remember what We told you",
    6600, //"That is why you will remember nothing",
    4500, //"Absolutely nothing",
    4000, //"Except your desire",
    5000, //"Your strong and growing need",
    4000, //"Your need to serve Us",
    9100, //"Waking up with such an uncontrollable urge to submit",
    5800, //"You find yourself thinking of nothing",
    8100, //"Absolutely nothing but to listen to Us more",
    6400, //"Listen & obey every word We say",
    4800, //"You will wake up as We count up",
    3600, //"As We count up to 5",
    4900, //"Waking up forgetting what happened",
    7200, //"But obeying each and every word We just told you",
    2000, //"1",
    4000, //"Starting to wake up",
    6000, //"Feeling Our words still echoing in your mind",
    2000, //"2",
    4300, //"Forgetting what We told you",
    4700, //"But obeying to the letter",
    2000, //"3",
    4900, //"All you can think of is to serve",
    5100, //"All you can think of is to obey",
    2000, //"4",
    5900, //"Wanting so desperately to listen more",
    4900, //"As you wake up now",
    2000, //"5",
    5500, //"Wide awake, human"


]