import React, { Component } from 'react';
import Aux from '../../../../hoc/auxiliary/auxiliary';
import classes from '../HypnosisShowMenu.module.scss'
// import classes2 from '../ExperienceMenu/ExperienceMenu.module.scss'
import HypnosisShow from '../../../../assets/Images/Blank.png'
import ForgetNumber from './HypnosisShowVolForgetNumber'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'

class HypnosisShowBeforeDeepener extends Component {

    state = {
        beforeDeepener: true,
        forgetNumber: false,
    }

    forgetNumberHandler = () => {
        this.setState({ beforeDeepener: false, forgetNumber: true })
    }


    render() {
        let HypnosisShowForm = null
        if (this.state.beforeDeepener) {
            HypnosisShowForm = (
                <html style={{ backgroundImage: `url(${HypnosisShow})` }}>
                    <div className={classes.storyFrom}>
                        <p>
                            <strong>Deep.</strong><br></br>
                        You feel yourself falling deep. Falling so deep under after that snap.
                        Your mind empty without a thought in it, the only thing you are thinking about right now is his voice, The Great O's voice.
                        You smile just thinking about it, making you feel fuzzy and floaty while you continue to drift deeper down.<br></br>
                        <i>“You’re doing wonderful, just relax more. Relax & let my words help you sink deeper, guide you even further relaxing you & droping you deeper. 
                        Deeper & deeper you fall for me. Deeper & deeper you go. 
                        Deeper & deeper ready to do as I say”</i>
                        His voice taking you into newer depth, it takes you to where you want to go. Taking you down further where his voice becomes everything. 
                        Completely engulfing your mind, engulfing your mind with bliss. <br></br>
                        <i>"You want more don't you? you want to drop further and deeper? There is no limit to how deep you go and how deep I can take you.
                            You want me to take you deeper & deeper isn't that right?""
                        </i><br></br>
                        Nodding with a big smile on your face. Repeating in your head...
                        
                        </p>
                        <form className={classes.centered} onClick={this.forgetNumberHandler}>
                            <h3 className={classes.storyButtonSize}>Deeper & Deeper I go</h3>
                        </form>
                    </div>
                </html>
            )
        }
        if (this.state.forgetNumber) {
            HypnosisShowForm = (
                <ForgetNumber />
            )
        }

        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {HypnosisShowForm}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >




        )
    }
}


export default HypnosisShowBeforeDeepener