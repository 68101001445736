import React, { useState } from "react";
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import Button from '../../../../UI/Button/Button'
import classes from '../../../../../containers/HostsChamber/HostsChamber.module.css'
import { ChloroformReinforcement, FastEffectChloroform, NormalEffectChloroform } from '../../../../../store/actions/AudioRuns/audioRunsIndex'
import { connect } from 'react-redux'
import WebsiteAudio from '../../../../Background/WebsiteAudio';
import Background from '../../../../Background/Background'
import FlashyVisual from './FlashyVisual'
import ColorVisual from './ColorsVisual'
import NoBackground from './NoBackground'
import { Redirect } from "react-router-dom";


function Chloroform_Reinforcement({ userId, token, Chloroform_reinforcementComplete, fast_chloroform, normal_chloroform, ChloroformReinforcement, onFastEffectChloroform, onNormalEffectChloroform, profileOptions }) {

    let [chloroform_opening, setProtocol] = useState(true)


    ChloroformReinforcement(userId, token)

    const enterFastChloroformSequenceHandler = () => {
        setProtocol(chloroform_opening = false)
        onFastEffectChloroform()
    }

    const enterNormalChloroformSequencerHandler = () => {
        setProtocol(chloroform_opening = false)
        onNormalEffectChloroform()
    }

    let chloroform_reinforcement_form = null

    if (chloroform_opening) {
        chloroform_reinforcement_form = (
            <Aux>
                <div className={classes.HostsChamber}>
                    <Background />
                    <WebsiteAudio />
                    <div className={classes.FormStyle}>
                        <div className={classes.HostButtons}>
                            <Button btnType="Success" clicked={enterFastChloroformSequenceHandler}>Quick Effect Chloroform</Button>
                            <Button btnType="Success" clicked={enterNormalChloroformSequencerHandler}>Normal Effect Chloroform</Button>
                        </div>
                    </div>
                </div>
            </Aux >
        )
    }

    if (profileOptions.audio_run_background === '0') {
        if (fast_chloroform) {
            chloroform_reinforcement_form = <NoBackground fast_chloroform={fast_chloroform} />
        }


        if (normal_chloroform) {
            chloroform_reinforcement_form = <NoBackground normal_chloroform={normal_chloroform} />
        }
    } else if (profileOptions.audio_run_background === '1') {
        if (fast_chloroform) {
            chloroform_reinforcement_form = <FlashyVisual fast_chloroform={fast_chloroform} />
        }


        if (normal_chloroform) {
            chloroform_reinforcement_form = <FlashyVisual normal_chloroform={normal_chloroform} />
        }
    } else if (profileOptions.audio_run_background === '2') {
        if (fast_chloroform) {
            chloroform_reinforcement_form = <ColorVisual fast_chloroform={fast_chloroform} />
        }


        if (normal_chloroform) {
            chloroform_reinforcement_form = <ColorVisual normal_chloroform={normal_chloroform} />
        }
    }


    if (Chloroform_reinforcementComplete) {
        chloroform_reinforcement_form = <Redirect to="/" />
    }


    return (
        <Aux>
            {chloroform_reinforcement_form}
        </Aux>
    )

}

const mapStateToProps = state => {
    return {
        userId: state.auth.userId,
        token: state.auth.token,
        fast_chloroform: state.chloroReinforce.fast_chloroform,
        normal_chloroform: state.chloroReinforce.normal_chloroform,
        Chloroform_reinforcementComplete: state.chloroReinforce.Chloroform_reinforcementComplete,
        profileOptions: state.options.profileOptions,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        ChloroformReinforcement: (userId, token) => dispatch(ChloroformReinforcement(userId, token)),
        onFastEffectChloroform: () => dispatch(FastEffectChloroform()),
        onNormalEffectChloroform: () => dispatch(NormalEffectChloroform()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Chloroform_Reinforcement)