import React, { useState } from "react";
import TextTransition from "react-text-transition";
import TypingEffect from 'react-typing-effect'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import Button from '../../../../UI/Button/Button'
import classes from '../../../../../containers/HostsChamber/AudioRuns/AudioRuns.module.css'
import * as scripts from '../../../../../shared/AudioRuns/TriggerReinforcement/TentaclesReinforcement/TentaclesReinforcementScripts'
import * as Delays from '../../../../../shared/AudioRuns/TriggerReinforcement/TentaclesReinforcement/TentaclesReinforcementDelay'
import BodyWriting from '../../../../../assets/audio_runs/Trigger_Reinforcement/Tentacle_Reinforcement/Covert_Commands_Body_Writing.mp3'
import Sound from 'react-sound'
import Delay from "react-delay";
import useInterval from 'react-useinterval';
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom'
import { TentacleReinforcementEnd } from '../../../../../store/actions/AudioRuns/audioRunsIndex'

const DELAY_TEXT = [3100]


function Tentacle_Covert_Body_Write_Command({ userId, token, Tentacle_Reinforcement_Complete, TentacleReinforcementEnd, profileOptions }) {
  let [count, setCount] = useState(0);
  let [tentacle_covert_body_write, setProtocol] = useState(true)


  const increaseCount = () => {
    if (tentacle_covert_body_write && count < scripts.COVERT_TENTACLES_BODYWRITING_COMMAND_SCRIPT.length) {
      setCount(count + 1);
    }
  };

  useInterval(increaseCount, Delays.COVERT_BODY_WRITING_COMMAND[count]);

  const enterCovertEdgeEndSequenceHandler = () => {
    setProtocol(tentacle_covert_body_write = false)
    TentacleReinforcementEnd(userId, token, 0, 0, 0, 0, 0, 1)
  }

  let bodyPhrase = Math.floor((Math.random() * 5) + 1)
  let bodyLocation = Math.floor((Math.random() * 5) + 1)
  let bodyDuration = Math.floor((Math.random() * 10) + 3)

  let phrase= ''
  if (bodyPhrase === 1) {
    // eslint-disable-next-line
    phrase = "'I obey what The Hypno Virus say'"
  } else if (bodyPhrase === 2) {
    // eslint-disable-next-line
    phrase = "'A host for The Hypno Virus'"
  } else if (bodyPhrase === 3) {
    // eslint-disable-next-line
    phrase = "'I must serve The Hypno Virus'"
  }else if (bodyPhrase === 4) {
    // eslint-disable-next-line
    phrase = "'The Hypno Virus owns me'"
  }else if (bodyPhrase === 5) {
    // eslint-disable-next-line
    phrase = "'Obedient Host'"
  }

  let location= ''
  if (bodyLocation === 1) {
    location = "Waist"
  } else if (bodyLocation === 2) {
    location = "Chest"
  } else if (bodyLocation === 3) {
    location = "Arms"
  }else if (bodyLocation === 4) {
    location = "Legs"
  }else if (bodyLocation === 5) {
    location = "Forehead"
  }

  let centerText = window.innerHeight / 2 - 55
  let texting2 = null
  if (profileOptions.audio_run_background === '0'){
    texting2 = {

      // display: 'flex',
      // flexFlow: 'column',
      whiteSpace: 'pre-line',
      alignItems: 'center',
      fontWeight: 'bold',
      boxSizing: "border-box",
      margin: 'auto',
      marginBottom: '10px',
      padding: '30px 10px',
      width: '50%',
      paddingTop: centerText + 'px',
      color: 'white'
  
    }
  }

  let command = (
    <div style={texting2}>
      <h3>Covert Body Writing Command</h3>
      <p><TypingEffect typingDelay={100} text={'Phrase: ' + phrase} speed={90} eraseDelay={1000000} /></p>
      <p><TypingEffect typingDelay={100} text={'Location: ' + location} speed={90} eraseDelay={1000000} /></p>
      <p><TypingEffect typingDelay={100} text={'Duration: ' + bodyDuration + ' Hours'} speed={90} eraseDelay={1000000} /></p>
      <br></br>
      <div className={classes.ButtonPadding}>
        <Delay wait={5500}>
          {scripts.BellSound}
        </Delay>
        <Delay wait={6500}>
          <Button btnType="Success" clicked={enterCovertEdgeEndSequenceHandler}>This Host obeys</Button>
        </Delay>
      </div>
    </div>
  )

  let texting = null
  if (profileOptions.audio_run_background === '0'){
    texting = {

      // display: 'flex',
      // flexFlow: 'column',
      whiteSpace: 'pre-line',
      alignItems: 'center',
      fontWeight: 'bold',
      boxSizing: "border-box",
      margin: 'auto',
      marginBottom: '10px',
      padding: '30px 10px',
      width: '50%',
      paddingTop: centerText + 'px',
      color: 'white'
  
    }
  }

  let tentacles_covert_body_write_command_form = null

  if (tentacle_covert_body_write) {
    tentacles_covert_body_write_command_form = (
      <Aux>
        {count < scripts.COVERT_TENTACLES_BODYWRITING_COMMAND_SCRIPT.length ?
          <div style={texting}>
            <Sound url={BodyWriting} playStatus={Sound.status.PLAYING} />
            <Delay wait={DELAY_TEXT[count]}>
              <TextTransition
                text={<TypingEffect typingDelay={100} text={scripts.COVERT_TENTACLES_BODYWRITING_COMMAND_SCRIPT[count]} speed={90} eraseDelay={1000000} />}
                Overflow
              />
            </Delay>
          </div>
          :
          <div>
            {command}
          </div>
        }
      </Aux >
    )
  }

  if (Tentacle_Reinforcement_Complete) {
    tentacles_covert_body_write_command_form = <Redirect to="/" />
  }

  return (
    <Aux>
      {tentacles_covert_body_write_command_form}
    </Aux>
  )

}

const mapStateToProps = state => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    Tentacle_Reinforcement_Complete: state.tentacleReinforce.Tentacle_Reinforcement_Complete,
    profileOptions: state.options.profileOptions,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    TentacleReinforcementEnd: (userId, token, daily_pleasure, daily_spank, daily_electric_spank, covert_fullday, covert_edging, covert_body_writing) => dispatch(TentacleReinforcementEnd(userId, token, daily_pleasure, daily_spank, daily_electric_spank, covert_fullday, covert_edging, covert_body_writing)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tentacle_Covert_Body_Write_Command)