import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    hostName: null,
    hostGender: null,
    runSpeed: 110,
    error: null,
    loading: false,
    introduction: false, 
    induction: false,
    inductionMethod: 0,
    foreheadInduction: false,
    bellInduction: false,
    mantra: false,
    deepener: false,
    deepenerMethod: 0,
    tickingClockDeepener: false,
    metronomeDeepener: false,
    trigger: false,
    triggerMethod: 0,
    triggerRepetition: false,
    triggerCommand: false,
    awakener: false,
    awakenerRemember: false,
    awakenerForget: false,
    ending: false,
    restartHostRun: false,
    BellInductionCounter: 0,
    ForeheadTapInductionCounter: 0,
    MetronomeDeepenerCounter: 0,
    TickingClockDeepenerCounter: 0,
    MantraCounter: 0,
    TriggerCommandCounter: 0,
    TriggerRepetitionCounter: 0,
    AwakenerRememberCounter: 0,
    AwakenerForgetCounter:0,
    Hosts_Run_Counter: 0,
    conditioningStages: false,
    audioRuns: false,
    text2speech: false,
    conditioning_protocols: false,
    trigger_introduction: false,
    trigger_reinforcement: false

}


const hostsChamberSuccess = (state, action) => {
    return updateObject(state, {
        introduction: true,
        restartHostRun: false
    })

}

const hostIntroduction = (state, action) => {
    return updateObject(state, {
        introduction: false,
        induction: true
    })
}

const hostInduction = (state, action) => {
    if (action.inductionMethod === 1){
        return updateObject(state, {
            induction: false,
            foreheadInduction: true,
            ForeheadTapInductionCounter: 1
        })
    } else if (action.inductionMethod === 2){
        return updateObject(state, {
            induction: false,
            bellInduction: true,
            BellInductionCounter: 1
        })
    }
    
}

const bellInduction = (state, action) => {
    return updateObject(state, {
        bellInduction: false,
        mantra: true
    })
}

const foreheadInduction = (state, action) => {
    return updateObject(state, {
        foreheadInduction: false,
        mantra: true
    })
}

const hostMantra = (state, action) => {
    return updateObject(state, {
        mantra: false,
        deepener: true,
        MantraCounter: 1
    })
}

const hostDeepener = (state, action) => {
    if (action.deepenerMethod === 1){
        return updateObject(state, {
            deepener: false,
            tickingClockDeepener: true,
            TickingClockDeepenerCounter: 1
        })
    } else {
        return updateObject(state, {
            deepener: false,
            metronomeDeepener: true,
            MetronomeDeepenerCounter: 1
        })
    }
    
}

const tickingClockDeepener = (state, action) => {
    return updateObject(state, {
        tickingClockDeepener: false,
        trigger: true
    })
}


const metronomeDeepener = (state, action) => {
    return updateObject(state, {
        metronomeDeepener: false,
        trigger: true
    })
}

const hostTrigger = (state, action) => {
    if (action.triggerMethod === 1){
        return updateObject(state, {
            trigger: false,
            triggerRepetition: true,
            TriggerRepetitionCounter:1
        })
    } else {
        return updateObject(state, {
            trigger: false,
            triggerCommand: true,
            TriggerCommandCounter: 1

        })
    }
    
}

const triggerCommand = (state, action) => {
    return updateObject(state, {
        triggerCommand: false,
        awakener: true
    })
}

const triggerRepetition = (state, action) => {
    return updateObject(state, {
        triggerRepetition: false,
        awakener: true
    })
}

const hostAwakener = (state, action) => {
    if (action.awakenerMethod === 1){
        return updateObject(state, {
            awakener: false,
            awakenerForget: true,
            AwakenerForgetCounter: 1
        })
    } else {
        return updateObject(state, {
            awakener: false,
            awakenerRemember: true,
            AwakenerRememberCounter: 1
        })
    }
    
}

const awakenerRemember = (state, action) => {
    return updateObject(state, {
        awakenerRemember: false,
        Hosts_Run_Counter: 1,
        ending: true
    })
}

const awakenerForget = (state, action) => {
    return updateObject(state, {
        awakenerForget: false,
        Hosts_Run_Counter: 1,
        ending: true
    })
}

const hostEnding = (state, action) => {
    return updateObject(state, {
        ending: false,
        loading: false,
        restartHostRun: true
    })
}

const viewConditioningStages = (state, action) => {
    return updateObject(state, {
        conditioningStages: true,
    })
}

const viewAudioRun = (state, action) => {
    return updateObject(state, {
        audioRuns: true,
    })
}

const viewConditioningProtocol = (state, action) => {
    return updateObject(state, {
        conditioning_protocols: true,
    })
}

const viewTriggerIntroduction = (state, action) => {
    return updateObject(state, {
        trigger_introduction: true,
    })
}

const viewTriggerReinforcement = (state, action) => {
    return updateObject(state, {
        trigger_reinforcement: true
    })
}

const viewTextToSpeech = (state, action) => {
    return updateObject(state, {
        text2speech: true,
    })
}

const returnHostsChambers = (state, action) => {
    return updateObject(state, {
        conditioningStages: false,
        audioRuns: false,
        text2speech: false,
        conditioning_protocols: false,
        trigger_introduction: false,
        trigger_reinforcement: false
    })
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.START_HOSTS_CHAMBER: return hostsChamberSuccess(state, action)
        case actionTypes.HOST_INTRODUCTION: return hostIntroduction(state, action)
        case actionTypes.HOST_INDUCTION: return hostInduction(state, action)
        case actionTypes.HOST_BELL_INDUCTION: return bellInduction(state, action)
        case actionTypes.HOST_FOREHEAD_INDUCTION: return foreheadInduction(state, action)
        case actionTypes.HOST_MANTRA: return hostMantra(state, action)
        case actionTypes.HOST_DEEPENER: return hostDeepener(state, action)
        case actionTypes.HOST_TICKING_CLOCK_DEEPENER: return tickingClockDeepener(state, action)
        case actionTypes.HOST_METRONOME_DEEPENER: return metronomeDeepener(state, action)
        case actionTypes.HOST_TRIGGER: return hostTrigger(state, action)
        case actionTypes.HOST_TRIGGER_COMMAND: return triggerCommand(state, action)
        case actionTypes.HOST_TRIGGER_REPETITION: return triggerRepetition(state, action)
        case actionTypes.HOST_AWAKENER: return hostAwakener(state, action)
        case actionTypes.HOST_AWAKENER_REMEMBER: return awakenerRemember(state, action)
        case actionTypes.HOST_AWAKENER_FORGET: return awakenerForget(state, action)
        case actionTypes.HOST_ENDING: return hostEnding(state, action)
        case actionTypes.VIEW_CONDITIONING_STAGES: return viewConditioningStages(state, action)
        case actionTypes.VIEW_AUDIO_RUN: return viewAudioRun(state, action)
        case actionTypes.VIEW_CONDITIONING_PROTOCOL: return viewConditioningProtocol(state, action)
        case actionTypes.VIEW_TRIGGER_INTRODUCTION: return viewTriggerIntroduction(state, action)
        case actionTypes.VIEW_TRIGGER_REINFORCEMENT: return viewTriggerReinforcement(state, action)
        case actionTypes.VIEW_TEXT_TO_SPEECH: return viewTextToSpeech(state, action)
        case actionTypes.RETURN_HOSTS_CHAMBER: return returnHostsChambers(state, action)
        default:
            return state

    }
}


export default reducer