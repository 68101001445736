import React from 'react';
import TypingEffect from 'react-typing-effect';
import Delay from 'react-delay'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import Sound from 'react-sound'
import mantra2 from '../../../../../assets/audio/mantra2.mp3'
import mantra3 from '../../../../../assets/audio/mantra3.mp3'
import classes from '../../FreerunComponent.module.css'

const mantra = (props) => {
    // eslint-disable-next-line
    let mantraDuration = Math.floor(Math.random() * 2) + 1;
    let mantraDelay = 0
    let mantraAudio = null
    if (mantraDuration === 1){
        mantraAudio = (<Sound  url={mantra2}
        playStatus={Sound.status.PLAYING} />)
        mantraDelay = 120000
    } else {
        mantraAudio = (<Sound  url={mantra3}
        playStatus={Sound.status.PLAYING} />)
        mantraDelay = 180000
    }
    // eslint-disable-next-line
    const mantra = '"No Thoughts"\n\
                    "No Will"\n\
                    "I Obey What The Hypno Virus Say"\n\n\
                    This is your mantra\n\
                    This is the mantra of The Hypno Virus\n\
                    Whenever you think about it\n\
                    You feel quite docile\n\
                    Compliant\n\
                    Submissive\n\
                    Eager...\n\
                    Eager to obey\n\
                    Eager & Excited to obey The Hypno Virus\n\
                    In a moment you will start hearing music playing\n\
                    As soon as the music starts playing you will start reciting your mantra\n\
                    Over & Over\n\
                    Until The Bell rings\n\
                    Once The Bell rings We will allow you to sink deeper into Our control\n\
                    Now it is time to recite your mantra, '+ // eslint-disable-next-line
                    (props.naming)
    let duration = 150 + (mantra.length * 115)
    mantraDelay = duration +mantraDelay+5000
    let mantraParagraph = (
            <Aux>
                <h3 className= {classes.titles}>MANTRA</h3>
                <TypingEffect typingDelay={5000} text={mantra} speed={110} eraseDelay={1000000} />
                <Delay wait={duration+5000}>
                    {mantraAudio}
                </Delay>
                <Delay wait={mantraDelay}><h4 className={classes.blinking} onClick={props.mantra}>I am ready to be taken deeper</h4></Delay>
            </Aux>
    )
    return mantraParagraph
}


export default mantra