import React, { useState } from "react";
import TextTransition from "react-text-transition";
import TypingEffect from 'react-typing-effect'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import * as scripts from '../../../../../shared/AudioRuns/TriggerReinforcement/ChloroformReinforcement/ChloroformReinforcementScripts'
import * as Delays from '../../../../../shared/AudioRuns/TriggerReinforcement/ChloroformReinforcement/ChloroformReinforcementDelay'
import Fast from '../../../../../assets/audio_runs/Trigger_Reinforcement/Chloroform_Reinforcement/Fast_Effect_Chloroform.mp3'
import Sound from 'react-sound'
import Delay from "react-delay";
import useInterval from 'react-useinterval';
import ChloroformBodyPlay from './Chloroform_Body_Play'
import ArousalEffect from './Chloroform_Arousal_Effect'
import AmnesiaEffect from './Chloroform_Amnesia_Effect'

const DELAY_TEXT = [4000]


function Fast_Chloroform_Effect() {
    let [count, setCount] = useState(0);
    let [fast_chloroform, setProtocol] = useState(true)
    let effect = 1

    const increaseCount = () => {
        if (count <= scripts.CHLOROFORM_FAST_DROP.length) {
            setCount(count + 1);
        } else {
            setProtocol(fast_chloroform = false)
        }
    };

    useInterval(increaseCount, Delays.CHLOROFORM_FAST_DROP_DELAY[count]);

    let centerText = window.innerHeight / 2 - 55
    let texting = {

        // display: 'flex',
        // flexFlow: 'column',
        whiteSpace: 'pre-line',
        alignItems: 'center',
        fontWeight: 'bold',
        boxSizing: "border-box",
        margin: 'auto',
        marginBottom: '10px',
        padding: '30px 10px',
        width: '50%',
        paddingTop: centerText + 'px',
        color: 'White'

    }
    let fast_chloroform_effect_form = null
    if (fast_chloroform) {
        fast_chloroform_effect_form = (
            <Aux>
                {count < scripts.CHLOROFORM_FAST_DROP.length ?
                    <div style={texting}>
                        <Sound url={Fast} playStatus={Sound.status.PLAYING} />
                        <Delay wait={DELAY_TEXT[count]}>
                            <TextTransition
                                text={<TypingEffect typingDelay={100} text={scripts.CHLOROFORM_FAST_DROP[count]} speed={50} eraseDelay={1000000} />}
                                Overflow
                            />
                        </Delay>
                    </div >
                    :
                    effect === 1 ?
                            <ChloroformBodyPlay />
                            :       
                                Delays.CHLOROFORM_EFFECT === 2 ?
                                    <ArousalEffect />
                                    :       
                                        Delays.CHLOROFORM_EFFECT === 3 ?
                                            <AmnesiaEffect />
                                            : null
                }
            </Aux>
        )
    }

    return (
        <Aux>
            {fast_chloroform_effect_form}
        </Aux>
    )

}

export default Fast_Chloroform_Effect