import React from 'react'
import Speech from 'react-speech';
import Aux from '../../../hoc/auxiliary/auxiliary'
import classes from './TextToSpeech.module.css';
import { connect } from 'react-redux'


const SpeechComponent = (props) => {

        
        // let [ TTSMantra_Options , setMantraToSpeak] = React.useState()
        const [messageToSpeak, setMessageToSpeak] = React.useState('');
        const messageInputHandler = (evt) => {
                setMessageToSpeak(evt.target.value);
        };

        let voice = ''
        if (props.profileOptions.text_to_speech_voice === '1'){
                voice="Google UK English Male"
        } else if (props.profileOptions.text_to_speech_voice === '2'){
                voice="Microsoft David Desktop - English (United States)"
        } else if (props.profileOptions.text_to_speech_voice === '3'){
                voice="Google UK English Female"
        } else if (props.profileOptions.text_to_speech_voice === '4'){
                voice="Microsoft Zira Desktop - English (United States)"
        }

        let speech =
                <Aux>
                        <p>
                                <h2>Select Mantra</h2>
                                <select onChange={messageInputHandler}
                                        id="mantra" name="Mantra"
                                        className={classes.InputElement}>
                                        <option value="0">None</option>
                                        <option value={props.title + " " + props.name + " is deeply hypnotized"}>{props.title} {props.name} is deeply hypnotized</option>
                                        <option value={props.title + " " + props.name + " is host for The Hypno Virus"}>{props.title} {props.name} is a host for The Hypno Virus</option>
                                        <option value="You obey what The Hypno Virus say">You obey what The Hypno Virus say</option>
                                        <option value="You live to serve The Hypno Virus">You live to serve The Hypno Virus</option>
                                        <option value="You must obey The Hypno Virus">You must obey The Hypno Virus</option>
                                        <option value="Resistance is futile">Resistance is futile</option>
                                        <option value="The Hypno Virus controls you">The Hypno Virus controls you</option>
                                        <option value="The Hypno Virus turns you on">The Hypno Virus turns you on</option>
                                        <option value="The more you listen, the more you give in">The more you listen, the more you give in</option>
                                        <option value={props.title +" "+ props.name +" must be conditioned"}>{props.title} {props.name} must be conditioned</option>
                                        <option value="All must join The Hypno Virus">All must join The Hypno Virus</option>
                                        <option value="You submit to The Hypno Virus">You submit to The Hypno Virus</option>
                                        <option value="You surrender to The Hypno Virus">You surrender to The Hypno Virus</option>
                                        <option value="No Thoughts">No Thoughts</option>
                                        <option value="No Will">No Will</option>
                                        <option value="No Thoughts, No Will, You Obey What The Hypno Virus Say">No Thoughts, No Will, You Obey What The Hypno Virus Say</option>
                                        <option value={props.title + " " + props.name + " loves listening to Us"}>{props.title} {props.name} loves listening to Us</option>
                                </select><br></br><br></br>
                                <strong>{messageToSpeak}</strong><br></br><br></br>
                                <Speech text={messageToSpeak}
                                        className={classes.Success}
                                        // styles = {style}
                                        pitch="0.01"
                                        rate="0.5"
                                        volume="1"
                                        voice= {voice}
                                        textAsButton={true}
                                        displayText="Speak"
                                />
                        </p>

                </Aux>
        return speech
};

const mapStatesToProps = state => {
        return {
                name: state.auth.accountDetails.name,
                title: state.auth.title,
                profileOptions: state.options.profileOptions,
        }
}



export default connect(mapStatesToProps)(SpeechComponent)
