import * as audioRunTypes from '../audioRunTypes'
import axios from '../../../../axios'

export const ChloroformIntroductionStart = () => {
    return {
        type: audioRunTypes.AUDIO_RUN_START
    }
}

export const ChloroformIntroductionSuccess = (Chloroform_introduction) => {
        return {
            type: audioRunTypes.CHLOROFORM_INTRODUCTION_SUCCESS,
            Chloroform_introduction: Chloroform_introduction,
        } 
}

export const ChloroformRoomIntroduction = () => {
    return {
        type: audioRunTypes.CHLOROFORM_ROOM_INTRODUCTION,
    } 
}


export const ForcedChloroformIntroduction = () => {
    return {
        type: audioRunTypes.FORCED_CHLOROFORM_INTRODUCTION,
    } 
}

export const ChloroformIntroduction = (userId, token) => {
    return dispatch => {
        dispatch(ChloroformIntroductionStart());
        axios.get('/Runs/'+userId+'/AudioRuns/Trigger_Introduction/Chloroform_Introduction.json?auth=' + token)
            
            .then( response => {
                // dispatch(ObedienceStageStart());
                if(response.data === null){
                    let Chloroform_introduction = {
                        Chloroform_introduction: 0,
                        chloroform_room:0,
                        forced_chloroform:0,
                    }
                    axios.put('/Runs/'+userId+'/AudioRuns/Trigger_Introduction/Chloroform_Introduction.json?auth=' + token, Chloroform_introduction)
                    .then(response => {
                        dispatch(ChloroformIntroductionSuccess(response.data))
                    })
                    .catch(error => {
                        dispatch(ChloroformIntroductionFailed(error.message.data))
                    })
                    
                } else{
                    dispatch(ChloroformIntroductionSuccess(response.data))
                }
                       
            })
            .catch(error => {
                dispatch(ChloroformIntroductionFailed(error.message.data))
            })
    }
}

export const ChloroformIntroductionFailed = (error) => {
    return{
        type: audioRunTypes.AUDIO_RUN_FAILED,
        error: error
    }
}

export const ChloroformintroductionEnd = (userId, token, chloroform_room, forced_chloroform) => {
    return dispatch => {
        axios.get('/Runs/'+userId+'/AudioRuns/Trigger_Introduction/Chloroform_Introduction.json?auth=' + token)
            .then( response => {
                    let updatedChloroform_introduction = {
                        Chloroform_introduction: response.data.Chloroform_introduction + 1,
                        chloroform_room: response.data.chloroform_room + chloroform_room,
                        forced_chloroform: response.data.forced_chloroform + forced_chloroform,
                    }
                    axios.put('/Runs/'+userId+'/AudioRuns/Trigger_Introduction/Chloroform_Introduction.json?auth=' + token, updatedChloroform_introduction)
                    .then(response => {
                        dispatch(ChloroformIntroductionCompleted())
                    })
                    .catch(error => {
                        dispatch(ChloroformIntroductionFailed(error.message.data))
                    })    
                       
            })
            .catch(error => {
                dispatch(ChloroformIntroductionFailed(error.message))
            })
    }
}

export const ChloroformIntroductionCompleted = () => {
    return{
            type: audioRunTypes.CHLOROFORM_INTRODUCTION_COMPLETED,
        }
}