import React from 'react'
import classes from '../../containers/Account/Account.module.css'
import Input from '../UI/Input/Input'
import Button from '../UI/Button/Button'
import Aux from '../../hoc/auxiliary/auxiliary'

const passwordDatabase = (props) => {

    let passwordForm = (
        <Aux>
            <div className={classes.AccountContainer} >
                <form className={classes.FormStyle}>
                    Enter the Password, Human!
                {props.formElementsArray.map(formElement => (
                    <Input
                        key={formElement.id}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        invalid={!formElement.config.valid}
                        shouldValidate={formElement.config.validation}
                        touched={formElement.config.touched}
                        changed={(event) => props.inputChangedHandler(event, formElement.id)} />
                ))}
                    <Button btnType='Success' disabled={!props.PasswordEntered} clicked={props.submitHandler} >{props.PasswordSubmitted? "Welcome to your real life, Host":"Please Confirm You Want To Enter, Human"} </Button>
                    <p className={classes.PasswordLink}>You Can Find The Password <a className={classes.SpecialLink} href="https://www.patreon.com/posts/hypno-virus-34499031">Here</a></p>
                </form>
        </div> 
     </Aux>)


    return passwordForm 
}

export default passwordDatabase