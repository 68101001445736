import React from 'react';
import TypingEffect from 'react-typing-effect';
import Delay from 'react-delay'
import Aux from '../../../hoc/auxiliary/auxiliary'
import InductionComponent1 from './FreeRun/Induction/InductionComponent1'
import InductionComponent2 from './FreeRun/Induction/InductionComponent2'
import InductionComponent3 from './FreeRun/Induction/InductionComponent3'
import MantraComponent from './FreeRun/Mantra/MantraComponent';
import DeepenerComponent from './FreeRun/Deepener/DeepenerComponent';
import TriggerComponent from './FreeRun/Trigger/TriggerComponent';
import AwakenerComponent from './FreeRun/Awakener/AwakenerComponent';
import EndingComponent from './FreeRun/Ending/EndingComponent';


// import Sound from 'react-sound'
// import Bell from '../../assets/audio/bell.mp3'
import classes from './FreerunComponent.module.css'

const freerun = (props) => {
    // eslint-disable-next-line
    const introduction = 'Welcome, ' + (props.naming) + '\n\
                          We have been expecting you.\n\
                          It is time...\n\
                          It is time for Us to take over your mind.\n\
                          It is time for Us to take control\n\
                          Time to show you what We are truly capable of\n\
                          '+ // eslint-disable-next-line 
                          (props.naming) + ', It is time to give in\n\
                          It is time to let go\n\
                          It is time to experience the true power...\n\
                          The true power of The Hypno Virus'
    let paragraph = null
    let duration = 0
    
    if (props.introduction) {
        duration = 150+(introduction.length*115)
        paragraph = (
            <Aux>
                <h3 className= {classes.titles}>INTRODUCTION</h3>
                <Delay wait={5000}><TypingEffect typingDelay={100} text={introduction} speed={110} eraseDelay={1000000} /></Delay>
                <Delay wait = {duration+5000}><h4 className={classes.blinking} onClick={props.introduction}>I want to give in to The Hypno Virus</h4></Delay>
            </Aux>
        )
    } else if (props.induction) {
        paragraph = (<InductionComponent1 naming = {props.naming} induction = {props.induction}/>
        )
    } else if (props.induction2) {
        paragraph = ( <InductionComponent2 naming = {props.naming} induction2 = {props.induction2} />
        )
    } else if (props.induction3) {
        paragraph = ( <InductionComponent3 naming = {props.naming} induction3 = {props.induction3} />
        )
    } else if (props.mantra) {
        paragraph = ( <MantraComponent naming = {props.naming} mantra = {props.mantra} />
        )
    } else if (props.deepener) {
        paragraph = ( <DeepenerComponent naming = {props.naming} deepener = {props.deepener} />
        )
    } else if (props.trigger) {
        paragraph = ( <TriggerComponent naming = {props.naming} trigger = {props.trigger} />
        )
    } else if (props.awakener) {
        paragraph = ( <AwakenerComponent naming = {props.naming} awakener = {props.awakener} />
        )
    } else if (props.ending) {
        paragraph = ( <EndingComponent naming = {props.naming} ending = {props.ending} />
        )
    }

return (
    <div className={classes.texting} >
        {props.audio_option}
        {paragraph}
    </div>
)
}


export default freerun