import React, { Component } from 'react';
import Aux from '../../../../hoc/auxiliary/auxiliary';
import classes from '../HypnosisShowMenu.module.scss'
// import classes2 from '../ExperienceMenu/ExperienceMenu.module.scss'
import HypnosisShow from '../../../../assets/Images/Blank.png'
import Suggestions from '././HypnosisShowVolSuggestions'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'

class HypnosisShowForgetNumber extends Component {

    state = {
        ForgetNumber: true,
        suggestions: false,
    }

    suggestionsHandler = () => {
        this.setState({ ForgetNumber: false, suggestions: true })
    }


    render() {
        let HypnosisShowForm = null
        if (this.state.ForgetNumber) {
            HypnosisShowForm = (
                <html style={{ backgroundImage: `url(${HypnosisShow})` }}>
                    <div className={classes.storyFrom}>
                        <p>
                            <i>"Deeper & Deeper I go"</i> Repeating it over & over.<br></br>
                            Mindlessly empty & happy, falling deeper under & loving every moment of it. Once you hear his voice everything else goes quiet. 
                            He becomes all you focus on & all your hear. <br></br>
                            <i>“Aren't you having fun? Enjoying yourself & how good you feel. Feeling so good listening to my voice and following it. 
                                Deeper & deeper you go for it, Deeper & deeper you go for me. Don't you just love that? <br></br>
                            Of course you do! Wouldn't you love to listen some more and follow what I tell you? Doesn't that sound perfect? <br></br>
                            You know what the best part is? You don't need to do anything but forget one simple number. All you need to do is forget the number five. See, it's that simple!
                            All you need to do is forget the number five when I wake. You can do that can't you? I know you can!” </i> <br></br>
                            Tapping your forehead he says <br></br>
                            <i>“POP! The number five is now gone from your mind. It's locked in a safe place for now and you don't have to worry about it. <br></br>
                            What I want you to do for me right now though is start waking up <br></br>
                            One, Feeling warm & fuzzy. <br></br>
                            Two, Feeling good & happy.<br></br>
                            Three, excited to follow this simple task. <br></br>
                            Four, Forgetting a single number <br></br>
                            Five, wide awake"</i> <br></br>
                            <strong>*SNAP* </strong> <br></br>
                            Blinking rapidly for a couple of seconds as you take a deep breath, stretching you slowly feel yourself waking up. 
                            Waking up remembering where you are & what you're doing. <br></br> 
                            You take a few seconds to think back on what happened, trying to remember the sequence of events that occured. 
                            All while feeling amazing, feeling like you just woke up from a beautiful nap, woke up from a nice, soothing nap. <br></br> 
                            You've been lost in your thoughts you're only just noticing what is going on around you. 
                            Hearing The Great O’s voice talking to one of the volunteers he tells him to raise his hands in front of him and count each finger. <br></br>
                            One<br></br>
                            Two<br></br>
                            Three<br></br>
                            Four<br></br>
                            Six<br></br>
                            Seven<br></br>
                            Eight<br></br>
                            Nine<br></br>
                            Ten<br></br>
                            Eleven<br></br>
                            Eleven?! <br></br>
                            <i>"How does he have eleven fingers? Something must be wrong! He looks as confused as I am"</i> you think to yourself. 
                            In fact it seems like you are all confused to how that is possible <br></br>
                            While you try to make sense of it you see The Great O suddenly holds his head telling him <strong>“Sleep now”</strong> the guys head falls forward as he slumps into his chair. <br></br>
                            You however, you feel a shiver from hearing these words. You shudder from how these two words made you feel. You know they were not directed towards you but you felt their effect on you. You're still feeling their effect on you.
                            How your thoughts all came to a halt as soon as you heard him saying those words. Your eyes fluttered for a seconded feeling a strong pull that wanted you to fall but you caught yourself just in time. <br></br>
                            You try to compose yourself again, sitting up straight in your chair you see he said something to the audience you didn't notice but they were laughing so it must have been funny. You chuckle to yourself wondering what he might have said.
                            But the chuckles don't last long as he turns around facing you and walking towards you. In a much softer, more relaxing & soothing one he tells you <br></br>
                            <i>“Would you kindly raise your hands in front of you?” </i><br></br>
                            Without thinking you raise your hands, you raise your hands right in front of you. Looking at your hands, focusing on them as you see his finger pointing towards your thumb with him saying <br></br>
                            <i>“Count along as I point on each finger” </i> <br></br>
                            You nod without thinking and start saying the numbers loud while he points. <br></br>
                            <i>One</i><br></br>
                            <i>Two</i><br></br>
                            <i>Three</i><br></br>
                            <i>Four</i><br></br>
                            <i>Six</i><br></br>
                            <i>Seven</i><br></br>
                            <i>Eight</i><br></br>
                            <i>Nine</i><br></br>
                            <i>Ten</i><br></br>
                            <i>Eleven</i><br></br>
                            It takes you a second before you realize...<br></br>
                            <strong>Eleven?!</strong><br></br>
                            Confused & nervous, you look up at him like a lost puppy not sure what is going on exactly. But he just smiles, he smiles softly and you immediately feel at ease & smile in return<br></br>
                            <i>“Let’s try this again, shall we?” </i><br></br>
                            Before you can react you see his index finger moving with your eyes wide open following as it taps your forehead. <br></br>
                            <i>“Pop. Back to normal! I think you'll find this time things make much more sense, would you kindly raise your hands again” </i><br></br> 
                            You nod while smiling already feeling at ease from his reassurance & you start to count with him <br></br>
                            <i>One</i><br></br>
                            <i>Two</i><br></br>
                            <i>Three</i><br></br>
                            <i>Four</i><br></br>
                            <i>Five</i><br></br>
                            <i>Six</i><br></br>
                            <i>Seven</i><br></br>
                            <i>Eight</i><br></br>
                            <i>Nine</i><br></br>
                            <i>Ten</i><br></br>
                            Sighing in relief knowing that you’re back to normal and understanding what he did to you. Thinking to yourself <br></br>
                            <i>"OMG did he really make me forget the number five and I had no idea! No way! This is crazy! This is..."</i> <br></br>
                            He interrupts your train of thought by feeling his hand holding your head as he says in a firm, commanding yet soothing tone <br></br>
                            <strong>“SLEEP NOW” </strong><br></br>
                            Deep, deep in trance. Deep in to this wonderful, blissful trance you go. Loving how deep you fall and ready for what he does next. Ready to go
                        </p>
                        <form className={classes.centered} onClick={this.suggestionsHandler}>
                            <h3 className={classes.storyButtonSize}>Even Deeper</h3>
                        </form>
                    </div>
                </html>
            )
        }
        if (this.state.suggestions) {
            HypnosisShowForm = (
                <Suggestions />
            )
        }

        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {HypnosisShowForm}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >




        )
    }
}


export default HypnosisShowForgetNumber