import * as actionTypes from './authTypes'
import axios from 'axios'
import customAxios from '../../../axios';

//--------------------------------------------PASSWORD AUTHENTICATION----------------------------------
export const passwordAuth = (password) => {
    return dispatch => {
        dispatch(passwordStart());
        customAxios.get('/Password.json')
            .then(response => {
                let intPass = JSON.stringify(response.data.password)
                if (password !== intPass) {
                    dispatch(passwordFail('Wrong Password!'))
                } else {
                    dispatch(passwordSuccess(intPass));
                }
            })
            .catch(error => {
                dispatch(passwordFail(error.response.data.error))
            })
    }
}

export const passwordStart = () => {
    return {
        type: actionTypes.PASSWROD_START
    }
}

export const passwordSuccess = (password) => {
    return {
        type: actionTypes.PASSWORD_SUCCESS,
        password: password,
    }
}

export const passwordFail = (error) => {
    return {
        type: actionTypes.PASSWORD_FAILED,
        error: error
    }
}

//--------------------------------------------------USER AUTHENTICATION-------------------------------------

export const Auth = (email, password, isSignUp) => {
    return dispatch => {
        dispatch(authStart());
        const authData = {
            email: email,
            password: password,
            returnSecureToken: true
        };
        let url = 'https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyCJwAwwwxPnkndoDEOWO54JwgR6tOeNRo8'
        if (!isSignUp) {
            url = 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyCJwAwwwxPnkndoDEOWO54JwgR6tOeNRo8'
        }
        axios.post(url, authData)
            .then(response => {
                const expirationDate = new Date(new Date().getTime() + response.data.expiresIn * 1000)
                dispatch(checkVerification(response.data.idToken, response.data.localId, email, password, expirationDate, isSignUp))
                dispatch(checkAuthTimeout(response.data.expiresIn));

            })
            .catch(error => {
                dispatch(authFail(error.response.data.error.message))
            })
    }
}

export const checkVerification = (token, userId, email, password, expirationDate, isSignUp) => {
    return dispatch => {
        const authData = {
            idToken: token,
        };
        let url = 'https://identitytoolkit.googleapis.com/v1/accounts:lookup?key=AIzaSyCJwAwwwxPnkndoDEOWO54JwgR6tOeNRo8'
        axios.post(url, authData)
            .then(response => {
                if (!response.data.users[0].emailVerified) {
                    if (!isSignUp){
                        dispatch(authFail('Verify Email!'))
                    }
                    dispatch(emailSuccess(token, isSignUp))
                } else {
                    localStorage.setItem('token', token);
                    localStorage.setItem('expirationDate', expirationDate)
                    localStorage.setItem('userId', userId)
                    customAxios.get('/VerifiedAccounts/' + userId + '/.json?auth=' + token)
                        .then(response => {
                            if (response.data) {
                                dispatch(fetchProfile(token, userId))
                            }
                        })
                        .catch(error => {
                            dispatch(authFail(error.response.data.error.message))
                        })
                    dispatch(authSuccess(token, userId, email, password));

                }
            })
            .catch(error => {
                dispatch(authFail(error.response.data.error.message))
            })
    }
}

export const emailSuccess = (token, isSignUp) => {
    return dispatch => {
        let url = 'https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=AIzaSyCJwAwwwxPnkndoDEOWO54JwgR6tOeNRo8'
        let verifyForm = {
            requestType: 'VERIFY_EMAIL',
            idToken: token
        }
        axios.post(url, verifyForm)
            .then(response => {
                if(!isSignUp){
                    dispatch(authFail('Verify Email!'))
                }
                dispatch(checkEmail())
            })
            .catch(error => {
                if(!isSignUp){
                    dispatch(authFail('Verify Email!'))
                }else{
                    dispatch(authFail(error.response.data.error))
                }
                
            })
    }
}

export const checkEmail = () => {
    return {
        type: actionTypes.VERIFY_EMAIL_STATUS
    }
}

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
}

export const authSuccess = (token, userId, email, password) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
        userId: userId,
        email: email,
        password: password,
    }
}

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    }
}

export const logout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('expirationDate')
    localStorage.removeItem('userId')
    return {
        type: actionTypes.AUTH_LOGOUT
    }
}

export const resetLogout = () => {
    return {
        type: actionTypes.RESET_LOGOUT
    }
}

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout())
        }, expirationTime * 1000)
    }
}

// //-----------------------------FETCH PROFILE DETAILS---------------------------------

export const fetchProfile = (token, userId) => {
    return dispatch => {
        dispatch(authStart());
        // const queryParams = '?auth=' + token + '&orderBy="userId"&equalTo="' + userId + '"'
        customAxios.get('/Accounts/' + userId + '.json?auth=' + token)
            .then(response => {
                const AccountInfo = [];

                AccountInfo.push({
                    ...response.data,
                });
                dispatch(fetchTitle(token, userId, AccountInfo[0]))
            })
            .catch(error => {
                dispatch(authFail(error.response.data.error))
            })
    }
}

export const fetchTitle = (token, userId, AccountInfo) => {
    return dispatch => {
        
        dispatch(authStart());
        // const queryParams = '?auth=' + token + '&orderBy="userId"&equalTo="' + userId + '"'
        customAxios.get('/UserTitles/' + userId + '.json?auth=' + token)
            .then(response => {
                if (response.data === null) {
                    let defaultTitle = {
                        title: 'Host'
                    }
                    customAxios.put('/UserTitles/' + userId + '.json?auth=' + token, defaultTitle)
                        .then(response => {
                            dispatch(fetchProfileSuccess(response.data.title, AccountInfo))
                        })
                        .catch(error => {
                            dispatch(authFail(error.response.data.error))
                        })
                }
                else {
                    dispatch(fetchProfileSuccess(response.data.title, AccountInfo))
                }
            })
            .catch(error => {
                    dispatch(authFail(error.response.data.error))
                })
    }
}


export const fetchProfileSuccess = (title, accountDetails) => {
    return {
        type: actionTypes.FETCH_ACCOUNT_DETAILS,
        accountDetails: accountDetails,
        title: title
    }
}



//---------------------------------STAY_SIGNED_IN------------------------
export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token')
        if (!token) {
            dispatch(logout());
        }
        else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'))
            if (expirationDate <= new Date()) {
                dispatch(logout())
            } else {
                const userId = localStorage.getItem('userId')
                dispatch(authSuccess(token, userId))
                dispatch(fetchProfile(token, userId))
                dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000));
            }
        }
    }
}

//---------------------------------RETURN_BACK_TUMBLR----------------------
export const returnBackSuccess = () => {
    return {
        type: actionTypes.RETURN_BACK_SUCCESS,
    }
}

