import React, { Component } from 'react';
import Aux from '../../../../hoc/auxiliary/auxiliary';
import classes from '../HypnosisShowMenu.module.scss'
// import classes2 from '../ExperienceMenu/ExperienceMenu.module.scss'
import HypnosisShow from '../../../../assets/Images/Blank.png'
import ForeheadTap from './HypnosisShowNonVolForeheadTap'
import HypnoGun from './HypnosisShowNonVolHypnoGun'
import SelfSnap from './HypnosisShowNonVolSelfSnap'
import WakeUp from './HypnosisShowNonVolWakeUp'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'

class HypnosisShowSnap2 extends Component {

    state = {
        fingerSnap: true,
        foreheadTap: false,
        hypnoGun: false,
        countdownSnap: false,
        wakeUp: false
    }

    foreheadTapHandler = () => {
        this.setState({ fingerSnap: false, foreheadTap: true })
    }

    hypnoGunHandler = () => {
        this.setState({ fingerSnap: false, hypnoGun: true })
    }

    countdownSnapHandler = () => {
        this.setState({ fingerSnap: false, countdownSnap: true })
    }

    wakeUpHandler = () => {
        this.setState({ fingerSnap: false, wakeUp: true })
    }


    render() {
        let HypnosisShowForm = null
        if (this.state.fingerSnap) {
            HypnosisShowForm = (
                <html style={{ backgroundImage: `url(${HypnosisShow})` }}>
                    <div className={classes.storyFrom}>
                        <p>
                        <i>"Confused with no recollection of what I'm saying or remembering every detail of it. That does not matter right now. You are feeling wonderful, you are feeling great, you are feeling relaxed & you are feeling happy. That is all that matters right now, that and...</i> <br></br>
                        <strong>*SNAP*</strong><br></br>
                        You drop. Without thinking you drop <br></br>
                        Deep under as you return to an even deeper state. <br></br>
                        An even deeper state of trance feeling open, completely open and happy to listen. <br></br>
                        Listen to his voice as he say <br></br>
                        <i>"Dropping deeper & deeper, sinking more, drifting and floating further. Drifting & floating further into trance. All you want is have me…”</i>
                        </p>
                        <form className={classes.centered} onClick={this.foreheadTapHandler}>
                            <h4 className={classes.storyButtonSize}>Tap your Forehead</h4>
                        </form>
                        <form className={classes.centered} onClick={this.hypnoGunHandler}>
                            <h4 className={classes.storyButtonSize}>Use my Hypno Gun</h4>
                        </form>
                        <form className={classes.centered} onClick={this.countdownSnapHandler}>
                            <h4 className={classes.storyButtonSize}>Make you Countdown & Snap your Fingers</h4>
                        </form>
                        <form className={classes.centered} onClick={this.wakeUpHandler}>
                            <h4 className={classes.storyButtonSize}>Wake you Up</h4>
                        </form>
                    </div>
                </html>
            )
        }

        if (this.state.foreheadTap) {
            HypnosisShowForm = (
                <ForeheadTap />
            )
        }

        if (this.state.hypnoGun) {
            HypnosisShowForm = (
                <HypnoGun />
            )
        }

        if (this.state.countdownSnap) {
            HypnosisShowForm = (
                <SelfSnap />
            )
        }

        if (this.state.wakeUp) {
            HypnosisShowForm = (
                <WakeUp />
            )
        }

        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {HypnosisShowForm}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >




        )
    }
}


export default HypnosisShowSnap2