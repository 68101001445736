import React, { Component } from 'react';
import Aux from '../../../../hoc/auxiliary/auxiliary';
import classes from '../HypnosisShowMenu.module.scss'
// import classes2 from '../ExperienceMenu/ExperienceMenu.module.scss'
import HypnosisShow from '../../../../assets/Images/Blank.png'
import Under from './HypnosisShowNonVolUnder'
import Awake from './HypnosisShowNonVolAwake'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'

class HypnosisShowDecline extends Component {

    state = {
        decline: true,
        under: false,
        awake: false
    }

    UnderHandler = () => {
        this.setState({ decline: false, under: true })
    }

    AwakeHandler = () => {
        this.setState({ decline: false, awake: true })
    }



    render() {
        let HypnosisShowForm = null
        if (this.state.decline) {
            HypnosisShowForm = (
                <html style={{ backgroundImage: `url(${HypnosisShow})` }}>
                    <div className={classes.storyFrom}>
                        <p>
                        Feeling butterflies in your stomach as he lets go of your shoulders allowing you to make the decision for yourself.<br></br>
                            Nervous & anxious of going up to stage but the thought of feeling more of this is quite enticing. Torn between giving into your nervousness and giving into how good this feels.<br></br>
                            You finally reach a decision, you take a deep breath in, holding it for a few seconds only to let it out again with a smile on your face you you decline his offer.<br></br>
                        Feeling his hand on your shoulder, squeezing it just a little bit as he starts to whisper in your ears, <br></br>
                        <i>"How about if I told you that you can enjoy remaining under even while you're here with the audience? 
                            Would you like that? To Enjoy all I do on stage from the comfort of your seat here among the audience. Would you like that?"</i><br></br>
                        You take a second to think and you decide to...
                        </p>
                        <form className={classes.centered} onClick={this.UnderHandler}>
                            <h4 className={classes.storyButtonSize}>Stay Under</h4>
                        </form>
                        <form className={classes.centered} onClick={this.AwakeHandler}>
                            <h4 className={classes.storyButtonSize}>Wake up</h4>
                        </form>
                    </div>
                </html>
            )
        }
        if (this.state.under) {
            HypnosisShowForm = (
                <Under />
            )
        }

        if (this.state.awake) {
            HypnosisShowForm = (
                <Awake />
            )
        }


        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {HypnosisShowForm}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >




        )
    }
}


export default HypnosisShowDecline