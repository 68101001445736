import * as actionTypes from '../../actions/Account/authTypes';
import { updateObject } from '../../../shared/utility';

const initialState = {
    error: null,
    profileActive: false,
    loading: false,
    viewProfile: false,
    editProfile: false,
    changeData: false,
    optionsView: false
}


const authSuccess = (state, action) => {
        return updateObject(state, {
            viewProfile: true,
            error: null,
            loading: false
        })
}



const authLogout = (state, action) => {
    return updateObject(state, {
        profileActive: false,
        loading: false,
        viewProfile: false,
        editProfile: false,
    })
}

const optionsView  = (state, action) => {
    return updateObject(state, {
        optionsView: true,
        error: null,
        loading: false
    })
}

const createAccountSuccess = (state, action) => {
    return updateObject(state, {
        viewProfile: true,
        profileActive: false,
        loading: false
    })
}

const deleteAccountSuccess = (state, action) => {
    return updateObject(state, {
        accountCreate: false,
        PasswordSubmitted: true,
        profileActive: false,
        loading: false,
        viewProfile: false,
        editProfile: false,
        authRedirectValid: false,
        error: null,
        token: null,
        userId: null
    })
}

const changePasswordSuccess = (state, action) => {
    return updateObject(state, {
        changeData: false,
        loading: false
    })
}

const changeEmailPasswordView = (state, action) => {
    return updateObject(state, {
        changeData: true,
    })
}

const changeEmailSuccess = (state, action) => {
    return updateObject(state, {
        changeData: false,
        loading: false
    })
}


const editProfile = (state, action) => {
    return updateObject(state, {
        loading: false,
        viewProfile: true,
        editProfile: false
    })
}

const editProfileView = (state, action) => {
    return updateObject(state, {
        loading: false,
        viewProfile: false,
        editProfile: true
    })
}

const viewRunDetails = (state, action) => {
    return updateObject(state, {
        viewProfile: false,
        loading: false,
    })
}


const viewHostRunDetailSuccess = (state, action) => {
    return updateObject(state, {
        viewProfile: false,
        loading: false,
    })

}

const returnBackSuccess = (state, action) => {
    return updateObject(state, {
        viewProfile: true,
        editProfile: false,
        changeData: false,
        optionsView: false
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action)
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action)
        case actionTypes.CREATE_ACCOUNT_SUCCESS: return createAccountSuccess(state, action)
        case actionTypes.DELETE_ACCOUNT_SUCCESS: return deleteAccountSuccess(state, action)
        case actionTypes.CHANGE_PASSWORD_SUCCESS: return changePasswordSuccess(state, action)
        case actionTypes.CHNAGE_EMAIL_SUCCESS: return changeEmailSuccess(state, action)
        case actionTypes.EDIT_PROFILE_SUCCESS: return editProfile(state, action)
        case actionTypes.EDIT_PROFILE_VIEW: return editProfileView(state, action)
        case actionTypes.VIEW_RUN_DETAIL: return viewRunDetails(state, action)
        case actionTypes.VIEW_OPTIONS_PAGE: return optionsView(state, action)
        case actionTypes.RETURN_BACK_SUCCESS: return returnBackSuccess(state, action)
        case actionTypes.CHANGE_EMAIL_PASSWORD_VIEW: return changeEmailPasswordView(state, action)
        case actionTypes.VIEW_HOSTS_RUN_DETAIL: return viewHostRunDetailSuccess(state, action)
        default:
            return state

    }
}


export default reducer