import React, { Component } from 'react';
import Layout from './containers/Layout/Layout'
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import HomePage from './containers/HomePage/Homepage'
import FreeRun from './containers/FreeRun/Freerun'
import MindMeltingExperience from './MindMeltingExperience/components/ExperienceMenu/ExperienceMenu'
import { connect } from 'react-redux';
import HostsChamber from './containers/HostsChamber/HostsChamber'
import Account from './containers/Account/Account';
import * as actions from './store/actions/index'
import './App.scss';




class App extends Component {

    componentDidMount() {
        this.props.onTryAutoSignup()
    }
    render() {

        let routes = null
        if (!this.props.isAuthenticated) {
            routes = (
                <Switch >
                    <Route path="/FreeChamber"
                        component={FreeRun} />
                    <Route path="/Account"
                        component={Account} />
                    <Route path='/'
                        exact component={HomePage} />
                    <Redirect to='/' />
                </Switch>
            )
        } else {
            routes = (
                <Switch >
                    <Route path="/FreeChamber"
                        component={FreeRun} />
                    <Route path="/HostsChamber"
                        component={HostsChamber} />
                    <Route path="/Account"
                        component={Account} />
                    <Route path='/'
                        exact component={HomePage} />
                    <Route path="/TheMindMeltingExperience"
                        component={MindMeltingExperience} />
                    <Redirect to='/' />
                </Switch>
            )
        }


        return (
            <div className='App' >
                <Layout >
                    {routes}
                </Layout>
            </div >
        );
    }

}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignup: () => dispatch(actions.authCheckState())
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));