import React, { Component } from 'react';
import Aux from '../../../hoc/auxiliary/auxiliary';
import classes from './HypnosisShowMenu.module.scss'
// import classes2 from '../ExperienceMenu/ExperienceMenu.module.scss'
import HypnosisShow from '../../../assets/Images/Blank.png'
import FrontSeat from './HypnosisShowFrontSeat'
import BackSeat from'./HypnosisShowBackSeat'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'

class HypnosisShowEnterTent extends Component {

    state = {
        enterTent: true,
        frontSeat: false,
        backSeat: false
    }

    frontSeatHandler = () => {
        this.setState({ enterTent: false, frontSeat: true })
    }

    backSeatHandler = () => {
        this.setState({ enterTent: false, backSeat: true })
    }
    render() {
        let HypnosisShowForm = null
        if (this.state.enterTent) {
            HypnosisShowForm = (
                <html style={{ backgroundImage: `url(${HypnosisShow})` }}>
                    <div className={classes.storyFrom}>
                    <p>
                    Sliding the curtains open you enter the tent looking around thinking <i>“It’s not as big as I thought it would be” </i>
                    You are greeted by light music playing in the background and a nice smell. Taking a deep breath in trying to figure out what that smell is and discover it's Lavender. 
                    You take a few steps inside checking the place out, looking at the stage you see a few wooden chairs where you guess the volunteers will be sitting. The audience space looks like it can accommodate 30 maybe 40 people maximum. <br></br> 
                    <i>It feels like quite the small affair with an intimate setting. <strong>You like that very much.</strong></i> <br></br>
                    Looking up you see the spotlights directed on stage, the scented candles lighting the tent and giving it nice cozy atmosphere.
                    The whole place making you feel quite calm & relaxed from the moment you entered. <br></br> 
                    <i>"I have a feeling I'm going to like this show"</i> you think to yourself as you start to notice the tent filling up and people taking their seats. <br></br>
                    You look around for a place to sit and decide to sit at the... 
                    </p>
                    <form className={classes.centered} onClick={this.frontSeatHandler}>
                        <h3 className={classes.storyButtonSize}>Front</h3>
                    </form>
                    <form className={classes.centered} onClick={this.backSeatHandler}>
                        <h3 className={classes.storyButtonSize}>Back</h3>
                    </form>
                    </div>
                </html>
                )
        }
        if(this.state.frontSeat){
            HypnosisShowForm = (
                <FrontSeat/>
            )
        }
        if(this.state.backSeat){
            HypnosisShowForm = (
                <BackSeat />
            )
        }

        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {HypnosisShowForm}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >




        )
    }
}


export default HypnosisShowEnterTent