import React, { Component } from 'react';
import Aux from '../../../hoc/auxiliary/auxiliary';
import classes from './HypnosisShowMenu.module.scss'
// import classes2 from '../ExperienceMenu/ExperienceMenu.module.scss'
import HypnosisShow from '../../../assets/Images/Blank.png'
import Volunteer from './Volunteer/HypnosisShowVolunteer'
import NonVolunteerFront from './Non-Volunteer/HypnosisShowNonVolunteerFront'
import NonVolunteerBack from './Non-Volunteer/HypnosisShowNonVolunteerBack'
import ShowStart from './HypnosisShowStart'
import EnterTent from './HypnosisShowEnterTent'
import StartingMenu from './StartingMenu';
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/MindMeltingExperience/TheGreatOHypnosisShow/index';

class HypnosisShowChapterSelect extends Component {

    state = {
        showBegins: true,
        showStart: false,
        startMenu: false,
        volunteer: false,
        non_volunter: false,
        enterTent: false
    }

    showStartsHandler = () => {
        this.props.onShowStarts()
    }

    VolunteerHandler = () => {
        this.setState({ showBegins: false, volunteer: true })
    }

    NonVolunteerHandler = () => {
        this.setState({ showBegins: false, non_volunter: true })
    }

    EnterTentHandler = () => {
        this.setState({ showBegins: false, enterTent: true })
    }

    showEndsHandler = () => {
        this.setState({ showBegins: false, startMenu: true })
    }

    render() {
        let seating = Math.floor(Math.random() * 2) +1
        let HypnosisShowForm = null
        if (this.state.showBegins) {
            HypnosisShowForm = (
                <html style={{ backgroundImage: `url(${HypnosisShow})` }}>
                    <h3 className={classes.ChapterTitle}>Select Chapter</h3>
                    <div className={classes.ChapterSelect}>
                        
                        <form onClick={this.showStartsHandler} className={classes.buttonSize}>
                            <h4>Start Story</h4>
                        </form>
                        <form  onClick={this.EnterTentHandler} className={classes.buttonSize}>
                            <h4>Go to Show</h4>
                        </form>
                        <form onClick={this.VolunteerHandler} className={classes.buttonSize}>
                            <h4>Volunteer</h4>
                        </form>
                        <form onClick={this.NonVolunteerHandler} className={classes.buttonSize}>
                            <h4>Don't Volunteer</h4>
                        </form>
                        <form onClick={this.showEndsHandler} className={classes.buttonSize}>
                            <h4>Start Menu</h4>
                        </form>
                    </div>
                </html>
            )
        }
        if (this.props.startStory) {
            HypnosisShowForm = (
                <ShowStart />
            )
        }

        if (this.state.enterTent) {
            HypnosisShowForm = (
                <EnterTent />
            )
        }

        if (this.state.volunteer) {
            HypnosisShowForm = (
                <Volunteer />
            )
        }

        if (this.state.non_volunter) {
            if(seating === 1){
                HypnosisShowForm = (
                    <NonVolunteerFront />
                )
            } else{
                HypnosisShowForm = (
                    <NonVolunteerBack />
                )
            }
            
        }

        if (this.state.startMenu) {
            HypnosisShowForm = (
                <StartingMenu />
            )
        }

        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {HypnosisShowForm}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >




        )
    }
}

const mapStateToProps = state => {
    return {
        showStart: state.hypnosisShow.showStart,
        startStory: state.hypnosisShow.startStory
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowStarts: () => dispatch(actions.hypnosisShowStart()),
        onShowEnds: () => dispatch(actions.hypnosisShowEnd())
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(HypnosisShowChapterSelect);