import React from 'react'
import classes from '../../../../../containers/Account/Account.module.css'
// import Input from '../../UI/Input/Input'
import Button from '../../../../UI/Button/Button'
import Aux from '../../../../../hoc/auxiliary/auxiliary'

const obedienceStage1 = (props) => {

    let obedienceStage1Form = (
        <Aux>
            <div className={classes.AccountContainer}>
                    <form className={classes.FormStyle}>
                        <h2>Obedience Stage 1 Run Details</h2>
                        <p className={classes.smallText}>We See you, {props.name}</p>
                        <div>
                            <p><strong>Obedience Stage 1: {props.viewObedienceStage1.obedience_stage1}</strong></p>
                        </div>
                        <div>
                            <p><strong>Typing Command: {props.viewObedienceStage1.typing_command} </strong></p>
                        </div>
                        <div>
                            <p><strong>Green Text Command: {props.viewObedienceStage1.green_text_reinforcement_command}</strong></p>
                        </div>
                        <div>
                            <p><strong>Monitor Command: {props.viewObedienceStage1.we_are_watching_command}</strong></p>
                        </div>
                        <div>
                            <p><strong>Resistance is Futile: {props.viewObedienceStage1.resistance_is_futile_command}</strong></p>
                        </div>
                        <div>
                            <p><strong>Instant Trigger Command: {props.viewObedienceStage1.instant_trigger_command}</strong></p>
                        </div>
                    </form>
                    <div className={classes.AuthButtons}>
                        <Button btnType="Danger" clicked={props.ReturnBackHandler}>Back</Button>
                    </div>

                </div>
        </Aux>)


    return obedienceStage1Form
}

export default obedienceStage1