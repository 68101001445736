import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '../../../../components/UI/Button/Button'
import classes from '../../../../containers/HostsChamber/HostsChamber.module.css'
import ObedienceStage1 from '../../../../components/HostsChamber/ObedienceStages/ObedienceStage1'
import ObedienceStage2 from '../../../../components/HostsChamber/ObedienceStages/ObedienceStage2/ObedienceStage2'
import ObedienceStage3 from '../../../../components/HostsChamber/ObedienceStages/ObedienceStage3/ObedienceStage3'
import ObedienceStageComponent from '../../../../components/HostsChamber/ObedienceStages/ObedienceStage'
import * as actions from '../../../../store/actions/index';
import Aux from '../../../../hoc/auxiliary/auxiliary'

class ObedienceConditioning extends Component {
    state = {
        obedienceConditioning: true,
    }

    obedience1ThemeHandler = () => {
        this.setState({ obedienceConditioning: false })
        this.props.onObedienceStage1Start()
    }

    obedience2ThemeHandler = () => {
        this.setState({ obedienceConditioning: false })
        this.props.onObedienceStage2Start()
    }

    obedience3ThemeHandler = () => {
        this.setState({ obedienceConditioning: false })
        this.props.onObedienceStage3Start()
    }
     
    render() {
        let ObedienceStage = null
        if (this.state.obedienceConditioning) {
            let obedienceStage1Remaining = 0
            let obedienceStage1Open = false
            if(this.props.hostRun <= 4){
                obedienceStage1Remaining = 5 - parseInt(this.props.hostRun)
                obedienceStage1Open = false
            } else if (this.props.hostRun > 4){
                obedienceStage1Open = true
            }

            let obedienceStage2Remaining = 0
            let obedienceStage2Open = false
            if(this.props.obedienceStage1.obedience_stage1 <= 4){
                obedienceStage2Remaining = 5 - parseInt(this.props.obedienceStage1.obedience_stage1)
                obedienceStage2Open = false
            } else if (this.props.obedienceStage1.obedience_stage1 > 4){
                obedienceStage2Open = true
            }

            let obedienceStage3Remaining = 0
            let obedienceStage3Open = false
            if(this.props.obedienceStage2.obedience_stage2 <= 4){
                obedienceStage3Remaining = 5 - parseInt(this.props.obedienceStage2.obedience_stage2)
                obedienceStage3Open = false
            } else if (this.props.obedienceStage2.obedience_stage2 > 4){
                obedienceStage3Open = true
            }
            
            ObedienceStage = (
                <Aux>
                    <h2>Select Stage</h2>
                    <p className={classes.smallText}>Each stage conditions your more to Us, {this.props.hostName}</p>
                    
                        <Button btnType="Success" disabled = {!obedienceStage1Open} clicked={this.obedience1ThemeHandler}>Obedience Stage 1 {obedienceStage1Open ? null : <Aux><br></br>({obedienceStage1Remaining} Host Runs Remaining)</Aux>}</Button>
                        <Button btnType="Success" disabled = {!obedienceStage2Open} clicked={this.obedience2ThemeHandler}>Obedience Stage 2 {obedienceStage2Open ? null : <Aux><br></br>({obedienceStage2Remaining} Stage 1 Runs Remaining)</Aux>}</Button>
                        <Button btnType="Success" disabled = {!obedienceStage3Open} clicked={this.obedience3ThemeHandler}>Obedience Stage 3 {obedienceStage3Open ? null : <Aux><br></br>({obedienceStage3Remaining} Stage 2 Runs Remaining)</Aux>}</Button>
                    
                </Aux>
            )
        }
        
        if (this.props.obedienceStage1Valid){
            ObedienceStage =  <ObedienceStage1 />
        }else if(this.props.obedienceStage1Complete){
            ObedienceStage = <ObedienceStageComponent />
        }

        if (this.props.obedienceStage2Valid){
            ObedienceStage =  <ObedienceStage2 />
        }else if (this.props.obedienceStage2Complete){
            ObedienceStage = <ObedienceStageComponent />
        }

        if (this.props.obedienceStage3Valid){
            ObedienceStage =  <ObedienceStage3 />
        }else if(this.props.obedienceStage3Complete){
            ObedienceStage = <ObedienceStageComponent />
        }

        return (
            <div>
                {ObedienceStage}
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        hostName: state.auth.accountDetails.name,
        runSpeed: state.auth.accountDetails.speed,
        // userId: state.auth.userId,
        hostRun: state.auth.accountDetails.Host_Runs,
        token: state.auth.token,
        obedienceStage1Valid: state.obedienceReducer1.obedienceStage1Valid,
        obedienceStage2Valid: state.obedienceReducer2.obedienceStage2Valid,
        obedienceStage3Valid: state.obedienceReducer3.obedienceStage3Valid,
        obedienceStage1: state.obedienceReducer1.obedienceStage1,
        obedienceStage2: state.obedienceReducer2.obedienceStage2,
        obedienceStage1Complete: state.obedienceReducer1.obedienceStage1Complete,
        obedienceStage2Complete: state.obedienceReducer2.obedienceStage2Complete,
        obedienceStage3Complete: state.obedienceReducer3.obedienceStage3Complete,
    }
}

const mapDispatchToProps = dispatch => {
    return{
        onObedienceStage1Start: () => dispatch(actions.ObedienceStage1Start()),
        onObedienceStage2Start: () => dispatch(actions.ObedienceStage2Start()),
        onObedienceStage3Start: () => dispatch(actions.ObedienceStage3Start())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ObedienceConditioning)