import * as actionTypes from './actionTypes'
import axios from '../../axios'

export const saveName = (nameData) => {
    return {
        type: actionTypes.SAVE_NAME,
        nameData: nameData
    }
}

export const savingName = (nameData) => {
    return dispatch => {
        dispatch(saveName(nameData))
    }
}

export const fetchMantra = () => {
    return dispatch => {
        let NumofMantras = Math.floor(Math.random() * 5) + 1
        let mantraNum = []
        let mantra = []
        let i = 1
        // let j = 0
        while (i <= NumofMantras) {
            let addMantra = Math.floor(Math.random() * 15) + 1
            if (mantraNum.includes(addMantra)) {
                // eslint-disable-next-line
                mantraNum = mantraNum.sort()
            } else {
                mantraNum.push(addMantra)
                i++
            }
        }
        mantraNum = mantraNum.sort(function(a, b){return a - b})
        axios.get('/mantras.json')
            .then(response => {
                let j = 0
                let k = 0
                while (j < response.data.length) {
                    let counter = 0
                    while (counter < response.data.length) {
                        if (response.data.indexOf(response.data[j]) === mantraNum[k]) {
                            mantra.push(response.data[j])
                            
                            k++
                        }
                        counter ++
                    }
                    j++
                }
                
                dispatch(mantraSuccess(mantra, mantraNum))
            })
            .catch(error => {
                dispatch(mantraFail(error.message))
            })


        // if (j === mantraNum.length){
        //     console.log(mantra)
        //     console.log(mantra.length)
        //     console.log(mantraNum)
        //     console.log(mantraNum.length)
        //     
        // }

    }
}

export const mantraSuccess = (mantra, mantraNum) => {
    return {
        type: actionTypes.FETCH_MANTRA_SUCCESS,
        mantra: mantra,
        mantraNum: mantraNum
    }
}

export const mantraFail = (error) => {
    return {
        type: actionTypes.FETCH_MANTRA_FAIL,
        error: error
    }
}