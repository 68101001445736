import React, { Component } from 'react'
// import Input from '../../../components/UI/Input/Input'
import Button from '../../../components/UI/Button/Button'
import classes from '../Account.module.css'
import Aux from '../../../hoc/auxiliary/auxiliary';
import * as actions from '../../../store/actions/index';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { connect } from 'react-redux'
import ConditioningProtocol1 from '../../../components/Account/Runs/ConditioningProtocol1/ConditioningProtocol1'

class Runs extends Component {

    viewConditioningProtocol1DetailsHandler = (event) => {
        event.preventDefault();
        this.props.onViewConditioningProtocol1Details(this.props.token, this.props.userId)
    }


    ReturnBackHandler = (event) => {
        event.preventDefault();
        this.props.onReturnBack()
    }

    render() {

        let form = null

        if (this.props.viewConditioningProtocols) {
            form = (<div className={classes.AccountContainer}>
                <form className={classes.ViewProfile}>
                    <h2>Select Run</h2>
                    <div className={classes.AuthButtons}>
                        <Button btnType="Success" clicked={this.viewConditioningProtocol1DetailsHandler}>Conditioning Protocol 1 <br></br>Details</Button> <br></br>
                        <Button btnType="Danger" clicked={this.ReturnBackHandler}>Back</Button>
                    </div>
                </form>
            </div>
            )
        }


        if (this.props.viewConditioningProtocol1){
            form = 
                <ConditioningProtocol1
                    name = {this.props.accountDetails.name}
                    ConditioningProtocol1 = {this.props.viewConditioningProtocol1Details} 
                    ReturnBackHandler = {this.ReturnBackHandler}/>
        }


        if (this.props.loading) {
            form = <Spinner className={classes.Spinner} />
        }

        let errorMessage = null

        if (this.props.error) {
            errorMessage = (
                <p className={classes.error}>{this.props.error}</p>
            )
        }

        return (
            <Aux>
                <div className={classes.Account}>
                    {errorMessage}
                    {form}
                </div>
            </Aux >

        );
    }
}

const mapStatesToProps = state => {
    return {
        loading: state.runs.loading,
        error: state.auth.error,
        token: state.auth.token,
        userId: state.auth.userId,
        accountDetails: state.auth.accountDetails,
        authRedirectValid: state.auth.authRedirectValid,
        viewConditioningProtocols: state.runs.viewConditioningProtocols,
        viewConditioningProtocol1: state.runs.viewConditioningProtocol1Details,
        viewConditioningProtocol1Details: state.runs.viewConditioningProtocol1DetailsArray,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onViewRunDetails: () => dispatch(actions.viewRunDetails()),
        onViewConditioningProtocol1Details: (token, userId) => dispatch(actions.ViewConditioningProtocol1Details(token, userId)),

        onReturnBack: () => dispatch(actions.returnBackSuccess()),
        onLogout: () => dispatch(actions.logout())
    }
}

export default connect(mapStatesToProps, mapDispatchToProps)(Runs);