import React, { useState } from "react";
import TextTransition from "react-text-transition";
import TypingEffect from 'react-typing-effect'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import Button from '../../../../UI/Button/Button'
import classes from '../../../../../containers/HostsChamber/AudioRuns/AudioRuns.module.css'
import * as scripts from '../../../../../shared/AudioRuns/TriggerIntroduction/ChloroformIntroduction/ChloroformIntroductionScripts'
import * as Delays from '../../../../../shared/AudioRuns/TriggerIntroduction/ChloroformIntroduction/ChloroformIntroductionDelay'
import Setup from '../../../../../assets/audio_runs/Trigger_Introduction/Chloroform_Introduction/Chloroform_Room_Setup.mp3'
import Sound from 'react-sound'
import Delay from "react-delay";
import useInterval from 'react-useinterval';
import ChloroformRoomStart from './Chloroform_Room_Start'

const DELAY_TEXT = [4000]


function Chloroform_Room_Setup() {
  let [count, setCount] = useState(0);
  let [chloroform_room_setup, setProtocol] = useState(true)
  let [chloroform_room_start, setChloroformRoomStartSequence] = useState(false)


  const increaseCount = () => {
    if (chloroform_room_setup && count < scripts.CHLOROFORM_ROOM_SETUP.length) {
      setCount(count + 1);
    }
  };

  useInterval(increaseCount, Delays.CHLOROFORM_ROOM_SETUP_DELAY[count]);

  const enterChloroformRoomStartSequenceHandler = () => {
    setProtocol(chloroform_room_setup = false)
    setChloroformRoomStartSequence(chloroform_room_start = true)

  }



  let chloroform_room_setup_form = null

  if (chloroform_room_setup) {
    chloroform_room_setup_form = (
      <Aux>
        {count < scripts.CHLOROFORM_ROOM_SETUP.length ?
          <Aux >
            <Sound url={Setup} playStatus={Sound.status.PLAYING} />
            <Delay wait={DELAY_TEXT[count]}>
              <TextTransition
                text={<TypingEffect typingDelay={100} text={scripts.CHLOROFORM_ROOM_SETUP[count]} speed={50} eraseDelay={1000000} />}
                Overflow
              />
            </Delay>
          </Aux>
          :
          <div className={classes.ButtonPadding}>
            <Button btnType="Success" clicked={enterChloroformRoomStartSequenceHandler}>Setup Completed <br></br> This host confirms it is ready</Button>
          </div>
        }
      </Aux >
    )
  }

  if (chloroform_room_start) {
    chloroform_room_setup_form = <ChloroformRoomStart />
  }

  return (
    <Aux>
      {chloroform_room_setup_form}
    </Aux>
  )

}

export default Chloroform_Room_Setup