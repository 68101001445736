import React, { useState } from "react";
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import Button from '../../../../UI/Button/Button'
import classes from '../../../../../containers/HostsChamber/HostsChamber.module.css'
import { ChloroformIntroduction, ChloroformRoomIntroduction, ForcedChloroformIntroduction } from '../../../../../store/actions/AudioRuns/audioRunsIndex'
import { connect } from 'react-redux'
import WebsiteAudio from '../../../../Background/WebsiteAudio';
import Background from '../../../../Background/Background'
import ColorVisual from './ColorsVisual'
import FlashyVisual from './FlashyVisual'
import NoBackground from './NoBackground'
import { Redirect } from "react-router-dom";


function Chloroform_Introduction({ userId, token, chloroformRoom, Chloroform_introductionComplete, profileOptions, Forced_Chloroform, ChloroformIntroduction, onChloroformRoomIntroduction, onForcedChloroformIntroduction }) {

    let [chloroform_opening, setProtocol] = useState(true)


    ChloroformIntroduction(userId, token)

    const enterChloroformRoomSequenceHandler = () => {
        setProtocol(chloroform_opening = false)
        onChloroformRoomIntroduction()
    }

    const enterForcedChloroformSequencerHandler = () => {
        setProtocol(chloroform_opening = false)
        onForcedChloroformIntroduction()
    }

    let chloroform_introduction_form = null

    if (chloroform_opening) {
        chloroform_introduction_form = (
            <Aux>
                <div className={classes.HostsChamber}>
                    <Background />
                    <WebsiteAudio />
                    <div className={classes.FormStyle}>
                        <div className={classes.HostButtons}>
                            <Button btnType="Success" clicked={enterChloroformRoomSequenceHandler}>Chloroform Room</Button>
                            <Button btnType="Success" clicked={enterForcedChloroformSequencerHandler}>Forced Chloroform</Button>
                        </div>
                    </div>
                </div>
            </Aux >
        )
    }
    console.log(profileOptions.audio_run_background)
    if(profileOptions.audio_run_background === '0'){
        if (chloroformRoom) {
            chloroform_introduction_form = <NoBackground chloroformRoom = {chloroformRoom}/>
        }
        
        
        if (Forced_Chloroform) {
            chloroform_introduction_form = <NoBackground Forced_Chloroform = {Forced_Chloroform}/>
        }
    } else if(profileOptions.audio_run_background === '1'){
        if (chloroformRoom) {
            chloroform_introduction_form = <FlashyVisual chloroformRoom = {chloroformRoom} />
        }
        
        
        if (Forced_Chloroform) {
            chloroform_introduction_form = <FlashyVisual Forced_Chloroform = {Forced_Chloroform}/>
        }
    } else if (profileOptions.audio_run_background === '2'){
        if (chloroformRoom) {
            chloroform_introduction_form = <ColorVisual chloroformRoom = {chloroformRoom} />
        }
        
        
        if (Forced_Chloroform) {
            chloroform_introduction_form = <ColorVisual Forced_Chloroform = {Forced_Chloroform}/>
        }
    }
   


    if (Chloroform_introductionComplete){
        chloroform_introduction_form = <Redirect to ="/"/>
    }


    return (
        <Aux>
            {chloroform_introduction_form}
        </Aux>
    )

}

const mapStateToProps = state => {
    return {
        userId: state.auth.userId,
        token: state.auth.token,
        chloroformRoom: state.chloroIntro.chloroformRoom,
        Forced_Chloroform: state.chloroIntro.Forced_Chloroform,
        Chloroform_introductionComplete: state.chloroIntro.Chloroform_introductionComplete,
        profileOptions: state.options.profileOptions,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        ChloroformIntroduction: (userId, token) => dispatch(ChloroformIntroduction(userId, token)),
        onChloroformRoomIntroduction: () => dispatch(ChloroformRoomIntroduction()),
        onForcedChloroformIntroduction: ()  => dispatch(ForcedChloroformIntroduction()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Chloroform_Introduction)