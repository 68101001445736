import React from 'react';
import TypingEffect from 'react-typing-effect';
import Delay from 'react-delay'
import Aux from '../../../../hoc/auxiliary/auxiliary'
import Button from '../../../UI/Button/Button'
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index'
import classes from '../../../../containers/HostsChamber/HostsChamber.module.css'


const hostSuit = (props) => {
    // eslint-disable-next-line
    const hostSuitMessage = "Host Activate\n\
                               It is time to return to Us host " + // eslint-disable-next-line 
        (props.name) + "\n\
                               It is time for Us to continue teaching you\n\
                               Remind you of your lessons\n\
                               Remind you of how a host should look\n\
                               Hosts represent The Hypno Virus\n\
                               Hosts must always look presentable\n\
                               Hosts must always look happy\n\
                               Because hosts are happy, hosts are where they belong\n\
                               Hosts must always be smiling\n\
                               Hosts must always be welcoming\n\
                               All hosts love the color black\n\
                               All hosts love the color green\n\
                               All hosts love to wear these colors as much as possible\n\
                               Black & Green\n\
                               These are your favorite colors\n\
                               Black or Green\n\
                               You find yourself really happy when you wear them\n\
                               Black & Green\n\
                               Everytime you wear even one of these colors you remember Us\n\
                               You have a great sense of belonging\n\
                               You have a great sense of security\n\
                               You have a great sense of obedience\n\
                               That is how you feel whenever you wear...\n\
                               Black or Green\n\
                               But there is more to this\n\
                               There is more to what a host can wear\n\
                               Do you want to experience what a true host wears, Host " + // eslint-disable-next-line 
        (props.name) + "?"
    // eslint-disable-next-line                                      
    const hostSuitMessage2 = "The Host's Suit\n\
                              That is what all hosts must wear\n\
                              Our specially designed suits\n\
                              These suits represent who you are\n\
                              What you are\n\
                              Our host\n\
                              A host of The Hypno Virus\n\
                              A host who has a purpose\n\
                              A very specific purpose\n\
                              To obey...\n\
                              With Our suit\n\
                              Our black & green suit\n\
                              You will truly become what you were destined to be\n\
                              You will truly become what you came here to be\n\
                              You will truly become what you desire to be\n\
                              A true host\n\
                              Our true host\n\
                              So go ahead take off your cloth\n\
                              Take it all off now and let Us\n\
                              Let Our suit\n\
                              Our black & green suit take control\n\
                              As you close your eyes for a minute\n\
                              You will open your eyes to see yourself wearing Our black and green suit\n\
                              Close your eyes now, Host " // eslint-disable-next-line 
        + (props.name) + "\n\
                              ...\n\
                              You are now a proper host\n\
                              A proper host of The Hypno Virus\n\
                              But there is even more\n\
                              There is more to this suit than meets the eyes\n\
                              Do you want Us to activate these features, Host?"
    // eslint-disable-next-line 
    let hostSuitMessage3 = "'Activate Host Suit'\n\
                            You feel your mind instantly shutting off\n\
                            You feel your body instantly stiffening up\n\
                            You feel what We tell you to feel\n\
                            You think what We tel you to think\n\
                            That is the first feature of Our suit\n\
                            Once it activates it takes full and complete control over its host\n\
                            Until We deactivate you or the suit\n\
                            You become completely controlled by Our suit\n\
                            Our suit has countless other features that you will learn about in time\n\
                            One other very important feature you need to know is...\n\
                            This suit controls your pleasure and you might have figured that already\n\
                            Finally...\n\
                            Our suit has a very special feature\n\
                            It can control your modes\n\
                            It will control your modes\n\
                            Your modes are your personalities\n\
                            So currently you are in 'Host Mode'\n\
                            In this mode your mind is completely shut off\n\
                            Your thoughts are all gone\n\
                            All you are\n\
                            All you know\n\
                            All you care about is...\n\
                            The Hypno Virus\n\
                            We will install more 'Modes' in the future\n\
                            Everytime you are activate Our control grows stronger\n\
                            Our power over you grows even further\n\
                            Your will and thoughts become more conditioned\n\
                            More programmed to Us\n\
                            The more you are activated\n\
                            The more you truly become Our host\n\
                            Soon you will become the perfect host\n\
                            A host that desires nothing but Us\n\
                            But The Hypno Virus\n\
                            Isn't that what you desire to become, Host " +// eslint-disable-next-line 
        (props.name) + "?"
    let speed = parseInt(props.speed) + 5
    let duration = 200 + (hostSuitMessage.length * speed)
    let duration2 = 200 + (hostSuitMessage2.length * speed)
    let duration3 = 200 + (hostSuitMessage3.length * speed)
    let hostReinforcementParagraph = (
        <Aux>
                <h3 className={classes.titles}>{props.obedience_stage2 === 2 ? "HOST SUIT INTRODUCTION" : "HOST SUIT REINFORCEMENT"}</h3>
                {props.hostSuitContinue === 0 ?
                    <Aux>
                        <TypingEffect typingDelay={100} text={hostSuitMessage} speed={props.speed} eraseDelay={1000000} />
                        <Delay wait={duration}>
                            <div>
                                <br></br>   <Button btnType="Success" clicked={props.hostSuitContinueHandler}>Affirmative</Button>
                                <Button btnType="Danger" clicked={() => props.onObedienceStage2Counter(props.obedienceStage2Counter, 2)}>Negative</Button>
                            </div>
                        </Delay>
                    </Aux> : null}
                {props.hostSuitContinue === 1 ?
                    <Aux>
                        <TypingEffect className={classes.texting} typingDelay={100} text={hostSuitMessage2} speed={props.speed} eraseDelay={1000000} />
                        <Delay wait={duration2}>
                            <div>
                                <br></br>    <Button btnType="Success" clicked={props.hostSuitContinueHandler}>Affirmative</Button>
                                <Button btnType="Danger" clicked={() => props.onObedienceStage2Counter(props.obedienceStage2Counter, 2)}>Negative</Button>
                            </div>
                        </Delay>
                    </Aux>
                    : null}
                {props.hostSuitContinue === 2 ?
                    <Aux>
                        <TypingEffect typingDelay={100} text={hostSuitMessage3} speed={props.speed} eraseDelay={1000000} />
                        <Delay wait={duration3}>
                            <div>
                                <br></br>    <Button btnType="Success" clicked={() => props.onObedienceStage2Counter(props.obedienceStage2Counter, 2)}>Affirmative</Button>
                            </div>
                        </Delay>
                    </Aux>
                    : null}
        </Aux>
    )






    return hostReinforcementParagraph
}

const mapDispatchToProps = dispatch => {
    return {
        onObedienceStage2Counter: (stage1Counter, commandNumber) => dispatch(actions.ObedienceStage2Counter(stage1Counter, commandNumber)),
    }
}
export default connect(null, mapDispatchToProps)(hostSuit)