import React, { Component } from 'react';
import Aux from '../../../../hoc/auxiliary/auxiliary';
import classes from '../HypnosisShowMenu.module.scss'
// import classes2 from '../ExperienceMenu/ExperienceMenu.module.scss'
import HypnosisShow from '../../../../assets/Images/Blank.png'
import FinalWord from './HypnosisShowNonVolFinalWords'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'

class HypnosisShowWakeUp extends Component {

    state = {
        wakeUp: true,
        awake: false
    }

    fingerSnapHandler = () => {
        this.setState({ wakeUp: false, awake: true })
    }


    render() {
        let HypnosisShowForm = null
        if (this.state.wakeUp) {
            HypnosisShowForm = (
                <html style={{ backgroundImage: `url(${HypnosisShow})` }}>
                    <div className={classes.storyFrom}>
                        <p>
                        <i>“Wake you up. <br></br>
                        But before I do that we need to take care of a few things. The first thing we will take care of is triggers. Any & all triggers we used during the show have no effect. They have no effect on you at all. 
                        The second thing is that you will remember everything we did today & feel the desire to talk and share the experience you had today with everyone. Reminiscing about it.
                        You will find that while my triggers will have no effect over you at all when you wake up anytime in the future it’ll be quite easy and natural to fall down for me and drop back into this deep state, understood? <br></br>
                        Now it’s time to wake up. <br></br>
                        As I count up from one to five one final time. You will wake up feeling so good, so refresh & happy. 
                        This has definitely been an experience that you will not forget anytime soon and one I am sure you will want to repeat & experience many times in the future.”</i> <br></br>
                        Turning around to speak to you and the audience <br></br>
                        <i>”As for all the lovely people who dropped in the audience. You have done so well and I thank you for allowing me to help you have this relaxing experience just like everyone up here, and like them all triggers are gone as you remember everything & find it quite easy to drop for me and my voice in the future. 
                        Now it is time for you to wake up. <br></br>
                        One, starting to wake up <br></br>
                        Two, having loved this whole experience <br></br>
                        Three, slowly starting to stretch <br></br>
                        Four, eyes starting to blink and open <br></br>
                        Five, wide awake now!”</i> <br></br>
                        <strong>*SNAP*</strong><br></br>
                        </p>
                        <form className={classes.centered} onClick={this.fingerSnapHandler}>
                            <h4 className={classes.storyButtonSize}>Stretching & Waking Up</h4>
                        </form>
                    
                    </div>
                </html>
            )
        }
        if (this.state.awake) {
            HypnosisShowForm = (
                <FinalWord />
            )
        }

    

        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {HypnosisShowForm}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >




        )
    }
}


export default HypnosisShowWakeUp