import React, { Component } from 'react';
import Aux from '../../../hoc/auxiliary/auxiliary';
import classes from './HypnosisShowMenu.module.scss'
// import classes2 from '../ExperienceMenu/ExperienceMenu.module.scss'
import HypnosisShow from '../../../assets/Images/Blank.png'
// import TheEnd from './StartingMenu'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/MindMeltingExperience/TheGreatOHypnosisShow/index';

class HypnosisShowLeaveEarly extends Component {

    state = {
        leaveEarly: true,
        // endStory: false,
        // explore: false
    }

    

    EndStoryHandler = () => {
        this.props.onShowEnds()
        // this.setState({ leaveEarly: false, endStory: true })
    }
    render() {
        let HypnosisShowForm = null
        if (this.state.leaveEarly) {
            HypnosisShowForm = (
                <html style={{ backgroundImage: `url(${HypnosisShow})` }}>
                    <div className={classes.storyFrom}>
                    <p>
                    You think to yourself <i>“Maybe later”</i> as you walk away from the tent. <br></br>
                    Stopping for a second, you look back at the poster reading a number written at the bottom of it. 
                    You write it down as you say <i>“Just in case”.</i><br></br> 
                    Walking away again checking out the rest of the fair in this wonderful weather enjoying your day. <br></br>
                    In the back of your mind you remember the numbers and already have them memorized for <i>the future</i>
                    </p>
                    <form className={classes.centered} onClick={this.EndStoryHandler}>
                        <h3 className={classes.storyButtonSize}>The End</h3>
                    </form>
                    </div>
                </html>
                )
        }

        // if(this.props.endStory){
        //     HypnosisShowForm =<TheEnd/>
        // }

        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {HypnosisShowForm}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >




        )
    }
}


const mapStateToProps = state => {
    return {
        showStart: state.hypnosisShow.showStart,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowEnds: () => dispatch(actions.hypnosisShowEnd()),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(HypnosisShowLeaveEarly);