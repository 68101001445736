import React, { useState } from "react";
import TextTransition from "react-text-transition";
import TypingEffect from 'react-typing-effect'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import Button from '../../../../UI/Button/Button'
import classes from '../../../../../containers/HostsChamber/HostsChamber.module.css'
import * as scripts from '../../../../../shared/AudioRuns/TriggerReinforcement/ChloroformReinforcement/ChloroformReinforcementScripts'
import * as Delays from '../../../../../shared/AudioRuns/TriggerReinforcement/ChloroformReinforcement/ChloroformReinforcementDelay'
import Body_Play from '../../../../../assets/audio_runs/Trigger_Reinforcement/Chloroform_Reinforcement/Chloroform_Body_Play.mp3'
import { ChloroformReinforcementEnd  } from '../../../../../store/actions/AudioRuns/audioRunsIndex'
import Sound from 'react-sound'
import Delay from "react-delay";
import useInterval from 'react-useinterval';
import { connect } from 'react-redux'

const DELAY_TEXT = [3800]


function Chloroform_Body_Play({ userId, token, fast_chloroform, name, ChloroformReinforcementEnd}) {
    let [count, setCount] = useState(0);
    let [chloroform_body_play, setProtocol] = useState(true)
    // let effect = 1

    const increaseCount = () => {
        if (chloroform_body_play && count < scripts.CHLOROFORM_BODY_PLAY.length) {
            setCount(count + 1);
        }
    };

    useInterval(increaseCount, Delays.CHLOROFORM_BODY_PLAY_DELAY[count]);


    const ChloroformBodyPlayHandler = () => {
        setProtocol(chloroform_body_play = false)
        if(fast_chloroform === true) {
            ChloroformReinforcementEnd(userId, token, 1, 0, 1, 0, 0)
        } else {
            ChloroformReinforcementEnd(userId, token, 0, 1, 1, 0, 0)

        }
    }

    

    

    let centerText2 = window.innerHeight / 3
    let texting2 = {

        // display: 'flex',
        // flexFlow: 'column',
        fontSize: '24px',
        whiteSpace: 'pre-line',
        alignItems: 'center',
        fontWeight: 'bold',
        boxSizing: "border-box",
        margin: 'auto',
        marginBottom: '10px',
        padding: '30px 10px',
        width: '50%',
        paddingTop: centerText2 +'px',
        color: 'White'

    }

    let command = (
        <div style={texting2}>
        <h3>Chloroform Body Play</h3>
        <p>{scripts.message}</p>
        <br></br>
        <div className={classes.ButtonPadding}>
            <Delay wait={30000}>
            {scripts.BellSound}
            </Delay>
            <Delay wait={31000}>
            <Button btnType="Success" clicked={ChloroformBodyPlayHandler}>Thank you for commanding me!</Button>
            </Delay>
        </div>
        </div>
    )

    let centerText = window.innerHeight / 2 -55
    let texting = {

        // display: 'flex',
        // flexFlow: 'column',
        whiteSpace: 'pre-line',
        alignItems: 'center',
        fontWeight: 'bold',
        boxSizing: "border-box",
        margin: 'auto',
        marginBottom: '10px',
        padding: '30px 10px',
        width: '50%',
        paddingTop: centerText + 'px',
        color: 'White'

    }

    let chloroform_body_play_form = null

    if (chloroform_body_play) {
        chloroform_body_play_form = (
            <Aux>
                {count < scripts.CHLOROFORM_BODY_PLAY.length ?
                    <div style={texting}>
                        <Sound url={Body_Play} playStatus={Sound.status.PLAYING} />
                        <Delay wait={DELAY_TEXT[count]}>
                            <TextTransition
                                text={<TypingEffect typingDelay={100} text={scripts.CHLOROFORM_BODY_PLAY[count]} speed={50} eraseDelay={1000000} />}
                                Overflow
                            />
                        </Delay>
                    </div>
                    :
                    <div>
                        {command}
                    </div>
                }
            </Aux >
        )
    }

    return (
        <Aux>
            {chloroform_body_play_form}
        </Aux>
    )

}

const mapStateToProps = state => {
    return {
        name: state.auth.accountDetails.name,
        userId: state.auth.userId,
        token: state.auth.token,
        fast_chloroform: state.chloroReinforce.fast_chloroform,
        normal_chloroform: state.chloroReinforce.normal_chloroform,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        ChloroformReinforcementEnd: (userId, token, Quick_Effect_Chloroform, Normal_Effect_Chloroform, Body_Play, Arousal_Effect, Amnesia_Effect) => dispatch(ChloroformReinforcementEnd(userId, token,
            Quick_Effect_Chloroform, Normal_Effect_Chloroform, Body_Play, Arousal_Effect, Amnesia_Effect)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Chloroform_Body_Play)