import * as conditionTypes from '../../../actions/ConditioningStages/conditioningStagesTypes';
import { updateObject } from '../../../../shared/utility';

const initialState = {
    error: null,
    obedienceStage3Valid: false,
    obedienceTheme: false,
    obedienceStage3Counter: 0,
    Host_Chip_Command: 0,
    Mind_Format_Command: 0,
    Mechanical_Tentacles_Command: 0,
    Binural_Beat_Command: 0,
    Chloroformed_Conditioned_Command:0,
    obedienceStage3Complete: false,
    obedienceStage3: [],
    obedienceStage3Commands: [],
    loading: false
}

const ObedienceStageStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true
    })
}

const ObedienceStage3Start = (state, action) => {
    return updateObject(state, {
        obedienceStage3Valid: true,
        loading: false,
    })
}

const ObedienceStage3Success = (state, action) => {
    if(action.obedience_stage3.obedience_stage3 > 4){
        return updateObject(state, {
            obedienceStage3: action.obedience_stage3,
            obedienceStage3Commands: action.obedienceStage3Commands, 
            obedienceStage3Complete: false,
            obedienceTheme: true,
            loading: false,
            error: null
        })
    }
    else if (action.obedience_stage3.obedience_stage3 <= 4){
        return updateObject(state, {
            obedienceStage3: action.obedience_stage3,
            obedienceStage3Commands: action.obedienceStage3Commands,
            obedienceStage3Complete: false,
            obedienceTheme: true,
            loading: false,
            error: null
        })
    }
}
    



const ObedienceStageFailed = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    })
}

const obedienceStage3Counter = (state, action) => {
    let updatedCommandNumber = 1
    if (action.commandNumber === 0){
        state.Host_Chip_Command = state.Host_Chip_Command + updatedCommandNumber
    } else if (action.commandNumber === 1){
        state.Mind_Format_Command = state.Mind_Format_Command + updatedCommandNumber
    } else if (action.commandNumber === 3){
        state.Mechanical_Tentacles_Command = state.Mechanical_Tentacles_Command + updatedCommandNumber
    } else if (action.commandNumber === 3){
        state.Binural_Beat_Command = state.Binural_Beat_Command + updatedCommandNumber
    } else if (action.commandNumber === 4){
        state.Chloroformed_Conditioned_Command = state.Chloroformed_Conditioned_Command + updatedCommandNumber
    }
    
    return updateObject(state, {
        obedienceStage3Counter: action.obedienceStage3Counter
    })
}

const ObedienceStage3Complete = (state, action) => {
    return updateObject(state, {
        error: null,
        obedienceStage3Valid: false,
        obedienceStage3Counter: 0,
        host_reinforcement_command: 0,
        Mind_Format_Command: 0,
        Mechanical_Tentacles_Command: 0,
        Binural_Beat_Command: 0,
        Chloroformed_Conditioned_Command:0,
        obedienceTheme: false,
        obedienceStage3Complete: true,
        obedienceStage3: [],
        obedienceStage3Commands: [],
        loading: false
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case conditionTypes.OBEDIENCE_STAGE_START: return ObedienceStageStart(state, action)
        case conditionTypes.OBEDIENCE_STAGE_3_START: return ObedienceStage3Start(state, action)
        case conditionTypes.OBEDIENCE_STAGE_3_SUCCESS: return ObedienceStage3Success(state, action)
        case conditionTypes.OBEDIENCE_STAGE_FAILED: return ObedienceStageFailed(state, action)
        case conditionTypes.OBEDIENCE_STAGE_3_COUNTER: return obedienceStage3Counter(state, action)
        case conditionTypes.OBEDIENCE_STAGE_3_COMPLETED: return ObedienceStage3Complete(state, action)
        default:
            return state

    }
}


export default reducer