import React, { useState } from "react";
import TextTransition from "react-text-transition";
import TypingEffect from 'react-typing-effect'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import Button from '../../../../UI/Button/Button'
import classes from '../../../../../containers/HostsChamber/AudioRuns/AudioRuns.module.css'
import * as scripts from '../../../../../shared/AudioRuns/TriggerReinforcement/TentaclesReinforcement/TentaclesReinforcementScripts'
import * as Delays from '../../../../../shared/AudioRuns/TriggerReinforcement/TentaclesReinforcement/TentaclesReinforcementDelay'
import Pleasure from '../../../../../assets/audio_runs/Trigger_Reinforcement/Tentacle_Reinforcement/Daily_Pleasure1.mp3'
import Sound from 'react-sound'
import Delay from "react-delay";
import useInterval from 'react-useinterval';
import DailyPleasure2 from './Tentacles_Reinforcement_Daily_Pleasure2'
import { connect } from "react-redux";

const DELAY_TEXT = [3100]


function Tentacle_Daily_Pleasure({profileOptions}) {
  let [count, setCount] = useState(0);
  let [tentacle_daily_pleasure, setProtocol] = useState(true)
  let [tentacle_daily_pleasure2, setPleasure2Sequence] = useState(false)


  const increaseCount = () => {
    if (tentacle_daily_pleasure && count < scripts.DAILY_TENTACLES_PLEASURE_SCRIPT.length) {
      setCount(count + 1);
    }
  };

  useInterval(increaseCount, Delays.DAILY_PLEASURE[count]);

  const enterPleasure2SequenceHandler = () => {
    setProtocol(tentacle_daily_pleasure = false)
    setPleasure2Sequence(tentacle_daily_pleasure2 = true)

  }

  let centerText = window.innerHeight / 2 - 55
  let texting = null
  if (profileOptions.audio_run_background === '0'){
    texting = {

      // display: 'flex',
      // flexFlow: 'column',
      whiteSpace: 'pre-line',
      alignItems: 'center',
      fontWeight: 'bold',
      boxSizing: "border-box",
      margin: 'auto',
      marginBottom: '10px',
      padding: '30px 10px',
      width: '50%',
      paddingTop: centerText + 'px',
      color: 'white'
  
    }
  }

  let tentacles_daily_pleasure_form = null

  if (tentacle_daily_pleasure) {
    tentacles_daily_pleasure_form = (
      <Aux>
        {count < scripts.DAILY_TENTACLES_PLEASURE_SCRIPT.length ?
          <div style ={texting}>
            <Sound url={Pleasure} playStatus={Sound.status.PLAYING} />
            <Delay wait={DELAY_TEXT[count]}>
              <TextTransition
                text={<TypingEffect typingDelay={100} text={scripts.DAILY_TENTACLES_PLEASURE_SCRIPT[count]} speed={90} eraseDelay={1000000} />}
                Overflow
              />
            </Delay>
          </div>
          :
          <div className={classes.ButtonPadding} style ={texting}>
            <Button btnType="Success" clicked={enterPleasure2SequenceHandler}>This host must take off its cloth</Button>
          </div>
        }
      </Aux >
    )
  }

  if (tentacle_daily_pleasure2) {
    tentacles_daily_pleasure_form = <DailyPleasure2 />
  }

  return (
    <Aux>
      {tentacles_daily_pleasure_form}
    </Aux>
  )

}

const mapStateToProps = state => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    profileOptions: state.options.profileOptions,
  }
}

export default connect(mapStateToProps)(Tentacle_Daily_Pleasure)