import React from 'react'
import classes from '../../../../../containers/Account/Account.module.css'
// import Input from '../../UI/Input/Input'
import Button from '../../../../UI/Button/Button'
import Aux from '../../../../../hoc/auxiliary/auxiliary'

const obedienceStage3 = (props) => {
    let obedienceStage3Form = (
        <Aux>
            <div className={classes.AccountContainer}>
                    <form className={classes.FormStyle}>
                        <h2>Obedience Stage 2 Run Details</h2>
                        <p className={classes.smallText}>Becoming The Perfect Host, {props.name}</p>
                        <div>
                            <p><strong>Obedience Stage 3: {props.viewObedienceStage3.obedience_stage3}</strong></p>
                        </div>
                        <div>
                            <p><strong>Host Chip: {props.viewObedienceStage3.Host_Chip_Command} </strong></p>
                        </div>
                        <div>
                            <p><strong>Mind Format: {props.viewObedienceStage3.Mind_Format_Command}</strong></p>
                        </div>
                        <div>
                            <p><strong>Mechanical Tentacles: {props.viewObedienceStage3.Mechanical_Tentacles_Command}</strong></p>
                        </div>
                        <div>
                            <p><strong>Beta Beats: {props.viewObedienceStage3.Binural_Beat_Command}</strong></p>
                        </div>
                        <div>
                            <p><strong>Chloroformed & Conditioned: {props.viewObedienceStage3.Chloroformed_Conditioned_Command}</strong></p>
                        </div>

                        

                    </form>
                    <div className={classes.AuthButtons}>
                        <Button btnType="Danger" clicked={props.ReturnBackHandler}>Back</Button>
                    </div>

                </div>
        </Aux>)


    return obedienceStage3Form
}

export default obedienceStage3