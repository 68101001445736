import React, { Component } from 'react';
import Aux from '../../../hoc/auxiliary/auxiliary'
import { updateObject, checkValidity } from '../../../shared/utility'
import Input from '../../UI/Input/Input'
import Button from '../../UI/Button/Button'
import classes from '../Visual.module.css'
import VisualizeParticle from './ParticleVisual'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'

class ParticleVisual extends Component {
    state = {
        visual_controls: {
            background_color: {
                label: 'Background Color',
                elementType: 'input',
                elementConfig: {
                    type: 'color',
                },
                value: '#000000',
                validation: {
                    required: false
                },
                valid: true,
                touched: false
            },
            Particle_color: {
                label: 'Particles Color',
                elementType: 'input',
                elementConfig: {
                    type: 'color',
                },
                value: '#FFFFFF',
                validation: {
                    required: false
                },
                valid: true,
                touched: false
            },
            Particles_Number: {
                label: 'Particles Number',
                elementType: 'input',
                elementConfig: {
                    type: 'range',
                    min: '100',
                    max: '1000',
                    step: '50'
                },
                value: '400',
                validation: {
                    required: false
                },
                valid: true,
                touched: false
            },
            Sound: {
                label: 'Audio',
                elementType: 'select',
                elementConfig: {
                    options: [
                        { value: 0, displayValue: 'None' },
                        { value: 1, displayValue: 'Bell' },
                        { value: 2, displayValue: 'Metronome' },
                        { value: 3, displayValue: 'Ticking Clock' },
                        { value: 4, displayValue: 'Mantra' },
                        { value: 5, displayValue: 'Mantra Voice' },
                    ]
                },
                value: 0,
                validation: {
                    required: true
                },
                valid: true
            },
            Conditioning_Text: {
                label: 'Conditioning Lines',
                elementType: 'textarea',
                elementConfig: {
                    type: 'textarea',
                    rows: "10",
                    cols: "75",
                    placeholder: 'Seperate by commas, Max 10 Lines',

                },
                value: '',
                validation: {
                    required: true
                },
                valid: true
            },
            Text_Color: {
                label: 'Text Color',
                elementType: 'input',
                elementConfig: {
                    type: 'color',
                },
                value: '#F20574',
                validation: {
                    required: false
                },
                valid: true,
                touched: false
            },
            Text_Speed: {
                label: 'Text Speed',
                elementType: 'input',
                elementConfig: {
                    type: 'range',
                    min:'250',
                    max: '5000',
                    step: '250'
                },
                value: '1500',
                validation: {
                    required: false
                },
                valid: true,
                touched: false
            },
        },
        Presets: {
            Arousal_Preset: {
                label: 'Arousal',
                type: 'checkbox',
                value: 'Arousal',
                touched: false
            },
            Submissive_Preset: {
                label: 'Submissive',
                type: 'checkbox',
                value: 'Submissive',
                touched: false
            },
        },
        visualCompleted: false,
    }

    inputVisualControlChangeHandler = (event, VisualcontrolName) => {
        const updatedVisualControls = updateObject(this.state.visual_controls, {
            [VisualcontrolName]: updateObject(this.state.visual_controls[VisualcontrolName], {
                ...this.state.visual_controls[VisualcontrolName],
                value: event.target.value,
                // valid: checkValidity(event.target.value, this.state.visual_controls[VisualcontrolName].validation),
                touched: true
            })
        })
        this.setState({ visual_controls: updatedVisualControls })
    }

    inputPresetsChangeHandler = (event, presetsKey) => {
        const updatedPresets = updateObject(this.state.Presets, {
            [presetsKey]: updateObject(this.state.Presets[presetsKey], {
                ...this.state.Presets[presetsKey],
                value: event.target.value,
                valid: checkValidity(event.target.value, this.state.Presets[presetsKey].validation),
                touched: !this.state.Presets[presetsKey].touched
            })
        })
        this.setState({ Presets: updatedPresets })
    }

    submitHandler = (event) => {
        event.preventDefault();
        this.setState({ visualCompleted: true })
    }

    returnHandler = () => {
        this.setState({ visualCompleted: false })
    }

    render() {

        const formVisualControl = [];
        for (let key in this.state.visual_controls) {
            formVisualControl.push({
                id: key,
                config: this.state.visual_controls[key]
            });
        }
        const formPreset = [];
        for (let key in this.state.Presets) {
            formPreset.push({
                id: key,
                config: this.state.Presets[key]
            });
        }
        let ParticleVisual = null
        if (!this.state.visualCompleted) {
            ParticleVisual = (
                <div className={classes.Visual}>
                    <form className={classes.FormStyle}>
                        <h3>Particles</h3><br></br>
                        {formVisualControl.map(formElement => (
                            <Input
                                key={formElement.id}
                                elementType={formElement.config.elementType}
                                elementConfig={formElement.config.elementConfig}
                                value={formElement.config.value}
                                invalid={!formElement.config.valid}
                                shouldValidate={formElement.config.validation}
                                touched={formElement.config.touched}
                                label={formElement.config.label}
                                changed={(event) => this.inputVisualControlChangeHandler(event, formElement.id)} />

                        ))}
                        <div className={classes.column}>
                            {formPreset.map(presetElement => (
                                <Aux>
                                    <input
                                       key={presetElement.id}
                                       type={presetElement.config.type}
                                       value={presetElement.config.value}
                                       touched={presetElement.config.touched}
                                       onChange={(event) => this.inputPresetsChangeHandler(event, presetElement.id)} 
                                    />
                                    
                                    <label>{presetElement.config.label}</label>
                                </Aux>
                            ))}
                        </div>
                        <br></br><Button btnType="Success" clicked={this.submitHandler}>View Visual</Button>
                        <Button btnType="Danger" clicked={this.props.returnToVisualHandler}>Return to Homepage</Button>
                    </form>
                    <p className={classes.Note}>Performance/Quality May Vary with Text</p>
                </div>
            )
        } else {
            ParticleVisual = <VisualizeParticle
                background_color={this.state.visual_controls.background_color.value}
                color={this.state.visual_controls.Particle_color.value}
                particles_number={this.state.visual_controls.Particles_Number.value}
                sound={this.state.visual_controls.Sound.value}
                conditioning_text={this.state.visual_controls.Conditioning_Text.value}
                text_color={this.state.visual_controls.Text_Color.value}
                text_speed={this.state.visual_controls.Text_Speed.value}
                arousal_check={this.state.Presets.Arousal_Preset.touched}
                submissive_check={this.state.Presets.Submissive_Preset.touched}
                returnHandler = {this.returnHandler}
                 />
        }
        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {ParticleVisual}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >
        )
    }
}


export default ParticleVisual