import React from 'react';
import TypingEffect from 'react-typing-effect';
import Delay from 'react-delay'
import Aux from '../../../hoc/auxiliary/auxiliary'
import Induction from './Inductions/InductionsComponent'
import ForeheadInduction from './Inductions/ForeheadTap/ForeheadTap'
import BellInduction from './Inductions/BellInduction/BellInduction'
import MantraComponent from './Mantra/MantraComponent'
import DeepenerComponent from './Deepener/DeepenerComponent'
import TickingClockDeepener from './Deepener/TickingClockDeepener/TickingClockDeepener'
import MetronomeDeepener from './Deepener/MetronomeDeepener/MetronomeDeepener'
import TriggerComponent from './Trigger/TriggerComponent'
import TriggerCommand from './Trigger/TriggerCommand/TriggerCommand'
import TriggerRepetition from './Trigger/TriggerRepetition/TriggerRepetition'
import AwakenerComponent from './Awakener/AwakenerComponent'
import AwakenerRemember from './Awakener/AwakenerRemember/AwakenerRemember'
import AwakenerForget from './Awakener/AwakenerForget/AwakenerForget'
import EndingComponent from './Ending/EndingComponent'

import Sound from 'react-sound'
import Bell from '../../../assets/audio/bell.mp3'
import classes from './HostsRunComponent.module.css'

const hostsChamber = (props) => {
   
    // let praises = ['good toy', 'good host', 'good puppet']
    
    // if (props.gender === 'Male') {
    //     praises.push('good boy')
    //     // praiseChoice = Math.floor(Math.random() * 3);
    // } else if (props.gender === 'Female'){
    //     praises.push('good girl')
    //     // praiseChoice = Math.floor(Math.random() * 3);
    // }
    // let praiseChoice = Math.floor(Math.random() * praises.length);
    
    // let praise = praises[praiseChoice]
    
    // eslint-disable-next-line
    const introduction = 'Welcome, ' + (props.naming) + '\n\
                          You have been selected\n\
                          You have been selected to be Our new ' + // eslint-disable-next-line
                          (props.title) + '\n\
                          We will be starting your conditioning shortly\n\
                          By the time We are done with you\n\
                          You will understand why all must obey Us\n\
                          We are positive you will see things Our way like the ' + // eslint-disable-next-line
                          (props.title) + ' you are'
    let paragraph = null
    let duration = 0
    let speed = parseInt(props.speed) +5
    if (props.introduction) {
        duration = 150+(introduction.length*speed)+ 15000
        paragraph = (
            <Aux>
                <h3 className= {classes.titles}>INTRODUCTION</h3>
                <Sound url={Bell} playStatus={Sound.status.PLAYING} />
                <Delay wait = {5000}><Sound url={Bell} playStatus={Sound.status.PLAYING} /></Delay>
                <Delay wait = {10000}><Sound url={Bell} playStatus={Sound.status.PLAYING} /></Delay>
                <Delay wait={14000}><TypingEffect typingDelay={100} text={introduction} speed={props.speed} eraseDelay={1000000} /></Delay>
                <Delay wait = {duration}><h4 className={classes.blinking} onClick={props.introduction}>I want to give in to The Hypno Virus</h4></Delay>
            </Aux>
        )
    } else if (props.induction) {
        paragraph = (<Induction 
                        naming = {props.naming} 
                        // praises = {praises} 
                        title = {props.title}
                        speed = {props.speed}
                        inductionMethodName = {props.inductionMethodName}
                        induction = {props.induction}/>
        )
    } 
    else if (props.foreheadInduction) {
        paragraph = ( <ForeheadInduction 
                        naming = {props.naming} 
                        // praises = {praises}
                        title = {props.title}
                        speed = {props.speed}
                        foreheadInduction = {props.foreheadInduction} />
        )
    } else if (props.bellInduction) {
        paragraph = ( <BellInduction 
                        naming = {props.naming} 
                        // praises = {praises}
                        title = {props.title}
                        speed = {props.speed}
                        bellInduction = {props.bellInduction} />
        )
    } else if (props.mantra) {
        paragraph = ( <MantraComponent
                        naming = {props.naming} 
                        // praises = {praises}
                        title = {props.title}
                        speed = {props.speed}
                        mantra = {props.mantra} />
        )
    } else if (props.deepener) {
        paragraph = ( <DeepenerComponent 
                        naming = {props.naming} 
                        // praises = {praises}
                        title = {props.title}
                        speed = {props.speed}
                        deepenerMethodName = {props.deepenerMethodName}
                        deepener = {props.deepener} />
        )
    } else if (props.tickingDeepener) {
        paragraph = ( <TickingClockDeepener 
                        naming = {props.naming} 
                        // praises = {praises}
                        title = {props.title}
                        speed = {props.speed}
                        tickingDeepener = {props.tickingDeepener} />
        )
    } else if (props.metronomeDeepener) {
        paragraph = ( <MetronomeDeepener 
                        naming = {props.naming} 
                        // praises = {praises}
                        title = {props.title}
                        speed = {props.speed}
                        metronomeDeepener = {props.metronomeDeepener} />
        )
    } else if (props.trigger) {
        paragraph = ( <TriggerComponent 
                        naming = {props.naming} 
                        // praises = {praises}
                        title = {props.title}
                        speed = {props.speed}
                        triggerMethodName = {props.triggerMethodName}
                        trigger = {props.trigger} />
        )
    } else if (props.triggerCommand) {
        paragraph = ( <TriggerCommand 
                        naming = {props.naming} 
                        // praises = {praises}
                        title = {props.title}
                        speed = {props.speed}
                        triggerCommand = {props.triggerCommand} />
        )
    } else if (props.triggerRepetition) {
        paragraph = ( <TriggerRepetition 
                        naming = {props.naming} 
                        // praises = {praises}
                        title = {props.title}
                        speed = {props.speed}
                        triggerRepetition = {props.triggerRepetition} />
        )
    } else if (props.awakener) {
        paragraph = ( <AwakenerComponent 
                        naming = {props.naming} 
                        // praises = {praises}
                        title = {props.title}
                        speed = {props.speed}
                        awakenerMethodName = {props.awakenerMethodName}
                        awakener = {props.awakener} />
        )
    } else if (props.awakenerRemember) {
        paragraph = ( <AwakenerRemember 
                        naming = {props.naming} 
                        // praises = {praises}
                        title = {props.title}
                        speed = {props.speed}
                        awakenerRemember = {props.awakenerRemember} />
        )
    } else if (props.awakenerForget) {
        paragraph = ( <AwakenerForget 
                        naming = {props.naming} 
                        // praises = {praises}
                        title = {props.title}
                        speed = {props.speed}
                        awakenerForget = {props.awakenerForget} />
        )
    } else if (props.ending) {
        paragraph = ( <EndingComponent 
                        naming = {props.naming} 
                        // praises = {praises}
                        title = {props.title}
                        speed = {props.speed}
                        ending = {props.ending} />
        )
    } 

return (
    <div className={classes.texting} >
        {paragraph}
    </div>
)
}


export default hostsChamber