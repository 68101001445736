import React from 'react';
import TypingEffect from 'react-typing-effect';
import Delay from 'react-delay'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import classes from '../../../HostsRun/HostsRunComponent.module.css'
import Bell from '../../../../../assets/audio/bell.mp3'
import Sound from 'react-sound'


const awakenerRemember = (props) => {
    // let praiseChoice = 0
    // praiseChoice = Math.floor(Math.random() * props.praises.length)-1;
    // let praise = props.praises[praiseChoice]
    
    // eslint-disable-next-line
    const awakenerRememberMessage = (props.title) +" "+ (props.naming) + ", It is time for you to wake up\n\
                                     In a moment We will ring the bell\n\
                                     Once you hear it you will slowly wake up\n\
                                     Wake up feeling absolutely wonderful\n\
                                     So happy\n\
                                     So excited\n\
                                     Remembering everything We did\n\
                                     Eager\n\
                                     Eager to return to Us\n\
                                     To return to The Hypno Virus\n\
                                     Return and be Our " + // eslint-disable-next-line
                                     (props.title) + ' again\n\
                                     Remembering what We did\n\
                                     Realizing the power We now have over you\n\
                                     Which makes you want nothing more\n\
                                     Nothing more than to return to Us\n\
                                     Return to The Hypno Virus'
    let speed = parseInt(props.speed) +5
    let  duration = 170 + (awakenerRememberMessage.length * speed)
    let awakenerRememberParagraph = (
            <Aux>
                <h3 className= {classes.titles}>AWAKENER REMEMBER</h3>
                <Delay wait={5000}><TypingEffect typingDelay={100} text={awakenerRememberMessage} speed={props.speed} eraseDelay={1000000} /></Delay>
                <Delay wait={duration+5000}><Sound url={Bell} playStatus={Sound.status.PLAYING} /></Delay>
                <Delay wait={duration+10000}><h4 className={classes.blinking} onClick={props.awakenerRemember}>The Hypno Virus made me Remember everything</h4></Delay>
            </Aux>
        )
    
    return awakenerRememberParagraph
}


export default awakenerRemember