import React from 'react';
import TypingEffect from 'react-typing-effect';
import Delay from 'react-delay'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import classes from '../../../HostsRun/HostsRunComponent.module.css'

 
const triggerRepetition = (props) => {
    // let praiseChoice = 0
    // praiseChoice = Math.floor(Math.random() * props.praises.length);
    // let praise = props.praises[praiseChoice]
    let triggerRepeat = Math.floor(Math.random() * 15)+5;
    let triggerDepth = (Math.floor(Math.random() * 10)+1)*10;
    // eslint-disable-next-line
    const triggerRepetitionMessage = "The Hypno Virus Took Over\n\
                                      The Hypno Virus Took Over\n\
                                      The more you experience it\n\
                                      The Deeper you go\n\
                                      The Deeper you go\n\
                                      The more submissive you become\n\
                                      The Hypno Virus took over\n\
                                      In a moment you will repeat your trigger " + // eslint-disable-next-line
                                      triggerRepeat + ' times\n\
                                      Everytime you repeat it you fall even deeper\n' // eslint-disable-next-line
                                      + triggerDepth +' times deeper everytime \n\
                                      A ' + (props.title) + " doing as you're told now"
    let speed = parseInt(props.speed) +5
    let  duration = 170 + (triggerRepetitionMessage.length * speed)
    let triggerRepetitionParagraph = (
            <Aux>
                <h3 className= {classes.titles}>TRIGGER REPETITION</h3>
                <Delay wait={5000}><TypingEffect typingDelay={100} text={triggerRepetitionMessage} speed={props.speed} eraseDelay={1000000} /></Delay>
                <Delay wait={duration+5000}><h4 className={classes.blinking} onClick={props.triggerRepetition}>The Hypno Virus took complete Control</h4></Delay>
            </Aux>
        )
    





    return triggerRepetitionParagraph
}


export default triggerRepetition