import React from 'react'
import classes from '../../../../containers/Account/Account.module.css'
// import Input from '../../UI/Input/Input'
import Button from '../../../UI/Button/Button'
import Aux from '../../../../hoc/auxiliary/auxiliary'

const tentacleIntroduction = (props) => {
    let tentacleIntroductionForm = (
        <Aux>
            <div className={classes.AccountContainer}>
                    <form className={classes.FormStyle}>
                        <h2>T.E.N.T.A.C.L.E. Introduction Run Details</h2>
                        <p className={classes.smallText}>T.E.N.T.A.C.L.E. unit is ready to be activated, {props.name}</p>
                        <div>
                            <p><strong>T.E.N.T.A.C.L.E. Introduction Run: {props.TentacleIntroduction.Tentacle_introduction}</strong></p>
                        </div>
                        <div>
                            <p><strong>Obedience Sequence: {props.TentacleIntroduction.obedience_sequence} </strong></p>
                        </div>
                        <div>
                            <p><strong>Resistance Sequence: {props.TentacleIntroduction.resistance_sequence}</strong></p>
                        </div>
                    </form>
                    <div className={classes.AuthButtons}>
                        <Button btnType="Danger" clicked={props.ReturnBackHandler}>Back</Button>
                    </div>

                </div>
        </Aux>)


    return tentacleIntroductionForm
}

export default tentacleIntroduction