import * as audioRunTypes from '../audioRunTypes'
import axios from '../../../../axios'

export const ConditioningProtocol1Start = () => {
    return {
        type: audioRunTypes.AUDIO_RUN_START
    }
}

export const ConditioningProtocol1Success = (conditioning_protocol1) => {
        return {
            type: audioRunTypes.CONDITIONING_PROTOCOL1_SUCCESS,
            conditioning_protocol1: conditioning_protocol1,
        }
   
}

export const ConditioningProtocol1 = (userId, token) => {
    return dispatch => {
        dispatch(ConditioningProtocol1Start());
        axios.get('/Runs/'+userId+'/AudioRuns/Conditioning_Protocols/Conditioning_Protocols1.json?auth=' + token)
            
            .then( response => {
                // dispatch(ObedienceStageStart());
                if(response.data === null){
                    let conditioning_protocol1 = {
                        conditioning_protocol1: 0,
                        comply_sequence:0,
                        resistance_sequence:0,
                    }
                    axios.put('/Runs/'+userId+'/AudioRuns/Conditioning_Protocols/Conditioning_Protocols1.json?auth=' + token, conditioning_protocol1)
                    .then(response => {
                        dispatch(ConditioningProtocol1Success(response.data))
                    })
                    .catch(error => {
                        dispatch(ConditioningProtocol1Failed(error.message.data))
                    })
                    
                } else{
                    dispatch(ConditioningProtocol1Success(response.data))
                }
                       
            })
            .catch(error => {
                dispatch(ConditioningProtocol1Failed(error.message.data))
            })
    }
}

export const ConditioningProtocol1Failed = (error) => {
    return{
        type: audioRunTypes.AUDIO_RUN_FAILED,
        error: error
    }
}

export const ConditioningProtocol1End = (userId, token, comply_sequenece, resistance_sequence) => {
    return dispatch => {
        axios.get('/Runs/'+userId+'/AudioRuns/Conditioning_Protocols/Conditioning_Protocols1.json?auth=' + token)
            .then( response => {
                    let updatedconditioning_protocol1 = {
                        conditioning_protocol1: response.data.conditioning_protocol1 + 1,
                        comply_sequence: response.data.comply_sequence + comply_sequenece,
                        resistance_sequence: response.data.resistance_sequence + resistance_sequence,
                    }
                    axios.put('/Runs/'+userId+'/AudioRuns/Conditioning_Protocols/Conditioning_Protocols1.json?auth=' + token, updatedconditioning_protocol1)
                    .then(response => {
                        dispatch(ConditioningProtocol1Completed())
                    })
                    .catch(error => {
                        dispatch(ConditioningProtocol1Failed(error.message.data))
                    })    
                       
            })
            .catch(error => {
                dispatch(ConditioningProtocol1Failed(error.message))
            })
    }
}

export const ConditioningProtocol1Completed = () => {
    return{
            type: audioRunTypes.CONDITIONING_PROTOCOL1_COMPLETED,
        }
        
    
}