export const AUDIO_RUN_START = 'AUDIO_RUN_START'
export const AUDIO_RUN_FAILED = 'AUDIO_RUN_FAILED'

//Conditioning Protocol #1
export const CONDITIONING_PROTOCOL1_SUCCESS = 'CONDITIONING_PROTOCOL1_SUCCESS'
export const CONDITIONING_PROTOCOL1_COMPLETED = 'CONDITIONING_PROTOCOL1_COMPLETED'

//Tentacle Introduction
export const TENTACLE_INTRODUCTION_SUCCESS = 'TENTACLE_INTRODUCTION_SUCCESS'
export const TENTACLE_INTRODUCTION_COMPLETED = 'TENTACLE_INTRODUCTION_COMPLETED'

//Tentacle Reinforcement
export const TENTACLE_REINFORCEMENT_SUCCESS = 'TENTACLE_REINFORCEMENT_SUCCESS'
export const TENTACLE_REINFORCEMENT_COMPLETED = 'TENTACLE_REINFORCEMENT_COMPLETED'

//Chloroform Introduction
export const CHLOROFORM_INTRODUCTION_SUCCESS = 'CHLOROFORM_INTRODUCTION_SUCCESS'
export const CHLOROFORM_ROOM_INTRODUCTION = 'CHLOROFORM_ROOM_INTRODUCTION'
export const FORCED_CHLOROFORM_INTRODUCTION = 'FORCED_CHLOROFORM_INTRODUCTION'
export const CHLOROFORM_INTRODUCTION_COMPLETED = 'CHLOROFORM_INTRODUCTION_COMPLETED'

//Chloroform Reinforcement
export const CHLOROFORM_REINFORCEMENT_SUCCESS = 'CHLOROFORM_REINFORCEMENT_SUCCESS'
export const FAST_EFFECT_CHLOROFORM = 'FAST_EFFECT_CHLOROFORM'
export const NORMAL_EFFECT_CHLOROFORM = 'NORMAL_EFFECT_CHLOROFORM'
export const CHLOROFORM_REINFORCEMENT_COMPLETED = 'CHLOROFORM_REINFORCEMENT_COMPLETED'

//Motor Function Control Introduction
export const MOTOR_FUNCTION_CONTROL_INTRODUCTION_SUCCESS = 'MOTOR_FUNCTION_CONTROL_INTRODUCTION_SUCCESS'
export const INSTANT_MOTOR_FUNCTION_CONTROL_INTRODUCTION = 'INSTANT_MOTOR_FUNCTION_CONTROL_INTRODUCTION'
export const SLOW_MOTOR_FUNCTION_CONTROL_INTRODUCTION = 'SLOW_MOTOR_FUNCTION_CONTROL_INTRODUCTION'
export const MOTOR_FUNCTION_CONTROL_INTRODUCTION_COMPLETED = 'MOTOR_FUNCTION_CONTROL_INTRODUCTION_COMPLETED'

//Motor Function Control Reinforcement
export const MOTOR_FUNCTION_CONTROL_REINFORCEMENT_SUCCESS = 'MOTOR_FUNCTION_CONTROL_REINFORCEMENT_SUCCESS'
// export const INSTANT_MOTOR_FUNCTION_CONTROL_REINFORCEMENT = 'INSTANT_MOTOR_FUNCTION_CONTROL_REINFORCEMENT'
// export const SLOW_MOTOR_FUNCTION_CONTROL_REINFORCEMENT = 'SLOW_MOTOR_FUNCTION_CONTROL_REINFORCEMENT'
export const MOTOR_FUNCTION_CONTROL_REINFORCEMENT_COMPLETED = 'MOTOR_FUNCTION_CONTROL_REINFORCEMENT_COMPLETED'