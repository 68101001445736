import * as audioRunTypes from '../audioRunTypes'
import axios from '../../../../axios'

export const TentacleReinforcementStart = () => {
    return {
        type: audioRunTypes.AUDIO_RUN_START
    }
}

export const TentacleReinforcementSuccess = (Tentacle_Reinforcement) => {
        return {
            type: audioRunTypes.TENTACLE_REINFORCEMENT_SUCCESS,
            Tentacle_Reinforcement: Tentacle_Reinforcement,
        }
   
}

export const TentacleReinforcement = (userId, token) => {
    return dispatch => {
        dispatch(TentacleReinforcementStart());
        axios.get('/Runs/'+userId+'/AudioRuns/Trigger_Reinforcement/Tentacle_Reinforcement.json?auth=' + token)
            
            .then( response => {
                // dispatch(ObedienceStageStart());
                if(response.data === null){
                    let Tentacle_Reinforcement = {
                        Tentacle_Reinforcement: 0,
                        daily_pleasure:0,
                        daily_spank:0,
                        daily_electric_spank:0,
                        covert_fullday:0,
                        covert_edging:0,
                        covert_body_writing:0
                    }
                    axios.put('/Runs/'+userId+'/AudioRuns/Trigger_Reinforcement/Tentacle_Reinforcement.json?auth=' + token, Tentacle_Reinforcement)
                    .then(response => {
                        dispatch(TentacleReinforcementSuccess(response.data))
                    })
                    .catch(error => {
                        dispatch(TentacleReinforcementFailed(error.message.data))
                    })
                    
                } else{
                    dispatch(TentacleReinforcementSuccess(response.data))
                }
                       
            })
            .catch(error => {
                dispatch(TentacleReinforcementFailed(error.message.data))
            })
    }
}

export const TentacleReinforcementFailed = (error) => {
    return{
        type: audioRunTypes.AUDIO_RUN_FAILED,
        error: error
    }
}

export const TentacleReinforcementEnd = (userId, token, daily_pleasure, daily_spank, daily_electric_spank, covert_fullday, covert_edging, covert_body_writing) => {
    return dispatch => {
        axios.get('/Runs/'+userId+'/AudioRuns/Trigger_Reinforcement/Tentacle_Reinforcement.json?auth=' + token)
            .then( response => {
                    let updatedTentacle_Reinforcement = {
                        Tentacle_Reinforcement: response.data.Tentacle_Reinforcement + 1,
                        daily_pleasure: response.data.daily_pleasure + daily_pleasure,
                        daily_spank: response.data.daily_spank + daily_spank,
                        daily_electric_spank: response.data.daily_electric_spank + daily_electric_spank,
                        covert_fullday: response.data.covert_fullday + covert_fullday,
                        covert_edging: response.data.covert_edging + covert_edging,
                        covert_body_writing: response.data.covert_body_writing + covert_body_writing,
                    }
                    axios.put('/Runs/'+userId+'/AudioRuns/Trigger_Reinforcement/Tentacle_Reinforcement.json?auth=' + token, updatedTentacle_Reinforcement)
                    .then(response => {
                        dispatch(TentacleReinforcementCompleted())
                    })
                    .catch(error => {
                        dispatch(TentacleReinforcementFailed(error.message.data))
                    })    
                       
            })
            .catch(error => {
                dispatch(TentacleReinforcementFailed(error.message))
            })
    }
}

export const TentacleReinforcementCompleted = () => {
    return{
            type: audioRunTypes.TENTACLE_REINFORCEMENT_COMPLETED,
        }
        
    
}