import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import { Provider } from 'react-redux';
import FreeRunReducer from './store/reducer/Freerun'
import HostsChamberReducer from './store/reducer/HostsChamber'
import HomePageReducer from './store/reducer/HomePage'
import authReducer from './store/reducer/Account/auth'
import profileReducer from './store/reducer/Account/Profile'
import runReducer from './store/reducer/Account/Runs'
import obedienceReducer from './store/reducer/ConditioningStages/ObedienceStages/obedienceStages'
import obedienceReducer1 from './store/reducer/ConditioningStages/ObedienceStages/obedienceStages1'
import obedienceReducer2 from './store/reducer/ConditioningStages/ObedienceStages/obedienceStages2'
import obedienceReducer3 from './store/reducer/ConditioningStages/ObedienceStages/obedienceStages3'
import ConditioningProtocol1 from './store/reducer/AudioRuns/Conditioning_Protocols/Conditioning_Protocol1'
import TentacleIntroduction from './store/reducer/AudioRuns/Trigger_Introduction/Tentacle_Introduction'
import ChloroformIntroduction from './store/reducer/AudioRuns/Trigger_Introduction/Chloroform_Introduction'
import TentacleReinforcement from './store/reducer/AudioRuns/Trigger_Reinforcement/Tentacle_Reinforcement'
import ChloroformReinforcement from './store/reducer/AudioRuns/Trigger_Reinforcement/Chloroform_Reinforcement'
import MotroFunctionControlIntroduction from './store/reducer/AudioRuns/Trigger_Introduction/Motor_Function_Control_Introduction'
import MotorFunctionControlReinforcement from './store/reducer/AudioRuns/Trigger_Reinforcement/Motor_Function_Control_Reinforcement'
import options from './store/reducer/Account/options'
import titles from './store/reducer/Account/titles'
import hypnosisShow from './store/reducer/MindMeltingExperience/TheGreatOHypnosisShow/TheGreatOHypnosisShow'

const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;

const rootReducer = combineReducers({
    freeRun: FreeRunReducer,
    homePage: HomePageReducer,
    hostsChamber: HostsChamberReducer,
    auth: authReducer,
    obedienceReducer: obedienceReducer,
    obedienceReducer1: obedienceReducer1,
    obedienceReducer2: obedienceReducer2,
    obedienceReducer3: obedienceReducer3,
    profile: profileReducer,
    runs: runReducer,
    options: options,
    titles: titles,
    conditioningProtocol1: ConditioningProtocol1,
    tentacleIntro: TentacleIntroduction,
    chloroIntro: ChloroformIntroduction,
    tentacleReinforce: TentacleReinforcement,
    chloroReinforce: ChloroformReinforcement,
    motorFunctionIntro: MotroFunctionControlIntroduction,
    motorFunctionReinforce: MotorFunctionControlReinforcement,
    hypnosisShow: hypnosisShow
})


const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
))

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
)

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
