import React, { Component } from 'react';
import Aux from '../../../../hoc/auxiliary/auxiliary';
import classes from '../HypnosisShowMenu.module.scss'
// import classes2 from '../ExperienceMenu/ExperienceMenu.module.scss'
import HypnosisShow from '../../../../assets/Images/Blank.png'
import Conclusion from './HypnosisShowVolConclusion'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'

class HypnosisShowAwake extends Component {

    state = {
        awake: true,
        conclusion: false
    }

    ConclusionHandler = () => {
        this.setState({ awake: false, conclusion: true })
    }


    render() {
        let HypnosisShowForm = null
        if (this.state.awake) {
            HypnosisShowForm = (
                <html style={{ backgroundImage: `url(${HypnosisShow})` }}>
                    <div className={classes.storyFrom}>
                        <p>
                            Taking a deep breath in, you stretch & open your eyes while waking up. Feeling so good, unbelievably relaxed & comfortable like waking up from a perfect nap. <br></br> 
                        <i>“A round of applause for these amazing volunteers & for all of you lovely people out there!" </i> <br></br> 
                        You hear him say while bowing to the audience as they all clap to him and by extension to you. <br></br>
                        <i>"You’ve all been amazing and I hope to see you in my upcoming shows! You can grab a flyer on your way out if you want to book me for any public or private event!” </i><br></br>
                        You stand up along with the other volunteers, making your way down the stage. The Great O stands by the stairs smiling & shaking everyone’s hand while handing flyers to everyone. <br></br> 
                        He tells you <i>“You were quite lovely, I have a feeling I’ll be hearing from some of you soon too” </i><br></br>
                        He looks at you shifting his tone to a more hypnotic one <i>“Isn’t that right?” </i><br></br>
                        You realize that you’re nodding and notice a few others nodding as well in an almost trance like state.  
                        <i>“Excellent!” </i><br></br>
                        He says in a normal tone again as he shakes your hand and you go down the stage.
                        </p>
                        <form className={classes.centered} onClick={this.ConclusionHandler}>
                            <h3 className={classes.storyButtonSize}>Conclusion</h3>
                        </form>
                    
                    </div>
                </html>
            )
        }
        if (this.state.conclusion) {
            HypnosisShowForm = (
                <Conclusion />
            )
        }

    

        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {HypnosisShowForm}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >




        )
    }
}


export default HypnosisShowAwake