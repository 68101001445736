import React, { Component } from 'react'
import Input from '../../components/UI/Input/Input'
import Button from '../../components/UI/Button/Button'
import classes from './Account.module.css'
import Aux from '../../hoc/auxiliary/auxiliary';
import * as actions from '../../store/actions/index';
import Spinner from '../../components/UI/Spinner/Spinner';
import { connect } from 'react-redux'
import { updateObject, checkValidity } from '../../shared/utility'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'
import { Redirect } from 'react-router-dom';
import PasswordDatabase from '../../components/Account/Password_Database'
import Profile from './Profile/Profile'
import Runs from './Runs/Runs'
import Background from '../../components/Background/Background'
import WebsiteAudio from '../../components/Background/WebsiteAudio';

class Account extends Component {
    state = {
        controls: {
            password: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Password'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 6
                },
                valid: false,
                touched: false
            },
        },
        InfoForm: {
            registerEmail: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Your email'
                },
                value: '',
                validation: {
                    required: true,
                    isEmail: true
                },
                valid: false,
                touched: false
            },
            registerPassword: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Password'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 8
                },
                valid: false,
                touched: false
            },
        },
        isSignUp: false,
        formIsValid: false,
        PasswordEntered: false,
        selectForm: -1,
        message: null
    }

    // componentDidMount() {
        
    //     this.props.onTitlesView()
    // }
    inputChangedHandler = (event, controlName) => {
        
        
        const updatedControls = updateObject(this.state.controls, {
            [controlName]: updateObject(this.state.controls[controlName], {
                ...this.state.controls[controlName],
                value: event.target.value,
                valid: checkValidity(event.target.value, this.state.controls[controlName].validation),
                touched: true
            })
        })
        let PasswordEntered = true
        for (let controlName in updatedControls) {
            PasswordEntered = updatedControls[controlName].valid && PasswordEntered;
        }
        this.setState({ controls: updatedControls, PasswordEntered: PasswordEntered })
    }

    inputAuthHandler = (event, inputIdentifier) => {

        const updatedFormElement = updateObject(this.state.InfoForm[inputIdentifier], {
            ...this.state.InfoForm[inputIdentifier],
            value: event.target.value,
            valid: checkValidity(event.target.value, this.state.InfoForm[inputIdentifier].validation),
            touched: true
        });
        const updatedInfo = updateObject(this.state.InfoForm, {
            [inputIdentifier]: updatedFormElement
        });

        let formIsValid = true;
        for (let inputIdentifier in updatedInfo) {
            formIsValid = updatedInfo[inputIdentifier].valid && formIsValid;
        }
        this.setState({ InfoForm: updatedInfo, formIsValid: formIsValid });

    }

    authFormHandler = (event) => {
        event.preventDefault();
        this.props.onAuth(this.state.InfoForm.registerEmail.value, this.state.InfoForm.registerPassword.value, this.state.isSignUp)
        if(this.state.isSignUp){
            this.setState({selectForm: 0})
        }
        
    }

    submitHandler = (event) => {
        event.preventDefault();
        this.props.onPasswordAuth(this.state.controls.password.value)
        if(this.props.PasswordSubmitted) {
            this.setState({ selectForm: 0})
        }
        
    }

    switchViewModelHandler = () => {
        this.setState({ selectForm: 0})
    }

    switchSignUpModelHandler = () => {
        this.setState({isSignUp: true, selectForm: 1})
    }

    switchSignInModelHandler = () => {
        this.setState({isSignUp: false, selectForm: 2})
    }

    ReturnBackHandler = (event) => {
        event.preventDefault();
        this.props.onReturnBack()
    }



    render() {
        const formElementsArray = [];
        for (let key in this.state.controls) {
            formElementsArray.push({
                id: key,
                config: this.state.controls[key]
            });
        }

        const authFormElementsArray = [];
        for (let key in this.state.InfoForm) {
            authFormElementsArray.push({
                id: key,
                config: this.state.InfoForm[key]
            });
        }

        let form = null
        if (this.props.token === null) {
            form =
                <div className={classes.Account}>
                    <PasswordDatabase
                        formElementsArray={formElementsArray}
                        inputChangedHandler={this.inputChangedHandler}
                        PasswordEntered={this.state.PasswordEntered}
                        PasswordSubmitted={this.props.PasswordSubmitted}
                        submitHandler={this.submitHandler} />
                </div>
        }
        if (this.state.selectForm === 0) {
            form =
            <div className={classes.Account}>
                <div className={classes.AccountContainer}>
                    <form className={classes.FormStyle}>
                        <h2>Welcome where you belong</h2>
                        <p className={classes.smallText}>We will make sure you love it here</p>
                    </form>
                    <div className={classes.AuthButtons}>
                        <Button btnType="Success" clicked={this.switchSignInModelHandler}>Sign In</Button>
                        <Button btnType="Success" clicked={this.switchSignUpModelHandler}>Sign Up</Button>
                    </div>
                </div>
            </div>
        }

        if (this.state.selectForm === 1) {
            form =
                <div className={classes.Account}>
                    <div className={classes.AccountContainer}>
                        <form className={classes.FormStyle}>
                            <h2>Sign Up, Host</h2>
                            <p className={classes.smallText}>The start of your new life</p>
                            {authFormElementsArray.map(formElement => (
                                <Input
                                    key={formElement.id}
                                    elementType={formElement.config.elementType}
                                    elementConfig={formElement.config.elementConfig}
                                    value={formElement.config.value}
                                    invalid={!formElement.config.valid}
                                    shouldValidate={formElement.config.validation}
                                    touched={formElement.config.touched}
                                    changed={(event) => this.inputAuthHandler(event, formElement.id)} />
                            ))}
                        </form>
                        <div className={classes.AuthButtons}>
                            <Button btnType="Success" disabled={!this.state.formIsValid} clicked={this.authFormHandler}>This Host Submits</Button>
                            <Button btnType="Danger" clicked={this.switchViewModelHandler}>Return to Form Selection</Button>
                        </div>
                    </div>
                </div>
        }

        if (this.state.selectForm === 2) {
            form =
                <div className={classes.Account}>
                    <div className={classes.AccountContainer}>
                        <form className={classes.FormStyle}>
                            <h2>Sign In, Host</h2>
                            <p className={classes.smallText}>The start of your new life</p>
                            {authFormElementsArray.map(formElement => (
                                <Input
                                    key={formElement.id}
                                    elementType={formElement.config.elementType}
                                    elementConfig={formElement.config.elementConfig}
                                    value={formElement.config.value}
                                    invalid={!formElement.config.valid}
                                    shouldValidate={formElement.config.validation}
                                    touched={formElement.config.touched}
                                    changed={(event) => this.inputAuthHandler(event, formElement.id)} />
                            ))}
                        </form>
                        <div className={classes.AuthButtons}>
                            <Button btnType="Success" disabled={!this.state.formIsValid} clicked={this.authFormHandler}>This Host Submits</Button>
                            <Button btnType="Danger" clicked={this.switchViewModelHandler}>Return to Form Selection</Button>
                        </div>
                    </div>
                </div>
        }



        if (this.props.viewProfile) {
            form = <Profile />
        }

        if (this.props.editProfile) {
            form = <Profile />
        }

        if (this.props.viewRunDetails) {
            form = <Runs />
        }

        if (this.props.loading) {
            form = <div className={classes.Account}>
                <Spinner className={classes.Spinner} />
            </div>
        }

        let errorMessage = null

        if (this.props.error) {
            errorMessage = (
                <p className={classes.error}>{this.props.error}</p>
            )
        }

        let authRedirect = null
        if (this.props.authRedirectValid) {
            authRedirect = <Redirect to='/' />
        }

        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {authRedirect}
                            <div className={classes.Account}>
                                <WebsiteAudio />
                                <Background />
                                {errorMessage}
                                {form}
                            </div>
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >
            </Aux >

        );
    }
}

const mapStatesToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        token: state.auth.token,
        userId: state.auth.userId,
        email: state.auth.email,
        password: state.auth.password,
        isVerified: state.auth.isVerified,
        isAuthenticated: state.auth.token !== null,
        PasswordSubmitted: state.auth.PasswordSubmitted,
        authRedirectValid: state.auth.authRedirectValid,
        profileActive: state.profile.profileActive,
        viewProfile: state.profile.viewProfile,
        editProfile: state.profile.editProfile,
        viewRunDetails: state.runs.viewRunDetails,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onPasswordAuth: (password) => dispatch(actions.passwordAuth(password)),
        onAuth: (email, password, isSignUp) => dispatch(actions.Auth(email, password, isSignUp)),
        onReturnBack: () => dispatch(actions.returnBackSuccess()),
        onLogout: () => dispatch(actions.logout())
    }
}

export default connect(mapStatesToProps, mapDispatchToProps)(Account);