import React, { useState } from "react";
import TextTransition from "react-text-transition";
import TypingEffect from 'react-typing-effect'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import Button from '../../../../UI/Button/Button'
import classes from '../../../../../containers/HostsChamber/AudioRuns/AudioRuns.module.css'
import * as scripts from '../../../../../shared/AudioRuns/ConditioningProtocols/ConditioningProtocol#1/ConditioningProtocol1Scripts'
import * as Delays from '../../../../../shared/AudioRuns/ConditioningProtocols/ConditioningProtocol#1/ConditioningProtocol#1Delay'
import { connect } from 'react-redux';
import { ConditioningProtocol1End } from '../../../../../store/actions/AudioRuns/audioRunsIndex'
import ConditioningDeny1 from './Conditioning_Protocol_Resist_Deny1'
import Sound from 'react-sound'
import ResistDeny from '../../../../../assets/audio_runs/conditioning_protocols/conditioning_protocol_1/conditioning_protocol_resist_deny.mp3'
import Delay from "react-delay";
import useInterval from 'react-useinterval';

const DELAY_DENY = [4000]


function Conditioning_Protocol_Resist({ userId, token, conditioningProtocol1Complete, ConditioningProtocol1End }) {

    
    let [conditioning_resistDenyTextIndex, countConditioning_resistDenyTextIndex] = useState(0)
    let [conditioning_deny, setConditioning_deny] = useState(true)
    let [conditioning_deny1, setConditioning_deny1] = useState(false)
   
   
    const increaseResistDenyCount = () => {
        if (conditioning_deny && conditioning_resistDenyTextIndex < scripts.CONDITIONING_PROTOCOL_RESIST_DENY.length) {
            countConditioning_resistDenyTextIndex(conditioning_resistDenyTextIndex + 1)
        }
    }

    
   
    useInterval(increaseResistDenyCount, Delays.DELAY_TIME_DENY[conditioning_resistDenyTextIndex]);
  
    const enterDeny1Handler = () => {
        setConditioning_deny(conditioning_deny = false)
        setConditioning_deny1(conditioning_deny1 = true)
    }


    let conditioning_protocol_resist_form = null

    

    if (conditioning_deny) {
        conditioning_protocol_resist_form = (
            <Aux>
                {conditioning_resistDenyTextIndex < scripts.CONDITIONING_PROTOCOL_RESIST_DENY.length ?
                    <Aux >
                        {/* <div className={classes.texting}> */}
                            <Sound url={ResistDeny} playStatus={Sound.status.PLAYING} />
                            <Delay wait={DELAY_DENY[conditioning_resistDenyTextIndex]}>
                                <TextTransition
                                    text={<TypingEffect typingDelay={100} text={scripts.CONDITIONING_PROTOCOL_RESIST_DENY[conditioning_resistDenyTextIndex]} speed={90} eraseDelay={1000000} />}
                                    Overflow
                                />
                            </Delay>
                        {/* </div> */}
                    </Aux>
                    :
                    <div className={classes.ButtonPadding}>
                        <Button btnType="Success" clicked={enterDeny1Handler}>Yes, I will Obey</Button>
                    </div>
                } 
            </Aux>
        )
    }

    if (conditioning_deny1) {
        conditioning_protocol_resist_form = (
            <ConditioningDeny1 />
        )
    }



    return (
        conditioning_protocol_resist_form
    );
}


const mapStateToProps = state => {
    return {
        hostName: state.auth.accountDetails.name,
        runSpeed: state.auth.accountDetails.speed,
        userId: state.auth.userId,
        token: state.auth.token,
        conditioningProtocol1Complete: state.conditioningProtocol1.conditioningProtocol1Complete
    }
}

const mapDispatchToProps = dispatch => {
    return {
        ConditioningProtocol1End: (userId, token, comply_sequence, resistance_sequence) => dispatch(ConditioningProtocol1End(userId, token, comply_sequence, resistance_sequence)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Conditioning_Protocol_Resist)