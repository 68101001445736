import React from 'react';
import TypingEffect from 'react-typing-effect';
import Delay from 'react-delay'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import classes from '../../FreerunComponent.module.css'

const trigger = (props) => {
    // eslint-disable-next-line
    const trigger = (props.naming) + ", it is time to plant a trigger inside your head\n\
                     It is time to set a trigger for Us to control you\n\
                     A trigger for Us to use\n\
                     A trigger for Us to use to control you\n\
                     A trigger that the more We use\n\
                     The more powerful it becomes\n\
                     The more We use this trigger\n\
                     The more obedient you become to Us\n\
                     The more submissive you become to Us\n\
                     The more open and eager you are for Our control\n"
                     + // eslint-disable-next-line
                     (props.naming) +', it is time to be triggered\n\
                     "The Hypno Virus took over"\n\
                     That is your trigger\n\
                     The Hypno Virus took over\n\
                     Everytime We say it you drop even deeper\n\
                     The Hypno Virus took over\n\
                     Everytime We say it you give in even more\n\
                     The Hypno Virus took over\n\
                     Everytime We say it you are more eager to submit to Us\n\
                     The Hypno Virus took over\n\
                     Everytime We say it you want to obey Us even more\n\
                     The Hypno Virus took over\n\
                     Say it now and feel how deep you go for Us'
    let duration = 150 + (trigger.length * 115)
    let triggerParagraph = (
            <Aux>
                <h3 className= {classes.titles}>TRIGGER</h3>
                <TypingEffect typingDelay={5000} text={trigger} speed={110} eraseDelay={1000000} />
                <Delay wait={duration+5000}><h4 className={classes.blinking} onClick={props.trigger}>The Hypno Virus took over</h4></Delay>
            </Aux>
    )
    return triggerParagraph
}


export default trigger