export const OBEDIENCE_STAGE_START = 'OBEDIENCE_STAGE_START'
export const OBEDIENCE_STAGE_1_START = 'OBEDIENCE_STAGE_1_START'
export const OBEDIENCE_STAGE_1_SUCCESS = 'OBEDIENCE_STAGE_1_SUCCESS'
export const OBEDIENCE_STAGE_FAILED = 'OBEDIENCE_STAGE_FAILED'
export const OBEDIENCE_STAGE_1_COUNTER = 'OBEDIENCE_STAGE_1_COUNTER'
export const OBEDIENCE_STAGE_1_COMPLETED = 'OBEDIENCE_STAGE_1_COMPLETED'

export const OBEDIENCE_STAGE_2_START = 'OBEDIENCE_STAGE_2_START'
export const OBEDIENCE_STAGE_2_SUCCESS = 'OBEDIENCE_STAGE_2_SUCCESS'
export const OBEDIENCE_STAGE_2_COUNTER = 'OBEDIENCE_STAGE_2_COUNTER'
export const OBEDIENCE_STAGE_2_COMPLETED = 'OBEDIENCE_STAGE_2_COMPLETED'

export const OBEDIENCE_STAGE_3_START = 'OBEDIENCE_STAGE_3_START'
export const OBEDIENCE_STAGE_3_SUCCESS = 'OBEDIENCE_STAGE_3_SUCCESS'
export const OBEDIENCE_STAGE_3_COUNTER = 'OBEDIENCE_STAGE_3_COUNTER'
export const OBEDIENCE_STAGE_3_COMPLETED = 'OBEDIENCE_STAGE_3_COMPLETED'

export const OBEDIENCE_STAGE_COMPLETED = 'OBEDIENCE_STAGE_COMPLETED'