import React from 'react';
import TypingEffect from 'react-typing-effect';
import Delay from 'react-delay'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import classes from '../../HostsRunComponent.module.css'
import Sound from 'react-sound'
import Ticking1 from '../../../../../assets/audio/Ticking2.mp3'
import Ticking2 from '../../../../../assets/audio/Ticking3.mp3'

const tickingClock = (props) => {
    // let praiseChoice = 0
    // praiseChoice = Math.floor(Math.random() * props.praises.length);
    // let praise = props.praises[praiseChoice]
    let TickingDuration = Math.floor(Math.random() * 2) + 1
    let tickingDelay = 0
    let tickingAudio = null
    let tickingMin = ''
    if (TickingDuration === 1){
        tickingAudio = (<Sound  url={Ticking1}
        playStatus={Sound.status.PLAYING} />)
        tickingMin = '2'
        tickingDelay = 120000

    } else {
        tickingAudio = (<Sound  url={Ticking2}
        playStatus={Sound.status.PLAYING} />)
        tickingMin = '2'
        tickingDelay = 180000
    }
    // eslint-disable-next-line
    const TickingClockMessage = "Tick Tock\n\
                                 Tick Tock\n\
                                 It's so hypnotic\n\
                                 So captivating\n\
                                 Easy to get lost in\n\
                                 easy to lose yourself in this ticking sound\n\
                                 To slip even further\n\
                                 To fall even deeper\n\
                                 It is what you want to do after all\n\
                                 It is what a " + // eslint-disable-next-line
                                 (props.title) + " always wants after all\n\
                                 To hear this ticking sound\n\
                                 Get rid of these pesky thoughts\n\
                                 All these pesky thoughts\n\
                                 You don't need them\n\
                                 You don't need them at all\n\
                                 So just listen\n\
                                 Listen to us for the next " + // eslint-disable-next-line
                                 tickingMin + " minutes\n\
                                 Listen and give in to Us\n\
                                 Give in to Us completely, " // eslint-disable-next-line
                                 + (props.naming)
    let speed = parseInt(props.speed) +5
    let duration = 150 + (TickingClockMessage.length * speed)
    let tickingtime = duration + tickingDelay +5000
    let tickingDeepenerParagraph = (
            <Aux>
                <h3 className= {classes.titles}>TICKING CLOCK DEEPENER</h3>
                <TypingEffect typingDelay={5000} text={TickingClockMessage} speed={props.speed} eraseDelay={1000000} />
                <Delay wait={duration}>{tickingAudio}</Delay>
                <Delay wait={tickingtime}><h4 className={classes.blinking} onClick={props.tickingDeepener}>Tick Tock my mind has popped</h4></Delay>
            </Aux>
        )


    return tickingDeepenerParagraph
}


export default tickingClock