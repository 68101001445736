import React, { Component } from 'react';
import Aux from '../../../../hoc/auxiliary/auxiliary';
import classes from '../HypnosisShowMenu.module.scss'
// import classes2 from '../ExperienceMenu/ExperienceMenu.module.scss'
import HypnosisShow from '../../../../assets/Images/Blank.png'
// import TheEnd from '../StartingMenu'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/MindMeltingExperience/TheGreatOHypnosisShow/index';

class HypnosisAwake extends Component {

    state = {
        awake: true,
        // end: false
    }

    TheEndHandler = () => {
        this.props.onShowEnds()
        // this.setState({ awake: false, end: true })
    }



    render() {
        let HypnosisShowForm = null
        if (this.state.awake) {
            HypnosisShowForm = (
                <html style={{ backgroundImage: `url(${HypnosisShow})` }}>
                    <div className={classes.storyFrom}>
                        <p>
                            You contemplate your decision for a second but you shake your head again and decline the offer opting to wake up<br></br>
                            Feeling his hand on your shoulder again, gently giving it this slow and subtle rub as he leans next to your ears and says <br></br>
                            <i>"It's totally fine, nothing to worry about at all. In a moment I will snap my fingers for you to wake up completely.
                                You will wake up feeling quite good & relaxed, excited and eager to watch the rest of the show knowing you'll have a blast and enjoy everything I do throughout the show.
                                Now it's time to wake up"</i><br></br>
                            <strong>*snap*</strong><br></br>
                            Eyes blinking for a few seconds as you realize what happened and feel yourself returning to normal with the fog and cloud over your mind clearing.
                            Watching him go up stage to continue the show first by deepen the trance of those on stage, making them forget the number five and showing them how his suggestions affect them.<br></br>
                            Once that’s done he puts them back under and apply some other suggestions.<br></br>
                            Snapping his fingers to drop them back in trance,<br></br>
                            tapping their forehead to drop them,<br></br>
                            using his finger guns to shoot them and have them drop<br></br>
                            and finally making them count from five to one and snap their fingers to drop themselves.<br></br>
                            In the end he removes all triggers and suggestions giving them the ability to find it easier to drop for him in the future if they choose to, waking everyone up & handing them flyers in case they want to book him for future shows.
                        </p>
                        <form className={classes.centered} onClick={this.TheEndHandler}>
                            <h4 className={classes.storyButtonSize}>The End</h4>
                        </form>
                    </div>
                </html>
            )
        }
        // if (this.state.end) {
        //     HypnosisShowForm = (
        //         <TheEnd />
        //     )
        // }


        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {HypnosisShowForm}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >




        )
    }
}


const mapStateToProps = state => {
    return {
        showStart: state.hypnosisShow.showStart,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowEnds: () => dispatch(actions.hypnosisShowEnd()),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(HypnosisAwake);