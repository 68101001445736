import React, { useState } from "react";
import TextTransition from "react-text-transition";
import TypingEffect from 'react-typing-effect'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import Button from '../../../../UI/Button/Button'
import classes from '../../../../../containers/HostsChamber/AudioRuns/AudioRuns.module.css'
import * as scripts from '../../../../../shared/AudioRuns/TriggerIntroduction/ChloroformIntroduction/ChloroformIntroductionScripts'
import * as Delays from '../../../../../shared/AudioRuns/TriggerIntroduction/ChloroformIntroduction/ChloroformIntroductionDelay'
import Activate from '../../../../../assets/audio_runs/Trigger_Introduction/Chloroform_Introduction/Forced_Chloroform_Explanation.mp3'
import Sound from 'react-sound'
import Delay from "react-delay";
import useInterval from 'react-useinterval';
import ForcedChloroform from './Forced_Chloroform'

const DELAY_TEXT = [4300]


function Forced_Chloroform_Explanation() {
  let [count, setCount] = useState(0);
  let [forced_chloroform_explanation, setProtocol] = useState(true)
  let [forced_chloroform, setForcedChloroformSequence] = useState(false)


  const increaseCount = () => {
    if (forced_chloroform_explanation && count < scripts.FORCED_CHLOROFORM_EXPLANATION.length) {
      setCount(count + 1);
    }
  };

  useInterval(increaseCount, Delays.FORCED_CHLOROFORM_EXPLANATION_DELAY[count]);

  const enterForcedChloroformSequenceHandler = () => {
    setProtocol(forced_chloroform_explanation = false)
    setForcedChloroformSequence(forced_chloroform = true)

  }



  let focred_chloroforom_explanation_form = null

  if (forced_chloroform_explanation) {
    focred_chloroforom_explanation_form = (
      <Aux>
        {count < scripts.FORCED_CHLOROFORM_EXPLANATION.length ?
          <Aux >
            <Sound url={Activate} playStatus={Sound.status.PLAYING} />
            <Delay wait={DELAY_TEXT[count]}>
              <TextTransition
                text={<TypingEffect typingDelay={100} text={scripts.FORCED_CHLOROFORM_EXPLANATION[count]} speed={50} eraseDelay={1000000} />}
                Overflow
              />
            </Delay>
          </Aux>
          :
          <div className={classes.ButtonPadding}>
            <Button btnType="Success" clicked={enterForcedChloroformSequenceHandler}>I accept this fact<br></br>This host will be chloroformed</Button>
          </div>
        }
      </Aux >
    )
  }

  if (forced_chloroform) {
    focred_chloroforom_explanation_form = <ForcedChloroform />
  }

  return (
    <Aux>
      {focred_chloroforom_explanation_form}
    </Aux>
  )

}

export default Forced_Chloroform_Explanation