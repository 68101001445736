import * as audioRunTypes from '../audioRunTypes'
import axios from '../../../../axios'

export const MotorFunctionControlIntroductionStart = () => {
    return {
        type: audioRunTypes.AUDIO_RUN_START
    }
}

export const MotorFunctionControlSuccess = (Motor_Function_Control_Introduction) => {
        return {
            type: audioRunTypes.MOTOR_FUNCTION_CONTROL_INTRODUCTION_SUCCESS,
            Motor_Function_Control_Introduction: Motor_Function_Control_Introduction,
        } 
}

export const SlowMotorFunctionControl = () => {
    return {
        type: audioRunTypes.SLOW_MOTOR_FUNCTION_CONTROL_INTRODUCTION,
    } 
}


export const InstantMotorFunctionControl = () => {
    return {
        type: audioRunTypes.INSTANT_MOTOR_FUNCTION_CONTROL_INTRODUCTION,
    } 
}

export const MotorFunctionControl = (userId, token) => {
    return dispatch => {
        dispatch(MotorFunctionControlIntroductionStart());
        axios.get('/Runs/'+userId+'/AudioRuns/Trigger_Introduction/Motor_Function_Control_Introduction.json?auth=' + token)
            
            .then( response => {
                // dispatch(ObedienceStageStart());
                if(response.data === null){
                    let Motor_Function_Control_Introduction = {
                        Motor_Function_Control_Introduction: 0,
                        instant_control:0,
                        slow_control:0,
                        mental_control: 0
                    }
                    axios.put('/Runs/'+userId+'/AudioRuns/Trigger_Introduction/Motor_Function_Control_Introduction.json?auth=' + token, Motor_Function_Control_Introduction)
                    .then(response => {
                        dispatch(MotorFunctionControlSuccess(response.data))
                    })
                    .catch(error => {
                        dispatch(MotorFunctionControlFailed(error.message.data))
                    })
                    
                } else{
                    dispatch(MotorFunctionControlSuccess(response.data))
                }
                       
            })
            .catch(error => {
                dispatch(MotorFunctionControlFailed(error.message.data))
            })
    }
}

export const MotorFunctionControlFailed = (error) => {
    return{
        type: audioRunTypes.AUDIO_RUN_FAILED,
        error: error
    }
}

export const MotorFunctionControlEnd = (userId, token, instant_control, slow_control, mental_control) => {
    return dispatch => {
        axios.get('/Runs/'+userId+'/AudioRuns/Trigger_Introduction/Motor_Function_Control_Introduction.json?auth=' + token)
            .then( response => {
                    let updatedMotor_Function_Control_Introduction = {
                        Motor_Function_Control_Introduction: response.data.Motor_Function_Control_Introduction + 1,
                        instant_control: response.data.instant_control + instant_control,
                        slow_control: response.data.slow_control + slow_control,
                        mental_control: response.data.mental_control + mental_control,
                    }
                    axios.put('/Runs/'+userId+'/AudioRuns/Trigger_Introduction/Motor_Function_Control_Introduction.json?auth=' + token, updatedMotor_Function_Control_Introduction)
                    .then(response => {
                        dispatch(MotorFunctionControlCompleted())
                    })
                    .catch(error => {
                        dispatch(MotorFunctionControlFailed(error.message.data))
                    })    
                       
            })
            .catch(error => {
                dispatch(MotorFunctionControlFailed(error.message))
            })
    }
}

export const MotorFunctionControlCompleted = () => {
    return{
            type: audioRunTypes.MOTOR_FUNCTION_CONTROL_INTRODUCTION_COMPLETED,
        }
}