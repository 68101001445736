import React from 'react';
import TypingEffect from 'react-typing-effect';
import Delay from 'react-delay'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
// import Sound from 'react-sound'
// import Bell from '../../assets/audio/bell.mp3'
import classes from '../../FreerunComponent.module.css'

const induction3 = (props) => {
    // eslint-disable-next-line
    const induction3 = "That's right, "+ (props.naming) +"\n\
                        Just give in\n\
                        It feels so good\n\
                        It feels so right\n\
                        So right to let Our words fill your mind\n\
                        So right to let Our words guide you\n\
                        So right to let Our words control you\n\
                        It is time...\n\
                        It is time to drop, " + // eslint-disable-next-line
                        (props.naming) +"\n\
                        As We count you down now\n\
                        As We count you down from 10 to 1\n\
                        Dropping deep\n\
                        So deep for Us now...\n\
                        10\n\
                        Slipping down\n\
                        9\n\
                        Sinking deeper\n\
                        8\n\
                        Under Our control\n\
                        7\n\
                        Letting go\n\
                        6\n\
                        Giving in\n\
                        5\n\
                        Halfway there\n\
                        4\n\
                        We are taking over\n\
                        3\n\
                        We control you, " + // eslint-disable-next-line
                        (props.naming) +'\n\
                        2\n\
                        We are taking over completely\n\
                        1\n\
                        DROP\n\
                        The Hypno Virus took over, '+ // eslint-disable-next-line
                        (props.naming)+'\n\
                        You are deeply hypnotized\n\
                        You are deeply hypnotized by The Hypno Virus'
    let duration = 150 + (induction3.length * 115)
    let inductionParagraph = (
            <Aux>
                <h3 className= {classes.titles}>INDUCTION</h3>
                <TypingEffect typingDelay={5000} text={induction3} speed={110} eraseDelay={1000000} />
                <Delay wait={duration+5000}><h4 className={classes.blinking} onClick={props.induction3}>I am deeply hypnotized by The Hypno Virus</h4></Delay>
            </Aux>
    )
    return inductionParagraph
}


export default induction3