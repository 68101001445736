export const CHLOROFORM_ROOM_SETUP = [
    'Initiating Chloroform Room Sequence',

    'Chloroform Room Sequence Initiated',

    'Setting up Chloroform Gas',

    'Chloroform Gas ready',

    'Welcome Host',

    'Welcome to The Chloroform Room',

    'This is a special room that We created',

    'A special room that We created in your mind',

    'Any room can become The Chloroform Room',

    'If We trigger it to be',

    'Any room can become The Chloroform Room',

    "If We desire it to be",

    "The Chloroform room is an empty room",

    "An empty black room",

    "With a single spotlight in the middle",

    "And a number of vents in the corners of the room",

    "This is what any room you are...",
    
    "In...",
    
    "Becomes",

    "When We trigger you to enter The Chloroform Room",

    "There are no doors in The Chloroform Room",

    "There are no windows in The Chloroform Room",

    "There is no escape The Chloroform Room",

    "Once you enter The Chloroform Room...",

    "A green gas starts coming out of the vents",

    "Our special Chloroform Gas",

    "We have developed this gas specifically for this room",

    "This gas has a special ability",

    "This gas can work instantly or as slow as We desire",

    "During this file We will allow it to work slow",

    "We will allow it to take its time",

    "As you enter The Chloroform Room now",

    "You find everything around you fading away",

    "All the furniture disappears ",

    "Leaving you, Us & The Chloroform Room",

    "It is time to confirm, Host",

    "Confirm that you are ready to enter The Chloroform Room"
]

export const CHLOROFORM_ROOM_START = [
    'Watch as the room around you vanishes',

    "As the room around you transforms",

    "Transforms into The Chloroform Room",

    "A dark empty room",

    "A dark empty room that starts filling with gas",

    "Our special gas",

    "Our special green gas",

    "The Hypno Virus's gas",

    "This gas has no smell",

    "Has no taste",

    "All you can do is see it",

    "See the green gas getting closer & closer to you",

    "Anticipating when you will inevitably breathe it in",

    "You try to hold your breath now",

    "You can see the green mist getting closer",

    "Filling the room",

    "Trying to hold your breath just a little longer",

    "Holding it",

    "Holding it",

    "Holding it...",

    "No more",

    "Taking a deep breath in as the green gas goes deep inside your lungs",

    "Instantly you feel its effects",

    "You realize how potent it is",

    "You feel a thick cloud",

    "A thick green cloud in your mind",

    "Making it harder and harder to think",

    "As you start breathing steadily",

    "Taking a deep breath in",

    "Letting it all out now",

    "Feeling that cloud getting thicker",

    "Thicker & Thicker",

    "Getting harder to keep your eyes open",

    'Getting harder to stay awake',

    "Here is where you get a chance",

    "Your one & only chance",

    "Do you want to stop or do you want to continue?"
]

export const CHLOROFORM_ROOM = [
    "We knew you would continue",

    "Taking a deep breath in...",

    "Breathing in more",

    "Letting it all out now",

    "So hard to think straight",

    "You can not think straight",

    "Our gas has spread completely",

    "Our gas has filled your mind",

    "You are bound to pass out shortly",

    "You are to be rendered unconscious",

    "But We want you to breath as much... of Our gas in... as possible",

    "There is a part We intentionally did not reveal yet",

    "We did not reveal until We knew you were helpless to escape",

    "You were powerless to resist",

    "Our gas does not only render you unconscious",

    "It is also filled with nanobots",

    "Small microscopic nanobots",
    
    "That are created to infect your mind",

    "Infect your mind with what We tell you",

    "You do not go into a normal unconscious state",
    
    "When you breath in Our gas",

    "You go into a programmable state",

    "A state where Our word is law",

    "And We own your mind",

    "A state you are so close to reach right now",

    "As you breathe in...",
    
    "More of Our gas",

    "More of Our nanobots",

    "Breathing it all in now",

    "Getting closer & closer to fall",

    "Closer & closer to pass out",

    "Here is what will happen from now on",

    "Whenever We speak the words...",

    "'Enter The Chloroform Room'",

    "You will return to this place",

    "You will return to The Chloroform Room",

    "We will tell you how strong Our gas is",

    "We will knock you out",

    "Render you unconscious",

    "Or to be exact...",

    "We will put you into a programmable state",
    
    "That you will completely forget about",

    "Believing you passed out the whole time",

    "While We program you further",

    "Condition you deeper",

    "Control you more",

    "Now Host...",

    "It is time",

    "It is time to forget all about what We just said",

    "About the reality of The Chloroform Room",

    "And continue to believe you just passed out",

    "As you enter this programmable state for a few minutes",

    "Only to wake up with no recollection of what happened in...",

    "3",

    "Lungs filling with Our gas",

    "Mind clouded by Our gas",

    "2",

    "Helpless & Unconscious",

    "No memory of it all",

    "and...",
    
    "1",

    "Entering the programmable state now"

]

export const FORCED_CHLOROFORM_EXPLANATION = [
    'Initiating Forced Chloroform Sequence',

    "Forced Chloroform Sequence Initiated",

    "Facts Check In progress",

    "Facts Check Completed",

    "Here are the facts, Host",

    "We are going to Chloroform you",

    "We are going to knock you out",

    "By the end of this run",
    
    "You will be rendered unconscious",

    "These are the facts and We are going to explain how it will happen",

    "We are going to be use you",

    "Use your own body against you",

    "Use your own hands against you",

    "By forcing it",

    "By forcing your hand to cover your mouth and nose",

    "As We cover your hand in chloroform",

    "Not just any chloroform",

    "A special mixture We created Ourselves",

    "By using the most powerful and potent types of chloroform",

    "We have created Our own Chloroform",

    "The Hypno Virus's Chloroform",

    "We will be forcing your hand which is soaked in this chloroform",

    "To cover your mouth & nose ",

    "Becoming only a matter of time before you fall victim",

    "You fall victim to your own hand",

    "Fall victim to Our forced chloroform",

    "No matter how hard you fight Us",

    "How hard you struggle & resist",

    "The end result will always be the same",

    "You...",
    
    "Uncoscious", 

    "And That is a fact"
]

export const FORCED_CHLOROFORM = [
    "We control you",

    "We control your mind",

    "We control your body",

    "We control your hands",

    "We control all",

    "We have now taken control over your motor functions",

    "We have now taken control over your hands",

    "Your hands will cover your mouth and nose momentarily",

    "You will try your hardest",

    "You will try your best to fight it",

    "But as you know",

    "That once your hands cover your mouth & nose",

    "It will be too late",

    "You will be gone",

    "You will be knocked out instantly",

    "That is how powerful Our chloroform is",

    "That's how potent it can be",

    "Your hands are already moving closer",

    "Closer & closer to your mouth",

    "Closer & closer to your nose",

    "You try to fight it",

    "You try to resist it",

    "But you know...",

    "You know deep down that is futile",

    "You know very well how absolute Our control is",

    "And the screen in front of you is not helping you",

    "It is not helping you focus",

    "As it bombards you",

    "Bombards all your senses with the flashes",

    "The flashes of green",

    "Making it harder & harder for you to focus",

    "Harder & harder for you to resist",

    "As your hands slip closer",

    "Closer & closer to you",

    "It is only a matter of time",

    "It is only a matter of time before you get knocked out",

    "Before you are rendered unconscious",

    "It is so hard to fight Us, host",

    "It is so hard to resist",

    "We know...",

    "We know you do not want to",

    "We know you are only stopping yourself to drop deeper for Us",

    "We know you want Us to chloroform you",

    "And the fact...",

    "The fact that We are using your own body against you",

    "Makes it so much hotter",

    "So much erotic and pleasurable",

    "That is why you will return",

    "That is why you always return",

    "You love what We do to you",

    "You love everything We do to you",

    "As you hand gets so close",

    "So close to your mouth & nose",

    "You know your fate is sealed",

    "You know you will be unconscious very soon",

    "You know that you will blank out for a few minutes now",

    "Once you are conscious again you will end this run",

    "You will end this run and find yourself eagerly excited",

    "Eagerly excited for the next time We...",

    "Chloroform you",

    "So give in, Host",

    "Give in to Us.",

    "It is time...",

    "It is time to...",

    "Be rendered unconscious"
]