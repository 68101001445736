import React from 'react';
import Delay from 'react-delay'
import Aux from '../../../../hoc/auxiliary/auxiliary'
import classes from '../../../../containers/FreeRun/MusicConditioning/MusicConditioning.module.css'
import Sound from 'react-sound'
import Metronome from '../../../../assets/audio/Metronome2.mp3'

const metronomeChoice = (props) => {
    let hostName = props.name.toUpperCase()
    let metronomeChoice = null

    let mantras = ["EACH TICK TAKES YOU DEEPER, " + hostName
                ,"DEEPER AND DEEPER FOR US, " + hostName
                ,hostName + ', SO EAGER TO SERVE US'
                ,hostName + ', IT IS SO EASY TO GET LOST IN THE METRONOME'
                ,'THE HYPNO VIRUS IS ALL THERE IS'
                ,'OUR WORDS ARE YOUR COMMANDS, ' + hostName
                ,'THE METRONOME IS TAKING AWAY ALL YOUR FREE WILL, ' + hostName
                ,'WE ARE ALWAYS IN CONTROL, ' + hostName
                ,'THERE IS NO RESISTING THE HYPNO VIRUS'
                ,'YOU ARE OURS, '+hostName]
    let metronomeMantra = []
    for (let key = 0; key < mantras.length; key++) {
        metronomeMantra.push({
            id: key,
            config: mantras[key]
        })
    }
    // eslint-disable-next-line
    if (props.metronomeChoiceProp) {
        metronomeChoice = (
            <Aux>
                <Delay wait={0}>{metronomeMantra.map(metronome => (
                    <Delay key={metronome.id} wait={metronome.id * 1700}>
                        <h3>{metronomeMantra[metronome.id].config}</h3>
                    </Delay>
                ))}</Delay>
            </Aux>
        )
    } else {
        metronomeChoice = null
    }

    return (
        <div className={classes.MetronomeChoice}>
            <Sound url={Metronome} playStatus={Sound.status.PLAYING} loop={true} />
            {metronomeChoice}
        </div>

    )
}


export default metronomeChoice
