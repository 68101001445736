import React, { Component } from 'react';
// import Spinner from '../../components/UI/Spinner/Spinner'
// import classes from './Homepage.module.scss'
import Aux from '../../../hoc/auxiliary/auxiliary';
import classes from './ExperienceMenu.module.scss'
import HypnosisShow from '../../../assets/Images/show.png'
// import tumblrLogo from '../../assets/Images/tumblr.png'
// import twitterLogo from '../../assets/Images/twitter.png'
import Button from '../../../components/UI/Button/Button'
import HypnosisShowMenu from '../TheGreatOHypnosisShow/StartingMenu'

import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'
import Layout from '../../../containers/Layout/Layout';
// import * as actions from '../../store/actions/index';
// import Background from '../../components/Background/Background'
// import WebsiteAudio from '../../components/Background/WebsiteAudio'
// import { connect } from 'react-redux'

class ExperienceMenu extends Component {

    state = {
        experienceOptions: true,
        layout: false,
        greatoshow: false
    }

    greatOShowHandler = () => {
        this.setState({ experienceOptions: false, greatoshow: true })
    }

    returnMainPageHandler = () => {
        this.setState({ experienceOptions: false, layout: true })
        // window.history.pushState('','/');

    }

    render() {

        let experienceMenuForm = null
        if (this.state.experienceOptions) {
            experienceMenuForm = (
                <div className={classes.ExperienceMenu}>
                    <h1 className={classes.Title}>The Mind Melting Experience</h1>
                    <br></br>
                    <div className={classes.Experiences}>
                        <form className={classes.centered} onClick={this.greatOShowHandler}>
                            <img src={HypnosisShow} alt="The Great O's Hypnosis Show" width="200" height="300" />
                            <h4 >The Great O's Hypnosis Show</h4>
                        </form>
                    </div>
                    <div className={classes.ReturnMenu} >
                        <Button btnType="Danger" clicked={this.returnMainPageHandler}>Return to Main Page</Button>
                    </div>
                </div>
            )
        }

        if (this.state.greatoshow) {
            experienceMenuForm = (
                <HypnosisShowMenu />
            )
        }

        if (this.state.layout) {
            experienceMenuForm = (
                <Layout />
            )
        }

        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            <div className={classes.ExperienceMenu}>
                                {experienceMenuForm}
                            </div>
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >
        )
    }
}

// const mapStatesToProps = state => {
//     return {
//         dailyCommand: state.homePage.dailyCommand,
//         error: state.homePage.error
//     }
// }

// const mapDispatchToProps = dispatch => {
//     return {
//         fetchDailyCommands: () => dispatch(actions.dailyCommand()),
//         onResetLogout: () => dispatch(actions.resetLogout()),
//         onHypnoVisualsActivate: () => dispatch(actions.hypnoVisualsActivate())
//     }
// }

export default ExperienceMenu