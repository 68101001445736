import * as audioRunTypes from '../../../actions/AudioRuns/audioRunTypes';
import { updateObject } from '../../../../shared/utility';

const initialState = {
    error: null,
    Motor_Function_Reinforcement_Complete: false,
    // instant_control : false,
    // slow_control : false,
    Motor_Function_Reinforcement_Commands: [],
    loading: false,
}

const motorFunctionIntroductionStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true
    })
}

const motorFunctionIntroductionSuccess = (state, action) => {
        return updateObject(state, {
            Motor_Function_Reinforcement_Complete: false,
            Motor_Function_Reinforcement_Commands: action.Motor_Function_Reinforcement_Commands,
            loading: false,
        })
}

const chloroformIntroductionFailed = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    })
}

// const InstantControlSuccess = (state, action) => {
//     return updateObject(state, {
//         instant_control: true,
//         loading: false
//     })
// }

// const SlowControlSuccess = (state, action) => {
//     return updateObject(state, {
//         slow_control: true,
//         loading: false
//     })
// }

const motorFunctionIntroductionComplete = (state, action) => {
    return updateObject(state, {
        error: null,
        Motor_Function_Reinforcement_Complete: true,
        // instant_control: false,
        // slow_control: false,
        Motor_Function_Reinforcement_Commands: [],
        loading: false,
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case audioRunTypes.AUDIO_RUN_START: return motorFunctionIntroductionStart(state, action)
        case audioRunTypes.AUDIO_RUN_FAILED: return chloroformIntroductionFailed(state, action)
        case audioRunTypes.MOTOR_FUNCTION_CONTROL_REINFORCEMENT_SUCCESS: return motorFunctionIntroductionSuccess(state,action)
        // case audioRunTypes.INSTANT_MOTOR_FUNCTION_CONTROL_INTRODUCTION: return InstantControlSuccess(state,action)
        // case audioRunTypes.SLOW_MOTOR_FUNCTION_CONTROL_INTRODUCTION: return SlowControlSuccess(state,action)
        case audioRunTypes.MOTOR_FUNCTION_CONTROL_REINFORCEMENT_COMPLETED: return motorFunctionIntroductionComplete(state, action)
        default:
            return state

    }
}


export default reducer