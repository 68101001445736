export const TENTACLES_OPENING_DELAY_TIME = [
    9000, //"Welcome back, Host",
    5100, //"We have been expecting you",
    5800, //"We knew you would return to Us",
    6250, //"Return to let Us condition you",
    8400, //"Let Our T.E.N.T.A.C.L.E. unit control you",
    7300, //"Let It go deeper inside your head",
    4200, //"Let Its control grow",
    6900, //"Let Its control become irresistible",
    3200, //"Listen to Us",
    7300, //"Listen to Us and let Us reinforce your conditioning",
    5300, //"Let Us reinforce the power...",
    6700, //"The Power of the T.E.N.T.A.C.L.E. unit",
    4250, //"Here are your options, Host",
    5150, //"Either give in to Our Daily Conditiong",
    8900, //"Or let Us covertly condition you throughout the day",
    5500, //"The Choice is yours",
    9000, //"(For now)"
]

export const DAILY_PLEASURE = [
    9000, //"Activate T.E.N.T.A.C.L.E. unit",
    5000, //"Feeling a wonderful wave of pleasure"
    4000, //"Passing through your body",
    6400, //"As you feel the T.E.N.T.A.C.L.E. unit on top of your head",
    3400, //"Latching on to you",
    3000, //"Draining you",
    5000, //"Draining you of all your thoughts",
    4000, //"All your free will",
    3900, //"Leaving you as...",
    2500, //"An empty",
    1800, //"Mindless",
    2300, //"Host",
    5000, //"As the T.E.N.T.A.C.L.E. branches",
    5700, //"Branches out with new T.E.N.T.A.C.L.E. units",
    5750, //"Branches and wraps itself around your body",
    4450, //"Making you take off your cloth",
    2600, //"All your cloth",
    4850, //"Taking them off now",
]

export const DAILY_PLEASURE2 = [
    6250, //"Leaving you naked",
    3500, //"Leaving you exposed",
    3600, //"Leaving you helpless",
    2700, //"Powerless"
    4600, //"Unable to stop Us",
    6200, //"Stop Us from doing what We desire with you",
    4600, //"We start with spreading your legs",
    4400, //"Spreading your legs apart",
    2900, //"Pulling your arms",
    4000, //"Pulling your arms behind you",
    2400, //"Tying you up",
    4700, //"Making sure you have no where to go",
    3150, //"No where to escape",
    3800, //"All you can do is...",
    2000, //"Watch",
    2000, //"Listen"
    2050, //"Obey"
    4800, //"As the T.E.N.T.A.C.L.E. keeps draining you",
    4600, //"Draining you of any thought",
    3200, //"Whenever you try to think",
    4200, //"You feel that thought being drained",
    6600, //"As one of the T.E.N.T.A.C.L.E units slides down",
    4700, //"Down between your legs",
    5000, //"Slowly rubbing itself against you",
    2600, //"Teasing you",
    4550, //"Turning you on so much",
    3650, //"Yet you are helpless",
    5200, //"Helpless to do anything about it",
    5300, //"Powerless to fight or resist Us",
    6900, //"Becoming more and more turned on",
    3150, //"All you can do",
    3400, //"The best you can do"
    6600, //"Is rub yourself against the T.E.N.T.A.C.L.E. unit",
    3000, //"Becoming a needy",
    2000, //"Desperate",
    2900, //"Host",
    7000, //"As these words keep echoing deep inside your head",
    2000, //"Watch",
    2000, //"Listen",
    1900, //"Obey",
    5000, //"Echoing deep inside your mind",
    5700, //"Making sure you can't think on your own",
    7800, //"Making sure you are just a compliant host",
    5800, //"Always remind you of Us",
    5500, //"Always reminding you of your place",
    5550, //"Always reminding you of who you are",
    3800, //"What you are",
    2900, //"Our Host",
    4900, //"As you feel so turned on right now",
    3200, //"So aroused",
    4500, //"So needy & desperate",
    3000, //"Desperate for release",
    3200, //"You are so wet",
    3000, //"We can feel it",
    4200, //"We know you are very close",
    4200, //"As We keep teasing you",
    5700, //"You know that once We allow you to cum",
    5100, //"Once We allow you to orgasm",
    4800, //"Once you are done",
    2850, //"You will wake up",
    4200, //"You will wake up back to normal",
    4000, //"But you will also...",
    7800, //"You will also be falling deeper under Our control",
    5700, //"You will also be craving more",
    5300, //"You will want to return again",
    8400, //"You will want to let Us condition you even more",
    4200, //"You will want to...",
    1400, //"Watch",
    1400, //"Listen",
    1950, //"Obey",
    4600, //"As you edge closer & closer now",
    3800, //"Closer & Closer",
    2000, //"Eager",
    3300, //"Eager & Excited",
    3000, //"So turned on",
    4300, //"Turned on as you realize...",
    8400, //"You realize that you will return again to Us",
    3200, //"CUM NOW",
    16800, //"Deactivate T.E.N.T.A.C.L.E. unit"
]

export const DAILY_SPANK = [
    10000, //"Activate T.E.N.T.A.C.L.E. unit",
    7300, //"Instantly you feel the unit latches on to your head",
    6600, //"Latches on to you as it starts to drain",
    6000, //"Starts to drain you of all your thoughts",
    3800, //"All your will",
    4500, //"Leaving you with one need",
    2500, //"One thought",
    2500, //"One desire",
    5900, //"To obey everything We say",
    5100, //"As you start taking off your pants",
    5500, //"Taking off your pants and exposing yourself",
    4500, //"Exposing yourself to Us",
    3900, //"Exposing yourself to Us",
    3200, //"getting ready",
    3800, //"Ready to be spanked",
    4000, //"Spanked into submission",
    4500, //"Spanked and conditioned",
    3300, //"Conditioned to Us",
    6000, //"Conditioned to associate Our control
    2700, //"with spanking",
    4100, //"When the T.E.N.T.A.C.L.E. unit spanks you",
    4800, //"You feel yourself dropping deeper",
    4000, //"So much deeper",
    4900, //"Deeper into Our control",
    7800, //"With each spank you feel Our control getting stronger",
    5700, //"Everytime We say 'Spank'",
    7200, //"You feel the T.E.N.T.A.C.L.E. spanking your exposed bottom"
]

export const DAILY_SPANK2 = [
    5800, //"Spank",
    2500, //"Feeling it",
    4400, //"Feeling its effect immediately",
    2300, //"Spank",
    3800, //"Feeling yourself getting lost",
    3900, //"Getting lost in the joy",
    2300, //"Spank",
    5000, //"The joy of being spanked",
    5900, //"Hearing Our whispers in the background",
    3000, //"Spanking you",
    4800, //"Spanking you over & over",
    3400, //"As you feel...",
    6900, //"You feel the joy & pleasure of having Us spank you",
    1900, //"Spank",
    2900, //"It feels so good",
    4200, //"So good to feel this powerless",
    3000, //"This helpless..."
    2600, //to Us",
    4400, //"As you spread your legs for Us",
    4600, //"Exposing yourself even more",
    4500, //"Exposing yourself to Us",
    4500, //"Embracing your helplessness",
    4800, //"Knowing that there is nothing you want more",
    4300, //"There is nothing you need more",
    2800, //"Than Our...",
    2300, //"Spank",
    3300, //"With each Spank",
    4850, //"Your mind becomes more conditioned",
    3200, //"With each Spank",
    4900, //"Your mind becomes more compliant",
    2800, //"With each Spank",
    2800, //"You learn...",
    5600, //"You learn that there is nothing you want more",
    3800, //"Than being Our Host",
    6400, //"You learn that you just want to return again",
    4800, //"Again & again",
    4400, //"You want to return to Us",
    4500, //"Get Spanked by Us",
    3000, //"Spank",
    3000, //"Spank",
    3200, //"Spank",
    4000, //"You can't get enough",
    2500, //"You need more",
    2800, //"So much more",
    4200, //"So much more spanking",
    2700, //"Doing anything",
    2900, //"Doing everything",
    5600, //"Everything you can just to have Us...",
    3100, //"Spank you",
    5900, //"Your exposed bottom is so sensitive right now",
    4400, //"That's such a turn on to you",
    6300, //"Knowing what Our T.E.N.T.A.C.L.E. units do to you",
    7800, //"Knowing how they can do this to your body anything We want",
    4200, //"How they can control your body",
    4500, //"Just like they control your mind",
    4200, //"This just makes you feel so small",
    2900, //"So submissive",
    4200, //"So honored & full of pride",
    3600, //"Full of pride to be chosen",
    4600, //"Be chosen to become Our Host",
    4100, //"To serve and please Us",
    4000, //"To have Us guide you",
    8000, //"Have Us guide you to become the best version of yourself",
    8200, //"You want to spend any time you get conditioning yourself",
    4500, //"Conditioning yourself to Us",
    6900, //"With each Spank you feel these words becoming true",
    4000, //"Becoming your reality",
    5400, //"They are your own thoughts and desires",
    4300, //"That is why with one last Spank",
    3100, //"One final Spank",
    2400, //"You will wake up",
    5200, //"You will wake up unbelievably submissive",
    6700, //"Quite turned on from how helpless you are to Us",
    2600, //"Craving...",
    5300, //"Craving & in desperate need",
    4600, //"To submit to Us even more",
    3400, //"It is time, Host",
    3700, //"It is time for a...",
    3300, //"Spank",
    9500, //"Deactivate T.E.N.T.A.C.L.E. unit"

]

export const DAILY_ELECTRIC_SPANK = [

    9500, //"Activate T.E.N.T.A.C.L.E. unit",
    3100, //"Just like that",
    6400, //"The T.E.N.T.A.C.L.E. immediately latches on to your head",
    4400, //"Latches and starts draining you",
    5900, //"Draining you of all your thoughts",
    4500, //"All your free will",
    3600, //"Leaving you with the need",
    2500, //"The desire",
    3500, //"The desire to submit",
    6400, //"To submit & obey every word We say",
    9000, //"This will only get strong with what We are about to do to you next",
    5300, //"We have a special treat in store for you now",
    3000, //"We are going to...",
    2300, //"Spank you",
    4500, //"But this is no ordinary spanking",
    6300, //"Our T.E.N.T.A.C.L.E. units have special abilities",
    8300, //"One of these abilities is that their tips can be electric",
    8300, //"Giving a small gentle shock to whatever they touch",
    6700, //"A shock that leaves you completely mindless",
    3900, //"Completely empty",
    6500, //"Simply becoming a drooling mess of a host",
    8500, //"A host that knows nothing but the pleasure and joy of obedience",
    4000, //"So take off your pants, Host"
    2900, //"Expose yourself",

]

export const DAILY_ELECTRIC_SPANK2 = [
    9000, //"Expose yourself and let Us...",
    3300, //"Spank you",
    3800, //"Instantly feeling that shock",
    3500, //"The sting of that shock",
    4400, //"It's just the perfect balance between",
    2700, //"Feeling a sting",
    4600, //"But not feeling any pain",
    2400, //"Spank",
    4000, //"You hear it in the background now",
    4900, //"You hear it over & over",
    5500, //"Feeling the electric shock everytime",
    3600, //"Your eyes start to flutter",
    4950, //"You lose all sense of your surroundings",
    6000, //"Nothing exists right now except Us",
    3800, //"Except The Hypno Virus",
    3200, //"As you feel that sting",
    3300, //"That electric shock",
    3500, //"You're starting to enjoy it",
    3500, //"Moaning with each shock",
    3500, //"Getting turn on by it",
    2500, //"As We...",
    2000, //"Spank",
    4400, //"As We spank your exposed bottom",
    4100, //"You feel that pleasure intensify",
    4100, //"As We spank your exposed bottom",
    3900, //"Your mind becomes more hazy",
    3300, //"Your thoughts disappear",
    3500, //"The only thing on your mind",
    4800, //"The only thing you want right now is...",
    3700, //"The Hypno Virus",
    2300, //"Spank",
    2500, //"That shock",
    2400, //"That sting",
    3100, //"You can't get enough of it",
    2600, //"You want more",
    2200, //"You need more",
    5200, //"You're amazed by how much you're enjoying it",
    4700, //"You're amazed by how much you want it",
    3800, //"But you feel so turned on",
    4900, //"You feel so much pleasure from it",
    3500, //"You don't care why",
    3400, //"You don't need to care why",
    4200, //"You just want to get spanked",
    3800, //"You want to get spanked more",
    3500, //"As you moan louder",
    4400, //"Louder & louder with each spank",
    3200, //"Knowing that soon",
    2300, //"Very soon"
    3800, //"We will allow you to orgasm",
    3800, //"We will allow you to...",
    3000, //" cum",
    4000, //"With one final spank",
    5200, //"You will feel pleasure like nothing before",
    3700, //"Pleasure from submitting",
    6600, //"Pleasure from letting the T.E.N.T.A.C.L.E. unit take control",
    4700, //"Finding Its power growing stronger",
    3400, //"Stronger & Stronger",
    6100, //"Stronger & stronger each time We activate it",
    3500, //"Rendering you as Our",
    2000, //"Helpless",
    2300, //"Mindless",
    3700, //"Obedient Host",
    3800, //"All you want to do",
    4700, //"All you can think of is to...",
    1900, //"Obey",
    2800, //"Now is the time",
    3200, //"The time to get spanked",
    5200, //"Get spanked and surrender completely to Us",
    2200, //"To the shock",
    2500, //"To the sting",
    3500, //"The sting of Our...",
    3200, //"Spank",
    9400, //"Deactivate T.E.N.T.A.C.L.E. unit"
]

export const COVERT_FULLDAY = [

    10400, //"Activate T.E.N.T.A.C.L.E. unit",
    5200, //"Today you won't have to think for yourself",
    4000, //"As everyday should be",
    5200, //"You feel the T.E.N.T.A.C.L.E. latching on to your head",
    2800, //"Draining you",
    4700, //"Draining you empty of all thoughts",
    4100, //"All free will",
    6100, //"And all that can distract you from Us",
    4900, //"This will be your whole day",
    2000, //"Today..."
    4000, //"You'll have the unit drain you",
    4000, //"Feeling it on top of your head",
    5800, //"Latches nice & tight to it",
    3500, //"All through the day",
    5000, //"Drain you of all distractions",
    6300, //"Of all unwanted thoughts throughout the day",
    6900, //"Having Our mantra echoing deep inside your mind",
    2700, //"No Thoughts",
    2500, //"No Will",
    6500, //"You obey what The Hypno Virus say",
    6700, //"This mantra will keep echoing deep inside your head",
    4900, //"Over & Over & Over",
    3200, //"Conditioning you",
    4000, //"Brainwashing you",
    7800, //"Turning you into an even more obedient Host for Us",
    4200, //"But you will act completely normal",
    7300, //"Completely natural and casual to anyone around you",
    7600, //"All day long you will act and behave like you always do",
    6700, //"But deep inside your mind Our words echo",
    4800, //"Our words infect you even more",
    4500, //"Conditioning & Brainwashing you",
    3800, //"While you keep on this act",
    4100, //"The act of being normal",
    5400, //"Acting normal in front of everyone",
    4900, //"But once you are somewhere private",
    3100, //"Once you are alone",
    5500, //"You will start to recite your mantra",
    2500, //"No Thoughts",
    2300, //"No Will",
    5900, //"You obey What The Hypno Virus say",
    5000, //"This is how you act all through the day",
    4900, //"Until you are about to head to bed",
    6500, //"That is when the T.E.N.T.A.C.L.E. unit deactivates",
    4300, //"That is when you return to normal",
    4500, //"When the T.E.N.T.A.C.L.E. unit releases you",
    5500, //"That is when you realize what happened",
    3300, //"How deep you were",
    4300, //"How conditioned you became",
    9200, //"And how you can't wait to brainwash yourself for a full day again",
    4600, //"Fully embracing Our control",
    4200, //"Fully seeking Our control",
    5200, //"Wanting it at any chance you can get",
    3100, //"You don't need to worry",
    3300, //"You don't need to stress",
    3300, //"You don't need to think",
    3100, //"When We are in control"
    3000, //"none of that exists",
    2800, //"All there is",
    4100, //"All you can think of is...",
    4000, //"The bliss of obedience",
    4000, //"The pleasure of submitting",
    3900, //"The joy of surrendering",
    6000, //"That is how you feel throughout the day, host",
    2400, //"Now go...",
    13900, //"Go and have a blissfully mindless day"
   
]

export const COVERT_COMMANDS = [

    12000, //"You obey what The Hypno Virus say",
    5000, //"This is part of your mantra",
    5100, //"Part of what you seek as a host",
    5000, //"Part of what you want as a host",
    5400, //"To obey everything We say",
    5000, //"Here is where your dream becomes true",
    5600, //"Here is where you can truly condition yourself",
    4600, //"Condition yourself to Us",
    4200, //"Here is where you can...",
    2500, //"Obey",
    4300, //"With the help of the T.E.N.T.A.C.L.E. unit",
    3300, //"As it latches on to your head",
    3300, //"Making sure you comply",
    3500, //"Making sure you submit"
    4400, //"Making sure you obey",
    4300, //"As We choose a command for you",
    5300, //"A command you feel compelled to obey",
    5100, //"A command you need to obey",
    5700, //"A command you would love to obey",
    6500, //"As Our well conditioned host",
    4500, //"You just love to obey",
    5800, //"You obey what We say",
    10500, //"It is time for Us to choose your command, Host",
    4800, //"Loading Commands List...",
    5600, //"Loading Commands List Complete",
    4000, //"Choosing Command",
    7200, // "Computing the most suitable command",
    4000, // "Command Chosen",
    7000, //"Your command is..."
]

export const COVERT_EDGE_COMMAND = [

    8500, //"Activate T.E.N.T.A.C.L.E. unit",
    5700, //"Let Our unit take control",
    6100, //"Let the T.E.N.T.A.C.L.E. unit latch on to you",
    4500, //"Let it drain you completely",
    5300, //"Drain you of all your thoughts",
    4600, //"Drain you of your will",
    4200, //"Drain you of everything",
    4800, //"Leaving you with one thought",
    3000, //"One purpose",
    3500, //"To obey",
    3000, //"As We command you",
    5800, //"As The Hypno Virus commands you to...",
    3000, //"Edge",
    7000, //"We are currently computing how many times you edge",
    7200, //"What mantra will you be reciting as you edge",
    8300, //"Will you be fully clothed, wearing something specific or naked",
    8200, //"Finally How long you have to complete this command",
    6500, //"The T.E.N.T.A.C.L.E. unit will remain latched on to you",
    3200, //"Constantly draining you",
    3700, //"Draining you of everything",
    5000, //"Everything except your need to comply",
    5100, //"Your need to obey this command",
    4000, //"Allowing you to act casual",
    4000, //"Allowing you to act normal",
    4600, //"As it covertly conditons you",
    4400, //"As it covertly trains you",
    5200, //"Until you have complied",
    4600, //"Once you have fulfilled all conditions",
    4500, //"The unit will deactivate",
    4500, //"As you return back to normal",
    5800, //"Feeling the wonderful bliss of obedience",
    5200, //"Feeling the joy & pleasure of complying",
    7400, //"Eagerly looking forward to return to Us again and...",
    3000, //"Obey",
    3700, //"Computing Conditions",
    5000, //"Examining the most suitable conditions",
    4700, //"Evaluating Conditions",
    4700, //"Edging Count Accepted",
    4700, //"Edging Mantra Chosen",
    5400, //"Edging Outfit Determined",
    6000, //"Edging Deadline Established",
    9000, //"Here are the conditions..."  
]

export const COVERT_BODY_WRITING_COMMAND = [

    8200, //"Activate T.E.N.T.A.C.L.E. unit",
    5100, //"Let Our unit take control",
    6000, //"Let the T.E.N.T.A.C.L.E. unit latch on to you",
    4500, //"Let it drain you completely",
    5000, //"Drain you of all your thoughts",
    3600, //"Drain you of your will",
    3700, //"Drain you of everything",
    4300, //"Leaving you with one thought",
    3000, //"One purpose",
    3100, //"To obey",
    3200, //"As We command you",
    6200, //"As The Hypno Virus commands you to...",
    3600, //"Write on your body",
    3600, //"We choose what you write",
    4600, //"We determine where you write it",
    6800, //"We decide when the T.E.N.T.A.C.L.E. unit will deactivate",
    6100, //"During that time it will continue to condition you",
    3600, //"Continue to train you",
    3800, //"Continue to brainwash you",
    4900, //"Making sure that whatever We make you write",
    3000, //"Becomes the truth",
    4600, //"You accept it as a fact",
    4200, //"Covertly conditioning you",
    4000, //"Covertly training you",
    4300, //"Covertly brainwashing you",
    7800, //"As you hear Our voice repeating what We let you write",
    4900, //"Over & Over & Over",
    4000, //"Once the duration is over",
    6000, //"We will deactivate the unit",
    6500, //"Now it is time to choose the conditions, Host",
    6700, //"It is time for Us to choose the conditions",
    3900, //"Computing Conditions",
    5800, //"Scanning the most sufficient conditions",
    4900, //"Evaluating Conditions",
    3600, //"Phrase Determined",
    4500, //"Duration Evaluated",
    9000, //"Here are the conditions..."  

]