import React from 'react'
import classes from '../../../containers/Account/Account.module.css'
// import Input from '../../UI/Input/Input'
import Button from '../../UI/Button/Button'
import Aux from '../../../hoc/auxiliary/auxiliary'


const viewProfile = (props) => {
    let viewProfileForm = (
        <Aux>
            <div className={classes.AccountContainer}>
                    <form className={classes.ViewProfile}>
                        <h2>Profile Details</h2>
                        <p><strong>Email:</strong> <br></br>{props.accountDetails.email}</p>
                        <p><strong>Name:</strong> <br></br>{props.accountDetails.name}</p>
                        <p><strong>Password:</strong> <br></br> {props.togglePassword ? props.accountDetails.password : "*******"}<br></br> <Button btnType="Success" clicked ={props.togglePasswordHandler}>Show/Hide</Button></p> 
                        <p><strong>Gender:</strong> <br></br>{props.accountDetails.gender}</p>
                        <p><strong>Speed:</strong> <br></br>{props.accountDetails.speedName}</p>
                        <p><strong>Host Runs:</strong> <br></br>{props.accountDetails.Host_Runs}</p>

                        <div className={classes.AuthButtons}>
                            <Button btnType="Success" clicked={props.onEditProfileView}>Edit Profile</Button>
                            <Button btnType="Success" clicked={props.onViewRunDetails}>View Run Details</Button>
                            <Button btnType="Success" clicked={() => props.onOptionsView()}>Options</Button>
                            <Button btnType="Danger" clicked={props.changeDataHandler}>Change Email/Password</Button>
                            <Button btnType="Danger" clicked={(token, userId) => props.onDeleteAccount(props.token, props.userId)}>Delete Account</Button>
                            <Button btnType="Danger" clicked={props.logoutHandler}>Logout</Button>
                        </div>
                        <p className={classes.Note}>Note: Logout & Login again before changing Password/Email or Deleting Account to avoid any errors</p>
                    </form>
                </div>
        </Aux>)


    return viewProfileForm
}

export default viewProfile


