import React, { useState } from "react";
import TextTransition from "react-text-transition";
import TypingEffect from 'react-typing-effect'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import Button from '../../../../UI/Button/Button'
import classes from '../../../../../containers/HostsChamber/AudioRuns/AudioRuns.module.css'
import * as scripts from '../../../../../shared/AudioRuns/TriggerReinforcement/TentaclesReinforcement/TentaclesReinforcementScripts'
import * as Delays from '../../../../../shared/AudioRuns/TriggerReinforcement/TentaclesReinforcement/TentaclesReinforcementDelay'
import Pleasure from '../../../../../assets/audio_runs/Trigger_Reinforcement/Tentacle_Reinforcement/Daily_Spank2.mp3'
import Sound from 'react-sound'
import Delay from "react-delay";
import useInterval from 'react-useinterval';
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom'
import { TentacleReinforcementEnd } from '../../../../../store/actions/AudioRuns/audioRunsIndex'

const DELAY_TEXT = [4000]


function Tentacle_Daily_Spank2({ userId, token, Tentacle_Reinforcement_Complete, TentacleReinforcementEnd, profileOptions }) {
  let [count, setCount] = useState(0);
  let [tentacle_daily_spank, setProtocol] = useState(true)


  const increaseCount = () => {
    if (tentacle_daily_spank && count < scripts.DAILY_TENTACLES_SPANK_SCRIPT2.length) {
      setCount(count + 1);
    }
  };

  useInterval(increaseCount, Delays.DAILY_SPANK2[count]);

  const enterPleasure2SequenceHandler = () => {
    setProtocol(tentacle_daily_spank = false)
    TentacleReinforcementEnd(userId, token, 0, 1, 0, 0, 0, 0)
  }

  let centerText = window.innerHeight / 2 - 55
  let texting = null
  if (profileOptions.audio_run_background === '0'){
    texting = {

      // display: 'flex',
      // flexFlow: 'column',
      whiteSpace: 'pre-line',
      alignItems: 'center',
      fontWeight: 'bold',
      boxSizing: "border-box",
      margin: 'auto',
      marginBottom: '10px',
      padding: '30px 10px',
      width: '50%',
      paddingTop: centerText + 'px',
      color: 'white'
  
    }
  }

  let tentacles_daily_pleasure2_form = null

  if (tentacle_daily_spank) {
    tentacles_daily_pleasure2_form = (
      <Aux>
        {count < scripts.DAILY_TENTACLES_SPANK_SCRIPT2.length ?
          <div style={texting}>
            <Delay wait={2000}><Sound url={Pleasure} playStatus={Sound.status.PLAYING} /></Delay>
            <Delay wait={DELAY_TEXT[count]}>
              <TextTransition
                text={<TypingEffect typingDelay={100} text={scripts.DAILY_TENTACLES_SPANK_SCRIPT2[count]} speed={90} eraseDelay={1000000} />}
                Overflow
              />
            </Delay>
          </div>
          :
          <div className={classes.ButtonPadding} style={texting}>
            <Button btnType="Success" clicked={enterPleasure2SequenceHandler}>This Host eagerly await the next time it's spanked!</Button>
          </div>
        }
      </Aux >
    )
  }

  if (Tentacle_Reinforcement_Complete) {
    tentacles_daily_pleasure2_form = <Redirect to="/" />
  }

  return (
    <Aux>
      {tentacles_daily_pleasure2_form}
    </Aux>
  )

}

const mapStateToProps = state => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    Tentacle_Reinforcement_Complete: state.tentacleReinforce.Tentacle_Reinforcement_Complete,
    profileOptions: state.options.profileOptions,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    TentacleReinforcementEnd: (userId, token, daily_pleasure, daily_spank, daily_electric_spank, covert_fullday, covert_edging, covert_body_writing) => dispatch(TentacleReinforcementEnd(userId, token, daily_pleasure, daily_spank, daily_electric_spank, covert_fullday, covert_edging, covert_body_writing)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tentacle_Daily_Spank2)