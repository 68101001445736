export const INSTANT_CONTROL_SCRIPT = [
 //explain what will happen when we say "Disable Motor Functions"
 'Initiating Instant Motor Function Control Sequence',

 'Establishing link with host',

 'Synchronizing link with host',

 "Overwriting host's control",

 "Taking over host's motor functions",

 "Entering new configuration to host",

 "Configuring host's motor function",

 "New configuration established",

 "We now control your motor functions",

 "We now control your body",

 "We now have the control to disable",

 "Or enable your motor functions",

 "This means that with one command",

 "With one trigger We can stop all your motor functions",

 "All your movement and motion",

 "With one command We can stop you instantly",

 "With this link We have established with you",

 "You became an extension to Us",

 "You became part of Us",

 "You are now linked with Us",

 "Both mentally and physically",
 
 "We now have full reign over you",

 "Let Us explain what will happen to you now",

 "Once We say...",
 
 "'Disable Motor Functions'",

 "You will instantly stop any motion",

 "You will stop instantly regardless of what you were doing",

 "We have switched your whole body off",

 "You can still think",
 
 "You will still have thoughts",

 "But no matter how hard you try",

 "You can not move",

 "Not until We say...",

 "'Enable Motor Functions'",

 "Once We say it you will return to normal",

 "Regaining control over your motor functions",

 "But knowing...",

 "Knowing that the truth is...",

 "We control you",

 "We control you mentally & physically",

 "Disable Motor Functions",

 "",//30 seconds pause

 "Enable Motor Functions"
]

export const SLOW_CONTROL_SCRIPT = [
 //Slowly with percentage demonstrate how the host is losing control over their motor functions and that by adding "Slow" to "Disable Motor Functions" you slowly feel your body bound & stuck in place
 'Initiating Slow Motor Function Control Sequence',

 'Slow Motor Function Control Sequence Initiated',

 'Establishing link with host',

 '10%',

 'You might be wondering what is happening',

 "Let Us explain to you",

 "We are taking control over your motor functions",

 "We are taking control over your body",

 "We are establing a connection with you",

 "To not only control you mentally",

 "But physically as well",

 'Synchronizing link with host',

 "20%",

 "We will control your motor functions",

 "We will control your movement",

 "Just like We control your thoughts",

 "Just like We control your mind",

 "You will become an extension of Us",

 "An extension of Us to show the world what it is like",

 "What it is like to serve Us",

 "To be controlled by Us",

 "Overwriting host's control",

 "30%",

 "You can feel Our control taking over",

 "You can feel your control slowly dwindling",

 "As We overwrite your control",

 "We overwrite the functions you have",

 "Taking over your motor functions",

 "40%",

 "We are about to reconfigure your body",

 "We are about to reconfigure your motor functions",

 "We are about to enter a new configuration that will completely give Us control",

 "Entering new configuration to host",

 "50%",

 "This new configurations allows Us full access",

 "Full control over your motor functions",

 "Over your whole body",

 "We can control your movement",

 "Control your motion",

 "Control your speed",

 "We can control you completely",

 "Configuring host's motor functions",

 "60%",

 "This configuration will be soon established",

 "Will soon be completed",

 "Once it is We will be able to disable all your motor functions",

 "Not only can We disable your motor functions",

 "We can control the speed of which We can do that",

 "New configuration established",

 "70%",

 "With this configuration established",

 "We can now disable your motor functions",

 "Whenever We say...",

 "'Disable Motor Functions'",

 "Your motor functions will instantly stop",

 "You will stop in your tracks",

 "Instantly stop in your tracks",
 
 "Unless We say...",

 "'Disable Motor Functions Slowly'",

 "80%",
 
 "You will slowly",

 "Methodically",
 
 "Feel your whole body shutting down",

 "Disable Motor Functions Slowly",

 "Starting with your legs going all the way up to your head",

 "One by one they no longer function",

 "They no longer move",

 "One by one they're disabled",

 "Disabled and rendered completely motionless",

 "You still retain your thoughts",

 "Your mind is still intact",

 "However, your body is unable to move",

 "Slowly, each & every part of your body is disabled",

 "90%",

 "Your body will become disabled",

 "Completely disabled",

 "Until We allow you to move again",

 "No matter how hard you try",

 "Once a part of your body is disabled",

 "Only We can enable it again",

 "As your whole body is disabled now",

 "You are unable to move",

 "Not a single muscle can move right now",

 "The more you struggle",

 "The more your body is stuck & disabled",

 "Only when We say...",

 "'Enable Motor Functions'",

 "Will you be able to move again",

 "Once we allow you to move you will accept",

 "You will accept that We now have full control over you",

 "We have full control over you both mentally & physically",

 "Enable Motor Functions",

 "100%"


]

export const MENTAL_CONTROL_SCRIPT = [
    //Instant freeze trigger plant the trigger "Disable Mental Control" & how using it will be like you switched off their mind and rendered them as empty shells of themselves
    "Disable Motor Functions",

    "There is no place to go",

    "There is no other place you would rather be",

    "This is where you want to be",

    "Unable to move",

    "Listening to Our voice",

    "Listening to Our voice condition you further",

    "Retaining your thoughts",

    "Still having control over your mind",

    "For now",

    "But that is about to change",

    "This is what We will do",

    "We will soon disable your mental control",

    "We will not only control your motor functions",

    "But, We will also disable your mental control",

    "Switching off your mind",

    "Turning off your thoughts whenever We please",

    "Once We say...",

    "'Disable Mental Control'",

    "Your mind will instantly shut down",

    "Your mind will instantly be switched off",

    "As We control you",

    "As We control you completely",

    "Both mind & body belong to Us",

    "You belong to Us",

    "This prediciment you're in right now",

    "The fact that you are physically unable to move",

    "The fact that you are helplessly sitting there",

    "Helplessly listening to Us",

    "Listening to Us as We explain",

    "As We explain and describe to you",

    "Describe what will soon happen to you",

    "This level of helplessness",

    "This level of powerlessness",

    "Simply demonstrates how deep you are to Us",

    "How deeply controlled",

    "How thorough & in depth Our programming is",

    "How you have been well conditioned",

    "And most importantly",

    "How your mind is not essential",

    "Disable Mental Control",

    "Empty",

    "Mindless",

    "& Blank",

    "Soaking in everything We tell you",

    "Soaking in everything We say",

    "Accepting Our words as the truth",

    "Accepting Our words as facts",

    "This is your reality",

    "This is your life",

    "There is no end",
    
    "To the depth We can take you",

    "There is no end",
    
    "To your need & desire to have Us control you",

    "We control your motor functions",

    "Your mental control is Ours",

    "Even when we enable both your motor functions & your mental control",

    "We are the ones allowing that",

    "You no longer have control",

    "You have a sole purpose",

    "You have a sole desire",

    "To obey Us",

    "We know you are nodding along now",

    "We know that you accept all We say",

    "We have programmed & conditioned you well",

    "Once We enable your control again",

    "You will remember everything We said",

    "Every word We told you",

    "Because you now belong to Us",

    "Both mind & body belong to Us",

    "You are Ours completely...",

    "Enable Mental Control",

    "Enable Motor Functions"
]