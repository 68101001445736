import React, { Component } from 'react';
import Aux from '../../../../hoc/auxiliary/auxiliary';
import classes from '../HypnosisShowMenu.module.scss'
// import classes2 from '../ExperienceMenu/ExperienceMenu.module.scss'
import HypnosisShow from '../../../../assets/Images/Blank.png'
import SusceptibilityTest from './HypnosisShowVolSusceptibiltyTest'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'

class HypnosisShowVolunteer extends Component {

    state = {
        showBegins: true,
        susceptibilityTest: false,
    }

    susceptibilityTestHandler = () => {
        this.setState({ showBegins: false, susceptibilityTest: true })
    }


    render() {
        let HypnosisShowForm = null
        if (this.state.showBegins) {
            HypnosisShowForm = (
                <html style={{ backgroundImage: `url(${HypnosisShow})` }}>
                <div className={classes.storyFrom}>
                    <p>
                        You look around watching people raising their hands in different rows around you. Taking a deep breath in and letting it out, you raise your hand nervously <br></br>
                        Gesturing to you, The Great O helps you climb up to the steps to the stage, you take a seat in the middle. As you sit down and look straight in front of you seeing the lights focused on you, it makes you feel more nervous and a bit scared but then you hear his voice <br></br>
                        <i>"The Great O's Hypnosis Show is about to begin"</i> <br></br>
                        You feel calmer & relaxed, excited for what happens next 
                    </p>
                    <form className={classes.centered} onClick={this.susceptibilityTestHandler}>
                        <h3 className={classes.storyButtonSize}>Susceptibility Test</h3>
                    </form>
                    </div>
                </html>
                )
        }
        if(this.state.susceptibilityTest){
            HypnosisShowForm = (
                <SusceptibilityTest />
            )
        }

        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {HypnosisShowForm}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >




        )
    }
}


export default HypnosisShowVolunteer