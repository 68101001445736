import * as audioRunTypes from '../audioRunTypes'
import axios from '../../../../axios'

export const MotorFunctionControlReinforcementStart = () => {
    return {
        type: audioRunTypes.AUDIO_RUN_START
    }
}

export const MotorFunctionControlSuccess = (Motor_Function_Reinforcement_Commands) => {
        return {
            type: audioRunTypes.MOTOR_FUNCTION_CONTROL_REINFORCEMENT_SUCCESS,
            Motor_Function_Reinforcement_Commands: Motor_Function_Reinforcement_Commands,
        } 
}

// export const SlowMotorFunctionControlReinforcement = () => {
//     return {
//         type: audioRunTypes.SLOW_MOTOR_FUNCTION_CONTROL_REINFORCEMENT,
//     } 
// }


// export const InstantMotorFunctionControlReinforcement = () => {
//     return {
//         type: audioRunTypes.INSTANT_MOTOR_FUNCTION_CONTROL_REINFORCEMENT,
//     } 
// }

export const MotorFunctionControlReinforcement = (userId, token) => {
    return dispatch => {
        dispatch(MotorFunctionControlReinforcementStart());
        axios.get('/Runs/'+userId+'/AudioRuns/Trigger_Reinforcement/Motor_Function_Control_Reinforcement.json?auth=' + token)
            
            .then( response => {
                // dispatch(ObedienceStageStart());
                if(response.data === null){
                    let Motor_Function_Control_Reinforcement = {
                        Motor_Function_Control_Reinforcement: 0,
                        instant_control_reinforcement:0,
                        slow_control_reinforcement:0,
                        mental_control_reinforcement: 0,
                        wired_reconfiguration: 0
                    }
                    axios.put('/Runs/'+userId+'/AudioRuns/Trigger_Reinforcement/Motor_Function_Control_Reinforcement.json?auth=' + token, Motor_Function_Control_Reinforcement)
                    .then(response => {
                        dispatch(MotorFunctionControlSuccess(response.data))
                    })
                    .catch(error => {
                        dispatch(MotorFunctionControlFailed(error.message.data))
                    })
                    
                } else{
                    dispatch(MotorFunctionControlSuccess(response.data))
                }
                       
            })
            .catch(error => {
                dispatch(MotorFunctionControlFailed(error.message.data))
            })
    }
}

export const MotorFunctionControlFailed = (error) => {
    return{
        type: audioRunTypes.AUDIO_RUN_FAILED,
        error: error
    }
}

export const MotorFunctionControlReinforcementEnd = (userId, token, instant_control_reinforcement, slow_control_reinforcement, mental_control_reinforcement, wired_reconfiguration) => {
    return dispatch => {
        axios.get('/Runs/'+userId+'/AudioRuns/Trigger_Reinforcement/Motor_Function_Control_Reinforcement.json?auth=' + token)
            .then( response => {
                    let updatedMotor_Function_Control_Reinforcement = {
                        Motor_Function_Control_Reinforcement: response.data.Motor_Function_Control_Reinforcement + 1,
                        instant_control_reinforcement: response.data.instant_control_reinforcement + instant_control_reinforcement,
                        slow_control_reinforcement: response.data.slow_control_reinforcement + slow_control_reinforcement,
                        mental_control_reinforcement: response.data.mental_control_reinforcement + mental_control_reinforcement,
                        wired_reconfiguration: response.data.wired_reconfiguration + wired_reconfiguration
                    }
                    axios.put('/Runs/'+userId+'/AudioRuns/Trigger_Reinforcement/Motor_Function_Control_Reinforcement.json?auth=' + token, updatedMotor_Function_Control_Reinforcement)
                    .then(response => {
                        dispatch(MotorFunctionControlCompleted())
                    })
                    .catch(error => {
                        dispatch(MotorFunctionControlFailed(error.message.data))
                    })    
                       
            })
            .catch(error => {
                dispatch(MotorFunctionControlFailed(error.message))
            })
    }
}

export const MotorFunctionControlCompleted = () => {
    return{
            type: audioRunTypes.MOTOR_FUNCTION_CONTROL_REINFORCEMENT_COMPLETED,
        }
}