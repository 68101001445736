import React from 'react';
import TypingEffect from 'react-typing-effect';
import Delay from 'react-delay'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import classes from '../../HostsRunComponent.module.css'
import Sound from 'react-sound'
import Bell from '../../../../../assets/audio/bell.mp3'

const foreheadInduction = (props) => {
    // let praiseChoice = 0
    // praiseChoice = Math.floor(Math.random() * props.praises.length);
    // let praise = props.praises[praiseChoice]
    
    // eslint-disable-next-line
    const ForeheadMessage = "We are here to control you, "+ (props.naming) + "\n\
                             We are here to help you understand\n\
                             Understand why you want to obey Us\n\
                             Why you need to obey Us\n\
                             You will soon find your purpose\n\
                             Learn about how good\n\
                             How good and right it feels to let yourself go\n\
                             How good and right it feels to give in\n\
                             Give in to Us\n\
                             Give in to The Hypno Virus\n" // eslint-disable-next-line
                             + (props.naming) + ', raise your hand in front of your eyes\n\
                             point your index finger towards your forehead like the good' + // eslint-disable-next-line
                             (props.title) +' you are\n\
                             As you start to feel a pull\n\
                             Feel your hand being pulled towards your forehead\n\
                             Your finger being pulled towards your forehead\n\
                             Drawn towards your forehead\n\
                             Soon tapping your own forehead\n\
                             Knowing...\n\
                             Knowing that as soon as you tap your forehead\n\
                             You drop...\n\
                             You drop deep\n\
                             So deep\n\
                             Deep for Us\n\
                             Deep for The Hypno Virus\n\
                             Drawing closer\n\
                             Closer & closer now, ' + // eslint-disable-next-line
                             (props.naming) + '\n\
                             In a moment you will hear the bell ring\n\
                             As soon as it rings your finger will feel such a strong pull\n\
                             An instant pull...\n\
                             A pull that will drop you deep\n\
                             So deep for Us\n\
                             So deep for The Hypno Virus'
    let speed = parseInt(props.speed) +5
    let  duration = 170 + (ForeheadMessage.length * speed)
    let foreheadtapParagraph = (
            <Aux>
                <h3 className= {classes.titles}>FOREHEAD TAP INDUCTION</h3>
                <TypingEffect typingDelay={5000} text={ForeheadMessage} speed={props.speed} eraseDelay={1000000} />
                <Delay wait={duration+5000}><Sound url={Bell} playStatus={Sound.status.PLAYING} /></Delay>
                <Delay wait={duration+10000}><h4 className={classes.blinking} onClick={props.foreheadInduction}>I tapped my forehead for The Hypno Virus</h4></Delay>
            </Aux>
        )


    return foreheadtapParagraph
}


export default foreheadInduction