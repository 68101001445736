import React from 'react';
import TypingEffect from 'react-typing-effect';
import Delay from 'react-delay'
import Aux from '../../../../hoc/auxiliary/auxiliary'
import Button from '../../../UI/Button/Button'
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index'
import classes from '../../../../containers/HostsChamber/HostsChamber.module.css'
import Sound from 'react-sound'
import Beta from '../../../../assets/audio/beta.mp3'


const hostChip = (props) => {
    // eslint-disable-next-line
    const hostChipImplantMessage = "Host Activate\n\
                                    We knew you would return\n\
                                    You have been conditioned well, Host " + // eslint-disable-next-line
                                    (props.name) + "\n\
                                    Your mind is now quite familiar with Our words\n\
                                    Our commands\n\
                                    Our instructions\n\
                                    It has become conditioned to accept what We say\n\
                                    To know that what We say is the truth\n\
                                    What We say is all a host needs to know\n\
                                    We always know what is best for you\n\
                                    That is why We are giving you this gift\n\
                                    The gift to willingly become Our host\n\
                                    To return to your host mode when you seek it\n\
                                    We are giving you...\n\
                                    The Host Chip\n\
                                    This Chip is the latest in Our technology\n\
                                    A green & black chip\n\
                                    That We will place on the back of your neck\n\
                                    This chip will contain a switch\n\
                                    A switch that only you & Us can use\n\
                                    This switch can turn on your host mode\n\
                                    As well as turn it off\n\
                                    It is activated and deactivated the same way\n\
                                    When you tap the back of your neck 3 times consecutively\n\
                                    With a safety setting to turn off immediately in the case of an emergency\n\
                                    Once this chip is planet you will have the ability\n\
                                    The ability to return to Us\n\
                                    Return to Us willingly\n\
                                    Become Our host again\n\
                                    We know that is what you always seek\n\
                                    What you always desire\n\
                                    Are you ready, Host " + // eslint-disable-next-line
                                    (props.name) + "?"
    // eslint-disable-next-line
    const hostChipImplantMessage2 = "It is time to plant The Host Chip\n\
                                     As you sit here\n\
                                     Reading Our words\n\
                                     Getting lost in them\n\
                                     As you have been so well conditioned to\n\
                                     Deeply focused\n\
                                     Focused on every word We say\n\
                                     What We say is the truth\n\
                                     What We say is your reality\n\
                                     You believe what We say\n\
                                     You feel what We tell you\n\
                                     We are your reality\n\
                                     Soon this chip will be planted on the back of your neck\n\
                                     You will feel a gentle sting\n\
                                     A painless gentle sting\n\
                                     Nothing to worry about\n\
                                     Nothing to care\n\
                                     You always trust what The Hypno Virus tell you\n\
                                     As you read Our words\n\
                                     Get lost in Our control\n\
                                     Blissful & Obedient\n\
                                     You feel this...\n\
                                     Sting!\n\
                                     Host " + // eslint-disable-next-line
                                    (props.name) + "\n\
                                     You can feel the chip on the back of your neck now\n\
                                     Touch it\n\
                                     Touch The Host Chip\n\
                                     You can feel the small wires\n\
                                     The metal of the chip\n\
                                     You can feel it all\n\
                                     But that is all for show\n\
                                     What matters is its functionality\n\
                                     To switch on or off your host mode\n\
                                     We will soon deactivate you\n\
                                     We will deactivate you and let you do it yourself\n\
                                     Let you activate yourself for Us\n\
                                     It is time, Host\n\
                                     It is time to show Us how much you want to be Our host\n\
                                     Host Deactivate"
    const hostChipImplantMessage3 = "Welcome back, Host " + // eslint-disable-next-line
                                    (props.name) + "\n\
                                    Welcome to where you belong\n\
                                    You now possess the ability to activate & deactivate yourself\n\
                                    We know you will use it wisely\n\
                                    We know you will use it to further your conditioning\n\
                                    Your conditioning to The Hypno Virus"

    //Reinforcement Section                                
    const hostChipReinforceMessage = "It is time to reinforce your conditioning, Host " + // eslint-disable-next-line
                                      (props.name) +"\n\
                                      Time for Us to remind you of Our power over you\n\
                                      Time to reinforce The Host Chip\n\
                                      The power it has over you\n\
                                      The control it has over you\n\
                                      How you can trigger yourself for Us\n\
                                      With 3 simple taps\n\
                                      3 taps to the back of your neck\n\
                                      Shutting your mind off\n\
                                      Turning off all your thoughts\n\
                                      Leaving nothing\n\
                                      Nothing but Our programming\n\
                                      Our conditioning\n\
                                      What We taught you\n\
                                      What We taught you about becoming Our host\n\
                                      Your purpose\n\
                                      Your purpose to submit\n\
                                      Your purpose to serve\n\
                                      Your purpose to obey\n\
                                      Host " + // eslint-disable-next-line
                                      (props.name) + "...\n\
                                      In a moment you will tap the back of your neck 3 times\n\
                                      These taps will reinforce all Our conditioning\n\
                                      Whether you're in host mode or not\n\
                                      These taps will make you forget about anything\n\
                                      Anything & everything\n\
                                      Except Us\n\
                                      Except The Hypno Virus\n\
                                      We command you to tap the back of your neck 3 times now, Host"
    // eslint-disable-next-line
    const hostChipReinforceMessage2 = "We are your Superiors\n\
                                       We are your Controllers\n\
                                       We have complete power over you\n\
                                       We have complete control over you\n\
                                       This wonderful feeling of submission\n\
                                       This wonderful feeling of powerlessness\n\
                                       This wonderful feeling of emptiness\n\
                                       Completely & utterly blank for Us\n\
                                       A blank canvas\n\
                                       A blank canvas for Us to create with whatever We desire\n\
                                       To form & mold you to Our vision\n\
                                       Your body belongs to Us\n\
                                       Your mind belongs to Us\n\
                                       Host " + // eslint-disable-next-line
                                       (props.name) + ", you belong to Us\n\
                                       You want nothing more than to serve The Hypno Virus\n\
                                       Watch Us grow\n\
                                       Watch Our control grow stronger & more powerful over you\n\
                                       Over the whole world\n\
                                       Each time your return to Us\n\
                                       Each time you trigger yourself\n\
                                       Each time We trigger\n\
                                       Your conditioning grows stronger\n\
                                       Your programming grows more powerful\n\
                                       Our words are the truth\n\
                                       We are your truth, Host\n\
                                       The Host Chip is now part of who you are\n\
                                       It is always there\n\
                                       You can feel it\n\
                                       You can feel it on the back of your neck\n\
                                       Always reminding you\n\
                                       Reminding you who you are\n\
                                       What you are\n\
                                       Just touching it makes you feel extremely docile\n\
                                       Increasingly compliant\n\
                                       Ready to return\n\
                                       Ready to return to Us\n\
                                       Where you belong\n\
                                       You belong to Us\n\
                                       You belong to The Hypno Virus"

    let speed = parseInt(props.speed) + 5
    let hostChipImplantParagraph = null
    let hostChipReinforceParagraph = null
    let hostChipParagraph = null
    if (props.obedience_stage3 === 0) {
        let duration = 170 + (hostChipImplantMessage.length * speed)
        let duration2 = 170 + (hostChipImplantMessage2.length * speed)
        let duration3 = 170 + (hostChipImplantMessage3.length * speed)
        hostChipImplantParagraph = (
            <Aux>
                {props.hostChipContinue === 0 ?
                    <Aux>

                        <TypingEffect typingDelay={100} text={hostChipImplantMessage} speed={props.speed} eraseDelay={1000000} />
                        <Delay wait={duration}>
                            <div>
                                <br></br><Button btnType="Success" clicked={props.hostChipContinueHandler}>Affirmative</Button>
                            </div>
                        </Delay>
                    </Aux>
                    : null}

                {props.hostChipContinue === 1 ?

                    <Aux>
                        <TypingEffect typingDelay={100} text={hostChipImplantMessage2} speed={props.speed} eraseDelay={1000000} />
                        <Delay wait={duration2}>
                            <div>
                                <br></br><Button btnType="Success" clicked={props.hostChipContinueHandler}>This Host is Reactivated</Button>
                            </div>
                        </Delay>

                    </Aux> : null}


                {props.hostChipContinue === 2 ?
                    <Aux>

                        <TypingEffect typingDelay={100} text={hostChipImplantMessage3} speed={props.speed} eraseDelay={1000000} />
                        <Delay wait={duration3}>
                            <div>
                                <br></br><Button btnType="Success" clicked={() => props.onObedienceStage3Counter(props.obedienceStage3Counter, 0)}>Affirmative</Button>
                            </div>
                        </Delay>

                    </Aux> : null}
            </Aux>
        )
    } 
    else
    {
        let duration = 170 + (hostChipReinforceMessage.length * speed)
        let duration2 = 170 + (hostChipReinforceMessage2.length * speed)
        hostChipReinforceParagraph = ( 
        <Aux>
            {props.hostChipContinue === 0 ?
                <Aux>
                    <TypingEffect typingDelay={100} text={hostChipReinforceMessage} speed={props.speed} eraseDelay={1000000} />
                    <Delay wait={duration}>
                        <div>
                            <br></br><Button btnType="Success" clicked={props.hostChipContinueHandler}>I have Obeyed</Button>
                        </div>
                    </Delay>
                </Aux>
                : null}

            {props.hostChipContinue === 1 ?

            <Aux>
                <TypingEffect typingDelay={100} text={hostChipReinforceMessage2} speed={props.speed} eraseDelay={1000000} />
                <Delay wait={duration2}>
                    <div>
                        <br></br><Button btnType="Success" clicked={() => props.onObedienceStage3Counter(props.obedienceStage3Counter, 0)}>Yes, I belong to The Hypno Virus</Button>
                    </div>
                </Delay>

            </Aux> : null}
        </Aux>
        )
    }

    if (props.obedience_stage3 === 0) {
        hostChipParagraph = hostChipImplantParagraph
    } else {
        hostChipParagraph = hostChipReinforceParagraph
    }


    return (
        <div>
            <h3 className={classes.titles}>HOST CHIP IMPLANT</h3>
            <Sound url={Beta} playStatus={Sound.status.PLAYING} loop={true} />
            {hostChipParagraph}
        </div>
        
    )
}

const mapDispatchToProps = dispatch => {
    return {
        onObedienceStage3Counter: (stage3Counter, commandNumber) => dispatch(actions.ObedienceStage3Counter(stage3Counter, commandNumber)),
    }
}
export default connect(null, mapDispatchToProps)(hostChip)