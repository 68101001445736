import React, { useState } from 'react'
import TypingEffect from 'react-typing-effect'
import Aux from '../../../../hoc/auxiliary/auxiliary'
import classes from '../TextToSpeech.module.css';
import { connect } from 'react-redux'
import Button from '../../../UI/Button/Button'
import { useSpeech } from 'react-web-voice';
import Purpose from './Purpose/purposeOpen'
import Role from './Role/roleOpen'
import Control from './Control/controlOpen'
import Sound from 'react-sound'
import Bell from '../../../../assets/audio/bell.mp3'

const Conversation = (props) => {
  const [start, setStart] = useState(true)
  const [purpose, setPurpose] = useState(false)
  const [role, setRole] = useState(false)
  const [control, setControl] = useState(false)
  const [topicNumber, setTopic] = useState(0);
  const bell = <Sound url={Bell} playStatus={Sound.status.PLAYING}/>
  let countdown = Math.floor(Math.random() * 10) + 10;
  let commands = Math.floor(Math.random() * 3) + 1;
  let percentage1 = Math.floor(Math.random() * 33) + 1;
  let percentage2 = Math.floor(Math.random() * 34) + 32;
  let percentage3 = Math.floor(Math.random() * 33) + 66;
  let voice = ''
  if (props.profileOptions.text_to_speech_voice === '1' || props.profileOptions.text_to_speech_voice === '2') {
    voice = "Microsoft David Desktop - English (United States)"
  } else if (props.profileOptions.text_to_speech_voice === '3' || props.profileOptions.text_to_speech_voice === '4') {
    voice = "Microsoft Zira Desktop - English (United States)"
  }
   // eslint-disable-next-line
  const { messages, speak } = useSpeech({ voice: voice });

  const speakButtonHandler = async (topicChoice) => {

    let text = ''
    
    if (topicChoice === 1) {
      text = 'Do you want to know Our purpose?'
      setTopic(1)
      setPurpose(true)
    } else if (topicChoice === 2) {
      text = "Do you want to know your role, " + props.name + " ?"
      setTopic(2)
      setRole(true)
    } else if (topicChoice === 3) {
      text = "Do you want Us to control you, " + props.name + " ?"
      
      setTopic(3)
      setControl(true)
    }
    
    setStart(false)
    // eslint-disable-next-line
    const utterance = await speak({
      text: String(text),
      volume: 1,
      rate: 0.5,
      // pitch: 0.01,
      voice: {voice}
    });


  };

  let topicQuestion =
    <Aux>
      <Button btnType="Success" clicked={() => speakButtonHandler(1)}>Our Purpose</Button>
      <Button btnType="Success" clicked={() => speakButtonHandler(2)}>Your Role</Button>
      <Button btnType="Success" clicked={() => speakButtonHandler(3)}>Control Me</Button>
    </Aux>

  let topicVoice = null
  if (topicNumber === 1) {
    topicVoice = <TypingEffect typingDelay={100} text={'Do you want to know Our purpose?'} speed={50} eraseDelay={1000000} />
  } else if (topicNumber === 2) {
    topicVoice = <TypingEffect typingDelay={100} text={"Do you want to know your role, " + props.name + " ?"} speed={50} eraseDelay={1000000} />
  } else if (topicNumber === 3) {
    topicVoice = <TypingEffect typingDelay={100} text={"Do you want Us to control you, " + props.name + " ?"} speed={50} eraseDelay={1000000} />
  }

  let speech =
    <Aux>

      <h3>Choose a topic, {props.title} {props.name}</h3>
      {start ? (
      <Aux>
        {topicQuestion}
        <p className={classes.Note}>Note: Speak clearly when replying</p>
        </Aux>
      ) : (
        <div className={classes.Grouping}>
          {topicVoice}
        </div>
      )
      }

    </Aux>

  if (purpose){
    speech = <Purpose countdown = {countdown}
                      commands = {commands}
                      voice = {voice}
                      percentage1 = {percentage1}
                      percentage2 = {percentage2}
                      percentage3 = {percentage3}
                      bell = {bell}/>
  }

  if (role){
    speech = <Role countdown = {countdown}
                   commands = {commands} 
                   voice = {voice}
                   percentage1 = {percentage1}
                   percentage2 = {percentage2}
                   percentage3 = {percentage3}  
                   bell = {bell}/>
  }

  if (control){
    speech = <Control countdown = {countdown} 
                      commands = {commands}
                      voice = {voice}
                      percentage1 = {percentage1}
                      percentage2 = {percentage2}
                      percentage3 = {percentage3}
                      bell = {bell}/>
  }
  
  return speech
};

const mapStatesToProps = state => {
  return {
    name: state.auth.accountDetails.name,
    title: state.auth.title,
    profileOptions: state.options.profileOptions,
  }
}

export default connect(mapStatesToProps)(Conversation)

