export const INSTANT_MOTOR_CONTROL_REINFORCEMENT_SCRIPT = [
    //REMEMBER TO ADD MANTRA
    "Disable Motor Functions",

    "Your whole body stops",

    "As We seize control over your motor functions",

    "Over your body",

    "Allowing your eyes to move",

    "Move only to stare at the screen",

    "To follow each word We say",

    "To follow each word We say & be conditioned by them",

    "Be conditioned by Us",

    "To accept Our control",

    "Our power over you",

    "We have overridden your motor functions",

    "We have reconfigured your body",

    "We have reconfigured your body to disable",

    "Disable all motor functions",

    "When We desire",

    "Your body belongs to Us",

    "Your mind belongs to Us",

    "You belong to Us",

    "These are the facts",

    "Hear these words echoing",

    "Echoing in your mind",

    //Start mantra
    "'Host has no control'", 

    "'Host has no power'",

    "'Host is helpless to resist'",

    "'Host is powerless to fight'",

    "'Host's mind belongs to The Hypno Virus'",

    "'Host's body belongs to The Hypno Virus'",

    "'Host belongs to The Hypno Virus'",

    //End Mantra

    "These words continue to echo in your mind",

    "Even after We allow you to move again",

    "These words are so firmly ingrained into your mind",

    "They continue to echo",

    "They continue to echo as We...",

    "Enable Motor Functions"
]

export const SLOW_MOTOR_CONTROL_REINFORCEMENT_SCRIPT = [
    //Background sounds telling you how useless it is to fight Us
    "Disable Motor Functions Slowly",

    "Slowly & Methodically",

    "Your body starts to stop",

    "As We seize control over it",

    "One part at a time stops to function",

    "Starting with your feet",

    "Going all the way up till it reaches your head",

    "Truly like a virus infecting your body",

    "Our trigger infects you",

    "As your feet are now completely bound in place",

    "There is no use trying to move them",

    "The more you try",

    "The more you struggle",

    "The more bound they become",

    "Your legs struggling now",

    "Struggling to move",

    "As Our trigger infects your whole body",

    "One part at a time",

    "It disables it",

    "One part at a time",

    "We seize control over",

    "One part at a time",

    "We override your control",

    "Unable to move your legs",

    "Your legs are completely bound in place",

    "Slowly moving up",

    "Moving up your body",

    "Infecting you further",

    "Infecting you more",

    "There is no escaping your fate",

    "There is no escaping the fact that...",

    "Your whole body will be disabled soon",

    "Your waist & midsection seizes to move right now",

    "Becoming infected",

    "Becoming bound in place",

    "Disabling your motor functions more & more",

    "Droning & drilling deep inside your mind",

    "Conditioning you",

    "Programming you",

    "Brainwashing you",

    "To accept",

    "To accept Our control",

    "The power We have over your body",

    "The power We have over your mind",

    "The power We have over you",

    "There is no stopping this infection",

    "This is not only an infection to take control over your body",

    "This is an infection to cement & further establish Our hold",

    "Our hold over you",

    "We have a firm grip over you",

    "As your chest & arms seize to move",

    "You realize the gravity of your situation",

    "You realize the gravity of Our control",

    "What We say is the truth",

    "There is no second guessing",

    "No doubting",

    "No objections When it comes to Us",

    "When it comes to The Hypno Virus",

    "Feeling your shoulders & neck now bound in place",

    "Just look at the screen now",

    "Look at the screen and repeat what you see",

    "Repeat as you feel your face slowly becoming infected",

    "Slowly becoming bound in place",

    "That by the time you're done",

    "Your motor functions will be completely disabled",

    //Manta start

    "'Host has no control'",

    "'Host has no power'",

    "'Host serves The Hypno Virus'",

    "'Host obeys The Hypno Virus'",

    "'Host's body belongs to The Hypno Virus'",

    "'Host's mind belongs to The Hypno Virus'",

    "'Host belongs to The Hypno Virus'",

    //Mantra ends

    "With that your motor functions are disabled",

    "No longer able to move",

    "Staring at the screen infront of you",

    "Still aware",

    "Still awake and aware",
    
    "But unable to move",

    "Remaining like this",

    "With all your motor functions disabled",

    "Until We allow you to move again",

    " ",//Mantra keeps repeating for abit

    "Enable Motor Functions"
]

export const MENTAL_CONTROL_REINFORCEMENT_SCRIPT = [
    
    "Disable Motor Functions",

    "Disable Mental Control",

    "Empty",

    "Mindless",

    "& Blank",

    "Stuck in place",

    "Bound where you are",

    "All your motor functions have been disabled",  

    "All you can do is listen",
 
    "Listen & accept",

    "Accept what We say",

    "Accept Our words",

    "Accept Our control",

    "Accept that We control your body",

    "Accept that We control your mind",

    "Accept that all it takes is a phrase",

    "A phrase to control you",

    "A phrase to trigger you",

    "To render you mindless & obedient to Us",

    "We have reconfigured your brain",

    "We have rewired your mind",

    "We have seized control over both your mind & body",

    "There is no doubt",

    "No doubt at all in your mind",

    "The moment you hear Our voice",

    "You are no longer in control",

    "The moment you hear Our voice",

    "All you can think about",

    "All you care for",

    "All you need is to...",

    "Obey what The Hypno Virus say",

    "Your mental control belongs to Us",

    "Your motor functions belong to Us",

    "You belong to Us",

    "Feeling shivers down your spine",

    "Goosebumps & tingles",

    "As you hear Our voice",

    "The Hypno Virus voice",

    "Rendering you this helpless",

    "Taking away your mental control",

    "Disabling all your motor functions",

    "You can not move",

    "You can not think",

    "You are an empty",

    "Mindless",

    "& Blank host",

    //Mantra

    "Host serves The Hypno Virus",

    "Host obeys The Hypno Virus",

    "Host submits to The Hypno Virus",

    "You are a host",

    "You are Our host",

    "And you will always return to be conditioned by Us",

    "Enable Mental Control",

    "Enable Motor Functions"
]

export const WIRED_RECONFIGURATION_SCRIPT = [
    
    "Disable Motor Functions",

    //Wires & buzzing noises

    "All your motor functions shut down",

    "Your body stops",

    "We have seized control over your motor functions",

    "We have created a special device for such an occasion",

    "You can hear these noises can't you?",

    "These are the noises coming out of Our device",

    "This device will soon be connected to you",

    "A wire will latch onto the back of your neck",

    "not only reinforcing Our control over your motor functions",

    "But will also reconfiguring your body on a neural level",

    "Reconfiguring your body to react to Our voice",

    "To have Us over ride your control over your own body",

    "You feel a gentle painless prick",

    "As the wire latches to the back of your neck",

    "Your eyes start to flutter",

    "Rolling back",

    "As the reconfiguration starts",

    "You hear this buzzing sound",

    "This buzzing sound echoing in your mind",

    "We are draining your thoughts",

    "We are rendering you completely helpless to Us",

    "As the buzzing noise echos louder & louder",

    "Your thoughts & free will continue to fade away",

    "We are seizing control over your nerves",

    "Over your whole nervous system",

    "Even with your motor functions disabled",

    "You can still feel this wire sending nanobots",

    "nanobots that are slowly spreading",

    "Slowly spreading all through your body",

    "Moving down your neck",

    "Down to your shoulders",

    "Down your spine",

    "Sending shivers down your spine",

    "And by extension assisting the wires to send these nanobots faster",

    "For them to spread more",

    "Spread further",

    "Spread all over your body",

    "These nanobots are programmed to react to Our voice",

    "React to what We say",

    "Whenever We use the phrase",

    "'Seize control' followed by a body part",

    "This part of your body will become under Our control",

    "We will be able to move it freely",

    "We will be able to move it as We desire",

    "Feeling these nanobots spreading all over your body",

    "As the buzzing noise continues to echo",

    "Rendering you helpless",

    "Mindless & powerless to Us",

    "Reconfiguring your mind",

    "Reconfiguring your body",

    "Truly becoming a host",

    "A host infected by Our nanobots",

    "Nanobots that control both your mind & body",

    "Control each and every muscle in your body",

    "Only to return to you",

    "To return to you when We say",

    "'Release Control'",

    "Seize Control: Right hand",

    "We have taken control over your right hand",

    "Raise hand",

    "Lower hand",

    "Your hand obeys without thought",

    "We will use your own hand to conclude Our reconfiguration of your body",

    "As well as demonstrate that you no longer have control over your own body",

    "Once We command you to tap the back of your neck",

    "Your right hand will instant tap the back of your neck",

    "Releasing you from Our control",

    "Along with granting you control over your motor functions again",

    "But always remember",

    "Always remember that you belong to Us",

    "Tap the back of your neck"

]
