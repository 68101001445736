import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '../../../UI/Button/Button'
import classes from '../../../../containers/HostsChamber/HostsChamber.module.css'
import Aux from '../../../../hoc/auxiliary/auxiliary'
import Background from '../../../Background/Background'
import ConditioningProtocol1Visual from '../Conditioning_Protocols/Conditioning_Protocol_#1/Conditioning_Visual'
import WebsiteAudio from '../../../Background/WebsiteAudio';
import * as actions from '../../../../store/actions/index';


class ConditioningProtocols extends Component {
    state = {
        conditioning_protocols: true,
        conditioning_protocol_1: false
    }

    enterConditioningProtocol1Handler = () => {
        this.setState({ conditioning_protocols: false, conditioning_protocol_1: true })
    }

    ReturnHandler = () => {
        this.props.onReturnHostsChamber()
    }


    render() {
        let audioRunForm = null
        if (this.state.conditioning_protocols) {
            audioRunForm = (
                <Aux>
                    <div className={classes.HostsChamber}>
                        <Background />
                        <WebsiteAudio />
                        <div className={classes.FormStyle}>
                            <h3>Experience The Power of Our Voice, {this.props.hostName}</h3>
                            <div className={classes.HostButtons}>
                                <Button btnType="Success" className={classes.HostChamberButton} clicked={this.enterConditioningProtocol1Handler}>Conditioning Protocol #1</Button><br></br>
                                <Button btnType="Danger" clicked={this.ReturnHandler}>Return To Hosts Chamber</Button>
                            </div>
                        </div>
                    </div>
                </Aux>
            )
        }

        if (this.state.conditioning_protocol_1) {
            audioRunForm = (
                <Aux>
                    <ConditioningProtocol1Visual />
                </Aux>
            )
        }

        return (audioRunForm)
    }
}

const mapStateToProps = state => {
    return {
        hostName: state.auth.accountDetails.name,
        runSpeed: state.auth.accountDetails.speed,
        hostRun: state.auth.accountDetails.Host_Runs,
        token: state.auth.token,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onReturnHostsChamber: () => dispatch(actions.onReturnHostsChamber()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConditioningProtocols)