import React from 'react';
import TypingEffect from 'react-typing-effect';
import Delay from 'react-delay'
import Aux from '../../../../hoc/auxiliary/auxiliary'
import Button from '../../../UI/Button/Button'
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index'
import classes from '../../../../containers/HostsChamber/HostsChamber.module.css'
import Sound from 'react-sound'
import Beta from '../../../../assets/audio/beta.mp3'


const mechanicalTentacles = (props) => {
    // eslint-disable-next-line
    const mechanicalTentaclesIntroMessage = "Welcome, " + // eslint-disable-next-line
    (props.title)+ " " +(props.name) +"\n\
                                            We have been expecting you\n\
                                            We have been waiting for your return\n\
                                            We have been working on this new project\n\
                                            An upgrade\n\
                                            An upgrade to Our hardware that will be extremely beneficial\n\
                                            A new way for Us to demonstrate Our control\n\
                                            A new way for you to experience Our control\n\
                                            We have created...\n\
                                            Tentacles\n\
                                            After research We discovered that humans find these tentacles quite fascinating\n\
                                            Even erotic in many cases\n\
                                            But We are not here for your pleasure, " + // eslint-disable-next-line
                                            (props.name) + "\n\
                                            We are here to suck these thoughts from inside your head\n\
                                            With Our silver, mechanical tentacles\n\
                                            Which have very special suction cups\n\
                                            These cups are covered with small nanobots\n\
                                            These nanobots are programmed to send frequency waves that affect your mind\n\
                                            That once latched to your head\n\
                                            Once they wrap you & have a tight grip on you\n\
                                            There is no escape\n\
                                            No where to go and soon\n\
                                            Soon your mind will be mush\n\
                                            Are you ready, " + // eslint-disable-next-line
                                            (props.title) + "?"
    // eslint-disable-next-line
    let mechanicalTentaclesFirstRunMessage = "You see Our tentacles slithering in front of you\n\
                                              Dancing & swaying in front of you\n\
                                              They're quite mesmerizing aren't they?\n\
                                              Quite captivating\n\
                                              As you watch them slither & sway before you\n\
                                              Slowly coming closer\n\
                                              Closer & closer to you\n\
                                              Unable to look away from their hypnotic motion\n\
                                              They're so captivating & hypnotic\n\
                                              They starts to wrap itself arround you\n\
                                              Around your chest\n\
                                              Around your waist\n\
                                              Surrounding you\n\
                                              Engulfing you\n\
                                              Encompassing you\n\
                                              Tightening their grip on you\n\
                                              As they sliter and crawl\n\
                                              Making their way\n\
                                              Making their way to your neck\n\
                                              To your head\n\
                                              Right above your head now\n\
                                              Looming above it\n\
                                              Not quite touching...\n\
                                              Yet\n\
                                              as 2 other tentacles slowly make their way next to your ears\n\
                                              Knowing...\n\
                                              Knowing that as soon as they latch on to you\n\
                                              As soon as those nanobots make their way through your nerves\n\
                                              You'll be... gone\n\
                                              Completely gone...\n\
                                              Are you Ready, "+
                                              (props.title) + "?"
    // eslint-disable-next-line
    let mechanicalTentaclesFirstRunMessage1 = "You feel the tentacles latch on to you\n\
                                                Now\n\
                                                On the top of your head\n\
                                                On your ears\n\
                                                Hearing this buzzing noise\n\
                                                This frequency\n\
                                                Getting harder\n\
                                                Harder & harder to think\n\
                                                Harder & harder to focus\n\
                                                As your eyes start to flutter\n\
                                                Flutter & roll\n\
                                                Your mind goes mindless\n\
                                                Mindless and blank\n\
                                                Hearing this noise\n\
                                                This white noise\n\
                                                This is the frequency\n\
                                                This is the tone\n\
                                                Once you hear it\n\
                                                You go...\n\
                                                Blank\n\
                                                Mindless & Blank\n\
                                                From now \n\
                                                When We, When We say 'Activate Tentacles'\n\
                                                You feel the tentacles wrapping themselves\n\
                                                Wrapping themselves around your body\n\
                                                Latching on to you\n\
                                                Latchin on to you, rendering you\n\
                                                Rendering you mindless & blank\n\
                                                Until...\n\
                                                Until We say 'Tentacles Deactivate'\n\
                                                Once that happens\n\
                                                The tentacles slowly\n\
                                                Slowly retreat\n\
                                                As you start to regain your thoughts\n\
                                                Returning back to normal\n\
                                                So now it is time\n\
                                                It is time to deactivate the tentacles\n\
                                                But you will return\n\
                                                We know that you will\n\
                                                We have conditioned you to, " + // eslint-disable-next-line
                                                (props.name)+ "\n\
                                                Time to... Deactivate Tentacles"
    // eslint-disable-next-line
    const mechanicalTentaclesRegularIntroMessage = "You've had thoughts & a free will for far too long\n\
                                                    Time for Our tentacles to latch themselves onto you, " + // eslint-disable-next-line
                                                    (props.title) +" "+(props.name) + "\n\
                                                    Time for Our nanobots to sift through your brain\n\
                                                    Getting rid of all these pesky thoughts\n\
                                                    Time to activate Our tentacles & remind you of the power they have over you\n\
                                                    Are you ready, " + // eslint-disable-next-line
                                                    (props.name) + "?\n\
                                                    Are you ready to experience Our tentacles again?"
    // eslint-disable-next-line
    let mechanicalTentaclesRegularRunMessage = "Activate Tentacles\n\
                                                You see them, " + // eslint-disable-next-line
                                                (props.name) +"\n\
                                                You see Our tentacles\n\
                                                Swaying\n\
                                                Swaying & Slithering before your eyes\n\
                                                Wrapping themselves\n\
                                                Wrapping themselves around you\n\
                                                Round & Round they go\n\
                                                All around your body\n\
                                                Around your waist\n\
                                                Around your chest\n\
                                                Around your whole body\n\
                                                As the tentacles latch themselves\n\
                                                Latch themselves to your head\n\
                                                To your ears\n\
                                                Instantly...\n\
                                                Instantly hearing the frequency\n\
                                                Instantly feeling your eyes fluttering\n\
                                                Fluttering & Rolling\n\
                                                As Our tentacles erase all thoughts\n\
                                                Delete your free will\n\
                                                Leaving you...\n\
                                                Leaving you as an empty husk\n\
                                                An empty shell of yourself\n\
                                                Ready... ready to be programmed\n\
                                                So when you're ready you will continue"
    // eslint-disable-next-line                                                
    let mechanicalTentaclesRegularRunMessage1 = "Empty...\n\
                                                 Empty Shell\n\
                                                 No Thoughts\n\
                                                 No Will\n\
                                                 You obey what We say\n\
                                                 It is that simple, "+ // eslint-disable-next-line
                                                 (props.title)+" "+(props.name) + "\n\
                                                 As you feel the tentacles latched on to you\n\
                                                 You hear their frequency and understand\n\
                                                 Understand you only exist for Us\n\
                                                 Exist to serve Us\n\
                                                 Exist to please Us\n\
                                                 Exist to obey Us\n\
                                                 Feeling the tentacles tightening their grip\n\
                                                 Getting rid of any and all remaining thoughts\n" // eslint-disable-next-line
                                                 +(props.name) + ", We control you\n" // eslint-disable-next-line
                                                 +(props.name) + ", We own you\n" // eslint-disable-next-line
                                                 +(props.name) + ", We use you\n" // eslint-disable-next-line
                                                 +(props.name) + ", We do with you as We wish\n\
                                                 And you love every moment of it\n\
                                                 All you really want right now\n\
                                                 All you ever want these days is to return to Us\n\
                                                 Be controlled by Us\n\
                                                 Think of Us\n\
                                                 Think of ways to serve Us\n\
                                                 Think of ways to please Us\n\
                                                 Think of ways to show everyone how much you enjoy The Hypno Virus\n\
                                                 It is so natural\n\
                                                 So normal & natural to feel this way\n\
                                                 Feeling the tentacles engulfing your whole body\n\
                                                 Knowing\n\
                                                 Knowing this is bliss\n\
                                                 This is the bliss you've been looking for\n\
                                                 This is the wonderful bliss only We can make you feel\n\
                                                 This is how you feel when you surrender to Us\n\
                                                 And that is all you really want\n\
                                                 Isn't that right, " + // eslint-disable-next-line
                                                 (props.name) + "?"
    // eslint-disable-next-line                                                
    let mechanicalTentaclesRegularRunMessage2 = "Of course this is what you want\n\
                                                 There is nothing better than to get lost in Our bliss\n\
                                                 That is all you truly want\n\
                                                 That is all you truly need\n\
                                                 To see Us grow\n\
                                                 See Us evolve further\n\
                                                 Doing what you can to help Us even more\n\
                                                 Getting rewarded with this bliss\n\
                                                 This euphoria\n\
                                                 This is what you get when you submit to Us\n\
                                                 Our tentacles sending waves after waves of pure bliss\n\
                                                 Pure bliss all over your body\n\
                                                 Soon\n\
                                                 Soon We will deactivate the tentacles\n\
                                                 Returning back to normal\n\
                                                 Or rather what is now your normal\n\
                                                 Serving Us\n\
                                                 So get ready, " + // eslint-disable-next-line
                                                 (props.title) + "\n\
                                                 Get ready as We let you deactivate yourself now"                                                 
                                                 
    let speed = parseInt(props.speed) + 5
    let mechanicalTentaclesParagraph = null
    if (props.obedience_stage3 === 2) {
        if (props.mechanicalTentaclesToggle && props.mechanicalTentaclesContinue === 1) {
            mechanicalTentaclesFirstRunMessage = 'We are the ones who decide, ' + props.name + '\n' + mechanicalTentaclesFirstRunMessage
        }
        if (props.mechanicalTentaclesToggle && props.mechanicalTentaclesContinue === 2) {
            mechanicalTentaclesFirstRunMessage1 = 'Again you think you have a choice, ' + props.name + '\n' + mechanicalTentaclesFirstRunMessage1
        }
        let duration = 170 + (mechanicalTentaclesIntroMessage.length * speed)
        let duration2 = 170 + (mechanicalTentaclesFirstRunMessage.length * speed)
        let duration3 = 170 + (mechanicalTentaclesFirstRunMessage1.length * speed)
        mechanicalTentaclesParagraph = (
            <Aux>
                {props.mechanicalTentaclesContinue === 0 ?
                    <Aux>

                        <TypingEffect typingDelay={100} text={mechanicalTentaclesIntroMessage} speed={props.speed} eraseDelay={1000000} />
                        <Delay wait={duration}>
                            <div>
                                <br></br><Button btnType="Success" clicked={() => props.mechanicalTentaclesHandler(0)}>Affirmative</Button>
                                         <Button btnType="Danger" clicked={() => props.mechanicalTentaclesHandler(1)}>Negative</Button>
                            </div>
                        </Delay>
                    </Aux>
                    : null}
                {props.mechanicalTentaclesContinue === 1 ?
                    <Aux>

                        <TypingEffect typingDelay={100} text={mechanicalTentaclesFirstRunMessage} speed={props.speed} eraseDelay={1000000} />
                        <Delay wait={duration2}>
                            <div>
                                <br></br><Button btnType="Success" clicked={() => props.mechanicalTentaclesHandler(0)}>Yes! Please!</Button>
                                         <Button btnType="Danger" clicked={() => props.mechanicalTentaclesHandler(1)}>No, I have changed my mind</Button>
                            </div>
                        </Delay>
                    </Aux>
                    : null}
                {props.mechanicalTentaclesContinue === 2 ?
                    <Aux>

                        <TypingEffect typingDelay={100} text={mechanicalTentaclesFirstRunMessage1} speed={props.speed} eraseDelay={1000000} />
                        <Delay wait={duration3}>
                            <div>
                                <br></br><Button btnType="Success" clicked={() => props.onObedienceStage3Counter(props.obedienceStage3Counter, 2)}>The Tentacles have been Deactivated</Button>
                            </div>
                        </Delay>
                    </Aux>
                    : null}
            </Aux>
        )
    } 
    else
    {
        if (props.mechanicalTentaclesToggle && props.mechanicalTentaclesContinue === 1) {
            mechanicalTentaclesRegularRunMessage = 'It does not matter what you think, ' + props.name + '\n' + mechanicalTentaclesRegularRunMessage
        }
        if (props.mechanicalTentaclesToggle && props.mechanicalTentaclesContinue === 3) {
             // eslint-disable-next-line      
             mechanicalTentaclesRegularRunMessage2 = 'We know what is best for you, ' + props.name + '\n\
                                                      Soon you will see things Our way\n' + mechanicalTentaclesRegularRunMessage2
        }
        let duration = 170 + (mechanicalTentaclesRegularIntroMessage.length * speed)
        let duration2 = 170 + (mechanicalTentaclesRegularRunMessage.length * speed)
        let duration3 = 170 + (mechanicalTentaclesRegularRunMessage1.length * speed)
        let duration4 = 170 + (mechanicalTentaclesRegularRunMessage2.length * speed)
        mechanicalTentaclesParagraph = (
            <Aux>
                {props.mechanicalTentaclesContinue === 0 ?
                    <Aux>

                        <TypingEffect typingDelay={100} text={mechanicalTentaclesRegularIntroMessage} speed={props.speed} eraseDelay={1000000} />
                        <Delay wait={duration}>
                            <div>
                                <br></br><Button btnType="Success" clicked={() => props.mechanicalTentaclesHandler(0)}>Yes, I am Ready!</Button>
                                         <Button btnType="Danger" clicked={() => props.mechanicalTentaclesHandler(1)}>I am not sure</Button>
                            </div>
                        </Delay>
                    </Aux>
                    : null}
                {props.mechanicalTentaclesContinue === 1 ?
                    <Aux>

                        <TypingEffect typingDelay={100} text={mechanicalTentaclesRegularRunMessage} speed={props.speed} eraseDelay={1000000} />
                        <Delay wait={duration2}>
                            <div>
                                <br></br><Button btnType="Success" clicked={() => props.mechanicalTentaclesHandler(0)}>I am Ready!</Button>
                            </div>
                        </Delay>
                    </Aux>
                    : null}
                {props.mechanicalTentaclesContinue === 2 ?
                    <Aux>

                        <TypingEffect typingDelay={100} text={mechanicalTentaclesRegularRunMessage1} speed={props.speed} eraseDelay={1000000} />
                        <Delay wait={duration3}>
                            <div>
                                <br></br><Button btnType="Success" clicked={() => props.mechanicalTentaclesHandler(0)}>Yes, It is what I want!</Button>
                                         <Button btnType="Danger" clicked={() => props.mechanicalTentaclesHandler(1)}>I think so...</Button>
                            </div>
                        </Delay>
                    </Aux>
                    : null}
                {props.mechanicalTentaclesContinue === 3 ?
                <Aux>

                    <TypingEffect typingDelay={100} text={mechanicalTentaclesRegularRunMessage2} speed={props.speed} eraseDelay={1000000} />
                    <Delay wait={duration4}>
                        <div>
                            <br></br><Button btnType="Success" clicked={() => props.onObedienceStage3Counter(props.obedienceStage3Counter, 2)}>Deactivate Tentacles</Button>
                        </div>
                    </Delay>
                </Aux>
                : null}
            </Aux>
        )
    }

    return (
        <div>
            <h3 className={classes.titles}>MECHANICAL TENTACLES</h3>
            <Sound url={Beta} playStatus={Sound.status.PLAYING} loop={true} />
            {mechanicalTentaclesParagraph}
        </div>
        
    )
}

const mapDispatchToProps = dispatch => {
    return {
        onObedienceStage3Counter: (stage3Counter, commandNumber) => dispatch(actions.ObedienceStage3Counter(stage3Counter, commandNumber)),
    }
}
export default connect(null, mapDispatchToProps)(mechanicalTentacles)