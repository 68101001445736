import React, { Component } from 'react'
// import TypingEffect from 'react-typing-effect';
// import Delay from 'react-delay'
import * as actions from '../../../../store/actions/index'
import Button from '../../../UI/Button/Button'
import Aux from '../../../../hoc/auxiliary/auxiliary'
import classes from '../../../../containers/HostsChamber/HostsChamber.module.css'
// import Input from '../../../UI/Input/Input'
import { connect } from 'react-redux';
import HostReinforcement from './Host_Reinforcement'
import HostSpeech from './Host_Speech'
import HostSuit from './Host_Suit'
import HostPleasure from './Host_Pleasure'
import HostInfection from './Host_Infection'
import Sound from 'react-sound'
import Bell from '../../../../assets/audio/bell.mp3'
import Metronome from '../../../../assets/audio/Metronome2.mp3' 
import Mantra from '../../../../assets/audio/mantralong.mp3'
import Ticking from '../../../../assets/audio/Ticking3.mp3'
import Beta from '../../../../assets/audio/beta.mp3'

class ObedienceStage1 extends Component {
    state = {
        hostSpeechContinue: false,
        hostSuitContinue: 0,
        hostInfectionContinue: false
    }

    componentWillMount = () => {
        this.props.onViewProfileOptions(this.props.token, this.props.userId)
    }

    obedienceStage2CompletedHandler = () => {
        this.setState({
            hostSpeechContinue: false,
            hostSuitContinue: 0,
            hostInfectionContinue: false
        })
        this.props.onObedienceStage2Completed(this.props.userId, this.props.token,
            this.props.host_reinforcement_command,
            this.props.host_speech_command,
            this.props.host_suit_command,
            this.props.host_pleasure_command,
            this.props.host_infection_command)
    }

    hostSpeechContinueHandler = () => {
        this.setState({hostSpeechContinue: true})
    }

    hostInfectionContinueHandler = () => {
        this.setState({hostInfectionContinue: true})
    }

    hostSuitContinueHandler = () => {
        let hostCoutner = this.state.hostSuitContinue + 1
        
        this.setState({hostSuitContinue: hostCoutner})
    }

    render() {

        let audio_option = null
        if(this.props.profileOptions.audio_option === '1'){
            audio_option = <Sound url={Bell} playStatus={Sound.status.PLAYING} loop={true}/>
        }else if(this.props.profileOptions.audio_option === '2'){
            audio_option = <Sound url={Metronome} playStatus={Sound.status.PLAYING} loop={true}/>
        }else if(this.props.profileOptions.audio_option === '3'){
            audio_option = <Sound url={Ticking} playStatus={Sound.status.PLAYING} loop={true}/>
        }else if(this.props.profileOptions.audio_option === '4'){
            audio_option = <Sound url={Mantra} playStatus={Sound.status.PLAYING} loop={true}/>
        }else if(this.props.profileOptions.audio_option === '5'){
            audio_option = <Sound url={Beta} playStatus={Sound.status.PLAYING} loop={true}/>
        }

        let obedience2Commands = null


        while (this.props.obedienceStage2Counter < this.props.obedienceCommandsArray.length) {
            if (this.props.obedienceStage2.obedience_stage2 === 0 || this.props.obedienceCommandsArray[this.props.obedienceStage2Counter] === 0) {
                //Host Reinforcement
                obedience2Commands =
                        <HostReinforcement
                            name={this.props.hostName}
                            speed={this.props.runSpeed}
                            title={this.props.title}
                            obedienceStage2Counter = {this.props.obedienceStage2Counter}
                            obedience_stage2={this.props.obedienceStage2.obedience_stage2} />
            } else if (this.props.obedienceStage2.obedience_stage2 === 1 || this.props.obedienceCommandsArray[this.props.obedienceStage2Counter] === 1) {
                //Host Speech
                obedience2Commands =
                        <HostSpeech
                            name={this.props.hostName}
                            speed={this.props.runSpeed}
                            title={this.props.title}
                            obedienceStage2Counter = {this.props.obedienceStage2Counter}
                            obedience_stage2={this.props.obedienceStage2.obedience_stage2}
                            hostSpeechContinueHandler = {this.hostSpeechContinueHandler}
                            hostSpeechContinue = {this.state.hostSpeechContinue} />
            } else if (this.props.obedienceStage2.obedience_stage2 === 2 || this.props.obedienceCommandsArray[this.props.obedienceStage2Counter] === 2) {
                //Host Suit
                obedience2Commands =
                        <HostSuit
                            name={this.props.hostName}
                            speed={this.props.runSpeed}
                            title={this.props.title}
                            obedienceStage2Counter = {this.props.obedienceStage2Counter}
                            obedience_stage2={this.props.obedienceStage2.obedience_stage2}
                            hostSuitContinueHandler = {this.hostSuitContinueHandler}
                            hostSuitContinue = {this.state.hostSuitContinue} />

            } else if (this.props.obedienceStage2.obedience_stage2 === 3 || this.props.obedienceCommandsArray[this.props.obedienceStage2Counter] === 3) {
                //Host Pleasure
                obedience2Commands =
                        <HostPleasure
                            name={this.props.hostName}
                            speed={this.props.runSpeed}
                            title={this.props.title}
                            obedienceStage2Counter = {this.props.obedienceStage2Counter}
                            obedience_stage2={this.props.obedienceStage2.obedience_stage2}/>        
            } else if (this.props.obedienceStage2.obedience_stage2 === 4 || this.props.obedienceCommandsArray[this.props.obedienceStage2Counter] === 4) {
                //Host Infection
                obedience2Commands =
                        <HostInfection
                            name={this.props.hostName}
                            speed={this.props.runSpeed}
                            title={this.props.title}
                            obedienceStage2Counter = {this.props.obedienceStage2Counter}
                            obedience_stage2={this.props.obedienceStage2.obedience_stage2}
                            hostInfectionContinueHandler = {this.hostInfectionContinueHandler}
                            hostInfectionContinue = {this.state.hostInfectionContinue} />
            }

            let errorMessage = null

            if (this.props.error) {
                errorMessage = (
                    <p className={classes.error}>{this.props.error}</p>
                )
            }


            return (
                <div>
                    {errorMessage}
                    {audio_option}
                    {obedience2Commands}
                </div>
            )
        }

        let errorMessage = null

        if (this.props.error) {
            errorMessage = (
                <p className={classes.error}>{this.props.error}</p>
            )
        }

        if (this.props.obedienceStage2Counter >= this.props.obedienceCommandsArray.length) {
            obedience2Commands = (
                <Aux>
                    <div>
                        <h3 className= {classes.titles}>HOST DEACTIVATION</h3>
                        <p>{this.props.hostName}, you have obeyed Us well. <br></br>
                        We are pleased of you, Host</p>
                        <h3><strong>Host Deactivate</strong></h3>
                        <Button btnType="Success" clicked={this.obedienceStage2CompletedHandler}>This host has been deactivated</Button>
                    </div>
                </Aux>
            )
        }


        return (
            <div>
                {errorMessage}
                {audio_option}
                {obedience2Commands}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        hostName: state.auth.accountDetails.name,
        runSpeed: state.auth.accountDetails.speed,
        userId: state.auth.userId,
        loading: state.obedienceReducer2.loading,
        hostRun: state.auth.accountDetails.Host_Runs,
        token: state.auth.token,
        title: state.auth.title,
        error: state.obedienceReducer2.error,
        obedienceStage2Valid: state.obedienceReducer2.obedienceStage2Valid,
        obedienceStage2: state.obedienceReducer2.obedienceStage2,
        obedienceCommandsArray: state.obedienceReducer2.obedienceStage2Commands,
        obedienceStage2Counter: state.obedienceReducer2.obedienceStage2Counter,
        host_reinforcement_command: state.obedienceReducer2.host_reinforcement_command,
        host_speech_command: state.obedienceReducer2.host_speech_command,
        host_suit_command: state.obedienceReducer2.host_suit_command,
        host_pleasure_command: state.obedienceReducer2.host_pleasure_command,
        host_infection_command: state.obedienceReducer2.host_infection_command,
        profileOptions: state.options.profileOptions,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onObedienceStage2Counter: (stage1Counter, commandNumber) => dispatch(actions.ObedienceStage2Counter(stage1Counter, commandNumber)),
        onViewProfileOptions: (token, userId) => dispatch(actions.viewProfileOptions(token, userId)),
        onObedienceStage2Completed: (userId, token,
            host_reinforcement_command,
            host_speech_command,
            host_suit_command,
            host_pleasure_command,
            host_infection_command) => dispatch(actions.ObedienceStage2End(userId, token,
                host_reinforcement_command,
                host_speech_command,
                host_suit_command,
                host_pleasure_command,
                host_infection_command))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ObedienceStage1)