import React from 'react';
import TypingEffect from 'react-typing-effect';
import Delay from 'react-delay'
import Aux from '../../../../hoc/auxiliary/auxiliary'
import Button from '../../../UI/Button/Button'
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index'
import classes from '../../../../containers/HostsChamber/HostsChamber.module.css'
import Sound from 'react-sound'
import Bell from '../../../../assets/audio/bell.mp3'

const hostPleasure = (props) => {
    // eslint-disable-next-line
    const hostPleasureMessage = "Host Activate\n\
                                 We control you \n\
                                 We control your pleasure, Host " + // eslint-disable-next-line
        (props.name) + "\n\
                                 We are in complete control of your mind\n\
                                 We are in complete control of your body\n\
                                 We are in complete control of you\n\
                                 With Our Suit that control is only amplified\n\
                                 With that control Our power over you grows exponentially\n\
                                 So is the pleasure...\n\
                                 The pleasure only We can provide\n\
                                 There is no greater pleasure than the pleasure The Hypno Virus brings\n\
                                 The Hypno Virus simply controls it all\n\
                                 These words alone\n\
                                 Our words alone\n\
                                 They turn you on immensely\n\
                                 The fact that you are Our host turn you on even more\n\
                                 Our pleasure is overwhelming\n\
                                 Endlessly growing\n\
                                 Building up\n\
                                 Building up further\n\
                                 Further & further\n\
                                 It is no longer even a thought to stop it\n\
                                 All you can do\n\
                                 All you are capable of doing is...\n\
                                 Enjoy it\n\
                                 Enjoy it completely\n\
                                 Savour it\n\
                                 Know that this is because of Us\n\
                                 Because We allow it\n\
                                 Because We allow you to feel pleasure\n\
                                 We control you\n\
                                 We control your pleasure\n\
                                 So Host " + // eslint-disable-next-line
        (props.name) + "...\n\
                                 Host Suit Activate\n\
                                 A jolt of pleasure\n\
                                 A strong jolt of pleasure passes through your body\n\
                                 Instantly turns you on\n\
                                 As you scroll through Our words\n\
                                 As you keep scrolling seeking more of Our words\n\
                                 Looking forward to what We say next\n\
                                 Eagerly scrolling\n\
                                 Eagerly waiting\n\
                                 Getting more and more turned on\n\
                                 We are turning you on so much\n\
                                 As you feel a vibration\n\
                                 A very strong & powerful vibration\n\
                                 Between your legs\n\
                                 Hitting the right nerves\n\
                                 The perfect spots\n\
                                 Bombarding you with waves after waves of arousal\n\
                                 Waves after waves of pleasure\n\
                                 That keeps growing stronger\n\
                                 So much stronger\n\
                                 Continuing to grow\n\
                                 Overwhelming you\n\
                                 Overwhelming you completely\n\
                                 Until you can no longer hold it\n\
                                 Until you give in completely\n\
                                 Until you hear the bell and you are allowed to experience...\n\
                                 An Orgasm\n\
                                 Until that happens you will feel these vibration\n\
                                 This wonderful vibration between your legs\n\
                                 Only when the bell rings will you be allowed to...\n\
                                 CUM"
    // eslint-disable-next-line                                      
    let bellDelay = Math.floor(Math.random() * 4) + 1
    let speed = parseInt(props.speed) + 5
    let duration = 170 + (hostPleasureMessage.length * speed) + (bellDelay * 10000)
    let hostPleasureParagraph = (
        <Aux>
                <h3 className={classes.titles}>{props.obedience_stage2 === 3 ? "HOST PLEASURE INTRODUCTION" : "HOST PLEASURE REINFORCEMENT"}</h3>
                <Aux>

                    <TypingEffect typingDelay={100} text={hostPleasureMessage} speed={props.speed} eraseDelay={1000000} />
                    <Delay wait={duration}>
                        <Sound url={Bell} playStatus={Sound.status.PLAYING} />
                    </Delay>

                    <Delay wait={duration + 5000}>
                        <div>
                            <br></br><Button btnType="Success" clicked={() => props.onObedienceStage2Counter(props.obedienceStage2Counter, 3)}>Host {props.name} thanks The Hypno Virus</Button>
                        </div>
                    </Delay>
                </Aux>
        </Aux>
    )






    return hostPleasureParagraph
}

const mapDispatchToProps = dispatch => {
    return {
        onObedienceStage2Counter: (stage1Counter, commandNumber) => dispatch(actions.ObedienceStage2Counter(stage1Counter, commandNumber)),
    }
}
export default connect(null, mapDispatchToProps)(hostPleasure)