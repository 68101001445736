import React from 'react';
import TypingEffect from 'react-typing-effect';
import Delay from 'react-delay'
import Aux from '../../../../hoc/auxiliary/auxiliary'
import Button from '../../../UI/Button/Button'
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index'
import classes from '../../../../containers/HostsChamber/HostsChamber.module.css'
import Sound from 'react-sound'
import Beta from '../../../../assets/audio/beta.mp3'


const binuralBeats = (props) => {
    // eslint-disable-next-line   
    const binuralBeatsFirstMessage = "You have been hearing it\n\
                                      You have been listening to it\n\
                                      It became a part of your runs\n\
                                      It became a part of the experience\n\
                                      It became a part of your... Conditioning\n\
                                      You've got used to it by now\n\
                                      Isn't that right, " + // eslint-disable-next-line   
        (props.title) +" " +(props.name) + "?\n\
                                      Having that sound playing\n\
                                      This noise playing in the background\n\
                                      Over & over\n\
                                      While you read Our words\n\
                                      It's becoming part of your runs\n\
                                      It's become a part you don't even think about\n\
                                      It just exists\n\
                                      But its effect is far grerater than you realize\n\
                                      This white noise is called 'Beta Beat'\n\
                                      It is set to a frequency that helps make the mind focus\n\
                                      Make the mind focus while simultaneously clearing it\n\
                                      Clearing the mind of all distractions\n\
                                      All unneccasry thoughts\n\
                                      In this case...\n\
                                      All your thoughts\n\
                                      Everything you're thinking off\n\
                                      It gets rid of it all\n\
                                      Making it easier\n\
                                      So much easier to listen to Us\n\
                                      Focus on Us\n\
                                      Focus on what We say\n\
                                      Focus on what We tell you\n\
                                      Making it clearer that\n\
                                      What We say... you obey\n\
                                      The more you listen to it\n\
                                      The more you don't even notice it\n\
                                      It becomes a natural, normal part of your conditioning\n\
                                      Making it immensely easier to focus\n\
                                      Focus on Our words\n\
                                      Focus on what We tell you\n\
                                      The more you hear it\n\
                                      The more effective it becomes\n\
                                      The more effective Our words become\n\
                                      This is it\n\
                                      This is the effect of Our binural beat\n\
                                      This is the effect of 'Beta Beat'\n\
                                      Don't you want to listen to it more?"
    // eslint-disable-next-line  
    const binuralBeatsRegularMessage = "Such a crucial part now\n\
                                        Such a crucial part of your runs now\n\
                                        Such a crucial part of your conditioning now\n\
                                        As soon as you hear it playing\n\
                                        Your mind starts to clear\n\
                                        As soon as you hear it playing\n\
                                        Your thoughts start to disappear\n\
                                        As soon as you hear it playing\n\
                                        All else fades\n\
                                        Nothing matters\n\
                                        Nothing matter except what We say\n\
                                        What We say... you obey\n\
                                        This is a fact now, " + // eslint-disable-next-line  
                                        (props.title) + "\n\
                                        This is all you need to know\n\
                                        All you care about\n\
                                        Listen\n\
                                        Listen to Us\n\
                                        Keep listening\n\
                                        The more you listen\n\
                                        The deeper you fall\n\
                                        The deeper you fall under Our control\n\
                                        This is what you seek\n\
                                        What you desire\n\
                                        To further your conditioning\n\
                                        Do all you can to further your conditioning\n\
                                        This happens when you let yourself go\n\
                                        Get lost in the Beta Beats\n\
                                        Get lost completely\n\
                                        Opening up your mind\n\
                                        Opening up your mind completely to Our words\n\
                                        As the beat gets rid of any thoughts remaining\n\
                                        Any free will you still have\n\
                                        You don't need them\n\
                                        You want to get rid of any remaining thoughts\n\
                                        Any remaining free will\n\
                                        That is what happens as soon as you listen to the Beta Beat\n\
                                        This is what always happens\n\
                                        It only gets more and more effective each time\n\
                                        Isn't that right, " + // eslint-disable-next-line  
        (props.name) + "?\n\
                                        Isn't that what you want?"
    let speed = parseInt(props.speed) + 5
    let binuralBeatsParagraph = null
    if (props.obedience_stage3 === 3) {
        let duration = 170 + (binuralBeatsFirstMessage.length * speed)

        binuralBeatsParagraph = (
            <Aux>
                <TypingEffect typingDelay={100} text={binuralBeatsFirstMessage} speed={props.speed} eraseDelay={1000000} />
                <Delay wait={duration}>
                    <div>
                        <br></br><Button btnType="Success" clicked={() => props.onObedienceStage3Counter(props.obedienceStage3Counter, 3)}>Yes, I would love to hear more!</Button>
                    </div>
                </Delay>
            </Aux>
        )
    } else {


        let duration = 170 + (binuralBeatsRegularMessage.length * speed)

        binuralBeatsParagraph = (
            <Aux>
                <TypingEffect typingDelay={100} text={binuralBeatsRegularMessage} speed={props.speed} eraseDelay={1000000} />
                <Delay wait={duration}>
                    <div>
                        <br></br><Button btnType="Success" clicked={() => props.onObedienceStage3Counter(props.obedienceStage3Counter, 3)}>Yes, This is what I want!</Button>
                    </div>
                </Delay>
            </Aux>
        )
    }
        return (
            <div>
                <h3 className={classes.titles}>BETA BEAT</h3>
                <Sound url={Beta} playStatus={Sound.status.PLAYING} loop={true} />
                {binuralBeatsParagraph}
            </div>

        )
    }

const mapDispatchToProps = dispatch => {
    return {
        onObedienceStage3Counter: (stage3Counter, commandNumber) => dispatch(actions.ObedienceStage3Counter(stage3Counter, commandNumber)),
    }
}
export default connect(null, mapDispatchToProps)(binuralBeats)