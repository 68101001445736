import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '../../../components/UI/Button/Button'
import classes from '../../../containers/HostsChamber/HostsChamber.module.css'
import Aux from '../../../hoc/auxiliary/auxiliary'
import Background from '../../../components/Background/Background'
import ConditioningProtocols from '../../../components/HostsChamber/AudioRuns/Conditioning_Protocols/Conditioning_Protocols'
import WebsiteAudio from '../../../components/Background/WebsiteAudio';
import TriggerIntroductions from '../../../components/HostsChamber/AudioRuns/Trigger_Introduction/Trigger_Introductions';
import TriggerReinforcement from '../../../components/HostsChamber/AudioRuns/Trigger_Reinforcement/Trigger_Reinforcement';
import * as actions from '../../../store/actions/index';

class AudioRun extends Component {
    state = {
        audioRun: true,
    }

    enterConditioningProtocolsHandler = () => {
        this.props.onViewConditioningProtocol()
        this.setState({ audioRun: false})
    }

    enterTriggerIntroductionHandler = () => {
        this.props.onViewTriggerIntroduction()
        this.setState({ audioRun: false})
    }

    enterTriggerReinforcementHandler = () => {
        this.props.onViewTriggerReinforcement()
        this.setState({ audioRun: false})
    }

    ReturnHandler = () => {
        this.props.onReturnHostsChamber()
    }

    render() {
        let audioRunForm = null
        
        if (this.state.audioRun) {
            audioRunForm = (
                <Aux>
                    <div className={classes.HostsChamber}>
                        <Background />
                        <WebsiteAudio />
                        <div className={classes.FormStyle}>
                            <h3>Experience The Power of Our Voice, {this.props.hostName}</h3>
                            <div className={classes.HostButtons}>
                                <Button btnType="Success" className={classes.HostChamberButton} clicked={this.enterConditioningProtocolsHandler}>Conditioning Protocols</Button><br></br>
                                <Button btnType="Success" className={classes.HostChamberButton} clicked={this.enterTriggerIntroductionHandler}>Triggers Introduction</Button><br></br>
                                <Button btnType="Success" className={classes.HostChamberButton} clicked={this.enterTriggerReinforcementHandler}>Triggers Reinforcement</Button><br></br>
                                <Button btnType="Danger" clicked={this.ReturnHandler}>Return To Hosts Chamber</Button>
                                <p className={classes.Note}>"WARNING: Some visuals may potentially trigger seizures for people with photosensitive epilepsy. Viewer discretion is advised."</p>
                                <p className={classes.Note}>"Note: Change background from Profile Options in Account section"</p>
                            </div>
                        </div>
                    </div>
                </Aux>
            )
        }
        
        if (this.props.conditioning_protocols) {
            audioRunForm = (
                <Aux>
                    <ConditioningProtocols />
                </Aux>
            )
        }

        if (this.props.trigger_introduction) {
            audioRunForm = (
                <Aux>
                    <TriggerIntroductions />
                </Aux>
            )
        }

        if (this.props.trigger_reinforcement) {
            audioRunForm = (
                <Aux>
                    <TriggerReinforcement />
                </Aux>
            )
        }

        return (audioRunForm)
    }
}

const mapStateToProps = state => {
    return {
        hostName: state.auth.accountDetails.name,
        runSpeed: state.auth.accountDetails.speed,
        hostRun: state.auth.accountDetails.Host_Runs,
        token: state.auth.token,
        audioRuns: state.hostsChamber.audioRuns,
        conditioning_protocols: state.hostsChamber.conditioning_protocols,
        trigger_introduction: state.hostsChamber.trigger_introduction,
        trigger_reinforcement: state.hostsChamber.trigger_reinforcement
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onReturnHostsChamber: () => dispatch(actions.onReturnHostsChamber()),
        onViewConditioningProtocol: () => dispatch(actions.onViewConditioningProtocol()),
        onViewTriggerIntroduction: () => dispatch(actions.onViewTriggerIntroduction()),
        onViewTriggerReinforcement: () => dispatch(actions.onViewTriggerReinforcement()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AudioRun)