import React, { useState } from "react";
import TextTransition from "react-text-transition";
import TypingEffect from 'react-typing-effect'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import Button from '../../../../UI/Button/Button'
import classes from '../../../../../containers/HostsChamber/AudioRuns/AudioRuns.module.css'
import * as scripts from '../../../../../shared/AudioRuns/TriggerIntroduction/TentaclesIntroduction/TentaclesIntroductionScripts'
import * as Delays from '../../../../../shared/AudioRuns/TriggerIntroduction/TentaclesIntroduction/TentaclesIntroductionDelay'
import Obey from '../../../../../assets/audio_runs/Trigger_Introduction/Tentacle_Introduction/Tentacle_Obey_Opening.mp3'
import Sound from 'react-sound'
import Delay from "react-delay";
import useInterval from 'react-useinterval';
import TentacleObeyActivate from './Tentacle_Obey_Activate'

const DELAY_TEXT = [4000]


function Trigger_Obey_Opening() {
  let [count, setCount] = useState(0);
  let [tentacle_obey, setProtocol] = useState(true)
  let [tentacle_activate, setActivateSequence] = useState(false)


  const increaseCount = () => {
    if (tentacle_obey && count < scripts.TENTACLES_OBEY_SCRIPT_OPENING.length) {
      setCount(count + 1);
    }
  };

  useInterval(increaseCount, Delays.TENTACLES_OBEY_OPENING_DELAY_TIME[count]);

  const enterEndingSequenceHandler = () => {
    setProtocol(tentacle_obey = false)
    setActivateSequence(tentacle_activate = true)

  }

  

  let tentacles_obey_opening_form = null

  if (tentacle_obey) {
    tentacles_obey_opening_form = (
      <Aux>
        {count < scripts.TENTACLES_OBEY_SCRIPT_OPENING.length ?
          <Aux >
            <Sound url={Obey} playStatus={Sound.status.PLAYING} />
            <Delay wait={DELAY_TEXT[count]}>
              <TextTransition
                text={<TypingEffect typingDelay={100} text={scripts.TENTACLES_OBEY_SCRIPT_OPENING[count]} speed={90} eraseDelay={1000000} />}
                Overflow
              />
            </Delay>
          </Aux>
          :
    <div className={classes.ButtonPadding}>
      <Button btnType="Success" clicked={enterEndingSequenceHandler}>Yes... I am ready</Button>
    </div>
  }
      </Aux >
    )
}

if(tentacle_activate){
    tentacles_obey_opening_form = <TentacleObeyActivate />
}

return (
  <Aux>
      {tentacles_obey_opening_form}
  </Aux>
)

}

export default Trigger_Obey_Opening