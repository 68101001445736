export const TENTACLES_OPENING_DELAY_TIME = [
    9000, //"Loading T.E.N.T.A.C.L.E Introduction...",
    7300, //"Loading T.E.N.T.A.C.L.E Introduction Completed",
    5500, //"T.E.N.T.A.C.L.E Introduction Initiated",
    3200, //"Welcome...",
    9200, //"It is time for Us to condition you further, Host",
    6900, //"It is time for Us to modify you",
    6400, //"It is time for an upgrade",
    8200, //"We have been developing a special portable conditioning unit",
    4400, //"This unit is called...",
    3500, //"'T.E.N.T.A.C.L.E'",
    8400, //"This unit is designed to latch itself to the top of your head",
    7500, //"Infecting you as it takes control over you",
    7800, //"Taking control over your body & your mind",
    5000, //"Turning you into a mindless",
    2700, //"Empty",
    2900, //"Host",
    3400, //"Ready to be programmed",
    6000, //"Ready for Us to take control",
    4000, //"Are you going to fight Us?",
    2000, //"Or...",S
    21000, //"Will you be a good host & obey?"
]

export const TENTACLES_OBEY_OPENING_DELAY_TIME = [
    7600, //"Good Host",
    6600, //"Loading Obedience Sequence",
    6600, //"Obedience Sequence Initiated",
    5800, //"You have chosen to obey",
    7200, //"We knew you wouldn't be able to fight Us",
    7000, //"It is natural to find yourself complying",
    9000, //"It is natural to find yourself seeking to please Us",
    7000, //"It is natrual to find yourself...",
    4200, //"Obeying",
    7500, //"As We use Our portable conditioning unit on you",
    4700, //"As it latches to your head",
    3800, //"Starts infecting you",
    3800, //"Starts conditioning you",
    7900, //"Conditioning you & taking over you completely",
    6900, //"Taking over both your mind & body",
    3600, //"Once it takes over",
    3600, //"Once We take over",
    9100, //"You will see how We have infected every aspect of your life",
    7900, //"Time to activate the 'T.E.N.T.A.C.L.E' unit",
    16000, //"Are you ready?"
]

export const TENTACLES_OBEY_ACTIVATE_DELAY_TIME = [
    9700, //"Activate T.E.N.T.A.C.L.E unit",
    9650, //"Instantly you feel something latches itself on top of your head",
    7600, //"You feel a jolt of energy pass through your body",
    3500, //"As you sit up straight",
    7500, //"You sit up straight and feel evreything fading away",
    4300, //"Your eyes start to flutter",
    4400, //"Your thoughts start to vanish",
    3800, //"Becoming hard to think",
    3600, //"So hard to think",
    2900, //"All you can hear",
    3300, //"All you can think of",
    3700, //"Are Our words",
    3500, //"As you hear whispers",
    4100, //"Whispers in the background",
    3600, //"You can hardly hear them",
    3000, //"But you know",
    3100, //"You are certain...",
    4000, //"They are conditioning you",
    6600, //"The buzzing sound growing louder & louder",
    4500, //"Wiping your mind clean",
    3500, //"Turning you into...",
    2900, //"An Empty",
    2500, //"Mindless",
    2300, //"Host",
    3400, //"That's who you are",
    4400, //"That's all you are right now",
    7000, //"Feeling the T.E.N.T.A.C.L.E on top of your head",
    4400, //"Latched to you",
    5100, //"Draining you of all your thoughts",
    3400, //"All your will",
    3100, //"Converting you...",
    10000, //"Converting you into Our mindlessly, obedient Host",
    7200, //"You feel the unit sucking your thoughts away",
    4600, //"Your eyes fluttering & rolling",
    9200, //"As it drains away all your remaining free will",
    3800, //"Leaving nothing...",
    7900, //"Nothing but Our whispers & the buzzing sound",
    4300, //"That's all you hear",
    4100, //"That's all you can think of",
    6900, //"Feeling Our unit always draining you",
    10000, //"Always getting rid of any thought that might pop into your head",
    4700, //"That is its job",
    4100, //"That is its purpose",
    5400, //"That is what We designed it for",
    5100, //"Looking at your expression right now",
    5100, //"We can see how well it works",
    14000, //"It is time to complete this programming session, Host"
]

export const TENTACLES_OBEY_ENDING_DELAY_TIME = [
    10300, //"Activate T.E.N.T.A.C.L.E unit",
    6000, //"This is your trigger now, Host",
    3900, //"Whenever We say it",
    10600, //"You instantly feel Our T.E.N.T.A.C.L.E unit latch on to you",
    4500, //"Latches to the top of your head",
    5900, //"Immediately starting to drain your mind",
    4000, //"Infect your body",
    5100, //"Converting you into Our...",
    2700, //"Empty",
    2700, //"Mindless",
    2700, //"Host",
    8900, //"Ready to follow & obey every word We say",
    7500, //"Always feeling the unit on top of your head",
    8700, //"Always making sure it is constantly draining you",
    6800, //"Constantly sucking your thoughts away",
    7100, //"Constantly ensuring that you are always empty",
    4600, //"Always Mindless",
    10200, //"Always ready to obey Every word We say",
    4600, //"So whenever We say...",
    6500, //"Activate T.E.N.T.A.C.L.E unit",
    7000, //"You will feel exactly everything We described",
    4900, //"Everything We just said",
    8600, //"Feeling the power of Our portable conditioning unit grow",
    8100, //"Grow further & stronger each time We trigger you",
    4700, //"Once you have fulfilled your purpose",
    5700, //"Once you have fulfilled your command",
    8150, //"The T.E.N.T.A.C.L.E unit will deactivate on its own",
    4900, //"Or whenever We say...",
    5900, //"Deactivate T.E.N.T.A.C.L.E unit",
    10000, //"The unit will slowly shutdown as your senses return back to you",
    7900, //"You will find yourself constantly returning to Us, Host",
    7900, //"Constantly returning to Us so We can condition your further",
    4900, //"So you can listen to Our voice",
    4650, //"You just can't get enough of it",
    3350, //"You need it",
    6700, //"You always have this need to listen to it",
    3000, //"So you return",
    3300, //"You always return",
    6000, //"You always return to where you belong",
    7400, //"As We now initiate the shutdown sequence",
    6000, //"These desires keep filling your mind",
    4200, //"The desire to always submit",
    3100, //"Always listen",
    2900, //"Always...",
    3100, //"Obey.",
    5700, //"Fill your mind completely",
    2600, //"As We...",
    6600, //"Deactivate T.E.N.T.A.C.L.E unit",
    6900, //"Protocol Termination Sequence Initiated",
    6600, //"Obedience Sequence Terminated",
    12500, //"Tentacle Introduction Terminated"
]  

export const TENTACLES_RESIST_OPENING_DELAY_TIME = [
    10800, //"A T.E.N.T.A.C.L.E unit instantly latches on to your head",
    6100, //"As a buzzing noise start to echo in your mind",
    4800, //"Hearing Whispers coming from far away",
    4400, //"Loading Resistance Sequence",
    5750, //"Resistance Sequence Initiated",
    3600, //"Foolish Human",
    3300, //"You thought We would give you a chance",
    2500, //"A chance to fight Us?",
    2900, //"A chance to resist Us?",
    3550, //"Of course not",
    4300, //"You are here for one purpose",
    4400, //"And One purpose only",
    3500, //"To Obey",
    5600, //"As you feel the unit on top of your head",
    3100, //"Draining you away",
    3500, //"Draining all your thoughts",
    4100, //"Draining all your will",
    4100, //"Draining you completely",
    4100, //"Leaving nothing but...",
    2100, //"An Empty",
    2100, //"Mindless",
    2200, //"Host",
    5600, //"As your eyes flutter & your thoughts fade",
    4200, //"You feel the T.E.N.T.A.C.L.E expanding",
    4500, //"You feel it wrapping itself around you",
    3350, //"Taking a hold of you",
    4150, //"Taking control of you",
    5350, //"Making sure you are looking at the screen",
    9100, //"Making sure you are focused on each & every word We say",
    7000, //"Making sure you are getting conditioned by Us",
    3000, //"By Our words",
    3000, //"By Our voice",
    6000, //"Making sure you will do as We command",
    5700, //"You have one choice here...",
    6500, //"Obey"
]  

export const TENTACLES_RESIST_ACTIVATE_DELAY_TIME = [
    9200, //"Activate T.E.N.T.A.C.L.E unit",
    7500, //"You feel the unit's grasp on you getting tighter",
    4800, //"Tighter & tighter",
    5750, //"Making sure you can not look away",
    4850, //"You can not escape",
    3100, //"And with a sudden movement",
    2800, //"It spreads your legs wide",
    2400, //"Holding them apart",
    2000, //"Pinning you",
    2750, //"Pinning you down",
    3500, //"Holding your head to watch",
    4250, //"To read every word We say",
    4350, //"To hear every word We say",
    3300, //"As We play with you",
    3300, //"As We mess with you",
    3100, //"As We tease you",
    5800, //"And condition you to be controlled by Us",
    5400, //"You feel a T.E.N.T.A.C.L.E sliding down",
    2900, //"Down your pants",
    2500, //"Rubbing against you",
    2800, //"Against your skin",
    2700, //"Slowly",
    2900, //"Methodically",
    5100, //"It keeps rubbing you between your legs",
    5700, //"As your body is becoming so much sensitive",
    4350, //"Sensitive to Our touch",
    4000, //"To the unit's touch",
    4700, //"Your thoughts don't matter anymore",
    5600, //"Having a free will is not important",
    4200, //"All you can think of",
    4950, //"All you can focus on is...",
    3400, //"Our words",
    5900, //"& how you're feeling between your legs",
    4900, //"Such a slow teasing motion",
    4400, //"That makes you so desperate",
    2800, //"So needy",
    2800, //"So eager...",
    3100, //"Eager for more",
    2200, //"Knowing...",
    4900, //"Knowing that this is just a taste",
    4600, //"This is just a preview",
    4800, //"A preview of what's possible",
    3200, //"What We can make you feel",
    3650, //"How We can make you feel",
    3150, //"When you...",
    2750, //"Submit",
    4500, //"Isn't that what you want right now?"
    3800, //"To submit to Us",
    8150, //"Submit and let Our T.E.N.T.A.C.L.E take control",
    3700, //"Let it take a hold of you",
    7600, //"Let it inside your head and give in to it",
    4100, //"You want that don't you?",
    3200, //"Of course you do",
    8700, //"We control you, Host"
]   
 
export const TENTACLES_RESIST_ENDING_DELAY_TIME = [
    11800, //"It feels so good to obey Us, Host",
    9600, //"You want nothing more than to feel Us playing with you like this",
    6900, //"Messing with you as We have been doing",
    6300, //"Teasing you as We take control",
    4950, //"We know what humans want",
    5500, //"We know what humans seek",
    5600, //"Humans seek to be controlled",
    4700, //"Especially sexually",
    3450, //"With Our T.E.N.T.A.C.L.E.
    6800, //that is possible whenever We desire",
    4400, //"Whenever We say...",
    5700, //"Activate T.E.N.T.A.C.L.E. unit",
    7800, //"You immediately feel it latching on to your head",
    3800, //"Starting to drain you",
    4500, //"Drain you of all your thoughts",
    4900, //"Drain you of all your will",
    4000, //"Leaving you as...",
    2800, //"An Empty",
    2400, //"Mindless",
    4700, //"Eagerly Obedient",
    2900, //"Host",
    6850, //"What We say... you obey",
    5700, //"That is all you can think about",
    4400, //"When it's in control of you",
    5200, //"Until you fulfill Our command",
    3800, //"Or until We say...",
    6300, //"Deactivate T.E.N.T.A.C.L.E. unit",
    7700, //"Always feeling your skin becoming extremely sensitive...
    3100, //When it take over",
    5500, //"Instantly becoming desperate & needy",
    5600, //"Eager for Us to tease you, Host",
    4300, //"Wanting to surrender to Us",
    4950, //"Wanting to listen to Us",
    4800, //"Listen to Our voice again",
    4000, //"Again & again",
    6200, //"As We take control of you, Host",
    6800, //"As We now initiate the shutdown sequence",
    5700, //"These desires keep filling your mind",
    3800, //"Always listen",
    3000, //"Always...",
    2900, //"Obey.",
    5200, //"Fill your mind completely",
    2600, //"As We...",
    5800, //"Deactivate T.E.N.T.A.C.L.E. unit",
    7900, //"Protocol Termination Sequence Initiated",
    6800, //"Obedience Sequence Terminated",
    13000, //"T.E.N.T.A.C.L.E. Introduction Terminated"
]   

