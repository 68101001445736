import React, { useState } from "react";
import TextTransition from "react-text-transition";
import TypingEffect from 'react-typing-effect'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import Button from '../../../../UI/Button/Button'
import * as scripts from '../../../../../shared/AudioRuns/TriggerReinforcement/ChloroformReinforcement/ChloroformReinforcementScripts'
import * as Delays from '../../../../../shared/AudioRuns/TriggerReinforcement/ChloroformReinforcement/ChloroformReinforcementDelay'
import Arousal_Effect from '../../../../../assets/audio_runs/Trigger_Reinforcement/Chloroform_Reinforcement/Chloroform_Amnesia_Effect.mp3'
import { ChloroformReinforcementEnd } from '../../../../../store/actions/AudioRuns/audioRunsIndex'
import Sound from 'react-sound'
import Delay from "react-delay";
import useInterval from 'react-useinterval';
import { connect } from 'react-redux'

const DELAY_TEXT = [4000]


function Chloroform_Amnesia_Effect({ userId, token, fast_chloroform, name, ChloroformReinforcementEnd }) {
    let [count, setCount] = useState(0);
    let [chloroform_amnesia_effect, setProtocol] = useState(true)

    const increaseCount = () => {
        if (chloroform_amnesia_effect && count < scripts.CHLOROFORM_AMNESIA_EFFECT.length) {
            setCount(count + 1);
        }
    };

    useInterval(increaseCount, Delays.CHLOROFORM_AMNESIA_EFFECT_DELAY[count]);


    const ChloroformArousalEffectHandler = () => {
        setProtocol(chloroform_amnesia_effect = false)
        if (fast_chloroform === true) {
            ChloroformReinforcementEnd(userId, token, 1, 0, 0, 0, 1)
        } else {
            ChloroformReinforcementEnd(userId, token, 0, 1, 0, 0, 1)

        }
    }

    let centerText = window.innerHeight / 2 - 55
    let texting = {

        // display: 'flex',
        // flexFlow: 'column',
        whiteSpace: 'pre-line',
        alignItems: 'center',
        fontWeight: 'bold',
        boxSizing: "border-box",
        margin: 'auto',
        marginBottom: '10px',
        padding: '30px 10px',
        width: '50%',
        paddingTop: centerText + 'px',
        color: 'White'

    }

    let texting2 = {

        // display: 'flex',
        // flexFlow: 'column',
        fontSize: '24px',
        whiteSpace: 'pre-line',
        alignItems: 'center',
        fontWeight: 'bold',
        boxSizing: "border-box",
        margin: 'auto',
        marginBottom: '10px',
        padding: '30px 10px',
        width: '50%',
        paddingTop: centerText +'px',
        color: 'White'

    }

    let chloroform_amnesia_effect_form = null

    if (chloroform_amnesia_effect) {
        chloroform_amnesia_effect_form = (
            <Aux>
                {count < scripts.CHLOROFORM_AMNESIA_EFFECT.length ?
                    <div style={texting}>
                        <Sound url={Arousal_Effect} playStatus={Sound.status.PLAYING} />
                        <Delay wait={DELAY_TEXT[count]}>
                            <TextTransition
                                text={<TypingEffect typingDelay={100} text={scripts.CHLOROFORM_AMNESIA_EFFECT[count]} speed={50} eraseDelay={1000000} />}
                                Overflow
                            />
                        </Delay>
                    </div>
                    :
                    <div style={texting2}>
                        <Button btnType="Success" clicked={ChloroformArousalEffectHandler}>Being Conditioned<br></br>Turns me on</Button>
                    </div>
                }
            </Aux >
        )
    }

    return (
        <Aux>
            {chloroform_amnesia_effect_form}
        </Aux>
    )

}

const mapStateToProps = state => {
    return {
        name: state.auth.accountDetails.name,
        userId: state.auth.userId,
        token: state.auth.token,
        fast_chloroform: state.chloroReinforce.fast_chloroform,
        normal_chloroform: state.chloroReinforce.normal_chloroform,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        ChloroformReinforcementEnd: (userId, token, Quick_Effect_Chloroform, Normal_Effect_Chloroform, Body_Play, Arousal_Effect, Amnesia_Effect) => dispatch(ChloroformReinforcementEnd(userId, token,
            Quick_Effect_Chloroform, Normal_Effect_Chloroform, Body_Play, Arousal_Effect, Amnesia_Effect)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Chloroform_Amnesia_Effect)