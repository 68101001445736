import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '../../../components/UI/Button/Button'
import ObedienceConditioning from './ObedienceConditioning/ObedienceConditioning'
import classes from '../../../containers/HostsChamber/HostsChamber.module.css'
import * as actions from '../../../store/actions/index';
import Aux from '../../../hoc/auxiliary/auxiliary';
import WebsiteAudio from '../../../components/Background/WebsiteAudio';

class ConditioningStages extends Component {
    state = {
        selectModule: true,
    }

    obedienceThemeHandler = () => {
        this.props.onObedienceStage3(this.props.userId, this.props.token)
        this.props.onObedienceStage2(this.props.userId, this.props.token)
        this.props.onObedienceStage1(this.props.userId, this.props.token)
        this.props.onObedienceStageStart()
    }

    ReturnHandler = () => {
        this.props.onReturnHostsChamber()
    }


    render() {
        let ModuleSelect = null

        if (this.props.conditioningStages) {
            ModuleSelect = (
                <Aux>
                    <WebsiteAudio />
                    <h2>Select Module</h2>
                    <p className={classes.smallText}>{this.props.hostName}, there is no resisting Us</p>
                    <div>
                        <Button btnType="Success" clicked={this.obedienceThemeHandler}>Obedience Module</Button> <br></br>
                        <Button btnType="Danger" clicked={this.ReturnHandler}>Return To Hosts Chamber</Button>
                    </div>
                </Aux>
            )
        }
        if (this.props.obedienceModule) {
            ModuleSelect = <ObedienceConditioning />
        }


        return (
            <div>
                {ModuleSelect}
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        hostName: state.auth.accountDetails.name,
        obedienceModule: state.obedienceReducer.obedienceModule,
        userId: state.auth.userId,
        token: state.auth.token,
        conditioningStages: state.hostsChamber.conditioningStages,

    }
}

const mapDispatchToProps = dispatch => {
    return {
        onObedienceStageStart: () => dispatch(actions.ObedienceStageStart()),
        onObedienceStage1: (userId, token) => dispatch(actions.ObedienceStage1(userId, token)),
        onObedienceStage2: (userId, token) => dispatch(actions.ObedienceStage2(userId, token)),
        onObedienceStage3: (userId, token) => dispatch(actions.ObedienceStage3(userId, token)),
        onReturnHostsChamber: () => dispatch(actions.onReturnHostsChamber()),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ConditioningStages)