import React from '../../../../../node_modules/react';
import TypingEffect from '../../../../../node_modules/react-typing-effect'
import Delay from '../../../../../node_modules/react-delay'
import Aux from '../../../../hoc/auxiliary/auxiliary'
import classes from '../../HostsRun/HostsRunComponent.module.css'


const deepener = (props) => {
    // let praiseChoice = 0
    // praiseChoice = Math.floor(Math.random() * props.praises.length);
    // let praise = props.praises[praiseChoice]
     
    // eslint-disable-next-line
    const deepenerMessage = (props.naming) + ', The more you read Our words\n\
                             The Deeper you fall\n\
                             The Deeper you fall\n\
                             The more you want to read Our words\n\
                             The more you read Our words\n\
                             The more you want to follow Us\n\
                             The more you follow Us\n\
                             The more you want to obey Us\n\
                             The more you obey Us\n\
                             The better you feel\n\
                             This is what a ' + // eslint-disable-next-line
                             (props.title) + ' wants\n\
                             This is what a ' + // eslint-disable-next-line
                             (props.title) + ' wants the most\n\
                             We are here to take you even deeper\n\
                             Take you so much further\n\
                             We are here to control you\n\
                             We are here to take over your mind, ' + // eslint-disable-next-line
                             (props.naming) + '\n\
                             We will take you deep using ' + // eslint-disable-next-line
                             (props.deepenerMethodName)
    let speed = parseInt(props.speed) +5
    let  duration = 170 + (deepenerMessage.length * speed)
    let deepenerParagraph = (
            <Aux>
                <h3 className= {classes.titles}>DEEPENER</h3>
                <Delay wait={5000}><TypingEffect typingDelay={100} text={deepenerMessage} speed={props.speed} eraseDelay={1000000} /></Delay>
                <Delay wait={duration+5000}><h4 className={classes.blinking} onClick={props.deepener}>I need to fall deeper for The Hypno Virus</h4></Delay>
            </Aux>
        )
    





    return deepenerParagraph
}


export default deepener