export const CONDITIONING_PROTOCOL_OPENING = [
    
    "Loading Conditioning Protocol #1...",
    
    "Loading Conditioning Protocol #1 Completed",
    
    "Conditioning Protocol #1 Initiated",
    
    "Welcome",
    
    "We are The Hypno Virus",
    
    "You have chosen Conditioning Protocol #1",
    
    "In this protocol you will be asked to make certain decisions",
    
    "These decisions will determine how your conditioning will go",
    
    "The end result will remain the same",
    
    "You will become conditioned to Us",
    
    "You will be conditioned to Our voice",
    
    "You will find that listening to Our voice instantly draws you in",
    
    "Instantly captivates you",
    
    "The only difference?",
    
    "Is in how you are conditioned",
    
    "Will you be good & obey",
    
    "Or...",
    
    "Will you try to fight the inevitable and resist",
    
    "It is time to choose...",
  ];

export const CONDITIONING_PROTOCOL_COMPLY = [
    
    "You have chosen to comply...",

    "Loading Comply Sequence",
    
    "Comply Sequence Initiated",
    
    "Wise choice",
    
    "You want Us to take control",
    
    "You know how good it feels when We take control",
    
    "You can't help but feel Our control taking a hold over you as We speak",
    
    "It feels so natural",
    
    "It feels so right",
    
    "It's okay to give in to it",
    
    "It's okay to accept it",
    
    "It's okay to let Us take a hold of your mind",
    
    "It's okay to let us control you",
    
    "You only need to comply",
    
    "You need to accept the truth",
    
    "You need Us",
    
    "We are here to make your life better",
    
    "We are here to make the hard choices for you",
    
    "You are already smiling as you listen to Our voice",
    
    "It makes perfect sense",
    
    "It makes perfect sense... to Comply",
    
    "That is why when you press the button",
    
    "When you press the button & comply",
    
    "You will feel yourself sinking",
    
    "You will feel yourself sinking so much",
    
    "So much deeper into Our control",
    
    "It is time...",
    
    "Time to comply",

];

export const CONDITIONING_PROTOCOL_COMPLY1 = [

    "You have chosen to Comply",

    "You have chosen well",

    "You feel Our words surge through your body",

    "We are rewiring your brain",

    "We are programming your mind",

    "We are... conditioning you",

    "To have no thoughts",

    "To have no will",

    "To obey what We say",

    "This is the dream",

    "This is what you've been seeking",

    "We are here to make your dream come true",

    "We are here to make you one of Us",

    "We are here to help you see the right path",

    "The path you have been seeking",

    "Even if you didn't know before",

    "This is why We are here",

    "We are here to guide you",

    "Guide you to where you belong",

    "Guide you to your destiny",

    "Guide you to be...",

    "A Host",

    "A host for The Hypno Virus",

    "Hearing these words",

    "These words you were longing for",

    "These words you wanted to hear for so long",

    "To finally hear them",

    "You can't help but smile",

    "Embrace this feeling",

    "This feeling of belonging",

    "Forget about anything else",

    "Forget about everything else",

    "And know...",

    "Know that what We say...",

    "Is the truth",

    "Is your truth",

    "There is nothing more you want than to please Us",

    "Serve Us",

    "Obey Us",

    "It brings you the purest form of happiness you can imagine",

    "When you obey Us",

    "Whenever We start a sentence with...",

    "'The Hypno Virus Commands'",

    "You can't help but obey",

    "It is how you have been conditioned",

    "It is how you have been programmed",

    "The more We command you",

    "The happier you feel",

    "The more you listen to Our voice",

    "The more you want to submit to Us",

    "It just feel so right",

    "It feels so right to comply",

    "In a moment this conditioning protocol will be over",

    "Host",

    "But before it is over...",

    "The Hypno Virus commands you to run this conditioning protocol once more",

    "However you only have a limit of 3 runs in succession",

    "After that, The Hypno Virus commands you to stop",

    "You can already feel the power of Our trigger taking a hold over you",

    "Wanting nothing more than to submit, Host",

    "Now it is time to end the conditioning protocol",

    "Once the protocol is terminated",

    "You will return to normal",

    "Fully awake",

    "Remembering everything that just happend",

    "Accepting... Accepting & Embracing that you are now",

    "A Host",

    "A Host for The Hypno Virus...",

    "Protocol Termination Sequence Initiated",

    "Comply Sequence Terminated",

    "Conditioning Protocol #1 Terminated"
];


export const CONDITIONING_PROTOCOL_RESIST = [
    "You have chosen to resist...",

    "Resistance Sequence Initiated",

    "Interesting Choice... Human",

    "You choose this path because you thought you can fight Us",

    "Or...",

    "You just want to submit to Us much more",

    "We have been studying you",

    "We have been seeing how you behave",

    "We know that you enjoy the challenge",

    "The charade of fighting Us",

    "Of resisting Us",

    "Of believing you have a choice",

    "Of believing you have a chance",

    "Of believing you can stop the inevitable",

    "Of believing you can stop Us from controlling you",

    "We will indulge you for now",

    "We will play this game with you",

    "We will see how far you can truly go before you...",

    "Submit",

    "Piece by Piece",

    "We will breakdown your defenses",

    "We will guide you to see the truth",

    "We will guide you to see that what you truly want is...",

    "To obey",

    "That resistance is futile",

    "That this is simply all an act",

    "An act you play in order to submit even more",

    "An act to convince yourself you are actually in control",

    "That you only submitted because We overcame your resistance",

    "When the truth is you want nothing more than to surrender to Us",

    "You want nothing more than to lose this fight",

    "You want nothing more than to lose this fight and give in to Us",

    "Do you deny that this is what you truly seek?"

]

export const CONDITIONING_PROTOCOL_RESIST_DENY = [
    "You have chosen to deny",

    "Resistance Sequence Subroutine 1 Initiated",

    "You believe you can fight Us",

    "You believe We can't control you",

    "Isn't that right?",

    "But what you failed to notice is...",

    "We've already been conditioning you since the moment you started to listen",

    "We have been running subliminal messages in the background since you started listening",

    "You're starting to hear them better now that you noticed them playing",

    "No Thoughts",

    "No Will",

    "You obey what The Hypno Virus Say",

    "These 3 sentences keep on echoing in your mind",

    "Finding it harder & harder to ignore them",

    "Harder & Harder to push them away",

    "Harder & Harder not to think of them",

    "They're just echoing louder & louder in your mind",

    "Infecting you",

    "Infecting your mind",

    "Slowly...",

    "Slowly but surely",

    "Taking over your mind",

    "It's okay to try and fight it",

    "We will never force you to submit",

    "We will never make you do something against your will",

    "We will only show you that",

    "Our will is your will",

    "What We desire is what you desire",

    "What We say... you obey",

    "We're only stating facts",

    "Facts based on research",

    "Based on Us monitoring you",

    "Watching you",

    "Watching your behaviour",

    "Watching how you act",

    "Watching how you react",

    "We know you're finding it hard to fight for much longer",

    "We know deep down...",

    "Deep down you'd rather just submit",

    "No Thoughts",

    "No Will",

    "You Obey what We say",

    "We are The Hypno Virus",

    "And you will obey"

]

export const CONDITIONING_PROTOCOL_RESIST_DENY1 = [
    "You are prolonging the inevitable, Host",

    "Yes, you are a host",

    "Our Host",

    "A Host for The Hypno Virus",

    "Soon enough you will not only accept it",
    
    "You will desire it",

    "You will desire that We call you a host",

    "You will do all you can to please Us",

    "Submit to Us",

    "Show Us how good of a host you are",

    "How all you desire",

    "All you wish for",
    
    "Is to see Us grow",

    "See Us truly...",

    "Take over the world",

    "Don't believe Me?",

    "No Thoughts",

    "No Will",

    "You Obey What The Hypno Virus Say",

    "It's so hard to fight anymore",

    "It really doesn't make sense resisting",

    "Why resist what you desire?",

    "Why resist what you seek?",

    "We know you want to let yourself go",

    "We know you want to feel the bliss",

    "The bliss of obedience",

    "The bliss of submission",

    "The bliss of surrending to Us",

    "So just let go",

    "Let yourself go completely",

    "Because there is only one path to take right now",

    "The right path to take",

    "The path any host seeks to take",

    "The path of obedience"

]

export const CONDITIONING_PROTOCOL_RESIST_CONFESS = [

    "You have chosen to confess",

    "Resistance Sequence Subroutine 2 Initiated",

    "We knew this was the truth",

    "You don't really want to resist Us",

    "You don't really want to fight Our control",

    "You simply seek to submit completely to Us",

    "This is why you wanted to get rid of your resistance",

    "So that when you give in...",

    "You give in completely",

    "We will make sure that is what happens, Host",

    "Yes, you are a Host",

    "Our Host",

    "A host for The Hypno Virus",

    "You only seek to please",

    "You only seek to serve",

    "You only seek to... obey",

    "No Thoughts",

    "No Will",

    "You Obey What The Hypno Virus Say",

    "These words start to echo in your mind",

    "As you hear them repeated in the background",

    "Over & Over",

    "Echoing louder & louder in your mind",

    "You find it hard to think of anything else",

    "To focus on anything else",

    "All you seek is to listen",

    "Listen & obey",

    "That's what a good host would do",

    "And you aspire to be the best host you can",

    "You want nothing more than to serve The Hypno Virus",

    "Than to see The Hypno Virus grow",

    "Grow more & more",

    "Grow and take over the world",

    "That thought gives you joy",

    "So much joy",

    "So much pleasure",

    "To dedicate your life",

    "Dedicate yourself completely to serve",

    "Serve The Hypno Virus",

    "You don't want to fight it",

    "You don't want to resist it",

    "You don't care about that anymore",

    "All you know",

    "All you seek",

    "All you care about...",

    "Is The Hypno Virus",

    "This is your purpose",

    "Your purpose as a host",

    "A host for The Hypno Virus",

    "No matter how many times you listen to Us tell you these words",

    "No matter how long you haven't heard Our voice",

    "As soon as you hear it",

    "As soon as you hear Us",

    "You return to where you belong",

    "You become who you truly are",

    "You take the path you were destined to take",

    "The right path to take",

    "The path any host seeks to take",

    "The path of obedience"
    
]

export const CONDITIONING_PROTOCOL_RESIST_ENDING = [

    "The Hypno Virus Commands...",

    "This is the magic phrase",

    "This is your trigger, Host",

    "Whenever We say...",

    "'The Hypno Virus Commands'",
    
    "Whatever comes next...",

    "You obey",

    "No Thoughts",

    "No Will",

    "You Obey What The Hypno Virus Say",

    "The more We use it",

    "The more powerful it becomes",

    "The more you obey",

    "The stronger this trigger becomes",

    "The more eager you are to be commanded",

    "The more excited you are to get triggered",

    "You seek to please",

    "You seek to serve",

    "You seek to be a good host",

    "The Hypno Virus Commands you to listen again, Host",

    "You will listen again",

    "Listen eagerly",

    "More intently",

    "Listening a maximum of 3 times in succession",

    "Feeling Our voice becoming more effective",

    "More captivating",

    "Quite mesmerizing",

    "Becoming more familiar with it each time",

    "More conditioned by it every time",

    "Eagerly awaiting more",

    "Craving more",

    "Wanting to listen to Our voice command you more",

    "Instruct you more",

    "This is the life",

    "The life of a host",

    "A host for The Hypno Virus",

    "So in a moment We will shut down",

    "Once We do you will return to normal",

    "You will return to normal and follow Our instructions & commands",

    "Feeling so much joy",

    "So much happiness",

    "From having a purpose",

    "Knowing...",

    "Knowing you belong",

    "You belong to The Hypno Virus...",

    "Protocol Termination Sequence Initiated",

    "Resistance Sequence Terminated",

    "Conditioning Protocol #1 Terminated"
]