import React, { Component } from 'react';
import Aux from '../../../../hoc/auxiliary/auxiliary';
import classes from '../HypnosisShowMenu.module.scss'
// import classes2 from '../ExperienceMenu/ExperienceMenu.module.scss'
import HypnosisShow from '../../../../assets/Images/Blank.png'
// import TheEnd from '../StartingMenu'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/MindMeltingExperience/TheGreatOHypnosisShow/index';

class HypnosisShowConclusion extends Component {

    state = {
        conclusion: true,
        // end: false
    }

    TheEndHandler = () => {
        this.props.onShowEnds()
        // this.setState({ conclusion: false, end: true })
    }


    render() {
        let HypnosisShowForm = null
        if (this.state.conclusion) {
            HypnosisShowForm = (
                <html style={{ backgroundImage: `url(${HypnosisShow})` }}>
                    <div className={classes.storyFrom}>
                        <p>
                        Your imagination runs wild with thoughts as you make your way to the exit definitely grabbing a flyer and taking a minute to register or at least attempt to register what happened. <br></br>
                        <i>Words can not describe how you feel. </i><br></br>
                        Truly one of the most amazing experience you’ve ever had as you look at the number for booking & calls, memorize it & put the poster in your pocket thinking to yourself <br></br>
                        <i>“I’m definitely doing this again and who knows maybe next time I’ll volunteer”</i>
                        </p>
                        <form className={classes.centered} onClick={this.TheEndHandler}>
                            <h4 className={classes.storyButtonSize}>The End</h4>
                        </form>
                    
                    </div>
                </html>
            )
        }
        // if (this.state.end) {
        //     HypnosisShowForm = (
        //         <TheEnd />
        //     )
        // }

    

        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {HypnosisShowForm}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >




        )
    }
}


const mapStateToProps = state => {
    return {
        showStart: state.hypnosisShow.showStart,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowEnds: () => dispatch(actions.hypnosisShowEnd()),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(HypnosisShowConclusion);