import * as conditioningStagesTypes from '../conditioningStagesTypes'
import axios from '../../../../axios'

export const ObedienceStageStart = () => {
    return {
        type: conditioningStagesTypes.OBEDIENCE_STAGE_START
    }
}

export const ObedienceStage1Success = (obedience_stage1) => {

    if(obedience_stage1.obedience_stage1 > 4){
        let obedienceStage1CommandsNum = Math.floor(Math.random() * 3) + 1
        let obedience1counter = 0
        let obedienceCommandsArray = []
        while (obedience1counter < obedienceStage1CommandsNum) {
            let commandNumber = Math.floor(Math.random() * 5)
            if (!obedienceCommandsArray.includes(commandNumber)) {
                obedienceCommandsArray.push(commandNumber)
                obedience1counter++
            }
        }
        return {
            type: conditioningStagesTypes.OBEDIENCE_STAGE_1_SUCCESS,
            obedience_stage1: obedience_stage1,
            obedienceStage1Commands: obedienceCommandsArray
        }
    }
    else if (obedience_stage1.obedience_stage1 <= 4){
        return {
            type: conditioningStagesTypes.OBEDIENCE_STAGE_1_SUCCESS,
            obedience_stage1: obedience_stage1,
            obedienceStage1Commands: [5]
        }
    }
}

export const ObedienceStageFailed = (error) => {
    return{
        type: conditioningStagesTypes.OBEDIENCE_STAGE_FAILED,
        error: error
    }
}

export const ObedienceStage1 = (userId, token) => {
    return dispatch => {
        // dispatch(ObedienceStageStart());
       
        axios.get('/Runs/'+userId+'/Conditioning_Stages/Obedience_Stage_1/.json?auth=' + token)
        
            .then( response => {
                // dispatch(ObedienceStageStart());
                if(response.data === null){
                    let obedience_stage1 = {
                        obedience_stage1: 0,
                        typing_command: 0,
                        green_text_reinforcement_command: 0,
                        we_are_watching_command: 0,
                        resistance_is_futile_command: 0,
                        instant_trigger_command:0
                    }
                    axios.put('/Runs/'+userId+'/Conditioning_Stages/Obedience_Stage_1/.json?auth=' + token, obedience_stage1)
                    .then(response => {
                        dispatch(ObedienceStage1Success(response.data))
                    })
                    .catch(error => {
                        dispatch(ObedienceStageFailed(error.message.data))
                    })
                    
                } else{
                    dispatch(ObedienceStage1Success(response.data))
                }
                       
            })
            .catch(error => {
                dispatch(ObedienceStageFailed(error.message))
            })
    }
}

export const ObedienceStage1Counter = (stage1Counter, commandNumber) => {
    return{
        type: conditioningStagesTypes.OBEDIENCE_STAGE_1_COUNTER,
        obedienceStage1Counter: stage1Counter + 1,
        commandNumber: commandNumber
    }
}

export const ObedienceStage1End = (userId, token, typing_command, green_text_reinforcement_command, we_are_watching_command, resistance_is_futile_command, instant_trigger_command) => {
    return dispatch => {
        dispatch(ObedienceStageStart());
       
        axios.get('/Runs/'+userId+'/Conditioning_Stages/Obedience_Stage_1/.json?auth=' + token)
        
            .then( response => {
                dispatch(ObedienceStageStart());
                    let updatedObedience_stage1 = {
                        obedience_stage1: response.data.obedience_stage1 + 1,
                        typing_command: response.data.typing_command + typing_command,
                        green_text_reinforcement_command: response.data.green_text_reinforcement_command + green_text_reinforcement_command,
                        we_are_watching_command: response.data.we_are_watching_command + we_are_watching_command,
                        resistance_is_futile_command: response.data.resistance_is_futile_command + resistance_is_futile_command,
                        instant_trigger_command: response.data.instant_trigger_command + instant_trigger_command
                    }
                    axios.put('/Runs/'+userId+'/Conditioning_Stages/Obedience_Stage_1/.json?auth=' + token, updatedObedience_stage1)
                    .then(response => {
                        dispatch(ObedienceStage1Completed())
                    })
                    .catch(error => {
                        dispatch(ObedienceStageFailed(error.message.data))
                    })    
                       
            })
            .catch(error => {
                dispatch(ObedienceStageFailed(error.message))
            })
    }
}

export const ObedienceStage1Completed = () => {
    return {
        type: conditioningStagesTypes.OBEDIENCE_STAGE_1_COMPLETED,
    }
}

export const ObedienceStage1Start = () => {
    return {
        type: conditioningStagesTypes.OBEDIENCE_STAGE_1_START,
    }
}