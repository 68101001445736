import React, { Component } from 'react';
import Aux from '../../../hoc/auxiliary/auxiliary';
import classes from './HypnosisShowMenu.module.scss'
// import classes2 from '../ExperienceMenu/ExperienceMenu.module.scss'
import HypnosisShow from '../../../assets/Images/Half.png'
import HypnosisShowStart from './HypnosisShowStart'
import HypnosisChapterSelect from './HypnosisShowChapterSelect'
import StorySelect from '../ExperienceMenu/ExperienceMenu'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/MindMeltingExperience/TheGreatOHypnosisShow/index';

class StartingMenu extends Component {

    state = {
        startStory: false,
        chapterSelect: false,
        storySelect: false
    }

    storyStartHandler = () => {
        this.props.onShowStarts()
        // this.setState({ showMenu: false, startStory: true })
    }

    chapterSelectHandler = () => {
        // this.props.onShowStarts()
        this.setState({ showMenu: false, chapterSelect: true })
    }

    storySelectHandler = () => {
        // this.props.onShowStarts()
        this.setState({ showMenu: false, storySelect: true })
    }
    render() {
        let HypnosisShowMenuForm = null
        if (this.props.showStart) {
            HypnosisShowMenuForm = (
                <html style={{ backgroundImage: `url(${HypnosisShow})` }}>
                    
                    <div className={classes.Experiences} >
                        <form  onClick={this.storyStartHandler} className={classes.buttonSize}>
                            <h4 >Start Story</h4>
                        </form>
                        <form onClick={this.chapterSelectHandler} className={classes.buttonSize}>
                            <h4 >Select Chapter</h4>
                        </form>
                        <form  onClick={this.storySelectHandler} className={classes.buttonSize}>
                            <h4 >Story Select</h4>
                        </form>
                    </div>
                </html>)
        }
        if (this.props.startStory) {
            HypnosisShowMenuForm = (
                <HypnosisShowStart />
            )
        }
        if(this.state.chapterSelect){
            HypnosisShowMenuForm = (
                <HypnosisChapterSelect />
            )
        }

        if(this.state.storySelect){
            HypnosisShowMenuForm = (
                <StorySelect />
            )
        }

        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {HypnosisShowMenuForm}
                       </FullpageSection>
                   </FullPageSections>
                </Fullpage >

            </Aux >




        )
    }
}


const mapStateToProps = state => {
    return {
        showStart: state.hypnosisShow.showStart,
        startStory: state.hypnosisShow.startStory
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowStarts: () => dispatch(actions.hypnosisShowStart()),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(StartingMenu);