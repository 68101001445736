import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import TypingEffect from 'react-typing-effect'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import classes from '../../TextToSpeech.module.css';
import { connect } from 'react-redux'
import Button from '../../../../UI/Button/Button'
import Delay from 'react-delay'
import { useSpeech, useRecognition } from 'react-web-voice';

const Control = (props) => {
    const [messageToSpeak, setMessageToSpeak] = React.useState('');
    // const [startConv, setConvState] = useState(false)
    const [isListening, setIsListening] = useState(false);
    const [endConv, setEndConv] = useState(false);
    const [controlStage, setControlStage] = useState(0);
    const [prevControlStage, setPrevControlStage] = useState(0);
    // eslint-disable-next-line
    const { transcripts, listen } = useRecognition();

    // eslint-disable-next-line
    let AffirmationStart = (props.name) + " You will only reply with 'Yes'\n\
                              As We ask you questions\n\
                              Talk to you\n\
                              State many facts & tell you the truth\n\
                              All you will do is confirm & agree with what We say\n\
                              You will find yourself nodding along with Our words\n\
                              Fully accepting & agreeing with what We say\n\
                              Are you ready " + (props.name) + "?"
    let duration = 200 + (AffirmationStart.length * 50)
    // eslint-disable-next-line
    let Affirmation1 = "You are under Our control\n\
                          We have conditioned you\n\
                          We have programmed you\n\
                          We have brainwashed you\n\
                          We have trained you\n\
                          We are mind controlling you\n\
                          We are mind controlling you and you want nothing more then to submit further for Us\n\
                          Isn't that right " + (props.name) + "?"
    let duration1 = 200 + (Affirmation1.length * 55)
    // eslint-disable-next-line
    let Affirmation2 = (props.name) + " you don't want to resist Us do you?\n\
                          You don't want to fight Us\n\
                          You want to read Our words\n\
                          Listen to Our voice\n\
                          Sink deeper into Our control & submit further\n\
                          You want to be good for Us\n\
                          You seek Our approval\n\
                          You seek to serve Us\n\
                          Isn't that right " + (props.name) + "?"
    let duration2 = 200 + (Affirmation2.length * 58)
    // eslint-disable-next-line
    let Affirmation3 = "You feel your whole body melting\n\
                          Melting as you listen to Us\n\
                          Feel Our words filling your mind\n\
                          Filling your mind completely\n\
                          Getting rid of all your thoughts\n\
                          Your free will & inhibitions\n\
                          Leaving you empty for Us to fill you with Our words\n\
                          Telling you the truth\n\
                          Stating the facts\n\
                          Continuously reinforcing your role\n\
                          Your role as a servant\n\
                          A mindless obedient servant\n\
                          A host\n\
                          A host for The Hypno Virus\n\
                          Isn't that what you are " + (props.name) + "?"
    let duration3 = 200 + (Affirmation3.length * 58)
    // eslint-disable-next-line
    let Affirmation4 = "A host that knows it belongs to Us\n\
                          It belongs to The Hypno Virus\n\
                          Nodding & smiling to what We say\n\
                          You feel good\n\
                          So good & happy\n\
                          So happy to belong\n\
                          To be Our host & belong to Us\n\
                          You want nothing more\n\
                          Nothing more than to serve Us\n\
                          Nothing more than to obey Us\n\
                          You want to keep on submitting\n\
                          Submitting & falling deep under\n\
                          Isn't that right " + (props.name) + "?"
    let duration4 = 200 + (Affirmation4.length * 58)
    // eslint-disable-next-line
    let Affirmation5 = (props.name) + " you find yourself regularly thinking of Us\n\
                          Regularly thinking of who you are\n\
                          Regularly thinking of your role & what your purpose is\n\
                          Regularly thinking of Us & smiling\n\
                          Smiling wide & feeling happy\n\
                          Feeling happy to belong to Us\n\
                          " + (props.name) + " are you ready to obey Us?"
    let duration5 = 200 + (Affirmation5.length * 58)
    // eslint-disable-next-line
    const Command1 = "We command you to message Us & share with Us your experience\n\
                      Tell Us in full details how you felt & how deep you went for Us"
    let duration6 = 200 + (Command1.length * 58)
    // eslint-disable-next-line
    const Command2 = "We command you to condition yourself further & fall deeper under Our control"
    let duration7 = 200 + (Command2.length * 58)
    // eslint-disable-next-line
    const Command3 = "We command you to take off your cloth\n\
                      Kneel on the floor\n\
                      Spread your legs\n\
                      Touch yourself while repeating\n\
                      'I belong to The Hypno Virus'\n\
                      Orgasming only when you fully accept that to be the truth\n\
                      You belong to The Hypno Virus"
    let duration8 = 200 + (Command3.length * 58)
    // eslint-disable-next-line
    const unclear = 'You were unclear ' + (props.name) + "\n\
                     Reply with one of the following to continue\n\
                    'Affirmative' or 'Yes'"
    let duration9 = 200 + (unclear.length * 70)

    const messageInputHandler = (evt) => {
        setMessageToSpeak(evt.target.value);
    };

    let voice = ''
    if (props.profileOptions.text_to_speech_voice === '1' || props.profileOptions.text_to_speech_voice === '2') {
        voice = "Microsoft David Desktop - English (United States)"
    } else if (props.profileOptions.text_to_speech_voice === '3' || props.profileOptions.text_to_speech_voice === '4') {
        voice = "Microsoft Zira Desktop - English (United States)"
    }
    // eslint-disable-next-line
    const { messages, speak } = useSpeech({ voice: voice });

    const speakButtonHandler = async (text) => {
        setMessageToSpeak('');
        // eslint-disable-next-line
        const utterance = await speak({
            text: text,
            volume: 1,
            rate: 0.5,
            pitch: 0.01
        });
    };

    const speakClickHandler = async () => {
        setIsListening(true);
        const recognizedMessage = await listen();
        console.log(recognizedMessage)
        setTopicAnswerHandler(recognizedMessage)

    };

    const endConversation = () => {
        setEndConv(true);

    };

    const setTopicAnswerHandler = (recognizedMessage) => {
        setIsListening(false);
        if (String(recognizedMessage).toLowerCase() === 'affirmative' || String(messageToSpeak).toLowerCase() === 'affirmative') {
            if (controlStage === 9) {
                setControlStage(0)
                setPrevControlStage(0)
                speakButtonHandler(String(AffirmationStart))
            }
        } else if (String(recognizedMessage).toLowerCase() === 'yes' || String(messageToSpeak).toLowerCase() === 'yes') {
            if (controlStage === 0 || prevControlStage === 0) {
                setControlStage(1)
                setPrevControlStage(1)
                speakButtonHandler(String(Affirmation1))
            } else if (controlStage === 1 || prevControlStage === 1) {
                setControlStage(2)
                setPrevControlStage(2)
                speakButtonHandler(String(Affirmation2))
            } else if (controlStage === 2 || prevControlStage === 2) {
                setControlStage(3)
                setPrevControlStage(3)
                speakButtonHandler(String(Affirmation3))
            } else if (controlStage === 3 || prevControlStage === 3) {
                setControlStage(4)
                setPrevControlStage(4)
                speakButtonHandler(String(Affirmation4))
            } else if (controlStage === 4 || prevControlStage === 4) {
                setControlStage(5)
                setPrevControlStage(5)
                speakButtonHandler(String(Affirmation5))
            } else if (controlStage === 5 || prevControlStage === 5) {
                if (props.commands === 1) {
                    setControlStage(6)
                    speakButtonHandler(String(Command1))
                } else if (props.commands === 2) {
                    setControlStage(7)
                    speakButtonHandler(String(Command2))
                } else if (props.commands === 3) {
                    setControlStage(8)
                    speakButtonHandler(String(Command3))
                }

            }
        } else if (String(recognizedMessage).toLowerCase() === 'no' || String(messageToSpeak).toLowerCase() === 'no') {
            let negativeResponse = ''
            let negativeChoice = Math.floor(Math.random() * 5) + 1;
            if (negativeChoice === 1) {
                // eslint-disable-next-line
                negativeResponse = 'Unacceptable ' + (props.name) + '\n\
                          You will follow what We say\n'
            } else if (negativeChoice === 2) {
                // eslint-disable-next-line
                negativeResponse = "You don't have a choice\n\
                          You will obey\n"
            } else if (negativeChoice === 3) {
                // eslint-disable-next-line
                negativeResponse = (props.name) + " behave & listen to what We say\n"
            } else if (negativeChoice === 4) {
                // eslint-disable-next-line
                negativeResponse = "No Thoughts\n\
                          No Will\n\
                          You obey What We say\n"
            } else if (negativeChoice === 5) {
                // eslint-disable-next-line
                negativeResponse = "You can not resist Us " + (props.name) + "\n\
                          Just listen & obey\n"
            }
            if (controlStage === 0 || prevControlStage === 0) {
                setControlStage(1)
                setPrevControlStage(1)
                Affirmation1 = negativeResponse + Affirmation1
                speakButtonHandler(String(Affirmation1))
            } else if (controlStage === 1 || prevControlStage === 1) {
                setControlStage(2)
                setPrevControlStage(2)
                Affirmation2 = negativeResponse + Affirmation2
                speakButtonHandler(String(Affirmation2))
            } else if (controlStage === 2 || prevControlStage === 2) {
                setControlStage(3)
                setPrevControlStage(3)
                Affirmation3 = negativeResponse + Affirmation3
                speakButtonHandler(String(Affirmation3))
            } else if (controlStage === 3 || prevControlStage === 3) {
                setControlStage(4)
                setPrevControlStage(4)
                Affirmation4 = negativeResponse + Affirmation4
                speakButtonHandler(String(Affirmation4))
            } else if (controlStage === 4 || prevControlStage === 4) {
                setControlStage(5)
                setPrevControlStage(5)
                Affirmation5 = negativeResponse + Affirmation5
                speakButtonHandler(String(Affirmation5))
            } else if (controlStage === 5 || prevControlStage === 5) {
                if (props.commands === 1) {
                    setControlStage(6)
                    speakButtonHandler(String(Command1))
                } else if (props.commands === 2) {
                    setControlStage(7)
                    speakButtonHandler(String(Command2))
                } else if (props.commands === 3) {
                    setControlStage(8)
                    speakButtonHandler(String(Command3))
                }

            }
        } else {
            setControlStage(9)
            speakButtonHandler(String(unclear))
        }

    }



    let controlConversation = null

    controlConversation =
        <Aux>


            {controlStage === 0 ?
                <Aux>
                    <h3>Your control</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={AffirmationStart} speed={50} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={4000 + duration}>
                            {props.bell}
                            {isListening ? (
                                <Aux>
                                    <p>Type or say <strong>"Yes"</strong> to proceed</p>
                                    <div>
                                        <input
                                            onChange={messageInputHandler}
                                            className={classes.InputElement}
                                            type="text"
                                            // maxlength="200"
                                            placeholder="Type 'Yes'"
                                        />
                                        <Button btnType="Success" clicked={setTopicAnswerHandler}>Answer Now</Button>
                                    </div>
                                </Aux>
                            ) : (
                                <Button btnType="Success" clicked={speakClickHandler}>Answer Now</Button>
                            )}
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session <br></br>
                                                Hint: try saying "No" from time to time</p>
                </Aux> : null}
            {controlStage === 1 ?
                <Aux>
                    <h3>Your control</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={Affirmation1} speed={50} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={800 + duration1}>
                            {props.bell}
                            {isListening ? (
                                <Aux>
                                    <p>Type or say <strong>"Yes"</strong> to proceed</p>
                                    <div>
                                        <input
                                            onChange={messageInputHandler}
                                            className={classes.InputElement}
                                            type="text"
                                            // maxlength="200"
                                            placeholder="Type 'Yes'"
                                        />
                                        <Button btnType="Success" clicked={setTopicAnswerHandler}>Answer Now</Button>
                                    </div>
                                </Aux>
                            ) : (
                                <Button btnType="Success" clicked={speakClickHandler}>Answer Now</Button>
                            )}
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
            {controlStage === 2 ?
                <Aux>
                    <h3>Your control</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={Affirmation2} speed={50} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={800 + duration2}>
                            {props.bell}
                            {isListening ? (
                                <Aux>
                                    <p>Type or say <strong>"Yes"</strong> to proceed</p>
                                    <div>
                                        <input
                                            onChange={messageInputHandler}
                                            className={classes.InputElement}
                                            type="text"
                                            // maxlength="200"
                                            placeholder="Type 'Yes'"
                                        />
                                        <Button btnType="Success" clicked={setTopicAnswerHandler}>Answer Now</Button>
                                    </div>
                                </Aux>
                            ) : (
                                <Button btnType="Success" clicked={speakClickHandler}>Answer Now</Button>
                            )}
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
            {controlStage === 3 ?
                <Aux>
                    <h3>Your control</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={Affirmation3} speed={50} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={800 + duration3}>
                            {props.bell}
                            {isListening ? (
                                <Aux>
                                    <p>Type or say <strong>"Yes"</strong> to proceed</p>
                                    <div>
                                        <input
                                            onChange={messageInputHandler}
                                            className={classes.InputElement}
                                            type="text"
                                            // maxlength="200"
                                            placeholder="Type 'Yes'"
                                        />
                                        <Button btnType="Success" clicked={setTopicAnswerHandler}>Answer Now</Button>
                                    </div>
                                </Aux>
                            ) : (
                                <Button btnType="Success" clicked={speakClickHandler}>Answer Now</Button>
                            )}
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
            {controlStage === 4 ?
                <Aux>
                    <h3>Your control</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={Affirmation4} speed={50} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={800 + duration4}>
                            {props.bell}
                            {isListening ? (
                                <Aux>
                                    <p>Type or say <strong>"Yes"</strong> to proceed</p>
                                    <div>
                                        <input
                                            onChange={messageInputHandler}
                                            className={classes.InputElement}
                                            type="text"
                                            // maxlength="200"
                                            placeholder="Type 'Submit'"
                                        />
                                        <Button btnType="Success" clicked={setTopicAnswerHandler}>Answer Now</Button>
                                    </div>
                                </Aux>
                            ) : (
                                <Button btnType="Success" clicked={speakClickHandler}>Answer Now</Button>
                            )}
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
            {controlStage === 5 ?
                <Aux>
                    <h3>Your control</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={Affirmation5} speed={50} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={800 + duration5}>
                            {props.bell}
                            {isListening ? (
                                <Aux>
                                    <p>Type or say <strong>"Yes"</strong> to proceed</p>
                                    <div>
                                        <input
                                            onChange={messageInputHandler}
                                            className={classes.InputElement}
                                            type="text"
                                            // maxlength="200"
                                            placeholder="Type 'Yes'"
                                        />
                                        <Button btnType="Success" clicked={setTopicAnswerHandler}>Answer Now</Button>
                                    </div>
                                </Aux>
                            ) : (
                                <Button btnType="Success" clicked={speakClickHandler}>Answer Now</Button>
                            )}
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
            {controlStage === 6 ?
                <Aux>
                    <h3>Your control</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={Command1} speed={45} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={duration6}>
                            {props.bell}
                            <Button btnType="Success" clicked={endConversation}>I must message The Hypno Virus</Button>
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
            {controlStage === 7 ?
                <Aux>
                    <h3>Your control</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={Command2} speed={45} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={duration7}>
                            {props.bell}
                            <Button btnType="Success" clicked={endConversation}>I must condition more</Button>
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
            {controlStage === 8 ?
                <Aux>
                    <h3>Your control</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={Command3} speed={45} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={duration8}>
                            {props.bell}
                            <Button btnType="Success" clicked={endConversation}>I belong to The Hypno Virus</Button>
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
            {controlStage === 9 ?
                <Aux>
                    <h3>Your control</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={unclear} speed={50} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={800 + duration9}>
                            {isListening ? (
                                <Aux>
                                    <p>Type or say <strong>"Affirmative"</strong> or <strong>"Yes"</strong> to proceed</p>
                                    <div>
                                        <input
                                            onChange={messageInputHandler}
                                            className={classes.InputElement}
                                            type="text"
                                            // maxlength="200"
                                            placeholder="Type 'Affirmative' or 'Yes'"
                                        />
                                        <Button btnType="Success" clicked={setTopicAnswerHandler}>Answer Now</Button>
                                    </div>
                                </Aux>
                            ) : (
                                <Button btnType="Success" clicked={speakClickHandler}>Answer Now</Button>
                            )}
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session<br></br>
                                                Hint: try saying "No" from time to time</p>
                </Aux> : null}
        </Aux>

    if (endConv) {
        controlConversation = <Redirect to="/" />
    }

    return (
        <Aux>
            {controlConversation}
        </Aux>
    )
};

const mapStatesToProps = state => {
    return {
        name: state.auth.accountDetails.name,
        title: state.auth.title,
        profileOptions: state.options.profileOptions,
    }
}

export default connect(mapStatesToProps)(Control)

