import React from 'react'
import classes from '../../../containers/Account/Account.module.css'
import Input from '../../UI/Input/Input'
import Button from '../../UI/Button/Button'
import Aux from '../../../hoc/auxiliary/auxiliary'


const createProfile = (props) => {
   
    let createProfileForm = (
        <Aux>
            <div className={classes.AccountContainer}>
                <form
                    onSubmit={props.registerFormHandler}
                    className={classes.FormStyle}>
                    <h2>Tell Us about yourself, Host</h2>
                    <p className={classes.smallText}>A step closer to your new life</p>
                    {props.registerFormElementsArray.map(formElement => (
                        
                        <Input
                            key={formElement.id}
                            elementType={formElement.config.elementType}
                            elementConfig={formElement.config.elementConfig}
                            value={formElement.config.value}
                            invalid={!formElement.config.valid}
                            shouldValidate={formElement.config.validation}
                            touched={formElement.config.touched}
                            label = {formElement.config.label}
                            changed={(event) => props.inputRegisterHandler(event, formElement.id)} />
                    ))}
                </form>
                <div className={classes.AuthButtons}>
                    <Button btnType="Success" clicked={props.createAccountHandler}>Create Profile</Button>
                </div>

            </div>
        </Aux>)


    return createProfileForm
}

export default createProfile

// <div className={classes.AccountContainer}>
//     <form
//         onSubmit={this.registerFormHandler}
//         className={classes.FormStyle}>
//         <h2>Tell Us about yourself, Host</h2>
//         <p className={classes.smallText}>A step closer to your new life</p>
//         {registerFormElementsArray.map(formElement => (
//             <Input
//                 key={formElement.id}
//                 elementType={formElement.config.elementType}
//                 elementConfig={formElement.config.elementConfig}
//                 value={formElement.config.value}
//                 invalid={!formElement.config.valid}
//                 shouldValidate={formElement.config.validation}
//                 touched={formElement.config.touched}
//                 changed={(event) => this.inputRegisterHandler(event, formElement.id)} />
//         ))}
//     </form>
//     <div className={classes.AuthButtons}>
//         <Button btnType="Success" clicked={this.createAccountHandler}>Create Profile</Button>
//     </div>

// </div>

