import React, { Component } from 'react';
import Aux from '../../../../hoc/auxiliary/auxiliary';
import classes from '../HypnosisShowMenu.module.scss'
// import classes2 from '../ExperienceMenu/ExperienceMenu.module.scss'
import HypnosisShow from '../../../../assets/Images/Blank.png'
import Induction from './HypnosisShowVolInduction'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'

class HypnosisShowSusceptibilityTest extends Component {

    state = {
        susceptibilityTest: true,
        induction: false,
    }

    InductionHandler = () => {
        this.setState({ susceptibilityTest: false, induction: true })
    }


    render() {
        let HypnosisShowForm = null
        if (this.state.susceptibilityTest) {
            HypnosisShowForm = (
                <html style={{ backgroundImage: `url(${HypnosisShow})` }}>
                    <div className={classes.storyFrom}>
                        <p>
                            <i>"We start with a test. This test aims to measure your susceptibility & how you response to hypnosis. How you react to it is not an indication of whether you're a good subject or not</i><br></br>
                            Turning to face us he says in a softer, more soothing tone <br></br>
                            <i> “I’d like you to raise your hands straight in front of you, focus on the palm of your hands”</i><br></br>
                            He turns around to face the audience saying <br></br>
                            <i>"You're all welcome to try this out as well. Put your hands straight in front of you & focus on the palm of your hands"</i><br></br>
                            <i>"Now..." </i>He says while turning to face you. <i>"Focus on your right hand, feeling yourself holding a bucket of water. This bucket keeps getting heavier and heavier and heavier with each passing second. <br></br>
                            In the other hand you feel 2 balloons tied to your wrist. Slowly raising your hand higher as a third one is tied up, then a fourth & a fifth. Each balloon raising your hand higher & higher. 10 balloons now tied to your wrist raising it up high."</i> <br></br>
                            Your mind caught by his words & voice you only now notice that your right hand has fall down quite a bit while your left has been rising & floating up high in the air. This catches you off guard to see how your hands & mind instinctively reacted to his voice.
                            You're so distracted by the realization it takes you a second to notice he started talking again saying. <br></br>
                            <i>“Once I snap my fingers you'll find the water bucket disappearing along with all 10 balloons. Your right hand free of that weight pulling it down while your left hand drops instantly tired from staying up in the air for that long. They disappear in 3, 2, &… 1”</i><br></br>
                            <strong>*SNAP*</strong><br></br>
                            The bucket disappearing instantly, feeling your hand a little tired from holding that weight for a while and your left hand dropping down to your lap right away. You don't focus on how your arms are feeling that much as you're more amazed and excited of what he'll do next. <br></br>
                            Seeing The Great O going to each of the volunteers saying something in a low voice you can’t make out.
                            One by one he gets closer until it’s your turn, standing in front of you making you feel quite small as he towers over you feeling a little intimdated but as soon as he leans closer to your ears whispering <br></br>
                            <i>“You’ll make a great subject”</i> <br></br>
                            You feel a shiver running down your spine with your thoughts momentarily coming to a halt. Eyes blinking rapidly, trying to regain your composure making a visible effort to look up at him and all you can do is smile weakly.<br></br> 
                            He smiles back as he steps away turning to face the audience to say
                            <i>"I can see a few of you out there enjoyed this test as well, perfect! Now is the time for what you've all came here to see, now is the time for hypnosis"</i><br></br>
                            Looking at you he asks in a gentle tone <br></br>
                            <i>"Are you ready?"</i><br></br>
                            You nod slowly feeling butterflies, excited for the...
                        </p>
                        <form className={classes.centered} onClick={this.InductionHandler}>
                            <h3 className={classes.storyButtonSize}>Induction</h3>
                        </form>
                    </div>
                </html>
            )
        }
        if (this.state.induction) {
            HypnosisShowForm = (
                <Induction />
            )
        }

        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {HypnosisShowForm}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >




        )
    }
}


export default HypnosisShowSusceptibilityTest