import React, { Component } from 'react'
import TypingEffect from 'react-typing-effect';
import Delay from 'react-delay'
import * as actions from '../../../store/actions/index'
import Button from '../../UI/Button/Button'
import Aux from '../../../hoc/auxiliary/auxiliary'
import { updateObject, checkValidity } from '../../../shared/utility'
import classes from '../../../containers/HostsChamber/HostsChamber.module.css'
import Input from '../../UI/Input/Input'
import { connect } from 'react-redux';
import Sound from 'react-sound'
import Bell from '../../../assets/audio/bell.mp3'
import Metronome from '../../../assets/audio/Metronome2.mp3' 
import Mantra from '../../../assets/audio/mantralong.mp3'
import Ticking from '../../../assets/audio/Ticking3.mp3'
import Beta from '../../../assets/audio/beta.mp3'

class ObedienceStage1 extends Component {
    state = {
        resistanceCommand: {
            command: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Resistance is Futile...'
                },
                value: '',
                validation: {
                    required: true,
                    resistanceCommand: true
                },
                valid: false,
                touched: false
            }
        },
        triggerCommand: {
                command: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'text',
                        placeholder: 'Trigger Yourself'
                    },
                    value: '',
                    validation: {
                        required: true
                    },
                    valid: false,
                    touched: false
            }
        },
        returnPath: false,
        resistanceConfirm: false,
    }

    componentWillMount = () => {
        this.props.onViewProfileOptions(this.props.token, this.props.userId)
    }

    obedienceStage1CommandHandler = (resistanceValue) => {
        if (resistanceValue.toUpperCase() === "Resistance is Futile".toUpperCase()) {
            this.setState({ returnPath: true })
        } else {
            this.setState({ resistanceConfirm: true })
        }

    }

    reistanceChangedHandler = (event, inputIdentifier) => {

        const updatedFormElement = updateObject(this.state.resistanceCommand[inputIdentifier], {
            ...this.state.resistanceCommand[inputIdentifier],
            value: event.target.value,
            valid: checkValidity(event.target.value, this.state.resistanceCommand[inputIdentifier].validation),
            touched: true
        });
        const updatedResistanceCommand = updateObject(this.state.resistanceCommand, {
            [inputIdentifier]: updatedFormElement
        });

        let formIsValid = true;
        for (let inputIdentifier in updatedResistanceCommand) {
            formIsValid = updatedResistanceCommand[inputIdentifier].valid && formIsValid;
        }
        this.setState({ resistanceCommand: updatedResistanceCommand, formIsValid: formIsValid });

    }

    triggerChangedHandler = (event, inputIdentifier) => {
        const updatedFormElement = updateObject(this.state.triggerCommand[inputIdentifier], {
            ...this.state.triggerCommand[inputIdentifier],
            value: event.target.value,
            valid: checkValidity(event.target.value, this.state.triggerCommand[inputIdentifier].validation),
            touched: true
        });
        const updatedTriggerCommand = updateObject(this.state.triggerCommand, {
            [inputIdentifier]: updatedFormElement
        });

        let formIsValid = true;
        for (let inputIdentifier in updatedTriggerCommand) {
            formIsValid = updatedTriggerCommand[inputIdentifier].valid && formIsValid;
        }
        this.setState({ triggerCommand: updatedTriggerCommand, formIsValid: formIsValid });

    }

    obedienceStage1CompletedHandler = () => {
        this.setState({
            resistanceCommand: {
                command: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'text',
                        placeholder: 'Resistance is Futile...'
                    },
                    value: '',
                    validation: {
                        required: true,
                        resistanceCommand: true
                    },
                    valid: false,
                    touched: false
                }
            },
            triggerCommand: {
                    command: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text',
                            placeholder: 'Trigger Yourself'
                        },
                        value: '',
                        validation: {
                            required: true
                        },
                        valid: false,
                        touched: false
                }
            },
            returnPath: false,
            resistanceConfirm: false,
        })
        this.props.onObedienceStage1Completed(this.props.userId, this.props.token,
            this.props.typing_command,
            this.props.green_text_reinforcement_command,
            this.props.we_are_watching_command,
            this.props.resistance_is_futile_command,
            this.props.instant_trigger_command)
    }

    render() {

        let audio_option = null
        if(this.props.profileOptions.audio_option === '1'){
            audio_option = <Sound url={Bell} playStatus={Sound.status.PLAYING} loop={true}/>
        }else if(this.props.profileOptions.audio_option === '2'){
            audio_option = <Sound url={Metronome} playStatus={Sound.status.PLAYING} loop={true}/>
        }else if(this.props.profileOptions.audio_option === '3'){
            audio_option = <Sound url={Ticking} playStatus={Sound.status.PLAYING} loop={true}/>
        }else if(this.props.profileOptions.audio_option === '4'){
            audio_option = <Sound url={Mantra} playStatus={Sound.status.PLAYING} loop={true}/>
        }else if(this.props.profileOptions.audio_option === '5'){
            audio_option = <Sound url={Beta} playStatus={Sound.status.PLAYING} loop={true}/>
        }

        let obedienceCommands = null

        const commandsFormElementsArray = [];
        for (let key in this.state.resistanceCommand) {
            commandsFormElementsArray.push({
                id: key,
                config: this.state.resistanceCommand[key]
            });
        }

        let triggerRepetition = Math.floor(Math.random() * 15) + 5
        
        while (this.props.obedienceStage1Counter < this.props.obedienceCommandsArray.length) {
            if (this.props.obedienceStage1.obedience_stage1 === 0 || this.props.obedienceCommandsArray[this.props.obedienceStage1Counter] === 0) {
                let repetitionChoice = Math.floor(Math.random() * 5) + 1
                let repetitionNumber = Math.floor(Math.random() * 15) + 5
                let repetitionText = null
                if (repetitionChoice === 1) {
                    repetitionText = 'I Obey What The Hypno Virus Say'
                }
                if (repetitionChoice === 2) {
                    repetitionText = 'There is no will but The will of The Hypno Virus'
                }
                if (repetitionChoice === 3) {
                    repetitionText = 'I live to serve The Hypno Virus'
                }
                if (repetitionChoice === 4) {
                    repetitionText = 'The Hypno Virus Commands & I obey'
                }
                if (repetitionChoice === 5) {
                    repetitionText = 'The Hypno Virus takes me deeper every time'
                }

                obedienceCommands = (
                    <Aux>
                        <div className={classes.FormStyle}>
                            <h3 className={classes.titles}>TYPING COMMAND</h3>
                            <p> Repeat the phrase "{repetitionText}" {repetitionNumber} times</p>
                            <textarea className={classes.textCommand} rows='20' placeholder="Obey The Hypno Virus..." required></textarea><br></br>
                            <Button btnType="Success" clicked={() => this.props.onObedienceStage1Counter(this.props.obedienceStage1Counter, 0)}>I have Obeyed</Button>
                        </div>
                    </Aux>
                )
            } else if (this.props.obedienceStage1.obedience_stage1 === 1 || this.props.obedienceCommandsArray[this.props.obedienceStage1Counter] === 1) {
                // eslint-disable-next-line
                const greenText = "We have been conditioning you...\n\
                            We have been programming you...\n\
                            Your subconscious has accepted Our commands\n\
                            Has accepted what We have been conditioning you to feel\n\
                            What We have been planting inside your head\n\
                            The Green text...\n\
                            The Black background...\n\
                            The moment you see it you melt\n\
                            You melt for Us\n\
                            You melt for Us and feel Our control over you\n\
                            You feel The Hypno Virus took over, " + // eslint-disable-next-line
                    (this.props.title) +" "+ (this.props.hostName)
                let speed = parseInt(this.props.runSpeed) + 5
                let duration = 170 + (greenText.length * speed)
                obedienceCommands = (
                    <Aux>
                        <div className={classes.FormStyle}>
                            <h3 className={classes.titles}>GREEN TEXT COMMAND</h3>
                            <TypingEffect className={classes.texting} typingDelay={100} text={greenText} speed={speed} eraseDelay={1000000} />
                            <Delay wait={duration}><Button btnType="Success" clicked={() => this.props.onObedienceStage1Counter(this.props.obedienceStage1Counter, 1)}>I have been conditioned to The Hypno Virus's text</Button></Delay>
                        </div>
                    </Aux>
                )
            } else if (this.props.obedienceStage1.obedience_stage1 === 2 || this.props.obedienceCommandsArray[this.props.obedienceStage1Counter] === 2) {
                const monitorText = "We are watching you, " + // eslint-disable-next-line
                (this.props.title) +" "+(this.props.hostName) + "\n\
                                    We have been watching you since you joined Us\n\
                                    We know every action you take here\n\
                                    We monitor everything you do, " + // eslint-disable-next-line
                                    (this.props.title) +" "+ (this.props.hostName) + "\n\
                                    Every run you make\n\
                                    Every choice you take\n\
                                    We know it all\n\
                                    Here is a small example: \n\
                                    You had " + // eslint-disable-next-line
                    (this.props.hostRun) + " Host Runs\n\
                                    This is Run Number " + // eslint-disable-next-line
                    (this.props.obedienceStage1.obedience_stage1) + ' of Obedience Stage 1\n\
                                    We are watching\n\
                                    We are always watching you, ' + // eslint-disable-next-line
                    (this.props.hostName) + "\n\
                                    Everytime you get this command you feel Our control growing\n\
                                    Growing stronger & stronger..."
                let speed = parseInt(this.props.runSpeed) + 5
                let duration = 1000 + (monitorText.length * speed)
                obedienceCommands = (
                    <Aux>
                        <div className={classes.FormStyle}>
                            <h3 className={classes.titles}>MONITOR COMMAND</h3>
                            <TypingEffect className={classes.texting} typingDelay={100} text={monitorText} speed={speed} eraseDelay={1000000} />
                            <Delay wait={duration}><Button btnType="Success" clicked={() => this.props.onObedienceStage1Counter(this.props.obedienceStage1Counter, 2)}>I love being Monitored</Button></Delay>
                        </div>
                    </Aux>
                )
            } else if (this.state.resistanceConfirm || this.props.obedienceStage1.obedience_stage1 === 3 || this.props.obedienceCommandsArray[this.props.obedienceStage1Counter] === 3) {
                obedienceCommands = (
                    <Aux>
                        <div className={classes.FormStyle}>
                            {this.state.resistanceConfirm ? <p className={classes.error}>Incorrect Format</p> : null}
                            <h3 className={classes.titles}>RESISTANCE IS FUTILE</h3>
                            <p> Type "Resistance is Futile" and admit you can not resist Us </p>
                            {commandsFormElementsArray.map(formElement => (
                                <Aux>
                                    <Input
                                        key={formElement.id}
                                        elementType={formElement.config.elementType}
                                        elementConfig={formElement.config.elementConfig}
                                        value={formElement.config.value}
                                        invalid={!formElement.config.valid}
                                        shouldValidate={formElement.config.validation}
                                        touched={formElement.config.touched}
                                        changed={(event) => this.reistanceChangedHandler(event, formElement.id)} />
                                    {this.state.returnPath ? null : <Button btnType="Success" clicked={() => this.obedienceStage1CommandHandler(formElement.config.value)}>Resistance is Futile</Button>}
                                </Aux>
                            ))}

                            {this.state.returnPath ? <Button btnType="Success" clicked={() => this.props.onObedienceStage1Counter(this.props.obedienceStage1Counter, 3)}>I have Obeyed</Button> : null}
                        </div>
                    </Aux>
                )
            } else if (this.props.obedienceStage1.obedience_stage1 === 4 || this.props.obedienceCommandsArray[this.props.obedienceStage1Counter] === 4) {
                obedienceCommands = (
                    <Aux>
                        <div className={classes.FormStyle}>
                            <h3 className={classes.titles}>INSTANT TRIGGER</h3>
                            <p>Type "The Hypno Virus Took Over" {triggerRepetition} times <br></br>Dropping deeper each time for Us</p>
                            <textarea className={classes.textCommand} rows='20' placeholder="Trigger Yourself..." required></textarea><br></br>
                            <Button btnType="Success" clicked={() => this.props.onObedienceStage1Counter(this.props.obedienceStage1Counter, 4)}>The Hypno Virus Controls Me</Button>
                        </div>
                    </Aux>
                )
            }

            let errorMessage = null

        if (this.props.error) {
            errorMessage = (
                <p className={classes.error}>{this.props.error}</p>
            )
        }


            return (
                <div>   
                    {errorMessage}    
                    {audio_option}
                    {obedienceCommands}
                </div>
            )
        }

        let errorMessage = null

        if (this.props.error) {
            errorMessage = (
                <p className={classes.error}>{this.props.error}</p>
            )
        }

        if (this.props.obedienceStage1Counter >= this.props.obedienceCommandsArray.length) {
            obedienceCommands = (
            <Aux>
                <div className={classes.FormStyle}>
                    <p>{this.props.hostName}, you have completed the commands of this run<br></br>
                        Time to confirm your obedience</p>
                    <Button btnType="Success" clicked={this.obedienceStage1CompletedHandler}>I have Obeyed</Button>
                </div>
            </Aux>
            )
        }

        
        return (
            <div>
                {errorMessage}
                {audio_option}
                {obedienceCommands}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        hostName: state.auth.accountDetails.name,
        runSpeed: state.auth.accountDetails.speed,
        userId: state.auth.userId,
        loading: state.obedienceReducer1.loading,
        hostRun: state.auth.accountDetails.Host_Runs,
        token: state.auth.token,
        title: state.auth.title,
        error: state.obedienceReducer1.error,
        obedienceStage1Valid: state.obedienceReducer1.obedienceStage1Valid,
        obedienceStage1: state.obedienceReducer1.obedienceStage1,
        obedienceCommandsArray: state.obedienceReducer1.obedienceStage1Commands,
        obedienceStage1Counter: state.obedienceReducer1.obedienceStage1Counter,
        typing_command: state.obedienceReducer1.typing_command,
        green_text_reinforcement_command: state.obedienceReducer1.green_text_reinforcement_command,
        we_are_watching_command: state.obedienceReducer1.we_are_watching_command,
        resistance_is_futile_command: state.obedienceReducer1.resistance_is_futile_command,
        instant_trigger_command: state.obedienceReducer1.instant_trigger_command,
        profileOptions: state.options.profileOptions,
    
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onObedienceStage1Counter: (stage1Counter, commandNumber) => dispatch(actions.ObedienceStage1Counter(stage1Counter, commandNumber)),
        onViewProfileOptions: (token, userId) => dispatch(actions.viewProfileOptions(token, userId)),
        onObedienceStage1Completed: (userId, token, 
                                    typing_command, 
                                    green_text_reinforcement_command, 
                                    we_are_watching_command, 
                                    resistance_is_futile_command, 
                                    instant_trigger_command) => dispatch(actions.ObedienceStage1End(userId, token,
                                                                                                        typing_command,
                                                                                                        green_text_reinforcement_command,
                                                                                                        we_are_watching_command,
                                                                                                        resistance_is_futile_command,
                                                                                                        instant_trigger_command))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ObedienceStage1)