import React from 'react';
import TypingEffect from 'react-typing-effect';
import Delay from 'react-delay'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import classes from '../../../HostsRun/HostsRunComponent.module.css'
import Bell from '../../../../../assets/audio/bell.mp3'
import Sound from 'react-sound'


const awakenerForget = (props) => {
    // let praiseChoice = 0
    // praiseChoice = Math.floor(Math.random() * props.praises.length);
    // let praise = props.praises[praiseChoice]
    
    // eslint-disable-next-line
    const awakenerForgetMessage = (props.naming) + ", It is time for you to wake up\n\
                                     In a moment We will ring the bell\n\
                                     Once you hear it you will slowly wake up\n\
                                     Wake up feeling absolutely wonderful\n\
                                     So happy\n\
                                     So excited\n\
                                     Forgetting everything We did\n\
                                     But eager\n\
                                     Eager to return to Us\n\
                                     To return to The Hypno Virus\n\
                                     Return and be Our " + // eslint-disable-next-line
                                     (props.title) + ' again\n\
                                     Forgetting what We did\n\
                                     Which makes you realize the power We now have over you\n\
                                     How easy We can make you forget what We do to you\n\
                                     Which makes you want nothing more\n\
                                     Nothing more than to return to Us\n\
                                     Return to The Hypno Virus'
    let speed = parseInt(props.speed) +5
    let  duration = 170 + (awakenerForgetMessage.length * speed)
    let awakenerForgetParagraph = (
            <Aux>
                <h3 className= {classes.titles}>AWAKENER FORGET</h3>
                <Delay wait={5000}><TypingEffect typingDelay={100} text={awakenerForgetMessage} speed={props.speed} eraseDelay={1000000} /></Delay>
                <Delay wait={duration+5000}><Sound url={Bell} playStatus={Sound.status.PLAYING} /></Delay>
                <Delay wait={duration+10000}><h4 className={classes.blinking} onClick={props.awakenerForget}>The Hypno Virus made me forget everything</h4></Delay>
            </Aux>
        )
    





    return awakenerForgetParagraph
}


export default awakenerForget