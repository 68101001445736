import React, { Component } from 'react';
import Aux from '../../../../hoc/auxiliary/auxiliary';
import classes from '../HypnosisShowMenu.module.scss'
// import classes2 from '../ExperienceMenu/ExperienceMenu.module.scss'
import HypnosisShow from '../../../../assets/Images/Blank.png'
import BeforeDeepener from '../Volunteer/HypnosisShowVolBeforeDeepener'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'

class HypnosisShowAccept extends Component {

    state = {
        accept: true,
        beforeDeepener: false
    }

    BeforeDeepenerHandler = () => {
        this.setState({ accept: false, beforeDeepener: true })
    }



    render() {
        let HypnosisShowForm = null
        if (this.state.accept) {
            HypnosisShowForm = (
                <html style={{ backgroundImage: `url(${HypnosisShow})` }}>
                    <div className={classes.storyFrom}>
                        <p>
                            Feeling butterflies in your stomach as he lets go of your shoulders allowing you to make the decision for yourself.<br></br>
                            Nervous & anxious of going up to stage but the thought of feeling more of this is quite enticing. Torn between giving into your nervousness and giving into how good this feels.<br></br>
                            You finally reach a decision, you take a deep breath in, holding it for a few seconds only to let it out again with a smile on your face you accept his offer.<br></br>
                            He smiles back taking your hand & guiding you up on stage where you hear people in the audience cheering and clapping for you while you make your way up and take a seat. <br></br>
                            Feeling his hand on your shoulder again as he kneels next to your ears and whispers <br></br> 
                            <strong><i>"SLEEP NOW"</i></strong>
                        </p>
                        <form className={classes.centered} onClick={this.BeforeDeepenerHandler}>
                            <h4 className={classes.storyButtonSize}>*SNAP*</h4>
                        </form>
                    </div>
                </html>
            )
        }
        if (this.state.beforeDeepener) {
            HypnosisShowForm = (
                <BeforeDeepener />
            )
        }


        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {HypnosisShowForm}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >




        )
    }
}


export default HypnosisShowAccept