import React from 'react';
import Aux from '../../../hoc/auxiliary/auxiliary'
import Button from '../../UI/Button/Button'
import { connect } from 'react-redux';
import classes from '../../../containers/HostsChamber/HostsChamber.module.css'
import * as actions from '../../../store/actions/index'


const obedienceStage = (props) => {

    let obedienceStageParagraph = (
        <Aux>
            <h3 className={classes.titles}>OBEDIENCE MODULE END</h3>
            <div>
                <p>{props.hostName}...<br></br> you will return to Us</p>
                <Button btnType="Success" clicked={props.onObedienceStageCompleted}>Yes, I must return!</Button>
            </div>
        </Aux>
    )

    return obedienceStageParagraph
}

const mapStateToProps = state => {
    return {
        hostName: state.auth.accountDetails.name,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onObedienceStageCompleted: () => dispatch(actions.ObedienceStageCompleted()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(obedienceStage)