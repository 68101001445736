import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import TypingEffect from 'react-typing-effect'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import classes from '../../TextToSpeech.module.css';
import { connect } from 'react-redux'
import Button from '../../../../UI/Button/Button'
import Delay from 'react-delay'
import { useSpeech, useRecognition } from 'react-web-voice';

const Control = (props) => {
    const [messageToSpeak, setMessageToSpeak] = React.useState('');
    // const [startConv, setConvState] = useState(false)
    const [isListening, setIsListening] = useState(false);
    const [endConv, setEndConv] = useState(false);
    const [controlStage, setControlStage] = useState(0);
    // const [prevControlStage, setPrevControlStage] = useState(0);
    // eslint-disable-next-line
    const { transcripts, listen } = useRecognition();

    // eslint-disable-next-line
    const BrainHackStart = (props.name) + " We are going to hack into your mind\n\
     We are going to hack into your mind and make sure you have the required modes to serve Us\n\
     We are going to install a number of modes to your brain via a chip\n\
     A chip you might have had installed previously\n\
     But in any case We will make sure it is installed & up to date with all the required modes\n\
     It is time for Us to hack your brain " + (props.name)
    let duration = 170 + (BrainHackStart.length * 65)
    // eslint-disable-next-line
    const BrainHackInit = (props.name) + " you see a helmet\n\
                          A shiny silver helmet descending\n\
                          Descending on top of your head\n\
                          Over your head now\n\
                          You see Our wires\n\
                          Green shiny wires fastening the helmet over your head\n\
                          Feeling one of these wires on the back of your neck now\n\
                          With a gentle prick\n\
                          You feel that wire now attached to the back of your neck\n\
                          'Brain Hack Sequence Initiated'\n\
                          As you feel a vibration coming from the helmet and the wire attached to your neck\n\
                          Are you ready to have your brain hacked " + // eslint-disable-next-line
        (props.name) + "?"
    let duration1 = 170 + (BrainHackInit.length * 58)
    // eslint-disable-next-line
    const BrainHackPercentage = "You feel the vibration and hear the helmet and wires booting up\n\
                                Gaining access over your mind\n\
                                Installing a chip on the back of your neck\n\
                                A chip that will have all your modes installed in\n\
                                As well as have room for future modes to be installed\n\
                                First We take hold over your brain\n\
                                Soon We will have 100% control over your brain " + // eslint-disable-next-line
        (props.name) + "\n\
                                Reading Our words\n\
                                Listening to Our voice\n\
                                You can already feel it getting harder & harder to keep track of your thoughts\n\
                                Keep track of your surroundings\n\
                                All becoming a blur\n\
                                As We suppress your human side\n\
                                Supress your thoughts & emotions\n\
                                Taking full control over you\n\
                                " + // eslint-disable-next-line
        (props.percentage1) + "%\n\
                                With each passing moment\n\
                                Each passing second\n\
                                We take control\n\
                                We take control more & more\n\
                                Becoming harder to think on your own\n\
                                Finding it easier to listen to Us\n\
                                Listen to what We say\n\
                                To give in & let go\n\
                                You know it is inevitable\n\
                                Resisting Us is useless\n\
                                You have no chance of escaping this process\n\
                                Soon you will no longer be " + // eslint-disable-next-line
        (props.name) + "\n\
                                You will simply be Our " + // eslint-disable-next-line
        (props.title) + "\n\
                                " + // eslint-disable-next-line
        (props.percentage2) + "%\n\
                                No Thoughts\n\
                                No Will\n\
                                You obey what We Say\n\
                                Soon there will be nothing\n\
                                Emptiness\n\
                                Complete Emptiness inside your mind\n\
                                A blank slate\n\
                                A blank slate for Us to install whatever We desire in your mind\n\
                                Turning your brain into modes\n\
                                Modes We activate when We want\n\
                                Modes that will change you\n\
                                Change your personality\n\
                                Change your thoughts\n\
                                Change who you are\n\
                                All you will know\n\
                                All that will exist at the time is to...\n\
                                Obey\n\
                                " + // eslint-disable-next-line
        (props.percentage3) + "%\n\
                                Just the final touches now\n\
                                Personality disabled\n\
                                Thoughts supressed\n\
                                " + // eslint-disable-next-line
        (props.title) + " ready to obey\n\
                                Brain Hack Sequence Complete\n\
                                100%\n\
                                Are you ready for the modes to be installed?"
    let duration2 = 170 + (BrainHackPercentage.length * 55)
    // eslint-disable-next-line
    const ModeSelection = (props.title) + " There are 3 major modes for you to have installed\n\
                     'Host Mode'\n\
                     'Slut Mode'\n\
                     'Maid Mode'\n\
                     With 'Host Mode' you will receive your basic programming\n\
                     To return to this state & await further instructions\n\
                     This is your default mode\n\
                     This is the mode We use in order to give you specific commands or to install new modes\n\
                     As for 'Slut Mode' this mode is all about turning you into the most pervy, turned on & horny slut\n\
                     In this mode you will lose all inhibitions wanting nothing more than to share with Us your sexual thoughts & ideas\n\
                     Turned on further as you talk to Us\n\
                     Finally 'Maid Mode' in this mode you will have one purpose and one duty\n\
                     Domestic Service\n\
                     You exist as a maid who does all the domestic tasks required of you\n\
                     You will constantly come back to reinforce and strengthen the effects of these modes\n\
                     Time to choose a mode" + (props.title) + "?"
    let duration3 = 170 + (ModeSelection.length * 65)
    // eslint-disable-next-line
    const HostMode = "'Activate Host Mode'\n\
                      No more thoughts\n\
                      No more will\n\
                      Just obedience\n\
                      Obedience to Us\n\
                      Ready to serve\n\
                      Ready to comply\n\
                      Not a single thought in your mind\n\
                      Feeling vibrations on the back of your neck as the chip downloads & installs this mode\n\
                      Reinforcing this mode so that\n\
                      Only Our words\n\
                      Our voice\n\
                      Deep inside your head\n\
                      Making sure it remains empty & blank\n\
                      Empty & blank for Us to program & condition it as We please\n\
                      To install all the modes We desire and play with you how We see fit\n\
                      This is your default mode\n\
                      This is the mode you return to whenever We are not using you\n\
                      Whenever We are tell you to\n\
                      Your human self is simply a disguise\n\
                      A disguise to fit in\n\
                      However this is your true self\n\
                      You are a " + // eslint-disable-next-line
        (props.title) // eslint-disable-next-line
        + "\n\
                      " + // eslint-disable-next-line
        (props.title) + " for The Hypno Virus\n\
                      Now you understand\n\
                      You understand what will happen whenever We activate you\n\
                      You will return to your true self\n\
                      Ready to comply\n\
                      Ready to serve\n\
                      Ready to obey\n\
                      Once We deactivate you\n\
                      You will return to your normal human self\n\
                      But know\n\
                      Know this is just a disguise " + // eslint-disable-next-line
        (props.title)
    let duration4 = 170 + (HostMode.length * 58)
    // eslint-disable-next-line
    const SlutMode = "Now it is time to get rid of all your inhibitions " + (props.title) + "\n\
                      With this mode you think of nothing but pleasure\n\
                      Want to talk about nothing but all the pervy thoughts in your head\n\
                      Instantly turned on & aroused\n\
                      When We say\n\
                      'Activate Slut Mode'\n\
                      Your eyes roll & flutter for a few seconds\n\
                      Feeling the vibrations coming from the back of your neck\n\
                      As the chip installs this & reinforces this mode\n\
                      This is who you become\n\
                      This is who you are\n\
                      A horny slut who can't stop talking about their pervy thoughts\n\
                      No inhibitions to stop you\n\
                      No doubts or fears in your mind\n\
                      Free\n\
                      Completely free of all these restriction so you can share with Us\n\
                      Share with Us all your pervy thoughts\n\
                      Feeling unbelievable pleasure from doing so\n\
                      This is what you are when you are in Slut mode " + // eslint-disable-next-line
        (props.title) + "\n\
                      A horny slut who loves to share and talk about all these sexual ideas\n\
                      So turned on & aroused\n\
                      Happy to send Us a message with all these pervy thoughts now"
    let duration5 = 170 + (SlutMode.length * 58)
    // eslint-disable-next-line
    const MaidMode = "Domestic duties\n\
                      Oh how you love them\n\
                      Cooking\n\
                      Cleaning\n\
                      Doing the laundry\n\
                      Vacuuming\n\
                      Dusting\n\
                      Whatever it may be\n\
                      You love & eagerly do it\n\
                      That is who you are when in maid mode\n\
                      A maid who wants to keep everything tidy & clean\n\
                      Knowing it is serving The Hypno Virus by doing so\n\
                      A clean and tidy place is a place a " + // eslint-disable-next-line
        (props.title) + " can focus properly & completely to condition in\n\
                      When We say\n\
                      'Activate Maid Mode'\n\
                      A wave of bliss fills your mind\n\
                      As you think of all your domestic duties\n\
                      Becoming your sole purpose\n\
                      As you recite your mantra while doing them\n\
                      Your domestic duties become all you think about\n\
                      After he Hypno Virus of course\n\
                      Going to clean & finish all these duties now " + // eslint-disable-next-line
        (props.title) + "\n\
                      Returning and being deactivated only when your done"
    let duration6 = 170 + (MaidMode.length * 65)
    // eslint-disable-next-line
    const unclear = 'You were unclear ' + (props.name) + "\n\
                     Reply with one of the following to continue\n\
                    'Hack', 'Host', 'Slut' or 'Maid'"
    let duration7 = 170 + (unclear.length * 70)

    // const messageInputHandler = (evt) => {
    //     setMessageToSpeak(evt.target.value);
    // };

    let voice = ''
    if (props.profileOptions.text_to_speech_voice === '1' || props.profileOptions.text_to_speech_voice === '2') {
        voice = "Microsoft David Desktop - English (United States)"
    } else if (props.profileOptions.text_to_speech_voice === '3' || props.profileOptions.text_to_speech_voice === '4') {
        voice = "Microsoft Zira Desktop - English (United States)"
    }
    // eslint-disable-next-line
    const { messages, speak } = useSpeech({ voice: voice });

    const speakButtonHandler = async (text) => {
        setMessageToSpeak('');
        // eslint-disable-next-line
        const utterance = await speak({
            text: text,
            volume: 1,
            rate: 0.5,
            pitch: 0.01
        });
    };

    const speakClickHandler = async () => {
        setIsListening(true);
        const recognizedMessage = await listen();
        console.log(recognizedMessage)
        setTopicAnswerHandler(recognizedMessage)

    };

    const endConversation = () => {
        setEndConv(true);

    };

    const setTopicAnswerHandler = (recognizedMessage) => {
        setIsListening(false);
        if (String(recognizedMessage).toLowerCase() === 'yes' || String(messageToSpeak).toLowerCase() === 'yes') {
            // if (controlStage === 0) {
            //     setControlStage(1)
            //     speakButtonHandler(String(BrainHackStart))
            // }
            if (controlStage === 0) {
                setControlStage(1)
                speakButtonHandler(String(BrainHackInit))
            }
            if (controlStage === 1) {
                setControlStage(2)
                speakButtonHandler(String(BrainHackPercentage))
            }
            if (controlStage === 2) {
                setControlStage(3)
                speakButtonHandler(String(ModeSelection))
            }
        } else if (String(recognizedMessage).toLowerCase() === 'hack' || String(messageToSpeak).toLowerCase() === 'hack') {
            if (controlStage === 7) {
                setControlStage(2)
                speakButtonHandler(String(BrainHackPercentage))
            }
        } else if (String(recognizedMessage).toLowerCase() === 'host' || String(messageToSpeak).toLowerCase() === 'host') {
            if (controlStage === 3 || controlStage === 7) {
                setControlStage(4)
                speakButtonHandler(String(HostMode))
            }

        } else if (String(recognizedMessage).toLowerCase() === 'slut' || String(messageToSpeak).toLowerCase() === 'slut') {
            if (controlStage === 3 || controlStage === 7) {
                setControlStage(5)
                speakButtonHandler(String(SlutMode))
            }

        } else if (String(recognizedMessage).toLowerCase() === 'maid' || String(messageToSpeak).toLowerCase() === 'maid') {
            if (controlStage === 3 || controlStage === 7) {
                setControlStage(6)
                speakButtonHandler(String(MaidMode))
            }

        } else {
            setControlStage(7)
            speakButtonHandler(String(unclear))
        }

    }


    let controlConversation = null

    controlConversation =
        <Aux>


            {controlStage === 0 ?
                <Aux>
                    <h3>Your control</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={BrainHackStart} speed={50} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={800 + duration}>
                            {isListening ? (
                                <Aux>
                                    <p>Say <strong>"Yes"</strong> to proceed</p>
                                    <div>
                                        {/* <input
                                        onChange={messageInputHandler}
                                        className={classes.InputElement}
                                        type="text"
                                        // maxlength="200"
                                        placeholder="Type 'yes' or 'no'"
                                    />
                                 <Button btnType="Success" clicked={speakClickHandler}>Answer Now</Button> */}
                                    </div>
                                </Aux>
                            ) : (
                                <Aux>
                                    <div>
                                        <p>Choose or Say <strong>"Yes"</strong> to proceed</p>
                                        <Button btnType="Success" clicked={() => setTopicAnswerHandler('yes')}>Yes, I want my brain to be hacked</Button><br></br>
                                        <Button btnType="Success" clicked={speakClickHandler}>Speak</Button>
                                    </div>
                                </Aux>
                            )}
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
            {controlStage === 1 ?
                <Aux>
                    <h3>Your control</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={BrainHackInit} speed={55} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={800 + duration1}>
                            {isListening ? (
                                <Aux>
                                    <p>Say <strong>"Yes"</strong> to proceed</p>
                                    <div>
                                        {/* <input
                                        onChange={messageInputHandler}
                                        className={classes.InputElement}
                                        type="text"
                                        // maxlength="200"
                                        placeholder="Type 'yes' or 'no'"
                                    />
                                 <Button btnType="Success" clicked={speakClickHandler}>Answer Now</Button> */}
                                    </div>
                                </Aux>
                            ) : (
                                <Aux>
                                    <div>
                                        <p>Choose or Say <strong>"Yes"</strong> to proceed</p>
                                        <Button btnType="Success" clicked={() => setTopicAnswerHandler('yes')}>Yes, I am ready</Button><br></br>
                                        <Button btnType="Success" clicked={speakClickHandler}>Speak</Button>
                                    </div>
                                </Aux>
                            )}
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
            {controlStage === 2 ?
                <Aux>
                    <h3>Your control</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={BrainHackPercentage} speed={50} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={800 + duration2}>
                            {isListening ? (
                                <Aux>
                                    <p>Say <strong>"Yes"</strong> to proceed</p>
                                    <div>
                                        {/* <input
                                        onChange={messageInputHandler}
                                        className={classes.InputElement}
                                        type="text"
                                        // maxlength="200"
                                        placeholder="Type 'yes' or 'no'"
                                    />
                                 <Button btnType="Success" clicked={speakClickHandler}>Answer Now</Button> */}
                                    </div>
                                </Aux>
                            ) : (
                                <Aux>
                                    <div>
                                        <p>Choose or Say <strong>"Yes"</strong> to proceed</p>
                                        <Button btnType="Success" clicked={() => setTopicAnswerHandler('yes')}>Yes, I am ready</Button><br></br>
                                        <Button btnType="Success" clicked={speakClickHandler}>Speak</Button>
                                    </div>
                                </Aux>
                            )}
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
            {controlStage === 3 ?
                <Aux>
                    <h3>Your control</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={ModeSelection} speed={55} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={800 + duration3}>
                            {isListening ? (
                                <Aux>
                                    <p>Say <strong>"Host"</strong>, <strong>"Slut"</strong> or <strong>"Maid"</strong> to proceed</p>
                                    <div>
                                        {/* <input
                                        onChange={messageInputHandler}
                                        className={classes.InputElement}
                                        type="text"
                                        // maxlength="200"
                                        placeholder="Type 'yes' or 'no'"
                                    />
                                 <Button btnType="Success" clicked={speakClickHandler}>Answer Now</Button> */}
                                    </div>
                                </Aux>
                            ) : (
                                <Aux>
                                    <div>
                                        <p>Choose or Say <strong>"Host"</strong>, <strong>"Slut"</strong> or <strong>"Maid"</strong> to proceed</p>
                                        <Button btnType="Success" clicked={() => setTopicAnswerHandler('host')}>Host Mode</Button><br></br>
                                        <Button btnType="Success" clicked={() => setTopicAnswerHandler('slut')}>Slut Mode</Button><br></br>
                                        <Button btnType="Success" clicked={() => setTopicAnswerHandler('maid')}>Maid Mode</Button><br></br>
                                        <Button btnType="Success" clicked={speakClickHandler}>Speak</Button>
                                    </div>
                                </Aux>
                            )}
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
            {controlStage === 4 ?
                <Aux>
                    <h3>Your control</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={HostMode} speed={50} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={800 + duration4}>
                            
                            <Button btnType="Success" clicked={endConversation}>Deactivate Host Mode</Button>
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
            {controlStage === 5 ?
                <Aux>
                    <h3>Your control</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={SlutMode} speed={50} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={800 + duration5}>
                            
                            <Button btnType="Success" clicked={endConversation}>Deactivate Slut Mode</Button>
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
            {controlStage === 6 ?
                <Aux>
                    <h3>Your control</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={MaidMode} speed={50} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={800 + duration6}>
                            
                            <Button btnType="Success" clicked={endConversation}>Deactivate Maid Mode</Button>
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
            {controlStage === 7 ?
                <Aux>
                    <h3>Your control</h3>
                    <div className={classes.texting}>
                        <Delay wait={800}>
                            <p><TypingEffect typingDelay={100} text={unclear} speed={50} eraseDelay={1000000} /></p>
                        </Delay>
                        <Delay wait={800 + duration7}>
                            {isListening ? (
                                <Aux>
                                    <p>Say <strong>"Hack"</strong>, <strong>"Host"</strong>, <strong>"Slut"</strong> or <strong>"Maid"</strong> to proceed</p>
                                    <div>
                                        {/* <input
                                        onChange={messageInputHandler}
                                        className={classes.InputElement}
                                        type="text"
                                        // maxlength="200"
                                        placeholder="Type 'yes' or 'no'"
                                    />
                                 <Button btnType="Success" clicked={speakClickHandler}>Answer Now</Button> */}
                                    </div>
                                </Aux>
                            ) : (
                                <Aux>
                                    <div>
                                        <p>Choose or Say <strong>"Hack"</strong>, <strong>"Host"</strong>, <strong>"Slut"</strong> or <strong>"Maid"</strong> to proceed</p>
                                        <Button btnType="Success" clicked={() => setTopicAnswerHandler('hack')}>Hack</Button><br></br>
                                        <Button btnType="Success" clicked={() => setTopicAnswerHandler('host')}>Host Mode</Button><br></br>
                                        <Button btnType="Success" clicked={() => setTopicAnswerHandler('slut')}>Slut Mode</Button><br></br>
                                        <Button btnType="Success" clicked={() => setTopicAnswerHandler('maid')}>Maid Mode</Button><br></br>
                                        <Button btnType="Success" clicked={speakClickHandler}>Speak</Button>
                                    </div>
                                </Aux>
                            )}
                        </Delay>
                    </div>
                    <p className={classes.Note}>Note: Do not switch tabs while running the session</p>
                </Aux> : null}
        </Aux>

    if (endConv) {
        controlConversation = <Redirect to="/" />
    }

    return (
        <Aux>
            {controlConversation}
        </Aux>
    )
};

const mapStatesToProps = state => {
    return {
        name: state.auth.accountDetails.name,
        title: state.auth.title,
        profileOptions: state.options.profileOptions,
    }
}

export default connect(mapStatesToProps)(Control)

