import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    name: null,
    mantra: [],
    mantraNum: [],
    error: false,
    saved: false,
    selectMethod: false,
}

const saveName = (state, action) => {
    return updateObject(state, {
        name: action.nameData,
        saved: true,
        selectMethod: true
    })
}

const fetchNameSuccess = (state, action) => {
    return updateObject(state, {
        mantra: action.mantra,
        mantraNum: action.mantraNum
    })
}

const fetchNameFail = (state, action) => {
    return updateObject(state, {
        error: true
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SAVE_NAME: return saveName(state, action)
        case actionTypes.FETCH_MANTRA_SUCCESS: return fetchNameSuccess(state, action)
        case actionTypes.FETCH_MANTRA_FAIL: return fetchNameFail(state, action)
        default:
            return state

    }
}

export default reducer