import React, { Component } from 'react';
import Aux from '../../../../hoc/auxiliary/auxiliary'
import classes from '../HypnosisShowMenu.module.scss'
// import classes2 from '../ExperienceMenu/ExperienceMenu.module.scss'
import HypnosisShow from '../../../../assets/Images/Blank.png'
import FingerSnap from '././HypnosisShowVolSnap'
import ForeHeadTap from '././HypnosisShowVolForeheadTap'
import HypnoGun from '././HypnosisShowVolHypnoGun'
import SelfSnap from '././HypnosisShowVolSelfSnap'
import WakeUpVol from './HypnosisShowVolWakeUp'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'

class HypnosisShowSuggestionChoices extends Component {

    state = {
        suggestionsChoices: true,
        fingerSnap: false,
        foreheadTap: false,
        hypnoGun: false,
        countdownSnap: false,
        wakeUp: false
    }

    fingerSnapHandler = () => {
        this.setState({ suggestionsChoices: false, fingerSnap: true })
    }

    foreheadTapHandler = () => {
        this.setState({ suggestionsChoices: false, foreheadTap: true })
    }

    hypnoGunHandler = () => {
        this.setState({ suggestionsChoices: false, hypnoGun: true })
    }

    countdownSnapHandler = () => {
        this.setState({ suggestionsChoices: false, countdownSnap: true })
    }

    wakeUpHandler = () => {
        this.setState({ suggestionsChoices: false, wakeUp: true })
    }


    render() {
        let HypnosisShowForm = null
        if (this.state.suggestionsChoices) {
            HypnosisShowForm = (
                <html style={{ backgroundImage: `url(${HypnosisShow})` }}>
                    <div className={classes.storyFrom}>
                        <p>
                        <i>“Ready for some amazing experiences?”</i><br></br> 
                        He asks as you nod enthusiastically excited for what he has in store for you. <br></br> 
                        <i>“In a moment I will wake you up, we have a nice chat and talk. Everything is normal & ordinary, you feeling very good, happy, relaxed & comfortable. 
                        It's all pretty normal that is until I…” </i>
                        </p>
                        <form className={classes.centered} onClick={this.fingerSnapHandler}>
                            <h3 className={classes.storyButtonSize}>Snap my Fingers</h3>
                        </form>
                        <form className={classes.centered} onClick={this.foreheadTapHandler}>
                            <h3 className={classes.storyButtonSize}>Tap your Forehead</h3>
                        </form>
                        <form className={classes.centered} onClick={this.hypnoGunHandler}>
                            <h3 className={classes.storyButtonSize}>Use my Hypno Gun</h3>
                        </form>
                        <form className={classes.centered} onClick={this.countdownSnapHandler}>
                            <h3 className={classes.storyButtonSize}>Countdown & Self Drop you</h3>
                        </form>
                        <form className={classes.centered} onClick={this.wakeUpHandler}>
                            <h3 className={classes.storyButtonSize}>Wake you Up</h3>
                        </form>
                    </div>
                </html>
            )
        }
        if (this.state.fingerSnap) {
            HypnosisShowForm = (
                <FingerSnap />
            )
        }

        if (this.state.foreheadTap) {
            HypnosisShowForm = (
                <ForeHeadTap />
            )
        }

        if (this.state.hypnoGun) {
            HypnosisShowForm = (
                <HypnoGun />
            )
        }

        if (this.state.countdownSnap) {
            HypnosisShowForm = (
                <SelfSnap />
            )
        }

        if (this.state.wakeUp) {
            HypnosisShowForm = (
                <WakeUpVol />
            )
        }

        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {HypnosisShowForm}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >




        )
    }
}


export default HypnosisShowSuggestionChoices