import React, { Component } from 'react';
import Aux from '../../../../hoc/auxiliary/auxiliary';
import classes from '../HypnosisShowMenu.module.scss'
// import classes2 from '../ExperienceMenu/ExperienceMenu.module.scss'
import HypnosisShow from '../../../../assets/Images/Blank.png'
import Snap from './HypnosisShowNonVolSnap1'
import HypnoGun from './HypnosisShowNonVolHypnoGun'
import SelfSnap from './HypnosisShowNonVolSelfSnap'
import WakeUp from './HypnosisShowNonVolWakeUp'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'

class HypnosisShowForeheadTap extends Component {

    state = {
        foreheadTap: true,
        fingerSnap: false,
        hypnoGun: false,
        countdownSnap: false,
        wakeUp: false
    }

    fingerSnapHandler = () => {
        this.setState({ foreheadTap: false, fingerSnap: true })
    }


    hypnoGunHandler = () => {
        this.setState({ foreheadTap: false, hypnoGun: true })
    }

    countdownSnapHandler = () => {
        this.setState({ foreheadTap: false, countdownSnap: true })
    }

    wakeUpHandler = () => {
        this.setState({ foreheadTap: false, wakeUp: true })
    }


    render() {
        let HypnosisShowForm = null
        if (this.state.foreheadTap) {
            HypnosisShowForm = (
                <html style={{ backgroundImage: `url(${HypnosisShow})` }}>
                    <div className={classes.storyFrom}>
                        <p>
                        <i>“Tap your forehead. Like a switch turned off in your mind, your body slumps as you fall down instantly. Falling down again into an even deeper trance. Into an even deeper and more relaxing trance, understood?" </i><br></br>
                        You wonder to yourself <i>"Will he get off stage to tap your forehead or will he do it some other way? What trick does he have up his sleeve?"</i><br></br>
                        Before your mind starts running wild with different scenarios he starts waking you up <br></br>
                        <i>“Waking up on the count of five. <br></br>
                        One, starting to wake up. <br></br>
                        Two, feeling wonderful and refreshed. <br></br>
                        Three, Excited & happy. <br></br>
                        Four, Eager to participate and join in the experience<br></br>
                        Five, wide awake now!”</i><br></br>
                        <strong>*SNAP*</strong><br></br>
                        <i>“Smiling, stretching & breathing deep, Welcome back! How are you all feeling? All good?” </i><br></br>
                        Stretching and taking a deep breath while watching him walk towards the first person sitting in the volunteers telling her <br></br>
                        <i>“You having a good time?”</i> She looks up at him and nods slowly with a smile on her face looking a bit out of it <br></br>
                        <i>“Wonderful!”</i> He say nonchalantly while tapping her forehead. You watch in awe how her body shuts down as soon as he taps her forehead with her head falling safely backward and she drops back into trance. <br></br> 
                        Moving towards the next guy seeing he’s already under just from seeing the lady before him dropped The Great O looks at you with a smirk on his face. He winks to the audience as they laugh and applaud him while 
                        you look at him with wide eyes laser focused on his every movement drawn by him. <br></br>
                        Caught, captured and enthralled by him while he stands behind one of the volunteers & leans next to their ear saying <i>“Enjoying yourself?”</i> In a mix of excitement & nervousness they nod as he says <br></br>
                        <i>“Perfect” </i><br></br>
                        Raising his hand in front of their face seeing their body jumping a little instantly freezing in place with their eyes fixated on him.  
                        You look at their hand as well and feel yourself drawn to them, fixated on him and his fingers as they slowly approach, as they slowly approach their forehead now, almost touching it. 
                        Your eyes locked on to him and his hands hearing him say <br></br>
                        <strong>“SLEEP NOW”</strong> <br></br>
                        You feel yourself dropping a little with them, entering this fuzzy & floaty state almost feeling his finger on your forehead. 
                        Smiling, you see him at the center of the stage saying <br></br>
                        <i>“As for the lovely people in the audience. You’ll help me out in dropping yourself” </i>
                        Knowing you’re helping him drop you even deeper makes you feel really good, smiling wide ready & excited to do as you're told <br></br> 
                        <i>“I want you to raise your left hand in front of you, point it towards your forehead and..."</i> 
                        <strong>*snap*</strong> finding your hands now attracted to your forehead like a magnet. 
                        Getting closer & closer to your forehead. The more you try to stop it, the stronger that attraction becomes. <br></br> 
                        <i>"In a moment you will tap your forehead for me. As soon as you do you’ll all drop, drop deep.
                            You'll drop so deep for me, falling further and further down” </i> <br></br>
                        You’ve been focusing on your finger from the moment he instructed you to point it towards yourself and snapped his fingers. You have no control over it, all you can do is follow it with your eyes getting closer to your forehead. 
                        Your heart racing while you helpless watch yourself dropping yourself for him. Loving that so much. Loving that he is this deep inside your head now.
                        You’ve heard everything he said and you know that it is the truth by now, you know that everything he said will happen and you are happy that it will. <br></br>
                        You see your finger now getting closer, closer & closer to your forehead unable to stop it. 
                        You accept it, you accept that you will fall. You embrace with joy and happiness that he has you controlled like this. Feeling your finger tap your forehead... <br></br>
                        <strong>NOW!</strong><br></br>
                        Deep you go, deep you drop. Deeper & deeper you fall and let his voice and words fill your mind so much more. <br></br>
                        <i>"Dropping deeper & deeper, sinking more, drifting and floating further. Drifting & floating further into trance. All you want is have me…”</i>
                        </p>
                        <form className={classes.centered} onClick={this.fingerSnapHandler}>
                            <h4 className={classes.storyButtonSize}>Snap my Fingers</h4>
                        </form>
                        <form className={classes.centered} onClick={this.hypnoGunHandler}>
                            <h4 className={classes.storyButtonSize}>Use my Hypno Gun</h4>
                        </form>
                        <form className={classes.centered} onClick={this.countdownSnapHandler}>
                            <h4 className={classes.storyButtonSize}>Make you Countdown & Snap your Fingers</h4>
                        </form>
                        <form className={classes.centered} onClick={this.wakeUpHandler}>
                            <h4 className={classes.storyButtonSize}>Wake you Up</h4>
                        </form>
                    </div>
                </html>
            )
        }
        if (this.state.fingerSnap) {
            HypnosisShowForm = (
                <Snap />
            )
        }

        if (this.state.hypnoGun) {
            HypnosisShowForm = (
                <HypnoGun />
            )
        }

        if (this.state.countdownSnap) {
            HypnosisShowForm = (
                <SelfSnap />
            )
        }

        if (this.state.wakeUp) {
            HypnosisShowForm = (
                <WakeUp />
            )
        }

        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {HypnosisShowForm}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >




        )
    }
}


export default HypnosisShowForeheadTap