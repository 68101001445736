import React from 'react'
import classes from '../../../containers/Account/Account.module.css'
import Input from '../../UI/Input/Input'
import Button from '../../UI/Button/Button'
import Aux from '../../../hoc/auxiliary/auxiliary'

const changeData = (props) => {

    let changeDataForm = (
        <Aux>
            <div className={classes.AccountContainer}>
                    <form
                        onSubmit={props.registerFormHandler}
                        className={classes.FormStyle}>
                        <p className={classes.error}>{props.message}</p>
                        <h2>Change Account Details</h2>
                        <p className={classes.smallText}>You want to become the best host ever</p>
                        {props.changeInfoFormElementsArray.map(formElement => (
                            <Input
                                key={formElement.id}
                                elementType={formElement.config.elementType}
                                elementConfig={formElement.config.elementConfig}
                                value={formElement.config.value}
                                invalid={!formElement.config.valid}
                                shouldValidate={formElement.config.validation}
                                touched={formElement.config.touched}
                                changed={(event) => props.inputChangeInfoHandler(event, formElement.id)} />
                        ))}
                    </form>
                    <div className={classes.AuthButtons}>
                        <Button btnType="Success" clicked={() => props.onChangeEmail(props.token, props.newEmail, props.userId)}>Change Email</Button>
                        <Button btnType="Success" clicked={() => props.onChangePassword(props.token, props.newPassword, props.userId)}>Change Password</Button><br></br>
                        <Button btnType="Danger" clicked={props.ReturnBackHandler}>Back</Button>
                    </div>
                </div>
        </Aux>)


    return changeDataForm
}

export default changeData

         // <div className={classes.AccountContainer}>
                //     <form
                //         onSubmit={this.registerFormHandler}
                //         className={classes.FormStyle}>
                //         <p className={classes.error}>{this.state.message}</p>
                //         <h2>Change Account Details</h2>
                //         <p className={classes.smallText}>You want to become the best host ever</p>
                //         {changeInfoFormElementsArray.map(formElement => (
                //             <Input
                //                 key={formElement.id}
                //                 elementType={formElement.config.elementType}
                //                 elementConfig={formElement.config.elementConfig}
                //                 value={formElement.config.value}
                //                 invalid={!formElement.config.valid}
                //                 shouldValidate={formElement.config.validation}
                //                 touched={formElement.config.touched}
                //                 changed={(event) => this.inputChangeInfoHandler(event, formElement.id)} />
                //         ))}
                //     </form>
                //     <div className={classes.AuthButtons}>
                //         <Button btnType="Success" clicked={() => this.props.onChangeEmail(this.props.token, this.state.ChangeInfoForm.newEmail.value, this.props.userId)}>Change Email</Button>
                //         <Button btnType="Success" clicked={() => this.props.onChangePassword(this.props.token, this.state.ChangeInfoForm.newPassword.value, this.props.userId)}>Change Password</Button>
                //     </div>

                // </div>