import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '../../../UI/Button/Button'
import classes from '../../../../containers/HostsChamber/HostsChamber.module.css'
import classes2 from '../../../../containers/HostsChamber/AudioRuns/AudioRuns.module.css'
import Aux from '../../../../hoc/auxiliary/auxiliary'
import Background from '../../../Background/Background'
import ColorsVisual from './Tentacles_Reinforcement/ColorsVisual'
import FlashyVisual from './Tentacles_Reinforcement/FlashyVisual'
import ChloroformReinforcement from './Chloroform_Reinforcement/Chloroform_Reinforcement'
import MotorFunctionControlReinforcement from './Motor_Function_Control_Reinforcement/Motor_Function_Control_Reinforcement'
import WebsiteAudio from '../../../Background/WebsiteAudio';
import * as actions from '../../../../store/actions/index';
import TentaclesReinforcementOpening from '../Trigger_Reinforcement/Tentacles_Reinforcement/Tentacles_Reinforcement_Opening'


class TriggerReinforcement extends Component {
    state = {
        trigger_reinforcement: true,
        tentacles_reinforcement: false,
        chloroform_reinforcement: false,
        motor_function_control_reinforcement: false
    }

    enterTentaclesReinforcementHandler = () => {
        this.setState({ trigger_reinforcement: false, tentacles_reinforcement: true })
    }

    enterChloroformReinforcementHandler = () => {
        this.setState({ trigger_reinforcement: false, chloroform_reinforcement: true })
    }

    enterMotorFunctionControlReinforcementHandler = () => {
        this.setState({ trigger_reinforcement: false, motor_function_control_reinforcement: true })
    }

    ReturnHandler = () => {
        this.props.onReturnHostsChamber()
    }

    render() {
        let audioRunForm = null
        if (this.state.trigger_reinforcement) {
            audioRunForm = (
                <Aux>
                    <div className={classes.HostsChamber}>
                        <Background />
                        <WebsiteAudio />
                        <div className={classes.FormStyle}>
                            <h3>Experience The Power of Our Voice, {this.props.hostName}</h3>
                            <div className={classes.HostButtons}>
                                <Button btnType="Success" className={classes.HostChamberButton} clicked={this.enterTentaclesReinforcementHandler}>T.E.N.T.A.C.L.E. Reinforcement</Button><br></br>
                                <Button btnType="Success" className={classes.HostChamberButton} clicked={this.enterChloroformReinforcementHandler}>Chloroform Reinforcement</Button><br></br>
                                <Button btnType="Success" className={classes.HostChamberButton} clicked={this.enterMotorFunctionControlReinforcementHandler}>Motor Function <br></br>Control Reinforcement</Button><br></br>
                                <Button btnType="Danger" clicked={this.ReturnHandler}>Return To Hosts Chamber</Button>
                            </div>
                        </div>
                    </div>
                </Aux>
            )
        }

        if (this.state.tentacles_reinforcement) {
            if (this.props.profileOptions.audio_run_background === '0') {
                audioRunForm = (
                    <Aux>
                        <div className={classes2.Grouping}>
                            <p><TentaclesReinforcementOpening background={this.props.profileOptions.audio_run_background}/></p>
                        </div>
                    </Aux>
                )
            } else if (this.props.profileOptions.audio_run_background === '1') {
                audioRunForm = (
                    <Aux>
                        <FlashyVisual background={this.props.profileOptions.audio_run_background} />
                    </Aux>
                )
            } else if (this.props.profileOptions.audio_run_background === '2') {
                audioRunForm = (
                    <Aux>
                        <ColorsVisual background={this.props.profileOptions.audio_run_background} />
                    </Aux>
                )
            }

        }

        if (this.state.chloroform_reinforcement) {
            audioRunForm = (
                <Aux>
                    <ChloroformReinforcement background={this.props.profileOptions.audio_run_background} />
                </Aux>
            )
        }

        if (this.state.motor_function_control_reinforcement) {
            audioRunForm = (
                <Aux>
                    <MotorFunctionControlReinforcement background={this.props.profileOptions.audio_run_background} />
                </Aux>
            )
        }

        return (audioRunForm)
    }
}

const mapStateToProps = state => {
    return {
        hostName: state.auth.accountDetails.name,
        runSpeed: state.auth.accountDetails.speed,
        hostRun: state.auth.accountDetails.Host_Runs,
        token: state.auth.token,
        profileOptions: state.options.profileOptions,

    }
}

const mapDispatchToProps = dispatch => {
    return {
        onReturnHostsChamber: () => dispatch(actions.onReturnHostsChamber()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TriggerReinforcement)