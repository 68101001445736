import * as actionTypes from './authTypes'
import axios from 'axios'
import customAxios from '../../../axios';


export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
}

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    }
}

//-----------------------------FETCH PROFILE DETAILS---------------------------------

export const fetchProfile = (token, userId) => {
    return dispatch => {
        dispatch(authStart());
        // const queryParams = '?auth=' + token + '&orderBy="userId"&equalTo="' + userId + '"'
        customAxios.get('/Accounts/' + userId + '.json?auth=' + token)
            .then(response => {
                const AccountInfo = [];

                AccountInfo.push({
                    ...response.data,
                });
                dispatch(fetchProfileSuccess(AccountInfo[0]))
            })
            .catch(error => {
                // console.log(error.response.data.error)
                dispatch(authFail(error.response.data.error))
            })
    }
}


export const fetchProfileSuccess = (accountDetails) => {
    return {
        type: actionTypes.FETCH_ACCOUNT_DETAILS,
        accountDetails: accountDetails
    }
}

//------------------------------REMOVE USER -----------------------------------------

export const removeAccount = (token, userId) => {
    return dispatch => {
        dispatch(authStart());
        let url = "https://identitytoolkit.googleapis.com/v1/accounts:delete?key=AIzaSyCJwAwwwxPnkndoDEOWO54JwgR6tOeNRo8"
        // const queryParams = '?auth=' + token + '&equalTo="' + userId + '"'
        let newtoken = localStorage.getItem('token')
        const tokenData = {
            idToken: newtoken
        }
        axios.post(url, tokenData)
            .then(response => {

                customAxios.delete('/Accounts/' + userId + '.json?auth=' + token)
                    .then(response => {
                    })
                    .catch(error => {
                        // console.log(error.response.data.error.message)
                        dispatch(authFail(error.response.data.error.message))
                    })

                customAxios.delete('/Runs/' + userId + '.json?auth=' + token)
                    .then(response => {
                    })
                    .catch(error => {
                        // console.log(error.response.data.error.message)
                        dispatch(authFail(error.response.data.error.message))
                    })

                customAxios.delete('/VerifiedAccounts/' + userId + '/.json?auth=' + token)
                    .then(response => {
                    })
                    .catch(error => {
                        dispatch(authFail(error.response.data.error.message))
                    })
                dispatch(deleteSuccess())
            })
            .catch(error => {
                // console.log(error.response.data.error.message)
                dispatch(authFail(error.response.data.error.message))
            })



    }
}

export const deleteSuccess = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('expirationDate')
    localStorage.removeItem('userId')
    return {
        type: actionTypes.DELETE_ACCOUNT_SUCCESS
    }
}

//----------------------------------------CHANGE PASSWORD-------------------------------

export const changePassword = (token, password, userId) => {
    return dispatch => {
        dispatch(authStart());
        let url = "https://identitytoolkit.googleapis.com/v1/accounts:update?key=AIzaSyCJwAwwwxPnkndoDEOWO54JwgR6tOeNRo8"
        let newtoken = localStorage.getItem('token')
        const passwordData = {
            idToken: newtoken,
            password: password,
            returnSecureToken: true
        }
        axios.post(url, passwordData)
            .then(response => {
                let currentToken = response.data.idToken
                const expirationDate = new Date(new Date().getTime() + response.data.expiresIn * 1000)
                localStorage.setItem('token', response.data.idToken);
                localStorage.setItem('expirationDate', expirationDate)
                localStorage.setItem('userId', response.data.localId)

                customAxios.get('/Accounts/' + userId + '.json?auth=' + token)
                    .then(response => {
                        const AccountInfo = [];

                        AccountInfo.push({
                            ...response.data,
                        });
                        dispatch(changePasswordSuccess(currentToken, password))
                        dispatch(updatePassword(AccountInfo[0], password, token, userId))
                    })
                    .catch(error => {
                        // console.log(error.response.data.error)
                        dispatch(authFail(error.response.data.error))
                    })
            })
            .catch(error => {
                // console.log(error.response.data.error.message)
                dispatch(authFail(error.response.data.error.message))
            })


    }
}

export const updatePassword = (AccountInfo, password, token, userId) => {
    return dispatch => {
        let updatedPasswordAccount = {
            email: AccountInfo.email,
            name: AccountInfo.name,
            gender: AccountInfo.gender,
            speed: AccountInfo.speed,
            speedName: AccountInfo.speedName,
            Host_Runs: AccountInfo.Host_Runs,
            password: password,
            userId: AccountInfo.userId
        }
        customAxios.put('/Accounts/' + userId + '.json?auth=' + token, updatedPasswordAccount)
            .then(response => {
                dispatch(fetchProfile(token, userId))
            })
            .catch(error => {
                // console.log(error.response)
                dispatch(authFail(error.response.data.error.message))
            })
    }
}

export const changePasswordSuccess = (token, password) => {
    return {
        type: actionTypes.CHANGE_PASSWORD_SUCCESS,
        token: token,
        password: password

    }
}

export const changeEmailPasswordView = (password) => {
    return {
        type: actionTypes.CHANGE_EMAIL_PASSWORD_VIEW,
        password: password,
    }
}
//-------------------------------CHANGE EMAIL-------------------------------------

export const changeEmail = (token, email, userId) => {
    return dispatch => {
        dispatch(authStart());
        let url = "https://identitytoolkit.googleapis.com/v1/accounts:update?key=AIzaSyCJwAwwwxPnkndoDEOWO54JwgR6tOeNRo8"
        let newtoken = localStorage.getItem('token')
        const emailData = {
            idToken: newtoken,
            email: email,
            returnSecureToken: true
        }
        axios.post(url, emailData)
            .then(response => {
                // console.log(response)
                let currentToken = response.data.idToken
                const expirationDate = new Date(new Date().getTime() + response.data.expiresIn * 1000)
                localStorage.setItem('token', response.data.idToken);
                localStorage.setItem('expirationDate', expirationDate)
                localStorage.setItem('userId', response.data.localId)
                customAxios.get('/Accounts/' + userId + '.json?auth=' + token)
                    .then(response => {
                        const AccountInfo = [];

                        AccountInfo.push({
                            ...response.data,
                        });
                        dispatch(updateEmail(AccountInfo[0], email, token, userId))

                        dispatch(changeEmailSuccess(currentToken, email))
                    })
                    .catch(error => {
                        // console.log(error.response.data.error)
                        dispatch(authFail(error.response.data.error))
                    })
            })
            .catch(error => {
                // console.log(error.response.data.error.message)
                dispatch(authFail(error.response.data.error.message))
            })


    }
}

export const updateEmail = (AccountInfo, email, token, userId) => {
    return dispatch => {
        let updatedPasswordAccount = {
            email: email,
            name: AccountInfo.name,
            gender: AccountInfo.gender,
            speed: AccountInfo.speed,
            speedName: AccountInfo.speedName,
            Host_Runs: AccountInfo.Host_Runs,
            password: AccountInfo.password,
            userId: AccountInfo.userId
        }
        customAxios.put('/Accounts/' + userId + '.json?auth=' + token, updatedPasswordAccount)
            .then(response => {

            })
            .catch(error => {
                // console.log(error.response)
                dispatch(authFail(error.response.data.error.message))
            })
    }
}

export const changeEmailSuccess = (token, email) => {
    return {
        type: actionTypes.CHNAGE_EMAIL_SUCCESS,
        token: token,
        email: email

    }
}

//-------------------------------CREATE PROFILE---------------------------------

export const Account = (accountDetail, token, userId) => {
    return dispatch => {
        dispatch(authStart());
        customAxios.put('/Accounts/' + userId + '.json?auth=' + token, accountDetail)
            .then(response => {
                dispatch(createAccount(token, accountDetail));
                dispatch(createRunProfile(token, userId))
                dispatch(fetchProfile(token, userId))
            })
            .catch(error => {
                // console.log(error.response)
                dispatch(authFail(error.response.data.error.message))
            })
    }
}

export const createRunProfile = (token, userId) => {
    return dispatch => {
        dispatch(authStart());
        const HostsRun = {
            BellInduction: 0,
            ForeheadTapInduction: 0,
            MetronomeDeepener: 0,
            TickingClockDeepener: 0,
            Mantra: 0,
            TriggerCommand: 0,
            TriggerRepetition: 0,
            AwakenerRemember: 0,
            AwakenerForget: 0,
            Hosts_Run: 0
        }
        let verifiedAccount = true
        customAxios.put('/VerifiedAccounts/' + userId + '/.json?auth=' + token, verifiedAccount)
            .then(response => {
            })
            .catch(error => {
                dispatch(authFail(error.response.data.error.message))
            })


        customAxios.put('/Runs/' + userId + '/Hosts_Run.json?auth=' + token, HostsRun)
            .then(response => {
            })
            .catch(error => {
                dispatch(authFail(error.response.data.error.message))
            })
    }
}

export const createAccount = (token, accountDetail) => {
    return {
        type: actionTypes.CREATE_ACCOUNT_SUCCESS,
        accountDetail: accountDetail
    }
}


//---------------------------Edit Profile------------------------------

export const editProfile = (accountDetail, token, userId) => {
    return dispatch => {
        dispatch(authStart());
        customAxios.put('/Accounts/' + userId + '.json?auth=' + token, accountDetail)
            .then(response => {

                let AccountInfo = []
                AccountInfo.push({
                    ...response.data
                })
                dispatch(editProfileSuccess(AccountInfo))
                dispatch(fetchProfile(token, userId))
            })
            .catch(error => {
                dispatch(authFail(error.response.data.error.message))
            })
    }
}

export const editProfileView = () => {
    return {
        type: actionTypes.EDIT_PROFILE_VIEW
    }
}

export const editProfileSuccess = (AccountInfo) => {
    return {
        type: actionTypes.EDIT_PROFILE_SUCCESS,
        accountDetails: AccountInfo
    }
}

//-------------------------------OPTIONS VIEW ------------------------
export const viewOptionPage = () => {
    return {
        type: actionTypes.VIEW_OPTIONS_PAGE,
    }
}