import React from 'react'
import classes from '../../../containers/Account/Account.module.css'
import Button from '../../UI/Button/Button'
import Aux from '../../../hoc/auxiliary/auxiliary'

const hostRun = (props) => {

    let hostRunForm = (
        <Aux>
            <div className={classes.AccountContainer}>
                    <form className={classes.FormStyle}>
                        <h2>Host Run Details</h2>
                        <p className={classes.smallText}>Every Run Drops you deeper</p>
                        <div>
                            <h3>Host Run: </h3>
                            <strong>Host Run: {props.viewHostRunDetails.Hosts_Run} </strong>
                        </div>
                        <div>
                            <h3>Induction: </h3>
                            <strong>Bell Inductions: {props.viewHostRunDetails.BellInduction}</strong> <br></br>
                            <strong>Forehead Tap Inductions: {props.viewHostRunDetails.ForeheadTapInduction}</strong>
                        </div>
                        <div>
                            <h3>Deepener: </h3>
                            <strong>Metronome Deepener: {props.viewHostRunDetails.MetronomeDeepener}</strong> <br></br>
                            <strong>Ticking Clock Deepener: {props.viewHostRunDetails.TickingClockDeepener}</strong>
                        </div>
                        <div>
                            <h3>Mantra: </h3>
                            <strong>Mantra: {props.viewHostRunDetails.Mantra}</strong>
                        </div>
                        <div>
                            <h3>Trigger: </h3>
                            <strong>Trigger Command: {props.viewHostRunDetails.TriggerCommand}</strong><br></br>
                            <strong>Trigger Repetition: {props.viewHostRunDetails.TriggerRepetition}</strong>
                        </div>
                        <div>
                            <h3>Awakener: </h3>
                            <strong>Awakener Remember: {props.viewHostRunDetails.AwakenerRemember}</strong><br></br>
                            <strong>Awakener Forget: {props.viewHostRunDetails.AwakenerForget}</strong>
                        </div>


                    </form>
                    <div className={classes.AuthButtons}>
                        <Button btnType="Danger" clicked={props.ReturnBackHandler}>Back</Button>
                    </div>

                </div>
        </Aux>)


    return hostRunForm
}

export default hostRun