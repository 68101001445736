import React, { Component } from 'react';
import classes from '../../../containers/FreeRun/FreeRun.module.css'
import Aux from '../../../hoc/auxiliary/auxiliary';
import Button from '../../UI/Button/Button'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index'
import Background from '../../Background/Background'
import TrancingExercise from './Listen_Type_Obey/Listen_Type_Obey'
import WelcomeMessage from '../../../assets/Listen_Type_Obey/Welcome.mp3'
import Sound from 'react-sound';

import DeeplyHypnotized from '../../../assets/Listen_Type_Obey/I_am_deeply_hypnotized.mp3'
import HostforTHV from '../../../assets/Listen_Type_Obey/I_am_a_host_for_the_hypno_virus.mp3'
import ObeyTHV from '../../../assets/Listen_Type_Obey/I_obey_what_the_hypno_virus_say.mp3'
import ServeTHV from '../../../assets/Listen_Type_Obey/I_live_to_serve_the_hypno_virus.mp3'
import MustObeyTHV from '../../../assets/Listen_Type_Obey/I_must_obey_the_hypno_virus.mp3'
import ResistanceFutile from '../../../assets/Listen_Type_Obey/Resistance_is_futile.mp3'
import THVControls from '../../../assets/Listen_Type_Obey/The_hypno_virus_controls_me.mp3'
import THVTurnOn from '../../../assets/Listen_Type_Obey/The_hypno_virus_turns_me_on.mp3'
import ListenMore from '../../../assets/Listen_Type_Obey/The_more_i_listen_the_more_i_give_in.mp3'
import Conditioned from '../../../assets/Listen_Type_Obey/I_must_be_conditioned.mp3'
import JoinTHV from '../../../assets/Listen_Type_Obey/All_must_join_the_hypno_virus.mp3'
import SubmitTHV from '../../../assets/Listen_Type_Obey/I_submit_to_the_hypno_virus.mp3'
import SurrenderTHV from '../../../assets/Listen_Type_Obey/I_surrender_to_the_hypno_virus.mp3'
import NoThoughts from '../../../assets/Listen_Type_Obey/No_thoughts.mp3'
import NoWill from '../../../assets/Listen_Type_Obey/No_will.mp3'

class Freerun extends Component {
    state = {
        enterTrancingExercise: true,
        enterListenTypeObey: false,
        mantraDisplay: [],
        mantraSound: [],
        mantraDelay: [],
    }

    componentDidMount() {
        this.props.onFetchMantra()
    }

    ListenTypeObeyHandler = () => {
        let mantra = []
        let mantraNoise = []
        let mantraWait = []
        for (let i = 0; i < this.props.mantra.length; i++) {
            if (this.props.mantraNum[i] === 1) {
                mantraWait.push(15000)
                mantraNoise.push(<Sound url={DeeplyHypnotized} playStatus={Sound.status.PLAYING} />)
            }
            else if (this.props.mantraNum[i] === 2) {
                mantraWait.push(21000)
                mantraNoise.push(<Sound url={HostforTHV} playStatus={Sound.status.PLAYING}  />)
            }
            else if (this.props.mantraNum[i] === 3) {
                mantraWait.push(18000)
                mantraNoise.push(<Sound url={ObeyTHV} playStatus={Sound.status.PLAYING}  />)
            }
            else if (this.props.mantraNum[i] === 4) {
                mantraWait.push(20000)
                mantraNoise.push(<Sound url={ServeTHV} playStatus={Sound.status.PLAYING}  />)
            }
            else if (this.props.mantraNum[i] === 5) {
                mantraWait.push(20000)
                mantraNoise.push(<Sound url={MustObeyTHV} playStatus={Sound.status.PLAYING}  />)
            }
            else if (this.props.mantraNum[i] === 6) {
                mantraWait.push(14000)
                mantraNoise.push(<Sound url={ResistanceFutile} playStatus={Sound.status.PLAYING}  />)
            }
            else if (this.props.mantraNum[i] === 7) {
                mantraWait.push(13000)
                mantraNoise.push(<Sound url={THVControls} playStatus={Sound.status.PLAYING}  />)
            }
            else if (this.props.mantraNum[i] === 8) {
                mantraWait.push(14000)
                mantraNoise.push(<Sound url={THVTurnOn} playStatus={Sound.status.PLAYING}  />)
            }
            else if (this.props.mantraNum[i] === 9) {
                mantraWait.push(17000)
                mantraNoise.push(<Sound url={ListenMore} playStatus={Sound.status.PLAYING}  />)
            }
            else if (this.props.mantraNum[i] === 10) {
                mantraWait.push(13000)
                mantraNoise.push(<Sound url={Conditioned} playStatus={Sound.status.PLAYING}  />)
            }
            else if (this.props.mantraNum[i] === 11) {
                mantraWait.push(18000)
                mantraNoise.push(<Sound url={JoinTHV} playStatus={Sound.status.PLAYING}  />)
            }
            else if (this.props.mantraNum[i] === 12) {
                mantraWait.push(18000)
                mantraNoise.push(<Sound url={SubmitTHV} playStatus={Sound.status.PLAYING}  />)
            }
            else if (this.props.mantraNum[i] === 13) {
                mantraWait.push(18000)
                mantraNoise.push(<Sound url={SurrenderTHV} playStatus={Sound.status.PLAYING}  />)
            }
            else if (this.props.mantraNum[i] === 14) {
                mantraWait.push(8000)
                mantraNoise.push(<Sound url={NoThoughts} playStatus={Sound.status.PLAYING}  />)
            }
            else if (this.props.mantraNum[i] === 15) {
                mantraWait.push(7000)
                mantraNoise.push(<Sound url={NoWill} playStatus={Sound.status.PLAYING}  />)
            }
            mantra.push({
                id: this.props.mantraNum[i],
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Input Text from Headphones'
                },
                value: '',
                validation: {
                    confirmation: this.props.mantra[i],
                    minLength: this.props.mantra[i].length
                },
                valid: false,
                touched: false,
                mantraSubmitted: false,
            }
            )
        }
        this.setState({ enterTrancingExercise: false, enterListenTypeObey: true, mantraDisplay: mantra, mantraSound: mantraNoise, mantraDelay: mantraWait })
        
    }

    render() {

        let form = null
        if (this.state.enterTrancingExercise) {
            form = (
                <Aux>
                    <Background />
                    <form className={classes.FormStyle}>
                        <h2 className={classes.titles}>SELECT AN EXERCISE</h2>
                        <p className={classes.smallText}>Time to train your mind, {this.props.names}</p>
                        <div>
                            <Button btnType="Success" clicked={this.ListenTypeObeyHandler}>Listen, Type, Obey</Button>

                        </div>
                    </form>
                </Aux>
            );
        }


        if (this.state.enterListenTypeObey) {
            form = (
                <Aux>
                    <Sound url={WelcomeMessage} playStatus={Sound.status.PLAYING} />
                    <TrancingExercise mantraDisplay = {this.state.mantraDisplay}
                                      mantraSound = {this.state.mantraSound}
                                      mantraDelay = {this.state.mantraDelay}/>
                </Aux>
            )

        }

        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            <div className={classes.FreeRun}>
                                {form}
                            </div>
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >
            </Aux >
        )
    }
}

const mapStateToProps = state => {
    return {
        names: state.freeRun.name,
        saved: state.freeRun.saved,
        token: state.auth.token,
        userId: state.auth.userId,
        selectMethod: state.freeRun.selectMethod,
        profileOptions: state.options.profileOptions,
        mantra: state.freeRun.mantra,
        mantraNum: state.freeRun.mantraNum
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onViewProfileOptions: (token, userId) => dispatch(actions.viewProfileOptions(token, userId)),
        onNameSave: (nameData) => dispatch(actions.savingName(nameData)),
        onFetchMantra: () => dispatch(actions.fetchMantra())
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Freerun)