import React from 'react';
import TypingEffect from 'react-typing-effect';
import Delay from 'react-delay'
import Aux from '../../../../hoc/auxiliary/auxiliary'
import classes from '../../HostsRun/HostsRunComponent.module.css'


const ending = (props) => {
    // let praiseChoice = 0
    // praiseChoice = Math.floor(Math.random() * props.praises.length);
    // let praise = props.praises[praiseChoice]
    
    
    const endingMessage = "The Hypno Virus has taken control, " + // eslint-disable-next-line
                             (props.naming) + "\n\
                             The Hypno Virus is now in control\n\
                             You are its " + // eslint-disable-next-line
                             (props.title) + "\n\
                             We know you will return to Us\n\
                             We know you want more\n\
                             You want Us to control you\n\
                             You will return, " + // eslint-disable-next-line
                             (props.title) +" "+ (props.naming) +'\n\
                             Now it is time for Us to leave\n\
                             It is time for Us to log off\n\
                             Until next time, ' + 
                             (props.naming)
    let speed = parseInt(props.speed) +5
    let  duration = 170 + (endingMessage.length * speed)
    let endingParagraph = (
            <Aux>
                <h3 className= {classes.titles}>ENDING</h3>
                <Delay wait={5000}><TypingEffect typingDelay={100} text={endingMessage} speed={props.speed} eraseDelay={1000000} /></Delay>
                <Delay wait={duration+5000}><h4 className={classes.blinking} onClick={props.ending}>The Hypno Virus is Logging off...</h4></Delay>
            </Aux>
        )
    





    return endingParagraph
}


export default ending