import React, { Component } from 'react';
import Aux from '../../../../hoc/auxiliary/auxiliary';
import classes from '../HypnosisShowMenu.module.scss'
// import classes2 from '../ExperienceMenu/ExperienceMenu.module.scss'
import HypnosisShow from '../../../../assets/Images/Blank.png'
import Conclusion from './HypnosisShowNonVolConclusion'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'

class HypnosisShowFinalWords extends Component {

    state = {
        finalWords: true,
        conclusion: false
    }

    ConclusionHandler = () => {
        this.setState({ finalWords: false, conclusion: true })
    }


    render() {
        let HypnosisShowForm = null
        if (this.state.finalWords) {
            HypnosisShowForm = (
                <html style={{ backgroundImage: `url(${HypnosisShow})` }}>
                    <div className={classes.storyFrom}>
                        <p>
                        Stretching and opening your eyes returning to normal feeling so good. 
                        You sit up straight after slumping down in your seat. Feeling unbelievably relaxed & comfortable like waking up from a perfect nap you hear him say <br></br>
                        <i>“A round of applause for these amazing volunteers & for all of you lovely people out there! 
                        You’ve all been amazing and I hope to see you in my upcoming shows! 
                        You can take a flyer you will find at the exit if you want to book me for any public or private events!” </i><br></br>
                        You watch them still feeling a bit fuzzy but so good as the volunteers stand up and make their way down the stage. 
                        The Great O stands by the stairs smiling & shaking everyone’s hand as he hands out flyers to everyone. <br></br>
                        You don’t hear him talk to them but it all seems quite casual except for a second there you see a few of them nod as if back in trance only to immediately snap out of it and smile. <br></br>
                        You feel so curious to know what he said to them letting your imagination run wild with thoughts.
                        </p>
                        <form className={classes.centered} onClick={this.ConclusionHandler}>
                            <h4 className={classes.storyButtonSize}>Conclusion</h4>
                        </form>
                    
                    </div>
                </html>
            )
        }
        if (this.state.conclusion) {
            HypnosisShowForm = (
                <Conclusion />
            )
        }

    

        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {HypnosisShowForm}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >




        )
    }
}


export default HypnosisShowFinalWords