import * as audioRunTypes from '../audioRunTypes'
import axios from '../../../../axios'

export const TentacleIntroductionStart = () => {
    return {
        type: audioRunTypes.AUDIO_RUN_START
    }
}

export const TentacleIntroductionSuccess = (Tentacle_introduction) => {
        return {
            type: audioRunTypes.TENTACLE_INTRODUCTION_SUCCESS,
            Tentacle_introduction: Tentacle_introduction,
        }
   
}

export const TentacleIntroduction = (userId, token) => {
    return dispatch => {
        dispatch(TentacleIntroductionStart());
        axios.get('/Runs/'+userId+'/AudioRuns/Trigger_Introduction/Tentacle_Introduction.json?auth=' + token)
            
            .then( response => {
                // dispatch(ObedienceStageStart());
                if(response.data === null){
                    let Tentacle_introduction = {
                        Tentacle_introduction: 0,
                        obedience_sequence:0,
                        resistance_sequence:0,
                    }
                    axios.put('/Runs/'+userId+'/AudioRuns/Trigger_Introduction/Tentacle_Introduction.json?auth=' + token, Tentacle_introduction)
                    .then(response => {
                        dispatch(TentacleIntroductionSuccess(response.data))
                    })
                    .catch(error => {
                        dispatch(TentacleIntroductionFailed(error.message.data))
                    })
                    
                } else{
                    dispatch(TentacleIntroductionSuccess(response.data))
                }
                       
            })
            .catch(error => {
                dispatch(TentacleIntroductionFailed(error.message.data))
            })
    }
}

export const TentacleIntroductionFailed = (error) => {
    return{
        type: audioRunTypes.AUDIO_RUN_FAILED,
        error: error
    }
}

export const TentacleIntroductionEnd = (userId, token, obedience_sequence, resistance_sequence) => {
    return dispatch => {
        axios.get('/Runs/'+userId+'/AudioRuns/Trigger_Introduction/Tentacle_Introduction.json?auth=' + token)
            .then( response => {
                    let updatedTentacle_introduction = {
                        Tentacle_introduction: response.data.Tentacle_introduction + 1,
                        obedience_sequence: response.data.obedience_sequence + obedience_sequence,
                        resistance_sequence: response.data.resistance_sequence + resistance_sequence,
                    }
                    axios.put('/Runs/'+userId+'/AudioRuns/Trigger_Introduction/Tentacle_Introduction.json?auth=' + token, updatedTentacle_introduction)
                    .then(response => {
                        dispatch(TentacleIntroductionCompleted())
                    })
                    .catch(error => {
                        dispatch(TentacleIntroductionFailed(error.message.data))
                    })    
                       
            })
            .catch(error => {
                dispatch(TentacleIntroductionFailed(error.message))
            })
    }
}

export const TentacleIntroductionCompleted = () => {
    return{
            type: audioRunTypes.TENTACLE_INTRODUCTION_COMPLETED,
        }
        
    
}