import React from 'react'
import classes from '../../../../containers/Account/Account.module.css'
// import Input from '../../UI/Input/Input'
import Button from '../../../UI/Button/Button'
import Aux from '../../../../hoc/auxiliary/auxiliary'

const chloroformIntroduction = (props) => {
    let chloroformIntroductionForm = (
        <Aux>
            <div className={classes.AccountContainer}>
                    <form className={classes.FormStyle}>
                        <h2>Chloroform Introduction Run Details</h2>
                        <p className={classes.smallText}>We will chloroform you, {props.name}</p>
                        <div>
                            <p><strong>Chloroform Introduction Run: {props.ChloroformIntroduction.Chloroform_introduction}</strong></p>
                        </div>
                        <div>
                            <p><strong>Chloroform Room Sequence: {props.ChloroformIntroduction.chloroform_room} </strong></p>
                        </div>
                        <div>
                            <p><strong>Forced Chloroform Sequence: {props.ChloroformIntroduction.forced_chloroform}</strong></p>
                        </div>
                    </form>
                    <div className={classes.AuthButtons}>
                        <Button btnType="Danger" clicked={props.ReturnBackHandler}>Back</Button>
                    </div>

                </div>
        </Aux>)


    return chloroformIntroductionForm
}

export default chloroformIntroduction