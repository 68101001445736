import React, { Component } from 'react';
import Aux from '../../../../hoc/auxiliary/auxiliary';
import classes from '../HypnosisShowMenu.module.scss'
// import classes2 from '../ExperienceMenu/ExperienceMenu.module.scss'
import HypnosisShow from '../../../../assets/Images/Blank.png'
import FallReaction from './HypnosisShowNonVolFallsReaction'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'

class HypnosisShowInduction extends Component {

    state = {
        induction: true,
        fallsReaction: false
    }

    fallsReactionHandler = () => {
        this.setState({ induction: false, fallsReaction: true })
    }


    render() {
        let HypnosisShowForm = null
        if (this.state.induction) {
            HypnosisShowForm = (
                <html style={{ backgroundImage: `url(${HypnosisShow})` }}>
                    <div className={classes.storyFrom}>
                        <p>
                            Focusing intently on his voice as you grab the arms of the chair, squeezing a little bit. Your eyes locked onto him, watching him take a few steps towards the center of the stage.
                            He turns his back to you, focusing now on the volunteers. This clears your mind just for a second until you hear his voice again which makes you sink in your seat feeling its strong pull before he properly starts. <br></br>
                            <i>“Get comfortable in your seat. Take a deep breath in and just let it out.
                                Listen to my voice talking to you, only you. Forget about your surroundings and everything around you. Relax with each deep breath you take.
                                Feeling light as your mind clears up, your mind clears up which makes it so much easier to listen and focus on my voice ” </i><br></br>
                            Sinking in your seat further, slumping down with each word he says, your hand releasing the arms of the chair and finding it quite natural to breathe deep and relax more.
                            Your surroundings are a blur, there is only one thing you see, one single focal point, The Great O. You watch as he moves around on stage and continues<br></br>
                            <i>“As your mind clears up, as you focus on my voice. Your body loosens up while you listen, your muscles relaxing and loosening up with each breath. Your mind slowing down, along with your thoughts.
                                Taking a deep breath in and letting it all out. Feeling calmer each time as your mind quiets down, quiets down and clears up. It's getting easier & easier to shift all your focus towards my voice. 
                                It's just you & me now. That is all that exists in your mind right now.
                                Your thoughts one by one becoming hazy & foggy and that’s okay.
                                That’s completely fine” </i><br></br>
                            <i>"It's just you & me"</i> That phrase sticks with you big time. You realize it is true and that puts a smile on your face, just watching him with your eyes glued on to his every movement.
                            He starts walking behind the volunteers while saying <br></br>
                            <i>“No more thoughts, no more pesky thoughts. No more stress or tension, you don’t need any of that right now. It feels so much better to listen to my voice, doesn't it? Focusing on it and following it feels good, yes?
                                Feeling carefree and letting yourself go sounds very nice and isn't that what you want right now? Isn't that what you need?
                                It feels right, it feels right and you love how happy you are from listening to me. You can just relax and let yourself fall.
                                Smiling and relaxing more. That's right, you're doing a wonderful job."</i><br></br>
                            With a big wide smile on your face you nod to every word he says, every question he asked. You nod and agree with him accepting his words as facts and loving how they make you feel.
                            You look up at him on stage, you see him grabbing one of the volunteers' shoulders which you instictively feel too. You sink further into your chair imagining his hand on your shoulder, melting to his touch while everything else becomes irrelevant.
                            Everything else is no longer important. <i>"It's just you & me"</i> you think to yourself with his tone shifting to a softer, calmer & lower tone. 
                            This makes you focus even more on what he says and in turn gets you to slip even further <br></br>
                            <i>“My voice is deep inside your mind now. It is all you hear, all you want & all you need to listen to.
                                Feeling so happy & relaxed, calm & peaceful without a care in the world.
                                Sink down, let yourself slip & sink down under.
                                Taking you deeper with each word I say, guiding you further down while you nod. Nodding along and agreeing with me. It just feels right, it all feels right.
                                You're falling further and further down now, can't wait to drop isn't that right? <br></br>
                                That's why the next time I snap my fingers you will drop. you will drop and fall in a deep trance where you no longer have to worry about anything.
                                Where you no longer have to care about anything. All you’ll have to do is listen.
                                Listen, focus & follow. Listen to my voice, focus on my words & follow what I tell you, doesn't that sound lovely?” </i><br></br>
                            He pauses for a moment with his back to you and he's facing to the volunteers but that no longer matters once you notice his hand slowly rising up in the air. You catch a glimpese of it.<br></br>
                            Your eyes flutter for a second only to become fixated & caught by it. Watching it rise all the way up in the air with your eyes glued to its movement. <br></br>
                            Waiting in anticipation for that snap, your heart beats faster especially when you can tell. You can tell that what follows this snap will be pure bliss. <br></br>
                            You don't realize you've been leaning forward in your chair in anticipation for that snap. Your entire being caught by his hands & fingers.
                            Caught & so ready for that... <br></br>
                            <strong>*SNAP*</strong>
                        </p>
                        <form className={classes.centered} onClick={this.fallsReactionHandler}>
                            <h4 className={classes.storyButtonSize}>SLEEP NOW</h4>
                        </form>
                    </div>
                </html>
            )
        }
        if (this.state.fallsReaction) {
            HypnosisShowForm = (
                <FallReaction />
            )
        }

        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {HypnosisShowForm}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >




        )
    }
}


export default HypnosisShowInduction