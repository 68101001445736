export const DAILY_COMMAND_START = 'DAILY_COMMAND_START'
export const DAILY_COMMAND_SUCCESS = 'DAILY_COMMAND_SUCCESS'
export const DAILY_COMMAND_FAILED = 'DAILY_COMMAND_FAILED'
export const HYPNO_VISUAL_ACTIVATE = 'HYPNO_VISUAL_ACTIVATE'
export const HYPNO_VISUAL_DEACTIVATE = 'HYPNO_VISUAL_DEACTIVATE'



export const SAVE_NAME ='SAVE_NAME'
export const FETCH_MANTRA_SUCCESS = 'FETCH_MANTRA_SUCCESS'
export const FETCH_MANTRA_FAIL = 'FETCH_MANTRA_FAIL'
// export const FETCH_NAME = 'FETCH_NAME'


export const START_HOSTS_CHAMBER = 'START_HOSTS_CHAMBER'
export const HOST_INTRODUCTION = 'HOST_INTRODUCTION'
export const HOST_INDUCTION = 'HOST_INDUCTION'
export const HOST_BELL_INDUCTION = 'HOST_BELL_INDUCTION'
export const HOST_FOREHEAD_INDUCTION = 'HOST_FOREHEAD_INDUCTION'
export const HOST_MANTRA = 'HOST_MANTRA'
export const HOST_DEEPENER = 'HOST_DEEPENER'
export const HOST_TICKING_CLOCK_DEEPENER = 'HOST_TICKING_CLOCK_DEEPENER'
export const HOST_METRONOME_DEEPENER = 'HOST_METRONOME_DEEPENER'
export const HOST_TRIGGER = 'HOST_TRIGGER'
export const HOST_TRIGGER_COMMAND = 'HOST_TRIGGER_COMMAND'
export const HOST_TRIGGER_REPETITION = 'HOST_TRIGGER_REPETITION'
export const HOST_AWAKENER = 'HOST_AWAKENER'
export const HOST_AWAKENER_REMEMBER = 'HOST_AWAKENER_REMEMBER'
export const HOST_AWAKENER_FORGET = 'HOST_AWAKENER_FORGET'
export const HOST_ENDING = 'HOST_ENDING'
export const VIEW_CONDITIONING_STAGES = 'VIEW_CONDITIONING_STAGES'
export const VIEW_AUDIO_RUN = 'VIEW_AUDIO_RUN'
export const VIEW_CONDITIONING_PROTOCOL = 'VIEW_CONDITIONING_PROTOCOL'
export const VIEW_TRIGGER_INTRODUCTION = 'VIEW_TRIGGER_INTRODUCTION'
export const VIEW_TRIGGER_REINFORCEMENT = 'VIEW_TRIGGER_REINFORCEMENT'
export const VIEW_TEXT_TO_SPEECH = 'VIEW_TEXT_TO_SPEECH'
export const RETURN_HOSTS_CHAMBER = 'RETURN_HOSTS_CHAMBER'

