import React from 'react';
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'
import Sound from 'react-sound'
import classes from '../Visual.module.css'
import Bell from '../../../assets/audio/bell.mp3'
import Mantra from '../../../assets/audio/mantralong.mp3'
import Metronome from '../../../assets/audio/Metronome2.mp3'
import Ticking from '../../../assets/audio/Ticking3.mp3'
import MantraVoice from '../../../assets/audio/MantraVoice.mp3'
import WordText from '../TextAddition'

function StarVisual(props) {

  let innerWidth = window.innerWidth
  let innerHeight = window.innerHeight
  const canvasRef = React.useRef(null)

  React.useEffect(() => {
    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')

    

    var centerX = canvas.width / 2;
    var centerY = canvas.height / 2;
    var radius = props.echo_size;
    setInterval(function () {
      let period = 5000; // [miliseconds]

      var linearMod = Date.now() % period / period; // this goes from 0 to 1
      var mod = Math.sin(linearMod * Math.PI); // and here with simple easing we create some bouncing
      var r = radius * mod; // voila
      canvas.width = innerWidth; // ugly dirty clean :)
      // let randomColor = Math.floor(Math.random() * 4) +1
      // let colorArray = []
      // for(let j = 0; j <150; j++){
      //   colorArray.push((Math.floor(Math.random()*5)+1))
      // }
      for (let i = 0; i < 150; i++) {
        ctx.beginPath();
        ctx.lineWidth = 10;
        ctx.arc(centerX, centerY, r + i * 10, 0, 2 * Math.PI, false);

        if (i % 4 === 0) {

          if (i % 5 === 0) {
            ctx.strokeStyle = 'black';
          } else if (i % 5 === 1) {
            ctx.strokeStyle = props.color1
          } else {
            if (props.color_number === '3' || props.color_number === '4'){
              ctx.strokeStyle = props.color3;
            }
          }
        } else {
          if (i % 5 === 0) {
            ctx.strokeStyle = 'black';
          } else if (i % 5 === 1) {
            ctx.strokeStyle = props.color2
          } else {
            if (props.color_number === '4'){
              ctx.strokeStyle = props.color4;
            }
          }
        }

        ctx.stroke();

      }

    }, 25);

  })


  let soundComponent = null
  switch (props.sound) {
    case '1': soundComponent = <Sound url={Bell} playStatus={Sound.status.PLAYING} loop={true} />
      break;
    case '2': soundComponent = <Sound url={Metronome} playStatus={Sound.status.PLAYING} loop={true} />
      break;
    case '3': soundComponent = <Sound url={Ticking} playStatus={Sound.status.PLAYING} loop={true} />
      break;
    case '4': soundComponent = <Sound url={Mantra} playStatus={Sound.status.PLAYING} loop={true} />
      break;
    case '5': soundComponent = <Sound url={MantraVoice} playStatus={Sound.status.PLAYING} loop={true} />
      break;
    default: soundComponent = null
  }

  let divStyle
  divStyle = {
    fontSize: "48px",
    fontFamily: "Arial",
    background: 'transpartent',
    position: 'fixed',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    zIndex: '997',
    textAlign: 'center',
    // display: 'visible'


  }


  return (
    <Fullpage >
      <FullPageSections>
        < FullpageSection>
          <canvas
            ref={canvasRef}
            width={innerWidth}
            height={innerHeight}
          >
            {soundComponent}
          </canvas>
          <div style={divStyle}>
          <p className ={classes.returnText} onClick={props.returnHandler}>Return to Customization</p>
          <WordText text_color = {props.text_color}
                    text_speed ={props.text_speed} 
                    conditioning_text={props.conditioning_text}
                    arousal_check={props.arousal_check}
                    submissive_check = {props.submissive_check}/>
          </div>
        </FullpageSection>
      </FullPageSections>
    </Fullpage>
  );
}

export default StarVisual;
