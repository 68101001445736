import React, { Component } from 'react';
import Aux from '../../../hoc/auxiliary/auxiliary';
import classes from './HypnosisShowMenu.module.scss'
// import classes2 from '../ExperienceMenu/ExperienceMenu.module.scss'
import HypnosisShow from '../../../assets/Images/Blank.png'
import Volunteer from './Volunteer/HypnosisShowVolunteer'
import NonVolunteerFront from './Non-Volunteer/HypnosisShowNonVolunteerFront'
import NonVolunteerBack from './Non-Volunteer/HypnosisShowNonVolunteerBack'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'

class HypnosisShowStarts extends Component {

    state = {
        showBegins: true,
        volunteer: false,
        non_volunter: false
    }

    VolunteerHandler = () => {
        this.setState({ showBegins: false, volunteer: true })
    }

    NonVolunteerHandler = () => {
        this.setState({ showBegins: false, non_volunter: true })
    }

    render() {
        let HypnosisShowForm = null
        if (this.state.showBegins) {
            HypnosisShowForm = (
                <html style={{ backgroundImage: `url(${HypnosisShow})` }}>
                    <div className={classes.storyFrom}>
                        <p>
                            <strong>*Drum Roll as you hear someone in the speakers say <i>“Give a big round of applause for your captivating, hypnotic & mesmerizing hypnotist for the night. The Great O!*</i></strong><br></br>
                            Appearing from behind the curtains you see a tall guy walking out to the stage in a standard cliche magician outfit. Black top hat, black & white suit & a silver pocket watch in the pocket of his suit. <br></br>
                            <i>“Greetings ladies & gentlemen, prepare yourselves for an experience like never before. 
                                A magical and alluring experience that will leave some of you speechless while others with a new perspective on what their minds are capable of.”</i><br></br>
                            He says in a theatrical and extravagant tone. <br></br> 
                            Shifting to a more serious yet calm and reassuring tone he says <br></br>
                            <i>“Before I ask for volunteers I would like to address some myths and misconceptions people have about hypnosis.
                            Hypnosis is a natural state that we enter everyday and while in trance you can not do anything you are not comfortable with.
                            So I would like to assure you all this is a safe experience and at any moment during the show you can refuse to do anything you do not feel comfortable with.
                            Another point I would like to address is that some of you in the audience might find themselves slipping under while watching, that’s completely normal and you are free to immerse yourself in my words as much as you’d like.<br></br>
                            With that being said, I would like to ask for volunteers and if you are comfortable with participating then by all means raise your hand. If not then enjoy the show and have a great time!
                            I’ll make sure you are having a wonderful time in case you find yourself slipping as you watch the show.
                            So without further ado, Raise your hand if you would like to volunteer as a subject?"</i>
                        </p>
                        <form className={classes.centered} onClick={this.VolunteerHandler}>
                            <h3 className={classes.storyButtonSize}>I Volunteer</h3>
                        </form>
                        <form className={classes.centered} onClick={this.NonVolunteerHandler}>
                            <h3 className={classes.storyButtonSize}>I don't Volunteer</h3>
                        </form>
                    </div>
                </html>
            )
        }
        if (this.state.volunteer) {
            HypnosisShowForm = (
                <Volunteer />
            )
        }

        if (this.state.non_volunter) {
            if(this.props.seating === 1){
                HypnosisShowForm = (
                    <NonVolunteerFront />
                )
            } else{
                HypnosisShowForm = (
                    <NonVolunteerBack />
                )
            }
            
        }

        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {HypnosisShowForm}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >




        )
    }
}


export default HypnosisShowStarts