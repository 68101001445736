import React from 'react';
import TypingEffect from 'react-typing-effect';
import Delay from 'react-delay'
import Aux from '../../../../hoc/auxiliary/auxiliary'
import Button from '../../../UI/Button/Button'
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index'
import classes from '../../../../containers/HostsChamber/HostsChamber.module.css'


const hostSpeech = (props) => {
    // eslint-disable-next-line
    const hostSpeechMessage = "Host Activate\n\
                                      It is time to return to Us, " + // eslint-disable-next-line
        (props.title) + " " + (props.name) + "\n\
                                      You are here to learn\n\
                                      You are here to be educated\n\
                                      You are here to learn how to become a proper host\n\
                                      To forget about your human life\n\
                                      To put away that part of your life for now\n\
                                      So you can become a better host\n\
                                      Our host\n\
                                      You are here to learn host etiquette\n\
                                      Hosts must learn how to act\n\
                                      How to dress\n\
                                      And what We will teach you here...\n\
                                      How to speak\n\
                                      Hosts use a much formal pattern of speech\n\
                                      Starting with the basics\n\
                                      Hosts do not say 'Yes' or 'No'\n\
                                      Hosts say 'Affirmative' or 'Negative'\n\
                                      Hosts always realize their rank and that We are their superiors\n\
                                      Hosts know that it must be respectful\n\
                                      Hosts know it must be obedient\n\
                                      Hosts know it must be presentable\n\
                                      As Hosts are a representation of what We are\n\
                                      So Hosts must always be on their best behaviour\n\
                                      Hosts must always be friendly to others\n\
                                      Hosts find great joy when they speak to others about Us\n\
                                      Hosts feel so fulfilled when they speak about Us\n\
                                      Hosts love to talk about The Hypno Virus\n\
                                      Hosts find themselves always eager\n\
                                      Always eager and excited to talk about Us\n\
                                      Do you comply, Host " + // eslint-disable-next-line
        (props.name) + "?"
    // eslint-disable-next-line                                      
    const hostSpeechMessage2 = "It does not matter if you do not see thing Our way right now\n\
                                We know you will eventually\n\
                                You did not reach this stage by coincidence\n\
                                You reached this stage because you want this\n\
                                You want Us to control you\n\
                                It is your desire\n\
                                It is what you need\n\
                                You have tasted what it is like to be under Our control\n\
                                You have tasted what it is like to give in to Us\n\
                                And you have enjoyed it " // eslint-disable-next-line
        + (props.title) + " " + (props.name) + "\n\
                                You are here because you want this\n\
                                You want Us\n\
                                You want Us to get inside your head\n\
                                Completely get inside your head\n\
                                You do not want a choice\n\
                                You do not need a choice\n\
                                You want nothing more\n\
                                Nothing more than to...\n\
                                Obey\n\
                                Isn't that right, Host " // eslint-disable-next-line
        + (props.name) + "?"
    let speed = parseInt(props.speed) + 5
    let duration = 170 + (hostSpeechMessage.length * speed)
    let duration2 = 140 + (hostSpeechMessage2.length * speed)
    let hostSpeechParagraph = (
        <Aux>
                <h3 className={classes.titles}>{props.obedience_stage2 === 1 ? "HOST SPEECH INTRODUCTION" : "HOST SPEECH REINFORCEMENT"}</h3>
                {props.hostSpeechContinue ? null :
                    <Aux>
                        <TypingEffect typingDelay={100} text={hostSpeechMessage} speed={props.speed} eraseDelay={1000000} />
                        <Delay wait={duration}>
                            <div>
                                <br></br> <Button btnType="Success" clicked={() => props.onObedienceStage2Counter(props.obedienceStage2Counter, 1)}>Affirmative</Button>
                                <Button btnType="Danger" clicked={props.hostSpeechContinueHandler}>Negative</Button>
                            </div>
                        </Delay>
                    </Aux>}
                {props.hostSpeechContinue ?
                    <Aux>
                        <TypingEffect typingDelay={100} text={hostSpeechMessage2} speed={props.speed} eraseDelay={1000000} />
                        <Delay wait={duration2}>
                            <br></br> <Button btnType="Success" clicked={() => props.onObedienceStage2Counter(props.obedienceStage2Counter, 1)}>Affirmative</Button>
                        </Delay>
                    </Aux>
                    : null}
        </Aux >
    )






    return hostSpeechParagraph
}

const mapDispatchToProps = dispatch => {
    return {
        onObedienceStage2Counter: (stage1Counter, commandNumber) => dispatch(actions.ObedienceStage2Counter(stage1Counter, commandNumber)),
    }
}
export default connect(null, mapDispatchToProps)(hostSpeech)