import * as actionTypes from './authTypes'
import axios from '../../../axios';

export const fetchTitles = () => {
    return dispatch => {
        axios.get('/Titles.json?')
            .then(response => {
                const TitlesInfo = [];
                TitlesInfo.push({
                    ...response.data,
                });
                dispatch(viewTitleSuccess(TitlesInfo[0]))
            })
            .catch(error => {
                dispatch(viewTitleFail(error.response.data.error))
            })
    }
}

export const changeTitle = (token, userId, newTitle) => {
    return dispatch => {
        let currentTitle = {
            title: newTitle
        }
        axios.put('/UserTitles/' + userId + '.json?auth=' + token, currentTitle)
            .then(response => {
                dispatch(updateTitle(token, userId))
            })

            .catch(error => {
                dispatch(viewTitleFail(error.response.data.error))
            })
    }
}

export const updateTitle = (token, userId) => {
    return dispatch => {
        // const queryParams = '?auth=' + token + '&orderBy="userId"&equalTo="' + userId + '"'
        axios.get('/UserTitles/' + userId + '.json?auth=' + token)
            .then(response => {
                dispatch(fetchProfileSuccess(response.data.title))
            })
            .catch(error => {
                dispatch(viewTitleFail(error.response))
            })
    }
}

export const fetchTitle = (token, userId) => {
    return dispatch => {
        
        axios.get('/UserTitles/' + userId + '.json?auth=' + token)
            .then(response => {
                if (response.data === null) {
                    let defaultTitle = {
                        title: 'Host'
                    }
                    axios.put('/UserTitles/' + userId + '.json?auth=' + token, defaultTitle)
                        .then(response => {
                            dispatch(getTitle(response.data.title))
                        })
                        .catch(error => {
                            dispatch(viewTitleFail(error.response.data.error))
                        })
                }
                else {
                    dispatch(getTitle(response.data.title))
                }
            })
            .catch(error => {
                    dispatch(viewTitleFail(error.response.data.error))
                })
    }
}

export const fetchProfileSuccess = (updatedTitle) => {
    return {
        type: actionTypes.FETCH_ACCOUNT_DETAILS,
        title: updatedTitle
    }
}

export const viewTitleSuccess = (Titles) => {
    return {
        type: actionTypes.VIEW_TITLE_SUCCESS,
        Titles: Titles,
    }
}

export const viewTitleFail = () => {
    return {
        type: actionTypes.VIEW_TITLE_FAIL,
    }
}

export const viewTitle = () => {
    return {
        type: actionTypes.VIEW_TITLE
    }
}

export const getTitle = (Title) => {
    return {
        type: actionTypes.GET_TITLE,
        Title: Title
    }
}

export const changeTitleSuccess = () => {
    return {
        type: actionTypes.CHANGE_PROFILE_TITLE,
    }
}