import React from 'react'
import classes from '../../../containers/Account/Account.module.css'
import Input from '../../UI/Input/Input'
import Button from '../../UI/Button/Button'
import Aux from '../../../hoc/auxiliary/auxiliary'

const editProfile = (props) => {

    let editProfileForm = (
        <Aux>
            <div className={classes.AccountContainer}>
                    <form
                        className={classes.FormStyle}>
                        <h2>Edit Profile</h2>
                        <p className={classes.smallText}>Getting Closer to where you belong</p>
                        {props.registerFormElementsArray.map(formElement => (
                            <Input
                                key={formElement.id}
                                elementType={formElement.config.elementType}
                                elementConfig={formElement.config.elementConfig}
                                value={formElement.config.value}
                                invalid={!formElement.config.valid}
                                shouldValidate={formElement.config.validation}
                                touched={formElement.config.touched}
                                label = {formElement.config.label}
                                changed={(event) => props.inputRegisterHandler(event, formElement.id)} />
                        ))}
                    </form>
                    <div className={classes.AuthButtons}>
                        <Button btnType="Success" clicked={(token, Host_Runs) => props.saveChangesHandler(props.token, props.Host_Runs)}>Save Changes</Button><br></br>
                        <Button btnType="Danger" clicked={props.ReturnBackHandler}>Back</Button>
                    </div>

                </div>
        </Aux>)


    return editProfileForm
}

export default editProfile