import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '../../../UI/Button/Button'
import classes from '../../../../containers/HostsChamber/HostsChamber.module.css'
import classes2 from '../../../../containers/HostsChamber/AudioRuns/AudioRuns.module.css'
import Aux from '../../../../hoc/auxiliary/auxiliary'
import Background from '../../../Background/Background'
import ColorsVisual from './Tentacles_Introduction/ColorsVisual'
import FlashyVisual from './Tentacles_Introduction/FlashyVisual'
import ChloroformIntroduction from './Chloroform_Introduction/Chloroform_Introduction'
import MotorFunctrionControlIntroduction from './Motor_Function_Control_Introduction/Motor_Function_Control_Introduction'
import WebsiteAudio from '../../../Background/WebsiteAudio';
import * as actions from '../../../../store/actions/index';
import TentaclesIndroductionOpening from '../Trigger_Introduction/Tentacles_Introduction/Tentacles_Introduction_Opening'

class TriggerIntroduction extends Component {
    state = {
        trigger_introduction: true,
        tentacles_introduction: false,
        chloroform_introduction: false,
        motor_function_control_introduction: false
    }

    enterTentaclesIntroductionHandler = () => {
        this.setState({ trigger_introduction: false, tentacles_introduction: true })
    }

    enterChloroformIntroductionHandler = () => {
        this.setState({ trigger_introduction: false, chloroform_introduction: true })
    }

    enterMotorFunctionControlIntroductionHandler = () => {
        this.setState({ trigger_introduction: false, motor_function_control_introduction: true })
    }

    ReturnHandler = () => {
        this.props.onReturnHostsChamber()
    }

    render() {
        let audioRunForm = null
        if (this.state.trigger_introduction) {
            audioRunForm = (
                <Aux>
                    <div className={classes.HostsChamber}>
                        <Background />
                        <WebsiteAudio />
                        <div className={classes.FormStyle}>
                            <h3>Experience The Power of Our Voice, {this.props.title} {this.props.hostName}</h3>
                            <div className={classes.HostButtons}>
                                <Button btnType="Success" className={classes.HostChamberButton} clicked={this.enterTentaclesIntroductionHandler}>T.E.N.T.A.C.L.E. Introduction</Button><br></br>
                                <Button btnType="Success" className={classes.HostChamberButton} clicked={this.enterChloroformIntroductionHandler}>Chloroform Introduction</Button><br></br>
                                <Button btnType="Success" className={classes.HostChamberButton} clicked={this.enterMotorFunctionControlIntroductionHandler}>Motor Function <br></br>Control Introduction</Button><br></br>
                                <Button btnType="Danger" clicked={this.ReturnHandler}>Return To Hosts Chamber</Button>
                            </div>
                        </div>
                    </div>
                </Aux>
            )
        }

        if (this.state.tentacles_introduction) {
            let centerText = window.innerHeight / 2 - 55
            let texting = {

                // display: 'flex',
                // flexFlow: 'column',
                whiteSpace: 'pre-line',
                alignItems: 'center',
                fontWeight: 'bold',
                boxSizing: "border-box",
                margin: 'auto',
                marginBottom: '10px',
                padding: '30px 10px',
                width: '50%',
                paddingTop: centerText + 'px',
                color: 'white'

            }
            if (this.props.profileOptions.audio_run_background === '0') {
                audioRunForm = (
                    <Aux>
                        <div className={classes2.Grouping}>
                            <p style={texting}> <TentaclesIndroductionOpening /></p>
                        </div>
                    </Aux>
                )
            } else if (this.props.profileOptions.audio_run_background === '1') {
                audioRunForm = (
                    <Aux>
                        <FlashyVisual background={this.props.profileOptions.audio_run_background} />
                    </Aux>
                )
            } else if (this.props.profileOptions.audio_run_background === '2') {
                audioRunForm = (
                    <Aux>
                        <ColorsVisual background={this.props.profileOptions.audio_run_background} />
                    </Aux>
                )
            }

        }

        if (this.state.chloroform_introduction) {
            audioRunForm = (
                <Aux>
                    <ChloroformIntroduction />
                </Aux>
            )
        }

        if (this.state.motor_function_control_introduction) {
            audioRunForm = (
                <Aux>
                    <MotorFunctrionControlIntroduction />
                </Aux>
            )
        }

        return (audioRunForm)
    }
}

const mapStateToProps = state => {
    return {
        hostName: state.auth.accountDetails.name,
        runSpeed: state.auth.accountDetails.speed,
        hostRun: state.auth.accountDetails.Host_Runs,
        token: state.auth.token,
        title: state.auth.title,
        profileOptions: state.options.profileOptions,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onReturnHostsChamber: () => dispatch(actions.onReturnHostsChamber()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TriggerIntroduction)