import * as actionTypes from './authTypes'
import customAxios from '../../../axios'

export const optionsStart = () => {
    return {
        type: actionTypes.VIEW_OPTIONS_SUCCESS
    }
}

export const optionsFail = (error) => {
    return {
        type: actionTypes.VIEW_OPTIONS_FAIL,
        error: error
    }
}

export const viewProfileOptions = (token, userId) => {
    return dispatch => {
        dispatch(optionsStart());
        customAxios.get('/Options/' + userId + '/Profile_Options/.json?auth=' + token)
            .then(response => {
                if (response.data === null || response.data.website_audio === undefined) {
                    let profile_options = {
                        audio_option: '0',
                        website_audio: '0',
                        audio_run_background: '1',
                        text_to_speech_voice: '1'
                    }
                    customAxios.put('/Options/' + userId + '/Profile_Options/.json?auth=' + token, profile_options)
                        .then(response => {
                            dispatch(viewProfileOptionsDetails(response.data))
                        })
                        .catch(error => {
                            dispatch(optionsFail(error.message.data))
                        })

                } else {
                    dispatch(viewProfileOptionsDetails(response.data))
                }

            })
            .catch(error => {
                dispatch(optionsFail(error.message))
            })
    }
}

export const editProfileOptions = (profile_options, token, userId) => {
    return dispatch => {
        let updated_profile_options ={
            audio_option: profile_options.Sound.value,
            website_audio: profile_options.WebsiteSound.value,
            audio_run_background: profile_options.Audio_Run_Background.value,
            text_to_speech_voice: profile_options.Text_To_Speech_Voice.value
        }
        customAxios.put('/Options/' + userId + '/Profile_Options/.json?auth=' + token, updated_profile_options)
            .then(response => {
                dispatch(editProfileOptionsDetails(response.data))
            })
            .catch(error => {
                dispatch(optionsFail(error.message.data))
            })
    }
}

export const viewOptions = () => {
    return {
        type: actionTypes.VIEW_OPTIONS,
    }

}

export const viewProfileOptionsDetails = (profile_options) => {
    return {
        type: actionTypes.VIEW_PROFILE_OPTIONS,
        profile_options: profile_options
    }

}

export const editProfileOptionsDetails = (updated_profile_options) => {
    return {
        type: actionTypes.EDIT_PROFILE_OPTIONS,
        profile_options: updated_profile_options
       
    }

}