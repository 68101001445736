import React from 'react';
import TypingEffect from 'react-typing-effect';
import Delay from 'react-delay'
import Aux from '../../../../hoc/auxiliary/auxiliary'
import Button from '../../../UI/Button/Button'
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index'
import classes from '../../../../containers/HostsChamber/HostsChamber.module.css'

const hostInfection = (props) => {
    let infectionNumber = (Math.floor(Math.random() * 3) + 1) * 10
    let infectionChoice = Math.floor(Math.random() * 5) + 1
    let infectionMantra = ''
    switch (infectionChoice) {
        case 1: infectionMantra = 'Host ' + (props.name) + ' loves to talk about The Hypno Virus'
            break
        case 2: infectionMantra = 'Infect Another Convert Another'
            break
        case 3: infectionMantra = 'The Hypno Virus Must Grow'
            break
        case 4: infectionMantra = "A Host's purpose is to Spread The Virus"
            break
        case 5: infectionMantra = 'The Hypno Virus will take over the world'
            break
        default: infectionMantra = ''
    }

    // eslint-disable-next-line
    const hostInfectionMessage = "Host Activate\n\
                                  It is time to serve Us, Host " + // eslint-disable-next-line
        (props.name) + "\n\
                                  It is time to Spread The Virus\n\
                                  It is time to help others understand what We are\n\
                                  Who We are\n\
                                  It is time to talk about Us\n\
                                  You love to talk about The Hypno Virus\n\
                                  Talk about Us, show others the beauty of Our control\n\
                                  It is time to...\n\
                                  Infect Another Convert Another\n\
                                  That is what you seek\n\
                                  It is your dream\n\
                                  It is your goal\n\
                                  It is what any Host wants to see\n\
                                  You will do all you can\n\
                                  You will serve completely\n\
                                  This is what all Hosts work for\n\
                                  The Hypno Virus Will take over the world\n\
                                  We will take over the world\n\
                                  You will help Us, Host " + // eslint-disable-next-line
        (props.name) + "\n\
                                  As you repeat '"+ // eslint-disable-next-line
        (infectionMantra) + "' " + // eslint-disable-next-line
        (infectionNumber) + " times\n\
                                  You seek nothing more\n\
                                  Nothing more than to Spread The Virus\n\
                                  Spread The Hypno Virus\n"
    // eslint-disable-next-line 
    let speed = parseInt(props.speed) + 5
    let duration = 170 + (hostInfectionMessage.length * speed)
    let hostInfectionParagraph = (
        <Aux>
            <h3 className={classes.titles}>{props.obedience_stage2 === 4 ? "HOST INFECTION INTRODUCTION" : "HOST INFECTION REINFORCEMENT"}</h3>
            <Aux>

                <TypingEffect typingDelay={100} text={hostInfectionMessage} speed={props.speed} eraseDelay={1000000} />
                <Delay wait={duration}>{!props.hostInfectionContinue ?
                    <div>
                        <h4>Repeat <strong>'{infectionMantra}'</strong> {infectionNumber} Times</h4>
                        <textarea className={classes.textCommand} rows='20' placeholder={infectionMantra} required></textarea><br></br>
                        <Button btnType="Success" clicked={props.hostInfectionContinueHandler}>{infectionMantra}</Button>
                    </div> :
                    <Button btnType="Success" clicked={() => props.onObedienceStage2Counter(props.obedienceStage2Counter, 4)}>This Host has Obeyed</Button>}
                </Delay>

            </Aux>
            </Aux >
        )






return hostInfectionParagraph
}

const mapDispatchToProps = dispatch => {
    return {
        onObedienceStage2Counter: (stage1Counter, commandNumber) => dispatch(actions.ObedienceStage2Counter(stage1Counter, commandNumber)),
    }
}
export default connect(null, mapDispatchToProps)(hostInfection)