import React, { useState } from "react";
import TextTransition from "react-text-transition";
import TypingEffect from 'react-typing-effect'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import Button from '../../../../UI/Button/Button'
import classes from '../../../../../containers/HostsChamber/AudioRuns/AudioRuns.module.css'
import * as scripts from '../../../../../shared/AudioRuns/ConditioningProtocols/ConditioningProtocol#1/ConditioningProtocol1Scripts'
import * as Delays from '../../../../../shared/AudioRuns/ConditioningProtocols/ConditioningProtocol#1/ConditioningProtocol#1Delay'
import { connect } from 'react-redux';
import { ConditioningProtocol1End } from '../../../../../store/actions/AudioRuns/audioRunsIndex'
import ConditioningEnd from './Conditioning_Protocol_Resist_End'
import Sound from 'react-sound'
import ResistConfess from '../../../../../assets/audio_runs/conditioning_protocols/conditioning_protocol_1/conditioning_protocol_resist_confess.mp3'
import Delay from "react-delay";
import useInterval from 'react-useinterval';


const DELAY_CONFESS = [3900]  

function Conditioning_Protocol_Resist({ userId, token, conditioningProtocol1Complete, ConditioningProtocol1End }) {

    
    let [conditioning_resistConfessTextIndex, countConditioning_resistConfessTextIndex] = useState(0)
    let [conditioning_confess, setConditioning_confess] = useState(true)
    let [conditioning_end, setConditioning_end] = useState(false)

    const increaseResistConfessCount = () => {
        if (conditioning_confess && conditioning_resistConfessTextIndex < scripts.CONDITIONING_PROTOCOL_RESIST_CONFESS.length) {
            countConditioning_resistConfessTextIndex(conditioning_resistConfessTextIndex + 1)

        }
    }


   
    useInterval(increaseResistConfessCount, Delays.DELAY_TIME_CONFESS[conditioning_resistConfessTextIndex]);

   
    const enterEndHandler = () => {
        setConditioning_confess(conditioning_confess = false)
        setConditioning_end(conditioning_end = true)
    }


    let conditioning_protocol_resist_form = null

    if (conditioning_confess) {
        conditioning_protocol_resist_form = (
            <Aux>
                {conditioning_resistConfessTextIndex < scripts.CONDITIONING_PROTOCOL_RESIST_CONFESS.length ?
                    <Aux >
                        {/* <div className={classes.texting}> */}
                            <Sound url={ResistConfess} playStatus={Sound.status.PLAYING} />
                            <Delay wait={DELAY_CONFESS[conditioning_resistConfessTextIndex]}>
                                <TextTransition
                                    text={<TypingEffect typingDelay={100} text={scripts.CONDITIONING_PROTOCOL_RESIST_CONFESS[conditioning_resistConfessTextIndex]} speed={90} eraseDelay={1000000} />}
                                    Overflow
                                />
                            </Delay>
                        {/* </div> */}
                    </Aux>
                    :
                    <div className={classes.ButtonPadding}>
                        <Button btnType="Success" clicked={enterEndHandler}>Yes, I am a host for The Hypno Virus</Button>
                    </div>
                } 
            </Aux>
        )
    }

    if (conditioning_end) {
        conditioning_protocol_resist_form = (
            <ConditioningEnd />
        )
    }





    return (
        conditioning_protocol_resist_form
    );
}


const mapStateToProps = state => {
    return {
        hostName: state.auth.accountDetails.name,
        runSpeed: state.auth.accountDetails.speed,
        userId: state.auth.userId,
        token: state.auth.token,
        conditioningProtocol1Complete: state.conditioningProtocol1.conditioningProtocol1Complete
    }
}

const mapDispatchToProps = dispatch => {
    return {
        ConditioningProtocol1End: (userId, token, comply_sequence, resistance_sequence) => dispatch(ConditioningProtocol1End(userId, token, comply_sequence, resistance_sequence)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Conditioning_Protocol_Resist)