import * as audioRunTypes from '../../../actions/AudioRuns/audioRunTypes';
import { updateObject } from '../../../../shared/utility';

const initialState = {
    error: null,
    Tentacle_introductionComplete: false,
    Tentacle_introductionCommands: [],
    loading: false,
}

const tentacleIntroductionStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true
    })
}

const tentacleIntroductionSuccess = (state, action) => {
        return updateObject(state, {
            Tentacle_introductionCommands: action.Tentacle_introduction,
            loading: false,
        })
    }

    



const tentacleIntroductionFailed = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    })
}


const tentacleIntroductionComplete = (state, action) => {
    return updateObject(state, {
        error: null,
        Tentacle_introductionComplete: true,
        Tentacle_introductionCommands: [],
        loading: false,
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case audioRunTypes.AUDIO_RUN_START: return tentacleIntroductionStart(state, action)
        case audioRunTypes.AUDIO_RUN_FAILED: return tentacleIntroductionFailed(state, action)
        case audioRunTypes.TENTACLE_INTRODUCTION_SUCCESS: return tentacleIntroductionSuccess(state,action)
        case audioRunTypes.TENTACLE_INTRODUCTION_COMPLETED: return tentacleIntroductionComplete(state, action)
        default:
            return state

    }
}


export default reducer