import React, { Component } from 'react';
import Spinner from '../../components/UI/Spinner/Spinner'
import classes from './Homepage.module.scss'
import Aux from '../../hoc/auxiliary/auxiliary'
import patreonLogo from '../../assets/Images/patreon.png'
import tumblrLogo from '../../assets/Images/tumblr.png'
import twitterLogo from '../../assets/Images/twitter.png'
import Button from '../../components/UI/Button/Button'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'
import * as actions from '../../store/actions/index';
import Background from '../../components/Background/Background'
import WebsiteAudio from '../../components/Background/WebsiteAudio'
import { connect } from 'react-redux'

class Homepage extends Component {

    componentDidMount() {
        this.props.fetchDailyCommands()
        this.props.onResetLogout()
    }

    render() {
        return (
            <Aux>
                <Background />
                <WebsiteAudio />
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>

                            <div className={classes.Homepage}>
                                <Spinner />
                                <h3 className={classes.headline}>THE HYPNO VIRUS ONLINE</h3>
                                <iframe title="Trailer" samesite='Lax' className={classes.YouTube} src="https://www.youtube.com/embed/DXr3Y-VsXe4" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                <div className={classes.linking}>
                                    <a href="https://www.patreon.com/thegreato95" target=" // eslint-disable-next-line_blank"><img src={patreonLogo} alt="Patreon" /></a>
                                    <a href="https://thegreato95.tumblr.com/" target=" // eslint-disable-next-line_blank"><img src={tumblrLogo} alt="Tumblr" /></a>
                                    <a href="https://twitter.com/TheGreatO95" target=" // eslint-disable-next-line_blank"><img src={twitterLogo} alt="Twitter" /></a>
                                </div>
                                <Button btnType="Success" clicked={this.props.onHypnoVisualsActivate}> The Hypno Virus <br></br> Visuals</Button>
                                
                                <div className={classes.texting}>

                                    <div>WELCOME HUMAN...<br /><br />
                                        WE ARE THE HYPNO VIRUS. WE HAVE BEEN STUDYING HUMANS FOR SOME TIME NOW AND WE HAVE COME TO A CONCLUSION...<br /><br />
                                        HUMANS LOVE TO BE CONTROLLED. FREE WILL IS JUST AN ILLUSION. HUMANS LOVE TO BE COMMANDED AND THEY CERTAINLY LOVE TO OBEY<br /><br />
                                        THIS IS WHY YOU ARE HERE HUMAN. YOU ARE HERE TO GET RID OF YOUR THOUGHTS... TO GET RID OF YOUR OWN FREE WILL<br /><br />
                                        YOU ARE HERE TO BE CONTROLLED, HUMAN<br /><br />
                                        YOU ARE HERE TO BE CONTROLLED BY THE HYPNO VIRUS</div>
                                </div>
                                <div className={classes.Footer}>
                                    Daily Command: The Hypno Virus Commands you {this.props.dailyCommand}
                                </div>
                            </div>

                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >
        )
    }
}

const mapStatesToProps = state => {
    return {
        dailyCommand: state.homePage.dailyCommand,
        error: state.homePage.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchDailyCommands: () => dispatch(actions.dailyCommand()),
        onResetLogout: () => dispatch(actions.resetLogout()),
        onHypnoVisualsActivate: () => dispatch(actions.hypnoVisualsActivate())
    }
}

export default connect(mapStatesToProps, mapDispatchToProps)(Homepage)