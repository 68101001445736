import * as actionTypes from '../../actions/Account/authTypes';
import { updateObject } from '../../../shared/utility';

const initialState = {
    viewRunDetails: false,
    viewHostRunDetails: false,

    viewConditioningStages: false,
    viewObedienceStage1RunDetails: false,
    viewObedienceStage2RunDetails: false,
    viewObedienceStage3RunDetails: false,

    viewConditioningProtocols: false,
    viewConditioningProtocol1Details: false,

    viewTriggerIntroduction: false,
    viewTentacleIntroduction: false,
    viewChloroformIntroduction: false,
    viewMotorFunctionControlIntroduction: false,

    viewTriggerReinforcement: false,
    viewTentacleReinforcement: false,
    viewChloroformReinforcement: false,
    viewMotorFunctionControlReinforcement: false,

    viewHostRunDetailsArray: [],

    viewObedienceStage1RunDetailsArray: [],
    viewObedienceStage2RunDetailsArray: [],
    viewObedienceStage3RunDetailsArray: [],

    viewConditioningProtocol1DetailsArray: [],

    viewTentacleIntroductionDetailsArray: [],
    viewChloroformIntroductionDetailsArray: [],
    viewMotorFunctionControlIntroductionDetailsArray: [],

    viewTentacleReinforcementDetailsArray: [],
    viewChloroformReinforcementDetailsArray: [],
    viewMotorFunctionControlReinforcementDetailsArray: [],
    
}


const viewRunDetails = (state, action) => {
    return updateObject(state, {
        viewRunDetails: true,
        loading: false,
    })
}

const viewHostRunDetailSuccess = (state, action) => {
    return updateObject(state, {
        viewHostRunDetails: true,
        loading: false,
        viewHostRunDetailsArray: action.viewDetails
    })

}

const ViewConditioningStages = (state, action) => {
    return updateObject(state, {
        viewConditioningStages: true,
        loading: false,
    })

}

const viewObedienceStage1RunDetails = (state, action) => {
    return updateObject(state, {
        viewObedienceStage1RunDetails: true,
        loading: false,
        viewObedienceStage1RunDetailsArray: action.obedienceRun1Details
    })

}

const viewObedienceStage2RunDetails = (state, action) => {
    return updateObject(state, {
        viewObedienceStage2RunDetails: true,
        loading: false,
        viewObedienceStage2RunDetailsArray: action.obedienceRun2Details
    })

}

const viewObedienceStage3RunDetails = (state, action) => {
    return updateObject(state, {
        viewObedienceStage3RunDetails: true,
        loading: false,
        viewObedienceStage3RunDetailsArray: action.obedienceRun3Details
    })

}

const ViewConditioningProtocols = (state, action) => {
    return updateObject(state, {
        viewConditioningProtocols: true,
        loading: false,
    })

}

const viewConditioningProtocol1Details = (state, action) => {
    return updateObject(state, {
        viewConditioningProtocol1Details: true,
        loading: false,
        viewConditioningProtocol1DetailsArray: action.conditioning_protocol1
    })

}

const viewTriggerIntroduction = (state, action) => {
    return updateObject(state, {
        viewTriggerIntroduction: true,
        loading: false,
    })

}

const viewTentacleIntroduction = (state, action) => {
    return updateObject(state, {
        viewTentacleIntroduction: true,
        loading: false,
        viewTentacleIntroductionDetailsArray: action.tentacle_introduction
    })

}

const viewChloroformIntroduction = (state, action) => {
    return updateObject(state, {
        viewChloroformIntroduction: true,
        loading: false,
        viewChloroformIntroductionDetailsArray: action.chloroform_introduction
    })

}

const viewMotorFunctionControlIntroduction = (state, action) => {
    
    return updateObject(state, {
        viewMotorFunctionControlIntroduction: true,
        loading: false,
        viewMotorFunctionControlIntroductionDetailsArray: action.motor_function_control_introduction
    })

}

const viewTriggerReinforcement = (state, action) => {
    return updateObject(state, {
        viewTriggerReinforcement: true,
        loading: false,
    })

}

const viewTentacleReinforcement = (state, action) => {
    return updateObject(state, {
        viewTentacleReinforcement: true,
        loading: false,
        viewTentacleReinforcementDetailsArray: action.tentacle_reinforcement
    })

}

const viewChloroformReinforcement = (state, action) => {
    return updateObject(state, {
        viewChloroformReinforcement: true,
        loading: false,
        viewChloroformReinforcementDetailsArray: action.chloroform_reinforcement
    })

}

const viewMotorFunctionControlReinforcement = (state, action) => {
    
    return updateObject(state, {
        viewMotorFunctionControlReinforcement: true,
        loading: false,
        viewMotorFunctionControlReinforcementDetailsArray: action.motor_function_control_reinforcement
    })

}

const returnBackSuccess = (state, action) => {
    return updateObject(state, {
        viewHostRunDetails: false,
        viewRunDetails: false,

        viewConditioningStages: false,
        viewObedienceStage1RunDetails: false,
        viewObedienceStage2RunDetails: false,
        viewObedienceStage3RunDetails: false,

        viewConditioningProtocols: false,
        viewConditioningProtocol1Details: false,

        viewTriggerIntroduction: false,
        viewTentacleIntroduction: false, 
        viewChloroformIntroduction: false,
        viewMotorFunctionControlIntroduction: false,

        viewTriggerReinforcement: false,
        viewTentacleReinforcement: false,
        viewChloroformReinforcement: false,
        viewMotorFunctionControlReinforcement: false,

        viewHostRunDetailsArray: [],

        viewObedienceStage1RunDetailsArray: [],
        viewObedienceStage2RunDetailsArray: [],
        viewObedienceStage3RunDetailsArray: [],

        viewConditioningProtocol1DetailsArray: [],

        viewTentacleIntroductionDetailsArray: [],
        viewChloroformIntroductionDetailsArray: [],
        viewMotorFunctionControlIntroductionDetailsArray: [],

        viewTentacleReinforcementDetailsArray: [],
        viewChloroformReinforcementDetailsArray: [],
        viewMotorFunctionControlReinforcementDetailsArray: [],
        loading: false
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.VIEW_RUN_DETAIL: return viewRunDetails(state, action)
        case actionTypes.RETURN_BACK_SUCCESS: return returnBackSuccess(state, action)
        case actionTypes.VIEW_HOSTS_RUN_DETAIL: return viewHostRunDetailSuccess(state, action)
        case actionTypes.VIEW_CONDITIONING_STAGES_RUN_DETAIL: return ViewConditioningStages(state, action)
        case actionTypes.VIEW_CONDITIONING_PROTOCOLS_RUN_DETAIL: return ViewConditioningProtocols(state, action)
        case actionTypes.VIEW_OBEDIENCE_STAGE_1_RUN_DETAIL: return viewObedienceStage1RunDetails(state, action)
        case actionTypes.VIEW_OBEDIENCE_STAGE_2_RUN_DETAIL: return viewObedienceStage2RunDetails(state, action)
        case actionTypes.VIEW_OBEDIENCE_STAGE_3_RUN_DETAIL: return viewObedienceStage3RunDetails(state, action)        
        case actionTypes.VIEW_CONDITIONING_PROTOCOL_1: return viewConditioningProtocol1Details(state, action)
        case actionTypes.VIEW_TRIGGER_INTRODUCTION: return viewTriggerIntroduction(state, action)
        case actionTypes.VIEW_TENTACLE_INTRODUCTION: return viewTentacleIntroduction(state, action)
        case actionTypes.VIEW_CHLOROFORM_INTRODUCTION: return viewChloroformIntroduction(state, action)
        case actionTypes.VIEW_MOTOR_FUNCTION_CONTROL_INTRODUCTION: return viewMotorFunctionControlIntroduction(state, action)
        case actionTypes.VIEW_TRIGGER_REINFORCEMENT: return viewTriggerReinforcement(state, action)
        case actionTypes.VIEW_TENTACLE_REINFORCEMENT: return viewTentacleReinforcement(state, action)
        case actionTypes.VIEW_CHLOROFORM_REINFORCEMENT: return viewChloroformReinforcement(state, action)
        case actionTypes.VIEW_MOTOR_FUNCTION_CONTROL_REINFORCEMENT: return viewMotorFunctionControlReinforcement(state, action)
        default:
            return state

    }
}


export default reducer