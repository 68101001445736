let i = 0
let msg = ''
export const typingEffect = (message, count, speed) => {
    
    if (i < count) {
            msg += message.charAt(i);
            i++;
            setTimeout(typingEffect, speed);
            return msg
          }
          
}


export const checkValidity = (value, rules) => {
  let isValid = true;
  if (!rules) {
      return true;
  }
  
  if (rules.required) {
      isValid = value.trim() !== '' && isValid;
  }

  if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid
  }

  if (rules.maxLength) {
      isValid = value.length <= rules.maxLength && isValid
  }

  if (rules.isEmail) {
      const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      isValid = pattern.test(value) && isValid
  }

  if (rules.isNumeric) {
      const pattern = /^\d+$/;
      isValid = pattern.test(value) && isValid
  }

  if (rules.resistanceCommand){
      if (value.toUpperCase() === "RESISTANCE IS FUTILE".toUpperCase()){
        isValid = true
      }
      
  }

  return isValid;
}

export const updateObject = (oldObject, updatedProperties) => {
  return {
      ...oldObject,
      ...updatedProperties
  }
}

export const checkMantra = (id, value, rules) => {
    let isValid = true
    if(!rules){
        return true
    }

    if(rules.required){
        isValid = value.trim() !== '' && isValid;
    }

    if (value.toUpperCase() !== rules.confirmation.toUpperCase()){
        isValid = value === isValid 
    }

    return isValid
}