import React from 'react';
import TypingEffect from 'react-typing-effect';
import Delay from 'react-delay'
import Aux from '../../../../../hoc/auxiliary/auxiliary'
import classes from '../../FreerunComponent.module.css'

const deepener = (props) => {
    // eslint-disable-next-line
    let snapCount = Math.floor(Math.random() * 10) + 1;
    // eslint-disable-next-line
    const deepener = (props.naming) + ", it is time for Us to take you deeper\n\
                     It is time for Us to take you even deeper\n\
                     Deeper under Our control\n\
                     Deeper and more obedient\n\
                     More submissive\n\
                     More docile\n\
                     Open...\n\
                     Open to Us\n\
                     To everything We tell you\n\
                     Open to Our control\n\
                     You will help Us, " + // eslint-disable-next-line
                     (props.naming) + '\n\
                     You will help Us control you\n\
                     In a moment you will raise your fingers in front of you\n\
                     Straight in front of your eyes\n\
                     Snapping your fingers\n\
                     You will snap your fingers ' + // eslint-disable-next-line 
                     snapCount + ' times\n\
                     With each snap you will drop deeper\n\
                     Deeper for Us\n\
                     Deeper under Our control\n\
                     Eager...\n\
                     Eager & excited\n\
                     Eager & excited to be controlled more\n\
                     Controlled and used more by Us\n\
                     Used more by The Hypno Virus\n\
                     Now...'
                     + // eslint-disable-next-line
                     (props.naming) + ', snap your fingers.'
    let duration = 150 + (deepener.length * 115)
    let deepenerParagraph = (
            <Aux>
                <h3 className= {classes.titles}>DEEPENER</h3>
                <TypingEffect typingDelay={5000} text={deepener} speed={110} eraseDelay={1000000} />
                <Delay wait={duration+5000}><h4 className={classes.blinking} onClick={props.deepener}>Please control me, Hypno Virus</h4></Delay>
            </Aux>
    )
    return deepenerParagraph
}


export default deepener