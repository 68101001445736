import React from 'react';
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'
import classes from '../../../../../containers/HostsChamber/AudioRuns/AudioRuns.module.css'
import InstantControl from './Instant_Control'
import SlowControl from './Slow_Control'

function MotorFunctionControlIntroductionVisual(props) {

    

    let motor_function_control_form = null
    if (props.instant_control){
        motor_function_control_form = <InstantControl />
    } 

    if (props.slow_control){
        motor_function_control_form = <SlowControl/>
    }


    let centerText = window.innerHeight / 2 -55
    let texting = {

        // display: 'flex',
        // flexFlow: 'column',
        whiteSpace: 'pre-line',
        alignItems: 'center',
        fontWeight: 'bold',
        boxSizing: "border-box",
        margin: 'auto',
        marginBottom: '10px',
        padding: '30px 10px',
        width: '50%',
        paddingTop: centerText + 'px',
        color: 'white'

    }

    return (
        <Fullpage >
            <FullPageSections>
                < FullpageSection>
                    <div className={classes.Grouping}>
                        <p style={texting}>{motor_function_control_form}</p>
                    </div>
                </FullpageSection>
            </FullPageSections>
        </Fullpage>
    );
}

export default MotorFunctionControlIntroductionVisual;

