import React, { Component } from 'react';
import Aux from '../../../../hoc/auxiliary/auxiliary';
import classes from '../HypnosisShowMenu.module.scss'
// import classes2 from '../ExperienceMenu/ExperienceMenu.module.scss'
import HypnosisShow from '../../../../assets/Images/Blank.png'
import FingerSnap from '././HypnosisShowVolSnap'
import ForeHeadTap from '././HypnosisShowVolForeheadTap'
import SelfSnap from '././HypnosisShowVolSelfSnap'
import WakeUp from '././HypnosisShowVolWakeUp'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'

class HypnosisShowHypnoGun extends Component {

    state = {
        hypnoGun: true,
        fingerSnap: false,
        foreheadTap: false,
        countdownSnap: false,
        wakeUp: false
    }

    fingerSnapHandler = () => {
        this.setState({ hypnoGun: false, fingerSnap: true })
    }

    foreheadTapHandler = () => {
        this.setState({ hypnoGun: false, foreheadTap: true })
    }


    countdownSnapHandler = () => {
        this.setState({ hypnoGun: false, countdownSnap: true })
    }

    wakeUpHandler = () => {
        this.setState({ hypnoGun: false, wakeUp: true })
    }


    render() {
        let HypnosisShowForm = null
        if (this.state.hypnoGun) {
            HypnosisShowForm = (
                <html style={{ backgroundImage: `url(${HypnosisShow})` }}>
                    <div className={classes.storyFrom}>
                        <p>
                        <i>“Use my hypno gun. As soon as I point my fingers at you like a gun and shoot your body collapses as your mind falls deep. 
                        Sinking back under and feeling the joy that comes from going into trance again. Feeling so good & carefree just like you are now & enjoying every second of it, understood?” </i><br></br>
                        Nodding as you imagine that happening. Imagining him pointing his fingers at you, shooting and boom you just drop. 
                        Smiling at the thoughts as he says <br></br>
                        <i>“Waking up on the count of five. <br></br>
                        One, starting to wake up. <br></br>
                        Two, feeling wonderful and refreshed. <br></br>
                        Three, Excited & happy. <br></br>
                        Four, Eager to participate and join in the experience<br></br>
                        Five, wide awake now!”</i><br></br>
                        <strong>*SNAP*</strong><br></br>
                        Opening your eyes, stretching & blinking a few times as you regain consciousness and remember what you can from everything that's happening. <br></br>
                        <i>“What an amazing job you guys are doing! A round of applause for these lovely volunteers”</i><br></br> 
                        You watch as the audience clap & cheer for you. Feeling happiness and joy knowing all these people are clapping for you and enjoying what you’re doing.<br></br>
                        <strong>Suddenly</strong> He jumps around facing you making finger guns with his hand pointing them at the volunteers on each side. <br></br>
                        <i>“Stick’em up this is a robbery!”</i> He says seriously pausing for a few seconds as he bursts into laughter looking at you while you're not too sure what's going on. 
                        He starts to lower his hand only to raise them again and shoot both of them
                        <i>“Bam! Paw!” </i><br></br>
                        They both jump out of their place only to watch their bodies slump and fall forward almost falling to the floor, collapsing and they fall deep under. <br></br> 
                        He starts to walk slowly toward you while point his fingers at the lady next to you.  
                        <strong><i>“Bam!”</i></strong> you watch her dropping, her head falling on you to rest her head on your shoulders sinking deeper not caring about a thing. <br></br>
                        You look up at him again, seeing him point his fingers at you right to your forehead.
                        Feeling your heart racing from the mix of nervousness, excitement & just a little bit of fear that slowly subsides because by now you’re feeling quite comfortable & safe around him. Especially when he starts talking and saying <br></br>
                        <i>“There is absolutely nothing to be scared of right now, Are you ready?” </i><br></br>
                        You give him a small nod as he says in a soft tone<br></br> 
                        <i><strong>“Paw”</strong></i>
                        Dropping, dropping deep as your body falls a little bit forward. Feeling the lady beside you falling along with you & your mind just goes completely empty as you fall. 
                        Your mind & body completely shut down sinking further than ever before & hearing him say <br></br> 
                        <i>"Dropping deeper & deeper, sinking more, drifting and floating further. Drifting & floating further into trance. All you want is have me…”</i>
                        </p>
                        <form className={classes.centered} onClick={this.fingerSnapHandler}>
                            <h3 className={classes.storyButtonSize}>Snap my Fingers</h3>
                        </form>
                        <form className={classes.centered} onClick={this.foreheadTapHandler}>
                            <h3 className={classes.storyButtonSize}>Tap your Forehead</h3>
                        </form>
                        <form className={classes.centered} onClick={this.countdownSnapHandler}>
                            <h3 className={classes.storyButtonSize}>Countdown & Self Drop you</h3>
                        </form>
                        <form className={classes.centered} onClick={this.wakeUpHandler}>
                            <h3 className={classes.storyButtonSize}>Wake you Up</h3>
                        </form>
                    </div>
                </html>
            )
        }
        if (this.state.fingerSnap) {
            HypnosisShowForm = (
                <FingerSnap />
            )
        }

        if (this.state.foreheadTap) {
            HypnosisShowForm = (
                <ForeHeadTap />
            )
        }

        if (this.state.countdownSnap) {
            HypnosisShowForm = (
                <SelfSnap />
            )
        }

        if (this.state.wakeUp) {
            HypnosisShowForm = (
                <WakeUp />
            )
        }

        return (
            <Aux>
                <Fullpage >
                    <FullPageSections>
                        <FullpageSection>
                            {HypnosisShowForm}
                        </FullpageSection>
                    </FullPageSections>
                </Fullpage >

            </Aux >




        )
    }
}


export default HypnosisShowHypnoGun