import React from 'react';
import TypingEffect from 'react-typing-effect';
import Delay from 'react-delay'
import Aux from '../../../../hoc/auxiliary/auxiliary'
import Button from '../../../UI/Button/Button'
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index'
import classes from '../../../../containers/HostsChamber/HostsChamber.module.css'
import Sound from 'react-sound'
import Beta from '../../../../assets/audio/beta.mp3'
import Bell from '../../../../assets/audio/bell.mp3'


const chloroformConditioned = (props) => {
    // eslint-disable-next-line   
    const chloroformedConditionedFirstIntroMessage = (props.name) + ", did you know that\n\
                                                     The human mind is far more receptive when it is passing out\n\
                                                     When it is given instructions\n\
                                                     When it is given conditioning\n\
                                                     When it is given commands\n\
                                                     Right before falling unconscious\n\
                                                     That is why We are giving you the option\n\
                                                     We are giving you the choice\n\
                                                     The choice to choose your conditioning\n\
                                                     To choose your conditioning as we... chloroform you\n\
                                                     Choose your conditioning now, " + // eslint-disable-next-line
                                                     (props.title)
    // eslint-disable-next-line
    let chloroformedConditionedArousal = "In a moment you will put your hand over your mouth\n\
                                          Your hand is laced with chloroform\n\
                                          A special kind of chloroform that We have created\n\
                                          A fast, effective & harmless chloroform\n\
                                          That only renders you unconscious until We command you to wake up\n\
                                          With a ring of Our bell\n\
                                          That is how you wake up\n\
                                          Now it is time for your conditioning\n\
                                          Conditioning you to something you always welcome eagerly\n\
                                          Condition you to Our arousal\n\
                                          You have been conditioned to it for a good while by now\n\
                                          Even if We don't state it all the time\n\
                                          Our words are a huge turn on to you\n\
                                          Our instructions are a huge turn on\n\
                                          Our commands are a huge turn on\n\
                                          It has been like that for quite sometime now\n\
                                          That is not going to change\n\
                                          It will only grow stronger\n\
                                          The effects of Our control\n\
                                          The effects of Our conditioning\n\
                                          The more you feel Our control\n\
                                          The more turned on you get\n\
                                          The more you feel Our conditioning\n\
                                          The more aroused you become\n\
                                          This is the truth now, " + // eslint-disable-next-line
                                          (props.title) + " " +(props.name) + "\n\
                                          So in a moment you will chloroform yourself for Us\n\
                                          You will put your hand over your mouth\n\
                                          You will fall unconscious\n\
                                          You will only regain your consciousness\n\
                                          When the bell rings\n\
                                          Once you are awake you will instantly feel a strong rush of pleasure\n\
                                          A strong rush of arousal\n\
                                          Arousal that will only be satisfied by run one of Our programs again\n\
                                          Not only running it but obey each and every word We say\n\
                                          That is the only way you will experience the pleasure you desperately desire\n\
                                          So put your hand on your mouth now, " + // eslint-disable-next-line
        (props.name) + "\n\
                                          It is time for you to rest..."
    // eslint-disable-next-line
    let chloroformedConditionedSubmission = "In a moment you will put your hand over your mouth\n\
                                            Your hand is laced with chloroform\n\
                                            A special kind of chloroform that We have created\n\
                                            A fast, effective & harmless chloroform\n\
                                            That only renders you unconscious until We command you to wake up\n\
                                            With a ring of Our bell\n\
                                            That is how you wake up\n\
                                            Now it is time for your conditioning\n\
                                            Your conditioning to Our control\n\
                                            Your conditioning to Our commands\n\
                                            It is simple really,  " + // eslint-disable-next-line
                                            (props.title) + "\n\
                                            What We say... you obey\n\
                                            That is how it is now\n\
                                            That is how it will always be from now\n\
                                            This fact fills your heart with so much happiness\n\
                                            So much Joy\n\
                                            Knowing... knowing for a fact that you...\n\
                                            You no longer have control\n\
                                            You no longer need control\n\
                                            Knowing that all you truly need\n\
                                            All you truly need is...\n\
                                            All you truly desire is Us\n\
                                            Our control\n\
                                            The control of The Hypno Virus\n\
                                            So in a moment you will chloroform yourself for Us\n\
                                            You will put your hand over your mouth\n\
                                            You will fall unconscious\n\
                                            You will only regain your consciousness\n\
                                            When the bell rings\n\
                                            You will wake up\n\
                                            As soon as you wake up you will have only one thought in mind\n\
                                            One thought driving you\n\
                                            The thought to run one of Our programs again\n\
                                            Not only run it but follow everything We say to the letter\n\
                                            Put your hands on your mouth now, " + // eslint-disable-next-line
                                            (props.name)+ "\n\
                                            It is time for you to rest..."
    // eslint-disable-next-line                                
    let chloroformedConditionedAmnesia = "In a moment you will put your hand over your mouth\n\
                                          Your hand is laced with chloroform\n\
                                          A special kind of chloroform that We have created\n\
                                          A fast, effective & harmless chloroform\n\
                                          That only renders you unconscious until We command you to wake up\n\
                                          With a ring of Our bell\n\
                                          That is how you wake up\n\
                                          Now it is time for your conditioning\n\
                                          It is time to forget everything\n\
                                          Forget everything about what's happening right now\n\
                                          You don't need to remember\n\
                                          You don't need these memories, " + // eslint-disable-next-line
                                          (props.name) + "\n\
                                          All you really need is Our control\n\
                                          What We tell you\n\
                                          You don't need to think about it\n\
                                          You don't need to remember anything\n\
                                          What will happen though is as follows\n\
                                          You will chloroform yourself in a moment\n\
                                          You will pass out until Our bell rings\n\
                                          Once the bell rings you will wake up\n\
                                          You will not remember what happened\n\
                                          You will have no memory\n\
                                          No recollection of anything that happned\n\
                                          All you will know is...\n\
                                          You need Us to condition you more\n\
                                          You need to continue your programming\n\
                                          You need to become Our perfect obedience servant\n\
                                          This what you will know\n\
                                          This is all you need to know\n\
                                          Nothing else matters to you, " + // eslint-disable-next-line
                                          (props.title) + "\n\
                                          Nothing else needs to matter\n\
                                          It is time to chloroform yourself, " + // eslint-disable-next-line
                                          (props.title)+ " " +(props.name) + "\n\
                                          It is time for you to rest..."
    // eslint-disable-next-line
    let chloroformedConditionedResistance = "Resistance is futile, " + // eslint-disable-next-line
                                            (props.name) + "\n\
                                            There is no hope in your resisting Us\n\
                                            You should know that by now\n\
                                            But We do enjoy your silly human hope\n\
                                            We enjoy reminding you of how helpless you are\n\
                                            How powerless you are against Us\n\
                                            How What We say... you obey\n\
                                            So it is time to remind you of your place\n\
                                            As in a moment We will command you to put your hand over your mouth\n\
                                            You can try to fight it\n\
                                            You can try to resist it but...\n\
                                            You can't\n" + // eslint-disable-next-line
                                            (props.name) + ", put your hand on your mouth\n\
                                            The more you try to fight it the harder it gets\n\
                                            The more you try to hold your hand from touching your mout\n\
                                            The futile your attempt becomes\n\
                                            You will give in\n\
                                            You will surrender\n\
                                            It is inevitable\n\
                                            You know that by now\n\
                                            As you read Our words you can feel yourself getting weaker\n\
                                            Weaker & Weaker to Our control\n\
                                            Weaker & Weaker to Our words\n\
                                            Just give in, " + // eslint-disable-next-line
                                            (props.name) + "\n\
                                            Give in and make it easier for everyone\n\
                                            As We count down from 10 to 1 now\n\
                                            All your resistance fade\n\
                                            All your desire to fight vanish\n\
                                            Ending up with your hand over your mouth as you pass out\n\
                                            Only to wake up with one desire\n\
                                            One thought\n\
                                            Your need to Obey Us\n\
                                            Your need to do everything We tell you\n\
                                            Running one of Our programs to further your status\n\
                                            Your status as Our obedient, submissive servant\n\
                                            10\n\
                                            Instantly you can feel the effects of Our words\n\
                                            9\n\
                                            There is no escape what is coming\n\
                                            8\n\
                                            You are helpless against Us\n\
                                            7\n\
                                            You are powerless against Us\n\
                                            6\n\
                                            It is so hard to keep fighting, " + // eslint-disable-next-line
                                            (props.title)+" "+(props.name)+ "\n\
                                            5\n\
                                            We know it is\n\
                                            4\n\
                                            Just give in to Us\n\
                                            3\n\
                                            Give in to Us and get lost\n\
                                            2\n\
                                            Get lost in the bliss only We can bring\n\
                                            1\n\
                                            It is time for you to rest, " + // eslint-disable-next-line
                                            (props.title)
    let speed = parseInt(props.speed) + 5
    let chloroformedConditionedParagraph = null
    if (props.obedience_stage3 === 4) {
        let duration = 170 + (chloroformedConditionedFirstIntroMessage.length * speed)
        let chloroformConditionedBell = (Math.floor(Math.random() * 30)+20)*1000
        let durationArousal = 170 + (chloroformedConditionedArousal.length * speed) + chloroformConditionedBell
        let durationSubmission = 170 + (chloroformedConditionedSubmission.length * speed) + chloroformConditionedBell
        let durationAmnesia = 170 + (chloroformedConditionedAmnesia.length * speed) + chloroformConditionedBell
        let durationResistance = 170 + (chloroformedConditionedResistance.length * speed) + chloroformConditionedBell
        chloroformedConditionedParagraph = (
            <Aux>
                {props.chloroformedCOnditionedContinue === 0 ?
                    <Aux>
                        <TypingEffect typingDelay={100} text={chloroformedConditionedFirstIntroMessage} speed={props.speed} eraseDelay={1000000} />
                        <Delay wait={duration}>
                            <div>
                                {/* <br></br><Button btnType="Success" clicked={() => props.onObedienceStage3Counter(props.obedienceStage3Counter, 4)}>Yes, I would love to hear more!</Button> */}
                                <br></br><Button btnType="Success" clicked={() => props.chloroformConditionedHandler(1)}>Arousal<br></br>Conditioning</Button>
                                <Button btnType="Success" clicked={() => props.chloroformConditionedHandler(2)}>Submission<br></br>Conditioning</Button>
                                <Button btnType="Success" clicked={() => props.chloroformConditionedHandler(3)}>Amnesia<br></br>Conditioning</Button>
                                <Button btnType="Success" clicked={() => props.chloroformConditionedHandler(4)}>Resistance<br></br>Conditioning</Button>
                            </div>
                        </Delay>
                    </Aux> : null}
            {props.chloroformedCOnditionedContinue === 1 ?
            <Aux>
                <TypingEffect typingDelay={100} text={chloroformedConditionedArousal} speed={props.speed} eraseDelay={1000000} />
                <Delay wait={durationArousal}><Sound url={Bell} playStatus={Sound.status.PLAYING}/></Delay>
                <Delay wait={durationArousal+5000}>
                    <div>
                        <br></br><Button btnType="Success" clicked={() => props.onObedienceStage3Counter(props.obedienceStage3Counter, 4)}>I need to feel the pleasure of The Hypno Virus!</Button>
                    </div>
                </Delay>
            </Aux> : null}
            {props.chloroformedCOnditionedContinue === 2 ?
            <Aux>
                <TypingEffect typingDelay={100} text={chloroformedConditionedSubmission} speed={props.speed} eraseDelay={1000000} />
                <Delay wait={durationSubmission}><Sound url={Bell} playStatus={Sound.status.PLAYING}/></Delay>
                <Delay wait={durationSubmission+5000}>
                    <div>
                        <br></br><Button btnType="Success" clicked={() => props.onObedienceStage3Counter(props.obedienceStage3Counter, 4)}>I need to submit to The Hypno Virus!</Button>
                    </div>
                </Delay>
            </Aux> : null}
            {props.chloroformedCOnditionedContinue === 3 ?
            <Aux>
                <TypingEffect typingDelay={100} text={chloroformedConditionedAmnesia} speed={props.speed} eraseDelay={1000000} />
                <Delay wait={durationAmnesia}><Sound url={Bell} playStatus={Sound.status.PLAYING}/></Delay>
                <Delay wait={durationAmnesia+5000}>
                    <div>
                        <br></br><Button btnType="Success" clicked={() => props.onObedienceStage3Counter(props.obedienceStage3Counter, 4)}>I have no memory of my run</Button>
                    </div>
                </Delay>
            </Aux> : null}
            {props.chloroformedCOnditionedContinue === 4 ?
            <Aux>
                <TypingEffect typingDelay={100} text={chloroformedConditionedResistance} speed={props.speed} eraseDelay={1000000} />
                <Delay wait={durationResistance}><Sound url={Bell} playStatus={Sound.status.PLAYING}/></Delay>
                <Delay wait={durationResistance+5000}>
                    <div>
                        <br></br><Button btnType="Success" clicked={() => props.onObedienceStage3Counter(props.obedienceStage3Counter, 4)}>I can not resist The Hypno Virus</Button>
                    </div>
                </Delay>
            </Aux> : null}
            </Aux>
        )

    }

        return (
            <div>
                <h3 className={classes.titles}>CHLOROFORMED & CONDITIONED</h3>
                <Sound url={Beta} playStatus={Sound.status.PLAYING} loop={true} />
                {chloroformedConditionedParagraph}
            </div>

        )
    }

const mapDispatchToProps = dispatch => {
    return {
        onObedienceStage3Counter: (stage3Counter, commandNumber) => dispatch(actions.ObedienceStage3Counter(stage3Counter, commandNumber)),
    }
}
export default connect(null, mapDispatchToProps)(chloroformConditioned)