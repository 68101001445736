import * as conditioningStagesTypes from '../conditioningStagesTypes'

export const ObedienceStageStart = () => {
    return {
        type: conditioningStagesTypes.OBEDIENCE_STAGE_START,
    }
}

export const ObedienceStageCompleted = () => {
    return {
        type: conditioningStagesTypes.OBEDIENCE_STAGE_COMPLETED,
    }
}